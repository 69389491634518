import { call, put } from 'redux-saga/effects';

import { actions } from 'app/containers/GlobalSaga/slice';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

const { defaultPageSettings } = apiEndpoints;

export function* getDefaultPageData() {
	const requestURL = yield new URL(`${apiRoutes.base}/${defaultPageSettings}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.defaultPageLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.defaultPageError(responseError));
	}
}
