import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { getSelectedFilter } from 'app/containers/ReviewAndRelease/utils/helpers';
import { FutureMonth } from 'types/FutureMonth';
import { IGenericPagination } from 'types/GenericPagination';
import { CreatePreHedgeData } from 'types/PreHedge';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { selectFilters, selectPagination } from '../selectors';
import { actions } from '../slice';
import {
	PreHedgeDataPayload,
	PreHedgeFilters,
	PreHedgeViews,
	UpdatePreHedgePayload,
} from '../types';

const {
	preHedgeCreate,
	preHedgeFilter,
	preHedgeRoot,
	preHedgeEdit,
	preHedgeCancel,
	preHedgeFuturesMonths,
	reviewAndReleaseDetail,
} = apiEndpoints;

export function* createPreHedge(
	action: PayloadAction<{
		data: CreatePreHedgeData;
		closeAfterSubmit: boolean;
		successMessage: string;
	}>,
) {
	const { data, closeAfterSubmit, successMessage } = action.payload;

	const requestURL = yield new URL(`${apiRoutes.base}/${preHedgeCreate}`);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		data,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.loadPreHedgeList(true));
		yield put(actions.preHedgeCreated());
		if (closeAfterSubmit) {
			yield put(actions.setCurrentModal(null));
		}
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
				showDescription: false,
			}),
		);
	} else if (!!responseError.detail) {
		yield put(actions.activePreHedgeError(responseError));
	}
}

export function* getPreHedgeSummary(action: PayloadAction<{ id: string }>) {
	const { id } = action.payload;

	const requestURL = yield new URL(
		`${apiRoutes.base}/${reviewAndReleaseDetail}/${id}`,
	);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.preHedgeSummaryLoaded(responseData));
		yield put(actions.setCurrentView(PreHedgeViews.details));
	} else if (!!responseError.detail) {
		yield put(actions.preHedgeSummaryError(responseError));
	}
}

export function* updatePreHedge(action: PayloadAction<UpdatePreHedgePayload>) {
	const { data, successMessage } = action.payload;

	let requestURL = yield new URL(`${apiRoutes.base}/${preHedgeEdit}`);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		data,
	);

	if (!!responseData) {
		yield put(actions.preHedgeUpdated());
		yield put(actions.loadPreHedgeList(true));
		yield put(actions.setCurrentModal(null));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
				showDescription: false,
			}),
		);
	} else if (!!responseError.detail) {
		yield put(actions.activePreHedgeError(responseError));
	}
}

export function* getPreHedgeList(action: PayloadAction<boolean>) {
	const pagination: IGenericPagination = yield select(selectPagination);
	const selectedFilters: PreHedgeFilters = yield select(selectFilters);
	const requestURL = yield new URL(`${apiRoutes.base}/${preHedgeFilter}`);

	const selectedStatus = getSelectedFilter('status', 'value', selectedFilters);
	const selectedFuturesMonth = getSelectedFilter(
		'futuresMonth',
		'value',
		selectedFilters,
	);
	const selectedSymbol = getSelectedFilter('symbol', 'value', selectedFilters);
	const selectedCustomerId = yield getSelectedFilter(
		'customerId',
		'value',
		selectedFilters,
	);

	const data: PreHedgeDataPayload = {
		...pagination,
		...selectedFilters,
		status: selectedStatus,
		futuresMonth: selectedFuturesMonth,
		symbol: selectedSymbol,
		customerId: selectedCustomerId,
	};

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		data,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.preHedgeListLoaded(responseData));
		yield put(actions.setSelectedRows([]));
	} else if (!!responseError.detail) {
		yield put(actions.preHedgeListError(responseError));
	}
}

export function* getPreHedgeDetails(action: PayloadAction<string>) {
	const id = action.payload;
	const requestURL = yield new URL(`${apiRoutes.base}/${preHedgeRoot}/${id}`);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.preHedgeDetailLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.activePreHedgeError(responseError));
	}
}

export function* cancelPreHedge(action: PayloadAction<string>) {
	const id = action.payload;
	const requestURL = yield new URL(`${apiRoutes.base}/${preHedgeCancel}/${id}`);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
	);

	if (!!responseData) {
		yield put(actions.preHedgeCanceled());
		yield put(actions.loadPreHedgeList(true));
		yield put(actions.resetSuccess());
	} else if (!!responseError.detail) {
		yield put(actions.cancelPreHedgeError(responseError));
	}
}

export function* getFuturesMonths() {
	const requestURL = yield new URL(
		`${apiRoutes.base}/${preHedgeFuturesMonths}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		const data: FutureMonth[] = responseData.map((item) => ({
			id: item.name,
			...item,
		}));

		yield put(actions.getFuturesMonthsSuccess(data));
	} else if (!!responseError) {
		yield put(actions.getFuturesMonthsError(responseError));
	}
}
