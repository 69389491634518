import React from 'react';

import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { HedgeAccount } from 'app/containers/Transactions/components/HedgeAccount';
import { NumberOfLots } from 'app/containers/Transactions/components/NumberOfLots';
import { PreHedgeOrderType } from 'app/containers/Transactions/components/PreHedgeOrderType';
import { ActionType } from 'types/ActionType';
import { PRE_HEDGE_ORDER_TYPES } from 'utils/constants';
import Yup from 'yupCustom';

import { useTranslations } from '../../../shared/useTranslations';

export const useMarketOrderSchema = () => {
	const translations = useTranslations();

	const { required: requiredMsg, number: numberMsg } = translations.validations;

	return {
		initialValues: {
			action: ActionType.BUY,
			preHedgeOrderType: { value: PRE_HEDGE_ORDER_TYPES.marketOrder },
			commodity: { value: null },
			cropYear: null,
			futuresMonth: { value: null },
			quantity: null,
			comments: '',
			hedgeAccount: { value: null },
		},
		validationSchema: Yup.object().shape({
			preHedgeOrderType: Yup.object().requiredOption(requiredMsg),
			action: Yup.string().required(requiredMsg),
			commodity: Yup.object().requiredOption(requiredMsg),
			numberOfLots: Yup.string().typeError(numberMsg).required(requiredMsg),
			futuresMonth: Yup.object().requiredOption(requiredMsg),
			hedgeAccount: Yup.object().requiredOption(requiredMsg),
		}),
		elements: [
			<PreHedgeOrderType />,
			<Commodity reloadFuturesMonth checkDefaultValues />,
			<CropYears checkDefaultValues />,
			<Futures
				checkDefaultValues
				futuresMonth={{ market: true, disableWithinDeliveryMode: false }}
			/>,
			<NumberOfLots />,
			<HedgeAccount />,
		].concat([<Comments />]),
	};
};
