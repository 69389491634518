import { Col, Row } from 'antd';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectErpErrorMessage } from 'app/containers/TradeBook/selectors';

import { Filters } from './Filters';

export const ListMenu = memo(() => {
	const erpErrorMessage = useSelector(selectErpErrorMessage);

	return (
		<Row className="grid-menu" gutter={8}>
			<Col xs={12} sm={12} md={12} lg={9} xl={9}>
				<Filters />
			</Col>
			{erpErrorMessage && (
				<div className="tradebook_erp-error-message">
					<div>{erpErrorMessage}</div>
				</div>
			)}
		</Row>
	);
});
