import './style.scss';

import { Checkbox, Form } from 'formik-antd';
import React from 'react';

interface Props {
	name: string;
	label?: string;
	options?: any;
	disabled?: boolean;
	onChange?: (string) => void;
	disabledMode?: string;
	Children?: JSX.Element;
	customClass?: string;
	optionType?: 'button' | 'default';
	currentOptions?: [];
	initialValue?: any;
}

export const FormCheckboxGroup = (props: Props) => {
	const { name, label, options, disabled, Children, initialValue } = props;

	return (
		<Form.Item
			data-testid={name}
			label={label}
			name={name}
			className="form__checkbox label--bold"
		>
			<div>{Children}</div>
			<Checkbox.Group
				name={name}
				options={options}
				disabled={disabled}
				defaultValue={initialValue}
				className="checkbox--no-border-radius"
			/>
		</Form.Item>
	);
};
