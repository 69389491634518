import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions } from 'app/containers/GlobalSaga/slice';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

const { orderEntry } = apiEndpoints;

export function* getOrderEntrySaga(action: PayloadAction<any>) {
	const isActive = action?.payload?.isActive;

	const requestURL = yield new URL(`${apiRoutes.base}/${orderEntry}`);
	requestURL.searchParams.append('onlyActive', isActive);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
		true,
	);

	if (!!responseData) {
		yield put(actions.getOrderEntrySuccess(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.getOrderEntryError(responseError));
	}
}
