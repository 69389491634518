import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { employeeRoot } = apiEndpoints;

export function* createEmployee(action: PayloadAction<any>) {
	const { data, successMessage } = action.payload;
	const requestURL = yield new URL(`${apiRoutes.base}/${employeeRoot}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		data,
		false,
	);

	// Success actions
	if (!!responseData) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
			}),
		);
		yield put(actions.setCurrentModal(null));
		yield put(actions.loadEmployeesList());
		yield put(actions.employeeCreated());
	} else if (responseError?.status === 2) {
		yield put(actions.setErrorsInLine(responseError.data));
	} else if (!!responseError) {
		yield put(actions.employeeError(responseError));
	}
}
