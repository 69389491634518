import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { locationsRoot } = apiEndpoints;

export function* getDestinationsList() {
	let requestURL: any = yield new URL(
		`${apiRoutes.base}/${locationsRoot}?IsDestination=true`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.destinationsListLoaded(responseData.locations));
	} else if (!!responseError.detail) {
		yield put(actions.destinationsListError(responseError));
	}
}
