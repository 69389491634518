import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { ContractTypeSource } from 'types/ContractTypeSource';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { contractTypesRoot } = apiEndpoints;

export function* getContractTypeList(
	action: PayloadAction<ContractTypeSource>,
) {
	const requestURL = yield new URL(
		`${apiRoutes.base}/${contractTypesRoot}/${action.payload}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(
			actions.contractTypeListLoaded({
				data: responseData as any,
				source: action.payload,
			}),
		);
	} else if (!!responseError.detail) {
		yield put(
			actions.contractTypeError({
				error: responseError,
				source: action.payload,
			}),
		);
	}
}
