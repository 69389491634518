import './style.scss';

import { EditFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { UserRolIcon } from 'app/components/CustomIcon';
import { translations } from 'locales/i18n';

import { loadingActiveEmployee } from '../../../selectors';
import { actions } from '../../../slice';
import { View } from '../../../types';
interface Props {
	rowData: any;
}

export const Actions = memo((props: Props) => {
	const { rowData } = props;
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const domain =
		translations.app.containers.Settings.sections.Employees.View.List.Table
			.Actions;
	const activeEmployeeLoading = useSelector(loadingActiveEmployee);

	const handleEdit = () => {
		dispatch(actions.requestActiveEmployee(rowData));
	};

	const handleRoleAndPermissions = () => {
		dispatch(actions.setActiveEmployee(rowData));
		dispatch(actions.loadEmployeesPermissions(rowData.email));
		dispatch(actions.setCurrentView(View.Permissions));
	};

	return (
		<div className="actions">
			<Button
				type="text"
				data-testid="edit-button"
				className="actions__button--content-centered"
				onClick={handleEdit}
				disabled={activeEmployeeLoading}
			>
				<EditFilled />
				{translate(domain.Edit)}
			</Button>
			<Button
				className="actions__button--content-centered actions__role-and-permissions--pre-line"
				type="text"
				data-testid="role-permission-button"
				disabled={activeEmployeeLoading}
				onClick={handleRoleAndPermissions}
			>
				<UserRolIcon />
				{translate(domain.RolesAndPermission)}
			</Button>
		</div>
	);
});
