import { Button, Modal } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { translations } from 'locales/i18n';

import { actions } from '../../slice';
import { NotificationGroupDeleteHookValue } from '../../types';

interface Props {
	useGroupsHook: () => NotificationGroupDeleteHookValue;
}

export const DeleteNotificationGroup = ({ useGroupsHook }: Props) => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.NotificationSettings;

	const { activeGroup, onSubmit, onClose } = useGroupsHook();

	const handleCancel = () => {
		dispatch(actions.setCurrentModal(null));
		onClose();
	};

	return (
		<Modal
			maskClosable={false}
			className="notification-group-delete__modal"
			title={`${translate(translationsScope.Title)} #${activeGroup?.name}`}
			visible={true}
			onOk={onSubmit}
			onCancel={handleCancel}
			footer={[
				<Button
					key="back"
					onClick={handleCancel}
					className="return-button"
					data-testid="return-button"
				>
					{translate(translationsScope.CancelButton)}
				</Button>,
				<Button
					data-testid="confirm-button"
					key="submit"
					type="primary"
					onClick={onSubmit}
					className="cancel-button"
				>
					{translate(translationsScope.DeleteButton)}
				</Button>,
			]}
		>
			<Paragraph type="danger">
				{translate(translationsScope.BodyMessage)}
			</Paragraph>
		</Modal>
	);
};
