import { Moment } from 'moment';

import {
	AlertTone,
	NotificationAlertTone,
	NotificationGroupDto,
} from 'app/containers/GlobalSaga/types';
import { Permission } from 'types/Authorization';
import { GenericError } from 'types/GenericError';
import { GenericItem } from 'types/GenericItem';
import { NotificationGroupMember } from 'types/UserNotification';

export interface NotificationSettingTab {
	name: string;
	content: React.ReactNode;
	permission?: Permission;
}

export enum NotificationSettingsScope {
	tenant = 'tenant',
	personal = 'personal',
}

export enum NotificationSettingTabs {
	tenant = 'tenantNotificationSettings',
	personal = 'personalNotificationSettings',
	employeeGroup = 'employeeNotificationSettings',
	customerGroup = 'customerNotificationSettings',
}

export enum NotificationEventStatus {
	ScheduleTime = 'scheduleTime',
	IsInAppEnabled = 'isInAppEnabled',
	IsEmailEnabled = 'isEmailEnabled',
	IsSmsEnabled = 'isSmsEnabled',
	IsDailySummaryEnabled = 'isDailySummaryEnabled',
}

export enum NotificationEventType {
	OfferFilled = 'OfferFilled',
	OfferCompleted = 'OfferCompleted',
	OfferCancelled = 'OfferCancelled',
	OfferRejected = 'OfferRejected',
	OfferExpiring = 'OfferExpiring',
	HedgesReadyStatus = 'HedgesReadyStatus',
	HedgesRejectedStatus = 'HedgesRejectedStatus',
	BasisPush = 'BasisPush',
	BidsPublish = 'BidsPublish',
	BidsSent = 'BidsSent',
}

export interface NotificationEvent extends GenericItem {
	scope: NotificationSettingsScope;
	eventType: NotificationEventType;
	[NotificationEventStatus.ScheduleTime]: Moment | undefined;
	[NotificationEventStatus.IsInAppEnabled]: boolean | undefined;
	[NotificationEventStatus.IsEmailEnabled]: boolean | undefined;
	[NotificationEventStatus.IsSmsEnabled]: boolean | undefined;
	[NotificationEventStatus.IsDailySummaryEnabled]: boolean | undefined;
}

interface NotificationGroup extends NotificationGroupDto {
	members: GenericItem[];
}

export enum Modal {
	Delete = 'Delete',
}

export enum View {
	List = 'list',
	Create = 'create',
	Edit = 'edit',
}

export type UserNotificationSubscriptions = {
	userSubscriptions: NotificationEvent[];
	tenantSettings: NotificationEvent[];
};

export type NotificationGroupPayload = Partial<NotificationGroup>;
export type NotificationEventPayload = Partial<UserNotificationSubscriptions>;
export type NotificationAlertTonePayload = {
	alertSound: AlertTone;
};

/* --- STATE --- */
export interface NotificationSettingsState {
	currentModal: Modal | null;
	currentView: View;

	tenantPreferences: {
		data: NotificationEvent[];
		loading: boolean;
		error: GenericError | null;
	};
	userPreferences: {
		data: NotificationEvent[];
		loading: boolean;
		error: GenericError | null;
	};
	alertTones: {
		data: NotificationAlertTone[];
		loading: boolean;
		error: GenericError | null;
	};

	// Group Settings
	assignedGroups: {
		data: GenericItem[];
		loading: boolean;
		error: GenericError | null;
	};

	// Employee Group Settings
	employeeGroups: {
		data: NotificationGroupDto[];
		loading: boolean;
		error: GenericError | null;
	};
	editEmployeeGroupDetails: {
		data: NotificationGroupDto | null;
		loading: boolean;
		error: GenericError | null;
	};
	activeEmployeeGroup: {
		data: NotificationGroupDto | null;
		loading: boolean;
		error: GenericError | null;
	};
	employeeGroupMembers: {
		data: NotificationGroupMember[];
		loading: boolean;
		error: GenericError | null;
	};
	employeeGroupNonMembers: {
		data: NotificationGroupMember[];
		loading: boolean;
		error: GenericError | null;
	};

	// Customer Group Settings
	editCustomerGroupDetails: {
		data: NotificationGroupDto | null;
		loading: boolean;
		error: GenericError | null;
	};
	activeCustomerGroup: {
		data: NotificationGroupDto | null;
		loading: boolean;
		error: GenericError | null;
	};
	customerGroupMembers: {
		data: NotificationGroupMember[];
		loading: boolean;
		error: GenericError | null;
	};
	customerGroupNonMembers: {
		data: NotificationGroupMember[];
		loading: boolean;
		error: GenericError | null;
	};
}

export type NotificationSettingsSectionState = NotificationSettingsState;

// Hooks
export interface NotificationGroupListHookValue {
	handleCreateGroup: () => void;
	handleEditGroup: (item: NotificationGroupDto) => void;
	handleDeleteGroup: (item: NotificationGroupDto) => void;
	groupList: NotificationGroupDto[];
	listLoading: boolean;
}

export interface NotificationGroupCreateHookValue {
	onSubmit: (item: {
		groupName: string;
		groupMembers: NotificationGroupMember[];
	}) => void;
	onClose: () => void;
	nonMemberList: NotificationGroupMember[];
	loading: boolean;
}

export interface NotificationGroupEditHookValue {
	onSubmit: (item: {
		groupName: string;
		groupMembers: NotificationGroupMember[];
	}) => void;
	onClose: () => void;
	groupDetails: NotificationGroupDto | null;
	nonMemberList: NotificationGroupMember[];
	memberList: NotificationGroupMember[];
	loading: boolean;
}

export interface NotificationGroupDeleteHookValue {
	onSubmit: () => void;
	onClose: () => void;
	activeGroup: NotificationGroupDto | null;
}
