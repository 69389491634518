import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';

export const useLocationFieldTranslations = () => {
	const { t: translate } = useTranslation();
	const domain = {
		...translations.app.commons,
		...translations.app.containers.Settings.definitions.fields,
		...translations.app.containers.Settings.sections,
		...translations.app.containers.Settings.sections.Roles.Views.CreateRole,
	};

	const customerScope =
		translations.app.containers.Settings.sections.Customers.Modals.CreateEdit;

	return {
		validationSchema: {
			required: translate(domain.validationSchema.required),
			requiredFile: translate(domain.validationSchema.requiredFile),
			invalidEmail: translate(domain.validationSchema.invalidEmail),
			noAvailableBids: translate(domain.rollOrphanAvailableBidsError),
		},
		erpNumber: {
			label: translate(domain.erpNumber.label),
			placeholder: translate(domain.erpNumber.placeholder),
			erpNumberRepeatedMessage: translate(
				customerScope.erpNumberRepeatedMessage,
			),
			defaultErrorMessage: translate(customerScope.toastErrorCreateMessage),
		},
		location: {
			contractName: {
				label: translate(domain.Locations.contractLocationNameLabel),
				placeholder: translate(
					domain.Locations.contractLocationNamePlaceholder,
				),
			},
			contractNumber: {
				label: translate(domain.Locations.contractLocationErpNumberLabel),
				placeholder: translate(
					domain.Locations.contractLocationErpNumberPlaceholder,
				),
			},
			destinationName: {
				label: translate(domain.Locations.destinationLocationNameLabel),
				placeholder: translate(
					domain.Locations.destinationLocationNamePlaceholder,
				),
			},
			destinationNumber: {
				label: translate(domain.Locations.destinationLocationErpNumberLabel),
				placeholder: translate(
					domain.Locations.destinationLocationErpNumberPlaceholder,
				),
			},
			origin: {
				label: translate(domain.Locations.locationOriginLabel),
				instructions: translate(domain.Locations.locationOriginInstructions),
			},
			manualEditMode: translate(domain.Locations.manualEditMode),
			region: {
				label: translate(domain.regionPlaceholder),
				placeholder: translate(domain.regionPlaceholder),
			},
		},
	};
};
