import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';
import { createSlice } from 'utils/@reduxjs/toolkit';

import {
	CommoditySectionState,
	FileUploadPayload,
	Modal,
	TooglePayload,
	View,
} from './types';

export const initialState: CommoditySectionState = {
	currentView: View.List,
	currentModal: null,

	activeCommodity: {
		data: null,
		loading: false,
		error: null,
	},

	list: {
		data: [],
		loading: false,
		total: 0,
		error: null,
	},

	commodityStatus: {
		data: null,
		loading: false,
		error: null,
	},

	products: {
		data: [],
		loading: false,
		error: null,
	},

	hedgeMapTemplate: {
		data: null,
		uploadResponse: { data: null, status: null, statusText: null },
		loading: false,
		error: null,
	},
	loadFilledHedgemapTemplate: {
		commodityId: '',
		additionalCropYearsAdded: 0,
		name: '',
	},
	validationTextHelper: {
		label: '',
	},
};

const commoditiesSlice = createSlice({
	name: 'commodities',
	initialState,
	reducers: {
		setCurrentModal(state, action: PayloadAction<Modal | null>) {
			state.currentModal = action.payload;
		},

		setActiveCommodity(state, action) {
			state.activeCommodity = {
				data: action.payload,
				loading: true,
				error: null,
			};
		},

		resetActiveCommodity(state) {
			state.activeCommodity = {
				data: null,
				loading: false,
				error: null,
			};
		},

		loadCommoditiesList(state: CommoditySectionState) {
			state.list = {
				...state.list,
				data: [],
				loading: true,
				error: null,
			};
		},

		commoditiesListLoaded(
			state: CommoditySectionState,
			action: PayloadAction<any>,
		) {
			state.list = {
				data: action.payload.list,
				total: action.payload.total,
				loading: false,
				error: null,
			};
		},
		commoditiesListError(
			state: CommoditySectionState,
			action: PayloadAction<GenericError>,
		) {
			state.list = {
				...state.list,
				loading: false,
				error: action.payload,
			};
		},

		createCommodity(state, action: PayloadAction<any>) {
			state.activeCommodity = {
				...state.activeCommodity,
				data: action.payload,
				loading: true,
				error: null,
			};
		},
		commodityCreated(state, action: PayloadAction<any>) {
			state.activeCommodity = {
				...state.activeCommodity,
				data: action.payload,
				loading: false,
				error: null,
			};
		},
		commodityError(state, action: PayloadAction<GenericError>) {
			state.activeCommodity = {
				...state.activeCommodity,
				error: action.payload,
				loading: false,
			};
		},
		editCommodity(state, action: PayloadAction<any>) {
			state.activeCommodity = {
				...state.activeCommodity,
				loading: true,
				error: null,
			};
		},
		commodityEdited(state, action: PayloadAction<any>) {
			state.activeCommodity = {
				...state.activeCommodity,
				loading: false,
				error: null,
			};
		},
		requestedCommodityById(state, action: PayloadAction<any>) {
			state.activeCommodity = {
				...state.activeCommodity,
				data: action.payload,
				loading: false,
				error: null,
			};
		},
		setCommodityStatus(state, action: PayloadAction<TooglePayload>) {
			state.commodityStatus = {
				...state.commodityStatus,
				loading: true,
				error: null,
			};
		},

		setCommodityStatusSuccess(state, action) {
			state.commodityStatus = {
				...state.commodityStatus,
				loading: false,
				error: null,
				data: action.payload,
			};
		},

		setCommodityStatusError(state, action) {
			state.commodityStatus = {
				...state.commodityStatus,
				error: action.payload,
				loading: false,
			};
		},

		loadProducts(state) {
			state.products = {
				...state.products,
				data: [],
				loading: true,
				error: null,
			};
		},

		productListLoaded(state, action: PayloadAction<any>) {
			state.products = {
				...state.products,
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		productListError(state, action: PayloadAction<GenericError>) {
			state.products = {
				...state.products,
				loading: false,
				error: action.payload,
			};
		},

		loadHedgeMapTemplate(
			state: CommoditySectionState,
			action: PayloadAction<any>,
		) {
			state.hedgeMapTemplate = {
				data: null,
				uploadResponse: { data: null, status: null, statusText: null },
				loading: true,
				error: null,
			};
		},

		hedgeMapTemplateLoaded(state, action: PayloadAction<any>) {
			state.hedgeMapTemplate = {
				data: {
					...action.payload,
				},
				uploadResponse: { data: null, status: null, statusText: null },
				loading: false,
				error: null,
			};
		},

		hedgeMapTemplateUploaded(state, action: PayloadAction<any>) {
			state.hedgeMapTemplate = {
				...state.hedgeMapTemplate,
				uploadResponse: action.payload,
				loading: false,
			};
		},

		hedgeMapTemplateError(state, action: PayloadAction<GenericError>) {
			state.hedgeMapTemplate = {
				...state.hedgeMapTemplate,
				loading: false,
				error: action.payload,
			};
		},

		uploadCreateHedgeMapTemplate(
			state: CommoditySectionState,
			action: PayloadAction<FileUploadPayload>,
		) {
			state.hedgeMapTemplate = {
				...state.hedgeMapTemplate,
				uploadResponse: { data: null, status: null, statusText: null },
				loading: true,
			};
		},

		uploadEditHedgeMapTemplate(
			state: CommoditySectionState,
			action: PayloadAction<FileUploadPayload>,
		) {
			state.hedgeMapTemplate = {
				...state.hedgeMapTemplate,
				uploadResponse: { data: null, status: null, statusText: null },
				loading: true,
			};
		},

		clearHedgeMapTemplate(state: CommoditySectionState) {
			state.hedgeMapTemplate = {
				...state.hedgeMapTemplate,
				uploadResponse: { data: null, status: null, statusText: null },
				loading: false,
				error: null,
			};
		},

		filledHedgeMapTemplateLoaded(state, action: PayloadAction<any>) {
			state.hedgeMapTemplate = {
				...state.hedgeMapTemplate,
				data: {
					...action.payload,
				},
				uploadResponse: { data: null, status: null, statusText: null },
				loading: false,
			};
		},

		filledHedgeMapTemplateError(state, action: PayloadAction<GenericError>) {
			state.hedgeMapTemplate = {
				...state.hedgeMapTemplate,
				loading: false,
				error: action.payload,
			};
		},
		loadFilledHedgemapTemplate(state, action: PayloadAction<any>) {
			state.loadFilledHedgemapTemplate = {
				commodityId: action.payload.commodityId,
				name: action.payload.name,
				additionalCropYearsAdded: action.payload.additionalCropYearsAdded,
			};
			state.hedgeMapTemplate = {
				...state.hedgeMapTemplate,
				data: null,
				uploadResponse: { data: null, status: null, statusText: null },
				loading: true,
				error: null,
			};
		},

		validationTextAction(state, action: PayloadAction<any>) {
			state.validationTextHelper = {
				label: action.payload,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = commoditiesSlice;
