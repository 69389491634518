import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { selectDefaultPageData } from 'app/containers/GlobalSaga/selectors';
import { AssignedRegion } from 'app/containers/Transactions/components/AssignedRegion';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractType } from 'app/containers/Transactions/components/ContractType';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { Customer } from 'app/containers/Transactions/components/Customer';
import { DeliveryDates } from 'app/containers/Transactions/components/DeliveryDates';
import { DeliveryLocation } from 'app/containers/Transactions/components/DeliveryLocation';
import { Employee } from 'app/containers/Transactions/components/Employee';
import { ExpirationDateGTC } from 'app/containers/Transactions/components/ExpirationDateGTC';
import { Fees } from 'app/containers/Transactions/components/Fees';
import { Freight } from 'app/containers/Transactions/components/Freight';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Location } from 'app/containers/Transactions/components/Location';
import { Quantity } from 'app/containers/Transactions/components/Quantity';
import { TheirContract } from 'app/containers/Transactions/components/TheirContract';
import { Transaction } from 'app/containers/Transactions/components/Transaction';
import { useOrderEntriesFormSchema } from 'app/containers/Transactions/hooks/useOrderEntriesFormSchema';
import { ActionType } from 'types/ActionType';
import { DeliveryDateMode } from 'types/DeliveryDateMode';
import {
	isDeliveryDateCustom,
	isDeliveryDateWindow,
	validateQuarterCents,
} from 'utils/helpers';

import { useTranslations } from '../../../shared/useTranslations';

export const useHTASchema = (
	translations: ReturnType<typeof useTranslations>,
) => {
	const defaultData = useSelector(selectDefaultPageData);

	const requiredMsg = translations.validations.required;
	const numberMsg = translations.validations.number;
	const maxLength10Msg = translations.validations.maxLength10;
	const futuresPriceQuarterMsg =
		translations.validations.futuresPriceQuarterMsg;

	const { elements, initialValues, validationSchema } =
		useOrderEntriesFormSchema();

	const validation = useMemo(
		() =>
			Yup.object().shape({
				theirContract: Yup.string().nullable().max(10, maxLength10Msg),
				transaction: Yup.object().requiredOption(requiredMsg),
				contractNumber: Yup.string().nullable(),
				contract: Yup.object().requiredOption(requiredMsg),
				action: Yup.string().required(requiredMsg),
				commodity: Yup.object().requiredOption(requiredMsg),
				location: Yup.object().requiredOption(requiredMsg),
				deliveryLocation: Yup.object().requiredOption(requiredMsg),
				assignedRegion: Yup.object().requiredOption(requiredMsg),
				deliveryDate: Yup.mixed().when('deliveryDatesMode', {
					is: (mode) => isDeliveryDateCustom(mode),
					then: Yup.mixed().required(requiredMsg),
				}),
				deliveryDateWindow: Yup.mixed().when('deliveryDatesMode', {
					is: (mode) => isDeliveryDateWindow(mode),
					then: Yup.object().requiredOption(requiredMsg),
				}),
				futuresMonth: Yup.object().requiredOption(requiredMsg),
				futuresPrice: Yup.number()
					.typeError(requiredMsg)
					.required(requiredMsg)
					.test('quarterCentsValidation', futuresPriceQuarterMsg, (val) =>
						validateQuarterCents(val),
					),
				quantity: Yup.string().typeError(numberMsg).required(requiredMsg),
				expirationDateGTC: Yup.string()
					.nullable()
					.when('gtcMode', {
						is: false,
						then: Yup.string().required(requiredMsg),
						otherwise: Yup.string().nullable(),
					}),
				customer: Yup.object().requiredOption(requiredMsg),
				employee: Yup.object().requiredOption(requiredMsg),
				...validationSchema,
			}),
		[
			requiredMsg,
			numberMsg,
			maxLength10Msg,
			futuresPriceQuarterMsg,
			validationSchema,
		],
	);

	return useMemo(
		() => ({
			initialValues: {
				action: ActionType.BUY,
				commodity: { value: null },
				location: { value: null },
				deliveryLocation: { value: null },
				assignedRegion: { value: null },
				cropYear: null,
				deliveryDate: null,
				deliveryDatesMode: { value: DeliveryDateMode.Custom },
				deliveryDateWindow: { value: null },
				futuresMonth: { value: null },
				futuresPrice: null,
				freight: null,
				fees1: null,
				fees2: null,
				quantity: null,
				gtcMode: false,
				expirationDateGTC: '',
				customer: { value: null },
				employee: {
					label: defaultData?.employeeName,
					value: defaultData?.employeeId,
				},
				comments: '',
				useServiceFees: false,
				...initialValues,
			},
			validationSchema: validation,
			elements: (
				<>
					<TheirContract />
					<Transaction />
					<ContractType />
					<Commodity checkDefaultValues />
					<Location />
					<DeliveryLocation />
					<AssignedRegion />
					<CropYears />
					<DeliveryDates
						resetDependencies={false}
						modeSelectorDisabled
						useServiceFees
						forceToCustom
					/>
					<Futures
						futuresMonth={{ wrapperCol: { span: 6 } }}
						futuresPrice={{}}
					/>
					<Freight adjustFuturesPrice={false} />
					<Fees adjustFuturesPrice={false} />
					<Quantity />
					<ExpirationDateGTC disableWeekends />
					<Customer />
					<Employee />
					{elements}
					<Comments />
				</>
			),
		}),
		[defaultData, elements, initialValues, validation],
	);
};
