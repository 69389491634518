import './style.scss';

import {
	CheckCircleOutlined,
	DeleteOutlined,
	DownloadOutlined,
	UploadOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';
import React from 'react';

interface Props {
	show: boolean;
	url: any;
	fileName: string;
	disabled: boolean;
	isUploaded: boolean;
	isLoading?: boolean;
	uploadCompleteText: string;
	uploadingText?: string;
	downloadText: string;
	onDeleteIcon?: () => void;
	hide?: boolean;
}

export const DownloadLink = ({
	show,
	url,
	fileName,
	disabled,
	isUploaded,
	isLoading,
	uploadCompleteText,
	uploadingText,
	downloadText,
	onDeleteIcon,
	hide,
}: Props) => {
	const handleDeleteIconClick = () => {
		onDeleteIcon && onDeleteIcon();
	};

	return show ? (
		<span className={classNames('download-link', { hide: hide })}>
			<a
				href={url}
				download={fileName}
				className={classNames({
					disabled: disabled,
				})}
			>
				{isUploaded ? (
					<span>
						<CheckCircleOutlined /> {uploadCompleteText}
					</span>
				) : isLoading ? (
					<span>
						<UploadOutlined /> {uploadingText}
					</span>
				) : (
					<span>
						<DownloadOutlined /> {downloadText}
					</span>
				)}
			</a>
			{isUploaded && (
				<DeleteOutlined
					data-testid="delete-icon"
					onClick={handleDeleteIconClick}
				/>
			)}
		</span>
	) : null;
};
