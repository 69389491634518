import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from '../../slice';
import { Filters } from './Filters';
import { Table } from './Table';

export const List = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(actions.loadRoundingRulesList());
		dispatch(actions.loadRoundingTypesList());
	}, [dispatch]);

	return (
		<div className="list-container">
			<Filters />
			<Table />
		</div>
	);
};
