import React from 'react';
import { useSelector } from 'react-redux';

import { selectHasCustomFields } from 'app/containers/GlobalSaga/selectors';
import Yup from 'yupCustom';

import { Toggle } from '../components/Toggle';

export const useToggleSchema = () => {
	const hasCustomFields = useSelector(selectHasCustomFields);

	return {
		initialValues: {
			hasCustomFields,
		},
		validationSchema: Yup.object().shape({
			hasCustomFields: Yup.boolean(),
		}),
		elements: [<Toggle />],
	};
};
