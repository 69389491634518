import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { RadioButtons } from 'app/components/RadiosButtons';
import { translations } from 'locales/i18n';
import { ActionType } from 'types/ActionType';

interface Props {
	disabled?: boolean;
	hidden?: boolean;
	disableBuySell?: boolean;
}

enum PRE_HEDGE_ORDER_TYPES {
	limitOrder = 'limitOrder',
	marketOrder = 'marketOrder',
}

const fieldName = 'preHedgeOrderType';
export const PreHedgeOrderType = memo((props: Props) => {
	const { disabled = false, hidden = false, disableBuySell = false } = props;
	const { t: translate } = useTranslation();

	const msg =
		translations.app.containers.Transactions.components.PreHedgeOrderType;
	const getTranslation = (key: string) => translate(msg[key]);

	const orderTypeList = [
		{
			label: getTranslation(PRE_HEDGE_ORDER_TYPES.limitOrder),
			value: getTranslation(PRE_HEDGE_ORDER_TYPES.limitOrder),
		},
		{
			label: getTranslation(PRE_HEDGE_ORDER_TYPES.marketOrder),
			value: getTranslation(PRE_HEDGE_ORDER_TYPES.marketOrder),
		},
	];

	return (
		<GenericForm.FormItem
			label={translate(msg.label)}
			name="contract-group"
			customClassName={hidden ? ['hidden'] : []}
		>
			<GenericForm.FormItem
				name={fieldName}
				customClassName={hidden ? ['hidden'] : []}
			>
				<Dropdown
					disabled={disabled}
					key={fieldName}
					placeholder={translate(msg.placeholder)}
					options={orderTypeList}
				/>
			</GenericForm.FormItem>
			<GenericForm.FormItem name="action">
				<RadioButtons
					disabled={disableBuySell || disabled}
					name="action"
					key="action"
					disabledMode={disableBuySell ? 'multi' : 'single'}
					options={[
						{ label: translate(msg.action.buy), value: ActionType.BUY },
						{ label: translate(msg.action.sell), value: ActionType.SELL },
					]}
				/>
			</GenericForm.FormItem>
		</GenericForm.FormItem>
	);
});
