import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Popover, Typography } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FilterEmpty } from 'app/components/FilterEmpty';
import { GenericIcon } from 'app/components/GenericIcon';
import { GenericTable } from 'app/components/GenericTable';
import {
	ErpStates,
	ErpStatusToFilterKeyMap,
} from 'app/containers/FuturesErp/types';
import { translations } from 'locales/i18n';
import { FixedColumn } from 'types/FixedColumn';
import { FuturesErp } from 'types/FuturesErp';
import { dateFormat, hasSetFilters, timeFormat } from 'utils/helpers';

import {
	selectFuturesErpList,
	selectFuturesErpListLoading,
	selectFuturesErpTotal,
	selectPagination,
	selectSelectedFilters,
	selectSelectedRows,
} from '../../../../selectors';
import { actions } from '../../../../slice';

const { Paragraph } = Typography;

interface ColumnProps {
	name: string;
	title: string;
	className: string;
	fixed: FixedColumn;
	key?: string;
	dataIndex?: string;
	render: (data: FuturesErp) => JSX.Element;
}

export const TableWrapper = memo(() => {
	const dispatch = useDispatch();
	const [selectedRowKeys, setselectedRowKeys] = useState<string[]>([]);

	const translationsScope = translations.app.containers.FuturesErp;

	const loading = useSelector(selectFuturesErpListLoading);
	const selectedFilters = useSelector(selectSelectedFilters);
	const total = useSelector(selectFuturesErpTotal);
	const data = useSelector(selectFuturesErpList);
	const pagination = useSelector(selectPagination);
	const selectedRowsData = useSelector(selectSelectedRows);

	const { t: translate } = useTranslation();

	const handleOnChange = (page, pageSize) => {
		dispatch(
			actions.setPagination({
				limit: pageSize,
				start: page,
			}),
		);
		dispatch(actions.loadFuturesErpList());
	};

	const getErpStatusTranslation = (erpStatus) =>
		translate(translationsScope[ErpStatusToFilterKeyMap[erpStatus]]);

	useEffect(() => {
		setselectedRowKeys(selectedRowsData.map(({ id }) => id));
	}, [selectedRowsData]);

	const onChangeSelection = (_selectedIDs, selectedRowsData) => {
		dispatch(actions.setSelectedRows(selectedRowsData));
	};

	const Item = (props) => (
		<div className="wrapper-column">
			<div className={`order__column ${props.className}`}>{props.children}</div>
		</div>
	);

	const columns: ColumnProps[] = [
		{
			name: 'created',
			title: translate(translationsScope.created),
			className: 'column-table sm',
			fixed: 'left',
			render: (data) => (
				<Item>
					<Popover
						content={
							<div className="popover--content">
								<strong>{translate(translationsScope.status)}: </strong>
								{getErpStatusTranslation(data.erpStatus)}
							</div>
						}
						trigger="hover"
					>
						<div>
							<p className="text text--bold text--large">
								{dateFormat(data.createdOn ?? null)}
							</p>
							<p className="text text--label">
								{timeFormat(data.createdOn ?? null)}
							</p>
						</div>
					</Popover>
				</Item>
			),
		},
		{
			name: 'account',
			title: translate(translationsScope.account),
			className: 'column-table md md--content-limit',
			fixed: false,
			render: (data) => {
				return (
					<Item>
						<div className="text text--bold flex-row">
							<div>
								<span className="text text--large text--bold">
									{!!data?.accountNo ? `${data?.accountNo}` : '---'}
								</span>
							</div>
						</div>
					</Item>
				);
			},
		},
		{
			name: 'order',
			title: translate(translationsScope.order),
			className: 'column-table md',
			fixed: false,
			render: (data) => {
				const isErpStatusFailedOrTimedOut =
					data.erpStatus === ErpStates.TimeOut ||
					data.erpStatus === ErpStates.Fail;
				return (
					<GenericTable.Column>
						<div className="text text--bold flex-row">
							<div>
								{isErpStatusFailedOrTimedOut ? (
									<GenericIcon
										hasTooltip
										icon={<ExclamationCircleOutlined />}
										iconType="danger"
										tooltipText={
											!!data.erpMessage
												? data.erpMessage
												: getErpStatusTranslation(data.erpStatus)
										}
									/>
								) : data.erpStatus === ErpStates.Pending ? (
									<span className="text text--large text--bold">
										{getErpStatusTranslation(data.erpStatus)} {data?.marketId}
									</span>
								) : (
									<span className="text text--large text--bold">
										{!!data.marketId && `${data.marketId}`}
									</span>
								)}
							</div>
							{isErpStatusFailedOrTimedOut && (
								<p className="text text--large text--bold">{data?.marketId}</p>
							)}
						</div>
					</GenericTable.Column>
				);
			},
		},
		{
			name: 'sellBuy',
			title: translate(translationsScope.sellBuy),
			fixed: false,
			className: 'column-table sm',
			render: (data) => (
				<GenericTable.Column>
					<div>
						<p className="text text--large">
							{data.buySell === 'Sell'
								? translate(translationsScope.sellLabel)
								: translate(translationsScope.buyLabel)}
						</p>
					</div>
				</GenericTable.Column>
			),
		},
		{
			name: 'futuresMonth',
			title: translate(translationsScope.futuresMonth),
			fixed: false,
			className: 'column-table md',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis text--centered"
						title={data.futuresMonth}
						ellipsis={{ rows: 2 }}
					>
						{data.futuresMonth}
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			name: 'type',
			title: translate(translationsScope.type),
			fixed: false,
			className: 'column-table md',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis text--centered"
						title={data.transactionType}
						ellipsis={{ rows: 2 }}
					>
						{data.transactionType}
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			name: 'fillPrice',
			title: translate(translationsScope.fillPrice),
			fixed: false,
			className: 'column-table md',
			render: (data) => {
				return (
					<GenericTable.Column>
						<Paragraph
							className="text text--large ellipsis text--centered"
							title={data.price.toString()}
							ellipsis={{ rows: 2 }}
						>
							{data.price}
						</Paragraph>
					</GenericTable.Column>
				);
			},
		},
		{
			name: 'quantity',
			title: translate(translationsScope.quantity),
			className: 'column-table sm',
			fixed: false,
			render: (data) => (
				<Item>
					<p className="text text--large text--bold">{data?.orderQuantity}</p>
				</Item>
			),
		},
		{
			name: 'filledQuantity',
			title: translate(translationsScope.workingQty),
			fixed: false,
			className: 'column-table md',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis text--centered"
						title={data.filledQuantity.toString()}
						ellipsis={{ rows: 2 }}
					>
						{data.filledQuantity}
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			name: 'erpStatus',
			title: translate(translationsScope.erpStatus),
			fixed: false,
			className: 'column-table md',
			render: (data) => {
				const erpStatus = getErpStatusTranslation(data.erpStatus);
				return (
					<GenericTable.Column>
						<Paragraph
							className="text text--large ellipsis text--centered"
							title={erpStatus}
							ellipsis={{ rows: 2 }}
						>
							{erpStatus}
						</Paragraph>
					</GenericTable.Column>
				);
			},
		},
	];

	const tableLocale = {
		emptyText:
			!loading && hasSetFilters(selectedFilters) ? <FilterEmpty /> : false,
	};

	const onRow = (record) => ({
		className: `border-left--${record?.erpStatus?.toString()?.toLowerCase()}`,
	});

	return (
		<GenericTable.VirtualTable
			className="table-wrapper"
			columns={columns}
			loading={loading}
			pagination={{
				total,
				pageSize: pagination.limit,
				defaultPageSize: pagination.limit,
				current: pagination.start,
				onChange: handleOnChange,
				pageSizeOptions: ['20', '50', '100'],
			}}
			data={data}
			rowKey={(record) => record.id}
			rowClassName={({ erpStatus }) =>
				erpStatus !== ErpStates.Success ? 'table-colum--yellow' : ''
			}
			locale={tableLocale}
			onRow={onRow}
			rowSelection={{
				onChange: onChangeSelection,
				preserveSelectedRowKeys: false,
				selectedRowKeys,
				getCheckboxProps: (record) => ({
					disabled: record.erpStatus === ErpStates.Success,
				}), // Disable the checkbox if the erpStatus is 'Success'
			}}
		/>
	);
});
