import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { selectEnabledRegions } from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { fileRequest, genericRequest, httpMethod } from 'utils/request';

import { selectRegionInfo } from '../selectors';
import { hedgeMappingActions } from '../slice';
import { HedgeMappingRequest } from '../types';

const {
	downloadExcel,
	uploadExcelForHedgeMapAdmin,
	submitHedgeMap,
	hedgeMapRoot,
} = apiEndpoints;

export function* getHedgeMappingList(
	action: PayloadAction<HedgeMappingRequest>,
) {
	const { commodityId, regionId, errorTitle, errorDescription } =
		action.payload;
	const requestURL = yield new URL(`${apiRoutes.base}/${hedgeMapRoot}`);

	requestURL.searchParams.append('commodityId', commodityId);
	requestURL.searchParams.append('regionId', regionId);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
		null,
		false,
	);

	if (!!responseData) {
		yield put(hedgeMappingActions.hedgeMappingListLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.ERROR,
				title: errorTitle,
				message: errorDescription,
			}),
		);
		yield put(hedgeMappingActions.hedgeMappingListError(responseError));
	}
}

export function* downloadHedgeMapTemplate(action: PayloadAction<any>) {
	const params = action.payload;
	const requestURL = yield new URL(`${apiRoutes.base}/${downloadExcel}`);
	Object.entries(params).forEach(([key, value]) => {
		requestURL.searchParams.append(key, value);
	});

	const { responseData, responseError }: any = yield call(
		fileRequest,
		requestURL,
		httpMethod.Get,
		true,
	);

	// Success hedgeMappingActions
	if (!!responseData) {
		yield put(
			hedgeMappingActions.hedgeMapTemplateLoaded({
				...params,
				url: responseData,
			}),
		);
	} else if (!!responseError.detail) {
		yield put(hedgeMappingActions.hedgeMapTemplateError(responseError));
	}
}

export function* downloadFilledHedgemapRequest(action: PayloadAction<any>) {
	const params = action.payload;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${apiEndpoints.downloadUpdateHedgeMapTemplate}`,
	);

	Object.entries(params).forEach(([key, value]) => {
		requestURL.searchParams.append(key, value);
	});
	const { responseData, responseError }: any = yield call(
		fileRequest,
		requestURL,
		httpMethod.Get,
		true,
	);

	// Success hedgeMappingActions
	if (!!responseData) {
		yield put(hedgeMappingActions.downloadFilledHedgemapExcel(responseData));
	} else if (!!responseError.detail) {
		yield put(hedgeMappingActions.hedgeMapTemplateError(responseError));
	}
}

export function* uploadHedgeMapTemplateUpdated(action: PayloadAction<any>) {
	const requestURL = yield new URL(
		`${apiRoutes.base}/${uploadExcelForHedgeMapAdmin}`,
	);

	const { data, config } = action.payload;

	const { responseData, responseError }: any = yield call(
		fileRequest,
		requestURL,
		httpMethod.Post,
		true,
		data,
		config,
	);

	// Success hedgeMappingActions
	if (!!responseData) {
		yield put(hedgeMappingActions.hedgeMapTemplateUpdateUploaded(responseData));
	} else if (!!responseError.detail) {
		yield put(hedgeMappingActions.clearHedgeMapUpdateTemplate());
		yield put(hedgeMappingActions.hedgeMapUpdateTemplateError(responseError));
	}
}

export function* submitHedgeMapToServer(action: PayloadAction<any>) {
	const {
		data,
		config,
		successMessage,
		commodityId,
		errorTitle,
		errorDescription,
	} = action.payload;
	const regionId = yield select(selectRegionInfo);
	const regions = yield select(selectEnabledRegions);
	const requestURL = yield new URL(`${apiRoutes.base}/${submitHedgeMap}`);
	const { responseData, responseError }: any = yield call(
		fileRequest,
		requestURL,
		httpMethod.Post,
		true,
		data,
		config,
	);

	if (!!responseData) {
		yield put(hedgeMappingActions.setCurrentModal(null));
		yield put(
			hedgeMappingActions.loadHedgeMapList({
				commodityId: commodityId,
				errorTitle: errorTitle,
				errorDescription: errorDescription,
				regionId,
			}),
		);
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
			}),
		);
		const regionName = regions?.find((r) => r.id === regionId)?.name;
		yield put(
			hedgeMappingActions.downloadFilledHedgemapRequest({
				commodityId,
				name: 'HrvystHedgeMapTemplate',
				regionName: regionName,
			}),
		);
		yield put(hedgeMappingActions.clearHedgeMapUpdateTemplate());
	} else if (!!responseError.detail) {
		yield put(hedgeMappingActions.clearHedgeMapUpdateTemplate());
		yield put(hedgeMappingActions.hedgeMapUpdateTemplateError(responseError));
	}
}
