import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.liveLedger || initialState;

export const selectSelectedCommodity = createSelector(
	[selectDomain],
	(liveLedgerState) => liveLedgerState.selectedFilters.selectedCommodity,
);

export const selectSelectedCropYear = createSelector(
	[selectDomain],
	(liveLedgerState) => liveLedgerState.selectedFilters.selectedCropYear,
);

export const selectSelectedDate = createSelector(
	[selectDomain],
	(liveLedgerState) => liveLedgerState.selectedFilters.selectedDate,
);

export const selectSelectedRegion = createSelector(
	[selectDomain],
	(liveLedgerState) => liveLedgerState.selectedFilters.selectedRegion,
);

export const selectCropYearBalanceList = createSelector(
	[selectDomain],
	(liveLedgerState) => liveLedgerState.cropYearBalanceList,
);

export const selectLiveLedgerList = createSelector(
	[selectDomain],
	(liveLedgerState) => liveLedgerState.liveLedgerList.data,
);

export const selectLiveLedgerListLoading = createSelector(
	[selectDomain],
	(liveLedgerState) => liveLedgerState.liveLedgerList.loading,
);

export const selectEventList = createSelector(
	[selectDomain],
	(liveLedgerState) => liveLedgerState.events,
);

export const selectLiveLedgerTemplate = createSelector(
	[selectDomain],
	(liveLedgerState) => liveLedgerState.liveLedgerTemplate,
);
