import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { settingsActions } from '../slice';

const { locationsSettings } = apiEndpoints;

export function* getLocationsListForSettings() {
	let requestURL: any = yield new URL(`${apiRoutes.base}/${locationsSettings}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(
			settingsActions.locationsListForSettingsLoaded(responseData.locations),
		);
	} else if (!!responseError.detail) {
		yield put(settingsActions.locationsListForSettingsError(responseError));
	}
}
