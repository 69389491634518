import './style.scss';

import { Col, Row } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Collapsible } from 'app/components/Collapsible';
import { FilterAutocomplete } from 'app/components/FilterAutocomplete';
import { FilterInput } from 'app/components/FilterInput';
import { FilterMultiple } from 'app/components/FilterMultiple';
import { FilterSingle } from 'app/components/FilterSingle';
import {
	OrderDirectionOptions,
	OrderStatus,
	ViewOnlyContractTypeIds,
	ViewOnlyContractTypeOptions,
} from 'app/containers/Contracts/types';
import {
	selectCommoditiesList,
	selectContractTypeCatalog,
	selectCustomersList,
	selectDestinationsList,
	selectEmployeesList,
	selectEventList,
} from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { translations } from 'locales/i18n';
import { FeatureFlag } from 'types/FeatureFlags';
import { GenericOption } from 'types/GenericOption';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import { FILTERS_FOR_CONTRACTS } from 'utils/constants';
import {
	GetPersistedData,
	mapToLabelValue,
	mapToLabelValueObject,
	UpdatePersistedData,
} from 'utils/helpers';

import {
	selectContractsFuturesMonth,
	selectSelectedFilters,
} from '../../../../../selectors';
import { actions } from '../../../../../slice';

const allDefaultOption = {
	id: 'all',
	name: '',
};

export const Filters = memo(() => {
	const sourceName = GlobalSagaSource.contractModal;
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const featureFlags = useFlags();
	const commoditiesList = useSelector(selectCommoditiesList);
	const contractTypesList = useSelector(selectContractTypeCatalog);
	const customers = useSelector(selectCustomersList);
	const eventList = useSelector(selectEventList);
	const selectedFilters = useSelector(selectSelectedFilters);
	const { data: destinationList = [], loading: loadingDestinations = false } =
		useSelector(selectDestinationsList) || { data: [], loading: false };
	// Get the persisted filter DATA from localstorage and load the contract grid

	const persistedFilters = GetPersistedData<null | typeof selectedFilters>(
		FILTERS_FOR_CONTRACTS,
		null,
	);

	const getFilteredContractList = useCallback(() => {
		dispatch(
			actions.setPagination({
				start: 1,
			}),
		);

		dispatch(actions.loadContractList());
	}, []);

	// Apply persisted filters
	useEffect(() => {
		dispatch(actions.setSelectedFilters(persistedFilters));
		getFilteredContractList();
		dispatch(actions.loadContractsFuturesMonthsList({ source: sourceName }));
	}, []);

	const catalogFuturesMonths = useSelector(selectContractsFuturesMonth);
	const { data: catFMlist = [], loading: catFMloading } =
		(catalogFuturesMonths && catalogFuturesMonths[sourceName]) || {
			data: [],
			loading: false,
		};
	const getTranslation = (key: string, subKey: string) =>
		translate(
			translations.app.containers.Contracts.ListMenu.filters[key][subKey],
		);

	const [futuresMonthsOptions, setFuturesMonthsOptions] = useState<
		GenericOption[]
	>(() => []);
	const [destinationOptions, setDestinationOptions] = useState<GenericOption[]>(
		() => [],
	);

	useEffect(() => {
		if (!catFMloading && catFMlist.length > 0) {
			setFuturesMonthsOptions([
				mapToLabelValueObject(allFuturesMonthsOption),
				...catFMlist,
			]);
		}
	}, [catalogFuturesMonths]);

	useEffect(() => {
		if (!loadingDestinations && destinationList.length > 0) {
			setDestinationOptions(
				mapToLabelValue([
					{
						...allDefaultOption,
						name: getTranslation('destinations', 'all'),
					},
					...destinationList,
				]),
			);
		}
	}, [destinationList]);

	const customersList = customers?.[sourceName]?.data || [];
	const customersLoading = customers?.[sourceName]?.loading || false;
	const allCommoditiesOption = {
		...allDefaultOption,
		name: getTranslation('commodities', 'all'),
	};
	const commoditiesOptions = mapToLabelValue([
		allCommoditiesOption,
		...commoditiesList,
	]);
	const customerOptions = mapToLabelValue(customersList);
	const allContractTypesOption = {
		...allDefaultOption,
		name: getTranslation('contract', 'all'),
	};

	const contractTypesOptions = mapToLabelValue([
		allContractTypesOption,
		...contractTypesList,
	]);
	const transactionTypeOptions = mapToLabelValue([
		{
			...allDefaultOption,
			name: getTranslation('transaction', 'all'),
		},
		{
			id: ViewOnlyContractTypeIds.Overfill,
			name: ViewOnlyContractTypeOptions.Overfill,
		},
		{
			id: ViewOnlyContractTypeIds.Underfill,
			name: ViewOnlyContractTypeOptions.Underfill,
		},
		{
			id: ViewOnlyContractTypeIds.SpotContract,
			name: ViewOnlyContractTypeOptions.SpotContract,
		},
	]);

	const employees = useSelector(selectEmployeesList);
	const { data: employeesList = [], loading: employeesLoading } = (employees &&
		employees[sourceName]) || { data: [], loading: false };

	const allOrderDirectionOptions = [
		{
			...allDefaultOption,
			name: translate(
				translations.app.containers.Contracts.ListMenu.filters.buyOrSell.all,
			),
		},
		{
			name: OrderDirectionOptions.Buy,
			id: OrderDirectionOptions.Buy,
		},
		{
			name: OrderDirectionOptions.Sell,
			id: OrderDirectionOptions.Sell,
		},
	];
	const orderDirectionOptions = mapToLabelValue(allOrderDirectionOptions);
	const allEventOption = {
		...allDefaultOption,
		name: getTranslation('event', 'all'),
	};
	const eventOptions = mapToLabelValue([allEventOption, ...eventList]);

	const statusOptions = [
		{
			label: getTranslation('status', 'all'),
			value: 'all',
		},

		{
			label: OrderStatus.Open,
			value: OrderStatus.Open,
		},
		{
			label: OrderStatus.Priced,
			value: OrderStatus.Priced,
		},
	];

	const allFuturesMonthsOption = {
		...allDefaultOption,
		name: translate(
			translations.app.containers.Contracts.ListMenu.filters.futuresMonth.all,
		),
	};

	const handleChangeCommodities = (selectedValues) => {
		UpdatePersistedData(FILTERS_FOR_CONTRACTS, selectedFilters, {
			commodities: selectedValues,
		});

		dispatch(
			actions.setSelectedFilters({
				commodities: selectedValues,
			}),
		);

		getFilteredContractList();
	};

	const handleChangeDestination = (selectedValues) => {
		UpdatePersistedData(FILTERS_FOR_CONTRACTS, selectedFilters, {
			destinations: selectedValues,
		});

		dispatch(
			actions.setSelectedFilters({
				destinations: !!selectedValues ? selectedValues : null,
			}),
		);

		getFilteredContractList();
	};

	const handleChangeCustomer = (selectedValue) => {
		UpdatePersistedData(FILTERS_FOR_CONTRACTS, selectedFilters, {
			customerId: selectedValue,
		});

		dispatch(
			actions.setSelectedFilters({
				customerId: selectedValue,
			}),
		);

		if (!selectedValue.length) {
			dispatch(
				globalActions.clearCustomersList({
					source: sourceName,
				}),
			);
		}

		getFilteredContractList();
	};

	const handleSearchCustomer = (searchValue) => {
		if (searchValue.length) {
			dispatch(
				globalActions.loadCustomersList({
					search: searchValue,
					source: sourceName,
				}),
			);
		} else if (!searchValue.length) {
			dispatch(globalActions.clearCustomersList({ source: sourceName }));
		} else if (selectedFilters.customerId) {
			UpdatePersistedData(FILTERS_FOR_CONTRACTS, selectedFilters, {
				customerId: null,
			});
			dispatch(
				actions.setSelectedFilters({
					customerId: null,
				}),
			);

			dispatch(globalActions.clearCustomersList({ source: sourceName }));

			getFilteredContractList();
		}
	};

	const handleChangeContractType = (selectedValues) => {
		UpdatePersistedData(FILTERS_FOR_CONTRACTS, selectedFilters, {
			contractTypeId: selectedValues,
		});

		dispatch(
			actions.setSelectedFilters({
				contractTypeId: selectedValues,
			}),
		);

		getFilteredContractList();
	};

	const handleChangeEvent = (selectedValues) => {
		UpdatePersistedData(FILTERS_FOR_CONTRACTS, selectedFilters, {
			eventId: selectedValues,
		});
		dispatch(
			actions.setSelectedFilters({
				eventId: selectedValues,
			}),
		);

		getFilteredContractList();
	};

	const handleOnSearchContract = (value) => {
		UpdatePersistedData(FILTERS_FOR_CONTRACTS, selectedFilters, {
			number: value,
		});
		dispatch(
			actions.setSelectedFilters({
				number: value,
			}),
		);

		getFilteredContractList();
	};

	const handleChangeStatus = (selectedValue) => {
		UpdatePersistedData(FILTERS_FOR_CONTRACTS, selectedFilters, {
			statusView: selectedValue,
		});
		dispatch(
			actions.setSelectedFilters({
				statusView: selectedValue,
			}),
		);

		getFilteredContractList();
	};

	const handleChangeFuturesMonth = (value) => {
		UpdatePersistedData(FILTERS_FOR_CONTRACTS, selectedFilters, {
			futuresMonths: value || null,
		});

		dispatch(
			actions.setSelectedFilters({
				futuresMonths: !!value ? value : null,
			}),
		);

		getFilteredContractList();
	};

	const employeeOptions = mapToLabelValue(employeesList);

	const handleChangeEmployee = (selectedValue) => {
		UpdatePersistedData(FILTERS_FOR_CONTRACTS, selectedFilters, {
			employeeId: selectedValue,
		});
		dispatch(
			actions.setSelectedFilters({
				employeeId: selectedValue,
			}),
		);
		if (!selectedValue.length)
			dispatch(globalActions.clearEmployeesList({ source: sourceName }));

		getFilteredContractList();
	};

	const handleSearchEmployee = (searchValue) => {
		if (searchValue.length) {
			dispatch(
				globalActions.loadEmployeesList({
					search: searchValue,
					source: sourceName,
				}),
			);
		} else if (!searchValue.length) {
			dispatch(globalActions.clearEmployeesList({ source: sourceName }));
		} else if (selectedFilters.employeeId) {
			UpdatePersistedData(FILTERS_FOR_CONTRACTS, selectedFilters, {
				employeeId: null,
			});
			dispatch(
				actions.setSelectedFilters({
					employeeId: null,
				}),
			);
			dispatch(globalActions.clearEmployeesList({ source: sourceName }));
			getFilteredContractList();
		}
	};

	const handleChangeOrderDirection = (selectedValue) => {
		UpdatePersistedData(FILTERS_FOR_CONTRACTS, selectedFilters, {
			orderDirection: selectedValue,
		});

		dispatch(
			actions.setSelectedFilters({
				orderDirection: selectedValue,
			}),
		);

		getFilteredContractList();
	};

	const handleChangeTransactionType = (selectedValue) => {
		UpdatePersistedData(FILTERS_FOR_CONTRACTS, selectedFilters, {
			transactionTypeId: selectedValue,
		});
		dispatch(
			actions.setSelectedFilters({
				transactionTypeId: selectedValue,
			}),
		);

		getFilteredContractList();
	};
	const newFiltersLayout = featureFlags[FeatureFlag.enableNewFilterLayout];
	return (
		<>
			<section className="grid-main-filters">
				<div className="grid-menu-label">
					{translate(translations.app.containers.Contracts.ListMenu.label)}
				</div>
				<Row gutter={newFiltersLayout ? [8, 8] : [16, { sm: 16, md: 0 }]}>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3 }
							: { sm: 4, md: 3 })}
					>
						<FilterMultiple
							currentOptions={
								selectedFilters.commodities || [commoditiesOptions[0]]
							}
							handleChange={handleChangeCommodities}
							options={commoditiesOptions}
							placeholder={getTranslation('commodities', 'all')}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3 }
							: { sm: 4, md: 3 })}
					>
						<FilterAutocomplete
							currentOption={selectedFilters.customerId}
							handleChange={handleChangeCustomer}
							handleSearch={handleSearchCustomer}
							loading={customersLoading}
							maxTagTextLength={30}
							notFoundContent={
								!customersLoading && !customerOptions.length
									? translate(
											translations.app.containers.Contracts.ListMenu.filters
												.notfound,
										)
									: null
							}
							options={customerOptions}
							placeholder={getTranslation('customer', 'all')}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3 }
							: { sm: 4, md: 3 })}
					>
						<FilterSingle
							currentOption={selectedFilters.contractTypeId}
							handleChange={handleChangeContractType}
							options={contractTypesOptions}
							placeholder={getTranslation('contract', 'all')}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3 }
							: { sm: 4, md: 3 })}
					>
						<FilterMultiple
							currentOptions={
								selectedFilters.destinations || [destinationOptions[0]]
							}
							handleChange={handleChangeDestination}
							options={destinationOptions}
							placeholder={getTranslation('destinations', 'placeholder')}
						/>
					</Col>
				</Row>
			</section>

			<Collapsible
				content={
					<Row gutter={newFiltersLayout ? [8, 8] : [16, { sm: 16, md: 0 }]}>
						<Col
							{...(newFiltersLayout
								? { xs: 12, md: 6, lg: 3 }
								: { sm: 4, md: 3, lg: 2 })}
						>
							<FilterInput
								className="search-contract"
								currentOption={selectedFilters.number || ''}
								onSearch={handleOnSearchContract}
								placeholder={getTranslation('contract', 'searchContractLabel')}
							/>
						</Col>
						<Col
							{...(newFiltersLayout
								? { xs: 12, md: 6, lg: 3 }
								: { sm: 4, md: 2 })}
						>
							<FilterMultiple
								options={futuresMonthsOptions}
								handleChange={handleChangeFuturesMonth}
								currentOptions={
									selectedFilters.futuresMonths || [futuresMonthsOptions[0]]
								}
								placeholder={getTranslation('futuresMonth', 'placeHolder')}
							/>
						</Col>
						<Col
							{...(newFiltersLayout
								? { xs: 12, md: 6, lg: 3 }
								: { sm: 4, md: 3, lg: 2 })}
						>
							<FilterAutocomplete
								maxTagTextLength={30}
								options={employeeOptions}
								handleChange={handleChangeEmployee}
								handleSearch={handleSearchEmployee}
								loading={employeesLoading}
								currentOption={selectedFilters?.employeeId || []}
								placeholder={translate(
									translations.app.containers.Contracts.ListMenu.filters
										.employees.searchLabel,
								)}
								notFoundContent={
									!employeesLoading && !employeeOptions.length
										? translate(
												translations.app.containers.Contracts.ListMenu.filters
													.notfound,
											)
										: null
								}
							/>
						</Col>
						<Col
							{...(newFiltersLayout
								? { xs: 12, md: 6, lg: 3 }
								: { sm: 4, md: 2 })}
						>
							<FilterSingle
								currentOption={selectedFilters.transactionTypeId}
								handleChange={handleChangeTransactionType}
								options={transactionTypeOptions}
								placeholder={getTranslation('transaction', 'placeHolder')}
							/>
						</Col>
						<Col
							{...(newFiltersLayout
								? { xs: 12, md: 6, lg: 3 }
								: { sm: 4, md: 2 })}
						>
							<FilterSingle
								currentOption={selectedFilters.eventId}
								handleChange={handleChangeEvent}
								options={eventOptions}
								placeholder={getTranslation('event', 'all')}
							/>
						</Col>
						<Col
							{...(newFiltersLayout
								? { xs: 12, md: 6, lg: 3 }
								: { sm: 4, md: 1 })}
						>
							<FilterSingle
								currentOption={selectedFilters.statusView}
								handleChange={handleChangeStatus}
								options={statusOptions}
								placeholder={getTranslation('status', 'label')}
							/>
						</Col>
						<Col
							{...(newFiltersLayout
								? { xs: 12, md: 6, lg: 3 }
								: { sm: 4, md: 1 })}
						>
							<FilterSingle
								options={orderDirectionOptions}
								handleChange={handleChangeOrderDirection}
								currentOption={[
									selectedFilters.orderDirection?.[0] ||
										orderDirectionOptions[0],
								]}
								placeholder={translate(
									translations.app.containers.Offers.components.Views.List
										.ListMenu.Filters.buyOrSell.placeHolder,
								)}
							/>
						</Col>
					</Row>
				}
				inlineTitle={true}
				collapsibleKey="contracts-filter-more"
			/>
		</>
	);
});
