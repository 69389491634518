import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { compareNumbers } from 'app/containers/Contracts/utils/helper';
import { selectDefaultPageData } from 'app/containers/GlobalSaga/selectors';
import {
	selectActivePreHedge,
	selectContractStateFromPreHedge,
} from 'app/containers/PreHedge/selectors';
import { AssignedRegion } from 'app/containers/Transactions/components/AssignedRegion';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractNumber } from 'app/containers/Transactions/components/ContractNumber';
import { ContractType } from 'app/containers/Transactions/components/ContractType';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { Customer } from 'app/containers/Transactions/components/Customer';
import { DeliveryDates } from 'app/containers/Transactions/components/DeliveryDates';
import { DeliveryLocation } from 'app/containers/Transactions/components/DeliveryLocation';
import { Employee } from 'app/containers/Transactions/components/Employee';
import { ExpirationDate } from 'app/containers/Transactions/components/ExpirationDate';
import { Fees } from 'app/containers/Transactions/components/Fees';
import { Freight } from 'app/containers/Transactions/components/Freight';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Location } from 'app/containers/Transactions/components/Location';
import { NetFutures } from 'app/containers/Transactions/components/NetFutures';
import { PassFill } from 'app/containers/Transactions/components/PassFill';
import { Quantity } from 'app/containers/Transactions/components/Quantity';
import { TheirContract } from 'app/containers/Transactions/components/TheirContract';
import { Transaction } from 'app/containers/Transactions/components/Transaction';
import { useOrderEntriesFormSchema } from 'app/containers/Transactions/hooks/useOrderEntriesFormSchema';
import { ActionType } from 'types/ActionType';
import { DeliveryDateMode } from 'types/DeliveryDateMode';
import { CONSTANTS } from 'utils/constants';
import {
	isDeliveryDateCustom,
	isDeliveryDateWindow,
	validateQuarterCents,
} from 'utils/helpers';
import { isEmptyObject } from 'utils/validators';

import {
	validateMinQuantity,
	validatePositiveValue,
	validatePrice,
} from '../../../shared/helpers';
import { useTranslations } from '../../../shared/useTranslations';

export const useHTASchema = (
	translations: ReturnType<typeof useTranslations>,
) => {
	const defaultData = useSelector(selectDefaultPageData);
	const contractStateFromPreHedge = useSelector(
		selectContractStateFromPreHedge,
	);
	const preHedgeData = useSelector(selectActivePreHedge);

	const shouldDisabled = !isEmptyObject(contractStateFromPreHedge);

	const { elements, initialValues, validationSchema } =
		useOrderEntriesFormSchema();

	const quantityErrorMsg =
		translations.validations.createContractMinQuantityValidation;
	const numberMsg = translations.validations.number;

	const requiredMsg = translations.validations.required;
	const futuresPriceFormatError =
		translations.validations.futuresPriceFormatError;

	const nonNegativeMsg = translations.validations.nonNegativeMsg;

	const validation = useMemo(
		() =>
			Yup.object().shape({
				transaction: Yup.object().requiredOption(requiredMsg),
				contractNumber: Yup.string().nullable(),
				contract: Yup.object().requiredOption(requiredMsg),
				commodity: Yup.object().requiredOption(requiredMsg),
				location: Yup.object().requiredOption(requiredMsg),
				deliveryLocation: Yup.object().requiredOption(requiredMsg),
				assignedRegion: Yup.object().requiredOption(requiredMsg),
				deliveryDate: Yup.mixed().when('deliveryDatesMode', {
					is: (mode) => isDeliveryDateCustom(mode),
					then: Yup.mixed().required(requiredMsg),
				}),
				deliveryDateWindow: Yup.mixed().when('deliveryDatesMode', {
					is: (mode) => isDeliveryDateWindow(mode),
					then: Yup.object().requiredOption(requiredMsg),
				}),
				freight: Yup.number().typeError(numberMsg).nullable(),
				fees: Yup.number().typeError(numberMsg),
				netFutures: Yup.number().typeError(numberMsg).required(requiredMsg),
				quantity: Yup.string()
					.typeError(numberMsg)
					.required(requiredMsg)
					.test('minValidation', quantityErrorMsg, (value: string) =>
						validateMinQuantity(value),
					)
					.test(
						'maxValidation',
						'Quantity must be lower or equal to available Max balance',
						(value) => {
							return compareNumbers(
								value,
								preHedgeData?.max,
								CONSTANTS.FIXED_QUANTITY_DECIMALS,
							);
						},
					),
				customer: Yup.object().requiredOption(requiredMsg),
				employee: Yup.object().requiredOption(requiredMsg),
				futuresMonth: Yup.object().requiredOption(requiredMsg),
				futuresPrice: Yup.number()
					.typeError(requiredMsg)
					.required(requiredMsg)
					.test('priceValidation', futuresPriceFormatError, (value: string) =>
						validatePrice(value),
					)
					.test('nonNegativeValues', nonNegativeMsg, (value: string) =>
						validatePositiveValue(value),
					),
				...validationSchema,
			}),
		[
			validationSchema,
			quantityErrorMsg,
			numberMsg,
			requiredMsg,
			futuresPriceFormatError,
			nonNegativeMsg,
			preHedgeData?.max,
		],
	);

	return useMemo(
		() => ({
			initialValues: {
				action: ActionType.SELL,
				commodity: { value: null },
				location: { value: null },
				deliveryLocation: { value: null },
				assignedRegion: { value: preHedgeData?.regionId },
				deliveryDate: null,
				deliveryDatesMode: { value: DeliveryDateMode.Custom },
				deliveryDateWindow: { value: null },
				cropYear: null,
				futuresMonth: { value: null },
				futuresPrice: null,
				postedBasis: null,
				pushBasis: null,
				netBasis: null,
				freight: null,
				fees1: null,
				fees2: null,
				flatPrice: null,
				netFutures: null,
				netBasisPrice: null,
				quantity: null,
				gtcMode: false,
				expirationDate: '',
				customer: { value: null },
				employee: {
					label: defaultData?.employeeName,
					value: defaultData?.employeeId,
				},
				comments: '',
				basis: null,
				futures: null,
				passFill: false,
				doNotHedge: false,
				useServiceFees: false,
				max: preHedgeData?.max,
				...initialValues,
			},
			validationSchema: validation,
			elements: (
				<>
					<TheirContract />
					<Transaction />
					<ContractNumber disabled={false} />
					<ContractType
						disabled={shouldDisabled}
						disableBuySell={shouldDisabled}
					/>
					<Commodity checkDefaultValues disabled={shouldDisabled} />
					<Location />
					<DeliveryLocation />
					<AssignedRegion />
					<CropYears
						checkDefaultValues={!preHedgeData}
						disabled={shouldDisabled}
					/>
					<DeliveryDates
						checkDefaultValues
						modeSelectorDisabled
						forceToCustom
						useServiceFees
					/>
					<Quantity showMax={shouldDisabled} maxQuantity={preHedgeData?.max} />
					<PassFill hedgeable />
					<Futures
						checkDefaultValues
						useRoundingRules
						usePriceFromCache
						futuresMonth={{ market: true, disabled: shouldDisabled }}
						futuresPrice={{
							market: preHedgeData ? false : true,
							disabled: shouldDisabled,
						}}
					/>
					<ExpirationDate />
					<Freight />
					<Fees />
					<NetFutures />
					<Customer />
					<Employee />
					{elements}
					<Comments />
				</>
			),
		}),
		[
			defaultData,
			preHedgeData,
			shouldDisabled,
			elements,
			initialValues,
			validation,
		],
	);
};
