import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GenericTable } from 'app/components/GenericTable';
import { translations } from 'locales/i18n';

import { selectListLoading, selectUserRolesListData } from '../../../selectors';
import { Delete } from './Delete';
import { Edit } from './Edit';

const { Paragraph } = Typography;

export const TableWrapper = () => {
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.UserRoles;

	const listData = useSelector(selectUserRolesListData);
	const listLoading = useSelector(selectListLoading);

	const rows = { rows: 2 };
	const widthEdit = 20;

	const columns = [
		{
			title: translate(translationsScope.View.List.Table.RolesNameHeader),
			className: 'column-table',
			dataIndex: 'name',
			key: 'name',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={rows}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: '',
			className: 'column-table',
			fixed: 'right' as 'right',
			width: widthEdit,
			render: (data) => (
				<GenericTable.Column className="edit">
					<Edit rowData={data} />
				</GenericTable.Column>
			),
		},
		{
			title: '',
			className: 'column-table',
			fixed: 'right' as 'right',
			width: widthEdit,
			render: (data) => (
				<GenericTable.Column className="last-column">
					<Delete rowData={data} />
				</GenericTable.Column>
			),
		},
	];

	return (
		<>
			<GenericTable.Table
				otherClassName="table-container"
				columns={columns}
				loading={listLoading}
				data={listData}
				pagination={false}
			/>
		</>
	);
};
