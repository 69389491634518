import React from 'react';
import { useSelector } from 'react-redux';

import { UseFieldTranslations } from 'app/containers/Settings/definitions/fields/translations';
import { mapToLabelValue, transFormToLabelValue } from 'utils/helpers';
import Yup from 'yupCustom';

import { Address } from '../../../components/Address';
import { Cellphone } from '../../../components/Cellphone';
import { Email } from '../../../components/Email';
import { ErpNumber } from '../../../components/ErpNumber';
import { MobileApp } from '../../../components/MobileApp';
import { Name } from '../../../components/Name';
import { Notify } from '../../../components/Notify';
import { selectCurrentCustomerData } from '../../../selectors';

export const useEditCustomerSchema = () => {
	const activeCustomer = useSelector(selectCurrentCustomerData);
	const translations = UseFieldTranslations();
	const validEmail = translations.validationSchema.invalidEmail;
	let initialValues = {};

	if (activeCustomer) {
		const {
			number,
			firstName,
			lastName,
			phoneNumber,
			street,
			zipCode,
			country,
			city,
			email,
			state,
			isMobileAppEnable,
			mobileAppLimit,
			isEmailNotificationEnabled,
			isSmsNotificationEnabled,
		} = activeCustomer;
		initialValues = {
			address: street,
			cellphone: parseInt(phoneNumber) || null,
			city,
			country: mapToLabelValue(transFormToLabelValue([country])),
			email,
			erpNumber: String(number),
			firstName,
			lastName,
			state: mapToLabelValue(transFormToLabelValue([state])),
			zipCode,
			isMobileAppEnable,
			mobileAppLimit,
			isEmailNotificationEnabled,
			isSmsNotificationEnabled,
		};
	}

	return {
		initialValues,
		validationSchema: Yup.object().shape({
			address: Yup.string().nullable(),
			city: Yup.string().nullable(),
			cellphone: Yup.string().nullable(),
			country: Yup.mixed(),
			email: Yup.string().email(validEmail).nullable(),
			erpNumber: Yup.string().nullable(),
			firstName: Yup.string().nullable(),
			lastName: Yup.string().nullable(),
			state: Yup.mixed(),
			zipCode: Yup.string().nullable(),
			mobileAppLimit: Yup.string().nullable(),
		}),
		elements: [
			<ErpNumber disabled />,
			<Name />,
			<Cellphone />,
			<Address />,
			<Email />,
			<MobileApp />,
			<Notify />,
		],
	};
};
