import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { BidsheetPublish } from '../Bidsheet/Modals/BidsheetPublish';
import { RollOrphan } from '../Bidsheet/Modals/RollOrphan';
import { UploadBidsheet } from '../Bidsheet/Modals/UploadBisheet';
import { bidsheetSaga } from './sagas';
import { selectCurrentModal } from './selectors';
import { actions, reducer, sliceKey } from './slice';
import { Modal } from './types';
import { List } from './View/List';

export const Bidsheet = () => {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: bidsheetSaga });

	const currentModal = useSelector(selectCurrentModal);
	const dispatch = useDispatch();

	const handleRollOrphanClose = () => {
		dispatch(actions.setCurrentModal(null));
	};

	useEffect(() => {
		return () => {
			actions.resetContainer();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<List />
			{currentModal === Modal.Upload && <UploadBidsheet />}
			{currentModal === Modal.RollOrphan && (
				<RollOrphan handleClose={handleRollOrphanClose} />
			)}
			{currentModal === Modal.Publish && <BidsheetPublish />}
		</>
	);
};
