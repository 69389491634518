/**
 *
 * OrdersCancel
 *
 */
import './style.scss';

import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { translations } from 'locales/i18n';

import { selectActiveOffer } from '../../../selectors';
import { actions } from '../../../slice';

export const CancelModal = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const activeOffer: any = useSelector(selectActiveOffer);

	const setText = () => {
		if (activeOffer?.contract?.number) {
			const number = activeOffer.contract.number;
			return (
				<p className="orders-cancel-modal__text--bold">
					{translate(
						translations.app.containers.Offers.components.CancelOffer
							.cancelMessage,
						{ number },
					)}
				</p>
			);
		} else {
			return (
				<p data-testid="default-message">
					{translate(
						translations.app.containers.Offers.components.CancelOffer
							.defaultCancelMessage,
					)}
				</p>
			);
		}
	};

	const handleConfirmation = () => {
		activeOffer && dispatch(actions.cancelOffer(activeOffer.id));
		dispatch(actions.setCurrentModal(null));
	};

	const handleCancel = () => {
		dispatch(actions.setCurrentModal(null));
	};
	return (
		<Modal
			maskClosable={false}
			className="orders-cancel-modal"
			title={translate(
				translations.app.containers.Offers.components.CancelOffer
					.cancelModalTitle,
			)}
			visible={true}
			onOk={handleConfirmation}
			onCancel={handleCancel}
			footer={[
				<Button
					key="back"
					onClick={handleCancel}
					className="return-button"
					data-testid="return-button"
				>
					{translate(
						translations.app.containers.Offers.components.CancelOffer.cancel,
					)}
				</Button>,
				<Button
					data-testid="confirm-button"
					key="submit"
					type="primary"
					onClick={handleConfirmation}
					className="cancel-button"
				>
					{translate(
						translations.app.containers.Offers.components.CancelOffer.confirm,
					)}
				</Button>,
			]}
		>
			{activeOffer && setText()}
		</Modal>
	);
};
