import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { contractTypesRoot } = apiEndpoints;

export function* getContractTypeCatalog() {
	const requestURL = yield new URL(`${apiRoutes.base}/${contractTypesRoot}`);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.contractTypeCatalogLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.contractTypeCatalogError(responseError));
	}
}
