import { takeLatest } from 'redux-saga/effects';

import {
	getFuturesErpList,
	getFuturesMonths,
	integrateToErp,
} from './sagas/futuresErp-saga';
import { actions } from './slice';

export function* FuturesErpSaga() {
	yield takeLatest(actions.loadFuturesErpList.type, getFuturesErpList);

	yield takeLatest(actions.integrateToErpRequest.type, integrateToErp);

	yield takeLatest(actions.getFuturesMonthsRequest.type, getFuturesMonths);
}
