import { FieldValues } from 'react-hook-form';

export const getFormValues = (
	id: string,
	{
		comments,
		futuresMonth,
		futuresPrice,
		hedgeAccount,
		orderNumber,
	}: FieldValues,
) => ({
	comments,
	futuresMonth: futuresMonth?.value,
	futuresPrice,
	hedgeAccountId: hedgeAccount?.value,
	id,
	orderNumber,
});
