import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { FutureMonth } from 'types/FutureMonth';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { selectSelectedFilters } from '../selectors';
import { actions } from '../slice';
import { IntegrateToErpDataType } from '../types';

const { erpTradeBook, erpBookMatch, futuresMonthOptions } = apiEndpoints;

export function* getTradeBookList() {
	const requestURL = yield new URL(`${apiRoutes.base}/${erpTradeBook}`);
	const selectedFilters = yield select(selectSelectedFilters);

	const filters = {
		...selectedFilters,
		accountNo:
			selectedFilters.accountNo?.find((element) => element.value !== 'all')
				?.label || '',
		commodityId:
			selectedFilters.commodityId?.find((element) => element.value !== 'all')
				?.value || '',
		futuresMonth:
			selectedFilters.futuresMonth?.find((element) => element.value !== 'all')
				?.value || '',
	};

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		filters,
	);

	if (!!responseData) {
		yield put(
			actions.tradeBookListLoaded({
				data: responseData,
				total: responseData?.total,
				erpErrorMessage: responseData?.erpErrorMessage,
			}),
		);
	} else if (!!responseError.detail) {
		yield put(actions.tradeBookListError(responseError));
	}
}

export function* integrateToErp(
	action: PayloadAction<IntegrateToErpDataType[]>,
) {
	const requestURL = yield new URL(`${apiRoutes.base}/${erpBookMatch}`);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		action.payload,
	);

	if (!!responseData) {
		yield put(actions.integrateToErpSuccess());

		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(actions.loadTradeBookList());
		yield put(actions.setSelectedBuyRowsAsEmpty());
		yield put(actions.setSelectedSellRowsAsEmpty());
	} else if (!!responseError.detail) {
		yield put(actions.integrateToErpError(responseError));
	}
}

export function* getFuturesMonths() {
	const requestURL = yield new URL(`${apiRoutes.base}/${futuresMonthOptions}`);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		const data: FutureMonth[] = responseData.map((item) => ({
			id: item.name,
			...item,
		}));

		yield put(actions.getFuturesMonthsSuccess(data));
	} else if (!!responseError) {
		yield put(actions.getFuturesMonthsError(responseError));
	}
}
