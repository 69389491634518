import { takeLatest } from 'redux-saga/effects';

import {
	getFuturesMonths,
	getTradeBookList,
	integrateToErp,
} from './sagas/tradeBook-saga';
import { actions } from './slice';

export function* TradeBookSaga() {
	yield takeLatest(actions.loadTradeBookList.type, getTradeBookList);

	yield takeLatest(actions.integrateToErpRequest.type, integrateToErp);

	yield takeLatest(actions.getFuturesMonthsRequest.type, getFuturesMonths);
}
