import './style.scss';

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { translations } from 'locales/i18n';
interface Props {
	disabled?: boolean;
}

export const UnfilledLots = memo((props: Props) => {
	const { disabled } = props;
	const { t: translate } = useTranslation();
	const msg = translations.app.containers.Transactions.components.UnfilledLots;

	return (
		<GenericForm.FormItem
			label={translate(msg.UnfilledLots)}
			name="unfilledLots"
			customClassName={['input-unfilledLots__label']}
			data-testid="unfilledLots-form-item"
		>
			<Input
				className="input-unfilledLots__input"
				key="unfilledLots"
				name="unfilledLots"
				placeholder={translate(msg.placeholder)}
				type="text"
				disabled={disabled}
			/>
		</GenericForm.FormItem>
	);
});
