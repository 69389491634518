import { takeLatest } from 'redux-saga/effects';

import { createCustomer } from './sagas/addCustomers-saga';
import { getCountriesList, getStatesList } from './sagas/countriesStates-saga';
import { getCustomersList } from './sagas/customers-saga';
import { editCustomer, getCustomerById } from './sagas/editCustomer-saga';
import { setCustomerStatus } from './sagas/setCustomerStatus-saga';
import { actions } from './slice';

export function* customersSaga() {
	// yield takeLatest(actions.someAction.type, doSomething);

	// Watches for createEditCustomer actions and calls createEditCustomer when one comes in.

	yield takeLatest(actions.createCustomer.type, createCustomer);

	// Watches for editCustomer actions and calls editCustomer when one comes in.
	yield takeLatest(actions.editCustomer.type, editCustomer);

	// Watches for getCountriesList actions and calls getCountriesList when one comes in.
	yield takeLatest(actions.getCountriesList.type, getCountriesList);

	// Watches for getStatesList actions and calls getStatesList when one comes in.
	yield takeLatest(actions.getStatesList.type, getStatesList);

	yield takeLatest(actions.loadCustomersList.type, getCustomersList);

	yield takeLatest(actions.setCustomerStatus.type, setCustomerStatus);

	yield takeLatest(actions.setActiveCustomer.type, getCustomerById);
}
