import { takeLatest } from 'redux-saga/effects';

import { getDNHListSaga, setDNHListSaga } from './sagas/activate-dnh-saga';
import { actions } from './slice';

export function* activateDNHModalSaga() {
	yield takeLatest(actions.getDNHListRequest.type, getDNHListSaga);

	yield takeLatest(actions.setDNHListRequest.type, setDNHListSaga);
}
