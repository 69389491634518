import './style.scss';

import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GenericTable } from 'app/components/GenericTable';
import { SwitchStateFull } from 'app/components/SwitchStateFull';
import { translations } from 'locales/i18n';
import { CONTRACT_TYPES_GUIDS, ROUNDING_TYPES_GUIDS } from 'utils/constants';
import { formatPriceHelper } from 'utils/helpers';

import {
	selectCurrentRoundingRuleError,
	selectFilters,
	selectListData,
	selectListLoading,
	selectListTotal,
	selectPaginationLimit,
	selectPaginationStart,
} from '../../../selectors';
import { actions } from '../../../slice';
import { Actions } from './Actions';
import { Delete } from './Delete';
const { Paragraph } = Typography; //

export const Table = () => {
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.RoundingRules.View.List.Table;
	const translationsShared = translations.app.containers.Settings.Shared;

	const dispatch = useDispatch();
	const listData = useSelector(selectListData);
	const listTotal = useSelector(selectListTotal);
	const listLoading = useSelector(selectListLoading);
	const start = useSelector(selectPaginationStart);
	const limit = useSelector(selectPaginationLimit);
	const filters = useSelector(selectFilters);
	const columns = [
		{
			title: translate(translationsScope.Headers.Status),
			dataIndex: 'isActive',
			key: 'isActive',
			fixed: true,
			width: 70,
			render: (data, record) => (
				<GenericTable.Column>
					<SwitchStateFull
						initialStatus={data}
						recordId={record.id}
						toggleAction={actions.toggleStatus}
						errorSelector={selectCurrentRoundingRuleError}
						toogleOnMessage={translate(
							translationsScope.activateSwitch.toogleOnMessage,
						)}
						toggleOffMessage={translate(
							translationsScope.activateSwitch.toggleOffMessage,
						)}
						confirmButtonText={translate(
							translationsScope.activateSwitch.confirmButtonText,
						)}
						cancelButtonText={translate(
							translationsScope.activateSwitch.cancelButtonText,
						)}
						stateControlled={false}
					/>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.Headers.Contract),
			key: 'contractTypeId',
			dataIndex: 'contractTypeId',
			width: 160,
			render: (data) => {
				return (
					<GenericTable.Column>
						<Paragraph
							className="text text--large ellipsis"
							title={data}
							ellipsis={{ rows: 2 }}
						>
							<p className="text text--large">
								{CONTRACT_TYPES_GUIDS[data.toUpperCase()]}
							</p>
						</Paragraph>
					</GenericTable.Column>
				);
			},
		},
		{
			title: translate(translationsScope.Headers.BuySell),
			key: 'isSell',
			dataIndex: 'isSell',
			width: 160,
			render: (data) => {
				return (
					<GenericTable.Column>
						<Paragraph
							className="text text--large ellipsis"
							title={data}
							ellipsis={{ rows: 2 }}
						>
							<p className="text text--large">
								{data === true ? 'Sell' : 'Buy'}
							</p>
						</Paragraph>
					</GenericTable.Column>
				);
			},
		},
		{
			title: translate(translationsScope.Headers.From),
			key: 'from',
			dataIndex: 'from',
			width: 100,
			render: (data) => {
				return (
					<GenericTable.Column>
						<Paragraph
							className="text text--large ellipsis"
							title={data}
							ellipsis={{ rows: 2 }}
						>
							<p className="text text--large">{formatPriceHelper(data)}</p>
						</Paragraph>
					</GenericTable.Column>
				);
			},
		},
		{
			title: translate(translationsScope.Headers.To),
			key: 'to',
			dataIndex: 'to',
			width: 100,
			render: (data) => {
				return (
					<GenericTable.Column>
						<Paragraph
							className="text text--large ellipsis"
							title={data}
							ellipsis={{ rows: 2 }}
						>
							<p className="text text--large">{formatPriceHelper(data)}</p>
						</Paragraph>
					</GenericTable.Column>
				);
			},
		},
		{
			title: translate(translationsScope.Headers.DecimalPlace),
			key: 'decimalPlace',
			dataIndex: 'decimalPlace',
			width: 160,
			render: (data) => {
				return (
					<GenericTable.Column>
						<Paragraph
							className="text text--large ellipsis"
							title={data}
							ellipsis={{ rows: 2 }}
						>
							<p className="text text--large">
								{data === 0 ? data.toPrecision(5) : data.toPrecision(2)}
							</p>
						</Paragraph>
					</GenericTable.Column>
				);
			},
		},
		{
			title: translate(translationsScope.Headers.Rounding),
			key: 'roundingTypeId',
			dataIndex: 'roundingTypeId',
			width: 160,
			render: (data) => {
				return (
					<GenericTable.Column>
						<Paragraph
							className="text text--large ellipsis"
							title={data}
							ellipsis={{ rows: 2 }}
						>
							<p className="text text--large">
								{data === null ? '' : ROUNDING_TYPES_GUIDS[data.toUpperCase()]}
							</p>
						</Paragraph>
					</GenericTable.Column>
				);
			},
		},
		{
			fixed: 'right' as 'right',
			width: 15,
			render: (data) => (
				<GenericTable.Column>
					<Actions rowData={data} />
				</GenericTable.Column>
			),
		},
		{
			title: '',
			className: 'column-table',
			fixed: 'right' as 'right',
			width: 15,
			render: (data) => (
				<GenericTable.Column className="last-column">
					<Delete rowData={data} />
				</GenericTable.Column>
			),
		},
	];

	const handleOnChange = (page, pageSize) => {
		dispatch(
			actions.setPagination({
				limit: pageSize,
				start: page,
			}),
		);
		dispatch(actions.loadRoundingRulesList());
	};

	return (
		<GenericTable.Table
			otherClassName="table-container rounding-rules"
			columns={columns}
			data={listData}
			loading={listLoading}
			selectedFilters={filters}
			pagination={{
				total: listTotal,
				pageSize: limit,
				current: start,
				onChange: handleOnChange,
			}}
			emptyText={translate(translationsShared.Table.emptyText, {
				query: filters.name,
			})}
		/>
	);
};
