import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state?.offers || initialState;

export const selectCurrentView = createSelector(
	[selectDomain],
	(offersState) => offersState.currentView,
);

export const selectCurrentModal = createSelector(
	[selectDomain],
	(offersState) => offersState.currentModal,
);

export const selectOffersList = createSelector(
	[selectDomain],
	(offersState) => offersState.offersList.data,
);

export const selectOffersListLoading = createSelector(
	[selectDomain],
	(offersState) => offersState.offersList.loading,
);

export const selectTotalOffers = createSelector(
	[selectDomain],
	(offersState) => offersState.offersList.total,
);

export const selectSelectedColumns = createSelector(
	[selectDomain],
	(offersState) => offersState.selectedColumns,
);

export const selectFixedColumns = createSelector(
	[selectDomain],
	(offersState) => offersState.fixedColumns,
);

export const selectPagination = createSelector(
	[selectDomain],
	(offersState) => offersState.pagination,
);

export const selectActiveOffer = createSelector(
	[selectDomain],
	(offersState) => offersState.activeOffer.data,
);

export const selectActiveOfferLoading = createSelector(
	[selectDomain],
	(offersState) => offersState.activeOffer.loading,
);

export const selectActiveOfferDetails = createSelector(
	[selectDomain],
	(offersState) => offersState.activeOfferDetails,
);

export const selectSelectedFilters = createSelector(
	[selectDomain],
	(offersState) => offersState.selectedFilters,
);

export const selectSelectedRows = createSelector(
	[selectDomain],
	(offersState) => offersState.selectedRows,
);
