import { ErrorOption } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectCachedFuturesPrice } from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';
import { CONSTANTS } from 'utils/constants';
import { handlePriceControl } from 'utils/helpers';

export const usePriceControl = (
	futuresPrice: number,
	setError: (name: string, error: ErrorOption) => void,
	clearErrors: (name?: string | string[] | undefined) => void,
) => {
	const { t: translate } = useTranslation();
	const msg = translations.app.containers.Transactions.components.Futures;
	const cachedFuturesPrice = useSelector(selectCachedFuturesPrice);

	const up =
		cachedFuturesPrice?.futuresPrice + cachedFuturesPrice?.priceControl;
	const down =
		cachedFuturesPrice?.futuresPrice - cachedFuturesPrice?.priceControl;

	const handlePriceError = () => {
		if (up === 0 && down === 0) return;
		setError('futuresPrice', {
			type: 'error',
			message: translate(msg.priceControlMsg, {
				range: `${down.toFixed(CONSTANTS.FIXED_DECIMALS)} - ${up.toFixed(CONSTANTS.FIXED_DECIMALS)}`,
			}),
			shouldFocus: true,
		});
	};

	const validatePrice = () => {
		if (!handlePriceControl(futuresPrice, up, down)) {
			handlePriceError();
		} else {
			clearErrors('futuresPrice');
		}
	};

	return validatePrice;
};
