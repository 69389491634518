import { Row, Spin } from 'antd';
import React from 'react';

import { FieldDefinition } from 'types/FieldDefinition';

import { useNotificationGroupSchema } from '../../schemas/useNotificationGroupSchema';
import { NotificationGroupEditHookValue } from '../../types';
import { GroupSettingsForm } from '../GroupSettingsForm';

interface Props {
	useGroupsHook: () => NotificationGroupEditHookValue;
	nonMembersTitle: string;
}

export const EditNotificationGroup = ({
	useGroupsHook,
	nonMembersTitle,
}: Props) => {
	const {
		groupDetails,
		nonMemberList = [],
		memberList = [],
		onSubmit,
		onClose,
		loading,
	} = useGroupsHook();

	const notificationGroupSchema = useNotificationGroupSchema({
		groupDetails,
		nonMemberList,
		memberList,
		nonMembersTitle,
	}) as FieldDefinition[];

	if (loading) {
		return (
			<Row justify="space-around">
				<Spin />
			</Row>
		);
	} else {
		return (
			<GroupSettingsForm
				fieldSchema={notificationGroupSchema}
				handleSubmit={onSubmit}
				onClose={onClose}
			/>
		);
	}
};
