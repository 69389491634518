import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { applyNameId } = apiEndpoints;

export function* getApplyNameID(action: PayloadAction<any>) {
	const id = action.payload;

	const requestURL = yield new URL(`${apiRoutes.base}/${applyNameId}/${id}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.applyNameIdSuccess(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.applyNameIdError(responseError));
	}
}

export function* applyNameID(action: PayloadAction<any>) {
	const { id, successMessage, data } = action.payload;

	const requestURL = yield new URL(`${apiRoutes.base}/${applyNameId}/${id}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		data,
	);

	if (!!responseData) {
		yield put(actions.setCurrentModal(null));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				title: successMessage,
				showDescription: false,
			}),
		);
		yield put(actions.loadContractList());
	} else if (!!responseError.detail) {
		yield put(actions.applyNameIdError(responseError));
	}
}
