import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';
import { createSlice } from 'utils/@reduxjs/toolkit';

import {
	HedgeMappingModals,
	HedgeMappingRequest,
	HedgeMappingSectionState,
	RegionInfo,
	View,
} from './types';

export const initialState: HedgeMappingSectionState = {
	currentView: View.List,
	currentModal: null,

	list: {
		data: [],
		loading: false,
		error: null,
	},
	hedgeMapTemplate: {
		data: null,
		uploadResponse: { data: null, status: null, statusText: null },
		loading: false,
		error: null,
	},

	hedgeMapTemplateUpdate: {
		data: null,
		uploadResponse: { data: null, status: null, statusText: null },
		loading: false,
		error: null,
	},

	regionInfo: {
		commodityId: '',
		regionId: '',
	},
	submitHedgeMapToServer: {
		data: null,
		uploadResponse: { data: null, status: null, statusText: null },
		loading: false,
		error: null,
	},
	downloadFilledHedgemapExcel: {
		url: null,
		loading: false,
		error: null,
	},
	downloadFilledHedgemapRequest: {
		commodityId: '',
	},
};

const hedgeMappingSlice = createSlice({
	name: 'hedgeMapping',
	initialState,
	reducers: {
		setCurrentModal(state, action: PayloadAction<HedgeMappingModals | null>) {
			state.currentModal = action.payload;
		},
		loadHedgeMapList(state, action: PayloadAction<HedgeMappingRequest>) {
			state.list = {
				...state.list,
				loading: true,
				error: null,
			};
		},

		resetHedgeMappingSection(state) {
			state.list = { ...initialState.list };
		},

		hedgeMappingListLoaded(state, action: PayloadAction<any>) {
			state.list = {
				...state.list,
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		hedgeMappingListError(state, action: PayloadAction<GenericError>) {
			state.list = {
				...state.list,
				loading: false,
				error: action.payload,
			};
		},
		downloadFilledHedgemapExcel(state, action: PayloadAction<any>) {
			state.downloadFilledHedgemapExcel = {
				...state.downloadFilledHedgemapExcel,
				url: action.payload,
				loading: true,
				error: null,
			};
		},
		downloadFilledHedgemapRequest(state, action: PayloadAction<any>) {
			state.downloadFilledHedgemapRequest = {
				...state.downloadFilledHedgemapRequest,
				...action.payload,
			};
		},
		hedgeMapTemplateLoaded(state, action: PayloadAction<any>) {
			state.hedgeMapTemplate = {
				data: {
					...action.payload,
				},
				uploadResponse: { data: null, status: null, statusText: null },
				loading: false,
				error: null,
			};
		},

		submitHedgeMapToServer(
			state: HedgeMappingSectionState,
			action: PayloadAction<any>,
		) {
			state.submitHedgeMapToServer = {
				...state.submitHedgeMapToServer,
				uploadResponse: { data: null, status: null, statusText: null },
			};
		},

		uploadHedgeMapTemplateUpdated(
			state: HedgeMappingSectionState,
			action: PayloadAction<any>,
		) {
			state.hedgeMapTemplateUpdate = {
				...state.hedgeMapTemplateUpdate,
				uploadResponse: { data: null, status: null, statusText: null },
				loading: true,
			};
		},
		hedgeMapTemplateUploaded(state, action: PayloadAction<any>) {
			state.hedgeMapTemplate = {
				...state.hedgeMapTemplate,
				uploadResponse: action.payload,
				loading: false,
			};
		},

		hedgeMapTemplateUpdateUploaded(state, action: PayloadAction<any>) {
			state.hedgeMapTemplateUpdate = {
				...state.hedgeMapTemplateUpdate,
				uploadResponse: action.payload,
				loading: false,
			};
		},

		hedgeMapTemplateError(state, action: PayloadAction<GenericError>) {
			state.hedgeMapTemplate = {
				...state.hedgeMapTemplate,
				loading: false,
				error: action.payload,
			};
		},

		hedgeMapUpdateTemplateError(state, action: PayloadAction<GenericError>) {
			state.hedgeMapTemplateUpdate = {
				...state.hedgeMapTemplateUpdate,
				loading: false,
				error: action.payload,
			};
		},

		loadHedgeMapTemplate(
			state: HedgeMappingSectionState,
			action: PayloadAction<any>,
		) {
			state.hedgeMapTemplate = {
				data: null,
				uploadResponse: { data: null, status: null, statusText: null },
				loading: true,
				error: null,
			};
		},

		clearHedgeMapTemplate(state: HedgeMappingSectionState) {
			state.hedgeMapTemplate = {
				...state.hedgeMapTemplate,
				uploadResponse: { data: null, status: null, statusText: null },
				loading: false,
				error: null,
			};
		},

		clearHedgeMapUpdateTemplate(state: HedgeMappingSectionState) {
			state.hedgeMapTemplateUpdate = {
				...state.hedgeMapTemplateUpdate,
				uploadResponse: { data: null, status: null, statusText: null },
				loading: false,
				error: null,
			};
		},

		loadRegionInfo(
			state: HedgeMappingSectionState,
			action: PayloadAction<RegionInfo>,
		) {
			const { commodityId, regionId } = action.payload;
			state.regionInfo = {
				...state.regionInfo,
				commodityId,
				regionId,
			};
		},
	},
});

export const {
	actions: hedgeMappingActions,
	reducer,
	name: sliceKey,
} = hedgeMappingSlice;
