/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'sanitize.css/sanitize.css';
// Initialize languages
import './locales/i18n';
// Initialize languages
import './locales/i18n';

import * as Sentry from '@sentry/react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import moment from 'moment-timezone';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import { App } from 'app';
import { configureAppStore } from 'store/configureStore';
import runtimeConfig from 'utils/runtime-config';

if (process.env.NODE_ENV !== 'development') {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [],
		environment: runtimeConfig.sentryEnv,
	});
}

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

moment.tz.setDefault('America/Chicago');

const withLDProvider = async (Component: typeof App) => {
	const LDProvider = await asyncWithLDProvider({
		clientSideID: runtimeConfig.launchDarklyClientSideId,
		options: {
			bootstrap: 'localStorage',
		},
	});

	return () => (
		<LDProvider>
			<Provider store={store}>
				<HelmetProvider>
					<Component />
				</HelmetProvider>
			</Provider>
		</LDProvider>
	);
};

const render = async (Component: typeof App) => {
	const ConnectedApp = await withLDProvider(Component);
	ReactDOM.render(<ConnectedApp />, MOUNT_NODE);
};

if (module.hot) {
	// Hot reloadable translation json files and app
	// modules.hot.accept does not accept dynamic dependencies,
	// have to be constants at compile-time
	module.hot.accept(['./app', './locales/i18n'], () => {
		ReactDOM.unmountComponentAtNode(MOUNT_NODE);
		const App = require('./app').App;
		render(App);
	});
}

render(App);
