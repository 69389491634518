/**
 *
 * TagWrapped
 *
 */
import './style.scss';

import { Tag } from 'antd';
import React from 'react';

export const TagWrapped = (props) => {
	const { label, closable, onClose } = props;
	return (
		<Tag className="ant-tag-wrapper" closable={closable} onClose={onClose}>
			{label}
		</Tag>
	);
};
