import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { translations } from 'locales/i18n';

interface Props {
	disabled?: boolean;
	deliveryDateLabel?: boolean;
}

export const OriginDeliveryWindow = memo((props: Props) => {
	const { t: translate } = useTranslation();
	const { disabled, deliveryDateLabel } = props;

	const msg =
		translations.app.containers.Contracts.components.OriginDeliveryWindow;

	const label = deliveryDateLabel ? msg.deliveryDate.label : msg.label;

	return (
		<GenericForm.FormItem
			label={translate(label)}
			name="deliveryWindow"
			data-testid="deliveryWindow-form-item"
		>
			<Input
				key="deliveryWindow"
				data-testid="deliveryWindow-input-item"
				type="text"
				disabled={disabled}
				name="deliveryWindow"
			/>
		</GenericForm.FormItem>
	);
});
