import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { GenericError } from 'types/GenericError';
import { ERP_NUMBER_REPEATED } from 'utils/constants';

export const useCustomErrorMessage = (formikProps, hookParams) => {
	const { dirty, setFieldError } = formikProps;

	const dispatch = useDispatch();

	const { field, errorMessageERP, errorSelector, defaultErrorMessage } =
		hookParams;

	const error: GenericError = useSelector(errorSelector);
	const errorDetail = error?.detail;

	const useEffectErpErrorMessage = (effect: React.EffectCallback) => {
		useEffect(effect, [errorDetail]);
	};

	useEffectErpErrorMessage(() => {
		if (dirty && ERP_NUMBER_REPEATED === errorDetail) {
			setFieldError(field, errorMessageERP);
		} else if (dirty && error && defaultErrorMessage) {
			dispatch(
				globalActions.addNotification({
					type: CustomNotificationTypes.ERROR,
					message: defaultErrorMessage,
				}),
			);
		}
	});
};
