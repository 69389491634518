import './style.scss';

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { translations } from 'locales/i18n';
interface Props {
	disabled?: boolean;
	showMax?: boolean;
	maxQuantity?: string;
}

export const Quantity = memo((props: Props) => {
	const { disabled, showMax, maxQuantity } = props;

	const { t: translate } = useTranslation();
	const msg = translations.app.containers.Transactions.components.Quantity;

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.target.blur();
		}
	};

	return (
		<>
			<GenericForm.FormItem
				label={translate(msg.label)}
				name="quantity"
				customClassName={['input-quantity__label']}
				data-testid="quantity-form-item"
				isNumericFieldSlow
			>
				<Input
					onKeyDown={handleKeyPress}
					className="input-quantity__input"
					key="quantity"
					name="quantity"
					placeholder={translate(msg.placeholder)}
					type="text"
					disabled={disabled}
				/>
			</GenericForm.FormItem>
			{showMax && (
				<GenericForm.FormItem
					customClassName={['input-quantity__label']}
					data-testid="max-form-item"
					label={translate(msg.max.label)}
					name="max"
				>
					<Input
						className="input-quantity__input"
						data-testid="max-input-item"
						disabled
						placeholder={maxQuantity}
						name="max"
					/>
				</GenericForm.FormItem>
			)}
		</>
	);
});
