import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from './../slice';

const { reviewAndReleaseStates } = apiEndpoints;

export function* getReviewAndReleaseStates(
	action: PayloadAction<any | undefined>,
) {
	const requestURL = yield new URL(
		`${apiRoutes.base}/${reviewAndReleaseStates}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.reviewAndReleaseStatesLoaded(responseData));
	} else if (!!responseError) {
		yield put(actions.reviewAndReleaseStatesError(responseError));
	}
}
