import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectEnabledRegions } from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { Region } from 'types/Regions';

import { useFeatureFlags } from './useFeatureFlags';

type MapperResult<Mapper> = Mapper extends (r: Region[]) => infer Mapped
	? Mapped
	: Region[];
export function useEnabledRegions<
	// any is fine in an generic extends
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Mapper extends undefined | ((regions: Region[]) => any),
	Result = MapperResult<Mapper>,
>(mapperCallback?: Mapper, isEmployeeActiveRegion = false): Result | null {
	const dispatch = useDispatch();
	const { isRegionsEnabled } = useFeatureFlags();
	const regions = useSelector(selectEnabledRegions);

	useEffect(() => {
		dispatch(
			globalActions.getEnabledRegionsRequest({ isEmployeeActiveRegion }),
		);
	}, [isEmployeeActiveRegion, dispatch]);

	if (!isRegionsEnabled) return null;
	return mapperCallback ? mapperCallback([...regions]) : regions;
}
