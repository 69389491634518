import { takeLatest } from 'redux-saga/effects';

import {
	getAcknowledgeAndFillSaga,
	setAcknowledgeAndFillSaga,
} from './sagas/acknowledge-and-fill-saga';
import { createExternalFill } from './sagas/create-external-fill-saga';
import { exportReviewAndRelease } from './sagas/export-saga';
import { getFuturesMonths } from './sagas/futures-months-saga';
import {
	cancelExternalFill,
	editExternalFill,
	getExternalFill,
	getOrderDetail,
	getReviewAndReleaseList,
	reviewAndReleaseMenuAction,
} from './sagas/review-and-release-saga';
import { actions } from './slice';

export function* reviewAndReleaseSaga() {
	// Watches for loadOrdersList actions and calls getOrdersList when one comes in.
	yield takeLatest(
		actions.loadReviewAndReleaseList.type,
		getReviewAndReleaseList,
	);

	yield takeLatest(
		actions.reviewAndReleaseMenuAction.type,
		reviewAndReleaseMenuAction,
	);

	yield takeLatest(actions.loadOrderDetail.type, getOrderDetail);

	yield takeLatest(actions.createExternalFill.type, createExternalFill);

	yield takeLatest(actions.editExternalFill.type, editExternalFill);

	yield takeLatest(actions.cancelExternalFill.type, cancelExternalFill);

	yield takeLatest(actions.getExternalFill.type, getExternalFill);

	yield takeLatest(
		actions.getAcknowledgeAndFillRequest.type,
		getAcknowledgeAndFillSaga,
	);

	yield takeLatest(
		actions.setAcknowledgeAndFillRequest.type,
		setAcknowledgeAndFillSaga,
	);

	yield takeLatest(actions.exportReviewAndRelease.type, exportReviewAndRelease);

	yield takeLatest(actions.getFuturesMonthsRequest.type, getFuturesMonths);
}
