import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { CONSTANTS } from 'utils/constants';

export const useFormatPrice = (setZero?: boolean) => {
	const { getValues, setValue } = useFormContext();

	const formatPrice = useCallback(
		(name) => {
			const value = getValues()[name];
			if (!!value) {
				setValue(name, parseFloat(value).toFixed(CONSTANTS.FIXED_DECIMALS));
			} else if (setZero) {
				setValue(name, (0).toFixed(CONSTANTS.FIXED_DECIMALS));
			}
		},
		[setZero, getValues, setValue],
	);

	return { formatPrice };
};
