import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { translations } from 'locales/i18n';
import { GenericOption } from 'types/GenericOption';
import { yearsToLabelValue } from 'utils/helpers';

import { selectERPTypes } from '../../selectors';
import { SETUP_FORM_ITEMS } from '../../types';

const domain =
	translations.app.containers.Settings.sections.ERPIntegration.components
		.AccountingSystem;

export const AccountingSystem = memo(() => {
	const { t: translate } = useTranslation();
	const erpTypesList = useSelector(selectERPTypes);

	const getTranslation = (key: string) => translate(domain[key]);

	return (
		<GenericForm.FormItem
			data-testid={`${SETUP_FORM_ITEMS.AccountingSystem}-form-item`}
			label={getTranslation('label')}
			name={SETUP_FORM_ITEMS.AccountingSystem}
		>
			<Dropdown
				options={yearsToLabelValue(erpTypesList) as GenericOption[]}
				placeholder={getTranslation('placeholder')}
			/>
		</GenericForm.FormItem>
	);
});
