import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { selectFeatureFlags } from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';
import { FeatureFlag } from 'types/FeatureFlags';

import { SourceContext } from '../..';
import { useLocationsByAction } from '../../hooks/useLocationsByAction';
import { useHandleBusinessRules } from '../hooks/useHandleBusinessRules';

interface Props {
	disabled?: boolean;
	hidden?: boolean;
}

export const Location = (props: Props) => {
	const featureFlgas = useSelector(selectFeatureFlags);
	const isLocationEnabled: boolean =
		featureFlgas && featureFlgas[FeatureFlag.enabledDestinationLocation];
	const { disabled = false, hidden = isLocationEnabled } = props;
	const { t: translate } = useTranslation();
	const msg = translations.app.containers.Transactions.components.Location;

	const sourceName = useContext(SourceContext);

	const { watch } = useFormContext();

	const { locations } = useLocationsByAction(watch('action'));

	const { getPostedBasis } = useHandleBusinessRules(sourceName);
	const handleChange = () => {
		getPostedBasis(sourceName);
	};

	return (
		<GenericForm.FormItem
			label={translate(msg.label)}
			name="location"
			onChange={handleChange}
			data-testid="location-form-item"
			customClassName={hidden ? ['hidden'] : []}
		>
			<Dropdown
				disabled={disabled}
				key="location"
				placeholder={translate(msg.placeholder)}
				options={locations}
			/>
		</GenericForm.FormItem>
	);
};
