import { takeLatest } from 'redux-saga/effects';

import { getCashbidsData } from './sagas/cashbids-saga';
import { getFutureQuoteData, getProductsList } from './sagas/future-quotes';
import { actions } from './slice';

export function* cashbitdsAndQuotesSaga() {
	yield takeLatest(actions.loadFutureQuotes.type, getFutureQuoteData);
	yield takeLatest(actions.loadCashbids.type, getCashbidsData);
	yield takeLatest(actions.loadProductList.type, getProductsList);
}
