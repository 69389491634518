import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { translations } from 'locales/i18n';
import { yearsToLabelValue } from 'utils/helpers';
import Yup from 'yupCustom';

import { AccountingSystem } from '../components/AccountingSystem';
import { CommentsLength } from '../components/CommentsLength';
import { Password } from '../components/Password';
import { URL } from '../components/URL';
import { Username } from '../components/Username';
import { selectERPIntegration, selectERPTypes } from '../selectors';
import { SETUP_FORM_ITEMS } from '../types';
import { getERPTypeIndex } from '../utils/helpers';

const domain =
	translations.app.containers.Settings.sections.ERPIntegration.validations;

export const useERPSetupFormSchema = () => {
	const { t: translate } = useTranslation();
	const erpIntegration = useSelector(selectERPIntegration);
	const erpTypesList = useSelector(selectERPTypes);

	const getTranslation = (key: string) => translate(domain[key]);

	const requiredMsgAccountingSystem = getTranslation(
		'requiredAccountingSystem',
	);
	const requiredMsgCommentsLength = getTranslation('requiredCommentsLength');
	const requiredMsgPassword = getTranslation('requiredPassword');
	const requiredMsgURL = getTranslation('requiredURL');
	const requiredMsgUsername = getTranslation('requiredUsername');

	const erpTypeIndex = getERPTypeIndex(
		erpTypesList,
		erpIntegration?.[SETUP_FORM_ITEMS.AccountingSystem],
	);

	return {
		initialValues: {
			[SETUP_FORM_ITEMS.AccountingSystem]:
				yearsToLabelValue(erpTypesList)?.[erpTypeIndex],
			[SETUP_FORM_ITEMS.Username]: erpIntegration?.[SETUP_FORM_ITEMS.Username],
			[SETUP_FORM_ITEMS.Password]: erpIntegration?.[SETUP_FORM_ITEMS.Password],
			[SETUP_FORM_ITEMS.URL]: erpIntegration?.[SETUP_FORM_ITEMS.URL],
			[SETUP_FORM_ITEMS.CommentsLength]:
				erpIntegration?.[SETUP_FORM_ITEMS.CommentsLength],
		},
		validationSchema: Yup.object().shape({
			[SETUP_FORM_ITEMS.AccountingSystem]: Yup.object().requiredOption(
				requiredMsgAccountingSystem,
			),
			[SETUP_FORM_ITEMS.Username]: Yup.string().required(requiredMsgUsername),
			[SETUP_FORM_ITEMS.Password]: Yup.string().required(requiredMsgPassword),
			[SETUP_FORM_ITEMS.URL]: Yup.string().required(requiredMsgURL),
			[SETUP_FORM_ITEMS.CommentsLength]: Yup.number()
				.required(requiredMsgCommentsLength)
				.typeError(requiredMsgCommentsLength),
		}),
		elements: [
			<AccountingSystem />,
			<Username />,
			<Password />,
			<URL />,
			<CommentsLength />,
		],
	};
};
