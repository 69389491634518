import { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import {
	NotificationEvent,
	NotificationEventPayload,
	UserNotificationSubscriptions,
} from '../types';

export function* getTenantPreferences() {
	const { tenantNotificationSettings } = apiEndpoints;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${tenantNotificationSettings}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(
			actions.tenantPreferencesLoaded(
				responseData.map((record: NotificationEvent) => ({
					...record,
					scheduleTime: record.scheduleTime
						? moment(record.scheduleTime)
						: null,
				})),
			),
		);
	} else if (!!responseError.detail) {
		yield put(actions.tenantPreferencesError(responseError));
	}
}

export function* setTenantPreferenceStatus(
	action: PayloadAction<NotificationEventPayload>,
) {
	const { tenantNotificationSettings } = apiEndpoints;
	const { payload } = action;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${tenantNotificationSettings}`,
	);
	const tenantSettings: Pick<UserNotificationSubscriptions, 'tenantSettings'> =
		{
			tenantSettings: payload.tenantSettings!.map((record) => ({
				...record,
				scheduleTime: record.scheduleTime?.utc(),
			})),
		};

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		tenantSettings,
	);

	// Success actions
	if (!!responseData) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(actions.getTenantPreferences());
	}

	if (!!responseError) {
		yield put(actions.setTenantPreferenceStatusError(responseError));
	}
}
