import React from 'react';
import { useSelector } from 'react-redux';

import { RolesLocationsEmployee } from 'app/containers/Settings/sections/UserRoles/View/CreateEditRole/components/RoleLocationsEmployee';
import { selectLocationsListForSettingsData } from 'app/containers/Settings/selectors';
import { RoleSettings } from 'app/containers/Settings/types';
import { useFeatureFlags } from 'utils/hooks/useFeatureFlags';

import { selectEmployeeLocations } from '../../../selectors';

const generateRolesLocations = (formFields) => {
	return <RolesLocationsEmployee {...formFields} />;
};

const useRolesLocationsByEmployee = () => {
	const employeeList = useSelector(selectEmployeeLocations);
	const locationsList = useSelector(selectLocationsListForSettingsData);
	const { isEmployeeGroupedLocationPermissionEnabled } = useFeatureFlags();

	let initialValues = employeeList.reduce(
		(locations, location) => ({
			...locations,
			[location.locationId]: {
				CanBuy: location.canBuy,
				CanSell: location.canSell,
				LocationId: location.locationId,
				LocationName: location.locationName,
			},
		}),
		{},
	);

	if (
		!isEmployeeGroupedLocationPermissionEnabled &&
		employeeList.length === 0
	) {
		initialValues = locationsList.reduce(
			(locations, location) => ({
				...locations,
				[location.id]: {
					CanBuy: false,
					CanSell: false,
					LocationId: location.id,
					LocationName: location.name,
				},
			}),
			{},
		);
	}

	const locationsRoles = {
		name: 'rolesLocations',
		key: 'rolesLocations',
		initialValue: initialValues,
		locationsListData: isEmployeeGroupedLocationPermissionEnabled
			? employeeList
			: locationsList,
		section: RoleSettings.LOCATIONS,
		render: generateRolesLocations,
	};

	return {
		rolesComponent: locationsRoles,
	};
};

export { useRolesLocationsByEmployee };
