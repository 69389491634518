import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { Region } from 'types/Regions';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { regions } = apiEndpoints;

export function* createRegionSaga(action: PayloadAction<Region[]>) {
	const requestURL = yield new URL(`${apiRoutes.base}/${regions}`);

	const regionsData = action?.payload;

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		regionsData,
		true,
	);

	if (!!responseData) {
		yield put(actions.createRegionSuccess());
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(actions.getAllRegionsRequest());
	} else if (!!responseError?.detail) {
		yield put(actions.createRegionError(responseError));
	}
}

export function* getAllRegionsSaga(action: PayloadAction<Region[]>) {
	const requestURL = yield new URL(`${apiRoutes.base}/${regions}`);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
		true,
	);

	if (!!responseData) {
		yield put(actions.getAllRegionsSuccess(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.getAllRegionsError(responseError));
	}
}
