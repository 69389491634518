import { GenericError } from 'types/GenericError';

export interface ERPIntegrationState {
	erpIntegration: {
		data: any;
		error: GenericError | null;
		loading: boolean;
	};

	erpIntegrationUpdate: {
		data: any;
		error: GenericError | null;
		loading: boolean;
	};

	erpTypes: {
		data: Array<any>;
		error: GenericError | null;
		loading: boolean;
	};

	hedgeIntegration: {
		data: hedgeIntegrationResponse | null;
		error: GenericError | null;
		loading: boolean;
	};
	hedgeIntegrationUpdate: {
		data: hedgeIntegrationResponse | null;
		error: GenericError | null;
		loading: boolean;
	};
}

export interface hedgeIntegrationResponse {
	isHedgeActive?: boolean;
	isHedgeAutomatic?: boolean;
	isHedgeInstant?: boolean;
	hedgeTime?: string | null;
}

export interface hedgeIntegrationPayload {
	data: {
		isHedgeActive?: boolean;
		isHedgeAutomatic?: boolean;
		isHedgeInstant?: boolean;
		hedgeTime?: string | null;
	};
}

export const HEDGE_INTEGRATION_TYPE = {
	automatic: 'Automatic',
	manual: 'Manual',
};

export const HEDGE_EXECUTION_TYPE = {
	setTime: 'SetTime',
	instant: 'Instant',
};

export type ERPIntegrationSectionState = ERPIntegrationState;

export enum SETUP_FORM_ITEMS {
	AccountingSystem = 'erpType',
	Username = 'systemUsername',
	Password = 'systemPassword',
	URL = 'systemUrl',
	CommentsLength = 'commentsLength',
}

export enum SWITCH_FORM_ITEMS {
	ERPIntegration = 'isActive',
	HedgeIntegration = 'isHedgeActive',
}
