import { takeLatest } from 'redux-saga/effects';

import { createRoundingRule } from './sagas/create-rounding-rule-saga';
import { deleteRoundingRule } from './sagas/delete-rounding-rule-saga';
import {
	editRoundingRule,
	getRoundingRuleById,
} from './sagas/edit-rounding-rule-saga';
import {
	getRoundingRulesList,
	getRoundingTypesList,
} from './sagas/rounding-rule-saga';
import { toggleStatus } from './sagas/toggle-status-saga';
import { actions } from './slice';

export function* roundingRulesSaga() {
	yield takeLatest(actions.loadRoundingRulesList.type, getRoundingRulesList);
	yield takeLatest(actions.toggleStatus.type, toggleStatus);
	yield takeLatest(actions.createRoundingRule.type, createRoundingRule);
	yield takeLatest(actions.editRoundingRule.type, editRoundingRule);
	yield takeLatest(actions.setcurrentRoundingRule.type, getRoundingRuleById);
	yield takeLatest(actions.deleteRoundingRule.type, deleteRoundingRule);
	yield takeLatest(actions.loadRoundingTypesList.type, getRoundingTypesList);
}
