import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ErrorInLine } from '../../types';
import { TogglePayload } from '../ServiceFees/types';
import { LocationListPayload, LocationListResponse } from './fields/types';
import { Filters, LocationsSectionState, Modal, View } from './types';

// The initial state of the Settings container
export const initialState: LocationsSectionState = {
	currentView: View.List,
	currentModal: null,

	currentLocation: {
		data: null,
		error: null,
		loading: false,
	},

	list: {
		data: [],
		loading: false,
		total: 0,
		error: null,
	},
	searchListForContractLocation: {
		data: [],
		loading: false,
		error: null,
	},
	searchListForDestinationLocation: {
		data: [],
		loading: false,
		error: null,
	},
	pagination: {
		limit: 50,
		start: 1,
	},

	selectedFilters: {
		name: '',
		regionIds: [],
	},
	errorsInLine: [],
	enableManualEdit: { data: false },
};

const locationsSlice = createSlice({
	name: 'locations',
	initialState,
	reducers: {
		setCurrentModal(state, action: PayloadAction<Modal | null>) {
			state.currentModal = action.payload;
		},

		toggleStatus(state, action: PayloadAction<TogglePayload>) {
			state.currentLocation = {
				...state.currentLocation,
				loading: true,
				error: null,
			};
		},

		locationStatusToggled(state, action) {
			state.currentLocation = {
				...state.currentLocation,
				loading: false,
				error: null,
				data: action.payload,
			};
		},

		locationStatusError(state, action) {
			state.currentLocation = {
				...state.currentLocation,
				error: action.payload,
				loading: false,
			};
		},

		setCurrentLocation(state, action) {
			state.currentLocation = {
				data: action.payload,
				loading: true,
				error: null,
			};
		},

		loadLocationsList(state) {
			state.list = {
				...state.list,
				loading: true,
				error: null,
			};
		},

		locationsListLoaded(state, action: PayloadAction<any>) {
			state.list = {
				...state.list,
				data: action.payload.list,
				total: action.payload.total,
				loading: false,
				error: null,
			};
		},

		locationsListError(state, action: PayloadAction<GenericError>) {
			state.list = {
				...state.list,
				loading: false,
				error: action.payload,
			};
		},

		setPagination(state, action: PayloadAction<any>) {
			state.pagination = {
				limit: action.payload.limit || state.pagination.limit,
				start: action.payload.start || state.pagination.start,
			};
		},

		setSelectedFilters(state, action: PayloadAction<Filters>) {
			state.selectedFilters = {
				name: action.payload.name || '',
				regionIds: action.payload.regionIds || null,
			};
		},

		createLocation(state, action) {},

		locationCreated(state, action) {
			state.currentLocation = {
				data: null,
				loading: false,
				error: null,
			};
		},

		editLocation(state, action) {
			state.currentLocation = {
				...state.currentLocation,
				error: null,
				loading: true,
			};
		},

		locationEdited(state, action) {
			state.currentLocation = {
				data: null,
				loading: false,
				error: null,
			};
		},

		locationError(state, action: PayloadAction<GenericError>) {
			state.currentLocation = {
				...state.currentLocation,
				error: action.payload,
				loading: false,
			};
		},

		editLocationManually(state, action) {
			state.currentLocation = {
				...state.currentLocation,
				error: null,
				loading: true,
			};
		},

		locationEditedManually(state, action) {
			state.currentLocation = {
				data: null,
				loading: false,
				error: null,
			};
		},

		locationErrorManually(state, action: PayloadAction<GenericError>) {
			state.currentLocation = {
				...state.currentLocation,
				error: action.payload,
				loading: false,
			};
		},

		setErrorsInLine(state, action: PayloadAction<ErrorInLine[]>) {
			state.errorsInLine = action.payload.map((error) => {
				if (error.Field === 'name') {
					return {
						...error,
						Field: 'locationName',
					};
				}
				if (error.Field === 'number') {
					return {
						...error,
						Field: 'erpNumber',
					};
				} else return { ...error };
			});

			state.currentLocation = {
				...state.currentLocation,
				loading: false,
			};
		},

		requestedLocationById(state, action) {
			state.currentLocation = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		loadSearchLocationsListForContractLocation(
			state,
			action: PayloadAction<LocationListPayload>,
		) {
			state.searchListForContractLocation = {
				...state.searchListForContractLocation,
				loading: true,
				error: null,
			};
		},

		searchLocationsListLoadedForContractLocation(
			state,
			action: PayloadAction<LocationListResponse[]>,
		) {
			state.searchListForContractLocation = {
				...state.searchListForContractLocation,
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		searchLocationsListErrorForContractLocation(
			state,
			action: PayloadAction<GenericError>,
		) {
			state.searchListForContractLocation = {
				...state.searchListForContractLocation,
				loading: false,
				error: action.payload,
			};
		},

		loadSearchLocationsListForDestinationLocation(
			state,
			action: PayloadAction<LocationListPayload>,
		) {
			state.searchListForDestinationLocation = {
				...state.searchListForDestinationLocation,
				loading: true,
				error: null,
			};
		},

		searchLocationsListLoadedForDestinationLocation(
			state,
			action: PayloadAction<LocationListResponse[]>,
		) {
			state.searchListForDestinationLocation = {
				...state.searchListForDestinationLocation,
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		searchLocationsListErrorForDestinationLocation(
			state,
			action: PayloadAction<GenericError>,
		) {
			state.searchListForDestinationLocation = {
				...state.searchListForDestinationLocation,
				loading: false,
				error: action.payload,
			};
		},

		enableManualEdit(state, action: PayloadAction<boolean>) {
			state.enableManualEdit = {
				...state.enableManualEdit,
				data: action.payload,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = locationsSlice;
