import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GenericTable } from 'app/components/GenericTable';
import { SwitchStateFull } from 'app/components/SwitchStateFull';
import { translations } from 'locales/i18n';
import { dateShortFormat } from 'utils/helpers';

import {
	selectCommodityStatusError,
	selectListData,
	selectListLoading,
} from '../../../selectors';
import { actions } from '../../../slice';
import { Actions } from './Actions';

const { Paragraph } = Typography;

export const TableWrapper = () => {
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.Commodities;
	const listData = useSelector(selectListData);
	const listLoading = useSelector(selectListLoading);
	const rows = { rows: 2 };
	const widthSwitch = 70;
	const widthEdit = 20;

	const columns = [
		{
			title: translate(translationsScope.View.List.Table.Headers.Status),
			className: 'column-table',
			dataIndex: 'isActive',
			key: 'isActive',
			fixed: true,
			width: widthSwitch,
			render: (data, record) => (
				<GenericTable.Column>
					<SwitchStateFull
						initialStatus={data}
						recordId={record.id}
						toggleAction={actions.setCommodityStatus}
						errorSelector={selectCommodityStatusError}
						toogleOnMessage={translate(
							translationsScope.View.List.Table.activateSwitch.toogleOnMessage,
						)}
						toggleOffMessage={translate(
							translationsScope.View.List.Table.activateSwitch.toggleOffMessage,
						)}
						confirmButtonText={translate(
							translationsScope.View.List.Table.activateSwitch
								.confirmButtonText,
						)}
						cancelButtonText={translate(
							translationsScope.View.List.Table.activateSwitch.cancelButtonText,
						)}
						stateControlled={false}
					/>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.ErpCode),
			className: 'column-table',
			dataIndex: 'number',
			key: 'number',
			fixed: true,
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={rows}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.HedgeFutures),
			className: 'column-table',
			dataIndex: 'hedgeFutures',
			key: 'hedgeFutures',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={rows}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.CommodityName),
			className: 'column-table',
			dataIndex: 'name',
			key: 'name',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={rows}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.CropDates),
			className: 'column-table',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={rows}
					>
						<p className="text text--large">{`${dateShortFormat(data.cropYearStartDate)} - ${dateShortFormat(data.cropYearEndDate)}`}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.Cropyears),
			className: 'column-table',
			dataIndex: 'numberOfCropYears',
			key: 'numberOfCropYears',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={rows}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.PriceCrtl),
			className: 'column-table',
			dataIndex: 'priceControl',
			key: 'priceControl',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={rows}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.BasisCtrl),
			className: 'column-table',
			dataIndex: 'basisControl',
			key: 'basisControl',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={rows}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: '',
			className: 'column-table',
			fixed: 'right' as 'right',
			width: widthEdit,
			render: (data) => (
				<GenericTable.Column className="last-column">
					<Actions rowData={data} />
				</GenericTable.Column>
			),
		},
	];

	return (
		<>
			<GenericTable.Table
				otherClassName="table-container"
				columns={columns}
				loading={listLoading}
				data={listData}
				pagination={false}
			/>
		</>
	);
};
