import { call, put } from 'redux-saga/effects';

import { translations } from 'locales/i18n';
import { CropYearExpiration } from 'types/CropYearExpiration';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import { CustomNotificationTypes } from '../types';

const { commoditiesRoot } = apiEndpoints;

export function* getCommoditiesList() {
	const requestURL = yield new URL(`${apiRoutes.base}/${commoditiesRoot}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.commoditiesLoaded(responseData.commodities));
	} else if (!!responseError.detail) {
		yield put(actions.commoditiesError(responseError));
	}
}

export function* getCommoditiesCropYearsExpired() {
	const domain = translations.app.commons;
	const requestURL = yield new URL(`${apiRoutes.base}/${commoditiesRoot}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		const commodities = responseData.commodities;
		const aboutToExpireCommodities = commodities.filter(({ cropYears }) =>
			cropYears?.some(
				({ expiration }) => expiration === CropYearExpiration.ABOUT_TO_EXPIRE,
			),
		);
		const expiredCommodities = commodities.filter(({ cropYears }) =>
			cropYears?.some(
				({ expiration }) => expiration === CropYearExpiration.EXPIRED,
			),
		);

		for (let commodity of aboutToExpireCommodities) {
			const name = commodity.name;

			yield put(
				actions.addNotification({
					confirmText: { key: domain.confirmButton },
					duration: 0,
					message: { key: domain.aboutToExpireCropYear, data: { name } },
					showConfirmButton: true,
					title: { key: domain.attention.title },
					type: CustomNotificationTypes.WARNING,
				}),
			);
		}

		for (let commodity of expiredCommodities) {
			const name = commodity.name;

			yield put(
				actions.addNotification({
					confirmText: { key: domain.confirmButton },
					duration: 0,
					message: { key: domain.expiredCropYear, data: { name } },
					showConfirmButton: true,
					title: { key: domain.attention.title },
					type: CustomNotificationTypes.WARNING,
				}),
			);
		}
	} else if (!!responseError.detail) {
		yield put(actions.commoditiesError(responseError));
	}
}
