import { Col, Row } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ExcelExport } from 'app/components/ExcelExport';
import { selectExportTemplate } from 'app/containers/PreHedge/selectors';
import { actions } from 'app/containers/PreHedge/slice';
import { translations } from 'locales/i18n';

import { OrphanedBalanceTable } from './components/OrphanedBalanceTable';
import { Filters } from './Filters';

export const Menu = memo(() => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const scopeTranslations =
		translations.app.containers.preHedge.components.Views.List.Menu;
	const url = useSelector(selectExportTemplate)?.url;

	const handleOnClickExport = () => {
		const errorMsg = translate(scopeTranslations.failedExport);
		const successMsg = translate(scopeTranslations.successExport);
		dispatch(actions.exportPreHedge({ errorMsg, successMsg }));
	};
	return (
		<>
			<Row>
				<Col xs={12} md={12} lg={12} xl={9}>
					<Filters />
				</Col>
				<Col xs={12} md={12} lg={12} xl={3} className="padding">
					<Row justify="end">
						<div className="preHedge-menu__orphaned-export">
							<OrphanedBalanceTable />
							<ExcelExport
								fileName={translate(scopeTranslations.exportFileName)}
								handleFileRequest={handleOnClickExport}
								url={url}
							/>
						</div>
					</Row>
				</Col>
			</Row>
		</>
	);
});
