import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { eventRoot } = apiEndpoints;

export function* getEventList() {
	const requestURL = yield new URL(`${apiRoutes.base}/${eventRoot}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.eventLoaded(responseData));
	} else {
		yield put(actions.eventError(responseError));
	}
}
