import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) =>
	state.legacyLocations || initialState;

export const selectCurrentModal = createSelector(
	[selectDomain],
	(settingsState) => settingsState.currentModal,
);

export const selectListData = createSelector(
	[selectDomain],
	(settingsState) => settingsState.list.data,
);

export const selectListLoading = createSelector(
	[selectDomain],
	(settingsState) => settingsState.list.loading,
);

export const selectListTotal = createSelector(
	[selectDomain],
	(settingsState) => settingsState.list.total,
);
export const selectPaginationLimit = createSelector(
	[selectDomain],
	(settingsState) => settingsState.pagination.limit,
);

export const selectPaginationStart = createSelector(
	[selectDomain],
	(settingsState) => settingsState.pagination.start,
);
export const selectFilters = createSelector(
	[selectDomain],
	(settingsState) => settingsState.selectedFilters,
);

export const selectCurrentLocationError = createSelector(
	[selectDomain],
	(settingsState) => settingsState.currentLocation.error,
);

export const selectCurrentLocation = createSelector(
	[selectDomain],
	(settingsState) => settingsState.currentLocation.data,
);
export const selectCurrentLocationId = createSelector(
	[selectDomain],
	(settingsState) => settingsState.currentLocation.data?.id,
);

export const selectErrorsInline = createSelector(
	[selectDomain],
	(settingsState) => settingsState.errorsInLine,
);

export const selectCurrentLocationLoading = createSelector(
	[selectDomain],
	(settingsState) => settingsState.currentLocation.loading,
);
