import './style.scss';

import { Checkbox as AntDCheckbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import React, { memo } from 'react';

interface Props extends CheckboxProps {
	label?: string;
	disabled?: boolean;
}

export const Checkbox = memo((props: Props) => {
	const { label, disabled, onChange, ...otherProps } = props;
	const handleChange = (e) => {
		onChange?.(e.target.checked);
	};

	return (
		<AntDCheckbox
			{...otherProps}
			onChange={handleChange}
			checked={props.value}
			data-testid="checkbox-input"
			disabled={disabled}
			className="checkbox-form__checkbox"
		>
			{label}
		</AntDCheckbox>
	);
});
