import './styles.scss';

import { Card, List } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import {
	selectUserAssignedGroups,
	selectUserAssignedGroupsLoading,
} from '../../../selectors';

export const UserGroups = () => {
	const groups = useSelector(selectUserAssignedGroups);
	const loading = useSelector(selectUserAssignedGroupsLoading);

	return (
		<List
			className="user-groups"
			loading={loading}
			grid={{
				gutter: 16,
				column: 3,
				xs: 1,
				sm: 1,
				md: 2,
			}}
			dataSource={groups}
			renderItem={(item) => (
				<List.Item>
					<Card>{item.name}</Card>
				</List.Item>
			)}
		/>
	);
};
