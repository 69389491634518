import './style.scss';

import { useFormikContext } from 'formik';
import { Form, FormItemProps, Switch, SwitchProps } from 'formik-antd';
import React, { memo } from 'react';

interface IndexedPropertyValue {
	keyValue: string;
	property: string;
	index: number | string;
}
interface Props
	extends SwitchProps,
		Omit<FormItemProps, 'children' | 'validate'> {
	name: string;
	label?: string;
	size?: 'default' | 'small';
	disabled?: boolean;
	isValueArray?: IndexedPropertyValue;
	handleChange?: (event) => void;
}

export const FormSwitch = memo((props: Props) => {
	const {
		name,
		label,
		size = 'default',
		disabled = false,
		handleChange,
		isValueArray,
		...rest
	}: any = props;

	const { values }: any = useFormikContext();

	let isChecked = values.hasOwnProperty(name) && !!values[name];
	if (isValueArray) {
		const { keyValue, index, property } = isValueArray;
		isChecked = values[keyValue]?.[index]?.[property] || isChecked;
	}
	return (
		<Form.Item
			className="switch-form__label"
			label={label}
			name={name}
			key={name}
			{...rest}
		>
			<Switch
				name={name}
				checked={isChecked}
				disabled={disabled}
				size={size}
				onChange={handleChange}
				{...rest}
			></Switch>
		</Form.Item>
	);
});
