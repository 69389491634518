import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { DefaultPageState } from './types';

// The initial state of the ByPassReview State
/**
 * @internal exported for automated tests
 */
export const initialState: DefaultPageState = {
	updateDefaultPage: {
		data: null,
		error: null,
		loading: false,
	},
};

const defaultPageSlice = createSlice({
	name: 'defaultPage',
	initialState,
	reducers: {
		updateDefaultPage(state: DefaultPageState, action: PayloadAction<any>) {
			state.updateDefaultPage = {
				...state.updateDefaultPage,
				loading: true,
				error: null,
			};
		},

		updateDefaultPageSuccess(
			state: DefaultPageState,
			action: PayloadAction<any>,
		) {
			state.updateDefaultPage = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		updateDefaultPageError(
			state: DefaultPageState,
			action: PayloadAction<GenericError>,
		) {
			state.updateDefaultPage = {
				...state.updateDefaultPage,
				loading: false,
				error: action.payload,
			};
		},
	},
});

export const {
	actions: defaultPageActions,
	reducer: defaultPageReducer,
	name: sliceKey,
} = defaultPageSlice;
