import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { RadioButtons } from 'app/components/RadiosButtons';
import { translations } from 'locales/i18n';

import { HEDGE_INTEGRATION_TYPE } from '../../types';

interface Props {
	disabled?: boolean;
}
export const HedgeIntegrationType = memo((props: Props) => {
	const { disabled } = props;
	const { t: translate } = useTranslation();

	const msg =
		translations.app.containers.Settings.sections.ERPIntegration.components
			.HedgeIntegration;

	return (
		<GenericForm.FormItem name="action">
			<RadioButtons
				disabled={disabled}
				name="action"
				key="action"
				disabledMode={'single'}
				options={[
					{
						label: translate(msg.action.automatic),
						value: HEDGE_INTEGRATION_TYPE.automatic,
					},
					{
						label: translate(msg.action.manual),
						value: HEDGE_INTEGRATION_TYPE.manual,
					},
				]}
			/>
		</GenericForm.FormItem>
	);
});
