/**
 *
 * PrivateRoute
 *
 */
import React, { useEffect } from 'react';
import { useAbac } from 'react-abac';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { Userpilot } from 'userpilot';

import {
	selectAuthUser,
	selectUserInfo,
} from 'app/containers/GlobalSaga/selectors';
import { ROUTES } from 'app/routes';
import { Permission } from 'types/Authorization';

interface Props {
	children?: JSX.Element;
	grant?: Permission;
	[key: string]: any;
}

export const PrivateProtectedRoute = ({
	children = <></>,
	grant = undefined,
	...rest
}: Props) => {
	const authUser = useSelector(selectAuthUser);
	const userInfo = useSelector(selectUserInfo);

	const location = useLocation();

	useEffect(() => {
		Userpilot.reload();
	}, [location]);

	const { userHasPermissions } = useAbac();
	const isAllowed = grant ? userHasPermissions(grant) : true;

	return (
		<Route
			{...rest}
			render={(props) => {
				if (!authUser || !userInfo) {
					return (
						<Redirect
							to={{
								pathname: ROUTES.LOGIN,
								state: { from: props.location },
							}}
						/>
					);
				} else if (!isAllowed) {
					return (
						<Redirect
							to={{
								pathname: ROUTES.DEFAULT,
								state: { from: props.location },
							}}
						/>
					);
				} else {
					return <>{children}</>;
				}
			}}
		/>
	);
};
