import { defineMessages } from 'react-intl';

const scope =
	'app.containers.Settings.sections.Bidsheet.View.List.Table.Delete';

export default defineMessages({
	confirmDelete: {
		id: `${scope}.confirmDelete`,
		defaultMessage: 'Do you want to delete this bid?',
	},
});
