import React, { useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { selectFeatureFlags } from 'app/containers/GlobalSaga/selectors';
import { actions } from 'app/containers/GlobalSaga/slice';
import { NotificationGroupDto } from 'app/containers/GlobalSaga/types';
import { FeatureFlag } from 'types/FeatureFlags';

import { Groups } from '../components/Groups';

export const useGroupsFormSchema = (
	currentSchema,
	formInstance: UseFormMethods,
	groups: NotificationGroupDto[] = [],
) => {
	const dispatch = useDispatch();
	const featureFlags = useSelector(selectFeatureFlags);

	useEffect(() => {
		dispatch(actions.loadEmployeeNotificationGroupList());
	}, []);

	useEffect(() => {
		if (featureFlags[FeatureFlag.enableNotifications]) {
			currentSchema.initialValues = {
				...currentSchema.initialValues,
				groups,
			};

			currentSchema.elements = (
				<>
					{currentSchema.elements}
					<Groups />
				</>
			);
			formInstance.reset();
		}
	}, [currentSchema]);
};
