import { CONTRACT_TYPES } from 'utils/constants';

import { useTranslations } from '../../../shared/useTranslations';
import { useBasisSchema } from './BasisSchema';
import { useFlatPriceSchema } from './FlatPriceSchema';
import { useHTASchema } from './HTASchema';

export const useOffersSchema = () => {
	const translations = useTranslations();
	return {
		[CONTRACT_TYPES.flatPrice]: useFlatPriceSchema(translations),
		[CONTRACT_TYPES.basis]: useBasisSchema(translations),
		[CONTRACT_TYPES.hta]: useHTASchema(translations),
	};
};
