import { Input } from 'antd';
import React, { memo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { useFormatPrice } from 'app/containers/Transactions/components/hooks/useFormatPrice';
import { translations } from 'locales/i18n';
import { ContractTypeSource } from 'types/ContractTypeSource';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import { preventWheelChange, preventWheelEvent } from 'utils/util';

import { SourceContext } from '../..';
import { useHandleBusinessRules } from '../hooks/useHandleBusinessRules';

interface Props {
	disabled?: boolean;
	callTotalPriceOnMount?: boolean;
}

export const FlatPrice = memo((props: Props) => {
	const { t: translate } = useTranslation();
	const { disabled, callTotalPriceOnMount = false } = props;

	const sourceName = useContext(SourceContext);

	const contractType =
		sourceName === GlobalSagaSource.contractModal
			? ContractTypeSource.CONTRACT
			: ContractTypeSource.OFFER;

	const msg = translations.app.containers.Transactions.components.FlatPrice;
	const inputName = 'flatPrice';

	const { formatPrice }: any = useFormatPrice();

	const {
		manageTotalPriceCall,
		manageSetFuturesPriceCall,
		manageSetPushBasis,
		setNetBasis,
	} = useHandleBusinessRules(sourceName);

	useEffect(() => {
		if (callTotalPriceOnMount) manageTotalPriceCall();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleChange = () => {
		manageSetFuturesPriceCall();
		manageSetPushBasis();
		setNetBasis();
	};

	return (
		<GenericForm.FormItem
			label={translate(msg[contractType].label)}
			name={inputName}
			onChange={handleChange}
			data-testid="flatPrice-form-item"
		>
			<Input
				key="flatPrice"
				data-testid="flatPrice-input-item"
				placeholder={translate(msg[contractType].placeholder)}
				type="number"
				onWheel={preventWheelChange}
				onFocus={preventWheelEvent}
				disabled={disabled}
				step={0.0001}
				onBlur={() => formatPrice(inputName)}
			/>
		</GenericForm.FormItem>
	);
});
