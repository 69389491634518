import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as commoditiesActions } from 'app/containers/Settings/sections/Commodities/slice';

import { selectBidsheetListType } from '../../selectors';
import { actions } from '../../slice';
import { Header } from './Header';
import { Table } from './Table';

export const List = () => {
	const dispatch = useDispatch();
	const bidsheetListType = useSelector(selectBidsheetListType);

	useEffect(() => {
		dispatch(commoditiesActions.loadCommoditiesList());
		dispatch(actions.loadBidsheetList({ type: bidsheetListType }));
		return () => {
			dispatch(
				actions.setPagination({
					start: 1,
				}),
			);
			dispatch(
				actions.setSelectedFilters({
					commodities: [],
					locations: [],
					cropYears: [],
				}),
			);
			dispatch(actions.bidsheetListClear());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="list-container">
			<Header />
			<Table />
		</div>
	);
};
