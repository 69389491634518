/**
 *
 * GenericReport
 *
 */

import './styles.scss';

import { Divider, Spin } from 'antd';
import React, { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { translations } from 'locales/i18n';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { FiltersGenerator } from './components/FiltersGenerator';
import { ReportSelector } from './components/ReportSelector';
import { TableBody } from './components/TableBody';
import { genericReportSaga } from './saga';
import {
	selectCurrentReport,
	selectTableDataLoaded,
	selectTableDataLoading,
	selectTableSchemaLoading,
} from './selectors';
import {
	genericReportActions,
	genericReportReducer,
	genericReportSliceKey,
} from './slice';

interface Props {
	mode?: 'simple' | 'form';
	reportKey?: Object;
}

export const GenericReport = memo((props: Props) => {
	const { mode = 'simple', reportKey } = props;

	useInjectReducer({
		key: genericReportSliceKey,
		reducer: genericReportReducer,
	});
	useInjectSaga({ key: genericReportSliceKey, saga: genericReportSaga });

	const currentReport = useSelector(selectCurrentReport);
	const dataTableLoaded = useSelector(selectTableDataLoaded);
	const dataTableLoading = useSelector(selectTableDataLoading);
	const tableSchemaLoading = useSelector(selectTableSchemaLoading);

	const dispatch = useDispatch();

	useEffect(() => {
		if (!reportKey) {
			dispatch(genericReportActions.loadReportsList());
			dispatch(genericReportActions.setCurrentReport(null));
			dispatch(genericReportActions.setSelectedFilters({}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { t: translate } = useTranslation();

	return (
		<>
			<Helmet>
				<title>
					{translate(translations.app.containers.GenericReport.customReport)}
				</title>
				<meta name="description" content="Description of Generic Report" />
			</Helmet>
			<ReportSelector reportKey={reportKey} />
			{tableSchemaLoading ? (
				<Spin />
			) : (
				!!currentReport && <FiltersGenerator mode={mode} />
			)}
			<Divider />
			{dataTableLoading && !dataTableLoaded ? (
				<Spin />
			) : (
				dataTableLoaded && <TableBody />
			)}
		</>
	);
});
