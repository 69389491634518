import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const {
	getLocationsSettingByEmployee,
	getLegacyLocationsSettingByEmployee,
	updateLocationsSettingByEmployee,
	updateLegacyLocationsSettingByEmployee,
} = apiEndpoints;

function createURL(
	base: string,
	endpoint: string,
	id: string = '',
	params = {},
) {
	const url = new URL(`${base}/${endpoint}/${id}`);
	Object.keys(params).forEach((key) =>
		url.searchParams.append(key, params[key]),
	);
	return url;
}

export function* getLocationsByEmployee(action) {
	const { id, regionId = null, isLegacyLocations = false } = action.payload;
	if (!id) return;

	const endpoint = isLegacyLocations
		? getLegacyLocationsSettingByEmployee
		: getLocationsSettingByEmployee;
	const requestURL = createURL(
		apiRoutes.base,
		endpoint,
		id,
		regionId !== null ? { regionId } : {},
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);
	if (!!responseData) {
		yield put(actions.employeeLocationsLoaded(responseData.list));
	} else if (!!responseError.detail) {
	}
}

export function* updateLocationsByEmployee(action) {
	const { data, isLegacyLocations = false } = action.payload;
	const endpoint = isLegacyLocations
		? updateLegacyLocationsSettingByEmployee
		: updateLocationsSettingByEmployee;
	const requestURL = createURL(apiRoutes.base, endpoint);
	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		data,
	);

	if (!!responseData) {
		yield put(actions.employeeLocationsUpdated());
	} else if (!!responseError.detail) {
	}
}
