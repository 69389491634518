import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { preHedgeStates } = apiEndpoints;

export function* getPreHedgeStates(action: PayloadAction<any | undefined>) {
	const requestURL = yield new URL(`${apiRoutes.base}/${preHedgeStates}`);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.preHedgeStatesLoaded(responseData));
	} else if (!!responseError) {
		yield put(actions.preHedgeStatesError(responseError));
	}
}
