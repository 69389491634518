import './style.scss';

import { ArrowsAltOutlined, CloseOutlined } from '@ant-design/icons';
import { Card, Col, Row, Spin } from 'antd';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { TabsWrapper } from 'app/components/TabsWrapper';
import { translations } from 'locales/i18n';

import useAutoDispatch from '../../customHooks/useAutoDispatch';
import {
	selectFuturesQoutesData,
	selectFuturesQoutesLoading,
	selectSelectedFilters,
} from '../../selectors';
import { actions } from '../../slice';
import { FuturesQouteTab } from '../../types';
import { CommonTable } from './components/CommonTable';
import { Filters } from './components/Filters';

interface Props {
	setIsFuturesQuotesMaximize: Dispatch<SetStateAction<boolean>>;
	isFuturesQuotesMaximize: boolean;
}

export function FuturesQoutes(props: Props) {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const { setIsFuturesQuotesMaximize, isFuturesQuotesMaximize } = props;

	const selectedFilters = useSelector(selectSelectedFilters);
	const FuturesQoutesData = useSelector(selectFuturesQoutesData);
	const FuturesQoutesLoading = useSelector(selectFuturesQoutesLoading);

	const translationsScope =
		translations.app.containers.CashbidsAndQuotes.FuturesQoutes;

	const isAnyCommoditySelected =
		selectedFilters.products && selectedFilters.products.length > 0;

	const [FuturesQoutesTabs, setFuturesQoutesTabs] = React.useState<
		FuturesQouteTab[]
	>([]);

	//TODO:* remove the below code when websocket/signalR is avalable
	useAutoDispatch(
		FuturesQoutesData?.length > 0,
		() => dispatch(actions.loadFutureQuotes()),
		60000,
	);

	useEffect(() => {
		let tabs: FuturesQouteTab[] = [];
		FuturesQoutesData?.forEach((item) => {
			tabs.push({
				name: item.productName,
				content: (
					<CommonTable
						commodity={item.productName}
						futuresQouteData={item.priceItems}
					/>
				),
			});
		});
		setFuturesQoutesTabs(tabs);
	}, [FuturesQoutesData]);

	// Hanlde resizing of CashBids And Futures Qoutes section
	const handleResizeSection = () => {
		setIsFuturesQuotesMaximize(!isFuturesQuotesMaximize);
	};

	const handleTabChange = (commodityName: string) => {
		let key = selectedFilters.products.find(
			(item) => item.label === commodityName,
		)?.value;
		dispatch(actions.activeCommoityInFuture(key));
	};

	return (
		<Card bordered={true} className="futures-qoute">
			<div className="futures-qoute__data">
				<Row className="grid-menu" gutter={16}>
					<Col span={10}>
						<Filters data-testid="futuresQoute-filters" />
					</Col>
					<Col span={2}>
						{!isFuturesQuotesMaximize ? (
							<ArrowsAltOutlined
								data-testid="arrows-icon"
								onClick={handleResizeSection}
								className="futures-qoute__icon"
							/>
						) : (
							<CloseOutlined
								onClick={handleResizeSection}
								className="futures-qoute__icon"
							/>
						)}
					</Col>
				</Row>
				<div className="futures-qoute__table-parent">
					{FuturesQoutesLoading && (
						<div className="futures-qoute--warning-writeup">
							<Spin />
						</div>
					)}
					{isAnyCommoditySelected ? (
						<TabsWrapper
							handleOnClick={handleTabChange}
							classname={'futures-qoute__tabs'}
							tabs={FuturesQoutesTabs}
							type="card"
						/>
					) : (
						<div className="futures-qoute--warning-writeup">
							{' '}
							<h1>{translate(translationsScope.futuresQouteWarningWriteup)}</h1>
						</div>
					)}
				</div>
			</div>
		</Card>
	);
}
