import { CONSTANTS, EMPTY_COLUMN } from 'utils/constants';
import { currencyFormat } from 'utils/helpers';

const getFirstCharacter = (value: string | undefined) =>
	!!value ? value.charAt(0) : null;

export const getColumnValue = (value: string | undefined) =>
	value || EMPTY_COLUMN;

export const getEmployeeInitials = (
	employee: { firstName: string; lastName: string } | null,
) => ({
	initialFirstName: getFirstCharacter(employee?.firstName),
	initialLastName: getFirstCharacter(employee?.lastName),
});

export const getFormattedCurrency = (value: number) =>
	!!value
		? currencyFormat(value, CONSTANTS.FIXED_DECIMALS, CONSTANTS.FIXED_DECIMALS)
		: EMPTY_COLUMN;
