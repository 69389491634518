import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { settingsActions } from '../../slice';
import { DeleteUserRole } from './Modals/DeleteUserRole';
import { selectCurrentModal, selectCurrentView } from './selectors';
import { userRolesActions } from './slice';
import { Modal, View } from './types';
import { CreateEditRole } from './View/CreateEditRole';
import { List } from './View/List';

export const UserRole = () => {
	const dispatch = useDispatch();
	const currentModal = useSelector(selectCurrentModal);
	const currentView = useSelector(selectCurrentView);

	useEffect(() => {
		dispatch(settingsActions.loadLocationsListForSettings());

		return () => {
			dispatch(userRolesActions.setCurrentView(View.List));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{currentView === View.Create || currentView === View.Edit ? (
				<CreateEditRole />
			) : (
				<List />
			)}
			{currentModal === Modal.Delete && <DeleteUserRole />}
		</>
	);
};
