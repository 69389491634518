import { Button, Col, Row } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ClearFilters } from 'app/components/ClearFilter';
import {
	selectSelectedFilters,
	selectSelectedRows,
} from 'app/containers/FuturesErp/selectors';
import { translations } from 'locales/i18n';
import { FILTERS_FOR_FUTURESERP } from 'utils/constants';
import { countFilledFilters } from 'utils/helpers';
import { initialFilters } from 'utils/initial-filters';

import { actions } from '../../../../slice';
import { Filters } from './Filters';

export const ListMenu = memo(() => {
	const [activeFilterCount, setActiveFilterCount] = useState(0);
	const selectedFilters = useSelector(selectSelectedFilters);
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const domain = translations.app.containers.FuturesErp;

	const selectedRowsData = useSelector(selectSelectedRows);
	const selectedRowKeys = selectedRowsData.map(({ id }) => id);

	const canBeIntegrated = selectedRowKeys.length > 0;

	const handleErpIntegration = () => {
		if (canBeIntegrated) {
			dispatch(actions.integrateToErpRequest(selectedRowKeys));
		}
	};

	useEffect(() => {
		setActiveFilterCount(countFilledFilters(selectedFilters));
	}, [selectedFilters]);

	return (
		<Row className="grid-menu" gutter={8}>
			<Col xs={12} sm={12} md={12} lg={9} xl={9}>
				<ClearFilters
					activeFilterCount={activeFilterCount}
					localStorageKey={FILTERS_FOR_FUTURESERP}
					loadGrid={actions.loadFuturesErpList()}
					seFilters={actions.setSelectedFilters(
						initialFilters.futuresErpFilters,
					)}
					grid={'futuresErpFilters'}
				/>
				<Filters />
			</Col>
			<Col xs={12} sm={12} md={12} lg={3} xl={3}>
				<Row justify="end">
					<Button
						onClick={handleErpIntegration}
						className="topbar-menu-container__button"
						disabled={!canBeIntegrated}
						type="primary"
					>
						{translate(domain.integrate)}
					</Button>
				</Row>
			</Col>
		</Row>
	);
});
