import './styles.scss';

import { Form } from 'antd';
import { useFormikContext } from 'formik';
import React, { memo, useEffect } from 'react';

interface Props {
	name: string;
	id: string;
	hasSelectedRows: boolean;
	children?: JSX.Element;
}
export const FormTableWrapper = memo(
	({ children, name, id, hasSelectedRows }: Props) => {
		const formik = useFormikContext();
		useEffect(() => {
			formik.setFieldValue(name, hasSelectedRows);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [hasSelectedRows]);

		return (
			<Form.Item
				name={name}
				key={id}
				label={undefined}
				className="form-generic-table-wrapper "
			>
				{children}
			</Form.Item>
		);
	},
);
