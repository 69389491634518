import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { ContractTypeSource } from 'types/ContractTypeSource';
import { DeliveryDate } from 'types/DeliveryDate';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import {
	LoadDeliveryDatesPayload,
	LoadFuturesMonthPayload,
	LoadPostedBasisPricePayload,
} from '../types';

const { deliveryDates, futuresMonth, futuresMonthOffers, postedBasisPrice } =
	apiEndpoints;

export function* getDeliveryDates(
	action: PayloadAction<LoadDeliveryDatesPayload>,
) {
	const source = action.payload.source;
	const params = action.payload.params;

	if (!params.commodityId || !params.cropYear || !params.locationId) {
		return;
	}

	let requestURL = yield new URL(`${apiRoutes.base}/${deliveryDates}`);

	requestURL.searchParams.append('CommodityId', params.commodityId);
	requestURL.searchParams.append('cropYear', params.cropYear);
	requestURL.searchParams.append('LocationId', params.locationId);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(
			actions.deliveryDatesLoaded({
				source,
				data: responseData.deliveryDates as DeliveryDate[],
			}),
		);
	} else if (!!responseError) {
		yield put(
			actions.deliveryDatesError({
				source,
				error: responseError,
			}),
		);
	}
}

export function* getFuturesMonth(
	action: PayloadAction<LoadFuturesMonthPayload>,
) {
	const source = action.payload.source;
	const params = action.payload.params;
	const type = action.payload.type;

	if (
		!params.cropYear ||
		!params.deliveryStartDate ||
		!params.deliveryEndDate
	) {
		return;
	}
	if (
		type === ContractTypeSource.OFFER &&
		(!params.commodity || !params.location)
	) {
		return;
	}
	const endpoint =
		type === ContractTypeSource.CONTRACT ? futuresMonth : futuresMonthOffers;

	let requestURL = yield new URL(`${apiRoutes.base}/${endpoint}`);
	requestURL.searchParams.append('CropYear', params.cropYear);
	requestURL.searchParams.append('DeliveryStart', params.deliveryStartDate);
	requestURL.searchParams.append('DeliveryEnd', params.deliveryEndDate);
	requestURL.searchParams.append('CommodityId', params.commodity);

	if (type === ContractTypeSource.OFFER) {
		requestURL.searchParams.append('DeliveryLocationId', params.location);
	}

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(
			actions.futuresMonthLoaded({
				source,
				data: responseData.futuresMonth,
			}),
		);
	} else if (!!responseError) {
		yield put(
			actions.futuresMonthError({
				source,
				error: responseError,
			}),
		);
	}
}

export function* getPostedBasisPrice(
	action: PayloadAction<LoadPostedBasisPricePayload>,
) {
	const source = action.payload.source;
	const params = action.payload.params;

	if (
		!params.commodityId ||
		!params.deliveryStartDate ||
		!params.deliveryEndDate ||
		!params.cropYear ||
		!params.locationId ||
		!params.deliveryLocationId
	) {
		return;
	}

	let requestURL = yield new URL(`${apiRoutes.base}/${postedBasisPrice}`);
	requestURL.searchParams.append('CommodityId', params.commodityId);
	requestURL.searchParams.append('DeliveryStartDate', params.deliveryStartDate);
	requestURL.searchParams.append('DeliveryEndDate', params.deliveryEndDate);
	requestURL.searchParams.append('CropYear', params.cropYear);
	requestURL.searchParams.append('LocationId', params.locationId);
	requestURL.searchParams.append(
		'DeliveryLocationId',
		params.deliveryLocationId,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(
			actions.postedBasisPriceLoaded({
				source,
				data: responseData,
			}),
		);
	} else if (!!responseError) {
		yield put(
			actions.postedBasisPriceError({
				source,
				error: responseError,
			}),
		);
	}
}
