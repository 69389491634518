import { GenericOption } from 'types/GenericOption';

import { ReviewAndReleaseFilters } from '../types';

export const getSelectedFilter = (
	filter: string,
	key: string,
	selectedFilters: ReviewAndReleaseFilters,
) =>
	!!selectedFilters[filter] && selectedFilters[filter].length > 0
		? selectedFilters[filter]
				.filter((element: GenericOption) => element[key] !== 'all')
				.map((element: GenericOption) => element[key])
		: null;

export const productToLabelValue = (array: any[]) =>
	array?.map(({ fullDescription, id }) => {
		const symbol = fullDescription?.split('-')?.[0].trim();

		return {
			label: symbol,
			value: id,
		};
	});
