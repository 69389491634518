import moment from 'moment';

import { contract } from 'app/containers/Contracts/types';

// create a generic function from above functions
export const hasSameValue = (
	selectedRowsData: contract[],
	key: keyof contract,
): number | boolean => {
	return (
		selectedRowsData.length &&
		// Two equal objects always gives false in js so converting them to string
		selectedRowsData.every(
			(item) => item[key].toString() === selectedRowsData[0][key].toString(),
		)
	);
};

export const hasSameFututreMonth = (
	selectedRowsData: contract[],
	futureMonth: string,
): number | boolean => {
	return (
		selectedRowsData.length &&
		selectedRowsData.every(({ price }) => price.futuresMonth === futureMonth)
	);
};

export const haveSameFees = (contracts: contract[]): boolean => {
	if (contracts.length === 0) {
		return false;
	}

	// Get the fees array from the first contract
	const firstFees = contracts[0].price.fees;

	// Check if all contracts have the same fees length
	if (
		!contracts.every(
			(contract) => contract.price.fees.length === firstFees.length,
		)
	) {
		return false;
	}

	// If all fee arrays are empty, return true
	if (firstFees.length === 0) {
		return true;
	}

	// Check consistency for each fee index
	for (let feeIndex = 0; feeIndex < firstFees.length; feeIndex++) {
		const expectedFee = firstFees[feeIndex];
		if (!contracts.every(({ price }) => price.fees[feeIndex] === expectedFee)) {
			return false;
		}
	}

	return true;
};

export const hasSameDeliveryDates = (selectedRowsData: contract[]): boolean => {
	if (!selectedRowsData.length) return false;

	const { delivery: firstDelivery } = selectedRowsData[0];
	const firstBegin = moment(firstDelivery.begin);
	const firstEnd = moment(firstDelivery.end);

	return selectedRowsData.every((item) => {
		const { delivery } = item;
		const deliveryBegin = moment(delivery.begin);
		const deliveryEnd = moment(delivery.end);

		return firstBegin.isSame(deliveryBegin) && firstEnd.isSame(deliveryEnd);
	});
};

export const hasSameCustomFieldValues = (
	selectedRowsData: contract[],
): boolean => {
	if (!selectedRowsData.length) return false;

	const { customFields: firstCustomFields } = selectedRowsData[0];

	return selectedRowsData.every((entry) => {
		const { customFields } = entry;
		return customFields.every((field, index) => {
			return field.value === firstCustomFields[index].value;
		});
	});
};
