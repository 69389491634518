import { PayloadAction } from '@reduxjs/toolkit';

import { UserInternalNotification } from 'types/UserNotification';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { MAX_USER_NOTIFICATION_RECORDS } from 'utils/constants';
import { generateNotificationLink } from 'utils/helpers';

import { NotificationAlertTone } from '../GlobalSaga/types';
import { ContainerState } from './types';

// The initial state of the SignalR hub
export const initialState: ContainerState = {
	userNotifications: {
		data: [],
		error: null,
		loading: false,
	},
	userAlertTone: null,
};

const hubSagaSlice = createSlice({
	name: 'hubSaga',
	initialState,
	reducers: {
		// SignalR connection
		initHubConnection() {},

		// Loading Notification List
		notificationListLoaded(
			state,
			action: PayloadAction<UserInternalNotification[]>,
		) {
			const data = [...action.payload].slice(0, MAX_USER_NOTIFICATION_RECORDS);

			state.userNotifications = {
				...state.userNotifications,
				data: data.map((item) => ({
					...item,
					path: generateNotificationLink(item),
				})),
				loading: false,
			};
		},
		// Receiving a new notification record
		notificationItemLoaded(
			state,
			action: PayloadAction<UserInternalNotification>,
		) {
			const data = [action.payload, ...state.userNotifications.data].slice(
				0,
				MAX_USER_NOTIFICATION_RECORDS,
			);

			state.userNotifications = {
				...state.userNotifications,
				data: data.map((item) => ({
					...item,
					path: generateNotificationLink(item),
				})),
				loading: false,
			};
		},
		playNotificationSound() {},

		dismissNotificationItem(state, action: PayloadAction<string>) {
			const data = state.userNotifications.data.filter(
				(item) => item.id !== action.payload,
			);

			state.userNotifications = {
				...state.userNotifications,
				data,
			};
		},
		dismissAllNotifications() {},

		clearNotificationsList(state) {
			state.userNotifications = {
				...state.userNotifications,
				data: [],
			};
		},
		markNotificationsAsSeen(state) {
			state.userNotifications = {
				...state.userNotifications,
				data: state.userNotifications.data.map((item) => ({
					...item,
					dateSeen: new Date(),
				})),
			};
		},
		// Bell Notifications
		getActiveAlertTone(state) {},
		setActiveAlertTone(state, action: PayloadAction<NotificationAlertTone>) {
			state.userAlertTone = action.payload;
		},

		// Connection
		startSignalRConnection() {},
		stopSignalRConnection() {},

		// Auto-refresh Subscriptions
		subscribeToContractUpdates() {},
		unsubscribeFromContractUpdates() {},

		subscribeToOfferUpdates() {},
		unsubscribeFromOfferUpdates() {},

		subscribeToReviewAndReleaseUpdates() {},
		unsubscribeFromReviewAndReleaseUpdates() {},

		subscribeToFuturesErpUpdates() {},
		unsubscribeFromFuturesErpUpdates() {},

		subscribeToStatusTransactionUpdates() {},

		subscribeToPreHedgeUpdates() {},
		unsubscribeFromPreHedgeUpdates() {},
	},
});

export const { actions, reducer, name: sliceKey } = hubSagaSlice;
