import { HubConnection } from '@microsoft/signalr';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { ActionType, PushNotification } from 'app/containers/HubSaga/types';
import { actions } from 'app/containers/ReviewAndRelease/slice';
import { ReviewAndReleaseStatusUpdate } from 'app/containers/ReviewAndRelease/types';
import { throttledDispatcher } from 'utils/store-util';

import { hubSubscriptionChannels, THROTTLE_TIME } from '../constants';

export function reviewAndReleaseHandler(
	connection: HubConnection,
	dispatch: Dispatch<AnyAction>,
) {
	const throttledUpdate = throttledDispatcher<ReviewAndReleaseStatusUpdate>(
		dispatch,
		actions.reviewAndReleaseUpdated,
		THROTTLE_TIME,
	);
	const throttledLoad = throttledDispatcher<void>(
		dispatch,
		() => actions.loadReviewAndReleaseList(),
		THROTTLE_TIME,
	);

	connection.on(
		hubSubscriptionChannels.reviewAndReleaseUpdates,
		(pushNotification: PushNotification<ReviewAndReleaseStatusUpdate>) => {
			if (pushNotification.action === ActionType.Update) {
				throttledUpdate(pushNotification.data);
			} else {
				throttledLoad();
			}
		},
	);

	connection.on(hubSubscriptionChannels.statusTransactionUpdates, (data) => {
		dispatch(globalActions.getReviewAndReleaseOrderStatusSuccess(data));
	});
}
