import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { translations } from 'locales/i18n';

interface Props {
	disabled?: {
		qtyRollBalance?: boolean;
		qtyRollAmount?: boolean;
	};
}

export const QuantityToRoll = memo((props: Props) => {
	const { t: translate } = useTranslation();
	const { disabled } = props;

	const msg = translations.app.containers.Contracts.components.QuantityToRoll;

	return (
		<GenericForm.FormItem
			label={translate(msg.quantityToRollLabel)}
			name="quantityToRoll"
			data-testid="quantityToRoll-form-item"
		>
			<GenericForm.FormItem
				name="qtyRollBalance"
				data-testid="qtyRollBalance-form-item"
				customClassName={['input-quantity__label']}
			>
				<Input
					className="input-quantity__input"
					data-testid="qtyRollBalance-input-item"
					type="text"
					disabled={disabled?.qtyRollBalance}
					name="qtyRollBalance"
					suffix={<>{translate(msg.balance.label)}</>}
				/>
			</GenericForm.FormItem>
			<GenericForm.FormItem
				name="qtyRollAmount"
				data-testid="qtyRollAmount-form-item"
				customClassName={['input-quantity__label']}
			>
				<Input
					className="input-quantity__input"
					placeholder={translate(msg.quantityToRollPlaceholder)}
					data-testid="qtyRollAmount-input-item"
					type="text"
					disabled={disabled?.qtyRollAmount}
					name="qtyRollAmount"
				/>
			</GenericForm.FormItem>
		</GenericForm.FormItem>
	);
});
