/**
 *
 * ModalContainer
 *
 */

import './style.scss';

import Modal, { ModalProps } from 'antd/lib/modal/Modal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { memo } from 'react';

interface ModalContainerProps
	extends Omit<
		ModalProps,
		| 'cancelText'
		| 'cancelButtonProps'
		| 'onOk'
		| 'okText'
		| 'okButtonProps'
		| 'okType'
	> {
	children: JSX.Element;
}

export const ModalContainer = memo((props: ModalContainerProps) => {
	const { ...modalProps } = props;

	const { modalResponsive, modalResponsiveHorizontal } = useFlags();
	return (
		<Modal
			visible={true}
			width="auto"
			maskClosable={false}
			centered={true}
			footer={null}
			{...modalProps}
			className={`generic-form-modal-container ${props.className} ${modalResponsive ? (modalResponsiveHorizontal ? 'horizontal' : 'vertical') : ''}`}
		/>
	);
});
