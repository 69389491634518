import { MoreOutlined } from '@ant-design/icons';
import { Button, Checkbox, Popover, Typography } from 'antd';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GenericTable } from 'app/components/GenericTable';
import {
	selectFilters,
	selectFixedColumns,
	selectListData,
	selectListLoading,
	selectListTotal,
	selectPagination,
	selectSelectedColumns,
	selectSelectedRows,
} from 'app/containers/ReviewAndRelease/selectors';
import { actions } from 'app/containers/ReviewAndRelease/slice';
import {
	OrderStatus,
	ReviewAndReleaseFixedColumns,
	ReviewAndReleaseRecord,
} from 'app/containers/ReviewAndRelease/types';
import { translations } from 'locales/i18n';
import { FixedColumn } from 'types/FixedColumn';
import { CONSTANTS } from 'utils/constants';
import { currencyFormat, dateFormat, timeFormat } from 'utils/helpers';

import { ActionsMenu } from './ActionsMenu';

const { Paragraph } = Typography;

export const Table = () => {
	const dispatch = useDispatch();
	const listData = useSelector(selectListData);
	const totalList = useSelector(selectListTotal);
	const loadingList = useSelector(selectListLoading);
	const pagination = useSelector(selectPagination);
	const filters = useSelector(selectFilters);
	const selectedRowsData = useSelector(selectSelectedRows);
	const selectedColumns = useSelector(selectSelectedColumns);
	const { showUserColumnPreference } = useFlags();
	let fixedColumns = useSelector(selectFixedColumns);
	if (!showUserColumnPreference) {
		fixedColumns = {
			created: 'left',
			updateDate: 'left',
			status: false,
			account: false,
			orderNumber: false,
			sellBuy: false,
			source: false,
			symbol: false,
			futuresMonth: false,
			type: false,
			gtc: false,
			price: false,
			avgFillPrice: false,
			quantity: false,
			workingQty: false,
			customer: false,
			hrvystId: false,
			dots: 'right',
		};
	}

	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.ReviewAndRelease.components.Views.List.Table;

	const handleOnChange = (page, pageSize) => {
		dispatch(
			actions.setPagination({
				limit: pageSize,
				start: page,
			}),
		);
		dispatch(actions.loadReviewAndReleaseList());
	};

	const gtcLabelValue = (data) => {
		if (data.type === 'LimitOrder') {
			return !!data?.gtc?.isGTC
				? translate(translationsScope.isGtc)
				: translate(translationsScope.isGtcFalse);
		} else {
			return '----';
		}
	};

	function getStatusDot(
		record: ReviewAndReleaseRecord,
	): null | { message: string; className: string } {
		if (record.status === 'Rejected' && record.wasAcknowledge) {
			return {
				message: translationsScope.rejectedWithAcknowledge,
				className: 'inline-dot--ok',
			};
		} else if (record.status === 'Rejected' && !record.wasAcknowledge) {
			return {
				message: translationsScope.rejectedWithOutAcknowledge,
				className: 'inline-dot--error',
			};
		} else if (record.isEditReject) {
			return {
				message: translationsScope.editRejected,
				className: 'inline-dot--error',
			};
		}
		return null;
	}

	const columns: {
		name: keyof ReviewAndReleaseFixedColumns;
		title: string;
		key?: string;
		width?: number;
		className: string;
		fixed: FixedColumn;
		dataIndex?: string;
		render: (data, obj?) => JSX.Element;
	}[] = [
		{
			name: 'created',
			title: translate(translationsScope.created),
			key: 'creationDate',
			width: 150,
			fixed: fixedColumns['created'],
			className: 'review-release-column md',
			render: (data) => (
				<GenericTable.Column>
					<div>
						<p className="text text--bold text--large">
							{dateFormat(data.creationDate)}
						</p>
						<p className="text text--label">{timeFormat(data.creationDate)}</p>
					</div>
				</GenericTable.Column>
			),
		},
		{
			name: 'updateDate',
			title: translate(translationsScope.updateDate),
			key: 'updateDate',
			fixed: fixedColumns['updateDate'],
			className: 'review-release-column md',
			render: (data) => (
				<GenericTable.Column>
					<div>
						{data.updateDate ? (
							<>
								<p className="text text--large">
									{dateFormat(data.updateDate)}
								</p>
								<p className="text text--label">
									{timeFormat(data.updateDate)}
								</p>
							</>
						) : (
							'----'
						)}
					</div>
				</GenericTable.Column>
			),
		},
		{
			name: 'status',
			title: translate(translationsScope.status),
			key: 'status',
			fixed: fixedColumns['status'],
			className: 'review-release-column md',
			render: (data) => (
				<GenericTable.Column>
					<div>
						<p className="text text--large">{data.status}</p>
						{getStatusDot(data) && (
							<Popover
								content={
									<div className="popover--content">
										<strong>
											{translate(getStatusDot(data)?.message ?? '')}
										</strong>
									</div>
								}
								trigger="hover"
							>
								<span
									className={classNames(
										'inline-dot',
										getStatusDot(data)?.className,
									)}
									data-testid="inline-dot"
								/>
							</Popover>
						)}
					</div>
				</GenericTable.Column>
			),
		},
		{
			name: 'account',
			title: translate(translationsScope.account),
			dataIndex: 'accountName',
			key: 'accountName',
			fixed: fixedColumns['account'],
			width: 150,
			className: 'review-release-column md',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			name: 'orderNumber',
			title: translate(translationsScope.orderNumber),
			key: 'orderNumber',
			fixed: fixedColumns['orderNumber'],
			className: 'review-release-column md',
			render: (data) => (
				<GenericTable.Column>
					<div>
						{data.orderNumber ? (
							<span className="text text--bold text--large">
								{data.orderNumber}
							</span>
						) : (
							'----'
						)}
					</div>
				</GenericTable.Column>
			),
		},
		{
			name: 'sellBuy',
			title: translate(translationsScope.sellBuy),
			key: 'sellBuy',
			fixed: fixedColumns['sellBuy'],
			className: 'review-release-column sm',
			render: (data) => (
				<GenericTable.Column>
					<div>
						<p className="text text--large">
							{data.isSell
								? translate(translationsScope.sellLabel)
								: translate(translationsScope.buyLabel)}
						</p>
					</div>
				</GenericTable.Column>
			),
		},
		{
			name: 'source',
			title: translate(translationsScope.source),
			dataIndex: 'source',
			key: 'source',
			fixed: fixedColumns['source'],
			width: 150,
			className: 'review-release-column md',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						<p className="text text--bold text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			name: 'symbol',
			title: translate(translationsScope.symbol),
			dataIndex: 'symbol',
			key: 'symbol',
			fixed: fixedColumns['symbol'],
			width: 60,
			className: 'review-release-column sm',
			render: (data) => (
				<GenericTable.Column>
					<div>
						<p className="text text--large">{data.code}</p>
					</div>
				</GenericTable.Column>
			),
		},
		{
			name: 'futuresMonth',
			title: translate(translationsScope.futuresMonth),
			dataIndex: 'futuresMonth',
			key: 'futuresMonth',
			fixed: fixedColumns['futuresMonth'],
			width: 80,
			className: 'review-release-column md',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis text--centered"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						{data}
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			name: 'type',
			title: translate(translationsScope.type),
			dataIndex: 'typeName',
			key: 'typeName',
			fixed: fixedColumns['type'],
			width: 150,
			className: 'review-release-column md',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis text--centered"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						{data}
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			name: 'gtc',
			title: translate(translationsScope.gtc),
			key: 'gtc',
			fixed: fixedColumns['gtc'],
			className: 'review-release-column sm',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis text--centered"
						title={gtcLabelValue(data)}
						ellipsis={{ rows: 2 }}
					>
						{gtcLabelValue(data)}
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			name: 'price',
			title: translate(translationsScope.price),
			dataIndex: 'price',
			key: 'price',
			fixed: fixedColumns['price'],
			className: 'review-release-column md',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis text--centered"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						{data
							? currencyFormat(
									data,
									CONSTANTS.FIXED_DECIMALS,
									CONSTANTS.FIXED_DECIMALS,
								)
							: '---'}
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			name: 'avgFillPrice',
			title: translate(translationsScope.avgFillPrice),
			dataIndex: 'avgFillPrice',
			key: 'avgFillPrice',
			fixed: fixedColumns['avgFillPrice'],
			className: 'review-release-column md',
			render: (data, obj) => {
				return (
					<GenericTable.Column>
						<Paragraph
							className="text text--large ellipsis text--centered"
							title={data}
							ellipsis={{ rows: 2 }}
						>
							{!!!obj.passFill || obj.status === OrderStatus.Filled
								? currencyFormat(
										data,
										CONSTANTS.FIXED_DECIMALS,
										CONSTANTS.FIXED_DECIMALS,
									)
								: '---'}
						</Paragraph>
					</GenericTable.Column>
				);
			},
		},
		{
			name: 'quantity',
			title: translate(translationsScope.quantity),
			dataIndex: 'quantity',
			key: 'quantity',
			fixed: fixedColumns['quantity'],
			className: 'review-release-column md',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis text--centered"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						{data}
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			name: 'workingQty',
			title: translate(translationsScope.workingQty),
			dataIndex: 'workingQuantity',
			key: 'workingQuantity',
			fixed: fixedColumns['workingQty'],
			className: 'review-release-column md',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis text--centered"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						{data}
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			name: 'customer',
			title: translate(translationsScope.customer),
			dataIndex: 'customer',
			key: 'customer',
			fixed: fixedColumns['customer'],
			className: 'column-table md md--content-limit',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--centered text--small line-height">
						{data?.number}
					</p>
					<Paragraph
						className="text text--centered text--small line-height"
						title={data?.name}
						ellipsis={{ rows: 2 }}
					>
						{data?.name}
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			name: 'hrvystId',
			title: translate(translationsScope.hrvystId),
			dataIndex: 'internalCode',
			key: 'internalCode',
			fixed: fixedColumns['hrvystId'],
			className: 'review-release-column md',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis text--centered"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						<span className="text text--bold text--large">{data}</span>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			name: 'dots',
			title: '',
			fixed: fixedColumns['dots'],
			width: 35,
			className: 'review-release-column xs',
			render: (data) => (
				<GenericTable.Column>
					<Popover
						content={<ActionsMenu rowData={data} />}
						trigger="click"
						data-testid="more-actions-trigger"
					>
						<Button type="text" icon={<MoreOutlined />} />
					</Popover>
				</GenericTable.Column>
			),
		},
	];

	columns.sort((a, b) => {
		const first = a.fixed === 'left' ? 0 : a.fixed === false ? 1 : 2;
		const second = b.fixed === 'left' ? 0 : b.fixed === false ? 1 : 2;

		if (first === second) {
			return 0;
		} else if (first < second) {
			return -1;
		} else {
			return 1;
		}
	});

	const selectedRowKeys = selectedRowsData.map(({ id }) => id);

	const onChangeSelection = (_selectedIDs, selectedRowsData) => {
		dispatch(actions.setSelectedRows(selectedRowsData));
	};

	const selectionColumnHeader =
		filters?.status?.[0]?.value === 'all' ? (
			<Checkbox disabled={true}></Checkbox>
		) : undefined;

	let newColumns = columns
		.filter((col) => selectedColumns[col.name])
		.map((col) => {
			return {
				...col,
				fixed: fixedColumns[col.name],
			};
		});

	return (
		<GenericTable.VirtualTable
			className="table-container"
			columns={newColumns}
			data={listData}
			loading={loadingList}
			pagination={{
				total: totalList,
				pageSize: pagination.limit,
				current: pagination.start,
				defaultPageSize: 50,
				showSizeChanger: true,
				onChange: handleOnChange,
			}}
			rowSelection={{
				onChange: onChangeSelection,
				columnTitle: selectionColumnHeader,
				preserveSelectedRowKeys: false,
				fixed: true,
				columnWidth: 30,
				selectedRowKeys,
			}}
		/>
	);
};
