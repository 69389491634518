import './style.scss';

import { Button, Modal, Row } from 'antd';
import React from 'react';
import { useAbac } from 'react-abac';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { actions as contractAction } from 'app/containers/Contracts/slice';
import { ContractsModals } from 'app/containers/Contracts/types';
import { selectActivePreHedge } from 'app/containers/PreHedge/selectors';
import { actions } from 'app/containers/PreHedge/slice';
import { translations } from 'locales/i18n';
import { Permission } from 'types/Authorization';
import {
	CONTRACT_TYPE_VALUES,
	CONTRACT_TYPES_GUIDS,
	TRANSACTION_TYPES_ENUM,
	TRANSACTION_TYPES_GUIDS,
} from 'utils/constants';
import { getActionType } from 'utils/helpers';

export default function SelectContractTypeModal() {
	const dispatch = useDispatch();
	const preHedgeData = useSelector(selectActivePreHedge);
	const GUTTER_SPACE = 0;
	const { userHasPermissions } = useAbac();
	const handleCancel = () => {
		dispatch(actions.setCurrentModal(null));
	};

	const { t: translate } = useTranslation();

	const scope = translations.app.containers.PreHedge.components.Modals;

	// Construct the contract details object using the given parameters

	const handleContract = (contractType) => {
		const contractDetails = {
			prehedgeId: preHedgeData?.id,
			action: getActionType(!preHedgeData?.isSell),
			commodity: { value: preHedgeData?.commodityId },
			contract: {
				label: CONTRACT_TYPES_GUIDS[contractType],
				value: contractType,
				type: contractType,
			},
			cropYear: preHedgeData?.cropYear,
			futuresMonth: { value: preHedgeData?.futuresMonth },
			futuresPrice: preHedgeData?.openPrice,
			location: { value: null },
			deliveryLocation: { value: null },
			transaction: {
				label: TRANSACTION_TYPES_GUIDS[TRANSACTION_TYPES_ENUM['Cash Contract']],
				value: TRANSACTION_TYPES_ENUM['Cash Contract'],
				type: undefined,
			},
			comments: preHedgeData?.comments,
		};

		dispatch(actions.setContractStateFromPreHedge(contractDetails));
		dispatch(actions.setCurrentModal(null));
		dispatch(contractAction.setCurrentModal(ContractsModals.Create));
	};
	return (
		<>
			<Modal
				footer={null}
				maskClosable={false}
				className="select-contract-type-modal"
				title={translate(scope.createContractFromPreHedgeTitle)}
				visible={true}
				onCancel={handleCancel}
			>
				{
					<div>
						<Row gutter={GUTTER_SPACE}>
							<div className="select-contract-type-modal__header">
								<p className="select-contract-type-modal__contract-text">
									{translate(scope.selectContractType)}
								</p>
							</div>
							<div>
								{userHasPermissions(
									Permission.CONTRACTSERVICE_CONTRACTHTA_CREATE,
								) && (
									<Button
										onClick={() => {
											handleContract(CONTRACT_TYPE_VALUES.hta);
										}}
									>
										HTA
									</Button>
								)}
								{userHasPermissions(
									Permission.CONTRACTSERVICE_CONTRACTFLATPRICE_CREATE,
								) && (
									<Button
										onClick={() => {
											handleContract(CONTRACT_TYPE_VALUES.flatPrice);
										}}
									>
										Flat Price
									</Button>
								)}
							</div>
						</Row>
					</div>
				}
			</Modal>
		</>
	);
}
