import './styles.scss';

import { Col, Row } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ClearFilters } from 'app/components/ClearFilter';
import { FixedColumns } from 'app/components/ColumnPreference/FixedColumns';
import { VisibleColumns } from 'app/components/ColumnPreference/VisibleColumns';
import {
	selectFixedColumns,
	selectSelectedColumns,
	selectSelectedFilters,
} from 'app/containers/Contracts/selectors';
import { translations } from 'locales/i18n';
import { GenericOption } from 'types/GenericOption';
import {
	FILTERS_FOR_CONTRACTS,
	FIXED_COLUMNS_FOR_CONTRACTS,
	SELECTED_COLUMNS_FOR_CONTRACTS,
} from 'utils/constants';
import { countFilledFilters } from 'utils/helpers';
import { initialFilters } from 'utils/initial-filters';

import { actions } from '../../../../slice';
import { BulkActions } from './BulkActions';
import { Filters } from './Filters';

export const ListMenu = memo(() => {
	const { showUserColumnPreference } = useFlags();
	const [activeFilterCount, setActiveFilterCount] = useState(0);
	const selectedFilters = useSelector(selectSelectedFilters);
	const mapOptions = (values: string[]) =>
		values.map<GenericOption>((value) => ({
			label: translate(
				translations.app.containers.OrderContainer.components.OrdersTable[
					value
				],
			),
			value: value,
		}));

	const { t: translate } = useTranslation();
	const selectedColumns = useSelector(selectSelectedColumns);
	const mutableColumns = Object.keys(selectedColumns).filter(
		(value) => !['created', 'contractNumber', 'dots'].includes(value),
	);
	const fixedColumns = useSelector(selectFixedColumns);
	const movableColumns = Object.keys(fixedColumns).filter(
		(value) => !['created', 'dots'].includes(value),
	);

	useEffect(() => {
		setActiveFilterCount(countFilledFilters(selectedFilters));
	}, [selectedFilters]);

	return (
		<Row className="grid-menu" gutter={8}>
			<Col xs={12} sm={12} md={12} lg={9} xl={9}>
				<ClearFilters
					activeFilterCount={activeFilterCount}
					localStorageKey={FILTERS_FOR_CONTRACTS}
					loadGrid={actions.loadContractList()}
					seFilters={actions.setSelectedFilters(initialFilters.contractFilters)}
					grid={'contractFilters'}
				/>
				<Filters />
			</Col>
			<Col xs={12} sm={12} md={12} lg={3} xl={3}>
				<Row justify="end">
					{showUserColumnPreference ? (
						<>
							<FixedColumns
								persistedDataKey={FIXED_COLUMNS_FOR_CONTRACTS}
								fixedColumns={fixedColumns}
								setFixedColumns={actions.setFixedColumns}
								columns={movableColumns}
								mapOptions={mapOptions}
							/>
							<VisibleColumns
								persistedDataKey={SELECTED_COLUMNS_FOR_CONTRACTS}
								selectedColumns={selectedColumns}
								setSelectedColumns={actions.setSelectedColumns}
								columns={mutableColumns}
								mapOptions={mapOptions}
							/>
						</>
					) : (
						<div>&nbsp;</div>
					)}
				</Row>
				<Row justify="end">
					<BulkActions />
				</Row>
			</Col>
		</Row>
	);
});
