/**
 *
 * CropYears
 *
 */
import React, { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { RadioButtons } from 'app/components/RadiosButtons';
import { translations } from 'locales/i18n';
import { getCropYearOptions } from 'utils/helpers';

import { selectDNHList } from '../../selectors';

const domain = translations.app.containers.Transactions.components.CropYears;

export const CropYears = memo(() => {
	const { t: translate } = useTranslation();
	const { watch } = useFormContext();
	const commoditiesList = useSelector(selectDNHList);

	const commodity = watch('commodity');

	const [cropYearOptions, setCropYearOptions] = useState(() =>
		getCropYearOptions(commoditiesList, commodity),
	);

	useEffect(() => {
		const options = getCropYearOptions(commoditiesList, commodity);
		setCropYearOptions(options);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [commodity]);

	return (
		<GenericForm.FormItem
			data-testid="cropYear-form-item"
			label={translate(domain.label)}
			name="cropYear"
		>
			<RadioButtons key="cropYear" options={cropYearOptions} />
		</GenericForm.FormItem>
	);
});
