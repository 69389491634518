/**
 *
 * GenericColumnItem
 *
 */
import classNames from 'classnames';
import React from 'react';

interface Props {
	className?: string;
	children?: React.ReactNode;
}

export function Column(props: Props) {
	const { children, className } = props;

	return (
		<div className="wrapper-column" data-testid="table-column">
			<div className={classNames('inner', className)}>{children}</div>
		</div>
	);
}
