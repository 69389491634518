import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.userRoles || initialState;

export const selectCurrentView = createSelector(
	[selectDomain],
	(userRolesState) => userRolesState.currentView,
);

export const selectCurrentModal = createSelector(
	[selectDomain],
	(userRolesState) => userRolesState.currentModal,
);

export const selectActiveUserRoles = createSelector(
	[selectDomain],
	(userRolesState) => userRolesState.activeUserRoles.data,
);

export const selectUserRolesListData = createSelector(
	[selectDomain],
	(userRolesState) => userRolesState.list.data,
);

export const selectListLoading = createSelector(
	[selectDomain],
	(userRolesState) => userRolesState.list.loading,
);

export const selectUserRoleDetails = createSelector(
	[selectDomain],
	(userRolesState) => userRolesState.userRoleDetails.data,
);

export const selectCreateUserRoleLoading = createSelector(
	[selectDomain],
	(userRolesState) => userRolesState.createUserRole.loading,
);

export const selectRoleLimits = createSelector(
	[selectDomain],
	(userRolesState) => userRolesState.roleLimits.data,
);
