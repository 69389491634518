import './styles.scss';

import { Button, Col, Row } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { memo, useEffect, useState } from 'react';
import { useAbac } from 'react-abac';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ClearFilters } from 'app/components/ClearFilter';
import { FixedColumns } from 'app/components/ColumnPreference/FixedColumns';
import { VisibleColumns } from 'app/components/ColumnPreference/VisibleColumns';
import {
	selectFixedColumns,
	selectSelectedColumns,
	selectSelectedFilters,
} from 'app/containers/Offers/selectors';
import {
	selectNoBidListData,
	selectNoBidListLoading,
	selectNoBidMappedData,
	selectTotalOrphans,
} from 'app/containers/Settings/sections/Bidsheet/selectors';
import { actions as BidsheetActions } from 'app/containers/Settings/sections/Bidsheet/slice';
import { Modal as BidsheetModals } from 'app/containers/Settings/sections/Bidsheet/types';
import { translations } from 'locales/i18n';
import { Permission } from 'types/Authorization';
import { FeatureFlag } from 'types/FeatureFlags';
import { GenericOption } from 'types/GenericOption';
import {
	FILTERS_FOR_OFFERS,
	FIXED_COLUMNS_FOR_OFFERS,
	SELECTED_COLUMNS_FOR_OFFERS,
} from 'utils/constants';
import { countFilledFilters } from 'utils/helpers';
import { initialFilters } from 'utils/initial-filters';

import { actions } from '../../../../slice';
import { BulkActions } from './BulkActions';
import { Filters } from './Filters';

export const ListMenu = memo(() => {
	const { showUserColumnPreference } = useFlags();
	const mapOptions = (values: string[]) =>
		values.map<GenericOption>((value) => ({
			label: translate(translations.app.containers.Offers.TableWrapper[value]),
			value: value,
		}));

	const [activeFilterCount, setActiveFilterCount] = useState(0);
	const selectedFilters = useSelector(selectSelectedFilters);

	useEffect(() => {
		setActiveFilterCount(
			countFilledFilters(selectedFilters, [
				{ value: 'Working/Rejected', label: 'Working / Rejected' },
			]),
		);
	}, [selectedFilters]);

	const { t: translate } = useTranslation();
	const { userHasPermissions } = useAbac();

	const selectedColumns = useSelector(selectSelectedColumns);
	const mutableColumns = Object.keys(selectedColumns).filter(
		(value) => !['created', 'dots'].includes(value),
	);
	const fixedColumns = useSelector(selectFixedColumns);
	const movableColumns = Object.keys(fixedColumns).filter(
		(value) => !['created', 'dots'].includes(value),
	);

	const dispatch = useDispatch();
	const featureFlags = useFlags();

	const totalOrphans = useSelector(selectTotalOrphans);
	const noBidListLoading = useSelector(selectNoBidListLoading);
	const notBidList = useSelector(selectNoBidListData);
	const notBidMapped = useSelector(selectNoBidMappedData);

	const noBidLoaded =
		notBidList?.length > 0 &&
		Object.keys(notBidMapped).length > 0 &&
		!noBidListLoading;

	const hasOrphans = typeof totalOrphans === 'number' && totalOrphans > 0;

	useEffect(() => {
		if (noBidLoaded) {
			dispatch(actions.setCurrentModal(BidsheetModals.RollOrphan));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [noBidLoaded]);

	const handleRollOrphans = () => {
		dispatch(BidsheetActions.requestNoBidList());
	};
	const newFiltersLayout = featureFlags[FeatureFlag.enableNewFilterLayout];

	return (
		<Row className="grid-menu" gutter={16}>
			<Col
				{...(newFiltersLayout
					? { xs: 12, md: 12, lg: 9, xl: 8 }
					: { span: 10 })}
			>
				<ClearFilters
					activeFilterCount={activeFilterCount}
					localStorageKey={FILTERS_FOR_OFFERS}
					loadGrid={actions.loadOffersList(true)}
					seFilters={actions.setSelectedFilters(initialFilters.offerFilters)}
					grid={'offerFilters'}
				/>
				<Filters />
			</Col>
			<Col
				{...(newFiltersLayout ? { xs: 12, md: 12, lg: 3, xl: 4 } : { span: 2 })}
			>
				<Row justify="end">
					{showUserColumnPreference ? (
						<>
							<FixedColumns
								persistedDataKey={FIXED_COLUMNS_FOR_OFFERS}
								fixedColumns={fixedColumns}
								setFixedColumns={actions.setFixedColumns}
								columns={movableColumns}
								mapOptions={mapOptions}
							/>
							<VisibleColumns
								persistedDataKey={SELECTED_COLUMNS_FOR_OFFERS}
								selectedColumns={selectedColumns}
								setSelectedColumns={actions.setSelectedColumns}
								columns={mutableColumns}
								mapOptions={mapOptions}
							/>
						</>
					) : (
						<div>&nbsp;</div>
					)}
				</Row>
				<Row justify="end">
					{hasOrphans &&
						userHasPermissions(Permission.CONTRACTSERVICE_BIDSHEET_UPLOAD) && (
							<Button
								type="primary"
								size="middle"
								onClick={handleRollOrphans}
								className="orphans-button"
								data-testid="roll-orphans-button"
								ghost
							>
								<span className="grid-menu__total-orphans">
									{totalOrphans && totalOrphans}
								</span>
								{translate(
									translations.app.containers.Contracts.Offers.ListMenu
										.rollOrphans,
								)}
							</Button>
						)}
					<BulkActions />
				</Row>
			</Col>
		</Row>
	);
});
