import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { mapToLabelValue, yearsToLabelValue } from 'utils/helpers';
import { fileRequest, genericRequest, httpMethod } from 'utils/request';

import {
	selectCurrentReport,
	selectPagination,
	selectSelectedFilters,
} from './selectors';
import { genericReportActions } from './slice';

const { reportExecutionRoot, reportMetadata, reportExecute, reportExcel } =
	apiEndpoints;

/**
 * @internal exported for automated tests
 */
export function* getReportsList() {
	const requestURL: any = yield new URL(
		`${apiRoutes.base}/${reportExecutionRoot}?type=Catalog`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(genericReportActions.reportsListLoaded(responseData.list));
	} else if (!!responseError.detail) {
		yield put(genericReportActions.tableDataError(responseError));
	}
}

/**
 * @internal exported for automated tests
 */
export function* getTableSchema() {
	const currentReport = yield select(selectCurrentReport);
	const requestURL: any = yield new URL(
		`${apiRoutes.base}/${reportMetadata}/${currentReport.key}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(genericReportActions.tableSchemaLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(genericReportActions.tableDataError(responseError));
	}
}

/**
 * @internal exported for automated tests
 */
export function* getFilterData(action: PayloadAction<any>) {
	const { endpoint, filterKey, filterValue } = action.payload;

	let requestURL: any = yield new URL(`${apiRoutes.base}/${endpoint}`);
	requestURL.searchParams.append('name', filterValue);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		const filterData = mapToLabelValue(responseData.commodities);
		let data = {};
		data[filterKey] = filterData;

		yield put(genericReportActions.filterDataLoaded(data));
	} else if (!!responseError.detail) {
		yield put(genericReportActions.tableDataError(responseError));
	}
}

/**
 * @internal exported for automated tests
 */
export function* getTableData() {
	const pagination = yield select(selectPagination);
	const currentReport = yield select(selectCurrentReport);
	const selectedFilters = yield select(selectSelectedFilters);

	let requestURL: any = yield new URL(
		`${apiRoutes.base}/${reportExecute}/${currentReport.key}`,
	);

	for (let key in selectedFilters) {
		requestURL.searchParams.append(key, selectedFilters[key]);
	}

	for (let key in pagination) {
		requestURL.searchParams.append(key, pagination[key]);
	}

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(genericReportActions.tableDataLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(genericReportActions.tableDataError(responseError));
	}
}

/**
 * @internal exported for automated tests
 */
export function* getTableDataReport() {
	const currentReport = yield select(selectCurrentReport);
	const selectedFilters = yield select(selectSelectedFilters);

	let requestURL: any = yield new URL(
		`${apiRoutes.base}/${reportExcel}/${currentReport.key}`,
	);

	for (let key in selectedFilters) {
		requestURL.searchParams.append(key, selectedFilters[key]);
	}
	const { responseData, responseError }: any = yield call(
		fileRequest,
		requestURL,
		httpMethod.Get,
		true,
	);

	// Success actions
	if (!!responseData) {
		yield put(
			genericReportActions.tableDataLoadedReport({
				url: responseData,
			}),
		);
	} else if (!!responseError.detail) {
		yield put(genericReportActions.tableDataErrorReport(responseError));
	}
}

/**
 * @internal exported for automated tests
 */
export function* getDynamicFilterData(action: PayloadAction<any>) {
	const { url, source } = action.payload;

	let requestURL: any = yield new URL(`${apiRoutes.base}${url}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		const filterList = Array.isArray(responseData)
			? responseData
			: responseData[Object.keys(responseData)[1]];

		const options =
			filterList.length && typeof filterList[0] === 'object'
				? mapToLabelValue(filterList)
				: yearsToLabelValue(filterList);

		yield put(
			genericReportActions.dynamicFiltersLoaded({
				source: source,
				data: options,
			}),
		);
	} else if (!!responseError.detail) {
		const payload = {
			source,
			error: responseError,
		};
		yield put(genericReportActions.dynamicFiltersError(payload));
	}
}

export function* genericReportSaga() {
	yield takeLatest(genericReportActions.loadReportsList.type, getReportsList);
	yield takeLatest(genericReportActions.loadTableSchema.type, getTableSchema);
	yield takeLatest(genericReportActions.loadFilterData.type, getFilterData);
	yield takeLatest(genericReportActions.loadTableData.type, getTableData);

	yield takeLatest(
		genericReportActions.loadTableDataReport.type,
		getTableDataReport,
	);
	yield takeEvery(
		genericReportActions.loadDynamicFilter.type,
		getDynamicFilterData,
	);
}
