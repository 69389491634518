import './style.scss';

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { CollapseProps } from 'antd/lib/collapse';
import React, { memo } from 'react';
const { Panel } = Collapse;
interface Props extends CollapseProps {
	collapsibleKey: string;
	title?: string;
	content: JSX.Element;
	inlineTitle?: boolean;
}

export const Collapsible = memo((props: Props) => {
	return (
		<Collapse
			ghost
			defaultActiveKey={[props.collapsibleKey]}
			expandIcon={({ isActive }) =>
				isActive ? <UpOutlined /> : <DownOutlined />
			}
			expandIconPosition="left"
			className={props.inlineTitle ? 'inline-collapse' : ''}
		>
			<Panel header={props.title} key={props.collapsibleKey}>
				{props.content}
			</Panel>
		</Collapse>
	);
});
