import { takeLatest } from 'redux-saga/effects';

import {
	createCustomerGroup,
	deleteCustomerGroup,
	loadCustomerGroupDetails,
	loadCustomerGroupMembers,
	loadCustomerGroupNonMembers,
	updateCustomerGroup,
} from './sagas/customerGroupNotificationSettings-saga';
import {
	createEmployeeGroup,
	deleteEmployeeGroup,
	loadEmployeeGroupDetails,
	loadEmployeeGroupMembers,
	loadEmployeeGroupNonMembers,
	loadEmployeeGroups,
	updateEmployeeGroup,
} from './sagas/employeeGroupNotificationSettings-saga';
import {
	getTenantPreferences,
	setTenantPreferenceStatus,
} from './sagas/tenantNotificationSettings-saga';
import {
	getUserAlertTones,
	getUserAssignedGroups,
	getUserPreferences,
	setUserAlertTone,
	setUserPreferenceStatus,
} from './sagas/userNotificationSettings-saga';
import { actions } from './slice';

export function* notificationSettingsSaga() {
	yield takeLatest(actions.getTenantPreferences.type, getTenantPreferences);
	yield takeLatest(
		actions.setTenantPreferenceStatus.type,
		setTenantPreferenceStatus,
	);

	yield takeLatest(actions.getUserPreferences.type, getUserPreferences);
	yield takeLatest(
		actions.setUserPreferenceStatus.type,
		setUserPreferenceStatus,
	);
	yield takeLatest(
		actions.getUserNotificationsAlertTones.type,
		getUserAlertTones,
	);
	yield takeLatest(
		actions.setUserNotificationsAlertTone.type,
		setUserAlertTone,
	);
	yield takeLatest(actions.getUserAssignedGroups.type, getUserAssignedGroups);

	// Employee Group Settings
	yield takeLatest(actions.loadEmployeeGroups.type, loadEmployeeGroups);
	yield takeLatest(
		actions.loadEmployeeGroupDetails.type,
		loadEmployeeGroupDetails,
	);
	yield takeLatest(
		actions.loadEmployeeGroupMembers.type,
		loadEmployeeGroupMembers,
	);
	yield takeLatest(
		actions.loadEmployeeGroupNonMembers.type,
		loadEmployeeGroupNonMembers,
	);
	yield takeLatest(actions.createEmployeeGroup.type, createEmployeeGroup);
	yield takeLatest(actions.updateEmployeeGroup.type, updateEmployeeGroup);
	yield takeLatest(actions.deleteEmployeeGroup.type, deleteEmployeeGroup);

	// Customer Group Settings
	yield takeLatest(
		actions.loadCustomerGroupDetails.type,
		loadCustomerGroupDetails,
	);
	yield takeLatest(
		actions.loadCustomerGroupMembers.type,
		loadCustomerGroupMembers,
	);
	yield takeLatest(
		actions.loadCustomerGroupNonMembers.type,
		loadCustomerGroupNonMembers,
	);
	yield takeLatest(actions.createCustomerGroup.type, createCustomerGroup);
	yield takeLatest(actions.updateCustomerGroup.type, updateCustomerGroup);
	yield takeLatest(actions.deleteCustomerGroup.type, deleteCustomerGroup);
}
