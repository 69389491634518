export const HUB_NAME = 'ws';
export const THROTTLE_TIME = 1000;

export const hubTriggers = {
	subscribeToNotificationMessages: 'GetNotificationMessages',
};

export const hubSubscriptionChannels = {
	contractUpdates: 'ContractUpdates',
	offerUpdates: 'OfferUpdates',
	reviewAndReleaseUpdates: 'ReviewAndReleaseUpdates',
	statusTransactionUpdates: 'StatusTransactionUpdates',
	futuresErpUpdates: 'ErpStatusUpdates',
	receiveNotificationList: 'ReceiveNotificationMessages',
	innerNotifications: 'NotificationReceived',
	preHedgeUpdates: 'PreHedgeUpdates',
};
