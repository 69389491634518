import './style.scss';

import { Empty } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';

import NoResults from './assets/no-results.svg';
interface Props {
	description?: string;
}
export const FilterEmpty = memo((props: Props) => {
	const { t: translate } = useTranslation();
	const {
		description = translate(translations.app.components.FilterEmpty.noresult),
	} = props;
	return (
		<Empty
			data-testid="filter-empty"
			className="table-wrapper__empty--no-results"
			image={NoResults}
			description={description}
		/>
	);
});
