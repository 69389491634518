import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import { TableTypes } from '../types';

const { bidsheetDeleteBidRow } = apiEndpoints;

export function* deleteBidRow(action: PayloadAction<any>) {
	const id = action.payload;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${bidsheetDeleteBidRow}?id=${id}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Delete,
	);

	if (!!responseData) {
		yield put(actions.loadBidsheetList({ type: TableTypes.Filter }));
	} else if (!!responseError.detail) {
		yield put(actions.deleteBidRowError(responseError));
	}
}
