import { useDispatch, useSelector } from 'react-redux';

import {
	selectEmployeeGroupNonMembers,
	selectEmployeeGroupNonMembersLoading,
} from '../selectors';
import { actions } from '../slice';
import { NotificationGroupCreateHookValue, View } from '../types';

export const useEmployeeGroupCreate = (): NotificationGroupCreateHookValue => {
	const dispatch = useDispatch();
	const nonMemberList = useSelector(selectEmployeeGroupNonMembers);
	const nonMemberListLoading = useSelector(
		selectEmployeeGroupNonMembersLoading,
	);

	const onClose = () => dispatch(actions.clearEmployeeGroupState());

	const onSubmit = ({ groupName, groupMembers }) => {
		dispatch(
			actions.createEmployeeGroup({
				name: groupName,
				members: groupMembers,
			}),
		);
		dispatch(actions.setCurrentView(View.List));
		onClose();
	};

	return {
		onClose,
		onSubmit,
		nonMemberList,
		loading: nonMemberListLoading,
	};
};
