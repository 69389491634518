import './style.scss';

import { Select } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { RangeDatePicker } from 'app/components/RangeDatePicker';
import { setCashbidsState } from 'app/containers/Contracts/selectors';
import { TransactionTypesContracts } from 'app/containers/Contracts/types';
import {
	selectCommoditiesList,
	selectDeliveryDates,
	selectServiceFeeList,
} from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { TransactionType } from 'app/containers/Offers/types';
import { translations } from 'locales/i18n';
import { ContractTypeSource } from 'types/ContractTypeSource';
import { DeliveryDateMode } from 'types/DeliveryDateMode';
import {
	DATE_FORMAT,
	TRANSACTION_TYPES_ENUM,
	TRANSACTION_TYPES_GUIDS,
} from 'utils/constants';
import {
	bidsheetDataFromDeliveryDate,
	calculateServiceFee,
	createRangeCalendarOption,
	formatPriceHelper,
} from 'utils/helpers';
import { isEmptyObject } from 'utils/validators';

import { SourceContext } from '../..';
import { useDefaultValues } from '../../hooks/useDefaultValues';
import { useHandleBusinessRules } from '../hooks/useHandleBusinessRules';

const { Option } = Select;

interface Props {
	dateFormat?: string;
	disabled?: boolean;
	modeSelectorDisabled?: boolean;
	rangePickerDisabled?: boolean;
	resetDependencies?: boolean;
	resetFutureDependencies?: boolean;
	resetBasisDependencies?: boolean;
	resetFuturePriceDependencies?: boolean;
	getFromBidsheet?: boolean;
	hideModeSelector?: boolean;
	filterByFutureMonth?: string;
	onDeliveryDatesChange?: () => {};
	source?: CalculatedFieldsSource;
	getDeliveryDatesOnMount?: boolean;
	checkDefaultValues?: boolean;
	spreadDependency?: boolean;
	useServiceFees?: boolean;
	isRoll?: boolean;
	forceToCustom?: boolean;
	forceToWindow?: boolean;
}
export const DeliveryDates = (props: Props) => {
	const {
		dateFormat = DATE_FORMAT,
		disabled,
		modeSelectorDisabled,
		rangePickerDisabled,
		resetDependencies = true,
		resetFutureDependencies = true,
		resetBasisDependencies = true,
		resetFuturePriceDependencies = false,
		getFromBidsheet = false,
		onDeliveryDatesChange = handleDeliveryDateChange,
		hideModeSelector = false,
		filterByFutureMonth = null,
		getDeliveryDatesOnMount = false,
		checkDefaultValues = false,
		spreadDependency = false,
		forceToCustom = false,
		forceToWindow = false,
		source,
		useServiceFees,
		isRoll,
	} = props;

	const dispatch = useDispatch();
	const [counter, setCounter] = useState(false);

	const {
		setValue,
		setError,
		watch,
		formState: { isDirty, dirtyFields },
	} = useFormContext();

	const sourceName = useContext(SourceContext);
	// TODO: Uncomment when needed
	// const { getServiceFee } = useHandleBusinessRules(sourceName);
	const serviceFees = useSelector(selectServiceFeeList);
	const { t: translate } = useTranslation();

	const commoditiesList = useSelector(selectCommoditiesList);
	const cashbidsState = useSelector(setCashbidsState);

	const defaultValues = useDefaultValues();

	let currentDeliveryDateData =
		useSelector(selectDeliveryDates)[source ?? sourceName]?.data ?? [];

	if (!!filterByFutureMonth && currentDeliveryDateData?.length) {
		currentDeliveryDateData = currentDeliveryDateData.filter(
			(data) => data.futureMonth === filterByFutureMonth,
		);
	}

	const deliveryDate = useWatch({ name: 'deliveryDate' });
	const futuresMonth = useWatch({ name: 'futuresMonth' });

	const [futuresMonthChanged, setFuturesMonthChanged] = useState(false);
	const isRollStates: any = [];
	if (isRoll) {
		isRollStates.push(futuresMonth);
		if (futuresMonthChanged) isRollStates.push(deliveryDate);
	} else {
		isRollStates.push(deliveryDate);
	}
	const useEffectSetServiceFee = (effect: React.EffectCallback) => {
		useEffect(effect, isRollStates);
	};

	useEffectSetServiceFee(() => {
		if (useServiceFees) {
			if (!futuresMonthChanged) setFuturesMonthChanged(true);
			calcServiceFee(isRoll);
			if (isRoll) {
				if (!hasSetExistingFee) {
					setExistingFee(values.fees1);
					setHasSetExistingFee(true);
				}
			}
		}
	});

	const calcServiceFee = (isRoll) => {
		const {
			contract,
			commodity,
			cropYear,
			transaction,
			deliveryDate,
			// fees1,
		} = values;
		if (
			serviceFees &&
			serviceFees.data &&
			contract &&
			commodity &&
			cropYear &&
			transaction &&
			deliveryDate
		) {
			const serviceFeeOptions = serviceFees.data;
			let selectedItem;
			let interimTransaction = transaction.value?.toLowerCase();
			if (
				TRANSACTION_TYPES_GUIDS[transaction.value.toUpperCase()] ===
				TransactionType.Offer
			)
				interimTransaction =
					TRANSACTION_TYPES_ENUM[TransactionType.Contract].toLowerCase();

			if (
				interimTransaction ===
				TRANSACTION_TYPES_ENUM[
					TransactionTypesContracts.BushelsOnly
				].toLowerCase()
			) {
				interimTransaction =
					TRANSACTION_TYPES_ENUM[
						TransactionTypesContracts.CashContract
					].toLowerCase();
			}
			for (var i = 0; i < serviceFeeOptions.length; i++) {
				if (
					serviceFeeOptions[i]['commodityId'] === commodity.value &&
					serviceFeeOptions[i]['contractTypeId'] ===
						contract.value?.toLowerCase() &&
					serviceFeeOptions[i]['cropYear'] === cropYear &&
					serviceFeeOptions[i]['transactionTypeId'] ===
						interimTransaction?.toLowerCase()
				) {
					selectedItem = serviceFeeOptions[i];
					break;
				}
			}
			if (selectedItem) {
				let targetDeliveryDate = deliveryDate[0].format('MMM YY');
				let serviceFeeMonth = selectedItem.serviceFeeMonthList.filter(
					(item) => item.deliveryMonth === targetDeliveryDate,
				);

				// We have to slice the serviceFeeMonthList (if serviceFeeMonth's length is 0) before checking isRolling condition
				if (serviceFeeMonth.length === 0) {
					serviceFeeMonth = selectedItem.serviceFeeMonthList.slice(0, 1);
				}
				if (isRoll) {
					setValue(
						'fees1',
						parseFloat(
							(
								(existingFee === null ? 0 : Number(existingFee)) +
								serviceFeeMonth?.[0]?.rollingFee
							).toFixed(4),
						),
					);
				} else if (serviceFeeMonth !== undefined) {
					if (serviceFeeMonth[0]?.writingFee === 0) setValue('fees1', null);
					else setValue('fees1', serviceFeeMonth?.[0]?.writingFee);
				}
			} else if (existingFee !== null) {
				setValue('fees1', existingFee);
			}
		}
	};

	const values = watch();

	useEffect(() => {
		const { contract, commodity, cropYear, transaction, deliveryDate } = values;
		if (
			isDirty &&
			useServiceFees &&
			!isEmptyObject(cashbidsState) &&
			deliveryDate
		) {
			let interimTransaction = transaction.value;
			if (
				TRANSACTION_TYPES_GUIDS[transaction.value.toUpperCase()] ===
				TransactionType.Offer
			)
				interimTransaction =
					TRANSACTION_TYPES_ENUM[TransactionType.Contract].toLowerCase();
			const serviceFee = calculateServiceFee(
				serviceFees.data,
				contract.value,
				commodity.value,
				cropYear,
				interimTransaction,
				deliveryDate,
			);
			setValue('fees1', serviceFee);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDirty]);

	const currentMode = values.deliveryDatesMode?.value;

	const msg = translations.app.containers.Transactions.components.DeliveryDates;

	const modeOptions = [
		{
			value: DeliveryDateMode.Window,
			key: DeliveryDateMode.Window,
			label: 'Delivery Date',
		},
		{
			value: DeliveryDateMode.Custom,
			key: DeliveryDateMode.Custom,
			label: 'Custom',
		},
	];

	const [currentCropData, setCurrentCropData] = useState(() =>
		getCurrentCropData(),
	);

	const [rangePickerKey, setRangePickerKey] = useState(0);

	const [existingFee, setExistingFee] = useState(0);
	const [hasSetExistingFee, setHasSetExistingFee] = useState(false);

	const {
		getPostedBasis,
		setNetBasis,
		manageTotalPriceCall,
		getDeliveryDates,
		getSpreadValue,
	} = useHandleBusinessRules(sourceName);

	const deliveryWindowOptions = currentDeliveryDateData?.map((range) => {
		const labels = {
			startLabel: translate(msg.deliveryDate.start),

			endLabel: translate(msg.deliveryDate.end),
		};

		return createRangeCalendarOption(
			range.start,
			range.end,
			range.name,
			labels,
		);
	});

	useEffect(() => {
		if (getDeliveryDatesOnMount) {
			getDeliveryDates(source ?? undefined);
		}
		if (checkDefaultValues) {
			checkDefaultValue();
		}
		if (forceToCustom) {
			setValue('deliveryDatesMode', { value: DeliveryDateMode.Custom });
		}
		if (forceToWindow) {
			setValue('deliveryDatesMode', { value: DeliveryDateMode.Window });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// When the forceToCustom prop changed and became true, force the field to custom
	useEffect(() => {
		if (forceToCustom) {
			setValue('deliveryDatesMode', { value: DeliveryDateMode.Custom });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forceToCustom]);

	useEffect(() => {
		const currentCropData = getCurrentCropData();
		setCurrentCropData(currentCropData);
		setRangePickerKey(rangePickerKey + 1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values.cropYear, values.commodity]);

	useEffect(() => {
		if (isDirty) {
			setValue('deliveryDateWindow', { value: null });
			setValue('deliveryDate', null);
		}
		if (useServiceFees) {
			calcServiceFee(isRoll);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentMode]);

	useEffect(() => {
		// This should run only on initial load, useEffect on mount coud not work if delivery dates state is not loaded
		if (!Object.keys(dirtyFields).length && checkDefaultValues) {
			checkDefaultValue();
			if (useServiceFees) {
				if (!counter) {
					calcServiceFee(isRoll);
					setCounter(true);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentDeliveryDateData]);

	function getCurrentCropData() {
		const commodityData = commoditiesList.find(
			(singleCommodity) => singleCommodity.id === values.commodity?.value,
		);

		const currentCrop = commodityData?.cropYears.find(
			(cropYearData) => cropYearData.cropYear === values.cropYear,
		);

		if (currentCrop) {
			return {
				...currentCrop,
				minDate: moment(currentCrop?.cropYearStartDate),
				maxDate: moment(currentCrop?.cropYearEndDate),
			};
		}

		return null;
	}

	const handleSelectMode = (selectedMode) => {
		if (resetFutureDependencies && resetDependencies) {
			setValue('futuresMonth', { value: null });
			setValue('futuresPrice', null);
		}
		if (resetBasisDependencies && resetDependencies) {
			setValue('postedBasis', null);
			setValue('netBasis', { value: null });
			setValue('netBasisPrice', null);
		}
	};

	/**
	 * DeliveryDateWindow trigger hidden deliveryDate value to store value
	 * @param selectedValue
	 * @param selectedOption
	 */
	const handleSelectDeliveryWindow = (selectedValue, selectedOption) => {
		const dates = [selectedOption.startdate, selectedOption.enddate];
		setValue('deliveryDate', dates);
		onDeliveryDatesChange(dates, watch());
	};

	const handleClick = () => {
		if (
			!values.commodity?.value ||
			!values.cropYear ||
			!values.deliveryLocation?.value
		) {
			setError('deliveryDateWindow', {
				type: 'error',
				message: translate(msg.validation.deliveryDateWindow),
			});
		}
	};

	const getDisabledDateRange = () => {
		if (currentCropData) {
			const disabledDates = {
				min: currentCropData.minDate,
				max: currentCropData.maxDate,
			};
			return disabledDates;
		}
		return null;
	};

	useEffect(() => {
		const { contract } = watch();

		if (contract?.label === 'Basis') {
			setNetBasis();
			manageTotalPriceCall();
		}
	}, [watch('quantity')]);

	const checkDefaultValue = () => {
		const { deliveryDateWindow } = defaultValues;
		const { commodity, deliveryDatesMode } = watch();

		let option = deliveryWindowOptions.find(
			(option) => option?.value === deliveryDateWindow.value,
		);

		if (
			deliveryDatesMode?.value === DeliveryDateMode.Window &&
			(deliveryDateWindow?.value || cashbidsState?.deliveryDateWindow?.value) &&
			commodity.value &&
			deliveryWindowOptions.length
		) {
			// finding the index of the delivery date range if user creating contract from Originator Screen (Cashbids section)
			const optionIndex = deliveryWindowOptions.findIndex(
				(option) => option?.value === cashbidsState?.deliveryDateWindow?.value,
			);

			// If there is no default value, the first available option will be used.But If a contract is being created from the Originator Screen, the appropriate deliveryWindow index will be added.
			option =
				!isEmptyObject(cashbidsState) && optionIndex !== -1
					? deliveryWindowOptions?.[optionIndex]
					: (option ?? deliveryWindowOptions?.[0] ?? null);

			setValue('deliveryDateWindow', {
				value: option?.value,
			});

			const dates: [string | undefined, string | undefined] = [
				option?.startDate?.format(DATE_FORMAT),
				option?.endDate?.format(DATE_FORMAT),
			];
			const { futuresMonth, postedBasis } = bidsheetDataFromDeliveryDate(
				dates,
				currentDeliveryDateData,
			);

			// This updates the reference hidden field
			setValue('deliveryDate', [option?.startDate, option?.endDate]);
			setValue('futuresMonth', { value: futuresMonth });
			setValue('postedBasis', formatPriceHelper(postedBasis));
			setNetBasis();
			manageTotalPriceCall();
		}
	};

	useEffect(() => {
		if (!isEmptyObject(cashbidsState)) {
			setNetBasis();
			manageTotalPriceCall();
		}
	}, [watch('deliveryLocation')]);

	function handleDeliveryDateChange(currentValue, values) {
		const deliveryDate = currentValue;
		const deliveryDatesMode = values.deliveryDatesMode;

		if (deliveryDate && deliveryDatesMode.value === DeliveryDateMode.Window) {
			const { futuresMonth, postedBasis } = bidsheetDataFromDeliveryDate(
				deliveryDate,
				currentDeliveryDateData,
			);
			if (resetDependencies) {
				if (resetBasisDependencies) {
					setValue('postedBasis', formatPriceHelper(postedBasis));
					setNetBasis();
				}
				if (resetFutureDependencies) {
					setValue('futuresMonth', {
						label: `${futuresMonth}`,
						value: futuresMonth,
					});
					if (
						values.futuresMonth.value !== futuresMonth &&
						resetFuturePriceDependencies
					)
						setValue('futuresPrice', null);
				}
				if (spreadDependency) {
					getSpreadValue();
				}

				manageTotalPriceCall();
			}
		} else if (!deliveryDate) {
			if (resetDependencies) {
				if (resetFutureDependencies) setValue('futuresMonth', { value: null });
				manageTotalPriceCall();
			}
		}
		// This applies only for offers
		if (getFromBidsheet) {
			const { cropYear, commodity, location } = values;

			if (deliveryDate && cropYear && commodity && location) {
				if (deliveryDatesMode.value === DeliveryDateMode.Custom) {
					dispatch(
						globalActions.loadFuturesMonth({
							type: ContractTypeSource.OFFER,
							params: {
								commodity: commodity.value,
								location: location.value,
								deliveryStartDate: deliveryDate[0].format(DATE_FORMAT),
								deliveryEndDate: deliveryDate[1].format(DATE_FORMAT),
								cropYear,
							},
							source: sourceName,
						}),
					);
				} else {
					if (deliveryDate) {
						const { futuresMonth, postedBasis } = bidsheetDataFromDeliveryDate(
							deliveryDate,
							currentDeliveryDateData,
						);
						setValue('futuresMonth', {
							label: `${futuresMonth}`,
							value: futuresMonth,
						});
						setValue('postedBasis', formatPriceHelper(postedBasis));
						setNetBasis();
					}
				}
			} else {
				dispatch(globalActions.clearFuturesMonth(sourceName));
			}
		}

		if (deliveryDatesMode.value === DeliveryDateMode.Custom) {
			getPostedBasis(sourceName);
		}
	}
	return (
		<>
			<GenericForm.FormItem
				label={translate(msg.deliveryWindow.label)}
				name="deliveryDatesMode"
				data-testid="deliveryDatesMode-form-item"
				customClassName={hideModeSelector ? ['hidden'] : []}
			>
				<Select
					data-testid="dropdown-form-window-mode"
					className="dropdown-form__select"
					placeholder={translate(msg.deliveryWindow.placeholder)}
					onSelect={handleSelectMode}
					options={modeOptions}
					labelInValue
					disabled={
						disabled || modeSelectorDisabled || forceToCustom || forceToWindow
					}
				/>
			</GenericForm.FormItem>

			{currentMode === DeliveryDateMode.Custom && (
				<GenericForm.FormItem
					label={translate(msg.deliveryDate.label)}
					name="deliveryDate"
					onChange={onDeliveryDatesChange}
				>
					<RangeDatePicker
						name="deliveryDate"
						format={dateFormat}
						key={rangePickerKey}
						getDisabledDateRange={getDisabledDateRange}
						startDatePlaceholder={translate(msg.customDateStart.start)}
						endDatePlaceholder={translate(msg.customDateStart.end)}
						disabled={rangePickerDisabled}
					/>
				</GenericForm.FormItem>
			)}

			{currentMode === DeliveryDateMode.Window && (
				<>
					<GenericForm.FormItem
						label={translate(msg.deliveryDate.label)}
						name="deliveryDateWindow"
					>
						<Select
							placeholder={translate(msg.deliveryDate.placeholder)}
							notFoundContent={null}
							onClick={handleClick}
							labelInValue
							onSelect={handleSelectDeliveryWindow}
							disabled={disabled}
						>
							{deliveryWindowOptions.map((option) => (
								<Option
									key={option.value}
									value={option.value}
									startdate={option.startDate}
									enddate={option.endDate}
								>
									{option.label}
								</Option>
							))}
						</Select>
					</GenericForm.FormItem>
					<GenericForm.FormItem
						name="deliveryDate"
						customClassName={['hidden']}
					></GenericForm.FormItem>
				</>
			)}
		</>
	);
};
