import './style.scss';

import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import React, { memo } from 'react';

interface Props {
	label?: string;
	format?: string;
	onSelect?: (string) => void;
	isRange: boolean;
	defaultValue?: any;
	disabledDate?: (any) => boolean;
	placeholder?: string;
	disabled?: boolean;
	defaultPickerValue?: [Moment, Moment];
	onCalendarChange?: (any) => boolean;
	customDisabledDate?: (any) => boolean;
}

export const Calendar = memo((props: Props) => {
	const {
		format,
		isRange,
		defaultValue,
		placeholder,
		disabled,
		defaultPickerValue,
		onCalendarChange,
		customDisabledDate = disableDate,
		...otherProps
	} = props;

	function disableDate(current) {
		return current && current < moment().endOf('day');
	}

	return isRange ? (
		<DatePicker.RangePicker
			{...otherProps}
			format={format}
			className="calendar__input"
			disabledDate={customDisabledDate}
			disabled={disabled}
			defaultValue={defaultValue}
			defaultPickerValue={defaultPickerValue}
			onCalendarChange={onCalendarChange}
		/>
	) : (
		<DatePicker
			{...otherProps}
			disabledDate={customDisabledDate}
			defaultValue={defaultValue}
			format={format}
			className="calendar__input"
			placeholder={placeholder}
			disabled={disabled}
		/>
	);
});
