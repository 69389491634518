import React, { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'app/components/Checkbox';
import { GenericForm } from 'app/components/GenericForm';
import { translations } from 'locales/i18n';
import { removeComma } from 'utils/helpers';

interface Props {
	disabled?: boolean;
	hedgeable?: boolean;
}

const HEDGEABLE_QUANTITY = 5000;

export const PassFill = memo((props: Props) => {
	const { disabled, hedgeable = false } = props;
	const { t: translate } = useTranslation();
	const msg = translations.app.containers.Transactions.components.PassFill;
	const [currentDisabled, setCurrentDisabled] = useState(hedgeable);
	const { watch, setValue } = useFormContext();
	const quantityValue = watch('quantity');
	const quantityPriceValue = watch('qtyPriceAmount');

	const quantityValidation =
		quantityValue &&
		parseFloat(removeComma(quantityValue)) >= HEDGEABLE_QUANTITY;
	const quantityPriceValidation =
		quantityPriceValue &&
		parseFloat(removeComma(quantityPriceValue)) >= HEDGEABLE_QUANTITY;

	useEffect(() => {
		if (quantityValidation || quantityPriceValidation) {
			setCurrentDisabled(false);
		} else {
			if (hedgeable) {
				setValue('passFill', false);
			}
			setCurrentDisabled(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quantityValue, quantityPriceValue]);

	const handleChange = () => {
		if (watch('passFill') === true) {
			setValue('doNotHedge', false);
		}
	};

	return (
		<GenericForm.FormItem
			name="passFill"
			wrapperCol={{ span: 4, offset: 3 }}
			onChange={handleChange}
			data-testid="passFill-form-item"
		>
			<Checkbox
				key="passFill"
				disabled={disabled || currentDisabled}
				label={translate(msg.checkbox)}
			/>
		</GenericForm.FormItem>
	);
});
