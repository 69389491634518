/**
 *
 * Accordion
 *
 */
import './style.scss';

import { Col, Collapse, Row } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';

import { GenericOption } from 'types/GenericOption';

const { Panel } = Collapse;

interface Props {
	sectionsList: GenericOption[];
	elements: JSX.Element[];
	colSpan: any;
}

const DELAY_UNTIL_LAYOUT_COMPLETE = 250;

export function Accordion(props: Props) {
	const { sectionsList, elements, colSpan } = props;

	const [layoutComplete, setLayoutComplete] = useState(false);
	return (
		<div className="generic-view-accordion__body">
			<Collapse
				accordion
				className="generic-view-accordion__collapse"
				onChange={() => {
					setLayoutComplete(false);
					setTimeout(() => {
						setLayoutComplete(true);
					}, DELAY_UNTIL_LAYOUT_COMPLETE);
				}}
			>
				{sectionsList.map((sectionOption, index) => (
					<Panel
						header={sectionOption.label}
						key={sectionOption.label}
						className={classNames('generic-view-accordion__panel', {
							'layout-complete': layoutComplete,
						})}
						forceRender
					>
						{elements.map(
							(element) =>
								element.props.section === sectionsList[index].value && (
									<Row
										key={`${element.props.section}${element.props.panelFieldLabel}`}
									>
										<Col
											flex={colSpan[index].col1}
											key={`${element.props.name}-label`}
										>
											{element.props.panelFieldLabel}
										</Col>
										<Col
											flex={colSpan[index].col2}
											key={`${element.props.name}-field`}
										>
											{element}
										</Col>
									</Row>
								),
						)}
					</Panel>
				))}
			</Collapse>
		</div>
	);
}
