import { Tabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
import React, { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
interface Props extends TabsProps {
	classname?: string;
	tabs: Array<any>;
	translations?: any;
	type?: 'line' | 'card' | 'editable-card' | undefined;
	handleOnClick?: Function;
}
const { TabPane } = Tabs;

export const TabsWrapper = memo((props: Props) => {
	const { tab: currentTab } = useParams<{ tab: string }>();

	// Update tab value at localstorage on tabChange
	useEffect(() => {
		localStorage.setItem('tab', currentTab);
	}, [currentTab]);

	const {
		classname,
		tabs,
		translations,
		type = undefined,
		handleOnClick,
		...spreadProps
	} = props;

	return (
		<Tabs
			className={classname}
			type={type}
			onTabClick={(tabKey) => {
				handleOnClick && handleOnClick(tabKey);
			}}
			destroyInactiveTabPane={true}
			activeKey={currentTab}
			{...spreadProps}
		>
			{tabs.map((tab) => (
				<TabPane
					tab={
						<div className={tab.className}>
							<label>
								{translations?.[tab.name] ?? tab.name} {tab.icon}
							</label>
						</div>
					}
					key={tab.name}
				>
					{tab.content}
				</TabPane>
			))}
		</Tabs>
	);
});
