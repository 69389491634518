import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { translations } from 'locales/i18n';

import { LocationForm } from '../../components/LocationForm';
import { SubmittedLocationValues } from '../../fields/types';
import { selectCurrentLocation } from '../../selectors';
import { actions } from '../../slice';

export const EditLocation = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const currentLocation = useSelector(selectCurrentLocation);

	const domain = translations.app.containers.Settings.sections.Locations;
	const { id = null, isUsed = false } = currentLocation || {};

	const handleSubmit = (values: SubmittedLocationValues) => {
		const data = {
			name: values.locationName,
			number: values.locationNumber,
			isLocation: values.locationOrigin.some((value) => value === 'isLocation'),
			isDestination: values.locationOrigin.some(
				(value) => value === 'isDestination',
			),
			isUsed,
		};
		dispatch(
			actions.editLocation({
				data,
				id,
				successMessage: translate(domain.toastSuccessEditMessage, {
					fullNameEdited: values.locationName.label,
				}),
			}),
		);
	};

	return (
		<LocationForm
			title={translate(domain.editLocationModalTitle)}
			submitButtonLabel={translate(domain.saveLocationModalButton)}
			handleSubmit={handleSubmit}
			currentLocation={currentLocation}
		/>
	);
};
