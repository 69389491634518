import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { selectCommodityId, selectRegionId } from '../selectors';
import { brokerMappingActions } from '../slice';
import {
	BrokerMappingRequest,
	CreateBrokerMapRequest,
	UpdateBrokerMappingRequest,
} from '../types';

const { getBrokerMapping, updateBrokerMapping, createBrokerMapping } =
	apiEndpoints;

export function* getBrokerMap(action: PayloadAction<BrokerMappingRequest>) {
	const { commodityId, regionId } = action.payload;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${getBrokerMapping}?CommodityId=${commodityId}&regionId=${regionId}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
		true,
	);

	if (!!responseData) {
		yield put(brokerMappingActions.mappingsLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(brokerMappingActions.mappingsError(responseError));
	}
}

export function* updateBrokerMap(
	action: PayloadAction<UpdateBrokerMappingRequest[]>,
) {
	const requestURL = yield new URL(`${apiRoutes.base}/${updateBrokerMapping}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		action.payload,
		true,
	);

	if (!!responseData) {
		yield put(brokerMappingActions.mappingsUpdated(responseData));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
	} else if (!!responseError.detail) {
		yield put(brokerMappingActions.mappingsUpdateError(responseError));
	}
}

export function* createBrokerMap(
	action: PayloadAction<CreateBrokerMapRequest[]>,
) {
	const requestURL = yield new URL(`${apiRoutes.base}/${createBrokerMapping}`);
	const regionId = yield select(selectRegionId);
	const commodityId = yield select(selectCommodityId);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		action.payload,
		true,
	);

	if (!!responseData) {
		yield put(brokerMappingActions.mappingsCreated(responseData));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(brokerMappingActions.loadMappings({ commodityId, regionId }));
	} else if (!!responseError.detail) {
		yield put(brokerMappingActions.mappingsCreateError(responseError));
	}
}
