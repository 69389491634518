import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';

import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { getCSTTime } from './components/Cashbids/utils/helper';
import { CashbidQuote, Product } from './types';

export const initialState: CashbidQuote = {
	success: null,
	futureQuoteList: {
		data: [],
		loading: false,
		error: null,
		lastUpdateTime: null,
	},
	cashBidsList: {
		data: [],
		loading: false,
		error: null,
		lastUpdateTime: null,
	},
	selectedFilters: {
		products: [],
		cashbidsCommodities: [],
		locations: [],
		cropYears: [],
	},
	activeState: {
		commodity: { value: '', label: '' },
	},
	productList: {
		data: [],
		loading: false,
		error: null,
	},
};

const cashBidsAndQuotesSlice = createSlice({
	name: 'cashBidsAndQuotes',
	initialState,
	reducers: {
		/** Future Quote Section comprise of the following reducers:
		 * 1. loadFutureQuotes: Request send to api to load the required data
		 * 2. futureQuoteListLoaded: If api gives a response this will update the state
		 * 3. futureListError: Handles Error for FutureQuoteapi
		 */
		loadFutureQuotes(state) {
			state.futureQuoteList = {
				...state.futureQuoteList,
				data: [],
				loading: true,
				error: null,
				lastUpdateTime: null,
			};
		},

		futureQuoteListLoaded(state, action: PayloadAction<any>) {
			const { productItems } = action.payload;
			state.futureQuoteList = {
				...state.futureQuoteList,
				data: productItems,
				loading: false,
				error: null,
				lastUpdateTime: getCSTTime(),
			};
		},

		futureListError(state, action: PayloadAction<GenericError>) {
			state.futureQuoteList = {
				...state.futureQuoteList,
				error: action.payload,
				loading: false,
				lastUpdateTime: null,
			};
		},

		/** Cashbids Section comprise of the following reducers:
		 * 1. loadCashbids: Request send to API to load the required data
		 * 2. CashbidListLoaded: If API gives a response this will update the state
		 * 3. CashbidListError: Handles Error for Cashbids API
		 */
		loadCashbids(state) {
			state.cashBidsList = {
				...state.cashBidsList,
				data: [],
				loading: true,
				error: null,
				lastUpdateTime: null,
			};
		},

		CashbidListLoaded(state, action: PayloadAction<any>) {
			const { bids } = action.payload;
			const { cashbids } = bids[0];

			state.cashBidsList = {
				...state.cashBidsList,
				data: cashbids,
				loading: false,
				error: null,
				lastUpdateTime: getCSTTime(),
			};
		},

		CashbidListError(state, action: PayloadAction<GenericError>) {
			state.cashBidsList = {
				...state.cashBidsList,
				error: action.payload,
				loading: false,
				lastUpdateTime: null,
			};
		},

		/***
		 * Filters
		 * 1. setSelectedFilters: All Filters state is beign stored for Originator Screen
		 * 2. activeCommoityInFuture: Active state of single commodity under FutureQuote Section is beign stored
		 */
		setSelectedFilters(state, action: PayloadAction<any>) {
			state.selectedFilters = {
				...state.selectedFilters,
				...action.payload,
			};
		},
		activeCommoityInFuture(state, action: PayloadAction<any>) {
			state.activeState.commodity = action.payload;
		},
		loadProductList(state) {
			state.productList = {
				data: [],
				loading: true,
				error: null,
			};
		},
		productListLoaded(state, action: PayloadAction<Product[]>) {
			state.productList = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},
		productListError(state, action: PayloadAction<GenericError>) {
			state.productList = {
				...state.productList,
				loading: false,
				error: action.payload,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = cashBidsAndQuotesSlice;
