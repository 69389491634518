/**
 *
 * ContractTypeSection
 *
 */
import React, { memo } from 'react';

import { ContractTypePill } from 'app/components/ContractTypePill';

import { Section } from '../Section';

interface Props {
	summary: any;
}

export const ContractTypeSection = memo((props: Props) => {
	const { summary } = props;
	return (
		summary && (
			<Section span={2} dataTestId="contract-type" noBorder={false}>
				<div className="contract-item" key={summary.contractType.type.label}>
					<span className="label">{summary.contractType.type.label}</span>
					<ContractTypePill
						contractType={summary.contractType.type.value}
						isSell={summary.contractType.isSell.value}
					/>
				</div>
				<div
					className="contract-item"
					key={summary.contractType.commodity.label}
				>
					<span className="label">{summary.contractType.commodity.label}</span>
					{summary.contractType.commodity.value
						? summary.contractType.commodity.value
						: '----'}
				</div>
			</Section>
		)
	);
});
