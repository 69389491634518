/**
 * ActionsMenu : Actions menu (the monu  which comes from more option (...)) for each row in the Contract table
 */
import {
	ArrowsAltOutlined,
	DollarOutlined,
	EditFilled,
	EyeFilled,
	RetweetOutlined,
	RotateRightOutlined,
	StopOutlined,
	SyncOutlined,
	UndoOutlined,
} from '@ant-design/icons';
import { Button, Divider, Popover } from 'antd';
import React, { Dispatch, memo, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectResendToERPLoading } from 'app/containers/Contracts/selectors';
import { actions } from 'app/containers/Contracts/slice';
import { contract, ContractsModals } from 'app/containers/Contracts/types';
import { translations } from 'locales/i18n';
import { EventType } from 'types/EventType';

interface Props {
	data: contract;
	setActivePopover?: Dispatch<SetStateAction<string | null>>;
	handleViewOrder: () => void;
}

export const ActionsMenu = memo(
	({ data, setActivePopover, handleViewOrder }: Props) => {
		const dispatch = useDispatch();
		const resendToERPLoading = useSelector(selectResendToERPLoading);

		const translationsScope =
			translations.app.containers.OrderContainer.components.OrdersTable;

		const { t: translate } = useTranslation();
		const handleCancelOrder = (contractData: contract): void => {
			dispatch(actions.setCurrentModal(ContractsModals.Cancel));
			dispatch(actions.setActiveOrder(contractData));
		};
		const handleUndoOrder = (contractData: contract): void => {
			dispatch(actions.setCurrentModal(ContractsModals.Undo));
			dispatch(actions.setActiveOrder(contractData));
		};

		const handleEditOrder = (contractId: string): void => {
			dispatch(actions.loadContractDetails(contractId));
			dispatch(actions.setCurrentModal(ContractsModals.Edit));
		};

		const priceHandler = (contractId: string): void => {
			dispatch(actions.loadContractDetails(contractId));
			dispatch(actions.setCurrentModal(ContractsModals.PriceRoll));
		};

		const handleRollModal = (data: contract): void => {
			switch (data.contract.type.toLowerCase()) {
				case 'hta':
					dispatch(actions.setCurrentEventType(EventType.rollingHTA));
					break;
				case 'basis':
					dispatch(actions.setCurrentEventType(EventType.rollingBasis));
					break;
			}
			priceHandler(data.id);
		};

		const handlePriceModal = (data: contract): void => {
			switch (data.contract.type.toLowerCase()) {
				case 'hta':
					dispatch(actions.setCurrentEventType(EventType.pricingHTA));
					break;
				case 'basis':
					dispatch(actions.setCurrentEventType(EventType.pricingBasis));
					break;
			}
			priceHandler(data.id);
		};

		const handlePriceViaOfferModal = (data: contract): void => {
			switch (data.contract.type.toLowerCase()) {
				case 'hta':
					dispatch(actions.setCurrentEventType(EventType.pricingViaOfferHTA));
					break;
				case 'basis':
					dispatch(actions.setCurrentEventType(EventType.pricingViaOfferBasis));
					break;
			}
			priceHandler(data.id);
		};

		const handlePriceViaEFPModal = (data: contract): void => {
			switch (data.contract.type.toLowerCase()) {
				case 'basis':
					dispatch(actions.setCurrentEventType(EventType.pricingViaEfpBasis));
					break;
			}
			priceHandler(data.id);
		};

		const handleApplyNameId = (data: contract): void => {
			dispatch(actions.loadApplyNameId(data.id));
			dispatch(actions.setCurrentModal(ContractsModals.ApplyNameId));
		};

		const handleResentToERP = (data: contract): void => {
			dispatch(actions.resendToERP(data.id));
		};

		const handleConvertModal = (contractId: string): void => {
			dispatch(actions.loadContractDetails(contractId));
			dispatch(actions.setCurrentModal(ContractsModals.Convert));
		};

		return (
			<div
				className="actions"
				onClick={() => {
					setActivePopover && setActivePopover(null);
				}}
			>
				<Button
					onClick={() => {
						handleViewOrder();
					}}
					data-testid="view-button"
				>
					<EyeFilled />
					{translate(translationsScope.contractViewDetails)}
				</Button>

				{data.restrictions.canBeRolled && (
					<Button
						onClick={() => handleRollModal(data)}
						data-testid="roll-modal-button"
					>
						<SyncOutlined />
						{translate(translationsScope.roll)}
					</Button>
				)}
				{data.restrictions.canBeCanceled && (
					<Button
						onClick={() => handleCancelOrder(data)}
						data-testid="cancel-button"
					>
						<StopOutlined />
						{translate(translationsScope.cancelContract)}
					</Button>
				)}
				{data.restrictions.canBeUndone && (
					<Button
						onClick={() => handleUndoOrder(data)}
						data-testid="undo-button"
					>
						<UndoOutlined />
						{translate(translationsScope.undoContract)}
					</Button>
				)}
				{data.restrictions.canBeEdited && (
					<Button
						onClick={() => handleEditOrder(data.id)}
						data-testid="edit-button"
					>
						<EditFilled />
						{translate(translationsScope.editContract)}
					</Button>
				)}
				{data.restrictions.canBeAppliedNameId &&
					(data.restrictions.hasActivePricingViaOffer &&
					data.remainingBalance <= 0 ? (
						<Popover
							content={
								<div className="popover--content">
									{translate(translationsScope.openOffer)}
								</div>
							}
							trigger="hover"
						>
							<Button data-testid="apply-name-id-modal-button" disabled={true}>
								<ArrowsAltOutlined />
								{translate(translationsScope.applyNameId)}
							</Button>
						</Popover>
					) : (
						<Button
							onClick={() => handleApplyNameId(data)}
							data-testid="apply-name-id-modal-button"
						>
							<ArrowsAltOutlined />
							{translate(translationsScope.applyNameId)}
						</Button>
					))}
				{(data.restrictions.canBePriced ||
					data.restrictions.canBePricedViaOffer ||
					data.restrictions.canBePricedViaEFP) && <Divider />}
				{data.restrictions.canBePriced && (
					<Button
						onClick={() => handlePriceModal(data)}
						data-testid="price-modal-button"
					>
						<DollarOutlined />
						{translate(translationsScope.price)}
					</Button>
				)}
				{data.restrictions.canBePricedViaOffer && (
					<Button
						onClick={() => handlePriceViaOfferModal(data)}
						data-testid="price-via-offer-modal-button"
					>
						<DollarOutlined />
						{translate(translationsScope.priceViaOffer)}
					</Button>
				)}
				{data.restrictions.canBePricedViaEFP && (
					<Button
						onClick={() => handlePriceViaEFPModal(data)}
						data-testid="price-via-efp-modal-button"
					>
						<DollarOutlined />
						{translate(translationsScope.priceViaEfp)}
					</Button>
				)}
				{data.restrictions.canBeResentToERP && (
					<Button
						data-testid="resend-erp-button"
						disabled={resendToERPLoading}
						onClick={() => handleResentToERP(data)}
					>
						<RetweetOutlined />
						{translate(translationsScope.resendErp)}
					</Button>
				)}

				{data.restrictions.canBeConverted && (
					<Button
						onClick={() => handleConvertModal(data.id)}
						data-testid="roll-modal-button"
					>
						<RotateRightOutlined />
						{translate(translationsScope.convert)}
					</Button>
				)}
			</div>
		);
	},
);
