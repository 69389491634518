import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GenericTable } from 'app/components/GenericTable';
import { SwitchStateFull } from 'app/components/SwitchStateFull';
import { translations } from 'locales/i18n';

import {
	selectListLoading,
	selectServiceFeesListData,
	selectServiceFeesStatusError,
} from '../../../selectors';
import { serviceFeesActions } from '../../../slice';
import { Delete } from './Delete';
import { Edit } from './Edit';

const { Paragraph } = Typography;

export const TableWrapper = () => {
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.ServiceFees;

	const listData = useSelector(selectServiceFeesListData);
	const listLoading = useSelector(selectListLoading);

	const rows = { rows: 2 };
	const widthEdit = 20;

	const columns = [
		{
			title: translate(translationsScope.View.List.Table.Headers.Status),
			dataIndex: 'isActive',
			key: 'isActive',
			fixed: true,
			width: 70,
			render: (data, record) => (
				<GenericTable.Column>
					<SwitchStateFull
						initialStatus={data}
						recordId={record.id}
						toggleAction={serviceFeesActions.setServiceFeeStatus}
						errorSelector={selectServiceFeesStatusError}
						toogleOnMessage={translate(
							translationsScope.View.List.Table.toggleOnMessage,
						)}
						toggleOffMessage={translate(
							translationsScope.View.List.Table.toggleOffMessage,
						)}
						confirmButtonText={translate(
							translationsScope.View.List.Table.confirmButtonText,
						)}
						cancelButtonText={translate(
							translationsScope.View.List.Table.cancelButtonText,
						)}
						stateControlled={false}
					/>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.Type),
			className: 'column-table',
			dataIndex: 'transactionType',
			key: 'transactionType',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={rows}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.Commodity),
			className: 'column-table',
			dataIndex: 'commodity',
			key: 'commodity',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={rows}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.CropYear),
			className: 'column-table',
			dataIndex: 'cropYear',
			key: 'cropYear',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={rows}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.Contract),
			className: 'column-table',
			dataIndex: 'contract',
			key: 'contract',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={rows}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: '',
			className: 'column-table',
			fixed: 'right' as 'right',
			width: widthEdit,
			render: (data) => (
				<GenericTable.Column className="edit">
					<Edit rowData={data} />
				</GenericTable.Column>
			),
		},
		{
			title: '',
			className: 'column-table',
			fixed: 'right' as 'right',
			width: widthEdit,
			render: (data) => (
				<GenericTable.Column className="last-column">
					<Delete rowData={data} />
				</GenericTable.Column>
			),
		},
	];

	return (
		<>
			<GenericTable.Table
				otherClassName="table-container"
				columns={columns}
				loading={listLoading}
				data={listData}
				pagination={false}
			/>
		</>
	);
};
