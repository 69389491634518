import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { translations } from 'locales/i18n';

import { LocationForm } from '../../components/LocationForm';
import { SubmittedLocationValues } from '../../fields/types';
import { actions } from '../../slice';

export const CreateLocation = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const domain = translations.app.containers.Settings.sections.Locations;

	const handleSubmit = (values: SubmittedLocationValues) => {
		const data = {
			name: values.locationName,
			number: values.locationNumber,
			isLocation: values.locationOrigin.some((value) => value === 'isLocation'),
			isDestination: values.locationOrigin.some(
				(value) => value === 'isDestination',
			),
		};

		dispatch(
			actions.createLocation({
				data: data,
				successMessage: translate(domain.toastSuccessCreateMessage, {
					fullNameCreated: values.locationName.label,
				}),
			}),
		);
	};

	return (
		<LocationForm
			title={translate(domain.createLocationModalTitle)}
			submitButtonLabel={translate(domain.addLocationModalButton)}
			handleSubmit={handleSubmit}
		/>
	);
};
