import './style.scss';

import { TextAreaProps } from 'antd/lib/input';
import { Form, Input } from 'formik-antd';
import React, { memo } from 'react';

interface Props extends TextAreaProps {
	name: string;
	key?: string;
	label?: string;
	placeholder?: string;
}

export const FormTextArea = memo((props: Props) => {
	const { name, label, placeholder, key } = props;

	return (
		<Form.Item
			className="form-text-area__label"
			label={label}
			name={name}
			key={key}
		>
			<Input.TextArea
				{...props}
				name={name}
				key={key}
				placeholder={placeholder}
			></Input.TextArea>
		</Form.Item>
	);
});
