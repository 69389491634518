import './style.scss';

import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Split from 'react-split';

import { Trade } from 'types/TradeBook';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { ButtonsDefination } from './components/ButtonsDefination';
import { BuyTableWrapper } from './components/Views/BuyTableWrapper';
import { ListMenu } from './components/Views/ListMenu';
import { SellTableWrapper } from './components/Views/SellTableWrapper';
import { TradeBookSaga } from './root-saga';
import { selectSelectedBuyRows, selectSelectedSellRows } from './selectors';
import { reducer, sliceKey } from './slice';

export function TradeBook() {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: TradeBookSaga });
	const selectedSellRows = useSelector(selectSelectedSellRows);
	const selectedBuyRows = useSelector(selectSelectedBuyRows);
	const [buySelectionCount, setBuySelectionCount] = useState<number>(0);
	const [sellSelectionCount, setSellSelectionCount] = useState<number>(0);
	const [buyDataSource, setBuyDataSource] = useState<Trade[]>([]);
	const [initialBuyDataSource, setInitialBuyDataSource] = useState<Trade[]>([]);
	const [sellDataSource, setSellDataSource] = useState<Trade[]>([]);
	const [initialSellDataSource, setInitialSellDataSource] = useState<Trade[]>(
		[],
	);

	useEffect(() => {
		if (selectedSellRows.length === 0) setSellSelectionCount(0);
		if (selectedBuyRows.length === 0) setBuySelectionCount(0);
	}, [selectedSellRows, selectedBuyRows]);

	return (
		<Card bordered={true} className="tradebook">
			<ListMenu />
			<div className="tradebook-container">
				<div className="tradebook-container__tables">
					<Split direction="horizontal" className="tradebook__split">
						<div className="tradebook-container__buy-table">
							<BuyTableWrapper
								buyDataSource={buyDataSource}
								setBuyDataSource={setBuyDataSource}
								initialBuyDataSource={initialBuyDataSource}
								setInitialBuyDataSource={setInitialBuyDataSource}
								sellSelectionCount={sellSelectionCount}
								buySelectionCount={buySelectionCount}
								setBuySelectionCount={setBuySelectionCount}
							/>
						</div>
						<div className="tradebook-container__sell-table">
							<SellTableWrapper
								sellDataSource={sellDataSource}
								setSellDataSource={setSellDataSource}
								initialSellDataSource={initialSellDataSource}
								setInitialSellDataSource={setInitialSellDataSource}
								buySelectionCount={buySelectionCount}
								sellSelectionCount={sellSelectionCount}
								setSellSelectionCount={setSellSelectionCount}
							/>
						</div>
					</Split>
				</div>
				<ButtonsDefination
					setBuySelectionCount={setBuySelectionCount}
					setSellSelectionCount={setBuySelectionCount}
					buyDataSource={buyDataSource}
					sellDataSource={sellDataSource}
				/>
			</div>
		</Card>
	);
}
