/**
 *
 * OrderSection
 *
 */
import React, { memo } from 'react';

import { IsSellRoundPill } from 'app/components/IsSellRoundPill';

import { Section } from '../Section';

interface Props {
	summary: any;
}

export const OrderSection = memo((props: Props) => {
	const { summary } = props;

	return (
		summary && (
			<Section span={2} dataTestId="order-detail" noBorder={true}>
				<div className="contract-item">
					<span className="label">{summary.order.number.label}</span>
					{summary.order.number.value ?? '----'}
					<span className="icon">
						<IsSellRoundPill isSell={summary.order.isSell.value} />
					</span>
				</div>
				<div className="contract-item">
					<span className="label">{summary.order.account.label}</span>
					{summary.order.account.value ?? '----'}
				</div>
				<div className="contract-item">
					<span className="label">{summary.order.theirContract.label}</span>
					{summary.order.theirContract.value ?? '----'}
				</div>
			</Section>
		)
	);
});
