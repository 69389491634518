import * as yup from 'yup';
yup.addMethod(yup.object, 'requiredOption', (message?: string) => {
	return yup.mixed().test({
		test: (value) => value?.value,
		message,
		name: 'requiredOption',
		exclusive: true,
	});
});
export default yup;
