/* --- STATE --- */
import { UploadResponse } from 'types/Commodity';
import { GenericError } from 'types/GenericError';

export enum View {
	List = 'list',
}

export enum Modal {
	Create = 'Create',
	Edit = 'Edit',
}

export interface TooglePayload {
	id: string;
	isActive: boolean;
}

export interface CommodityBrokerMappingPayload {
	contractTypeId: string;
	hedgeAccountId: string;
	cropYear: number;
	regionId?: string;
	commodityId?: string;
}

export interface CommodityCropSectionYears {
	year: number;
	HTA: string;
	Basis: string;
	FlatPrice: string;
}

export interface CommodityCropSection {
	[regionId: string]: CommodityCropSectionYears[];
}

export interface Commodity {
	id: string;
	name: string;
	lotFactor: number;
	priceControl: number;
	basisControl: number;
	number: string;
	hedgeFutures: string;
	productId: string;
	cropYearStartDate: string;
	cropYearEndDate: string;
	hasAdditionalCrop: boolean;
	additionalCropYearsAdded: null | string;
	numberOfCropYears: number;
	fileId: string;
	months: number;
	brokerMapping: null | CommodityBrokerMappingPayload[];
}

export interface FileUploadPayload {
	data: FormData;
	config: Record<string, Function>;
}

export interface CommoditiesState {
	currentView: View;
	currentModal: Modal | null;

	activeCommodity: {
		data: Commodity | null;
		error: GenericError | null;
		loading: boolean;
	};

	list: {
		data: Commodity[];
		loading: boolean;
		total: number;
		error: GenericError | null;
	};

	commodityStatus: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};

	products: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};

	hedgeMapTemplate: {
		data: any;
		uploadResponse: UploadResponse;
		loading: boolean;
		error: GenericError | null;
	};
	loadFilledHedgemapTemplate: {
		commodityId: string;
		name?: string;
		additionalCropYearsAdded?: number;
	};
	validationTextHelper: {
		label: string;
	};
}

export type CommoditySectionState = CommoditiesState;
