import './style.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { useAbac } from 'react-abac';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import {
	FormButtonsProps,
	FormSchemaDefinition,
} from 'app/components/GenericForm/types';
import { setCashbidsState } from 'app/containers/Contracts/selectors';
import { actions as contractAction } from 'app/containers/Contracts/slice';
import { selectOrderEntry } from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { selectActiveOfferLoading } from 'app/containers/Offers/selectors';
import { actions } from 'app/containers/Offers/slice';
import { SourceContext } from 'app/containers/Transactions';
import { ModalHeader } from 'app/containers/Transactions/components/ModalHeader';
import { useDefaultValues } from 'app/containers/Transactions/hooks/useDefaultValues';
import { useGroupsFormSchema } from 'app/containers/Transactions/hooks/useGroupsFormSchema';
import { Permission } from 'types/Authorization';
import { ContractTypeSource } from 'types/ContractTypeSource';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import {
	CONSTANTS as GLOBAL_CONSTANTS,
	CONTRACT_TYPES,
	DATE_FORMAT,
	PRICE_DECIMALS,
} from 'utils/constants';
import {
	customFormat,
	isActionSell,
	isDeliveryDateCustom,
} from 'utils/helpers';
import { getOrderEntriesFormValues } from 'utils/order-entry-helpers';
import { isEmptyObject } from 'utils/validators';

import { useTranslations } from '../shared/useTranslations';
import { useOffersSchema } from './schemas/Offers';

export const CreateModal = memo(() => {
	const dispatch = useDispatch();
	const { userHasPermissions } = useAbac();

	const translations = useTranslations();
	const orderEntries = useSelector(selectOrderEntry);
	const isLoading = useSelector(selectActiveOfferLoading);
	const cashbidsState = useSelector(setCashbidsState);
	const defaultInitialValues = useDefaultValues(ContractTypeSource.OFFER);

	const [closeAfterSubmit, setCloseAfterSubmit] = useState(true);
	const { newOfferTitle, createConfirmationMessage, confirmText, cancelText } =
		translations.actions;

	const { contract } = defaultInitialValues;

	const offerSchemas = useOffersSchema();
	const defaultSchema = offerSchemas[contract.label];
	const [currentSchema, setCurrentSchema] =
		useState<FormSchemaDefinition>(defaultSchema);

	const resolver = yupResolver(currentSchema.validationSchema);

	const formInstance = useForm({
		defaultValues: {
			...currentSchema.initialValues,
			...(isEmptyObject(cashbidsState) ? defaultInitialValues : cashbidsState),
		},
		resolver,
		mode: 'all',
	});

	const { watch } = formInstance;

	const currentTransactionType = watch('transaction')?.value;
	const currentContractType = watch('contract')?.value;

	const cleanState = () => {
		dispatch(globalActions.clearFuturesPrices(sourceName));
		dispatch(globalActions.clearPostedBasisPrice(sourceName));
		dispatch(globalActions.clearFuturesMonth(sourceName));
		dispatch(globalActions.clearDeliveryDates(sourceName));
		dispatch(
			globalActions.clearFuturesMonthOptions(
				CalculatedFieldsSource.Transactions,
			),
		);
	};

	useEffect(() => {
		const { transaction, contract, theirContract } = formInstance.watch();
		const { isDirty } = formInstance.formState;

		const getSchema = (cashbidsState) => {
			const state = cashbidsState;
			const contract = state?.contract;
			const contractType = contract?.label;
			const schema = offerSchemas[contractType];
			return schema;
		};
		if (!isEmptyObject(cashbidsState))
			setCurrentSchema(getSchema(cashbidsState));

		if (!transaction?.label || !contract?.label || !isDirty) return;

		const newSchema = offerSchemas[contract.label];

		cleanState();

		formInstance.reset({
			transaction,
			contract,
			theirContract,
			...newSchema?.initialValues,
		});

		setCurrentSchema(newSchema);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTransactionType, currentContractType]);

	useGroupsFormSchema(currentSchema, formInstance, []);

	const sourceName = GlobalSagaSource.createEditOfferModal;

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
		dispatch(globalActions.clearFuturesPrices(sourceName));
		dispatch(globalActions.clearPostedBasisPrice(sourceName));
		dispatch(globalActions.clearFuturesMonth(sourceName));
		dispatch(
			globalActions.clearFuturesMonthOptions(
				CalculatedFieldsSource.Transactions,
			),
		);
		dispatch(contractAction.clearCashbidsState());
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			className: 'order-form__new-order',
			htmlType: 'submit',
			children: translations.buttons.submitAndNewOffer,
			onClick: () => setCloseAfterSubmit(false),
			disabled: isLoading,
			debounceReenable: 1000,
			key: 'submitAndNewOffer',
		},
		{
			htmlType: 'submit',
			children: translations.buttons.submitOffer,
			disabled: isLoading,
			debounceReenable: 1000,
			key: 'submit',
		},
	];

	const header = (
		<ModalHeader
			title={newOfferTitle}
			content={`${createConfirmationMessage} ${translations.actions.confirmation}`}
			confirmText={confirmText}
			cancelText={cancelText}
			handleConfirm={handleClose}
		/>
	);

	const handleSubmit = (values) => {
		let data: any = {
			theirContract: values.theirContract,
			transactionTypeId: values.transaction.value,
			contractTypeId: values.contract.value,
			isSell: isActionSell(values.action),
			commodityId: values.commodity.value,
			locationId: values.location.value,
			deliveryLocationId:
				values.deliveryLocation.defaultDestinationLocationId ||
				values.deliveryLocation.value,
			cropYear: values.cropYear,
			isDeliveryDatesCustom: isDeliveryDateCustom(values.deliveryDatesMode),
			deliveryStartDate: values.deliveryDate?.[0].format(DATE_FORMAT),
			deliveryEndDate: values.deliveryDate?.[1].format(DATE_FORMAT),
			freightPrice: values.freight ? parseFloat(values.freight) : 0,
			fees1: values.fees1 ? parseFloat(values.fees1) : 0,
			fees2: values.fees2 ? parseFloat(values.fees2) : 0,
			gtc: values.gtcMode,
			expiration: values.expirationDateGTC?.length
				? moment(values.expirationDateGTC).format(DATE_FORMAT)
				: null,
			quantity: parseFloat(
				customFormat(
					values.quantity,
					false,
					GLOBAL_CONSTANTS.FIXED_QUANTITY_DECIMALS,
				),
			),
			customerId: values.customer.value,
			employeeId: values.employee.value,
			comments: values.comments,
			customFields: getOrderEntriesFormValues(orderEntries, values),
			groups: values.groups?.map((group) => group.value) || [],
			regionId: values?.assignedRegion?.value,
		};

		if (values.contract.label === CONTRACT_TYPES.flatPrice) {
			data = {
				...data,
				futuresMonth: values.futuresMonth?.value ?? values.futuresMonth,
				futuresPrice: values.futuresPrice ? parseFloat(values.futuresPrice) : 0,
				postedBasis: values.postedBasis ? parseFloat(values.postedBasis) : 0,
				pushBasis: values.pushBasis ? parseFloat(values.pushBasis) : 0,
				netBasis: values.netBasis ? parseFloat(values.netBasis) : 0,
				price: values.flatPrice ? parseFloat(values.flatPrice) : 0,
				cashSettlement: values.cashSettlement,
			};
		} else if (values.contract.label === CONTRACT_TYPES.basis) {
			data = {
				...data,
				postedBasis: values.postedBasis ? parseFloat(values.postedBasis) : 0,
				pushBasis: values.pushBasis ? parseFloat(values.pushBasis) : 0,
				netBasis: values.netBasis ? parseFloat(values.netBasis) : 0,
				price: values.netBasisPrice ? parseFloat(values.netBasisPrice) : 0,
			};
		} else if (values.contract.label === CONTRACT_TYPES.hta) {
			data = {
				...data,
				futuresMonth: values.futuresMonth?.value ?? values.futuresMonth,
				futuresPrice: parseFloat(values.futuresPrice || 0),
				price: parseFloat(
					customFormat(
						parseFloat(values.futuresPrice || 0) +
							parseFloat(values.freight || 0) +
							parseFloat(values.fees1 || 0) +
							parseFloat(values.fees2 || 0),
						false,
						PRICE_DECIMALS,
					),
				),
			};
		}

		batch(() => {
			if (closeAfterSubmit) {
				cleanState();
			}
			dispatch(
				actions.createOffer({
					data,
					closeAfterSubmit: closeAfterSubmit,
					successMessage: translations.common.success,
					loadReviewAndRelease: userHasPermissions(
						Permission.CONTRACTSERVICE_REVIEWANDRELEASE_VIEW,
					),
				}),
			);
			setCloseAfterSubmit(true);
		});
	};

	return (
		<GenericForm.ModalContainer
			key="customerModal"
			className="create-form__modal"
			title={header}
			onCancel={handleClose}
			closable={false}
			destroyOnClose={true}
			keyboard={false}
		>
			<SourceContext.Provider value={GlobalSagaSource.createEditOfferModal}>
				<GenericForm.Form
					className="order-form"
					key="customerForm"
					formInstance={formInstance}
					validationSchema={currentSchema?.validationSchema}
					onSubmit={handleSubmit}
					buttonsDefinition={btnsDefinition}
				>
					{currentSchema?.elements}

					{
						// TODO Review if it's necessary to add a spinner while the submition process
						/* {isLoading && <Spin key="spinner" />} */
					}
				</GenericForm.Form>
			</SourceContext.Provider>
		</GenericForm.ModalContainer>
	);
});
