import './styles.scss';

import { Spin } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectFeatureFlags } from 'app/containers/GlobalSaga/selectors';
import { FeatureFlag } from 'types/FeatureFlags';

import { useERPIntegrationData } from './hooks/useERPIntegrationData';
import { ContractIntegrationSwitchForm } from './Views/ContractIntegrationSwitchForm';
import { HedgeIntegrateSwitchForm } from './Views/HedgeIntegrateSwitchForm';
import { HedgeIntegration } from './Views/HedgeIntegration';
import { SetupForm } from './Views/SetupForm';

export const ERPIntegration = () => {
	const {
		isERPIntegrationActive,
		isLoading,
		isHedgeIntegrationActive,
		isLoadingHedgeIntegration,
	} = useERPIntegrationData();

	const featureFlags = useSelector(selectFeatureFlags);

	if (isLoading || isLoadingHedgeIntegration) {
		return <Spin data-testid="spinner-loading" key="spinner" />;
	}

	return (
		<div className="erp-integration-container">
			<ContractIntegrationSwitchForm />
			{isERPIntegrationActive && <SetupForm />}
			{featureFlags[FeatureFlag.enableHedgeIntegration] && (
				<HedgeIntegrateSwitchForm />
			)}
			{isHedgeIntegrationActive &&
				featureFlags[FeatureFlag.enableHedgeIntegration] && (
					<HedgeIntegration />
				)}
		</div>
	);
};
