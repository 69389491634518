import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { translations } from 'locales/i18n';
import { MIN_VALUE } from 'utils/constants';

interface Props {
	disabled?: {
		qtyPriceBalance?: boolean;
		qtyPriceAmount?: boolean;
	};
}

export const QuantityToPrice = memo((props: Props) => {
	const { t: translate } = useTranslation();
	const { disabled } = props;

	const msg = translations.app.containers.Contracts.components.QuantityToPrice;
	const { setValue } = useFormContext();

	const handleAmountChange = () => {
		setValue('efpBushels', MIN_VALUE);
	};

	return (
		<GenericForm.FormItem
			label={translate(msg.quantityToPriceLabel)}
			name="quantityToPrice"
			data-testid="quantityToPrice-form-item"
		>
			<GenericForm.FormItem
				name="qtyPriceBalance"
				data-testid="qtyPriceBalance-form-item"
				customClassName={['input-quantity__label']}
			>
				<Input
					className="input-quantity__input"
					data-testid="qtyPriceBalance-input-item"
					type="text"
					disabled={disabled?.qtyPriceBalance}
					name="qtyPriceBalance"
					suffix={<>{translate(msg.balance.label)}</>}
				/>
			</GenericForm.FormItem>
			<GenericForm.FormItem
				isNumericFieldSlow
				name="qtyPriceAmount"
				data-testid="qtyPriceAmount-form-item"
				customClassName={['input-quantity__label']}
				onChange={handleAmountChange}
			>
				<Input
					className="input-quantity__input"
					placeholder={translate(msg.quantityToPricePlaceholder)}
					data-testid="qtyPriceAmount-input-item"
					type="text"
					disabled={disabled?.qtyPriceAmount}
					name="qtyPriceAmount"
				/>
			</GenericForm.FormItem>
		</GenericForm.FormItem>
	);
});
