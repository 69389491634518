import { HubConnection } from '@microsoft/signalr';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';

import { actions } from 'app/containers/HubSaga/slice';
import { UserInternalNotification } from 'types/UserNotification';

import { hubSubscriptionChannels } from '../constants';

export function inAppNotificationsHandler(
	connection: HubConnection,
	dispatch: Dispatch<AnyAction>,
) {
	connection.on(
		hubSubscriptionChannels.receiveNotificationList,
		(data: UserInternalNotification[]) => {
			dispatch(actions.notificationListLoaded(data));
		},
	);
	connection.on(
		hubSubscriptionChannels.innerNotifications,
		(data: UserInternalNotification) => {
			dispatch(actions.notificationItemLoaded(data));
			dispatch(actions.playNotificationSound());
		},
	);
}
