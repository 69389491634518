import { PayloadAction } from '@reduxjs/toolkit';

import { FutureMonth } from 'types/FutureMonth';
import { FuturesErp } from 'types/FuturesErp';
import { GenericError } from 'types/GenericError';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { mapToLabelValue } from 'utils/helpers';

import { FuturesErpFilters, FuturesErpState, pagination } from './types';

export const initialState: FuturesErpState = {
	selectedFilters: {
		commodityId: [],
		orderNo: null,
		erpStatus: [],
		futuresMonth: [],
		endDate: null,
		startDate: null,
		accountNo: null,
	},

	pagination: {
		limit: 50,
		start: 1,
	},

	futuresErpList: {
		data: [],
		loading: false,
		total: 0,
		error: null,
	},
	integrateToErp: {
		loading: false,
		error: null,
	},

	selectedRows: [],
	futureMonths: {
		data: [],
		error: null,
		loading: false,
	},
};

const contractsSlice = createSlice({
	name: 'futuresErp',
	initialState,
	reducers: {
		setSelectedFilters(state, action: PayloadAction<FuturesErpFilters | null>) {
			state.selectedFilters = {
				...state.selectedFilters,
				...action.payload,
			};
		},

		loadFuturesErpList(state) {
			state.futuresErpList = {
				...state.futuresErpList,
				data: [],
				loading: true,
				error: null,
			};
		},
		futuresErpListLoaded(
			state,
			action: PayloadAction<{ data: FuturesErp[]; total: number }>,
		) {
			const { data, total } = action.payload;
			state.futuresErpList = {
				...state.futuresErpList,
				data,
				total,
				loading: false,
				error: null,
			};
		},

		futuresErpListError(state, action: PayloadAction<GenericError>) {
			state.futuresErpList = {
				...state.futuresErpList,
				error: action.payload,
				loading: false,
			};
		},

		integrateToErpRequest(state, action: PayloadAction<string[]>) {
			state.integrateToErp = {
				...state.integrateToErp,
				loading: true,
				error: null,
			};
		},

		integrateToErpError(state, action: PayloadAction<GenericError>) {
			state.integrateToErp = {
				...state.integrateToErp,
				error: action.payload,
				loading: false,
			};
		},

		setPagination(state, action: PayloadAction<pagination>) {
			state.pagination = {
				limit: action.payload.limit || state.pagination.limit,
				start: action.payload.start || state.pagination.start,
			};
		},
		setSelectedRows(state, action: PayloadAction<{ id: string }[]>) {
			state.selectedRows = action.payload;
		},

		setSelectedRowsAsEmpty(state) {
			state.selectedRows = [];
		},
		getFuturesMonthsError(state, action: PayloadAction<GenericError>) {
			state.futureMonths = {
				...state.futureMonths,
				error: action.payload,
				loading: false,
			};
		},

		getFuturesMonthsRequest(state) {
			state.futureMonths = {
				...state.futureMonths,
				data: [],
				error: null,
				loading: true,
			};
		},

		getFuturesMonthsSuccess(state, action: PayloadAction<FutureMonth[]>) {
			state.futureMonths = {
				...state.futureMonths,
				data: mapToLabelValue(
					action?.payload.map((item) => ({
						...item,
						highlight: item.isExpired,
					})),
				),
				error: null,
				loading: false,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = contractsSlice;
