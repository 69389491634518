import { FIELD_TYPES_VALUES } from 'types/OrderEntry';
import { dateShortFormat } from 'utils/helpers';

const getBlnValue = (formValue: any, translations: any) =>
	formValue
		? translations.customFields.isFieldTrue
		: translations.customFields.isFieldFalse;

const getDatValue = (formValue: any) => formValue && dateShortFormat(formValue);

const getDefaultValue = (formValue: any) => formValue;

const getIntValue = (formValue: any) =>
	formValue && parseFloat(formValue).toFixed(4);

const formValues = {
	[FIELD_TYPES_VALUES.BLN]: getBlnValue,
	[FIELD_TYPES_VALUES.DAT]: getDatValue,
	[FIELD_TYPES_VALUES.INT]: getIntValue,
	[FIELD_TYPES_VALUES.MUL]: getDefaultValue,
	[FIELD_TYPES_VALUES.TXT]: getDefaultValue,
	[FIELD_TYPES_VALUES.TXT2]: getDefaultValue,
	[FIELD_TYPES_VALUES.UND]: () => null,
};

const getValue = (
	translations: any,
	type: FIELD_TYPES_VALUES | undefined = FIELD_TYPES_VALUES.UND,
	value: any,
) => formValues[type](value, translations);

export const getCustomFields = (activeEntity: any, translations: any) =>
	activeEntity.customFields?.map(({ key, type, value }) => ({
		label: `${key}:`,
		value: getValue(translations, type, value),
	}));
