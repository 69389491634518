import React, { memo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { SearchWithFeedback } from 'app/components/SearchWithFeedback';
import { selectEmployeesList } from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { translations } from 'locales/i18n';
import { mapToLabelValue } from 'utils/helpers';

import { SourceContext } from '../..';

interface Props {
	disabled?: boolean;
}

export const Employee = memo((props: Props) => {
	const { disabled = false } = props;

	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const sourceName = useContext(SourceContext);

	const msg = translations.app.containers.Transactions.components.Employee;

	const employees = useSelector(selectEmployeesList);

	const employeesList = employees?.[sourceName]?.data
		? mapToLabelValue(employees[sourceName].data)
		: [];

	const employeesLoading = employees?.[sourceName]?.loading ?? false;

	const onSearchEmployee = (searchText) => {
		dispatch(
			globalActions.loadEmployeesList({
				source: sourceName,
				search: searchText,
				isActiveOnly: true,
			}),
		);
	};

	const clearEmployeesList = () => {
		dispatch(globalActions.clearEmployeesList({ source: sourceName }));
	};

	useEffect(() => {
		clearEmployeesList();
	}, []);

	return (
		<GenericForm.FormItem
			label={translate(msg.label)}
			name="employee"
			customClassName={['search-form__label']}
			data-testid="employee-form-item"
		>
			<SearchWithFeedback
				disabled={disabled}
				name="employee"
				key="employee"
				placeholder={translate(msg.placeholder)}
				onSearch={onSearchEmployee}
				options={employeesList}
				clearOptions={clearEmployeesList}
				loading={employeesLoading}
			/>
		</GenericForm.FormItem>
	);
});
