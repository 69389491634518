import { createSlice } from 'utils/@reduxjs/toolkit';

import { NtcAdminSectionState } from './types';

const initialState: NtcAdminSectionState = {
	ntcAdminUpdate: {
		data: null,
		error: null,
		loading: false,
	},
};

const NtcAdminSlice = createSlice({
	name: 'ntcAdmin',
	initialState,
	reducers: {
		setNtcAdminError(state, action) {
			state.ntcAdminUpdate = {
				...state.ntcAdminUpdate,
				error: action?.payload,
				loading: false,
			};
		},

		setNtcAdminRequest(state, action) {
			state.ntcAdminUpdate = {
				...state.ntcAdminUpdate,
				data: null,
				error: null,
				loading: true,
			};
		},

		setNtcAdminSuccess(state, action) {
			state.ntcAdminUpdate = {
				...state.ntcAdminUpdate,
				data: action?.payload,
				error: null,
				loading: false,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = NtcAdminSlice;
