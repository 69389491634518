import { takeLatest } from 'redux-saga/effects';

import { applyNameID, getApplyNameID } from './sagas/apply-name-id';
import {
	bulkConvertContract,
	bulkUpdateContract,
	cancelContract,
	convertContract,
	createContract,
	getContractDetails,
	getContractList,
	getContractsChildren,
	getContractSummary,
	getRollSpread,
	updateContract,
} from './sagas/contracts-saga';
import { getContractsFuturesMonthsList } from './sagas/contractsFuturesMonth-saga';
import { priceRollContract } from './sagas/price-roll-contracts';
import { resendToERP } from './sagas/resend-to-erp-saga';
import { actions } from './slice';

export function* contractsSaga() {
	// Watches for loadOrdersList actions and calls getOrdersList when one comes in.
	yield takeLatest(actions.loadContractList.type, getContractList);

	// Watches for loadOrderDetails actions and calls getOrderDetails when one comes in.
	yield takeLatest(actions.loadContractDetails.type, getContractDetails);

	// Watches for loadContractSummray actions and calls getContractSummary when one comes in.
	yield takeLatest(actions.loadContractSummary.type, getContractSummary);

	// Watches for createOrder actions and calls createOrder when one comes in.
	yield takeLatest(actions.createContract.type, createContract);

	// Watches for convertOrder actions and calls createOrder when one comes in.
	yield takeLatest(actions.convertOrder.type, convertContract);

	// Watches for updateOrder actions and calls updateOrder when one comes in.
	yield takeLatest(actions.updateOrder.type, updateContract);

	// Watches for cancelOrder actions and calls cancelOrder when one comes in.
	yield takeLatest(actions.cancelContract.type, cancelContract);

	// Watches for contractHta actions and calls createHta when one comes in.
	yield takeLatest(actions.priceRollContract.type, priceRollContract);

	// Watches for loadContractsChildren actions and calls getContractsChildren when one comes in.
	yield takeLatest(actions.loadContractsChildren.type, getContractsChildren);

	yield takeLatest(actions.loadSpreadValue.type, getRollSpread);

	yield takeLatest(actions.loadApplyNameId.type, getApplyNameID);

	yield takeLatest(actions.applyNameId.type, applyNameID);

	yield takeLatest(actions.resendToERP.type, resendToERP);

	//bulk convert order
	yield takeLatest(actions.bulkConvertOrder, bulkConvertContract);

	//bulk convert order
	yield takeLatest(actions.bulkUpdateOrder, bulkUpdateContract);

	yield takeLatest(
		actions.loadContractsFuturesMonthsList.type,
		getContractsFuturesMonthsList,
	);
}
