import { GenericError } from 'types/GenericError';
import { IGenericPagination } from 'types/GenericPagination';

import { ErrorInLine } from '../../types';
import { CurrentGroupedLocation, LocationListResponse } from './fields/types';

/* --- STATE --- */
export interface LocationsState {
	currentView: View;
	currentModal: Modal | null;

	currentLocation: {
		data: CurrentGroupedLocation | null;
		error: GenericError | null;
		loading: boolean;
	};

	list: {
		data: [];
		loading: boolean;
		total: number;
		error: GenericError | null;
	};

	searchListForContractLocation: {
		data: LocationListResponse[];
		loading: boolean;
		error: GenericError | null;
	};
	searchListForDestinationLocation: {
		data: LocationListResponse[];
		loading: boolean;
		error: GenericError | null;
	};

	enableManualEdit: { data: boolean };

	selectedFilters: Filters;

	pagination: IGenericPagination;

	/**
	 * Errors in Formik
	 * They will be displayed in line in field
	 */
	errorsInLine: ErrorInLine[];
}

export enum View {
	List = 'list',
}

export enum Modal {
	Create = 'Create',
	Edit = 'Edit',
}

enum FiltersEnum {
	Name = 'name',
	RegionIds = 'regionIds',
}

export enum FieldModes {
	Autocomplete = 'Autocomplete',
	Manual = 'Manual',
}

export interface Filters {
	[FiltersEnum.Name]: string | null;
	[FiltersEnum.RegionIds]: string[];
}

export type LocationsSectionState = LocationsState;
