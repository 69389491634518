import React, { memo, useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { setCashbidsState } from 'app/containers/Contracts/selectors';
import { selectFeatureFlags } from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { translations } from 'locales/i18n';
import { FeatureFlag } from 'types/FeatureFlags';
import { isEmptyObject } from 'utils/validators';

import { SourceContext } from '../..';
import { useLocationsByAction } from '../../hooks/useLocationsByAction';
import { useHandleBusinessRules } from '../hooks/useHandleBusinessRules';

interface Props {
	onChange?: () => {};
	disabled?: boolean;
	hidden?: boolean;
	source?: CalculatedFieldsSource;
}

export const DeliveryLocation = memo((props: Props) => {
	const featureFlags = useSelector(selectFeatureFlags);
	const cashbidsState = useSelector(setCashbidsState);
	// isDestinationLocationEnabledis used to hide destination location and use legacy version
	// TODO: Remove all if statement (not its content) and  its relevant flags when deployed to prod
	const isDestinationLocationEnabled: boolean =
		featureFlags && featureFlags[FeatureFlag.enabledDestinationLocation];

	const sourceName = useContext(SourceContext);
	const { disabled = false, hidden = false, source = undefined } = props;
	const dispatch = useDispatch();

	const { t: translate } = useTranslation();

	const msg =
		translations.app.containers.Transactions.components.DeliveryLocation;

	const { watch, setValue } = useFormContext();

	const { destinations, groupedLocations } = useLocationsByAction(
		watch('action'),
	);
	const arraysAreEqual = (arr1, arr2) => {
		if (arr1 === arr2) return true;
		if (arr1 == null || arr2 == null) return false;
		if (arr1.length !== arr2.length) return false;
		for (let i = 0; i < arr1.length; i++) {
			if (arr1[i] !== arr2[i]) return false;
		}
		return true;
	};

	useEffect(() => {
		if (
			cashbidsState &&
			!isEmptyObject(cashbidsState) &&
			isDestinationLocationEnabled
		) {
			setValue('deliveryLocation', { value: null });
		}
	}, []);

	useEffect(() => {
		if (destinations?.length === 1) {
			const currentDeliveryLocationValue = watch('deliveryLocation')?.value;
			const currentLocationValue = watch('location')?.value;
			const regionIds = destinations?.[0]?.regionIds;
			const filteredGroupLocation = groupedLocations.find(
				(item) => item.id === destinations?.[0]?.value,
			);
			// Check if the value is different before setting it to avoid an infinite loop
			if (
				(currentDeliveryLocationValue !==
					filteredGroupLocation?.destinationLocationId &&
					currentLocationValue !== filteredGroupLocation?.contractLocationId) ||
				!arraysAreEqual(regionIds, watch('deliveryLocation')?.regionIds)
			) {
				setValue('location', {
					value: filteredGroupLocation?.contractLocationId || null,
				});
				setValue('deliveryLocation', {
					value: filteredGroupLocation?.destinationLocationId || null,
					label: destinations?.[0]?.label,
					regionIds,
				});
			}
		}
	}, [destinations]);

	const { getDeliveryDates, getPostedBasis } =
		useHandleBusinessRules(sourceName);

	const handleChange = (currentValue) => {
		if (isDestinationLocationEnabled) {
			setValue('assignedRegion', { value: null });
			const filteredGroupLocation = groupedLocations.find(
				(item) => item.id === currentValue.value,
			);
			const regionIds = destinations.filter(
				(item) => item.value === currentValue.value,
			)[0]?.regionIds;
			setValue('location', {
				value: filteredGroupLocation?.contractLocationId || null,
			});
			setValue('deliveryLocation', {
				value: filteredGroupLocation?.destinationLocationId || null,
				label: currentValue.label,
				regionIds,
			});
			getPostedBasis(sourceName);
		}
		getDeliveryDates(source);
	};
	const { onChange = handleChange } = props;

	useEffect(() => {
		if (isDestinationLocationEnabled) {
			getPostedBasis(sourceName);
			dispatch(globalActions.loadGroupedLocationsList());
		}
	}, [isDestinationLocationEnabled]);

	useEffect(() => {
		getDeliveryDates(source);
	}, [dispatch, watch('action')]);

	return (
		<GenericForm.FormItem
			label={translate(msg.label)}
			name="deliveryLocation"
			onChange={onChange}
			data-testid="deliveryLocation-form-item"
			customClassName={hidden ? ['hidden'] : []}
		>
			<Dropdown
				disabled={disabled}
				key="deliveryLocation"
				placeholder={translate(msg.placeholder)}
				options={destinations}
			/>
		</GenericForm.FormItem>
	);
});
