/* --- STATE --- */
import { GenericError } from 'types/GenericError';
import { IGenericPagination } from 'types/GenericPagination';

import { ErrorInLine } from '../../types';
export interface EmployeesState {
	currentView: View;
	currentModal: Modal | null;

	activeEmployee: {
		data: any;
		error: GenericError | null;
		loading: boolean;
		userID: any;
	};

	list: {
		data: [];
		loading: boolean;
		total: number;
		error: GenericError | null;
	};

	selectedFilters: Filters;

	pagination: IGenericPagination;

	employeeStatus: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};

	permissions: {
		data: { name: string; fromRole: boolean }[] | null;
		loading: boolean;
		error: GenericError | null;
	};
	errorsInLine: ErrorInLine[];

	employeeRoles: {
		locations: {
			data: any;
			loading: boolean;
			error: GenericError | null;
		};
		limits: {
			data: any;
			loading: boolean;
			error: GenericError | null;
		};
	};
}

export enum View {
	List = 'list',
	Permissions = 'permissions',
}
export enum Modal {
	Create = 'Create',
	Edit = 'Edit',
	Location = 'Location',
}

export enum FiltersEnum {
	Query = 'query',
}

export interface Filters {
	[FiltersEnum.Query]?: string;
}

export interface TooglePayload {
	id: string;
	isActive: boolean;
}

export type EmployeeSectionState = EmployeesState;
