import { call, put } from 'redux-saga/effects';

import { FutureMonth } from 'types/FutureMonth';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { reviewAndReleaseFuturesMonths } = apiEndpoints;

export function* getFuturesMonths() {
	const requestURL = new URL(
		`${apiRoutes.base}/${reviewAndReleaseFuturesMonths}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		const data: FutureMonth[] = responseData.map((item) => ({
			id: item.name,
			...item,
		}));

		yield put(actions.getFuturesMonthsSuccess(data));
	} else if (!!responseError.detail) {
		yield put(actions.getFuturesMonthsError(responseError));
	}
}
