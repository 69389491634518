import {
	FILTERS_FOR_CONTRACTS,
	FILTERS_FOR_FUTURESERP,
	FILTERS_FOR_OFFERS,
	FILTERS_FOR_ORIGINATOR_SCREEN,
	FILTERS_FOR_REVIEW_AND_RELEASE,
} from 'utils/constants';

import { dateFormatYearMonthDay } from './helpers';

export const initialFilters = {
	[FILTERS_FOR_CONTRACTS]: {
		commodities: [],
		customerId: [],
		contractTypeId: [],
		eventId: [],
		contractOrCustomer: '',
		number: '',
		statusView: [],
		futuresMonths: [],
		employeeId: [],
		orderDirection: [],
		destinations: [],
		transactionTypeId: [],
	},
	[FILTERS_FOR_OFFERS]: {
		commodities: [],
		customerId: null,
		date: null,
		expirationDate: null,
		futuresMonth: [],
		gtc: false,
		statusView: [{ value: 'Working/Rejected', label: 'Working / Rejected' }],
		numberOrCustomer: null,
		employeeId: [],
		orderDirection: [],
		destinations: [],
	},
	[FILTERS_FOR_REVIEW_AND_RELEASE]: {
		startDate: dateFormatYearMonthDay(new Date()),
		endDate: null,
		status: [],
		sortColumnName: null,
		sortOrder: 0,
		accountName: null,
		customerId: [],
		futuresMonth: [],
		number: null,
		symbol: [],
	},
	[FILTERS_FOR_ORIGINATOR_SCREEN]: {
		products: [],
		cashbidsCommodities: [],
		locations: [],
		cropYears: [],
	},
	[FILTERS_FOR_FUTURESERP]: {
		commodityId: [],
		orderNo: null,
		erpStatus: [],
		futuresMonth: [],
		endDate: null,
		startDate: null,
		accountNo: null,
	},
};
