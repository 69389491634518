import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import { CustomNotificationTypes, NotificationMessagePayload } from '../types';

export function* loadEmployeeNotificationGroupList() {
	const { employeeGroupNotificationSettings } = apiEndpoints;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${employeeGroupNotificationSettings}`,
	);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
		null,
		false,
		{
			includeSystemGroups: false,
		},
	);

	if (!!responseData) {
		yield put(actions.employeeNotificationGroupListLoaded(responseData));
	}

	if (!!responseError) {
		yield put(actions.employeeNotificationGroupListError(responseError));
	}
}

export function* loadCustomerNotificationGroupList() {
	const { customerGroupNotificationSettings } = apiEndpoints;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${customerGroupNotificationSettings}`,
	);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.customerNotificationGroupListLoaded(responseData));
	}

	if (!!responseError) {
		yield put(actions.customerNotificationGroupListError(responseError));
	}
}

export function* sendCustomNotification(
	action: PayloadAction<NotificationMessagePayload>,
) {
	const { sendCustomNotification } = apiEndpoints;
	const { data, successMessage } = action.payload;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${sendCustomNotification}`,
	);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		data,
	);

	if (!!responseData) {
		yield put(
			actions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
				showDescription: true,
			}),
		);
	}

	if (!!responseError) {
		yield put(
			actions.addNotification({
				type: CustomNotificationTypes.ERROR,
				message: responseError,
				showDescription: true,
			}),
		);
	}
	yield put(actions.setCurrentModal(null));
}
