import { EditOutlined } from '@ant-design/icons';
import { TransferItem } from 'antd/lib/transfer';
import { TransferProps } from 'formik-antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormInputProps } from 'app/components/FormInput/types';
import { NotificationGroupDto } from 'app/containers/GlobalSaga/types';
import { FORM_HEADER } from 'app/containers/Settings/types';
import { translations } from 'locales/i18n';
import { FieldDefinition } from 'types/FieldDefinition';
import { NotificationGroupMember } from 'types/UserNotification';
import {
	generateInputField,
	generateTransfer,
} from 'utils/GenericFormInputs/generic-form-inputs';
import Yup from 'yupCustom';

interface Props {
	nonMemberList: NotificationGroupMember[];
	nonMembersTitle: string;
	memberList: NotificationGroupMember[];
	groupDetails?: NotificationGroupDto | null;
}
export const useNotificationGroupSchema = ({
	groupDetails,
	nonMemberList,
	memberList,
	nonMembersTitle,
}: Props): Array<FieldDefinition | FormInputProps | TransferProps<unknown>> => {
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.NotificationSettings;
	const requiredName = translate(translationsScope.RequiredName);
	const requiredMember = translate(translationsScope.RequiredMembers);
	const membersTitle = translate(translationsScope.MembersTitle);
	const namePlaceholder = translate(translationsScope.FieldGroupName);

	const [targetKeys, setTargetKeys] = useState<string[]>(
		memberList.map((member) => member.id),
	);
	const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

	/* The Ant Design Transfer component requires a key property to be present in the data source.
	 * We are concatenating the members and employees lists and adding the id property as the key property
	 * to present the data on the right side of the Transfer component.
	 */
	const groupEmployees: TransferItem[] =
		[...memberList, ...nonMemberList].map((employee) => ({
			...employee,
			key: employee.id,
			description: employee.email,
		})) || [];

	const handleChange = (newTargetKeys: string[]) => {
		setTargetKeys(newTargetKeys);
	};
	const handleSelectChange = (
		sourceSelectedKeys: string[],
		targetSelectedKeys: string[],
	) => {
		setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
	};
	const handleSearchFilter = (inputValue, item) =>
		item.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;

	useEffect(() => {
		const groupMembers = memberList.map((member) => member.id);
		setTargetKeys(groupMembers);
	}, [memberList.length]);

	return [
		{
			name: 'groupName',
			key: 'groupName',
			placeholder: namePlaceholder,
			initialValue: groupDetails?.name ?? '',
			render: generateInputField,
			borderless: true,
			section: FORM_HEADER,
			disabled: groupDetails?.isSystem,
			suffix: <EditOutlined />,
			validation: Yup.string().required(requiredName),
		},
		{
			name: 'groupMembers',
			key: 'groupMembers',
			initialValue: targetKeys,
			validation: Yup.array().min(1, requiredMember).required(requiredMember),
			render: generateTransfer,

			listStyle: {
				width: '100%',
				height: 500,
			},
			oneWay: true,
			showSearch: true,
			pagination: {
				pageSize: 50,
			},
			titles: [nonMembersTitle, membersTitle],
			dataSource: groupEmployees,
			selectedKeys: selectedKeys,
			filterOption: handleSearchFilter,
			onChange: handleChange,
			onSelectChange: handleSelectChange,
		},
	];
};
