/**
 *
 * OrderActionsSection
 *
 */
import './style.scss';

import {
	CheckCircleFilled,
	CloseOutlined,
	DislikeFilled,
	LikeFilled,
	RedoOutlined,
} from '@ant-design/icons';
import { Card, Col } from 'antd';
import classNames from 'classnames';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'app/containers/ReviewAndRelease/slice';
import { MenuActionType } from 'app/containers/ReviewAndRelease/types';

interface Props {
	activeEntity: any;
	span: number;
	noBorder?: boolean;
	dataTestId: string;
	handleClose: () => void;
}
export const OrderActionsSection = memo((props: Props) => {
	const { activeEntity, span, dataTestId, handleClose } = props;
	const restrictions = activeEntity?.data?.restrictions;

	const dispatch = useDispatch();

	const handleApprove = (data) => {
		dispatch(
			actions.reviewAndReleaseMenuAction({
				data: [data],
				type: MenuActionType.approve,
			}),
		);
	};

	const handleDeny = (data) => {
		dispatch(
			actions.reviewAndReleaseMenuAction({
				data: [data],
				type: MenuActionType.deny,
			}),
		);
	};

	// TODO Implement this method when resend order US is defined
	const handleResend = (data) => {
		dispatch(
			actions.reviewAndReleaseMenuAction({
				data: [data],
				type: MenuActionType.resent,
			}),
		);
	};

	const handleAcknowledge = (data) => {
		dispatch(
			actions.reviewAndReleaseMenuAction({
				data: [data],
				type: MenuActionType.acknowledge,
			}),
		);
	};

	return (
		activeEntity && (
			<Col span={span}>
				<Card
					className="contract summary-actions"
					data-testid={dataTestId}
					bordered={false}
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<div className="divider">
						<LikeFilled
							className={classNames('action-icon', {
								'disabled-icon': !restrictions?.canBeApproved,
							})}
							onClick={
								restrictions?.canBeApproved
									? () => handleApprove(activeEntity.data)
									: undefined
							}
							data-testid="approve-button"
						/>
						<DislikeFilled
							className={classNames('action-icon', {
								'disabled-icon': !restrictions?.canBeDenied,
							})}
							onClick={
								restrictions?.canBeDenied
									? () => handleDeny(activeEntity.data)
									: undefined
							}
							data-testid="deny-button"
						/>
						<RedoOutlined
							className={classNames('action-icon', {
								'disabled-icon': !restrictions?.canBeResent,
							})}
							onClick={
								restrictions?.canBeResent
									? () => handleResend(activeEntity.data)
									: undefined
							}
							data-testid="resend-button"
						/>

						<CheckCircleFilled
							className={classNames('action-icon', {
								'disabled-icon': !restrictions?.canBeAcknowledged,
							})}
							onClick={
								restrictions?.canBeAcknowledged
									? () => handleAcknowledge(activeEntity.data)
									: undefined
							}
							data-testid="acknowledge-button"
						/>

						<CloseOutlined
							className="action-icon close"
							onClick={handleClose}
							data-testid="close-order-button"
						/>
					</div>
				</Card>
			</Col>
		)
	);
});
