import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ErrorInLine } from '../../types';
import {
	EmployeeSectionState,
	Filters,
	Modal,
	TooglePayload,
	View,
} from './types';

// The initial state of the Settings container
export const initialState: EmployeeSectionState = {
	currentView: View.List,
	currentModal: null,

	activeEmployee: {
		data: null,
		loading: false,
		error: null,
		userID: null,
	},

	list: {
		data: [],
		loading: false,
		total: 0,
		error: null,
	},

	pagination: {
		limit: 50,
		start: 1,
	},

	selectedFilters: {
		query: '',
	},

	employeeStatus: {
		data: null,
		loading: false,
		error: null,
	},
	permissions: {
		data: null,
		loading: false,
		error: null,
	},
	errorsInLine: [],

	employeeRoles: {
		locations: {
			data: [],
			loading: false,
			error: null,
		},
		limits: {
			data: null,
			loading: false,
			error: null,
		},
	},
};

const employeesSlice = createSlice({
	name: 'employees',
	initialState,
	reducers: {
		setCurrentModal(state, action: PayloadAction<Modal | null>) {
			state.currentModal = action.payload;
		},

		setCurrentView(state, action: PayloadAction<View>) {
			state.currentView = action.payload;
		},

		setActiveEmployee(state, action) {
			state.activeEmployee = {
				...state.activeEmployee,
				data: action.payload,
				loading: false,
				error: null,
			};
		},
		setEmployeeUserID(state, action) {
			state.activeEmployee = {
				...state.activeEmployee,
				userID: action.payload,
				loading: false,
				error: null,
			};
		},

		requestActiveEmployee(state, action) {
			state.activeEmployee = {
				...state.activeEmployee,
				data: action.payload,
				loading: true,
				error: null,
			};
		},

		requestedEmployeeById(state, action) {
			state.activeEmployee = {
				...state.activeEmployee,
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		resetActiveEmployee(state) {
			state.activeEmployee = {
				data: null,
				loading: false,
				error: null,
				userID: null,
			};
			state.permissions = {
				...state.permissions,
				data: null,
			};
		},

		// Employees List
		loadEmployeesList(state: EmployeeSectionState) {
			state.list = {
				...state.list,
				data: [],
				loading: true,
				error: null,
			};
		},

		employeesListLoaded(
			state: EmployeeSectionState,
			action: PayloadAction<any>,
		) {
			state.list = {
				data: action.payload.list,
				total: action.payload.total,
				loading: false,
				error: null,
			};
		},

		employeesListError(
			state: EmployeeSectionState,
			action: PayloadAction<GenericError>,
		) {
			state.list = {
				...state.list,
				loading: false,
				error: action.payload,
			};
		},

		setPagination(state: EmployeeSectionState, action: PayloadAction<any>) {
			state.pagination = {
				limit: action.payload.limit || state.pagination.limit,
				start: action.payload.start || state.pagination.start,
			};
		},

		setSelectedFilters(
			state: EmployeeSectionState,
			action: PayloadAction<Filters>,
		) {
			state.selectedFilters = {
				query: action.payload.query || '',
			};
		},

		// Create Employee
		createEmployee(state, action: PayloadAction<any>) {
			state.activeEmployee = {
				...state.activeEmployee,
				loading: true,
				error: null,
			};
		},

		employeeCreated(state) {
			state.activeEmployee = {
				...state.activeEmployee,
				data: null,
				loading: false,
				error: null,
			};
		},

		employeeError(state, action: PayloadAction<GenericError>) {
			state.activeEmployee = {
				...state.activeEmployee,
				error: action.payload,
				loading: false,
			};
		},

		//  Edit employee
		editEmployee(state, action: PayloadAction<any>) {
			state.activeEmployee = {
				...state.activeEmployee,
				loading: true,
				error: null,
			};
		},

		employeeEdited(state) {
			state.activeEmployee = {
				...state.activeEmployee,
				loading: false,
				error: null,
			};
		},

		setEmployeeStatus(state, action: PayloadAction<TooglePayload>) {
			state.employeeStatus = {
				loading: true,
				error: null,
				data: action.payload.id,
			};
		},

		setEmployeeStatusSuccess(state, action) {
			state.employeeStatus = {
				...state.employeeStatus,
				loading: false,
				error: null,
			};
		},

		setEmployeeStatusError(state, action) {
			state.employeeStatus = {
				...state.employeeStatus,
				error: action.payload,
				loading: false,
			};
		},
		loadEmployeesPermissions(state, action) {
			state.permissions = {
				...state.permissions,
				loading: true,
			};
		},
		employeesPermissionsLoaded(state, action) {
			state.permissions = {
				...state.permissions,
				data: action.payload,
				loading: false,
			};
		},

		loadEmployeesPermissionsError(state, action) {
			state.permissions = {
				...state.permissions,
				error: action.payload,
				loading: false,
			};
		},
		updateEmployeePermissions(state, action) {
			state.permissions = {
				...state.permissions,
				loading: true,
			};
		},
		updateEmployeePermissionsLocations(state, action) {
			state.permissions = {
				...state.permissions,
				loading: true,
			};
		},
		employeesPermissionsUpdated(state) {
			state.permissions = {
				...state.permissions,
				loading: false,
			};
		},
		updateEmployeesPermissionsError(state, action) {
			state.permissions = {
				...state.permissions,
				error: action.payload,
				loading: false,
			};
		},

		setErrorsInLine(state, action: PayloadAction<ErrorInLine[]>) {
			state.errorsInLine = action.payload;
			state.activeEmployee = {
				...state.activeEmployee,
				loading: false,
			};
		},
		updateEmployeeLocations(state, action: PayloadAction<any>) {
			state.employeeRoles = {
				...state.employeeRoles,
				locations: {
					...state.employeeRoles.locations,
					loading: true,
				},
			};
		},
		employeeLocationsUpdated(state) {
			state.employeeRoles = {
				...state.employeeRoles,
				locations: {
					...state.employeeRoles.locations,
					loading: false,
				},
			};
		},

		updateEmployeeLimit(state, action: PayloadAction<any>) {
			state.employeeRoles = {
				...state.employeeRoles,
				limits: {
					...state.employeeRoles.limits,
					loading: true,
				},
			};
		},
		employeeLimitUpdated(state) {
			state.employeeRoles = {
				...state.employeeRoles,
				limits: {
					...state.employeeRoles.limits,
					loading: false,
				},
			};
		},
		getEmployeeLocations(state, action: PayloadAction<any>) {
			state.employeeRoles = {
				...state.employeeRoles,
				locations: {
					...state.employeeRoles.locations,
					loading: true,
				},
			};
		},
		employeeLocationsLoaded(state, action: PayloadAction<any>) {
			state.employeeRoles = {
				...state.employeeRoles,
				locations: {
					...state.employeeRoles.locations,
					data: action.payload,
					loading: false,
				},
			};
		},
		getEmployeeLimit(state, action: PayloadAction<any>) {
			state.employeeRoles = {
				...state.employeeRoles,
				limits: {
					...state.employeeRoles.limits,
					loading: true,
				},
			};
		},
		employeeLimitLoaded(state, action: PayloadAction<any>) {
			state.employeeRoles = {
				...state.employeeRoles,
				limits: {
					...state.employeeRoles.limits,
					data: action.payload,
					loading: false,
				},
			};
		},
		clearEmployeeRoles(state) {
			state.employeeRoles = {
				locations: {
					data: [],
					loading: false,
					error: null,
				},
				limits: {
					data: null,
					loading: false,
					error: null,
				},
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = employeesSlice;
