import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';

import {
	ContractType,
	functionsPermission,
	OfferType,
	RoleSections,
	RoleSettings,
	ScreenPermission,
} from '../../types';

export const UseFieldTranslations = () => {
	const { t: translate } = useTranslation();
	const domain = {
		...translations.app.commons,
		...translations.app.containers.Settings.definitions.fields,
		...translations.app.containers.Settings.sections,
		...translations.app.containers.Settings.sections.Roles.Views.CreateRole,
	};

	const customerScope =
		translations.app.containers.Settings.sections.Customers.Modals.CreateEdit;

	return {
		validationSchema: {
			required: translate(domain.validationSchema.required),
			requiredFile: translate(domain.validationSchema.requiredFile),
			invalidEmail: translate(domain.validationSchema.invalidEmail),
			noAvailableBids: translate(domain.rollOrphanAvailableBidsError),
		},
		erpNumber: {
			label: translate(domain.erpNumber.label),
			placeholder: translate(domain.erpNumber.placeholder),
			erpNumberRepeatedMessage: translate(
				customerScope.erpNumberRepeatedMessage,
			),
			defaultErrorMessage: translate(customerScope.toastErrorCreateMessage),
		},
		name: {
			nameLabel: translate(domain.name.label),
			firstNamePlaceholder: translate(domain.firstName.placeholder),
			lastNamePlaceholder: translate(domain.lastName.placeholder),
		},
		cellphone: {
			label: translate(domain.cellphone.label),
			placeholder: translate(domain.cellphone.placeholder),
		},
		address: {
			label: translate(domain.address.label),
			placeholder: translate(domain.address.placeholder),
		},
		cityPlaceholder: translate(domain.city.placeholder),
		statePlaceholder: translate(domain.state.placeholder),
		zipCodePlaceholder: translate(domain.zipCode.placeholder),
		countryPlaceholder: translate(domain.country.placeholder),
		email: {
			label: translate(domain.email.label),
			placeholder: translate(domain.email.placeholder),
		},
		role: {
			label: translate(domain.role.label),
			placeholder: translate(domain.role.placeholder),
		},
		products: {
			label: translate(domain.product.label),
			placeholder: translate(domain.product.placeholder),
		},
		commodity: {
			label: translate(domain.commodity.label),
			placeholder: translate(domain.commodity.placeholder),
		},
		priceCtrl: {
			label: translate(domain.priceCtrl.label),
		},
		basisCtrl: {
			label: translate(domain.basisCtrl.label),
		},
		addAdditionalCropYear: {
			label: translate(domain.addAdditionalCropYear.label),
		},
		isMobileAppEnable: {
			label: translate(domain.isMobileAppEnable.label),
		},
		mobileAppLimit: {
			label: translate(domain.mobileAppLimit.label),
			placeholder: translate(domain.mobileAppLimit.placeholder),
		},
		sms: {
			label: translate(domain.sms.label),
		},
		notifications: {
			label: translate(domain.notifications.label),
		},
		hedgeFuture: {
			label: translate(domain.hedgeFuture.label),
		},
		hedgeMapping: {
			dragText: translate(domain.hedgeMapping.dragText),
			downloadText: translate(domain.hedgeMapping.downloadText),
			uploadText: translate(domain.hedgeMapping.uploadText),
			uploadCompleteText: translate(domain.hedgeMapping.uploadCompleteText),
			fileFormatValidation: translate(domain.hedgeMapping.fileFormatValidation),
			fileSizeValidation: translate(domain.hedgeMapping.fileSizeValidation),
		},
		createEditRoleView: {
			header: {
				createRole: {
					namePlaceholder: translate(domain.createRole.namePlaceholder),
				},
			},
			sections: {
				[RoleSections.CONTRACT_TYPES]: translate(
					domain.roleSections.contractTypes,
				),
				[RoleSections.OFFERS_TYPES]: translate(domain.roleSections.offersTypes),
				[RoleSections.SCREENS]: translate(domain.roleSections.screens),
				[RoleSections.FUNCTIONS]: translate(domain.roleSections.functions),
				[RoleSettings.LOCATIONS]: translate(domain.roleSections.locations),
				[RoleSettings.LIMITS]: translate(domain.roleSections.limits),
			},
			labels: {
				[RoleSections.CONTRACT_TYPES]: {
					[ContractType.FLAT_PRICE]: translate(
						domain.contractType.flatPriceContact,
					),
					[ContractType.HTA]: translate(domain.contractType.htaContact),
					[ContractType.BASIS]: translate(domain.contractType.basisContact),
					[ContractType.NTC]: translate(domain.contractType.ntcContact),
				},
				[RoleSections.OFFERS_TYPES]: {
					[OfferType.FLAT_PRICE]: translate(domain.offerType.flatPriceOffer),
					[OfferType.HTA]: translate(domain.offerType.htaOffer),
					[OfferType.BASIS]: translate(domain.offerType.basisOffer),
				},
				[RoleSections.SCREENS]: {
					[ScreenPermission.REVIEW_RELEASE]: translate(
						domain.screenPermission.reviewAndRelease,
					),
					[ScreenPermission.LIVELEDGER]: translate(
						domain.screenPermission.liveLedger,
					),
					[ScreenPermission.SETTINGS]: translate(
						domain.screenPermission.settings,
					),
					[ScreenPermission.BIDSHEET_UPLOAD]: translate(
						domain.screenPermission.bidsheetUpload,
					),
					[ScreenPermission.ORIGINATOR_SCREEN]: translate(
						domain.screenPermission.cashBidsAndQuote,
					),
					[ScreenPermission.FUTURESERP_SCREEN]: translate(
						domain.screenPermission.futuresErp,
					),
					[ScreenPermission.TRADEBOOK_SCREEN]: translate(
						domain.screenPermission.tradeBook,
					),
				},
				[RoleSections.FUNCTIONS]: {
					[functionsPermission.CANCEL]: translate(
						domain.functionsPermission.cancel,
					),
					[functionsPermission.DO_NOT_HEDGE]: translate(
						domain.functionsPermission.doNotHedge,
					),
					[functionsPermission.EDIT_NO_QUANTITY]: translate(
						domain.functionsPermission.editNoQuantity,
					),
					[functionsPermission.EDIT_QUANITY]: translate(
						domain.functionsPermission.editQuantity,
					),
					[functionsPermission.SALES_TRADING]: translate(
						domain.functionsPermission.salesTrading,
					),
					[functionsPermission.RESEND_TO_ERP]: translate(
						domain.functionsPermission.resendErp,
					),
					[functionsPermission.MOBILEAPP]: translate(
						domain.functionsPermission.mobileApp,
					),
				},
				bushelsLimitLabel: translate(
					domain.createRole.limitsTab.bushelsLimitLabel,
				),
				bushelsLimitPlaceholder: translate(
					domain.createRole.limitsTab.bushelsLimitPlaceholder,
				),
				locationOperationBuy: translate(
					domain.createRole.limitsTab.locationOperationBuy,
				),
				locationOperationSell: translate(
					domain.createRole.limitsTab.locationOperationSell,
				),
				rolesNameValidation: translate(
					domain.createRole.limitsTab.roleNameValidation,
				),
			},
		},
		location: {
			contractName: {
				label: translate(domain.Locations.contractLocationNameLabel),
				placeholder: translate(
					domain.Locations.contractLocationNamePlaceholder,
				),
			},
			destinationName: {
				label: translate(domain.Locations.destinationLocationNameLabel),
				placeholder: translate(
					domain.Locations.destinationLocationNamePlaceholder,
				),
			},
			origin: {
				label: translate(domain.Locations.locationOriginLabel),
				instructions: translate(domain.Locations.locationOriginInstructions),
			},
		},
		rollOrphanBids: {
			yesBidPlaceholder: translate(
				domain.rollOrphanAvailableBidsFieldPlaceholder,
			),
		},
		enableAll: translate(domain.Roles.Views.CreateRole.enableAll),
		disableAll: translate(domain.Roles.Views.CreateRole.disableAll),
		regionLabel: translate(domain.regions),
		regionPlaceholder: translate(domain.regionPlaceholder),
	};
};
