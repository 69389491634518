/**
 *
 * Delete User Role
 *
 */
import './style.scss';

import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { translations } from 'locales/i18n';

import { selectActiveRoundingRule } from '../../selectors';
import { actions } from '../../slice';

export const DeleteRoundingRule = () => {
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.RoundingRules;
	const dispatch = useDispatch();
	const activeRoundingRule: any = useSelector(selectActiveRoundingRule);

	const handleCancel = () => {
		dispatch(actions.resetActiveRoundingRule());
		dispatch(actions.setCurrentModal(null));
	};

	const handleConfirmation = () => {
		dispatch(actions.deleteRoundingRule(activeRoundingRule?.id));
		dispatch(actions.setCurrentModal(null));
	};

	return (
		<Modal
			className="orders-cancel-modal"
			footer={[
				<Button
					className="return-button"
					data-testid="return-button"
					key="back"
					onClick={handleCancel}
				>
					{translate(translationsScope.Modals.Delete.CancelButton)}
				</Button>,
				<Button
					className="cancel-button"
					data-testid="confirm-button"
					key="submit"
					onClick={handleConfirmation}
					type="primary"
				>
					{translate(translationsScope.Modals.Delete.DeleteButton)}
				</Button>,
			]}
			maskClosable={false}
			onCancel={handleCancel}
			onOk={handleConfirmation}
			title={translate(translationsScope.Modals.Delete.Title)}
			visible
		>
			{translate(translationsScope.Modals.Delete.BodyMessage)}
		</Modal>
	);
};
