import { yupResolver } from '@hookform/resolvers/yup';
import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { FormButtonsProps } from 'app/components/GenericForm/types';
import { selectExternalFill } from 'app/containers/ReviewAndRelease/selectors';
import { actions } from 'app/containers/ReviewAndRelease/slice';
import { translations } from 'locales/i18n';
import { CONSTANTS } from 'utils/constants';
import { customFormat, isActionSell } from 'utils/helpers';

import { useEditSchema } from '../schemas/useEditSchema';

const domain =
	translations.app.containers.ReviewAndRelease.components.Views.List.Modals;

export const EditExternalFill = memo(() => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const externalFill = useSelector(selectExternalFill);
	const { elements, initialValues, validationSchema } = useEditSchema();
	const formInstance = useForm({
		defaultValues: {
			...initialValues,
		},
		mode: 'all',
		resolver: yupResolver(validationSchema),
	});

	const isLoading = externalFill.loading;

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
	};

	const handleSubmit = (values) => {
		let data: any = {
			id: externalFill.data.id,
			contractNumber: values.contractNumber || '',
			commodityId: values.commodity?.value,
			cropYear: values.cropYear,
			isSell: isActionSell(values.action),
			quantity: parseFloat(
				customFormat(values.quantity, false, CONSTANTS.FIXED_QUANTITY_DECIMALS),
			),
			futuresMonth: values.futuresMonth?.value,
			futuresPrice: values.futuresPrice,
			hedgeAccountId: values.hedgeAccount?.value,
			comments: values.comments,
		};

		dispatch(actions.editExternalFill(data));
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			children: translate(domain.cancelBtn),
			className: 'order-form__new-order',
			dataTestId: 'cancel-button',
			disabled: isLoading,
			htmlType: 'button',
			key: 'cancel',
			onClick: handleClose,
			type: 'default',
		},
		{
			children: translate(domain.editBtn),
			disabled: isLoading,
			htmlType: 'submit',
			key: 'edit-external-fill',
		},
	];

	return (
		<GenericForm.ModalContainer
			closable={false}
			key="editExternalFillModal"
			title={translate(domain.editExternalFillTitle)}
		>
			<GenericForm.Form
				buttonsDefinition={btnsDefinition}
				className="order-form"
				formInstance={formInstance}
				key="editExternalFillForm"
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
			>
				{elements}
			</GenericForm.Form>
		</GenericForm.ModalContainer>
	);
});
