/**
 *
 * ListWrapper
 *
 */
import './style.scss';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Card, Empty, List, Popover, Spin, Typography } from 'antd';
import classnames from 'classnames';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ContractTypePill } from 'app/components/ContractTypePill';
import { GenericIcon } from 'app/components/GenericIcon';
import { translations } from 'locales/i18n';
import { dateFormat, timeFormat } from 'utils/helpers';

import { TransactionsTabs } from '../../types';

const { Paragraph } = Typography;

interface Props {
	list: any[];
	listLoading: boolean;
	listLoaded?: boolean;
	activeItem: any;
	loadItemSummary: (string) => void;
	setNextStartItemList?: () => void;
	loadItemList: (boolean) => void;
	transactionType?: string;
	pagination?: any;
}

export const ListWrapper = memo((props: Props) => {
	const {
		list,
		listLoading,
		activeItem,
		loadItemSummary,
		transactionType,
		pagination,
	} = props;

	const { t: translate } = useTranslation();

	const scrollToItem = (itemKey) => {
		const element_to_scroll_to = document.querySelectorAll(
			`#item-${itemKey}`,
		)[0];
		if (element_to_scroll_to) {
			element_to_scroll_to.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}
	};

	const getDescription = (item) => {
		if (
			transactionType === TransactionsTabs.reviewAndRelease ||
			transactionType === TransactionsTabs.preHedge
		) {
			return (
				<>
					<Popover
						content={
							<div className="popover--content">
								<strong>
									{translate(
										translations.app.containers.ReviewAndRelease.Details.status,
									)}
									:{' '}
								</strong>
								{item.status}
							</div>
						}
						trigger="hover"
					>
						<div className="card-left">
							<p className="text text--bold text--large">
								{dateFormat(item.creationDate)}
							</p>
							<p className="text text--label hour">
								{timeFormat(item.creationDate)}
							</p>
						</div>
					</Popover>

					<div className="card-right">
						<div className="contract-info review-release">
							<span className="text text--large text--bold contract-number">
								{item.orderNumber || '- - - -'}
							</span>
						</div>
					</div>
				</>
			);
		}

		if (transactionType === 'offer' || transactionType === 'contract') {
			return (
				<>
					<div className="card-left">
						<p className="text text--bold text--large">
							{dateFormat(item.creationDate)}
						</p>
						<p className="text text--label hour">
							{timeFormat(item.creationDate)}
						</p>
					</div>
					<div className="card-right">
						<div className="contract-info">
							<span className="text text--large text--bold contract-number">
								{item.contract.erpError ? (
									<GenericIcon
										hasTooltip
										icon={<ExclamationCircleOutlined />}
										iconType="danger"
										tooltipText={translate(
											translations.app.containers.Transactions.erpWarning,
										)}
									/>
								) : (
									item.contract.number || '---'
								)}
							</span>
							<ContractTypePill
								contractType={item.contract.type}
								isSell={item.isSell}
								style={{ marginBottom: 6 }}
							/>
						</div>

						<Paragraph
							className="text text--bold text--large ellipsis customer"
							title={item.customer.name}
							ellipsis={{ rows: 1 }}
						>
							{item.customer.name}
						</Paragraph>
					</div>
				</>
			);
		}
	};

	const handleCardClick = (item) => {
		loadItemSummary(item);
		scrollToItem(item.id);
	};

	const getBorderColor = (status) =>
		transactionType !== 'reviewAndRelease' &&
		`border-left--${status?.trim().split(' ').join('-').toLowerCase()}`;

	useEffect(() => {
		if (activeItem.data) {
			scrollToItem(activeItem.data.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeItem.data]);

	useEffect(() => {
		if (activeItem.data === null && !activeItem.loading && list.length > 0) {
			loadItemSummary(list[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [list]);
	return (
		<div
			className={classnames('list-wrapper-container', {
				'no-data': list && !list.length,
				pagination: transactionType === 'reviewAndRelease',
			})}
		>
			{!!list && list.length ? (
				<>
					<List
						className="list-wrapper"
						dataSource={list}
						loading={listLoading}
						renderItem={(item) => (
							<Card
								id={`item-${item.id}`}
								data-testid={`item-${item.id}`}
								bordered={true}
								className={classnames(getBorderColor(item.status), {
									orphan: item.isOrphan,
									active: activeItem?.data?.id === item.id,
									offer: !!transactionType && transactionType === 'offer',
									'review-release':
										!!transactionType && transactionType === 'reviewAndRelease',
								})}
								onClick={() => {
									handleCardClick(item);
								}}
							>
								<List.Item>
									<List.Item.Meta description={getDescription(item)} />
								</List.Item>
							</Card>
						)}
						pagination={pagination || false}
						size="small"
					/>
				</>
			) : listLoading ? (
				<Spin data-testid="list-wrapper-spinner" />
			) : (
				<Empty
					data-testid="list-wrapper-empty"
					description={translate(
						translations.app.components.FilterEmpty.noresultsearch,
					)}
				/>
			)}
		</div>
	);
});
