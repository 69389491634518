import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectActivePreHedge } from 'app/containers/PreHedge/selectors';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { ExpirationDateGTC } from 'app/containers/Transactions/components/ExpirationDateGTC';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { HedgeAccount } from 'app/containers/Transactions/components/HedgeAccount';
import { NumberOfLots } from 'app/containers/Transactions/components/NumberOfLots';
import { OrderPrice } from 'app/containers/Transactions/components/OrderPrice';
import { PreHedgeOrderType } from 'app/containers/Transactions/components/PreHedgeOrderType';
import { UnfilledLots } from 'app/containers/Transactions/components/UnfilledLots';
import { PRE_HEDGE_ORDER_TYPES } from 'utils/constants';
import { getActionType } from 'utils/helpers';
import Yup from 'yupCustom';

import { useTranslations } from '../../../shared/useTranslations';

export const useLimitOrderSchema = () => {
	const translations = useTranslations();

	const preHedgeData = useSelector(selectActivePreHedge);

	const { required: requiredMsg, number: numberMsg } = translations.validations;

	return {
		initialValues: {
			preHedgeOrderType: {
				value:
					preHedgeData?.type === 'Limit'
						? PRE_HEDGE_ORDER_TYPES.limitOrder
						: PRE_HEDGE_ORDER_TYPES.marketOrder,
			},
			action: getActionType(preHedgeData?.isSell),
			commodity: { value: preHedgeData?.commodityId },
			cropYear: preHedgeData?.cropYear,
			futuresMonth: { value: preHedgeData?.futuresMonth },
			numberOfLots: preHedgeData?.lots,
			orderPrice: preHedgeData?.futuresPrice,
			unfilledLots: preHedgeData?.unfilledLots,
			gtcMode: preHedgeData?.gtc,
			expirationDateGTC: preHedgeData?.expiration
				? moment(preHedgeData?.expiration)
				: null,
			comments: preHedgeData?.comments,
			hedgeAccount: {
				value: preHedgeData?.futuresAccountName,
				id: preHedgeData?.futuresAccountId,
			},
		},
		validationSchema: Yup.object().shape({
			preHedgeOrderType: Yup.object().requiredOption(requiredMsg),
			action: Yup.string().required(requiredMsg),
			commodity: Yup.object().requiredOption(requiredMsg),
			numberOfLots: Yup.string().typeError(numberMsg).required(requiredMsg),
			orderPrice: Yup.string().typeError(numberMsg).required(requiredMsg),
			expirationDateGTC: Yup.string()
				.nullable()
				.when('gtcMode', {
					is: false,
					then: Yup.string().required(requiredMsg),
					otherwise: Yup.string().nullable(),
				}),
			futuresMonth: Yup.object().requiredOption(requiredMsg),
			hedgeAccount: Yup.object().requiredOption(requiredMsg),
		}),
		elements: [
			<PreHedgeOrderType disabled />,
			<Commodity disabled reloadFuturesMonth checkDefaultValues />,
			<CropYears checkDefaultValues disabled />,
			<Futures
				checkDefaultValues
				futuresMonth={{
					market: true,
					disableWithinDeliveryMode: true,
					disabled: true,
				}}
			/>,
			<NumberOfLots disabled />,
			<OrderPrice />,
			<UnfilledLots />,
			<HedgeAccount disabled />,
			<ExpirationDateGTC disabled />,
		].concat([<Comments />]),
	};
};
