import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { fileRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import { FileUploadPayload } from '../types';

const {
	downloadExcel,
	uploadExcelForCreateCommodity,
	uploadExcelForEditCommodity,
	downloadUpdateHedgeMapTemplate,
} = apiEndpoints;

export function* downloadFilledUpdateHedgemapRequest(
	action: PayloadAction<any>,
) {
	const params = action.payload;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${downloadUpdateHedgeMapTemplate}`,
	);

	Object.entries(params).forEach(([key, value]) => {
		requestURL.searchParams.append(key, value);
	});

	const { responseData, responseError }: any = yield call(
		fileRequest,
		requestURL,
		httpMethod.Get,
		true,
	);

	yield put(actions.clearHedgeMapTemplate());
	// Success hedgeMappingActions
	if (!!responseData) {
		yield put(
			actions.filledHedgeMapTemplateLoaded({
				...params,
				url: responseData,
			}),
		);
	} else if (!!responseError.detail) {
		yield put(actions.filledHedgeMapTemplateError(responseError));
	}
}

export function* downloadEmptyHedgeMapTemplateRequest(
	action: PayloadAction<any>,
) {
	const params = action.payload;
	const requestURL = yield new URL(`${apiRoutes.base}/${downloadExcel}`);
	Object.entries(params).forEach(([key, value]) => {
		requestURL.searchParams.append(key, value);
	});

	const { responseData, responseError }: any = yield call(
		fileRequest,
		requestURL,
		httpMethod.Get,
		true,
	);

	// Success actions
	if (!!responseData) {
		yield put(
			actions.hedgeMapTemplateLoaded({
				...params,
				url: responseData,
			}),
		);
	} else if (!!responseError.detail) {
		yield put(actions.hedgeMapTemplateError(responseError));
	}
}

/**
 * @internal exported for automated tests
 */
export function* uploadHedgeMapTemplate(
	action: PayloadAction<FileUploadPayload>,
	path: string,
) {
	const requestURL = yield new URL(`${apiRoutes.base}/${path}`);

	const { data, config } = action.payload;

	const { responseData, responseError }: any = yield call(
		fileRequest,
		requestURL,
		httpMethod.Post,
		true,
		data,
		config,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.hedgeMapTemplateUploaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.clearHedgeMapTemplate());
		yield put(actions.hedgeMapTemplateError(responseError));
	}
}

export function* uploadCreateHedgeMapTemplate(
	action: PayloadAction<FileUploadPayload>,
) {
	yield call(uploadHedgeMapTemplate, action, uploadExcelForCreateCommodity);
}

export function* uploadEditHedgeMapTemplate(
	action: PayloadAction<FileUploadPayload>,
) {
	yield call(uploadHedgeMapTemplate, action, uploadExcelForEditCommodity);
}
