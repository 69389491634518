import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { hedgeAccount } = apiEndpoints;

export function* getHedgeAccountList() {
	const requestURL = yield new URL(`${apiRoutes.base}/${hedgeAccount}`);
	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.hedgeAccountListLoaded(responseData.hedgeAccounts));
	} else if (!!responseError.detail) {
		yield put(actions.hedgeAccountError(responseError));
	}
}
