/**
 *
 * GenericTable
 *
 */
import { Table as TableAntd } from 'antd';
import { TableProps } from 'antd/lib/table';
import classNames from 'classnames';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterEmpty } from 'app/components/FilterEmpty';
import { VirtualScroll } from 'app/components/VirtualScroll';
import { translations } from 'locales/i18n';

import { ResizeObserverWrapper } from '../ResizeObserverWrapper';

// Scroll property is managed by the component
interface Props extends Omit<TableProps<any>, 'scroll' | 'dataSource'> {
	rowKey?: any;
	otherClassName?: string;
	selectedFilters?: object;
	emptyText?: string;
	data: any[];
}

export const VirtualTable = memo((props: Props) => {
	const {
		loading,
		pagination,
		emptyText,
		selectedFilters,
		data = [],
		className,
		locale,
		rowKey = 'id',
	} = props;

	const { t: translate } = useTranslation();

	// this will be set by ResizeObserverWrapper, but having a reasonable default helps prevent loading jank
	const [_scrollbar, setScrollBar] = useState<number>(window.innerHeight - 250);
	const scrollbar = loading ? undefined : _scrollbar;

	const [virtualData, setVirtualData] = useState<any>([]);

	const getEmptyMessage = () => {
		if (!loading && selectedFilters) {
			if (Object.values(selectedFilters).some((filter) => !!filter)) {
				return <FilterEmpty description={emptyText} />;
			}
			return false;
		}
	};

	const handleVirtualScroll = (data) => {
		setVirtualData(data);
	};

	const emptyPlaceholder = () => {
		if (
			locale?.emptyText &&
			!(data?.length > 1) &&
			!(virtualData?.length > 1) &&
			!loading
		)
			return locale;
		if (!(data?.length > 1) || !(virtualData?.length > 1))
			return { emptyText: getEmptyMessage() };
		return undefined;
	};

	const handleRefChange = useCallback((scrollHeight) => {
		if (!isNaN(scrollHeight) && scrollHeight > 0) {
			setScrollBar(scrollHeight);
		}
	}, []);

	return (
		<ResizeObserverWrapper onRefChange={handleRefChange}>
			<VirtualScroll
				onEndOfList={handleVirtualScroll}
				list={data}
				sliceUpdate={virtualData.length}
			>
				<TableAntd
					{...props}
					pagination={
						pagination && {
							...pagination,
							showTotal: (total, range) =>
								translate(translations.app.components.GenericTable.showTotal, {
									range1: range[0],
									range2: range[1],
									total: total,
								}),
							showSizeChanger: true,
							role: 'table-pagination',
						}
					}
					rowKey={rowKey}
					locale={emptyPlaceholder()}
					size="small"
					data-testid="generic-table"
					scroll={{
						x: 'max-content',
						y: scrollbar,
					}}
					dataSource={virtualData}
					className={classNames('virtual-table__wrapper', className)}
				/>
			</VirtualScroll>
		</ResizeObserverWrapper>
	);
});
