import './style.scss';

import { CloseOutlined } from '@ant-design/icons';
import React, { memo } from 'react';

import { ConfirmDialog } from 'app/components/ConfirmDialog';

interface Props {
	title: string;
	content: string;
	confirmText: string;
	cancelText: string;
	handleConfirm: () => void;
}

export const ModalHeader = memo((props: Props) => {
	const { title, content, confirmText, cancelText, handleConfirm } = props;

	return (
		<>
			<div className="title">{title}</div>
			<ConfirmDialog
				placement="bottomRight"
				data-testid="modal-header"
				message={content}
				handleConfirm={handleConfirm}
				confirmText={confirmText}
				cancelText={cancelText}
				trigger={<CloseOutlined />}
			/>
		</>
	);
});
