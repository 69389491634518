import { takeLatest } from 'redux-saga/effects';

import { createLocation } from './sagas/create-location-saga';
import { editLocation, editLocationManually } from './sagas/edit-location-saga';
import {
	getLocationById,
	getLocationsList,
	getSearchLocationsListForContractLocation,
	getSearchLocationsListForDestinationLocation,
} from './sagas/locations-saga';
import { toggleStatus } from './sagas/toggle-status-saga';
import { actions } from './slice';

export function* locationsSaga() {
	yield takeLatest(actions.loadLocationsList.type, getLocationsList);
	yield takeLatest(actions.toggleStatus.type, toggleStatus);
	yield takeLatest(actions.createLocation.type, createLocation);
	yield takeLatest(actions.editLocation.type, editLocation);
	yield takeLatest(actions.editLocationManually.type, editLocationManually);
	yield takeLatest(actions.setCurrentLocation.type, getLocationById);
	yield takeLatest(
		actions.loadSearchLocationsListForContractLocation.type,
		getSearchLocationsListForContractLocation,
	);
	yield takeLatest(
		actions.loadSearchLocationsListForDestinationLocation.type,
		getSearchLocationsListForDestinationLocation,
	);
}
