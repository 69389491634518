import { FuturesErp } from 'types/FuturesErp';
import { GenericError } from 'types/GenericError';
import { GenericOption } from 'types/GenericOption';

export interface FuturesErpFilters {
	commodityId?: { value: string; label: string }[];
	futuresMonth?: { value: string; label: string }[];
	endDate?: string | null;
	startDate?: string | null;
	accountNo?: string | null;
	erpStatus?: { value: string; label: string }[];
	orderNo?: string | null;
}

export interface pagination {
	limit?: number;
	start: number;
}

/* --- STATE --- */
export interface FuturesErpState {
	selectedRows: { id: string }[];

	selectedFilters: FuturesErpFilters;

	pagination: pagination;

	futuresErpList: {
		data: FuturesErp[];
		loading: boolean;
		total: number;
		error: GenericError | null;
	};
	integrateToErp: {
		loading: boolean;
		error: GenericError | null;
	};
	futureMonths: {
		data: GenericOption[];
		loading: boolean;
		error: GenericError | null;
	};
}

export enum ErpStates {
	NotSent,
	Pending,
	Fail,
	Success,
	NoErp,
	TimeOut,
}

enum ErpStatusFilter {
	NotIntegrated = 'NotIntegrated',
	Integrated = 'Integrated',
	Processing = 'Processing',
	Failed = 'Failed',
}

export const ErpStatusToFilterKeyMap = {
	[ErpStates.NotSent]: ErpStatusFilter.NotIntegrated,
	[ErpStates.NoErp]: ErpStatusFilter.NotIntegrated,
	[ErpStates.Pending]: ErpStatusFilter.Processing,
	[ErpStates.Success]: ErpStatusFilter.Integrated,
	[ErpStates.Fail]: ErpStatusFilter.Failed,
	[ErpStates.TimeOut]: ErpStatusFilter.Failed,
};

export const ErpStatusFilterMap = {
	[ErpStatusFilter.NotIntegrated]: [ErpStates.NotSent, ErpStates.NoErp],
	[ErpStatusFilter.Integrated]: [ErpStates.Success],
	[ErpStatusFilter.Processing]: [ErpStates.Pending],
	[ErpStatusFilter.Failed]: [ErpStates.Fail, ErpStates.TimeOut],
};
