/**
 *
 * Filter
 *
 */
import './styles.scss';

import { PlusCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row } from 'antd';
import React, { memo } from 'react';

const { Search } = Input;

interface Props {
	handleSearch: (searchText: string) => void;
	handleCreate?: () => void;
	placeholder: string;
}

export const Filter = memo((props: Props) => {
	const { handleSearch, handleCreate, placeholder } = props;

	return (
		<>
			<div className="details-filter__container">
				<Row>
					<Col span={handleCreate ? 10 : 12}>
						<Search
							className="details-filter__input"
							placeholder={placeholder}
							onSearch={handleSearch}
							allowClear={true}
							enterButton={
								<SearchOutlined data-testid="details-filter-search" />
							}
						/>
					</Col>
					{handleCreate && (
						<Col span={2}>
							<PlusCircleFilled
								className="details-filter__order-icon"
								onClick={handleCreate}
								data-testid="details-filter-create"
							/>
						</Col>
					)}
				</Row>
			</div>
		</>
	);
});
