export const apiEndpoints = {
	contractsRoot: 'contracts',
	contractsList: 'contracts/filter',
	contractsDetail: 'contracts/detail',
	contractsChildren: 'contracts/childs',
	contractsCancel: 'contracts/cancel',
	contractSetting: 'contracts/setting',
	contractSettingByRole: 'contracts/settingbyrole',
	applyNameId: 'contracts/applyNameId',
	contractNtcConvert: 'contracts/convert',
	bulkContractNtcConvert: 'contracts/bulk-convert',
	bulkContractNtcUpdate: 'contracts/bulk-edit',
	contractsFuturesMonths: 'contracts/futuresmonths',

	offersRoot: 'offers',
	offersList: 'offers/filter',
	offersDetails: 'offers/detail',
	offersCancel: 'offers/cancel',
	offersCatalogFuturesMonths: 'offers/futuresmonths',
	offersOrphans: 'offers/orphans',
	rollOrphans: 'offers/rollorphans',
	offersTotalOrphans: 'offers/orphanscount',
	offersBook: 'offers/book',
	offersMultipleBook: 'offers/bookoffers',
	offersComplete: 'offers/complete/',

	futuresMonthOptions: 'futures/months',
	futuresSpread: 'futures/spread',

	contractsRoll: 'contracts/roll',
	contractsPrice: 'contracts/price',
	contractsPriceViaOffer: 'contracts/priceviaoffer',
	contractsPriceViaEfp: 'contracts/priceviaefp',
	contractsResendErp: 'contracts/ResentToERP',

	postedBasisPrice: 'bidsheets/postedbasis',
	deliveryDates: 'bidsheets/deliverydates',
	futuresMonth: 'bidsheets/futuresmonth',
	futuresMonthOffers: 'bidsheets/offer/futuresmonth',
	bidsheetUpload: 'bidsheets/uploadexcel',
	missingBids: 'bidsheets/missingbids',
	availableBids: 'bidsheets/availablebids',
	bidsheetList: 'bidsheets/review',
	bidsheetPublish: 'bidsheets/publish',
	bidsheetListFilter: 'bidsheets/filter',
	bidsheetAddBid: 'bidsheets/addbid',
	bidsheetDeleteBidRow: 'bidsheets/delete',
	bidsheetUpdateBid: 'bidsheets/updatebid',
	bidsheetExport: 'bidsheets/export',

	reportExecutionRoot: 'reportexecution',
	reportMetadata: 'reportexecution/metadata',
	reportExecute: 'reportexecution/execute',
	reportExcel: 'reportexecution/export/excel',

	commoditiesRoot: 'commodities',
	commoditiesActivate: 'commodities/activate',
	commoditiesDeactivate: 'commodities/deactivate',
	commoditiesDNH: 'commodities/dnh',
	commoditiesDNHList: 'commodities/dnhlist',
	commoditiesFilter: 'commodities/filter',

	hedgeMapRoot: 'hedgemap',
	downloadExcel: 'hedgemap/downloadexcel',
	uploadExcelForHedgeMapAdmin: 'hedgemap/UploadExcelForHedgeMapAdmin',
	uploadExcelForCreateCommodity: 'hedgemap/UploadExcelForCreateCommodity',
	uploadExcelForEditCommodity: 'hedgemap/UploadExcelForEditCommodity',
	submitHedgeMap: 'hedgemap/UpdateHedgeMapping',
	downloadUpdateHedgeMapTemplate: 'hedgemap/downloadfilledupdatedhedgemapexcel',

	products: 'product',

	transactionsRoot: 'transactiontypes',

	contractTypesRoot: 'contracttypes',

	cropsRoot: 'cropyear',

	legacyLocationsRoot: 'locations/legacy',
	legacyLocationsFilter: 'locations/legacy/filter',
	legacyLocationsActivate: 'locations/legacy/activate',
	legacyLocationsDeactivate: 'locations/legacy/deactivate',

	locationsRoot: 'locations',
	locationsManual: 'locations/manual',
	locationsFilter: 'locations/filter',
	locationsSearch: 'locations/searchwithnameornumber',
	locationsActivate: 'locations/activate',
	locationsDeactivate: 'locations/deactivate',
	locationsSettings: 'locations/setting',
	locationsSettinByRole: 'locations/settingbyrole',
	groupedLocations: 'locations/groupedlocation',

	updateLocationsSettingByEmployee: 'locations/settinglocationemployee',
	updateLegacyLocationsSettingByEmployee:
		'locations/legacy/settinglocationemployee',
	getLocationsSettingByEmployee: 'locations/settingbyemployee',
	getLegacyLocationsSettingByEmployee: 'locations/legacy/settingbyemployee',

	roundingTypesRoot: 'roundingTypes',

	roundingRulesRoot: 'roundingRules',
	roundingRulesActivate: 'roundingRules/activate',
	roundingRulesDeactivate: 'roundingRules/deactivate',
	roundingRulesSettings: 'roundingRules/setting',
	roundingRulesSettinByRole: 'roundingRules/settingbyrole',

	customersRoot: 'customer',
	getCountries: 'customer/getcountries',
	getStates: 'customer/getStates',
	customersFilter: 'customer/filter',
	customerDeactivate: 'customer/deactivate',
	customerActivate: 'customer/activate',

	serviceFeeRoot: 'servicefee',
	serviceFeeDeactivate: 'servicefee/deactivate',
	serviceFeeActivate: 'servicefee/activate',
	serviceFeeMonths: 'serviceFeeMonths',
	serviceFeeTypes: 'types',

	futuresPrice: 'futures/futuresprice',

	dailyReport: 'dailyreport',

	employeeRoot: 'employees',
	employeesFilter: 'employees/filter',
	employeesRoles: 'employees/getroles',
	employeeDeactivate: 'employees/deactivate',
	employeeActivate: 'employees/activate',

	eventRoot: 'events',

	liveLedgerRoot: 'liveledger',
	liveLedgerExportExcel: 'liveledger/export/excel',

	longAndShortRoot: 'dashboard/longandshortsummary',
	longAndShortRootRecap: 'dashboard/longandshortrecap',

	reviewAndReleaseFilter: 'reviewandrelease/filter',
	reviewAndReleaseStates: 'reviewandrelease/status',
	reviewAndReleaseApprove: 'reviewandrelease/approve',
	reviewAndReleaseDeny: 'reviewandrelease/deny',
	reviewAndReleaseDetail: 'reviewandrelease/detail',
	reviewAndRealeaseExternalFill: 'reviewandrelease/externalfill',
	reviewAndReleaseResend: 'reviewandrelease/resend',
	reviewAndReleaseExternalFill: 'ReviewAndRelease/externalfill',
	reviewAndReleaseCancel: 'ReviewAndRelease/cancel',
	reviewAndReleaseExport: 'ReviewAndRelease/export',
	reviewAndReleaseFuturesMonths: 'reviewandrelease/futuresmonths',

	rolesRoot: 'roles',
	roleClaims: 'roleclaims/ByName',

	userClaims: 'userclaims',
	userClaimsByEmail: 'userclaims/byemail',

	reviewAndReleaseAcknowledge: 'reviewandrelease/acknowledge',
	reviewAndReleaseAcknowledgeAndFill: 'reviewandrelease/acknowledgeandfill',
	reviewAndReleaseOrderStatus: 'reviewandrelease/status/transaction',

	hedgeAccount: 'hedgeaccounts',

	bypassReviewTag50EmployeesList: 'employees/tag50',
	bypassReviewTag50Settings: 'settings/tag50',

	defaultPageSettings: 'settings/userdefaultvalues',
	updateEmployeeLimit: 'settings/updateuserlimit',
	getEmployeeLimit: 'settings/getuserlimit',

	updateBrokerMapping: 'brokermapping/UpdateBrokerMapping',
	getBrokerMapping: 'brokermapping',
	createBrokerMapping: 'brokermapping/CreateBrokerMapping',

	erpIntegration: 'settings/erpintegration',
	erphedgeintegration: 'settings/erphedgeintegration',
	erpTypes: 'settings/erptypes',

	orderEntry: 'ordermetadataconfigurations',
	ntcAdmin: 'contracttypes/ntc-admin',

	// Cashbid and future quotes
	futureQuote: 'futures/frontmonthfutureprices',
	cashbids: 'bidsdata/hrvystfilter',

	// Notification settings
	tenantNotificationSettings: 'notifications/tenantsettings',
	userNotificationSubscriptions: 'notifications/usersubscriptions',
	notificationAlertTones: 'alertsounds',
	employeeNotificationSettings: 'notifications/usersubscriptions/employee',
	employeeGroups: 'notifications/employeegroups/employee',
	bidSentNotify: 'bidsheets/notifymanually',

	// Merged notification groups - employees and customers
	notificationGroups: 'notifications/allgroups',

	// Notification Employee Groups
	employeeGroupNotificationSettings: 'notifications/employeegroups',
	employeeGroupAllNonMembers: 'notifications/employeegroups/all-non-members',

	// Notification Customer Groups
	customerGroupNotificationSettings: 'notifications/customergroups',
	customerGroupAllNonMembers: 'notifications/customergroups/all-non-members',

	// Bell icon Notifications
	markNotificationsAsSeen: 'notifications/markasseen',
	dismissNotification: 'notifications/dismiss',
	dismissAllNotifications: 'notifications/dismiss/all',
	notificationsHistory: 'notifications/history',
	sendCustomNotification: 'notifications/custom',

	preHedgeRoot: 'prehedge',
	preHedgeCreate: 'prehedge/create',
	preHedgeFilter: 'prehedge/filter',
	preHedgeEdit: 'prehedge/edit',
	preHedgeCancel: 'prehedge/cancel',
	preHedgeExport: 'prehedge/export',
	preHedgeFuturesMonths: 'prehedge/futuresmonths',
	preHedgeStates: 'prehedge/status',

	futuresErp: 'futures/erp/filter',
	futuresErpIntegrate: 'futures/erp',
	erpTradeBook: 'erp/tradebook/filter',
	erpBookMatch: 'erp/match',

	// Regions
	regions: 'region',
	regionsForEmployeeActivePermissions: 'region/EmployeeActivePermissions',
};
