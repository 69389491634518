import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import { hedgeIntegrationPayload } from '../types';

const { erpIntegration, erphedgeintegration } = apiEndpoints;

export function* getERPIntegrationSaga() {
	const requestURL = yield new URL(`${apiRoutes.base}/${erpIntegration}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
		true,
	);

	if (!!responseData) {
		yield put(actions.getERPIntegrationSuccess(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.getERPIntegrationError(responseError));
	}
}

export function* getHedgeIntegrationSaga() {
	const requestURL = yield new URL(`${apiRoutes.base}/${erphedgeintegration}`);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
		true,
	);

	if (!!responseData) {
		yield put(actions.getHedgeIntegrationSuccess(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.getHedgeIntegrationError(responseError));
	}
}

export function* setERPIntegrationSaga(action: PayloadAction<any>) {
	const requestURL = yield new URL(`${apiRoutes.base}/${erpIntegration}`);

	const erpIntegrationData = action?.payload?.data;

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		erpIntegrationData,
		true,
	);

	if (!!responseData) {
		yield put(actions.setERPIntegrationSuccess(responseData));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(actions.getERPIntegrationRequest());
	} else if (!!responseError?.detail) {
		yield put(actions.setERPIntegrationError(responseError));
	}
}
export function* setHedgeIntegrationSaga(
	action: PayloadAction<hedgeIntegrationPayload>,
) {
	const requestURL = yield new URL(`${apiRoutes.base}/${erphedgeintegration}`);

	const erpIntegrationData = action?.payload?.data;

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		erpIntegrationData,
		true,
	);

	if (!!responseData) {
		yield put(actions.setHedgeIntegrationSuccess(responseData));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(actions.getHedgeIntegrationRequest());
	} else if (!!responseError?.detail) {
		yield put(actions.setHedgeIntegrationError(responseError));
	}
}
