/**
 *
 * Accordion
 *
 */
import './style.scss';

import React from 'react';

import { GenericOption } from 'types/GenericOption';

interface Props {
	sectionsList: GenericOption[];
	elements: JSX.Element[];
	colSpan: any;
}

export function MultiColumnGrid(props: Props) {
	const { sectionsList, elements } = props;

	return (
		<>
			<div className="generic-view-accordion__grid">
				{elements.map(
					(element) =>
						element.props.section === sectionsList[0].value && <>{element}</>,
				)}
			</div>
		</>
	);
}
