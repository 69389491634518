import './style.scss';

import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { GenericTable } from 'app/components/GenericTable';

import {
	selectContractsChildren,
	selectContractsChildrenLoading,
} from '../../../../../selectors';

interface Props {
	columns: Array<Object>;
}

export const NestedTable = ({ columns }: Props) => {
	const listLoading = useSelector(selectContractsChildrenLoading);
	const list: any = useSelector(selectContractsChildren);

	const onRow = (record) => {
		return {
			className: classNames(`border-left--${record.status.toLowerCase()}`, {}),
		};
	};

	return (
		<GenericTable.Table
			className={classNames('table-wrapper table-wrapper--nested', {
				'table-wrapper--nested-no-border': !list?.length,
			})}
			columns={list?.length ? columns : []}
			data={list}
			rowKey={(record) =>
				`${record?.id}|${record?.parent?.id}|${record?.creationDate ?? record?.createdOn}`
			}
			pagination={false}
			onRow={onRow}
			loading={listLoading}
		/>
	);
};
