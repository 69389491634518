import { ActionType } from 'types/ActionType';
import { FixedColumn } from 'types/FixedColumn';
import { GenericError } from 'types/GenericError';
import { GenericOption } from 'types/GenericOption';
import { IGenericPagination } from 'types/GenericPagination';
import { CreatePreHedgeData } from 'types/PreHedge';

export enum Modal {
	Create = 'Create',
	Edit = 'Edit',
	Cancel = 'Cancel',
	ContractType = 'ContractType',
}

export interface PreHedgeFilters {
	accountName?: string | null;
	endDate?: string | null;
	futuresMonth?: GenericOption[];
	status?: GenericOption[];
	startDate?: string | null;
	number?: string | null;
	symbol?: GenericOption[];
	sortColumnName?: string | null;
	contractOrCustomer?: string;
}

export enum PreHedgeViews {
	table = 'table',
	details = 'details',
}

export interface ExportExcelPayload {
	successMsg: string;
	errorMsg: string;
}

export type ContainerState = PreHedgeState;

export interface PreHedgeListLoadedPayload {
	list: PreHedgeRecord[];
	total: number;
	totalOrphanedBalance: buyAndSell;
}
export interface CreatePreHedgePayload {
	data: PreHedgeRequestBody;
	closeAfterSubmit: boolean;
	successMessage: string;
}

export type UpdatePreHedgePayload = Omit<
	CreatePreHedgePayload,
	'closeAfterSubmit'
>;

export interface PaginationPayload {
	limit?: number;
	start?: number;
}

interface buyAndSell {
	buy: string | null;
	sell: string | null;
}
export interface ContractDetails {
	prehedgeId?: string | null | undefined;
	action: ActionType;
	commodity: { value: string | null | undefined };
	contract: { label: string; value: string; type: string };
	cropYear: string | null | undefined;
	futuresMonth: { value: string | null | undefined };
	location: { value: null };
	deliveryLocation: { value: null };
	transaction: { label: string; value: string; type: undefined };
}

export interface PreHedgeState {
	fixedColumns: PreHedgeFixedColumns;
	success: boolean | null;

	exportPreHedge: {
		url: string | null;
		fileName: string | null;
		loading: boolean;
		error: GenericError | null;
	};

	ContractDetails: ContractDetails | { prehedgeId?: string | null };

	activePreHedgeDetails: {
		data: CreatePreHedgeData | null;
		loading: boolean;
		error: GenericError | null;
	};

	activePreHedge: {
		data: CreatePreHedgeData | null;
		loading: boolean;
		error: GenericError | null;
	};

	currentModal: Modal | null;

	currentView: PreHedgeViews;

	list: {
		totalOrphanedBalance: buyAndSell;
		data: PreHedgeRecord[];
		loading: boolean;
		total: number;
		allLoaded: boolean;
		error: GenericError | null;
	};

	pagination: IGenericPagination;

	orderStatus: {
		data: string | boolean;
		loading: boolean;
		error: GenericError | null;
		isStreaming: boolean;
	};

	selectedFilters: PreHedgeFilters;

	selectedRows: CreatePreHedgeData[];

	futureMonths: {
		data: GenericOption[];
		loading: boolean;
		error: GenericError | null;
	};
}

export type PreHedgeFixedColumns = PreHedgeColumns<FixedColumn>;

interface PreHedgeColumns<T> {
	created: T;
	updateDate: T;
	status: T;
	account: T;
	orderNumber: T;
	sellBuy: T;
	source: T;
	symbol: T;
	futuresMonth: T;
	type: T;
	gtc: T;
	price: T;
	avgFillPrice: T;
	quantity: T;
	workingQty: T;
	dots: T;
}

export interface PreHedgeStatusUpdate extends Pick<PreHedgeRecord, 'id'> {}

interface PreHedgeRecord {
	id: string;
	orderNumber: string;
	type: string;
	typeName: string;
	creationDate: string;
	updateDate: string | null;
	price: number | null;
	isSell: boolean;
	side: string;
	quantity: number;
	workingQuantity: number;
	status: string;
	accountNumber: number;
	accountName: string;
	avgFillPrice: number;
	gtc: {
		isGTC: boolean;
		expiration: string | null;
	};
	futuresMonth: string;
	source: string;
	symbol: {
		code: string;
		name: string | null;
		instrument: string | null;
	};
	restrictions: PreHedgeRestrictions;
	lots: number;
}

interface PreHedgeRestrictions {
	canBeCancelled: boolean;
	canCreateContract: boolean;
	canBeEdited: boolean;
}

export interface PreHedgeRequestBody {
	id?: string;
	isMarketOrder: boolean;
	isSell: boolean;
	commodityId: string;
	cropYear: number;
	futuresMonth: string;
	lots: number;
	openPrice?: number;
	futuresAccountId: string;
	gtc: boolean;
	expiration: string | null;
	comments: string;
	unfilledLots?: number | null;
}

export interface EditPrehedgeRequestBody extends PreHedgeRequestBody {
	futuresPrice: number;
}

export interface PreHedgeDataPayload {
	accountName?: null | string;
	customerId: null | string | undefined;
	endDate?: null | string;
	futuresMonth: null | string[];
	limit: number;
	number?: null | string;
	start: number;
	startDate?: null | string;
	status: null | string[];
	symbol?: null | string;
}
