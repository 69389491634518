/**
 *
 * CropYears
 *
 */
import React, { memo, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { RadioButtons } from 'app/components/RadiosButtons';
import { selectCommoditiesList } from 'app/containers/GlobalSaga/selectors';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { translations } from 'locales/i18n';
import { getCropYearOptions } from 'utils/helpers';

import { SourceContext } from '../..';
import { useDefaultValues } from '../../hooks/useDefaultValues';
import { useHandleBusinessRules } from '../hooks/useHandleBusinessRules';

interface Props {
	disabled?: boolean;
	disabledMode?: string;
	required?: boolean;
	onChange?: () => {};
	source?: CalculatedFieldsSource;
	checkDefaultValues?: boolean;
}

export const CropYears = memo((props: Props) => {
	const {
		disabled,
		disabledMode,
		onChange = handleChange,
		source,
		checkDefaultValues = false,
	} = props;

	const sourceName = useContext(SourceContext);
	const { setValue, watch } = useFormContext();

	const commodity = watch('commodity');

	const defaultValues = useDefaultValues();

	const { t: translate } = useTranslation();

	const msg = translations.app.containers.Transactions.components.CropYears;

	const commoditiesList = useSelector(selectCommoditiesList);

	const { getDeliveryDates } = useHandleBusinessRules(sourceName);

	useEffect(() => {
		const options = getCropYearOptions(commoditiesList, commodity);
		setCropYearOptions(options);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [commodity]);

	useEffect(() => {
		getDeliveryDates(source ?? undefined);
		if (checkDefaultValues) {
			checkDefaultValue();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [cropYearOptions, setCropYearOptions] = useState(() =>
		getCropYearOptions(commoditiesList, commodity),
	);

	function handleChange(currentValue, values) {
		getDeliveryDates(source ?? undefined);
	}
	const checkDefaultValue = () => {
		const { cropYear } = defaultValues;
		const isValid = cropYearOptions.find((option) => option.value === cropYear);

		if (!isValid && cropYear) {
			setValue('cropYear', null);
		}
	};
	return (
		<GenericForm.FormItem
			label={translate(msg.label)}
			name="cropYear"
			onChange={onChange}
			data-testid="cropYear-form-item"
		>
			<RadioButtons
				key="cropYear"
				options={cropYearOptions}
				disabled={disabled}
				disabledMode={disabledMode}
			/>
		</GenericForm.FormItem>
	);
});
