import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { contractsFuturesMonths } = apiEndpoints;

export function* getContractsFuturesMonthsList(type) {
	const { source } = type.payload;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${contractsFuturesMonths}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		const data = responseData.map((item) => ({ id: item.name, ...item }));
		yield put(actions.contractsFuturesMonthsListLoaded({ source, data }));
	} else if (!!responseError) {
		yield put(actions.contractsFuturesMonthsListError(responseError));
	}
}
