/**
 *
 * LongShortSummary
 *
 */
import './style.scss';

import { Table } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FilterDate } from 'app/components/FilterDate';
import { FilterSingle } from 'app/components/FilterSingle';
import {
	selectCommoditiesList,
	selectCropsList,
} from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';
import { dateMoment, decimalSignFormat, mapToLabelValue } from 'utils/helpers';

import { selectLongShortReport, selectSelectedFilters } from '../../selectors';
import { actions } from '../../slice';

const Item = (props) => <div className="item-wrapper">{props.children}</div>;

export const LongShortSummary = () => {
	const commodities = useSelector(selectCommoditiesList);

	const selectedFilters = useSelector(selectSelectedFilters);
	const longShortReportData = useSelector(selectLongShortReport);
	const commoditiesOptions = mapToLabelValue(commodities);
	const cropsList = useSelector(selectCropsList);
	let cropsListOptions = cropsList.map((year) => {
		return { label: year.toString(), value: year.toString() };
	});
	cropsListOptions.unshift({ label: 'All Crop Years', value: 'All' });
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const translate = useCallback(
		(translateKey) =>
			t(
				translations.app.containers.SummaryDashboard.LongShortSummary[
					translateKey
				],
			),
		[t],
	);

	const columns = [
		{
			title: translate('delivery'),
			dataIndex: 'label',
			width: 150,
			render: (data) => <Item>{data}</Item>,
		},
		{
			title: translate('beginning'),
			dataIndex: 'beginningBalance',
			render: (data) => <Item>{data ? decimalSignFormat(data) : ' '}</Item>,
		},
		{
			title: translate('purchases'),
			dataIndex: 'purchases',
			render: (data) => <Item>{data ? decimalSignFormat(data) : ' '}</Item>,
		},
		{
			title: translate('sales'),
			dataIndex: 'sales',
			render: (data) => <Item>{data ? decimalSignFormat(data) : ' '}</Item>,
		},
		{
			title: translate('netHedged'),
			dataIndex: 'netHedged',
			render: (data) => <Item>{data ? decimalSignFormat(data) : ' '}</Item>,
		},
		{
			title: translate('endBalance'),
			dataIndex: 'endingBalance',
			render: (data) => <>{data ? decimalSignFormat(data) : ' '}</>,
		},
		{
			title: translate('cashMovement'),
			dataIndex: 'cashMovement',
			render: (data) => <>{data ? decimalSignFormat(data) : ' '}</>,
		},
	];

	const handleCommodityChange = (selectedValues) => {
		dispatch(
			actions.setSelectedFilters({
				commodity: selectedValues,
			}),
		);
		dispatch(actions.loadLongShortReport());
	};

	const handleCropYearChange = (selectedValues) => {
		dispatch(
			actions.setSelectedFilters({
				cropYear: selectedValues,
			}),
		);
		dispatch(actions.loadLongShortReport());
	};

	const handleDateChange = (selectedValues) => {
		dispatch(
			actions.setSelectedFilters({
				dateTime: selectedValues,
			}),
		);
		dispatch(actions.loadLongShortReport());
	};

	useEffect(() => {
		dispatch(
			actions.setSelectedFilters({
				commodity: new Array({
					value: commoditiesOptions[0]?.value,
					key: commoditiesOptions[0]?.value,
					label: commoditiesOptions[0]?.label,
				}),
				cropYear: new Array({
					value: cropsListOptions[0].value,
					key: cropsListOptions[0].value,
					label: cropsListOptions[0].label,
				}),
			}),
		);

		dispatch(actions.loadLongShortReport());

		return () => {
			dispatch(actions.resetLongShortReportError());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addKey = (array) =>
		array.map((item, index) => ({ ...item, key: index }));

	return (
		<div className="long-short-summary">
			<div className="filters-container">
				<FilterSingle
					options={commoditiesOptions}
					handleChange={handleCommodityChange}
					currentOption={selectedFilters.commodity}
				/>
				<FilterSingle
					options={cropsListOptions}
					handleChange={handleCropYearChange}
					currentOption={selectedFilters.cropYear}
				/>
				<FilterDate
					name="DashboardDate"
					handleChange={handleDateChange}
					defaultValue={
						!!selectedFilters.dateTime
							? dateMoment(selectedFilters.dateTime)
							: undefined
					}
				/>
			</div>

			<Table
				className="long-short-summary__table"
				columns={columns}
				dataSource={addKey(longShortReportData)}
				pagination={false}
				scroll={{ x: 'max-content', y: 'calc(100vh - 350px)' }}
				onRow={(record) => {
					return {
						className: classNames({ total: record.isTotal }),
					};
				}}
			/>
		</div>
	);
};
