import './styles.scss';

import { Input, Switch } from 'antd';
import React, { memo } from 'react';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { OrderEntry } from 'types/OrderEntry';

import { ORDER_ENTRY_FORM_ITEMS } from '../../types';
import { isMultiSelectType } from '../../utils/helpers';
import { useOrderEntryRowData } from './hooks/useOrderEntryRowData';
import { DOMAIN, isFieldTypeDisabled } from './utils/helpers';

interface Props {
	orderEntry: OrderEntry | undefined;
	index: number;
}

export const OrderEntryRow = memo(({ index, orderEntry }: Props) => {
	const {
		disabledItems,
		fieldType,
		fieldTypes,
		isActive,
		multiSelectInputs,
		multiSelectOptions,
		orderEntryId,
		setDisabledItems,
		setFieldType,
		setMultiSelectLength,
		translate,
	} = useOrderEntryRowData(index, orderEntry);

	const getFormItemId = (key: string) => `${key}-${orderEntryId}`;

	const getTranslation = (key: string, values?: any) =>
		translate(DOMAIN[key], values);

	const handleMultiSelectChange = ({ value }) => {
		setMultiSelectLength(value);
	};

	const handleStatusChange = (checked: boolean) => {
		setDisabledItems(!checked);
	};

	const handleTypeChange = ({ value }) => {
		setFieldType(value);
		setMultiSelectLength(0);
	};

	return (
		<>
			<GenericForm.FormItem name={orderEntryId}>
				<GenericForm.FormItem
					name={getFormItemId(ORDER_ENTRY_FORM_ITEMS.STATUS)}
				>
					<Switch
						data-testid="form-item-status"
						checked={isActive}
						onChange={handleStatusChange}
					/>
				</GenericForm.FormItem>
				<GenericForm.FormItem name={getFormItemId(ORDER_ENTRY_FORM_ITEMS.TYPE)}>
					<Dropdown
						disabled={isFieldTypeDisabled(disabledItems, orderEntry)}
						onChange={handleTypeChange}
						options={fieldTypes}
						placeholder={getTranslation('fieldTypesPlaceholder')}
					/>
				</GenericForm.FormItem>
				<GenericForm.FormItem
					name={getFormItemId(ORDER_ENTRY_FORM_ITEMS.LABEL)}
				>
					<Input
						disabled={disabledItems}
						key={getFormItemId(ORDER_ENTRY_FORM_ITEMS.LABEL)}
						name={getFormItemId(ORDER_ENTRY_FORM_ITEMS.LABEL)}
						placeholder={getTranslation('labelPlaceholder')}
						type="string"
					/>
				</GenericForm.FormItem>
				{isMultiSelectType(fieldType) && (
					<GenericForm.FormItem
						name={getFormItemId(ORDER_ENTRY_FORM_ITEMS.MULTI_SELECT_LENGTH)}
					>
						<Dropdown
							disabled={disabledItems}
							onChange={handleMultiSelectChange}
							options={multiSelectOptions}
							placeholder={getTranslation('multiSelectLengthPlaceholder')}
							showSearch={false}
						/>
					</GenericForm.FormItem>
				)}
			</GenericForm.FormItem>
			{isMultiSelectType(fieldType) && (
				<div className="multi-select-inputs">
					{multiSelectInputs.map((index: number) => (
						<GenericForm.FormItem
							name={getFormItemId(
								`${ORDER_ENTRY_FORM_ITEMS.MULTI_SELECT_INPUT}_${index}`,
							)}
						>
							<Input
								disabled={disabledItems}
								key={getFormItemId(
									`${ORDER_ENTRY_FORM_ITEMS.MULTI_SELECT_INPUT}_${index}`,
								)}
								name={getFormItemId(
									`${ORDER_ENTRY_FORM_ITEMS.MULTI_SELECT_INPUT}_${index}`,
								)}
								placeholder={getTranslation('multiSelectInputPlaceholder', {
									index: index + 1,
								})}
								type="string"
							/>
						</GenericForm.FormItem>
					))}
				</div>
			)}
		</>
	);
});
