import './style.scss';

import { Card } from 'antd';
import React, { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { bypassReviewSaga } from 'app/containers/Settings/sections/BypassReview/sagas';
import {
	reducer as bypassReviewReducer,
	sliceKey as bypassReviewSliceKey,
} from 'app/containers/Settings/sections/BypassReview/slice';
import { commoditiesSaga } from 'app/containers/Settings/sections/Commodities/sagas';
import {
	reducer as commoditiesReducer,
	sliceKey as commoditiesSliceKey,
} from 'app/containers/Settings/sections/Commodities/slice';
import { translations } from 'locales/i18n';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { useMenuItems } from './definitions/hooks/useMenuItems';
import { settingsSaga } from './saga';
import { Menu } from './sections/Menu';
import { serviceFeesSagas } from './sections/ServiceFees/sagas';
import {
	serviceFeesReducer,
	serviceFeesSliceKey,
} from './sections/ServiceFees/slice';
import { userRolesSagas } from './sections/UserRoles/sagas';
import {
	userRolesReducer,
	userRolesSliceKey,
} from './sections/UserRoles/slice';
import { selectActiveSection } from './selectors';
import { settingsActions, settingsReducer, settingsSliceKey } from './slice';
import { Sections } from './types';

export const Settings = memo(() => {
	// Root - Settings
	useInjectReducer({ key: settingsSliceKey, reducer: settingsReducer });
	useInjectSaga({ key: settingsSliceKey, saga: settingsSaga });

	// Sections
	// User Roles
	useInjectReducer({ key: userRolesSliceKey, reducer: userRolesReducer });
	useInjectSaga({ key: userRolesSliceKey, saga: serviceFeesSagas });

	// Sections
	// Service FEes
	useInjectReducer({ key: serviceFeesSliceKey, reducer: serviceFeesReducer });
	useInjectSaga({ key: serviceFeesSliceKey, saga: userRolesSagas });

	// Sections
	// Commodities
	useInjectReducer({ key: commoditiesSliceKey, reducer: commoditiesReducer });
	useInjectSaga({ key: commoditiesSliceKey, saga: commoditiesSaga });

	//Bypass Review
	useInjectReducer({ key: bypassReviewSliceKey, reducer: bypassReviewReducer });
	useInjectSaga({ key: bypassReviewSliceKey, saga: bypassReviewSaga });

	const activeSection = useSelector(selectActiveSection);

	const dispatch = useDispatch();

	const { t: translate } = useTranslation();
	const { section } = useParams<{ section: Sections }>();

	const { allowedMenuItems, defaultMenuItem } = useMenuItems();

	useEffect(() => {
		dispatch(settingsActions.setActiveSection(section ?? defaultMenuItem));
	}, [section]);

	useEffect(() => {
		dispatch(
			globalActions.setPageTitle(
				translate(translations.app.containers.Settings.title),
			),
		);

		return () => {
			dispatch(settingsActions.setActiveSection(Sections.Customers));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Helmet>
				<title>{translate(translations.app.containers.Settings.title)}</title>
				<meta
					name={translate(translations.app.containers.Settings.title)}
					content={translate(translations.app.containers.Settings.metaContent)}
				/>
			</Helmet>
			<div className="settings">
				<Card className="settings__left-panel" bordered={false}>
					<Menu
						menuItems={allowedMenuItems}
						defaultSelected={section || defaultMenuItem}
					/>
				</Card>
				<Card className="settings__right-panel" bordered={false}>
					{
						allowedMenuItems.find(
							(menuItem) => activeSection === menuItem?.name,
						)?.component
					}
				</Card>
			</div>
		</>
	);
});
