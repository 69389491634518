export enum FeatureFlag {
	enableNotifications = 'enableNotifications',
	enableNewFilterLayout = 'enableNewFilterLayout',
	enablePreHedge = 'enablePreHedge',
	enableHedgeIntegration = 'enableHedgeIntegration',
	enabledDestinationLocation = 'enabledDestinationLocation',
	enableOfferQuantityEdit = 'enableOfferQuantityEdit',
	enableRegions = 'enableRegions',
	enableEmployeeGroupedLocation = 'enableEmployeeGroupedLocation',
	enableBidsSentTimeScheduling = 'enableBidsSentTimeScheduling',
}
