import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';

export const useLocationFieldTranslations = () => {
	const { t: translate } = useTranslation();
	const domain = {
		...translations.app.containers.Settings.definitions.fields,
		...translations.app.containers.Settings.sections,
		...translations.app.containers.Settings.sections.Roles.Views.CreateRole,
	};

	const customerScope =
		translations.app.containers.Settings.sections.Customers.Modals.CreateEdit;

	return {
		validationSchema: {
			required: translate(domain.validationSchema.required),
			requiredFile: translate(domain.validationSchema.requiredFile),
			invalidEmail: translate(domain.validationSchema.invalidEmail),
			noAvailableBids: translate(domain.rollOrphanAvailableBidsError),
		},
		erpNumber: {
			label: translate(domain.erpNumber.label),
			placeholder: translate(domain.erpNumber.placeholder),
			erpNumberRepeatedMessage: translate(
				customerScope.erpNumberRepeatedMessage,
			),
			defaultErrorMessage: translate(customerScope.toastErrorCreateMessage),
		},
		location: {
			locationName: {
				label: translate(domain.LegacyLocations.locationNameLabel),
				placeholder: translate(domain.LegacyLocations.locationNamePlaceholder),
			},
			erpNumber: {
				label: translate(domain.LegacyLocations.erpNumberLabel),
				placeholder: translate(domain.LegacyLocations.erpNumberPlaceholder),
			},
			origin: {
				label: translate(domain.LegacyLocations.locationOriginLabel),
				instructions: translate(
					domain.LegacyLocations.locationOriginInstructions,
				),
				isLocationOption: translate(
					domain.LegacyLocations.locationOptionIsLocation,
				),
				isDestinationOption: translate(
					domain.LegacyLocations.locationOptionIsDestination,
				),
				message: translate(domain.LegacyLocations.locationOriginInstructions),
			},
		},
	};
};
