import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';
import { createSlice } from 'utils/@reduxjs/toolkit';

import {
	Customer,
	CustomerSectionState,
	Filters,
	Modal,
	TooglePayload,
	View,
} from './types';

// The initial state of the Settings container
export const initialState: CustomerSectionState = {
	currentView: View.List,
	currentModal: null,

	activeCustomer: {
		data: null,
		error: null,
		loading: false,
	},

	countriesList: {
		data: null,
		loading: false,
		error: null,
	},

	statesList: {
		data: null,
		loading: false,
		error: null,
	},

	customerStatus: {
		data: null,
		loading: false,
		error: null,
	},

	list: {
		data: [],
		loading: false,
		total: 0,
		allLoaded: false,
		error: null,
	},

	pagination: {
		limit: 50,
		start: 1,
	},

	selectedFilters: {
		query: '',
	},
};

const customersSlice = createSlice({
	name: 'customers',
	initialState,
	reducers: {
		setCurrentModal(state, action: PayloadAction<Modal | null>) {
			state.currentModal = action.payload;
		},

		resetCustomer(state) {
			state.activeCustomer = {
				data: null,
				loading: false,
				error: null,
			};
		},

		createCustomer(
			state,
			action: PayloadAction<{ data: Customer; successMessage: string }>,
		) {
			state.activeCustomer = {
				...state.activeCustomer,
				error: null,
				loading: true,
			};
		},

		customerCreated(state, action) {
			state.activeCustomer = {
				data: null,
				loading: false,
				error: null,
			};
		},

		editCustomer(
			state,
			action: PayloadAction<{ data: Customer; successMessage: string }>,
		) {
			state.activeCustomer = {
				...state.activeCustomer,
				error: null,
				loading: true,
			};
		},

		customerEdited(state, action) {
			state.activeCustomer = {
				data: null,
				loading: false,
				error: null,
			};
		},

		customerError(state, action: PayloadAction<GenericError>) {
			state.activeCustomer = {
				...state.activeCustomer,
				error: action.payload,
				loading: false,
			};
		},

		setCustomerStatus(state, action: PayloadAction<TooglePayload>) {
			state.customerStatus = {
				...state.customerStatus,
				loading: true,
				error: null,
			};
		},

		setCustomerStatusSuccess(state, action) {
			state.customerStatus = {
				...state.customerStatus,
				loading: false,
				error: null,
				data: action.payload,
			};
		},

		setCustomerStatusError(state, action) {
			state.customerStatus = {
				...state.customerStatus,
				error: action.payload,
				loading: false,
			};
		},

		setActiveCustomer(state, action: PayloadAction<Customer>) {
			state.activeCustomer = {
				data: action.payload,
				loading: true,
				error: null,
			};
		},

		// Countries actions
		getCountriesList(state) {
			state.countriesList = {
				...state.countriesList,
				data: null,
				loading: true,
				error: null,
			};
		},

		retrievedCountriesList(state, action) {
			state.countriesList = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		errorCountriesList(state, action: PayloadAction<GenericError>) {
			state.countriesList = {
				data: null,
				loading: false,
				error: action.payload,
			};
		},

		// States actions
		getStatesList(state) {
			state.statesList = {
				...state.statesList,
				data: null,
				loading: true,
				error: null,
			};
		},

		retrievedStatesList(state, action) {
			state.statesList = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		errorStatesList(state, action: PayloadAction<GenericError>) {
			state.statesList = {
				data: null,
				loading: false,
				error: action.payload,
			};
		},

		loadCustomersList(state) {
			state.list = {
				...state.list,
				data: [],
				loading: true,
				error: null,
			};
		},

		customersListLoaded(
			state,
			action: PayloadAction<{ list: Customer[]; total: number }>,
		) {
			state.list = {
				...state.list,
				data: action.payload.list,
				total: action.payload.total,
				loading: false,
				error: null,
			};
		},

		customersListError(state, action: PayloadAction<GenericError>) {
			state.list = {
				...state.list,
				loading: false,
				error: action.payload,
			};
		},

		setPagination(state, action: PayloadAction<any>) {
			state.pagination = {
				limit: action.payload.limit || state.pagination.limit,
				start: action.payload.start || state.pagination.start,
			};
		},

		setSelectedFilters(state, action: PayloadAction<Filters>) {
			state.selectedFilters = {
				query: action.payload.query || '',
			};
		},

		requestedCustomerById(state, action) {
			state.activeCustomer = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = customersSlice;
