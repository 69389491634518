import './style.scss';

import { Card } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { actions } from '../HubSaga/slice';
import { ListMenu } from './components/Views/List/ListMenu';
import { TableWrapper } from './components/Views/List/TableWrapper';
import { FuturesErpSaga } from './root-saga';
import { reducer, sliceKey } from './slice';

export function FuturesErp() {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: FuturesErpSaga });

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(actions.subscribeToFuturesErpUpdates());

		return () => {
			dispatch(actions.unsubscribeFromFuturesErpUpdates());
		};
	}, []);

	return (
		<Card bordered={true} className="futureserp-list">
			<ListMenu />
			<div className="futureserp-container">
				<TableWrapper />
			</div>
		</Card>
	);
}
