import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.customers || initialState;

export const selectCurrentModal = createSelector(
	[selectDomain],
	(settingsState) => settingsState.currentModal,
);

export const selectActiveCustomerError = createSelector(
	[selectDomain],
	(customersState) => customersState.activeCustomer.error,
);

export const selectCountriesList = createSelector(
	[selectDomain],
	(customersState) => customersState.countriesList,
);

export const selectStatesList = createSelector(
	[selectDomain],
	(customersState) => customersState.statesList,
);

export const selectListData = createSelector(
	[selectDomain],
	(customersState) => customersState.list.data,
);

export const selectListLoading = createSelector(
	[selectDomain],
	(customersState) => customersState.list.loading,
);

export const selectListTotal = createSelector(
	[selectDomain],
	(customersState) => customersState.list.total,
);
export const selectPaginationLimit = createSelector(
	[selectDomain],
	(customersState) => customersState.pagination.limit,
);

export const selectPaginationStart = createSelector(
	[selectDomain],
	(customersState) => customersState.pagination.start,
);
export const selectFilters = createSelector(
	[selectDomain],
	(customersState) => customersState.selectedFilters,
);
export const selectCustomerStatusError = createSelector(
	[selectDomain],
	(customersState) => customersState.customerStatus.error,
);
export const selectCurrentCustomerLoading = createSelector(
	[selectDomain],
	(customersState) => customersState.activeCustomer.loading,
);

export const selectCurrentCustomerData = createSelector(
	[selectDomain],
	(customersState) => customersState.activeCustomer.data,
);
