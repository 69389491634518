import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectNtcAdminLoading } from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { ntcAdminSaga } from '../saga';
import { reducer, sliceKey } from '../slice';

export const useNtcAdminData = () => {
	useInjectReducer({ key: sliceKey, reducer });
	useInjectSaga({ key: sliceKey, saga: ntcAdminSaga });
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const isLoading = useSelector(selectNtcAdminLoading);

	useEffect(() => {
		dispatch(globalActions.getNtcAdminRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		isLoading,
		translate,
	};
};
