/**
 *
 * Bypass Review
 *

*/
import './style.scss';

import { Button, Divider, Spin } from 'antd';
import classNames from 'classnames';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FormDropdown } from 'app/components/FormDropdown';
import { FormSwitch } from 'app/components/FormSwitch';
import { translations } from 'locales/i18n';
import { mapToLabelValue } from 'utils/helpers';

import {
	selectTag50EmployeesListData,
	selectTag50SettingsData,
	selectUpdateTag50SettingsLoading,
} from './selectors';
import { actions } from './slice';

export function BypassReview() {
	const { t: translate } = useTranslation();
	const domain = translations.app.containers.Settings.sections.BypassReview;

	const dispatch = useDispatch();

	const tag50Settings = useSelector(selectTag50SettingsData);
	const employees = useSelector(selectTag50EmployeesListData);
	const isLoading = useSelector(selectUpdateTag50SettingsLoading);

	const initialValues = {
		activateBypassReview: tag50Settings?.byPassOn || false,
		daySessionTag: tag50Settings
			? { value: tag50Settings.daySessionEmployeeId }
			: null,
		nightSessionTag: tag50Settings
			? { value: tag50Settings.nightSessionEmployeeId }
			: null,
	};

	useEffect(() => {
		dispatch(actions.loadTag50Settings());
		dispatch(actions.loadTag50EmployeesList());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = (values) => {
		const data = {
			byPassOn: values.activateBypassReview,
			daySessionEmployeeId: values.activateBypassReview
				? values.daySessionTag.value
				: null,
			nightSessionEmployeeId: values.activateBypassReview
				? values.nightSessionTag.value
				: null,
		};

		dispatch(
			actions.updateTag50Settings({
				data,
				successMessage: translate(domain.updateSuccessful),
			}),
		);
	};

	return (
		<>
			<div className="bypass-review-container">
				<Formik
					enableReinitialize
					initialValues={initialValues}
					onSubmit={handleSubmit}
				>
					{(formik) => {
						const {
							values: { activateBypassReview, daySessionTag, nightSessionTag },
							dirty,
						} = formik;
						const formIsComplete =
							!activateBypassReview ||
							(activateBypassReview &&
								!!daySessionTag?.value &&
								!!nightSessionTag?.value);
						return (
							<Form labelCol={{ span: 2 }} wrapperCol={{ span: 4 }}>
								<div className="bypass-form-body">
									<FormSwitch
										data-testid="bypass-form-switch"
										name="activateBypassReview"
										label={translate(domain.activateBypassReview)}
										labelCol={{ span: 2 }}
									/>
									<FormDropdown
										name="daySessionTag"
										label={translate(domain.daySessionTag)}
										placeholder={translate(domain.dropdownPlaceholder)}
										disabled={!activateBypassReview}
										options={mapToLabelValue(employees)}
									/>
									<FormDropdown
										name="nightSessionTag"
										label={translate(domain.nightSessionTag)}
										placeholder={translate(domain.dropdownPlaceholder)}
										disabled={!activateBypassReview}
										options={mapToLabelValue(employees)}
									/>
								</div>
								<div className="bypass-form-actions">
									<div className="form-buttons">
										<Divider className="form-buttons__divider" />
										<Button
											data-testid="form-button-submit"
											type="primary"
											className={classNames({
												'form-button-submit': isLoading,
											})}
											key="form-buttons-submit"
											htmlType={'submit'}
											disabled={isLoading || !formIsComplete || !dirty}
										>
											{isLoading && (
												<Spin
													data-testid="bypass-review-spin"
													className="form-button-spin"
													size="small"
												/>
											)}
											{translate(domain.saveChanges)}
										</Button>
									</div>
								</div>
							</Form>
						);
					}}
				</Formik>
			</div>
		</>
	);
}
