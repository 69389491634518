/**
 *
 * GenericForm
 *
 */

import { FieldDefinition } from 'types/FieldDefinition';

export interface FormSchema {
	initialValues: object;
	elements: JSX.Element[];
	validationSchema: object;
}

export const formSchemaGenerator = (schema: FieldDefinition[]) => {
	let initialValues: Record<string, unknown> = {};
	const elements: JSX.Element[] = [];
	let validationSchema: Record<string, unknown> = {};

	schema.forEach((singleField) => {
		const { name, initialValue, validation, render } = singleField;
		let isRequired: boolean = true;

		const innerFields = singleField.fields;

		if (innerFields && innerFields.length) {
			innerFields.forEach((field) => {
				const { name, initialValue, validation } = field;

				isRequired =
					isRequired &&
					validation &&
					validation._exclusive &&
					validation._exclusive.required;

				initialValues[name] = initialValue;
				validationSchema[name] = validation;
			});
		} else {
			isRequired =
				validation && validation._exclusive && validation._exclusive.required;

			initialValues[name] = initialValue;
			validationSchema[name] = validation;
		}

		if (isRequired && singleField.label) {
			singleField = {
				...singleField,
				label: `*${singleField.label}`,
			};
		}

		if (render) {
			elements.push(render(singleField));
		}
	});

	return {
		initialValues,
		elements,
		validationSchema,
	} as FormSchema;
};
