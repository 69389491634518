import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
	selectHasCustomFields,
	selectOrderEntry,
} from 'app/containers/GlobalSaga/selectors';
import { OrderEntryElement } from 'app/containers/Transactions/components/OrderEntryElement';
import { ContractDetails } from 'types/ContractDetails';
import { OfferDetails } from 'types/OfferDetails';
import { OrderEntry } from 'types/OrderEntry';
import {
	getInitialValues,
	getValidationSchema,
} from 'utils/order-entry-helpers';

const getElements = (orderEntries: OrderEntry[] = []) =>
	orderEntries
		.filter(({ isActive }: OrderEntry) => isActive)
		.map((orderEntry: OrderEntry) => (
			<OrderEntryElement
				key={`OrderEntry-${orderEntry.id}`}
				orderEntry={orderEntry}
			/>
		));

const EMPTY_SCHEMA = {
	initialValues: {},
	validationSchema: {},
	elements: [],
};

export const useOrderEntriesFormSchema = (
	orderData?: ContractDetails | OfferDetails | null | undefined,
) => {
	const { t: translate } = useTranslation();
	const hasCustomFields = useSelector(selectHasCustomFields);
	const orderEntries = useSelector(selectOrderEntry);
	const schema = useMemo(
		() => ({
			initialValues: getInitialValues(orderEntries, orderData),
			validationSchema: getValidationSchema(orderEntries, translate),
			elements: getElements(orderEntries),
		}),
		[orderData, orderEntries, translate],
	);

	if (!hasCustomFields) {
		return EMPTY_SCHEMA;
	}

	return schema;
};
