import './style.scss';

import { InputNumberProps } from 'antd/lib/input-number';
import classNames from 'classnames';
import { Form, InputNumber } from 'formik-antd';
import React, { memo } from 'react';

interface Props extends InputNumberProps {
	name: string;
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	arrowsHidden?: boolean;
	decimals?: number;
}
export const FormInputQuantity = memo((props: Props) => {
	const { name, label, arrowsHidden, placeholder, disabled, decimals } = props;

	return (
		<Form.Item
			className="form-input-quantity__label"
			label={label}
			name={name}
			key={name}
		>
			<InputNumber
				{...props}
				className={classNames('form-input-quantity__input', {
					'arrows-hidden': arrowsHidden,
				})}
				name={name}
				data-testid="form-input-quantity"
				placeholder={placeholder}
				disabled={disabled}
				precision={decimals}
				formatter={(value: any) => {
					if (typeof value === 'number') {
						return value.toString(10).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
					} else if (typeof value === 'string') {
						return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
					} else return '';
				}}
				parser={(value: any) => {
					if (typeof value === 'number') {
						return value.toString(10).replace(/\$\s?|(,*)/g, '');
					} else if (typeof value === 'string') {
						return value.replace(/\$\s?|(,*)/g, '');
					} else return '';
				}}
			/>
		</Form.Item>
	);
});
