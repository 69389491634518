export enum FIELD_TYPES_VALUES {
	BLN = 'BLN',
	DAT = 'DAT',
	INT = 'INT',
	MUL = 'MUL',
	TXT = 'TXT',
	TXT2 = 'TX2',
	UND = 'UND',
}

export interface CustomField {
	id: string;
	type: FIELD_TYPES_VALUES;
	value: string | null;
}

export type OrderEntry = {
	id?: string;
	items?: string[];
	label?: string;
	isActive?: boolean;
	type?: FIELD_TYPES_VALUES;
	defaultValue?: string;
};

export type OrderEntryData = {
	fields: OrderEntry[];
	hasCustomFields: boolean;
};
