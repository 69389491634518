import { CONTRACT_TYPES } from 'utils/constants';

import { useTranslations } from '../../../shared/useTranslations';
import { useFlatPriceSchema } from '../Open/FlatPriceSchema';
import { useBasisSchema } from './BasisSchema';
import { useHTASchema } from './HTASchema';

export const useEditPricedSchema = (
	translations: ReturnType<typeof useTranslations>,
) => ({
	[CONTRACT_TYPES.flatPrice]: useFlatPriceSchema(translations),
	[CONTRACT_TYPES.basis]: useBasisSchema(translations),
	[CONTRACT_TYPES.hta]: useHTASchema(translations),
});
