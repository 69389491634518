import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.roundingRules || initialState;

export const selectActiveRoundingRule = createSelector(
	[selectDomain],
	(settingsState) => settingsState.activeRoundingRule.data,
);

export const selectCurrentModal = createSelector(
	[selectDomain],
	(settingsState) => settingsState.currentModal,
);

export const selectListData = createSelector(
	[selectDomain],
	(settingsState) => settingsState.list.data,
);

export const selectTypeData = createSelector(
	[selectDomain],
	(settingsState) => settingsState.typelist.data,
);

export const selectListLoading = createSelector(
	[selectDomain],
	(settingsState) => settingsState.list.loading,
);

export const selectListTotal = createSelector(
	[selectDomain],
	(settingsState) => settingsState.list.total,
);
export const selectPaginationLimit = createSelector(
	[selectDomain],
	(settingsState) => settingsState.pagination.limit,
);

export const selectPaginationStart = createSelector(
	[selectDomain],
	(settingsState) => settingsState.pagination.start,
);
export const selectFilters = createSelector(
	[selectDomain],
	(settingsState) => settingsState.selectedFilters,
);

export const selectCurrentRoundingRuleError = createSelector(
	[selectDomain],
	(settingsState) => settingsState.currentRoundingRule.error,
);

export const selectCurrentRoundingRule = createSelector(
	[selectDomain],
	(settingsState) => settingsState.currentRoundingRule.data,
);

export const selectCurrentRoundingRuleLoading = createSelector(
	[selectDomain],
	(settingsState) => settingsState.currentRoundingRule.loading,
);
