import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectFeatureFlags } from 'app/containers/GlobalSaga/selectors';
import {
	OrderSource,
	OrderStatus,
} from 'app/containers/ReviewAndRelease/types';
import { FeatureFlag } from 'types/FeatureFlags';
import { CONSTANTS } from 'utils/constants';
import {
	basisFormat,
	currencyFormat,
	customFormat,
	dateFormatShortYear,
	dateShortFormat,
	dateTimeFormat,
} from 'utils/helpers';

import { getCustomFields } from '../utils/helpers';
import { useSummaryTranslations } from './useSummaryTranslations';

export const useSummarySchema = (activeEntityData) => {
	const translations = useSummaryTranslations();
	const [summary, setSummary] = useState<any>(null);
	const featureFlags = useSelector(selectFeatureFlags);

	const mapSummary = useCallback(
		(activeEntity: any) => ({
			originatedOfferId: {
				label: translations.contract.originatedOfferId,
				value: activeEntity.offerNumber,
			},
			contract: [
				{
					label: translations.contract.contractNumber,
					value: activeEntity.number,
				},
				{
					label: translations.contract.theirContract,
					value: activeEntity.theirContract,
				},
			],
			hrvystID: [
				{
					label: translations.hrvyst.id,
					value: activeEntity.internalNumber,
				},
				{
					label: translations.contract.parentContract,
					value: activeEntity.parent?.number,
				},
			],
			offer: [
				{
					label: translations.offer.offerNumber,
					value: activeEntity.number,
				},
				{
					label: translations.offer.theirContract,
					value: activeEntity.theirContract,
				},
			],
			order: {
				account: {
					label: translations.reviewAndRelease.accountNumber,
					value: activeEntity.accountNumber,
				},
				isSell: {
					labelSell: translations.contract.salePill,
					labelPurchase: translations.contract.purchasePill,
					value: activeEntity.isSell,
				},
				number: {
					label: translations.reviewAndRelease.orderNumber,
					value: activeEntity.orderNumber,
				},
				theirContract: {
					label: translations.reviewAndRelease.theirContract,
					value: activeEntity.theirContract,
				},
			},
			orderSource: [
				{
					label: translations.reviewAndRelease.orderSource,
					value: activeEntity.source,
				},
				{
					label: translations.reviewAndRelease.orderSymbol,
					value: activeEntity.symbol?.instrument,
				},
			],
			orderType: [
				{
					label: translations.reviewAndRelease.orderMonthYear,
					value: activeEntity.futuresMonth,
				},
				{
					label: translations.reviewAndRelease.orderType,
					value: activeEntity.typeName,
				},
			],
			orderGTC: [
				{
					label: translations.reviewAndRelease.orderGTC,
					value:
						activeEntity.source !== OrderSource.offer
							? '----'
							: activeEntity.gtc?.isGTC
								? translations.reviewAndRelease.orderIsGTC
								: translations.reviewAndRelease.orderIsNotGTC,
				},
			],
			preHedgeGTC: [
				{
					label: translations.preHedge.orderGTC,
					value: activeEntity.gtc?.isGTC
						? translations.preHedge.orderIsGTC
						: translations.preHedge.orderIsNotGTC,
				},
				{
					label: translations.preHedge.quantityOnHedge,
					value: activeEntity.quantityOnHedge,
				},
				{
					label: translations.preHedge.orphanedBalance,
					value: activeEntity.orphanedBalance,
				},
			],
			orderPrice: [
				{
					label: translations.reviewAndRelease.orderPrice,
					value: activeEntity.price
						? currencyFormat(
								activeEntity.price,
								CONSTANTS.FIXED_DECIMALS,
								CONSTANTS.FIXED_DECIMALS,
							)
						: '---',
				},
			],
			orderAvgFillPrice: [
				{
					label: translations.reviewAndRelease.orderAvgFillPrice,
					value:
						!!!activeEntity.passFill ||
						activeEntity.status === OrderStatus.Filled
							? currencyFormat(
									activeEntity.avgFillPrice,
									CONSTANTS.FIXED_DECIMALS,
									CONSTANTS.FIXED_DECIMALS,
								)
							: '',
				},
			],
			orderQuantity: [
				{
					label: translations.reviewAndRelease.orderQuantity,
					value: activeEntity.quantity,
				},
			],
			orderWorkingQuantity: [
				{
					label: translations.reviewAndRelease.orderWorkingQuantity,
					value: activeEntity.workingQuantity,
				},
			],
			orderFilledQuantity: [
				{
					label: translations.reviewAndRelease.orderFilledQuantity,
					value: activeEntity.workingQuantity,
				},
			],
			orderRejectedReason: [
				{
					label: translations.reviewAndRelease.orderRejectedReason,
					value: activeEntity.marketInformation,
				},
			],
			contractType: {
				type: {
					label: translations.contract.contractType,
					value: activeEntity.contractTypeName,
				},
				isSell: {
					labelSell: translations.contract.salePill,
					labelPurchase: translations.contract.purchasePill,
					value: activeEntity.isSell,
				},
				commodity: {
					label: translations.contract.contractCommodity,
					value: activeEntity.commodityName,
				},
			},
			offerExpiration: {
				expirationLabel: translations.offer.offerExpiration,
				gtcValue: translations.offer.offerGtc,
				expirationValue: dateFormatShortYear(activeEntity.expiration),
			},
			contractCrop: [
				{
					label: translations.contract.contractCrop,
					value: activeEntity.cropYear,
				},
				{
					label: translations.contract.contractFutureM,
					value: activeEntity.futuresMonth,
				},
			],
			offerCrop: [
				{
					label: translations.offer.offerCrop,
					value: activeEntity.cropYear,
				},
				{
					label: translations.offer.offerFuturesMonth,
					value: activeEntity.futuresMonth,
				},
			],
			contractQuantity: [
				{
					label: translations.contract.contractQuantity,
					value: activeEntity.quantity
						? customFormat(
								activeEntity.quantity,
								true,
								CONSTANTS.FIXED_QUANTITY_DECIMALS,
							)
						: activeEntity.quantity,
				},
				{
					label: translations.contract.contractUnpriced,
					value: customFormat(
						activeEntity.remainingBalance,
						true,
						CONSTANTS.FIXED_QUANTITY_DECIMALS,
					),
				},
			],
			offerQuantity: [
				{
					label: translations.offer.offerQuantity,
					value: activeEntity.quantity
						? customFormat(
								activeEntity.quantity,
								true,
								CONSTANTS.FIXED_QUANTITY_DECIMALS,
							)
						: activeEntity.quantity,
				},
				{
					label: translations.offer.offerUnfilledQuantity,
					value: customFormat(
						activeEntity.unFilled,
						true,
						CONSTANTS.FIXED_QUANTITY_DECIMALS,
					),
				},
			],
			customerTitle: translations.customerTitle,
			customer: [
				{
					label: translations.customer.customerName,
					value: activeEntity.customerName,
				},
				{
					label: translations.customer.cellPhone,
					value: activeEntity.customerPhoneNumber,
				},
				{
					label: translations.customer.email,
					value: activeEntity.customerEmail,
				},
			],
			employeeTitle: translations.employeeTitle,
			employee: [
				{
					label: translations.employee.createdOn,
					value: dateTimeFormat(activeEntity.createdOn),
				},
				{
					label: translations.employee.createdBy,
					value: activeEntity.createdBy,
				},
				{
					label: translations.employee.updatedOn,
					value: activeEntity.updatedOn
						? dateTimeFormat(activeEntity.updatedOn)
						: '---',
				},
				{
					label: translations.employee.updatedBy,
					value: activeEntity.updatedBy ? activeEntity.updatedBy : '—',
				},
			],
			deliveryInfoTitle: translations.deliveryInfoTitle,
			deliveryInfo: [
				{
					label: translations.deliveryInfo.deliveryStartDate,
					value: dateShortFormat(activeEntity.deliveryStartDate),
				},
				{
					label: translations.deliveryInfo.deliveryEndDate,
					value: dateShortFormat(activeEntity.deliveryEndDate),
				},
				{
					label: translations.deliveryInfo.expirationDate,
					value: activeEntity.expiration
						? dateShortFormat(activeEntity.expiration)
						: '---',
				},
				{
					label: `${translations.deliveryInfo.location}:`,
					value: activeEntity.locationName,
				},
				{
					label: `${translations.deliveryInfo.destination}:`,
					value: activeEntity.deliveryLocationName,
				},
			],
			prices: [
				{
					label: translations.prices.futures,
					value: !!!activeEntity.passFill
						? basisFormat(activeEntity.futuresPrice)
						: '$',
				},
				{
					label: translations.prices.freight,
					value: basisFormat(activeEntity.freightPrice),
				},
				{
					label: translations.prices.serviceFee,
					value1: basisFormat(activeEntity.fees1),
					value2: basisFormat(activeEntity.fees2),
				},
			],
			basis: [
				{
					label: translations.basis.postedB,
					value: basisFormat(activeEntity.postedBasis),
				},
				{
					label: translations.basis.pushB,
					value: basisFormat(activeEntity.pushBasis),
				},
				{
					label:
						activeEntity.contractTypeName === 'Basis'
							? translations.basis.adjustedB
							: translations.basis.netB,
					value: basisFormat(activeEntity.netBasis),
				},
			],
			cashPrice: {
				label: translations.cashPrice,
				value: !!!activeEntity.passFill ? basisFormat(activeEntity.price) : '$',
			},
			customFields: getCustomFields(activeEntity, translations),
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[activeEntityData],
	);

	useEffect(() => {
		if (activeEntityData) {
			const summary = mapSummary(activeEntityData);
			if (featureFlags && featureFlags[FeatureFlag.enableRegions])
				summary.deliveryInfo.push({
					label: `${translations.deliveryInfo.region}:`,
					value: activeEntityData.regionName,
				});
			setSummary(summary);
		} else {
			setSummary(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeEntityData]);

	return { summary };
};
