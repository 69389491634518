import './style.scss';

import { Layout } from 'antd';
import React, { useEffect } from 'react';
import { useAbac } from 'react-abac';
import { useDispatch } from 'react-redux';

import { actions as hubActions } from 'app/containers/HubSaga/slice';
import { Permission } from 'types/Authorization';

import { MainMenu } from '../MainMenu';

const { Content } = Layout;

interface Props {
	children: any;
	className?: string;
}

const APP_LAYOUT_CONSTANTS = {
	borderedCard: true,
	collapsedWidth: 64,
};

export function AppLayout(props: Props) {
	const { children, className } = props;
	const dispatch = useDispatch();
	const { userHasPermissions } = useAbac();
	const showReviewAndReleaseTab = userHasPermissions(
		Permission.CONTRACTSERVICE_REVIEWANDRELEASE_VIEW,
	);

	useEffect(() => {
		if (showReviewAndReleaseTab) {
			// We subscribe to transaction updates in application level to get updates even if the user is not in the transactions page
			dispatch(hubActions.subscribeToStatusTransactionUpdates());
		}
	}, []);

	return (
		<Layout className={`app-layout ${className}`}>
			<Layout className="main">
				<MainMenu />
				<Content data-testid="app-layout-content">{children}</Content>
			</Layout>
		</Layout>
	);
}
