import { FieldValues } from 'react-hook-form';

import { translations } from 'locales/i18n';
import { mapDynamicForm } from 'utils/helpers';

export const REGIONS_DOMAIN =
	translations.app.containers.Settings.sections.Regions;

export const REGION_ENTRIES_MIN = 1;
const MIN_GUID_PORTION = 5;

export const getFormValues = (values: FieldValues) =>
	mapDynamicForm(values).map((formItem: FieldValues) => {
		const breakId = formItem?.id.split('-');

		const idToPass =
			breakId.length < MIN_GUID_PORTION ? undefined : formItem?.id;

		return {
			id: idToPass,
			isEnabled: formItem?.isActive,
			name: formItem?.regionNameLabel,
			erpNumber: formItem?.erpIdLabel,
			isUsed: formItem?.isUsed,
		};
	});
