import React from 'react';
import { useSelector } from 'react-redux';

import { AppliedUnappliedBushel } from 'app/containers/Contracts/components/Business/AppliedUnappliedBushel';
import { EFPBushels } from 'app/containers/Contracts/components/Business/EFPBushels';
import { FuturesPrice } from 'app/containers/Contracts/components/Business/FuturesPrice';
import { QuantityToPrice } from 'app/containers/Contracts/components/Business/QuantityToPrice';
import { useTranslations } from 'app/containers/Contracts/components/Modals/shared/useTranslations';
import { selectActiveContract } from 'app/containers/Contracts/selectors';
import { AssignedRegion } from 'app/containers/Transactions/components/AssignedRegion';
import { Basis } from 'app/containers/Transactions/components/Basis';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractNumber } from 'app/containers/Transactions/components/ContractNumber';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { DeliveryDates } from 'app/containers/Transactions/components/DeliveryDates';
import { DeliveryLocation } from 'app/containers/Transactions/components/DeliveryLocation';
import { Employee } from 'app/containers/Transactions/components/Employee';
import { ExpirationDate } from 'app/containers/Transactions/components/ExpirationDate';
import { Fees } from 'app/containers/Transactions/components/Fees';
import { FlatPrice } from 'app/containers/Transactions/components/FlatPrice';
import { Freight } from 'app/containers/Transactions/components/Freight';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Location } from 'app/containers/Transactions/components/Location';
import { CONSTANTS, MIN_VALUE } from 'utils/constants';
import {
	customFormat,
	dateFormatYearMonthDay,
	dateMoment,
	getDeliveryDatesMode,
	getDeliveryDateWindow,
	getEfpQuantity,
	getExpirationDate,
	getFormattedExpirationDate,
	isDeliveryDateCustom,
	isDeliveryDateWindow,
	validatePriceFormat,
	validateQuarterCents,
} from 'utils/helpers';
import Yup from 'yupCustom';

import { validateMinMaxValue } from '../../../shared/helpers';

export const useBasisSchema = () => {
	const translations = useTranslations();

	let orderData = useSelector(selectActiveContract) as any;
	if (!!!orderData) return null;

	const { lotFactor } = orderData;

	const requiredMsg = translations.validations.required;

	return {
		initialValues: {
			theirContract: orderData.theirContract,
			contractNumber: orderData.number,
			commodity: {
				label: orderData.commodityName,
				value: orderData.commodityId,
			},
			location: {
				label: orderData.locationName,
				value: orderData.locationId,
			},
			deliveryLocation: {
				label: orderData.deliveryLocationName,
				value: orderData.deliveryLocationId,
			},
			assignedRegion: {
				label: orderData?.regionName,
				value: orderData?.regionId,
			},
			cropYear: orderData.cropYear,
			deliveryDate: [
				dateMoment(orderData.deliveryStartDate),
				dateMoment(orderData.deliveryEndDate),
			],
			deliveryDatesMode: getDeliveryDatesMode(orderData),
			deliveryDateWindow: getDeliveryDateWindow(orderData),
			futuresMonth: {
				label: orderData.futuresMonth,
				value: orderData.futuresMonth,
			},
			futuresPrice: null,
			futuresPriceInput: null,
			qtyPriceBalance: `${customFormat(orderData.remainingBalance, true, CONSTANTS.FIXED_QUANTITY_DECIMALS)}`,
			qtyPriceAmount: null,
			efpBushels: MIN_VALUE,
			postedBasis: validatePriceFormat(orderData.postedBasis),
			pushBasis: validatePriceFormat(orderData.pushBasis),
			netBasis: validatePriceFormat(orderData.netBasis),
			freight: validatePriceFormat(orderData.freightPrice),
			fees1: validatePriceFormat(orderData.fees1),
			fees2: validatePriceFormat(orderData.fees2),
			flatPrice: null,
			employee: {
				label: orderData.employeeName,
				value: orderData.employeeId,
			},
			comments: orderData.comments,
			expirationDate: getExpirationDate(orderData),
			isUnappliedLoad: false,
		},
		validationSchema: Yup.object().shape({
			location: Yup.object().requiredOption(requiredMsg),
			deliveryLocation: Yup.object().requiredOption(requiredMsg),
			assignedRegion: Yup.object().requiredOption(requiredMsg),
			deliveryDate: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateCustom(mode),
				then: Yup.mixed().required(requiredMsg),
			}),
			deliveryDateWindow: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateWindow(mode),
				then: Yup.object().requiredOption(requiredMsg),
			}),
			flatPrice: Yup.number()
				.typeError(translations.validations.number)
				.required(translations.validations.required),
			qtyPriceAmount: Yup.string()
				.required(translations.validations.required)
				.test(
					'minMaxValidation',
					translations.validations.rangeValue(
						customFormat(
							orderData.remainingBalance,
							true,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						),
					),
					(value: string) =>
						validateMinMaxValue(orderData.remainingBalance, value),
				)
				.nullable(),
			efpBushels: Yup.string().required(requiredMsg),
			futuresPriceInput: Yup.number()
				.typeError(requiredMsg)
				.required(requiredMsg)
				.test(
					'quarterCentsValidation',
					translations.validations.futuresPriceQuarterMsg,
					(val) => validateQuarterCents(val),
				),
			employee: Yup.object().requiredOption(requiredMsg),
			freight: Yup.number().typeError(translations.validations.number),
			fees1: Yup.number().typeError(translations.validations.number),
			fees2: Yup.number().typeError(translations.validations.number),
			expirationDate: Yup.string().nullable(),
		}),
		elements: [
			<Commodity hidden />, // hidden fields work as inner references for business rules
			<ContractNumber disabled />,
			<Location />,
			<DeliveryLocation />,
			<AssignedRegion />,
			<CropYears disabled />,
			<DeliveryDates
				modeSelectorDisabled
				filterByFutureMonth={orderData.futuresMonth}
			/>,
			<Futures futuresMonth={{ market: true, disabled: true }} />,
			<QuantityToPrice disabled={{ qtyPriceBalance: true }} />,
			<AppliedUnappliedBushel />,
			<EFPBushels />,
			<Basis
				fields={{ netBasis: true, pushBasis: false, postedBasis: false }}
				disabled={{ netBasis: true }}
			/>,
			<FuturesPrice setTotalPrice />,
			<ExpirationDate />,
			<Freight />,
			<Fees />,
			<FlatPrice disabled />,
			<Employee />,
			<Comments />,
		],
		getSubmitValues: (values) => {
			let submitValues = {
				theirContract: orderData.theirContract,
				locationId: values.location?.value,
				deliveryLocationId: values.deliveryLocation?.value,
				isDeliveryDatesCustom: isDeliveryDateCustom(values.deliveryDatesMode),
				deliveryStartDate: dateFormatYearMonthDay(values.deliveryDate?.[0]),
				deliveryEndDate: dateFormatYearMonthDay(values.deliveryDate?.[1]),
				quantity: parseFloat(
					customFormat(
						values.qtyPriceAmount,
						false,
						CONSTANTS.FIXED_QUANTITY_DECIMALS,
					),
				),
				efpQuantity: getEfpQuantity(
					parseFloat(
						customFormat(
							values.qtyPriceAmount,
							false,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						),
					),
					values.efpBushels === MIN_VALUE,
					lotFactor,
				),
				IsMinimumEFP: values.efpBushels === MIN_VALUE,
				futuresPrice: parseFloat(values.futuresPriceInput) || 0,
				futuresMonth: values.futuresMonth?.value,
				freightPrice: parseFloat(values.freight),
				fees1: parseFloat(values.fees1),
				fees2: parseFloat(values.fees2),
				employeeId: values.employee?.value,
				comments: values.comments,
				expirationDate: getFormattedExpirationDate(values),
				isAppliedLoad: !values?.isUnappliedLoad ?? true,
				regionId: values?.assignedRegion?.value,
			};

			if (!isNaN(values.freight)) {
				submitValues = {
					...submitValues,
					freightPrice: parseFloat(values.freight),
				};
			}

			if (!isNaN(values.fees1)) {
				submitValues = {
					...submitValues,
					fees1: parseFloat(values.fees1),
				};
			}

			if (!isNaN(values.fees2)) {
				submitValues = {
					...submitValues,
					fees2: parseFloat(values.fees2),
				};
			}

			return submitValues;
		},
	};
};
