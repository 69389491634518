import { FieldModes } from '../types';
import { CurrentGroupedLocation } from './types';
import { useAutocompleteEditSchema } from './useAutocompleteEditSchema';
import { useManualEditSchema } from './useManualEditSchema';

export const useLocationSchema = (
	currentLocation: CurrentGroupedLocation | null = null,
	isEditMode: boolean = false,
) => ({
	[FieldModes.Autocomplete]: useAutocompleteEditSchema(
		currentLocation,
		isEditMode,
	),
	[FieldModes.Manual]: useManualEditSchema(currentLocation),
});
