import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectActiveContract } from 'app/containers/Contracts/selectors';
import { AssignedRegion } from 'app/containers/Transactions/components/AssignedRegion';
import { Basis } from 'app/containers/Transactions/components/Basis';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractType } from 'app/containers/Transactions/components/ContractType';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { DeliveryDates } from 'app/containers/Transactions/components/DeliveryDates';
import { DeliveryLocation } from 'app/containers/Transactions/components/DeliveryLocation';
import { Employee } from 'app/containers/Transactions/components/Employee';
import { ExpirationDate } from 'app/containers/Transactions/components/ExpirationDate';
import { Fees } from 'app/containers/Transactions/components/Fees';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Location } from 'app/containers/Transactions/components/Location';
import { NetBasis } from 'app/containers/Transactions/components/NetBasis';
import { useOrderEntriesFormSchema } from 'app/containers/Transactions/hooks/useOrderEntriesFormSchema';
import { useTranslations } from 'app/containers/Transactions/hooks/useTranslations';
import {
	getActionType,
	getDeliveryDatesMode,
	getDeliveryDateWindow,
	getExpirationDate,
	isDeliveryDateCustom,
	isDeliveryDateWindow,
	validatePriceFormat,
} from 'utils/helpers';
import Yup from 'yupCustom';

import { validatePrice } from '../../shared/helpers';

export const useNTCSchema = () => {
	const translations = useTranslations();
	let orderData = useSelector(selectActiveContract) as any;

	const { elements, initialValues, validationSchema } =
		useOrderEntriesFormSchema(orderData);
	const numberMsg = translations.validations.number;

	const requiredMsg = translations.validations.required;
	const basisFormatError = translations.validations.basisFormatError;

	return {
		initialValues: {
			action: getActionType(orderData.isSell),
			commodity: {
				label: orderData.commodityName,
				value: orderData.commodityId,
			},
			location: {
				label: orderData.locationName,
				value: orderData.locationId,
			},
			deliveryLocation: {
				label: orderData.deliveryLocationName,
				value: orderData.deliveryLocationId,
			},
			assignedRegion: {
				label: orderData?.regionName,
				value: orderData?.regionId,
			},
			deliveryDatesMode: getDeliveryDatesMode(orderData),
			deliveryDateWindow: getDeliveryDateWindow(orderData),
			cropYear: orderData.cropYear,
			contract: {
				label: orderData.contractTypeName,
				value: orderData.contractTypeId,
			},
			futuresMonth: {
				label: orderData.futuresMonth,
				value: orderData.futuresMonth,
			},
			futuresPrice: validatePriceFormat(orderData.futuresPrice),
			postedBasis: validatePriceFormat(orderData.postedBasis),
			pushBasis: validatePriceFormat(orderData.pushBasis),
			netBasis: validatePriceFormat(orderData.netBasis),
			freight: validatePriceFormat(orderData.freightPrice),
			fees1: validatePriceFormat(orderData.fees1),
			fees2: validatePriceFormat(orderData.fees2),
			flatPrice: validatePriceFormat(orderData.price),
			netFutures: validatePriceFormat(orderData.price),
			netBasisPrice: validatePriceFormat(orderData.price),
			deliveryDate: [
				moment(orderData.deliveryStartDate),
				moment(orderData.deliveryEndDate),
			],
			employee: {
				label: orderData.employeeName,
				value: orderData.employeeId,
			},
			comments: orderData.comments,
			passFill: orderData.passFill,
			doNotHedge: orderData.doNotHedge,
			expirationDate: getExpirationDate(orderData),
			...initialValues,
		},
		validationSchema: Yup.object().shape({
			contract: Yup.object().requiredOption(requiredMsg),
			commodity: Yup.object().requiredOption(requiredMsg),
			location: Yup.object().requiredOption(requiredMsg),
			deliveryLocation: Yup.object().requiredOption(requiredMsg),
			assignedRegion: Yup.object().requiredOption(requiredMsg),
			deliveryDate: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateCustom(mode),
				then: Yup.mixed().required(requiredMsg),
			}),
			deliveryDateWindow: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateWindow(mode),
				then: Yup.object().requiredOption(requiredMsg),
			}),
			employee: Yup.object().requiredOption(requiredMsg),
			postedBasis: Yup.number()
				.required(requiredMsg)
				.typeError(numberMsg)
				.test('priceValidation', basisFormatError, (value: string) =>
					validatePrice(value),
				),
			pushBasis: Yup.number().typeError(numberMsg).nullable(),
			netBasisPrice: Yup.number().typeError(numberMsg).required(requiredMsg),
			futuresMonth: Yup.object().requiredOption(requiredMsg),
			...validationSchema,
		}),
		elements: [
			<ContractType resetDependenciesOnChange={false} disableBuySell={true} />,
			<Commodity checkDefaultValues disabled />,
			<Location />,
			<DeliveryLocation />,
			<AssignedRegion />,
			<CropYears disabled />,
			<DeliveryDates checkDefaultValues />,
			<Futures
				checkDefaultValues
				futuresMonth={{ market: true, disableWithinDeliveryMode: true }}
				futuresPrice={{ market: false, disabled: true }}
			/>,
			<Basis disabled={{ netBasis: true }} adjustedBasis datesModeDependency />,
			<Fees />,
			<NetBasis disabled />,
			<ExpirationDate />,
			<Employee />,
		]
			.concat(elements)
			.concat([<Comments />]),
	};
};
