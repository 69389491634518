import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

// initialize state
const selectDomain = (state: RootState) =>
	state.cashBidsAndQuotes || initialState;

export const activeCommoityInFuture = createSelector(
	[selectDomain],
	(activeCommoityInFutureState) =>
		activeCommoityInFutureState.activeState.commodity,
);

export const selectSelectedFilters = createSelector(
	[selectDomain],
	(futureQouteState) => futureQouteState.selectedFilters,
);

export const selectFuturesQoutesData = createSelector(
	[selectDomain],
	(futureQouteState) => futureQouteState.futureQuoteList.data,
);

export const selectFuturesQoutesLoading = createSelector(
	[selectDomain],
	(futureQouteState) => futureQouteState.futureQuoteList.loading,
);

export const selectFuturesQoutesLastUpdatedTime = createSelector(
	[selectDomain],
	(futureQouteState) => futureQouteState.futureQuoteList.lastUpdateTime,
);

export const selectProductList = createSelector(
	[selectDomain],
	(futureQouteState) => futureQouteState.productList.data,
);

export const selectCashbidsData = createSelector(
	[selectDomain],
	(cashbidsState) => cashbidsState.cashBidsList.data,
);

export const selectCashbidsDataLoading = createSelector(
	[selectDomain],
	(cashbidsState) => cashbidsState.cashBidsList.loading,
);

export const selectCashbidsLastUpdatedTime = createSelector(
	[selectDomain],
	(cashbidsState) => cashbidsState.cashBidsList.lastUpdateTime,
);
