import React, { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { RadioButtons } from 'app/components/RadiosButtons';
import { translations } from 'locales/i18n';

import { selectHedgeIntegration } from '../../selectors';
import { HEDGE_EXECUTION_TYPE } from '../../types';

interface Props {
	disabled?: boolean;
}

export const HedgeExecutionType = memo(({ disabled }: Props) => {
	const { t: translate } = useTranslation();
	const { setValue, watch } = useFormContext();
	const [executionType, setExecutionType] = useState<string>('');
	const hedgeIntegration = useSelector(selectHedgeIntegration);
	const hedgeExecutionType = watch('executionType');
	const msg =
		translations.app.containers.Settings.sections.ERPIntegration.components
			.HedgeExecutionType;

	const handleChange = (value: string) => {
		setExecutionType(value);
	};

	const handleOnChangeSetTime = (value) => {
		const newValue = typeof value === 'string' ? value : '';
		setValue('setTime', newValue);
	};

	useEffect(() => {
		const initExecutionType = hedgeIntegration?.isHedgeInstant
			? HEDGE_EXECUTION_TYPE.instant
			: HEDGE_EXECUTION_TYPE.setTime;
		setExecutionType(initExecutionType);
	}, []);

	useEffect(() => {
		setExecutionType(hedgeExecutionType);
	}, [hedgeExecutionType]);

	return (
		<>
			<GenericForm.FormItem name="executionType" onChange={handleChange}>
				<RadioButtons
					disabled={disabled}
					name="executionType"
					key="executionType"
					options={[
						{
							label: translate(msg.action.setTime),
							value: HEDGE_EXECUTION_TYPE.setTime,
						},
						{
							label: translate(msg.action.instant),
							value: HEDGE_EXECUTION_TYPE.instant,
						},
					]}
				/>
			</GenericForm.FormItem>
			{executionType === HEDGE_EXECUTION_TYPE.setTime && (
				<GenericForm.FormItem
					customClassName={['setTime-input']}
					label={translate(msg.label)}
					name="setTime"
				>
					<Input
						onChange={handleOnChangeSetTime}
						type="time"
						name="setTime"
						placeholder={translate(msg.placeholder)}
					/>
				</GenericForm.FormItem>
			)}
		</>
	);
});
