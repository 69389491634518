import { takeLatest } from 'redux-saga/effects';

import { getCommoditiesList } from './Sagas/commodities-sagas';
import { createCommodity } from './Sagas/createCommodity-saga';
import { editCommodity, getCommodityById } from './Sagas/editCommodity-saga';
import {
	downloadEmptyHedgeMapTemplateRequest,
	downloadFilledUpdateHedgemapRequest,
	uploadCreateHedgeMapTemplate,
	uploadEditHedgeMapTemplate,
} from './Sagas/hedge-map-saga';
import { getProductsList } from './Sagas/products-saga';
import { setCommodityStatus } from './Sagas/set-commodity-status-saga';
import { actions } from './slice';

export function* commoditiesSaga() {
	yield takeLatest(actions.loadCommoditiesList.type, getCommoditiesList);
	yield takeLatest(actions.setCommodityStatus.type, setCommodityStatus);

	yield takeLatest(actions.loadProducts.type, getProductsList);
	yield takeLatest(actions.editCommodity.type, editCommodity);

	yield takeLatest(
		actions.uploadCreateHedgeMapTemplate.type,
		uploadCreateHedgeMapTemplate,
	);
	yield takeLatest(
		actions.uploadEditHedgeMapTemplate.type,
		uploadEditHedgeMapTemplate,
	);

	yield takeLatest(actions.createCommodity.type, createCommodity);
	yield takeLatest(actions.setActiveCommodity.type, getCommodityById);

	// Gives us empty headgemap template
	yield takeLatest(
		actions.loadHedgeMapTemplate.type,
		downloadEmptyHedgeMapTemplateRequest,
	);

	// Gives us filled headgemap template
	yield takeLatest(
		actions.loadFilledHedgemapTemplate.type,
		downloadFilledUpdateHedgemapRequest,
	);
}
