import { Select } from 'antd';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectCommoditiesList } from 'app/containers/GlobalSaga/selectors';
import { actions } from 'app/containers/LiveLedger/slice';
import { GenericItem } from 'types/GenericItem';
import { GenericOption } from 'types/GenericOption';
import { SetPersistedData } from 'utils/helpers';

import { PERSISTED_FILTER } from '../../constants';
import { selectSelectedCommodity } from '../../selectors';

interface Props {}

const { Option } = Select;

const RenderOption = ({ name }: { name: string }) => {
	return <div>{name}</div>;
};

export const CommoditiesFilter = memo((props: Props) => {
	const selectedCommodityId = useSelector(selectSelectedCommodity);
	const commoditiesList: GenericItem[] = useSelector(selectCommoditiesList);
	const selectedCommodity =
		commoditiesList?.find((c) => c.id === selectedCommodityId) ??
		commoditiesList?.[0];

	const dispatch = useDispatch();

	useEffect(() => {
		if (
			commoditiesList?.[0] &&
			(!selectedCommodityId ||
				!commoditiesList.some((c) => c.id === selectedCommodityId))
		) {
			SetPersistedData(PERSISTED_FILTER.COMMODITY, commoditiesList[0].id);
			dispatch(
				actions.changeSelectedFilters({
					selectedCommodity: commoditiesList[0].id,
					selectedCropYear: null,
				}),
			);
		}
		dispatch(actions.loadLiveLedgerList());
	}, [commoditiesList, selectedCommodityId, dispatch]);

	const handleChange = (option: GenericOption) => {
		SetPersistedData(PERSISTED_FILTER.COMMODITY, option.value);
		dispatch(
			actions.changeSelectedFilters({
				selectedCommodity: option.value,
				selectedCropYear: null,
			}),
		);
		dispatch(actions.loadLiveLedgerList());
	};

	return (
		selectedCommodity && (
			<Select
				className="select"
				data-testid="commodities-list-filter"
				optionLabelProp="label"
				labelInValue={true}
				defaultValue={{
					label: selectedCommodity.name,
					value: selectedCommodity.id,
				}}
				onChange={handleChange}
			>
				{commoditiesList.map((option) => (
					<Option
						key={option.id}
						value={option.id}
						label={option.name}
						data-testid={`commodity-option-${option.id}`}
					>
						<RenderOption name={option.name} />
					</Option>
				))}
			</Select>
		)
	);
});
