import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import { GenericLoadListPayload } from '../types';

const { customersRoot } = apiEndpoints;

export function* getCustomersList(
	action: PayloadAction<GenericLoadListPayload>,
) {
	const source = action.payload.source;

	let requestURL = yield new URL(`${apiRoutes.base}/${customersRoot}`);
	requestURL.searchParams.append('name', action.payload.search);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		const payload = {
			source,
			data: responseData.customers,
		};
		yield put(actions.customersListLoaded(payload));
	} else if (!!responseError.detail) {
		const payload = {
			source,
			error: responseError,
		};
		yield put(actions.customersListError(payload));
	}
}
