import { Table as TableAntd } from 'antd';
import { TableProps } from 'antd/lib/table';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterEmpty } from 'app/components/FilterEmpty';
import { translations } from 'locales/i18n';

import { ResizeObserverWrapper } from '../ResizeObserverWrapper';

// Scroll property is managed by the component
interface Props extends Omit<TableProps<any>, 'scroll' | 'dataSource'> {
	rowKey?: any;
	useVirtualScroll?: boolean;
	otherClassName?: string;
	selectedFilters?: object;
	emptyText?: string;
	data: any[];
}

export const Table = (props: Props) => {
	const {
		data,
		loading = false,
		pagination,
		otherClassName,
		emptyText,
		selectedFilters = {},
		rowKey = 'id',
		useVirtualScroll = true,
	} = props;

	const { t: translate } = useTranslation();

	// this will be set by ResizeObserverWrapper, but having a reasonable default helps prevent loading jank
	const [_scrollbar, setScrollBar] = useState<number>(window.innerHeight - 250);
	const scrollbar = loading ? undefined : _scrollbar;

	const getEmptyMessage = () => {
		if (!loading) {
			if (Object.values(selectedFilters).some((filter) => !!filter)) {
				return <FilterEmpty description={emptyText} />;
			}
			return false;
		}
	};

	const handleRefChange = useCallback((scrollHeight) => {
		if (!isNaN(scrollHeight) && scrollHeight > 0) {
			setScrollBar(scrollHeight);
		}
		return () => {
			setScrollBar(0);
		};
	}, []);

	return (
		<ResizeObserverWrapper
			onRefChange={handleRefChange}
			containerClassName={otherClassName}
		>
			<TableAntd
				{...props}
				dataSource={data}
				rowKey={rowKey}
				scroll={{
					x: 'max-content',
					y: useVirtualScroll ? scrollbar : 'max-content',
				}}
				size="small"
				pagination={
					pagination && {
						showSizeChanger: true,
						showTotal: (total, range) =>
							translate(translations.app.components.GenericTable.showTotal, {
								range1: range[0],
								range2: range[1],
								total: total,
							}),
						role: 'table-pagination',
						...pagination,
					}
				}
				locale={{
					emptyText: getEmptyMessage(),
				}}
				data-testid="generic-table"
			/>
		</ResizeObserverWrapper>
	);
};
