import { Switch } from 'antd';
import React, { memo } from 'react';
import { useAbac } from 'react-abac';

import { GenericForm } from 'app/components/GenericForm';
import { NumberInput } from 'app/components/NumberInput';
import { UseFieldTranslations } from 'app/containers/Settings/definitions/fields/translations';
import { Permission } from 'types/Authorization';

const isMobileAppEnable = 'isMobileAppEnable';
const mobileAppLimit = 'mobileAppLimit';
const mobileApp = 'mobileApp';

export const MobileApp = memo(() => {
	const translations = UseFieldTranslations();
	const { userHasPermissions } = useAbac();

	const hasMobileAppEnabled = userHasPermissions(
		Permission.CONTRACTSERVICE_CUSTOMER_MOBILEAPPENABLE,
	);

	if (!hasMobileAppEnabled) {
		return null;
	}

	return (
		<GenericForm.FormItem
			data-testid={`${mobileApp}-form-item`}
			label={translations[isMobileAppEnable].label}
			name={mobileApp}
		>
			<GenericForm.FormItem
				data-testid={`${isMobileAppEnable}-form-item`}
				name={isMobileAppEnable}
			>
				<Switch key={isMobileAppEnable} />
			</GenericForm.FormItem>
			<GenericForm.FormItem
				data-testid={`${mobileAppLimit}-form-item`}
				name={mobileAppLimit}
			>
				<NumberInput
					arrowsHidden
					name={mobileAppLimit}
					placeholder={translations[mobileAppLimit].label}
				/>
			</GenericForm.FormItem>
		</GenericForm.FormItem>
	);
});
