import './style.scss';

import { Col, Row } from 'antd';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FilterMultiple } from 'app/components/FilterMultiple';
import {
	selectProductList,
	selectSelectedFilters,
} from 'app/containers/CashbidsAndQuotes/selectors';
import { actions } from 'app/containers/CashbidsAndQuotes/slice';
import { commodities, Product } from 'app/containers/CashbidsAndQuotes/types';
import { translations } from 'locales/i18n';
import { FILTERS_FOR_ORIGINATOR_SCREEN } from 'utils/constants';
import {
	GetPersistedData,
	mapToLabelValue,
	UpdatePersistedData,
} from 'utils/helpers';

const allDefaultOption = {
	id: 'all',
	name: '',
};

export const Filters = memo(() => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const productsList = useSelector(selectProductList);
	const selectedFilters = useSelector(selectSelectedFilters);

	const persistedFilters = GetPersistedData<null | typeof selectedFilters>(
		FILTERS_FOR_ORIGINATOR_SCREEN,
		null,
	);

	const translationsScope =
		translations.app.containers.CashbidsAndQuotes.FuturesQoutes;

	// Apply persisted filters
	useEffect(() => {
		dispatch(actions.loadProductList());
		if (persistedFilters?.products?.length === 0) return;
		dispatch(actions.setSelectedFilters(persistedFilters));
		dispatch(actions.loadFutureQuotes());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// this is to convert the product list to desired data structure
	function convertStructure(originalArray: Product[]) {
		const convertedArray = originalArray.map((item) => ({
			name: item.fullDescription,
			id: item.id,
		}));

		return convertedArray;
	}

	const allProductOptions = {
		...allDefaultOption,
		name: translate(translationsScope.products),
	};

	const productOptions = mapToLabelValue([
		allProductOptions,
		...convertStructure(productsList),
	]) as {
		label: string;
		value: any;
		type: string;
	}[];

	const handleChangeProduct = (selectedValues: commodities[]) => {
		UpdatePersistedData(FILTERS_FOR_ORIGINATOR_SCREEN, selectedFilters, {
			products: selectedValues,
		});
		dispatch(
			actions.setSelectedFilters({
				products: selectedValues,
			}),
		);
		if (selectedValues.length === 0) return;
		dispatch(actions.loadFutureQuotes());
	};

	return (
		<Row gutter={16}>
			<Col className="grid-menu-label" flex="none">
				{translate(translations.app.containers.Contracts.ListMenu.label)}
			</Col>
			<Col span={3}>
				<FilterMultiple
					currentOptions={selectedFilters.products}
					handleChange={handleChangeProduct}
					options={productOptions}
					placeholder={translate(translationsScope.products)}
				/>
			</Col>
		</Row>
	);
});
