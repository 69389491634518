import React, { useEffect, useState } from 'react';
import { useAbac } from 'react-abac';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FormFooterButtons } from 'app/components/FormFooterButtons';
import { GenericViewForm } from 'app/components/GenericViewForm';
import { selectFeatureFlags } from 'app/containers/GlobalSaga/selectors';
import {
	allEditNoQuantityLabels,
	allEditNoQuantityPermission,
	allEditQuantityLabels,
	allEditQuantityPermission,
	ContractType,
	contractTypeList,
	functionsPermission,
	offerTypeList,
	roleCreationList,
	roleSectionsList,
	ScreenPermission,
	screenPermissionList,
} from 'app/containers/Settings/types';
import { translations } from 'locales/i18n';
import { Permission } from 'types/Authorization';
import { FeatureFlag } from 'types/FeatureFlags';
import { useFeatureFlags } from 'utils/hooks/useFeatureFlags';

import { useFieldFactory } from '../../../../definitions/fields';
import { UseFieldTranslations } from '../../../../definitions/fields/translations';
import { ViewLocation } from '../../Modals/Locations/ViewLocation';
import {
	selectActiveEmployee,
	selectActiveEmployeeUserID,
	selectEmployeeLimit,
	selectEmployeeLocations,
	selectEmployeePermissionsData,
	selectEmployeePermissionsLoading,
} from '../../selectors';
import { actions } from '../../slice';
import { View } from '../../types';
import { useRolesLocationsByEmployee } from './hooks/useRolesLocationsByEmployee';

export const EditRoleAndPermission = () => {
	const { t: translate } = useTranslation();
	const fieldsTranslations = UseFieldTranslations();
	const dispatch = useDispatch();
	const fieldList = useFieldFactory();

	const activeEmployee = useSelector(selectActiveEmployee);
	const activeEmployeeID = useSelector(selectActiveEmployeeUserID);
	const permissionsList = useSelector(selectEmployeePermissionsData);
	const permissionsLoading = useSelector(selectEmployeePermissionsLoading);
	const employeeList = useSelector(selectEmployeeLocations);
	const featureFlags = useSelector(selectFeatureFlags);

	const employeeLimit = useSelector(selectEmployeeLimit);

	const domain =
		translations.app.containers.Settings.sections.Employees.Views
			.RolesAndPermission;

	const [userRoleSchema, setUserRoleSchema] = useState<any>(() => null);
	const [employeeName] = useState<string>(
		() => `${activeEmployee.firstName} ${activeEmployee.lastName}`,
	);
	const { isEmployeeGroupedLocationPermissionEnabled } = useFeatureFlags();
	const locationsSchema = useRolesLocationsByEmployee();

	const { userHasPermissions } = useAbac();

	const hasNTCPermission = userHasPermissions(
		Permission.CONTRACTSERVICE_ALLOW_CONTRACTNTC,
	);

	let contractTypes = contractTypeList;
	//if user does not have permission to view NTC from admin side then remove NTC from settings.
	if (!hasNTCPermission) {
		contractTypes = contractTypeList.filter((item) => {
			return (
				item !== contractTypeList.find((item) => item === ContractType.NTC)
			);
		});
	}

	const hasOriginatorPermission = userHasPermissions(
		Permission.CONTRACTSERVICE_ORIGINATOR_BIDS_QUOTES_TENANT_VIEW,
	);
	let screenPermissionsList: ScreenPermission[] = screenPermissionList;

	//if user does not have permission to view The Originator Screen then remove The Originator Screen from sidebar.
	if (!hasOriginatorPermission) {
		screenPermissionsList = screenPermissionList.filter((item) => {
			return (
				item !==
				screenPermissionList.find(
					(item) => item === ScreenPermission.ORIGINATOR_SCREEN,
				)
			);
		});
	}
	if (!featureFlags[FeatureFlag.enableHedgeIntegration]) {
		screenPermissionsList = screenPermissionList.filter((item) => {
			return !(
				item === ScreenPermission.FUTURESERP_SCREEN ||
				item === ScreenPermission.TRADEBOOK_SCREEN
			);
		});
	}
	useEffect(() => {
		if (!!permissionsList && employeeLimit !== null) {
			setUserRoleSchema([
				...contractTypes.map((field) => setInitialValues(field)),
				...offerTypeList.map((field) => setInitialValues(field)),
				...screenPermissionsList.map((field) => setInitialValues(field)),
				locationsSchema.rolesComponent,
				{
					...fieldList.bushelsLimit,
					initialValue: employeeLimit,
				},
				setInitialValues(functionsPermission.CANCEL),
				setInitialValues(functionsPermission.DO_NOT_HEDGE),
				allEdit(
					functionsPermission.EDIT_NO_QUANTITY,
					allEditNoQuantityPermission,
				),
				allEdit(functionsPermission.EDIT_QUANITY, allEditQuantityPermission),
				setInitialValues(functionsPermission.RESEND_TO_ERP),
				setInitialValues(functionsPermission.SALES_TRADING),
				setInitialValues(functionsPermission.MOBILEAPP),
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [permissionsList, employeeList, employeeLimit]);

	useEffect(() => {
		return () => {
			dispatch(actions.setCurrentView(View.List));
			dispatch(actions.resetActiveEmployee());
			dispatch(actions.clearEmployeeRoles());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(
			actions.getEmployeeLocations({
				id: activeEmployee.id,
				isLegacyLocations: !isEmployeeGroupedLocationPermissionEnabled,
			}),
		);
		dispatch(actions.getEmployeeLimit({ email: activeEmployee.email }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setInitialValues = (field) => {
		const foundPermission = permissionsList?.find(
			(permission) => permission.name === Permission[field],
		);

		return {
			...fieldList[field],
			initialValue: !!foundPermission,
			disabled: foundPermission?.fromRole,
		};
	};

	const allEdit = (fieldName, innerPermissions) => {
		const allPermissionsChecked = innerPermissions.every((innerPermission) =>
			permissionsList?.some(
				(permission) => permission.name === innerPermission,
			),
		);

		return {
			...fieldList[fieldName],
			initialValue: allPermissionsChecked,
			disabled: innerPermissions.some((innerPermission) =>
				permissionsList?.some(
					(permission) =>
						permission.name === innerPermission && permission.fromRole,
				),
			),
		};
	};

	const handleOnSubmit = (values) => {
		let checkedPermission: string[] = [];
		Object.entries(values).forEach(([key, value]) => {
			const foundPermission = permissionsList?.find(
				(permission) =>
					permission.name === Permission[key] && permission.fromRole,
			);

			if (typeof value === 'boolean' && value && !foundPermission) {
				if (key === functionsPermission.EDIT_NO_QUANTITY) {
					checkedPermission = [
						...checkedPermission,
						...allEditNoQuantityLabels,
					];
				} else if (key === functionsPermission.EDIT_QUANITY) {
					checkedPermission = [...checkedPermission, ...allEditQuantityLabels];
				} else {
					checkedPermission.push(key);
				}
			}
		});

		const claimGrantList = checkedPermission.map(
			(permission) => Permission[permission],
		);

		const dataTransferObject = {
			userId: activeEmployeeID,
			claimGrants: claimGrantList,
		};

		const locations = Object.values(values.rolesLocations);

		dispatch(
			actions.updateEmployeeLocations({
				isLegacyLocations: !isEmployeeGroupedLocationPermissionEnabled,
				data: {
					employeeId: activeEmployee?.id,
					locations,
				},
			}),
		);
		dispatch(
			actions.updateEmployeePermissions({
				data: dataTransferObject,
				successMessage: translate(domain.toastSuccessEditMessage, {
					name: employeeName,
				}),
			}),
		);

		dispatch(
			actions.updateEmployeeLimit({
				data: {
					userEmail: activeEmployee.email,
					limit: values.bushelsLimit,
				},
			}),
		);
	};

	const handleOnClose = () => {
		dispatch(actions.setCurrentView(View.List));
		dispatch(actions.resetActiveEmployee());
	};

	const translateList = (list) =>
		list.map((section) => ({
			label: fieldsTranslations.createEditRoleView.sections[section],
			value: section,
		}));
	const tabTitles = {
		tab1: 'Role Permissions',
		tab2: 'Role Limits',
	};
	return (
		userRoleSchema && (
			<>
				<GenericViewForm
					header={<b>{employeeName}</b>}
					secondTabSectionsList={translateList(roleCreationList)}
					formDefinition={userRoleSchema}
					sectionsList={translateList(roleSectionsList)}
					handleSubmit={handleOnSubmit}
					handleClose={handleOnClose}
					tabTitles={tabTitles}
					footerButtons={
						<FormFooterButtons
							isLoading={permissionsLoading}
							cancelLabel={translate(domain.forgetChanges)}
							submitLabel={translate(domain.saveRoleAndPermission)}
							closeModal={handleOnClose}
						/>
					}
					newLocationElement={<ViewLocation />}
				/>
			</>
		)
	);
};
