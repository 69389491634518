import './style.scss';

import { SwapRightOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import classNames from 'classnames';
import { useField } from 'formik';
import { Form } from 'formik-antd';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';

interface Props {
	name: string;
	format?: string;
	label?: string;
	getDisabledDateRange?: any;
	startDatePlaceholder?: string;
	endDatePlaceholder?: string;
	disabled?: boolean;
}

export const FormDateRangePickerLegacy = memo((props: Props) => {
	const {
		name,
		format,
		label,
		getDisabledDateRange,
		startDatePlaceholder,
		endDatePlaceholder,
		disabled = false,
	} = props;

	const [, meta, helpers] = useField(name);

	const [startDateValue, setStartDateValue]: any = useState();
	const [endDateValue, setEndDateValue]: any = useState();

	const [pickerStartDate, setPickerStartDate]: any = useState();
	const [pickerEndDate, setPickerEndDate]: any = useState();

	const [startPickerKey, setStartPickerKey] = useState(0);
	const [endPickerKey, setEndPickerKey] = useState(0);

	const [startOpen, setStartOpen] = useState(false);
	const [endOpen, setEndOpen] = useState(false);

	useEffect(() => {
		if (!!meta && meta.value) {
			setStartDateValue(meta.value[0]);
			setEndDateValue(meta.value[1]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!!getDisabledDateRange) {
			const disabledDates = getDisabledDateRange && getDisabledDateRange();
			if (!!disabledDates) {
				setPickerStartDate(
					disabledDates.min > moment() ? disabledDates.min : moment(),
				);
			}
			if (!!meta && meta.value?.length) {
				setPickerStartDate(meta.value[0]);
				setPickerEndDate(meta.value[1]);
			} else if (startDateValue && endDateValue) {
				setStartPickerKey(startPickerKey + 1);
				setStartDateValue(null);
				setEndDateValue(null);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getDisabledDateRange]);

	const onStartChange = (value) => {
		const endDate = endDateValue;
		setStartDateValue(value);

		if (!!!endDateValue || (!!endDate && value > endDate)) {
			setPickerEndDate(value);
			setEndPickerKey(endPickerKey + 1);
			setEndOpen(true);
			setEndDateValue(null);
			helpers.setValue(null);
			setStartDateValue(value);
		} else {
			helpers.setValue([value, endDateValue]);
		}

		setStartOpen(false);
	};

	const onEndChange = (value) => {
		setEndDateValue(value);
		if (value && !!startDateValue) {
			helpers.setValue([startDateValue, value]);
		} else {
			setStartPickerKey(startPickerKey + 1);
			setStartDateValue(null);
			helpers.setValue(null);
		}

		if (value && !!!startDateValue) {
			setStartOpen(true);
		}
	};

	const getEndDisabledDates = (current) => {
		if (startDateValue) {
			const newStartValue: any = startDateValue;
			const disabledDates = getDisabledDateRange && getDisabledDateRange();
			return current < newStartValue || current > disabledDates.max;
		} else {
			return getStartDisabledDates(current);
		}
	};

	const getStartDisabledDates = (current) => {
		if (!!getDisabledDateRange) {
			const disabledDates = getDisabledDateRange && getDisabledDateRange();
			if (!!disabledDates) {
				return current < disabledDates.min || current > disabledDates.max;
			}
		}

		return true;
	};

	const toggleEndOpen = () => {
		if (!startDateValue) {
			setStartOpen(true);
		} else {
			setEndOpen(!endOpen);
		}
	};

	const toggleStartOpen = () => {
		setStartOpen(!startOpen);
	};

	return (
		<Form.Item label={label} key={name} name={name} className="calendar__label">
			<div
				className={classNames('form-date-range-picker', {
					'form-date-range-picker--disabled': disabled,
				})}
			>
				<DatePicker
					allowClear={false}
					bordered={false}
					className="form-date-range-picker__start"
					defaultPickerValue={pickerStartDate}
					disabled={disabled || !getDisabledDateRange}
					disabledDate={getStartDisabledDates}
					format={format}
					open={startOpen}
					onOpenChange={toggleStartOpen}
					onChange={onStartChange}
					placeholder={startDatePlaceholder}
					showToday={false}
					suffixIcon={<SwapRightOutlined />}
					value={startDateValue}
					key={`startKey-${startPickerKey}`}
				/>

				<DatePicker
					bordered={false}
					className="form-date-range-picker__end"
					defaultPickerValue={pickerEndDate}
					disabled={disabled || !getDisabledDateRange}
					disabledDate={getEndDisabledDates}
					format={format}
					onChange={onEndChange}
					onOpenChange={toggleEndOpen}
					open={endOpen}
					placeholder={endDatePlaceholder}
					showToday={false}
					key={`endKey-${endPickerKey}`}
					value={endDateValue}
				/>
			</div>
		</Form.Item>
	);
});
