import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FilterDate } from 'app/components/FilterDate';
import { FilterInput } from 'app/components/FilterInput';
import { FilterMultiple } from 'app/components/FilterMultiple';
import { selectPreHedgeStatus } from 'app/containers/GlobalSaga/selectors';
import {
	selectFilters,
	selectFuturesMonthsList,
} from 'app/containers/PreHedge/selectors';
import { actions } from 'app/containers/PreHedge/slice';
import { PreHedgeFilters } from 'app/containers/PreHedge/types';
import { productToLabelValue } from 'app/containers/ReviewAndRelease/utils/helpers';
import { selectProductsList } from 'app/containers/Settings/sections/Commodities/selectors';
import { actions as commoditiesActions } from 'app/containers/Settings/sections/Commodities/slice';
import { translations } from 'locales/i18n';
import { GenericOption } from 'types/GenericOption';
import { allDefaultOption } from 'utils/constants';
import {
	dateMoment,
	mapToLabelValue,
	mapToLabelValueObject,
} from 'utils/helpers';

const translationsScope =
	translations.app.containers.ReviewAndRelease.components.Views.List.Menu
		.Filters;

export const Filters = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const productsList = useSelector(selectProductsList).product;
	const futuresMonthsList = useSelector(selectFuturesMonthsList);
	const filters = useSelector(selectFilters);
	const statusOptions = useSelector(selectPreHedgeStatus);

	useEffect(() => {
		dispatch(actions.getFuturesMonthsRequest());
		dispatch(commoditiesActions.loadProducts());
		dispatch(
			actions.setPagination({
				start: 1,
			}),
		);
		dispatch(actions.loadPreHedgeList(true));
	}, []);

	const allFuturesMonthsOption = {
		...allDefaultOption,
		name: translate(translationsScope.futuresMonthAll),
	};
	const futuresMonthsOptions = [
		mapToLabelValueObject(allFuturesMonthsOption),
		...futuresMonthsList,
	];

	const allStatusOption = {
		...allDefaultOption,
		name: translate(translationsScope.States.All),
	};
	const statesOptions: GenericOption[] = mapToLabelValue([
		allStatusOption,
		...(statusOptions.data || []),
	]);

	const allProductsOption = {
		label: translate(translationsScope.symbolAll),
		value: 'all',
	};
	const productsOptions = [
		allProductsOption,
		...productToLabelValue(productsList || []),
	];

	const handleChangeField = (obj: PreHedgeFilters) => {
		dispatch(actions.setSelectedFilters(obj));
		dispatch(
			actions.setPagination({
				start: 1,
			}),
		);
		dispatch(actions.loadPreHedgeList(true));
	};

	const handleChangeStates = (selectedStatus) => {
		const status =
			!!selectedStatus && !!selectedStatus.length
				? selectedStatus
				: [statesOptions[0]];
		handleChangeField({ status: status });
	};

	const handleChangeStartDate = (selectedDate) => {
		handleChangeField({ startDate: selectedDate || null });
	};

	const handleChangeEndDate = (selectedDate) => {
		handleChangeField({ endDate: selectedDate || null });
	};

	const handleChangeAccountName = (value) => {
		handleChangeField({ accountName: value || null });
	};

	const handleChangeFuturesMonth = (value) => {
		handleChangeField({ futuresMonth: value || null });
	};

	const handleChangeSymbol = (value) => {
		handleChangeField({ symbol: value || null });
	};

	const handleChangeNumber = (value) => {
		handleChangeField({ number: value || null });
	};

	return (
		<>
			<section className="grid-main-filters">
				<div className="grid-menu-label">
					{translate(translationsScope.title)}
				</div>
				<Row gutter={[8, 8]}>
					<Col xs={12} md={6} xl={3}>
						<FilterMultiple
							currentOptions={
								!!filters.status &&
								filters.status?.findIndex(
									(element) => element.value === 'all',
								) < 0
									? filters.status
									: []
							}
							options={statesOptions}
							handleChange={handleChangeStates}
							placeholder={translate(translationsScope.States.All)}
						/>
					</Col>
					<Col xs={12} md={3} lg={3} xl={2}>
						<FilterDate
							name="startDate"
							handleChange={handleChangeStartDate}
							defaultValue={dateMoment(
								!!filters.startDate ? filters.startDate : new Date(),
							)}
							placeholder={translate(translationsScope.startDate)}
							currentDate={
								!!filters.startDate ? dateMoment(filters.startDate) : null
							}
						/>
					</Col>
					<Col xs={12} md={3} lg={3} xl={2}>
						<FilterDate
							name="endDate"
							currentDate={
								!!filters.endDate ? dateMoment(filters.endDate) : null
							}
							handleChange={handleChangeEndDate}
							placeholder={translate(translationsScope.endDate)}
						/>
					</Col>
					<Col xs={12} md={6} lg={3} xl={2}>
						<FilterInput
							onSearch={handleChangeAccountName}
							placeholder={translate(translationsScope.accountName)}
							currentOption={filters.accountName || ''}
						/>
					</Col>
					<Col xs={12} md={6} lg={3} xl={2}>
						<FilterInput
							onSearch={handleChangeNumber}
							placeholder={translate(translationsScope.number)}
							currentOption={filters.number || ''}
						/>
					</Col>
					<Col xs={12} md={6} lg={3} xl={2}>
						<FilterMultiple
							handleChange={handleChangeFuturesMonth}
							placeholder={translate(translationsScope.futuresMonth)}
							options={futuresMonthsOptions}
							currentOptions={filters.futuresMonth || []}
						/>
					</Col>
					<Col xs={12} md={6} lg={3} xl={2}>
						<FilterMultiple
							handleChange={handleChangeSymbol}
							placeholder={translate(translationsScope.symbol)}
							options={productsOptions}
							currentOptions={filters.symbol || []}
						/>
					</Col>
				</Row>
			</section>
		</>
	);
};
