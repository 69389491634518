import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { UseFieldTranslations } from 'app/containers/Settings/definitions/fields/translations';
import { mapToLabelValue } from 'utils/helpers';

import { selectCountriesList, selectStatesList } from '../../selectors';

const address = 'address';
const city = 'city';
const country = 'country';
const zipCode = 'zipCode';
const state = 'state';

export const Address = memo(() => {
	const countriesList = useSelector(selectCountriesList).data;
	const statesList = useSelector(selectStatesList).data;
	const translations = UseFieldTranslations();

	return (
		<>
			<GenericForm.FormItem
				data-testid={`${address}-form-item`}
				label={translations[address].label}
				name={address}
			>
				<Input
					data-testid={`${address}-input-item`}
					name={address}
					placeholder={translations[address].placeholder}
				/>
			</GenericForm.FormItem>
			<GenericForm.FormItem
				data-testid="cityAndState-form-item"
				name="cityAndState"
				wrapperCol={{ offset: 3 }}
			>
				<GenericForm.FormItem data-testid={`${city}-form-item`} name={city}>
					<Input name={city} placeholder={translations.cityPlaceholder} />
				</GenericForm.FormItem>
				<GenericForm.FormItem data-testid={`${state}-form-item`} name={state}>
					<Dropdown
						options={mapToLabelValue(statesList)}
						placeholder={translations.statePlaceholder}
					/>
				</GenericForm.FormItem>
			</GenericForm.FormItem>
			<GenericForm.FormItem
				data-testid="zipAndCountry-form-item"
				name="zipAndCountry"
				wrapperCol={{ offset: 3 }}
			>
				<GenericForm.FormItem
					data-testid={`${zipCode}-form-item`}
					name={zipCode}
				>
					<Input
						data-testid={`${zipCode}-input-item`}
						maxLength={5}
						name={zipCode}
						placeholder={translations.zipCodePlaceholder}
					/>
				</GenericForm.FormItem>
				<GenericForm.FormItem
					data-testid={`${country}-form-item`}
					name={country}
				>
					<Dropdown
						options={mapToLabelValue(countriesList)}
						placeholder={translations.countryPlaceholder}
					/>
				</GenericForm.FormItem>
			</GenericForm.FormItem>
		</>
	);
});
