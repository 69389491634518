import { Switch } from 'antd';
import React from 'react';

import { GenericForm } from 'app/components/GenericForm';
import { UseFieldTranslations } from 'app/containers/Settings/definitions/fields/translations';

const isEmailNotificationEnabled = 'isEmailNotificationEnabled';
const isSmsNotificationEnabled = 'isSmsNotificationEnabled';

export const Notify = () => {
	const translations = UseFieldTranslations();

	return (
		<GenericForm.FormItem
			data-testid="notifications-form-item"
			label={translations.notifications.label}
			name="notifications"
		>
			<GenericForm.FormItem
				data-testid={`${isEmailNotificationEnabled}-form-item`}
				label={translations.email.label}
				name={isEmailNotificationEnabled}
			>
				<Switch key={isEmailNotificationEnabled} />
			</GenericForm.FormItem>
			<GenericForm.FormItem
				data-testid={`${isSmsNotificationEnabled}-form-item`}
				label={translations.sms.label}
				name={isSmsNotificationEnabled}
			>
				<Switch key={isSmsNotificationEnabled} />
			</GenericForm.FormItem>
		</GenericForm.FormItem>
	);
};
