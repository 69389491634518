import React from 'react';

import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { ExpirationDateGTC } from 'app/containers/Transactions/components/ExpirationDateGTC';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { HedgeAccount } from 'app/containers/Transactions/components/HedgeAccount';
import { NumberOfLots } from 'app/containers/Transactions/components/NumberOfLots';
import { OrderPrice } from 'app/containers/Transactions/components/OrderPrice';
import { PreHedgeOrderType } from 'app/containers/Transactions/components/PreHedgeOrderType';
import { ActionType } from 'types/ActionType';
import { PRE_HEDGE_ORDER_TYPES } from 'utils/constants';
import Yup from 'yupCustom';

import { useTranslations } from '../../../shared/useTranslations';

export const useLimitOrderSchema = () => {
	const translations = useTranslations();

	const { required: requiredMsg, number: numberMsg } = translations.validations;

	return {
		initialValues: {
			preHedgeOrderType: { value: PRE_HEDGE_ORDER_TYPES.limitOrder },
			action: ActionType.BUY,
			commodity: { value: null },
			cropYear: null,
			futuresMonth: { value: null },
			numberOfLots: null,
			orderPrice: null,
			gtcMode: false,
			expirationDateGTC: '',
			comments: '',
		},
		validationSchema: Yup.object().shape({
			preHedgeOrderType: Yup.object().requiredOption(requiredMsg),
			action: Yup.string().required(requiredMsg),
			commodity: Yup.object().requiredOption(requiredMsg),
			numberOfLots: Yup.string().typeError(numberMsg).required(requiredMsg),
			orderPrice: Yup.string().typeError(numberMsg).required(requiredMsg),
			expirationDateGTC: Yup.string()
				.nullable()
				.when('gtcMode', {
					is: false,
					then: Yup.string().required(requiredMsg),
					otherwise: Yup.string().nullable(),
				}),
			futuresMonth: Yup.object().requiredOption(requiredMsg),
			hedgeAccount: Yup.object().requiredOption(requiredMsg),
		}),
		elements: [
			<PreHedgeOrderType />,
			<Commodity reloadFuturesMonth checkDefaultValues />,
			<CropYears checkDefaultValues />,
			<Futures
				checkDefaultValues
				futuresMonth={{ market: true, disableWithinDeliveryMode: false }}
			/>,
			<NumberOfLots />,
			<OrderPrice />,
			<HedgeAccount />,
			<ExpirationDateGTC />,
		].concat([<Comments />]),
	};
};
