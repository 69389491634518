import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useHedgeIntegrationSwitchFormSchema } from '../schemas/useHedgeIntegrationSwitchFormSchema';
import {
	selectHedgeIntegration,
	selectHedgeIntegrationLoading,
} from '../selectors';

export const useHedgeIntegrationSwitchFormData = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const { elements, initialValues, validationSchema } =
		useHedgeIntegrationSwitchFormSchema();
	const resolver = yupResolver(validationSchema);
	const formInstance = useForm({
		defaultValues: {
			...initialValues,
		},
		mode: 'all',
		resolver,
	});
	const hedgeIntegration = useSelector(selectHedgeIntegration);
	const isLoading = useSelector(selectHedgeIntegrationLoading);

	return {
		dispatch,
		elements,
		hedgeIntegration,
		isLoading,
		formInstance,
		initialValues,
		translate,
		validationSchema,
	};
};
