import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) =>
	state.notificationSettings || initialState;

export const selectCurrentModal = createSelector(
	[selectDomain],
	(notificationSettingsState) => notificationSettingsState.currentModal,
);
export const selectCurrentView = createSelector(
	[selectDomain],
	(notificationSettingsState) => notificationSettingsState.currentView,
);

// Global Preferences
export const selectTenantNotificationSettings = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.tenantPreferences?.data,
);
export const selectTenantNotificationSettingsLoading = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.tenantPreferences?.loading,
);

// User Preferences
export const selectUserNotificationPreferences = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.userPreferences?.data,
);
export const selectUserNotificationPreferencesLoading = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.userPreferences?.loading,
);

export const selectNotificationAlerts = createSelector(
	[selectDomain],
	(notificationSettingsState) => notificationSettingsState.alertTones.data,
);
export const selectUserAssignedGroups = createSelector(
	[selectDomain],
	(notificationSettingsState) => notificationSettingsState.assignedGroups.data,
);
export const selectUserAssignedGroupsLoading = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.assignedGroups.loading,
);

// Employee Groups
export const selectEmployeeGroups = createSelector(
	[selectDomain],
	(notificationSettingsState) => notificationSettingsState.employeeGroups?.data,
);
export const selectEmployeeGroupDetails = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.editEmployeeGroupDetails?.data,
);
export const selectEmployeeGroupDetailsLoading = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.editEmployeeGroupDetails.loading,
);
export const selectEmployeeGroupMembers = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.employeeGroupMembers?.data,
);
export const selectEmployeeGroupMembersLoading = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.employeeGroupMembers.loading,
);
export const selectEmployeeGroupNonMembers = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.employeeGroupNonMembers?.data,
);
export const selectEmployeeGroupNonMembersLoading = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.employeeGroupNonMembers.loading,
);
export const selectActiveEmployeeGroup = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.activeEmployeeGroup?.data,
);

// Customer Groups
export const selectCustomerGroupDetails = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.editCustomerGroupDetails?.data,
);
export const selectCustomerGroupDetailsLoading = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.editCustomerGroupDetails.loading,
);
export const selectCustomerGroupMembers = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.customerGroupMembers?.data,
);
export const selectCustomerGroupMembersLoading = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.customerGroupMembers.loading,
);
export const selectCustomerGroupNonMembers = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.customerGroupNonMembers?.data,
);
export const selectCustomerGroupNonMembersLoading = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.customerGroupNonMembers.loading,
);
export const selectActiveCustomerGroup = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.activeCustomerGroup?.data,
);
