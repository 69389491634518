import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { EventType } from 'types/EventType';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { selectCurrentView } from '../selectors';
import { actions } from '../slice';
import { ContractsViews } from '../types';

const {
	contractsRoll,
	contractsPrice,
	contractsPriceViaOffer,
	contractsPriceViaEfp,
} = apiEndpoints;

const getRequestURL = (eventType: EventType, id) => {
	switch (eventType) {
		case EventType.pricingBasis:
		case EventType.pricingHTA:
			return `${apiRoutes.base}/${contractsPrice}/${id}`;

		case EventType.rollingBasis:
		case EventType.rollingHTA:
			return `${apiRoutes.base}/${contractsRoll}/${id}`;

		case EventType.pricingViaOfferHTA:
		case EventType.pricingViaOfferBasis:
			return `${apiRoutes.base}/${contractsPriceViaOffer}/${id}`;
		case EventType.pricingViaEfpBasis:
			return `${apiRoutes.base}/${contractsPriceViaEfp}/${id}`;
	}
};

/**
 * create Contract Price Roll
 * @param action: receives { data, id, eventType}
 */
export function* priceRollContract(action: PayloadAction<any>) {
	const { data, id, eventType, successTitleGenerator } = action.payload;

	const currentView = yield select(selectCurrentView);

	const requestURL = yield new URL(getRequestURL(eventType, id));

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		data,
	);

	if (!!responseData) {
		yield put(actions.setCurrentModal(null));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				title: successTitleGenerator(responseData, eventType),
				showDescription: false,
			}),
		);
		yield put(actions.contractPriceRollCreated(responseData));
		yield put(actions.loadContractList());
		if (currentView === ContractsViews.Details) {
			yield put(actions.loadContractSummary({ id }));
		}
	} else if (!!responseError.detail) {
		yield put(actions.errorContractPriceRoll(responseError));
	}
}
