import { HubConnection } from '@microsoft/signalr';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';

import { actions } from 'app/containers/FuturesErp/slice';
import { throttledDispatcher } from 'utils/store-util';

import { hubSubscriptionChannels, THROTTLE_TIME } from '../constants';

export function futuresErpHandler(
	connection: HubConnection,
	dispatch: Dispatch<AnyAction>,
) {
	const throttledLoad = throttledDispatcher<void>(
		dispatch,
		actions.loadFuturesErpList,
		THROTTLE_TIME,
	);
	connection.on(hubSubscriptionChannels.futuresErpUpdates, () => {
		throttledLoad();
	});
}
