/**
 *
 * VirtualScroll
 *
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
	onEndOfList: (string) => void;
	list: any[];
	children?: JSX.Element;
	sliceUpdate: number;
}

// 200% of the screen scroll, 60px each row
const VIEWABLE_RECORDS_RATIO = 2 / 60;

export function VirtualScroll(props: Props) {
	const { list: fullList, onEndOfList, sliceUpdate, children = <></> } = props;

	const fullListRef: any = useRef(null);
	const listRef: any = useRef(null);
	const dataRef: any = useRef(null);
	const tableRef: any = useRef(null);
	const lastRecordsRef: any = useRef(null);

	const viewableRecordsRef: any = useRef(0);
	const allLoadedRef: any = useRef(false);
	const observerRef: any = useRef(null);

	const [slice, setSlice] = useState(1);

	const ref = useCallback(
		(node) => {
			if (node) {
				if (!tableRef.current) tableRef.current = node;

				if (!viewableRecordsRef.current)
					viewableRecordsRef.current = Math.floor(
						tableRef.current.parentNode.scrollHeight * VIEWABLE_RECORDS_RATIO,
					);

				if (!observerRef.current)
					observerRef.current = new IntersectionObserver(
						(records: any[]) => {
							records.forEach((record) => {
								if (record.isIntersecting) {
									setSlice((slice) => slice + 1);
									observerRef.current.disconnect();
								}
							});
						},
						{
							root: tableRef.current.parentNode,
							rootMargin: '100px',
							threshold: 0,
						},
					);

				fullListRef.current = fullList;
				if (fullList?.length <= 20) {
					onEndOfList(fullList);
				} else if (fullList?.length > 20) {
					setSlice(1);
					allLoadedRef.current = false;
					listRef.current = fullList;
					dataRef.current = listRef.current.slice(0, 22);
					onEndOfList(dataRef.current);
				}
			}

			return () => {
				if (observerRef.current) {
					observerRef.current.disconnect();
				}
			};
		},
		// eslint-disable-next-line
		[fullList],
	);

	useEffect(() => {
		if (slice > 1) {
			const newLimit = viewableRecordsRef.current * slice;
			if (listRef.current.length >= newLimit) {
				dataRef.current = listRef.current.slice(0, newLimit);
				onEndOfList(dataRef.current);
			} else {
				dataRef.current = listRef.current;
				onEndOfList(dataRef.current);
				allLoadedRef.current = true;
			}
		}
		// eslint-disable-next-line
	}, [slice]);

	useEffect(() => {
		if (sliceUpdate > 20) {
			if (!allLoadedRef.current && tableRef.current) {
				const collection: any[] = tableRef.current.querySelectorAll('tr');
				const length: number = collection.length;
				const lastRows = [
					collection[length - 5],
					collection[length - 4],
					collection[length - 3],
					collection[length - 2],
					collection[length - 1],
				];
				lastRecordsRef.current = lastRows;
				if (lastRecordsRef.current.length) {
					lastRecordsRef.current.forEach((record) => {
						observerRef.current.observe(record);
					});
				}
			}
		}
	}, [sliceUpdate]);

	return (
		<div className="observer-target" ref={ref}>
			{children}
		</div>
	);
}
