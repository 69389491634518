import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { actions as hubActions } from 'app/containers/HubSaga/slice';
import { commoditiesSaga } from 'app/containers/Settings/sections/Commodities/sagas';
import {
	reducer as commoditiesReducer,
	sliceKey as commoditiesSliceKey,
} from 'app/containers/Settings/sections/Commodities/slice';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { Details } from './components/Views/Details';
import { List } from './components/Views/List';
import { AcknowledgeAndFill } from './components/Views/List/Modals/AcknowledgeAndFill';
import { CancelExternalFill } from './components/Views/List/Modals/CancelExternalFill';
import { EditExternalFill } from './components/Views/List/Modals/EditExternalFill';
import { ExternalFill } from './components/Views/List/Modals/ExternalFill';
import { reviewAndReleaseSaga } from './root-saga';
import { selectCurrentModal, selectCurrentView } from './selectors';
import { actions, reducer, sliceKey } from './slice';
import { Modal, ReviewAndReleaseViews } from './types';

export const ReviewAndRelease = () => {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: reviewAndReleaseSaga });

	// Sections
	// Commodities
	useInjectReducer({ key: commoditiesSliceKey, reducer: commoditiesReducer });
	useInjectSaga({ key: commoditiesSliceKey, saga: commoditiesSaga });

	const dispatch = useDispatch();
	const currentModal = useSelector(selectCurrentModal);
	const currentView = useSelector(selectCurrentView);

	const { id: orderId } = useParams<{ id: string }>();

	useEffect(() => {
		if (orderId) {
			dispatch(
				actions.loadOrderDetail({
					id: orderId,
				}),
			);
		} else {
			dispatch(actions.setCurrentView(ReviewAndReleaseViews.table));
		}
	}, [orderId]);

	useEffect(() => {
		// Enable auto-refresh for review and release
		dispatch(hubActions.subscribeToReviewAndReleaseUpdates());

		return () => {
			dispatch(hubActions.unsubscribeFromReviewAndReleaseUpdates());
			dispatch(actions.setCurrentModal(null));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{currentView === ReviewAndReleaseViews.details ? <Details /> : <List />}
			{currentModal === Modal.AcknowledgeAndFill && <AcknowledgeAndFill />}
			{currentModal === Modal.Cancel && <CancelExternalFill />}
			{currentModal === Modal.EditExternalFill && <EditExternalFill />}
			{currentModal === Modal.ExternalFill && <ExternalFill />}
		</>
	);
};
