import React from 'react';
import { useSelector } from 'react-redux';

import Yup from 'yupCustom';

import { HedgeIntegrationType } from '../../../components/HedgeIntegrationType';
import { selectHedgeIntegration } from '../../../selectors';
import { HEDGE_EXECUTION_TYPE, HEDGE_INTEGRATION_TYPE } from '../../../types';

export const useManulSchema = () => {
	const hedgeIntegration = useSelector(selectHedgeIntegration);

	return {
		initialValues: {
			action: HEDGE_INTEGRATION_TYPE.manual,
			executionType: hedgeIntegration?.isHedgeInstant
				? HEDGE_EXECUTION_TYPE.instant
				: HEDGE_EXECUTION_TYPE.setTime,
		},

		validationSchema: Yup.object().shape({}),
		elements: [<HedgeIntegrationType />],
	};
};
