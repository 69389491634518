import { takeLatest } from 'redux-saga/effects';

import {
	createBrokerMap,
	getBrokerMap,
	updateBrokerMap,
} from './Sagas/broker-mapping-saga';
import { brokerMappingActions } from './slice';

export function* brokerMappingSaga() {
	yield takeLatest(brokerMappingActions.loadMappings.type, getBrokerMap);
	yield takeLatest(brokerMappingActions.updateMappings.type, updateBrokerMap);
	yield takeLatest(brokerMappingActions.createMappings.type, createBrokerMap);
}
