import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

export function* getAllNotificationGroups() {
	const { notificationGroups } = apiEndpoints;
	const requestURL = yield new URL(`${apiRoutes.base}/${notificationGroups}`);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.allNotificationGroupsLoaded(responseData));
	}

	if (!!responseError) {
		yield put(actions.allNotificationGroupsError(responseError));
	}
}

export function* notifyBidSent(action: PayloadAction<string>) {
	const { bidSentNotify } = apiEndpoints;
	const requestURL = yield new URL(`${apiRoutes.base}/${bidSentNotify}`);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
	);

	if (!!responseData) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: action.payload,
				showDescription: true,
			}),
		);
	}

	if (!!responseError) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.ERROR,
				message: responseError,
				showDescription: true,
			}),
		);
	}
}
