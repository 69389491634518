/**
 *
 * ActionsMenu
 *
 */
import { EditFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentLocationLoading } from '../../../selectors';
import { actions } from '../../../slice';
import { Modal } from '../../../types';

interface Props {
	rowData: any;
}

export const Actions = (props: Props) => {
	const { rowData } = props;
	const activeLocationLoading = useSelector(selectCurrentLocationLoading);

	const dispatch = useDispatch();

	const handleEditOrder = (rowData) => {
		dispatch(actions.setCurrentLocation(rowData));
		dispatch(actions.setCurrentModal(Modal.Edit));
	};

	return (
		<div className="actions">
			<Button
				type="text"
				onClick={() => handleEditOrder(rowData)}
				data-testid="edit-button"
				disabled={activeLocationLoading}
			>
				<EditFilled />
			</Button>
		</div>
	);
};
