/**
 *
 * Delete Bid Sheet Row
 *
 */
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { ConfirmDialog } from 'app/components/ConfirmDialog';

import { actions } from '../../../../slice';
import messages from './messages';

interface Props {
	rowData: any;
}

export const Delete = ({ rowData }: Props) => {
	const dispatch = useDispatch();
	const handleDeleteBidRow = (rowData) => {
		dispatch(actions.deleteBidRow(rowData));
	};

	return (
		<Button className="action-icon" data-testid="delete-button-1" type="text">
			<ConfirmDialog
				cancelText={'No'}
				confirmText={'Yes'}
				disabled={false}
				handleConfirm={() => handleDeleteBidRow(rowData.id)}
				message={messages.confirmDelete.defaultMessage}
				placement={'bottomRight'}
				trigger={<DeleteOutlined className="action-icon" />}
				withOverlay={false}
			/>
		</Button>
	);
};
