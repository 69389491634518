import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { locationsRoot, groupedLocations } = apiEndpoints;

export function* getLocationsList() {
	const requestURL: URL = yield new URL(`${apiRoutes.base}/${locationsRoot}`);
	requestURL.searchParams.set('IsLocation', 'true');
	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.locationsListLoaded(responseData.locations));
	} else if (!!responseError.detail) {
		yield put(actions.locationsListError(responseError));
	}
}

export function* getGroupedLocationsList() {
	const requestURL: URL = yield new URL(
		`${apiRoutes.base}/${groupedLocations}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.groupedLocationsListLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.groupedLocationsListError(responseError));
	}
}
