import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { translations } from 'locales/i18n';

interface Props {
	disabled?: boolean;
}

export const OriginBasis = memo((props: Props) => {
	const { t: translate } = useTranslation();
	const { disabled } = props;

	const msg = translations.app.containers.Contracts.components.OriginBasis;

	return (
		<GenericForm.FormItem
			label={translate(msg.label)}
			name="origBasis"
			data-testid="origBasis-form-item"
		>
			<Input
				key="origBasis"
				data-testid="origBasis-input-item"
				type="text"
				disabled={disabled}
				name="origBasis"
			/>
		</GenericForm.FormItem>
	);
});
