/**
 *
 * NotificationsCenter
 *
 */
import React, { memo } from 'react';

import { CustomNotification } from 'app/containers/GlobalSaga/types';

import { ToastMessage } from '../ToastMessage';

interface Props {
	notificationsList: CustomNotification[];
	onCloseNotification?: (string) => void;
}

export const NotificationsCenter = memo((props: Props) => {
	return (
		<>
			{props.notificationsList.map((element) => {
				return (
					<ToastMessage
						{...element}
						key={element.id}
						onClose={props.onCloseNotification}
					/>
				);
			})}
		</>
	);
});
