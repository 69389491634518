import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from '../../slice';
import { Filters } from './Filters';
import { Table } from './Table';

export const List = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(actions.loadLocationsList());

		return () => {
			dispatch(actions.setSelectedFilters({}));
			dispatch(
				actions.setPagination({
					start: 1,
					limit: 50,
				}),
			);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="list-container">
			<Filters />
			<Table />
		</div>
	);
};
