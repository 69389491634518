import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';
import { EventType } from 'types/EventType';

export const useTranslations = () => {
	const { t: translate } = useTranslation();

	const rollingScope =
		translations.app.containers.Contracts.components.Modals.RollModal;

	const pricingScope =
		translations.app.containers.Contracts.components.Modals.PriceModal;

	const applyNameScope =
		translations.app.containers.Contracts.components.Modals.ApplyNameIdModal;

	const scope = translations.app.containers.Contracts.components.Modals;
	const sharedScope = translations.app.containers.Transactions.Modals;

	return useMemo(
		() => ({
			actions: {
				newOrderTitle: translate(scope.newOrderTitle),
				createConfirmationMessage: translate(scope.createConfirmationMessage),
				editConfirmationMessage: translate(scope.editConfirmationMessage),
				convertConfirmationMessage: translate(scope.convertConfirmationMessage),
				editOrderTitle: translate(scope.editOrderTitle),
				convertOrderTitle: translate(scope.convertOrderTitle),
				confirmation: translate(scope.confirmation),
				confirmText: translate(scope.confirmText),
				cancelText: translate(scope.cancelText),
				bulkEditOrderTitle: translate(scope.bulkEditOrderTitle),
				bulkConvertOrderTitle: translate(scope.bulkConvertOrderTitle),
			},
			buttons: {
				submitAndNewOrder: translate(scope.buttons.submitAndNewOrder),
				submitOrder: translate(scope.buttons.submitOrder),
				submitChanges: translate(scope.buttons.submitChanges),
				cancelChanges: translate(scope.buttons.cancelChanges),
				cancel: translate(scope.buttons.cancel),
			},
			validations: {
				number: translate(sharedScope.validations.number),
				maxLength10: translate(sharedScope.validations.maxlength10),
				required: translate(sharedScope.validations.required),
				createContractMinQuantityValidation: translate(
					scope.validations.createContractMinQuantityValidation,
				),
				futuresPriceFormatError: translate(
					scope.validations.futuresPriceFormatError,
				),
				basisFormatError: translate(scope.validations.basisFormatError),
				futuresPriceFieldsNeeded: translate(
					scope.validations.futuresPriceFieldsNeeded,
				),
				nonNegativeMsg: translate(scope.validations.nonNegativeValues),
				futuresPriceQuarterMsg: translate(
					translations.app.containers.Transactions.components.Futures
						.futuresPriceQuarterMsg,
				),
				rangeValue: (number) =>
					translate(scope.validations.rangeValue, { number }),
				spreadFetchMsg: translate(scope.validations.spreadValue),
			},
			common: {
				success: translate(translations.app.commons.success.description),
			},
			rolling: {
				submit: translate(rollingScope.submit),
				cancel: translate(scope.buttons.cancel),
				title: (number) => translate(rollingScope.title, { number }),
				error: translate(rollingScope.error),
				errorTitle: translate(rollingScope.errorTitle),
				successTitle: (number) =>
					translate(rollingScope.successTitle, { number }),
				successDescription: () => translate(rollingScope.successDescription),
			},
			pricing: {
				submit: translate(pricingScope.submit),
				cancel: translate(scope.buttons.cancel),
				title: (number) => translate(pricingScope.title, { number }),
				error: translate(pricingScope.error),
				errorTitle: translate(pricingScope.errorTitle),
				successTitle: (number, eventType) => {
					if (
						eventType === EventType.pricingViaOfferHTA ||
						eventType === EventType.pricingViaOfferBasis
					) {
						return translate(pricingScope.successOfferTitle, { number });
					} else {
						return translate(pricingScope.successTitle, { number });
					}
				},
				successDescription: (eventType) => {
					if (
						eventType === EventType.pricingViaOfferHTA ||
						eventType === EventType.pricingViaOfferBasis
					) {
						return translate(pricingScope.successOfferDescription);
					} else {
						return translate(pricingScope.successDescription);
					}
				},
			},
			applyNameId: {
				submit: translate(applyNameScope.applyNameId),
				cancel: translate(applyNameScope.cancelApplyNameId),
				title: (number) => translate(applyNameScope.title, { number }),
			},
		}),
		[translate, rollingScope, pricingScope, applyNameScope, scope, sharedScope],
	);
};
