import { HubConnection } from '@microsoft/signalr';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';

import { actions } from 'app/containers/Contracts/slice';
import { ActionType, PushNotification } from 'app/containers/HubSaga/types';
import { ContractInfoDto } from 'types/Contract';
import { throttledDispatcher } from 'utils/store-util';

import { hubSubscriptionChannels, THROTTLE_TIME } from '../constants';

export function contractsHandler(
	connection: HubConnection,
	dispatch: Dispatch<AnyAction>,
) {
	const throttledUpdate = throttledDispatcher<ContractInfoDto>(
		dispatch,
		actions.updateContractList,
		THROTTLE_TIME,
	);
	const throttledLoad = throttledDispatcher<void>(
		dispatch,
		actions.loadContractList,
		THROTTLE_TIME,
	);

	connection.on(
		hubSubscriptionChannels.contractUpdates,
		(pushNotification: PushNotification<ContractInfoDto>) => {
			if (pushNotification.action === ActionType.Update) {
				throttledUpdate(pushNotification.data);
			} else {
				// Refresh the whole table
				throttledLoad();
			}
		},
	);
}
