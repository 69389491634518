import './styles.scss';

import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ConfirmationModal } from 'app/components/ConfirmationModal';
import { selectOrderStatus } from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';

const domain =
	translations.app.containers.Transactions.components.TransactionsRejectedModal;

export const TransactionsRejectedModal = () => {
	const { t: translate } = useTranslation();
	const orderStatus = useSelector(selectOrderStatus);
	const { showRejectedHedgesAlert } = useFlags();
	const anyTransactionRejected = !!orderStatus?.anyTransactionRejected;
	const [rejectedHedges, setRejectedHedges] = useState(anyTransactionRejected);
	const location = useLocation();

	useEffect(() => {
		setRejectedHedges(anyTransactionRejected);
	}, [anyTransactionRejected, location]);

	const getTranslation = (key: string) => translate(domain[key]);

	const handleTransactionsRejectedModal = () =>
		setRejectedHedges(!anyTransactionRejected);

	return rejectedHedges && showRejectedHedgesAlert ? (
		<ConfirmationModal
			body={getTranslation('body')}
			confirmBtn={getTranslation('confirmButton')}
			handleCancel={handleTransactionsRejectedModal}
			handleConfirmation={handleTransactionsRejectedModal}
			showCancelButton={false}
			title={getTranslation('title')}
		/>
	) : null;
};
