/**
 *
 * NTC Admin Edit Button
 *
 */
import { EditFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

//  interface Props {
//    rowData: any;
//  }

export const EditButton = () => {
	//    const dispatch = useDispatch();
	//    const { rowData } = props;
	//    const activeCommodityLoading = useSelector(selectActiveCommodityLoading);

	//    const handleEditOrder = rowData => {
	//      dispatch(actions.setActiveCommodity(rowData));
	//      dispatch(actions.setCurrentModal(Modal.Edit));
	//    };

	return (
		<div className="actions">
			<Button type="text" data-testid="ntc-admin-edit-button" disabled={false}>
				<EditFilled />
			</Button>
		</div>
	);
};
