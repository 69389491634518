import { takeLatest } from 'redux-saga/effects';

import {
	getLongShortReport,
	getLongShortReportRecap,
} from './sagas/long-short-saga';
import { actions } from './slice';

export function* summaryDashboardSaga() {
	yield takeLatest(actions.loadLongShortReport.type, getLongShortReport);
	yield takeLatest(
		actions.loadLongShortReportRecap.type,
		getLongShortReportRecap,
	);
}
