import React, { memo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { SourceContext } from 'app/containers/Transactions';
import { useHandleBusinessRules } from 'app/containers/Transactions/components/hooks/useHandleBusinessRules';
import { translations } from 'locales/i18n';
import { preventWheelChange, preventWheelEvent } from 'utils/util';

interface Props {
	disabled?: boolean;
	setTotalPrice?: boolean;
}

export const FuturesPrice = memo((props: Props) => {
	const { t: translate } = useTranslation();
	const { disabled, setTotalPrice } = props;

	const sourceName = useContext(SourceContext);
	const { manageTotalPriceCall } = useHandleBusinessRules(sourceName);
	const msg = translations.app.containers.Transactions.components.FuturesPrice;

	const handleChange = () => {
		if (setTotalPrice) {
			manageTotalPriceCall();
		}
	};
	useEffect(() => {
		if (setTotalPrice) {
			manageTotalPriceCall();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<GenericForm.FormItem
			label={translate(msg.label)}
			name="futuresPriceInput"
			data-testid="futuresPrice-form-item"
			onChange={handleChange}
		>
			<Input
				key="futuresPriceInput"
				data-testid="futuresPrice-input-item"
				type="number"
				disabled={disabled}
				name="futuresPriceInput"
				onWheel={preventWheelChange}
				onFocus={preventWheelEvent}
			/>
		</GenericForm.FormItem>
	);
});
