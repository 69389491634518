import { ROUTES } from 'app/routes';
import { apiRoutes } from 'utils/api-routes';

const hostName = window.location.origin;
const returnUrl = hostName + ROUTES.AUTH_CALLBACK;

const authUrl = new URL(apiRoutes.auth);
const params = new URLSearchParams();
params.append('returnUrl', returnUrl);
authUrl.search = params.toString();

const resetURI = 'https://passwordreset.microsoftonline.com';
const resetURL = new URL(resetURI);
const resetParams = new URLSearchParams();
resetParams.append('ru', returnUrl);
resetURL.search = resetParams.toString();

export const RESET_PASSWORD_URL = resetURL.href;

export const AUTH_URL = authUrl.href;
