/**
 *
 * HedgeMapping
 *
 */
import './styles.scss';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { UploadHedgeMapping } from './Modals/UploadHedgeMapping';
import { hedgeMappingSaga } from './saga';
import { selectCurrentModal } from './selectors';
import { hedgeMappingActions, reducer, sliceKey } from './slice';
import { HedgeMappingModals } from './types';
import { List } from './View/List';

export const HedgeMapping = () => {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: hedgeMappingSaga });

	const dispatch = useDispatch();

	const currentModal = useSelector(selectCurrentModal);

	useEffect(() => {
		dispatch(hedgeMappingActions.resetHedgeMappingSection());
		dispatch(globalActions.loadCommoditiesList());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="hedge-mapping-container">
			<List />
			{currentModal === HedgeMappingModals.Upload && <UploadHedgeMapping />}
		</div>
	);
};
