import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { translations } from 'locales/i18n';
import { generateDragAndDrop } from 'utils/GenericFormInputs/generic-form-inputs';

import { selectBidsheetUpload } from '../../selectors';
import { actions } from '../../slice';

export const useUploadConfig = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const template = useSelector(selectBidsheetUpload);
	const domain =
		translations.app.containers.Settings.sections.Bidsheet.UploadBidsheet;
	const [preUploadErrors, setPreUploadErrors] = useState<string[]>([]);
	const [processedFile, setProcessedFile] = useState<string>('');
	const SUPPORTED_FORMATS = [
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	];

	const clearUpload = () => {
		dispatch(actions.clearBidsheetUpload());
		setProcessedFile('');
	};

	const beforeUpload = (file, setProgress) => {
		clearUpload();
		setProcessedFile('');

		let errors: any = [];
		setProgress(20);
		const isXlsx =
			file.type ===
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		if (!isXlsx) {
			errors.push(translate(domain.fileFormatValidation));
			setProgress(0);
		}
		const isLessThan25M = file.size / 1024 / 1024 < 30;
		if (!isLessThan25M) {
			errors.push(translate(domain.fileSizeValidation));
			setProgress(0);
		}
		setPreUploadErrors(errors);
		return isXlsx && isLessThan25M;
	};

	const onUpload = (options, setProgress) => {
		clearUpload();
		const { file, onProgress } = options;
		const fmData = new FormData();

		fmData.append('File', file);

		const config = {
			onUploadProgress: (event) => {
				const percent = Math.floor((event.loaded / event.total) * 100);
				setProgress(percent);
				onProgress({ percent: (event.loaded / event.total) * 100 });

				if (percent === 100) {
					setTimeout(() => setProgress(0), 1000);
				}
			},
		};
		setProcessedFile(file.name);

		dispatch(actions.uploadBidsheet({ data: fmData, config }));
	};

	const uploadComponent = {
		name: 'uploadBidsheet',
		initialValue: '',
		render: generateDragAndDrop,
		dragText: translate(domain.dragText),
		uploadCompleteText: translate(domain.uploadCompleteText),
		beforeUpload,
		onUpload,
		template,
		fileName: processedFile,
		errorList: template.uploadResponse.data
			? template.uploadResponse.data.data.errors
			: preUploadErrors,
		showFileName: true,
		onDeleteIcon: clearUpload,
		validation: Yup.mixed()
			.required(translate(domain.requiredFile))
			.test('fileSize', ' ', (value) => value && value.size / 1024 / 1024 < 30)
			.test(
				'fileFormat',
				' ',
				(value) => value && SUPPORTED_FORMATS.includes(value.type),
			)
			.test('wrongValues', ' ', (value) => {
				if (value && template.uploadResponse.data) {
					if (
						template.uploadResponse.data &&
						template.uploadResponse.data.data.hasErrors
					) {
						return false;
					} else {
						return true;
					}
				}
				return false;
			}),
	};

	return [uploadComponent];
};
