import './style.scss';

import { Card } from 'antd';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from '../../../slice';
import { ListMenu } from './ListMenu';
import { TableWrapper } from './TableWrapper';

export const List = memo(() => {
	const dispatch = useDispatch();

	const handleExpandedRow = (contractId) => {
		dispatch(actions.loadContractsChildren(contractId));
	};

	return (
		<Card bordered={true} className="contracts-list">
			<ListMenu />
			<div className="orders-container">
				<TableWrapper handleExpandedRow={handleExpandedRow} />
			</div>
		</Card>
	);
});
