import './style.scss';

import { Form, Select } from 'formik-antd';
import React, { memo } from 'react';

import { GenericOption } from 'types/GenericOption';

interface Props {
	name: string;
	label?: string;
	placeholder?: string;
	options: GenericOption[];
	onSelect?: (selectedValue) => void;
	disabled?: boolean;
	multiple?: boolean;
	defaultValue?: string | number;
	showSearch?: boolean;
	onChange?: (value) => void;
}

export const FormDropdown = memo((props: Props) => {
	const {
		name,
		label,
		placeholder,
		disabled,
		multiple,
		defaultValue,
		showSearch = true,
		onChange,
	} = props;

	const handleSelect = (selectedValue) => {
		if (props.onSelect) {
			props.onSelect(selectedValue);
		}
	};

	return (
		<Form.Item name={name} label={label} className="dropdown-form__label">
			<Select
				name={name}
				placeholder={placeholder}
				className="dropdown-form__select"
				onSelect={handleSelect}
				disabled={disabled}
				showArrow={!disabled}
				labelInValue
				mode={multiple ? 'multiple' : undefined}
				maxTagCount={multiple ? 2 : undefined}
				maxTagTextLength={multiple ? 10 : undefined}
				defaultValue={defaultValue}
				options={props.options}
				filterOption={(input, option) => {
					const label: any = option?.label;
					return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
				}}
				onChange={onChange}
				showSearch={showSearch}
			></Select>
		</Form.Item>
	);
});
