import './style.scss';

import { Card } from 'antd';
import React from 'react';

import { Menu } from './Menu';
import { Table } from './Table';

export const List = () => {
	return (
		<Card
			bordered={true}
			className="reviewAndRelease-wrapper"
			data-testid="reviewAndRelease-wrapper"
		>
			<div
				className="reviewAndRelease-menu"
				data-testid="reviewAndRelease-menu"
			>
				<Menu />
			</div>
			<div
				className="reviewAndRelease-table"
				data-testid="reviewAndRelease-table"
			>
				<Table />
			</div>
		</Card>
	);
};
