import moment from 'moment';
import React, { useMemo } from 'react';
import { useAbac } from 'react-abac';
import { useSelector } from 'react-redux';

import { selectActiveContract } from 'app/containers/Contracts/selectors';
import { AssignedRegion } from 'app/containers/Transactions/components/AssignedRegion';
import { Basis } from 'app/containers/Transactions/components/Basis';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractNumber } from 'app/containers/Transactions/components/ContractNumber';
import { ContractType } from 'app/containers/Transactions/components/ContractType';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { Customer } from 'app/containers/Transactions/components/Customer';
import { DeliveryDates } from 'app/containers/Transactions/components/DeliveryDates';
import { DeliveryLocation } from 'app/containers/Transactions/components/DeliveryLocation';
import { Employee } from 'app/containers/Transactions/components/Employee';
import { ExpirationDate } from 'app/containers/Transactions/components/ExpirationDate';
import { Fees } from 'app/containers/Transactions/components/Fees';
import { Freight } from 'app/containers/Transactions/components/Freight';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Location } from 'app/containers/Transactions/components/Location';
import { NetBasis } from 'app/containers/Transactions/components/NetBasis';
import { Quantity } from 'app/containers/Transactions/components/Quantity';
import { TheirContract } from 'app/containers/Transactions/components/TheirContract';
import { Transaction } from 'app/containers/Transactions/components/Transaction';
import { useOrderEntriesFormSchema } from 'app/containers/Transactions/hooks/useOrderEntriesFormSchema';
import { useTranslations } from 'app/containers/Transactions/hooks/useTranslations';
import { Permission } from 'types/Authorization';
import { CONSTANTS } from 'utils/constants';
import {
	customFormat,
	getActionType,
	getDeliveryDatesMode,
	getDeliveryDateWindow,
	getExpirationDate,
	isDeliveryDateCustom,
	isDeliveryDateWindow,
	validatePriceFormat,
} from 'utils/helpers';
import Yup from 'yupCustom';

import { validatePrice } from '../../../shared/helpers';

export const useBasisSchema = (
	translations: ReturnType<typeof useTranslations>,
) => {
	let orderData = useSelector(selectActiveContract) as any;

	const { elements, initialValues, validationSchema } =
		useOrderEntriesFormSchema(orderData);

	const { userHasPermissions } = useAbac();

	const quantityDisabled = !userHasPermissions(
		Permission.CONTRACTSERVICE_CONTRACTBASIS_EDITQUANTITY,
	);

	const numberMsg = translations.validations.number;
	const requiredMsg = translations.validations.required;
	const maxLength10Msg = translations.validations.maxLength10;
	const basisFormatError = translations.validations.basisFormatError;

	const validation = useMemo(
		() =>
			Yup.object().shape({
				theirContract: Yup.string().nullable().max(10, maxLength10Msg),
				transaction: Yup.object().requiredOption(requiredMsg),
				contractNumber: Yup.string().nullable(),
				contract: Yup.object().requiredOption(requiredMsg),
				commodity: Yup.object().requiredOption(requiredMsg),
				location: Yup.object().requiredOption(requiredMsg),
				deliveryLocation: Yup.object().requiredOption(requiredMsg),
				assignedRegion: Yup.object().requiredOption(requiredMsg),
				deliveryDate: Yup.mixed().when('deliveryDatesMode', {
					is: (mode) => isDeliveryDateCustom(mode),
					then: Yup.mixed().required(requiredMsg),
				}),
				deliveryDateWindow: Yup.mixed().when('deliveryDatesMode', {
					is: (mode) => isDeliveryDateWindow(mode),
					then: Yup.object().requiredOption(requiredMsg),
				}),
				freight: Yup.number().typeError(numberMsg).nullable(),
				postedBasis: Yup.number()
					.required(requiredMsg)
					.typeError(numberMsg)
					.test('priceValidation', basisFormatError, (value: string) =>
						validatePrice(value),
					),
				pushBasis: Yup.number().typeError(numberMsg).nullable(),
				fees1: Yup.number().typeError(numberMsg),
				fees2: Yup.number().typeError(numberMsg),
				netBasisPrice: Yup.number().typeError(numberMsg).required(requiredMsg),
				quantity: Yup.string().typeError(numberMsg).required(requiredMsg),
				customer: Yup.object().requiredOption(requiredMsg),
				employee: Yup.object().requiredOption(requiredMsg),
				futuresMonth: Yup.object().requiredOption(requiredMsg),
				...validationSchema,
			}),
		[
			validationSchema,
			numberMsg,
			requiredMsg,
			maxLength10Msg,
			basisFormatError,
		],
	);

	return useMemo(
		() => ({
			initialValues: {
				theirContract: orderData.theirContract,
				transaction: {
					label: orderData.transactionTypeName,
					value: orderData.transactionTypeId,
				},
				contractNumber: orderData.number,
				contract: {
					label: orderData.contractTypeName,
					value: orderData.contractTypeId,
				},
				action: getActionType(orderData.isSell),
				commodity: {
					label: orderData.commodityName,
					value: orderData.commodityId,
				},
				location: {
					label: orderData.locationName,
					value: orderData.locationId,
				},
				deliveryLocation: {
					label: orderData.deliveryLocationName,
					value: orderData.deliveryLocationId,
				},
				assignedRegion: {
					label: orderData?.regionName,
					value: orderData?.regionId,
				},
				deliveryDate: [
					moment(orderData.deliveryStartDate),
					moment(orderData.deliveryEndDate),
				],
				deliveryDatesMode: getDeliveryDatesMode(orderData),
				deliveryDateWindow: getDeliveryDateWindow(orderData),
				cropYear: orderData.cropYear,
				futuresMonth: {
					label: orderData.futuresMonth,
					value: orderData.futuresMonth,
				},
				futuresPrice: validatePriceFormat(orderData.futuresPrice),
				postedBasis: validatePriceFormat(orderData.postedBasis),
				pushBasis: validatePriceFormat(orderData.pushBasis),
				netBasis: validatePriceFormat(orderData.netBasis),
				freight: validatePriceFormat(orderData.freightPrice),
				fees1: validatePriceFormat(orderData.fees1),
				fees2: validatePriceFormat(orderData.fees2),
				flatPrice: validatePriceFormat(orderData.price),
				netFutures: validatePriceFormat(orderData.price),
				netBasisPrice: validatePriceFormat(orderData.price),
				quantity: customFormat(
					orderData.quantity,
					true,
					CONSTANTS.FIXED_QUANTITY_DECIMALS,
				),
				customer: {
					label: orderData.customerName,
					value: orderData.customerId,
				},
				employee: {
					label: orderData.employeeName,
					value: orderData.employeeId,
				},
				comments: orderData.comments,
				passFill: orderData.passFill,
				doNotHedge: orderData.doNotHedge,
				expirationDate: getExpirationDate(orderData),
				...initialValues,
			},
			validationSchema: validation,
			elements: (
				<>
					<TheirContract disabled={quantityDisabled} />
					<Transaction disabled />
					<ContractNumber disabled={quantityDisabled} />
					<ContractType disabled />
					<Commodity disabled />
					<Location />
					<DeliveryLocation disabled={quantityDisabled} />
					<AssignedRegion disabled={quantityDisabled} />
					<CropYears disabled />
					<DeliveryDates
						disabled={quantityDisabled}
						rangePickerDisabled={quantityDisabled}
						resetDependencies={false}
					/>
					<Quantity disabled={quantityDisabled} />
					<Futures
						futuresMonth={{ disabled: quantityDisabled, market: true }}
					/>
					<Basis
						disabled={{
							postedBasis: true,
							pushBasis: quantityDisabled,
							netBasis: true,
						}}
						adjustedBasis
						datesModeDependency
					/>
					<ExpirationDate disabled={quantityDisabled} />
					<Freight disabled={quantityDisabled} />
					<Fees disabled={{ fee1: quantityDisabled, fee2: quantityDisabled }} />
					<NetBasis disabled />
					<Customer disabled />
					<Employee />
					{elements}
					<Comments />
				</>
			),
		}),
		[orderData, elements, initialValues, quantityDisabled, validation],
	);
};
