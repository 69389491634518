import moment from 'moment';

import { DeliveryDateMode } from 'types/DeliveryDateMode';
import { OfferDetails } from 'types/OfferDetails';

export const getDeliveryDatesMode = (offerData: OfferDetails | null) =>
	offerData?.isDeliveryDatesCustom
		? { value: DeliveryDateMode.Custom }
		: { value: DeliveryDateMode.Window };

export const getExpiration = (offerData: OfferDetails | null) =>
	offerData?.expiration ? moment(offerData?.expiration) : null;
