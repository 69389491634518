import './style.scss';

import { UpOutlined } from '@ant-design/icons';
import { Card, Col, Row, Spin } from 'antd';
import classNames from 'classnames';
import React, { memo, useState } from 'react';

import { FilterEmpty } from 'app/components/FilterEmpty';

import { SummaryView } from '../../types';
import { ContractActionsSection } from './components/ContractActionsSection';
import { ContractTypeSection } from './components/ContractTypeSection';
import { OfferActionsSection } from './components/OfferActionsSection';
import { OrderActionsSection } from './components/OrderActionsSection';
import { OrderSection } from './components/OrderSection';
import { PreHedgeActionsSection } from './components/PreHedgeActionsSection';
import { Section } from './components/Section';
import { useSummarySchema } from './hooks/useSummarySchema';

interface Props {
	activeView: string;
	activeEntity: any;
	listLoading: boolean;
	isCollapsable: boolean;
	handleClose: () => void;
}

export const Summary = memo((props: Props) => {
	const { activeView, activeEntity, listLoading, isCollapsable, handleClose } =
		props;
	const { summary } = useSummarySchema(activeEntity.data);
	const [isPanelCollapsed, setIsPanelCollapsed] = useState(false);

	const handleExpandablePanel = () => {
		setIsPanelCollapsed(!isPanelCollapsed);
	};

	const renderSection = (section) => {
		const content: any[] = [];

		for (let item of section) {
			const row = (
				<div className="contract-item" key={item?.label}>
					<span className="label">{item?.label}</span>
					{item?.value || item?.value === 0 ? item?.value : '----'}
				</div>
			);
			content.push(row);
		}

		return content;
	};

	return (listLoading || activeEntity.loading) && activeEntity.data === null ? (
		<Spin className="flex-center" data-testid="summary-spin" />
	) : activeEntity.data === null ? (
		<FilterEmpty />
	) : (
		summary && (
			<>
				<div className="summary-wrapper">
					<Row
						className={classNames('summary top', {
							'collapsed-view': isPanelCollapsed,
						})}
					>
						{activeView === SummaryView.contracts && (
							<>
								<Section span={2} dataTestId="first-section" noBorder={true}>
									{renderSection(summary.contract)}
									{activeEntity.data.comesFromAnOffer && (
										<div className="contract-item">
											<span className="label">
												{summary.originatedOfferId.label}
											</span>
											{summary.originatedOfferId.value}
										</div>
									)}
								</Section>
								<Section
									span={2}
									dataTestId="hrvyst-id-section"
									noBorder={false}
								>
									{renderSection(summary.hrvystID)}
								</Section>
								<ContractTypeSection summary={summary} />
								<Section span={2} dataTestId="third-section" noBorder={false}>
									{renderSection(summary.contractCrop)}
								</Section>
								<Section span={2} dataTestId="forth-section" noBorder={false}>
									{renderSection(summary.contractQuantity)}
								</Section>
								<ContractActionsSection
									span={2}
									dataTestId="contract-actions"
									activeEntity={activeEntity}
									handleClose={handleClose}
								/>
							</>
						)}
						{activeView === SummaryView.offers && (
							<>
								<Section span={2} dataTestId="first-section" noBorder={true}>
									{renderSection(summary.offer)}
									<div className="contract-item">
										<span className="label">
											{summary.offerExpiration.expirationLabel}
										</span>
										{activeEntity.data.gtc
											? summary.offerExpiration.gtcValue
											: summary.offerExpiration.expirationValue}
									</div>
								</Section>
								<ContractTypeSection summary={summary} />
								<Section span={2} dataTestId="third-section" noBorder={false}>
									{renderSection(summary.offerCrop)}
								</Section>
								<Section span={3} dataTestId="forth-section" noBorder={false}>
									{renderSection(summary.offerQuantity)}
								</Section>
								<OfferActionsSection
									span={2}
									dataTestId="offer-actions"
									activeEntity={activeEntity}
									handleClose={handleClose}
								/>
							</>
						)}
						{(activeView === SummaryView.reviewAndRelease ||
							activeView === SummaryView.preHedge) && (
							<>
								<OrderSection summary={summary} />
								<Section span={2} dataTestId="second-section" noBorder={false}>
									{renderSection(summary.orderSource)}
								</Section>
								<Section span={2} dataTestId="third-section" noBorder={false}>
									{renderSection(summary.orderType)}
								</Section>
								{activeView === SummaryView.reviewAndRelease ? (
									<>
										<Section
											span={2}
											dataTestId="forth-section"
											noBorder={false}
										>
											{renderSection(summary.orderGTC)}
										</Section>
										<OrderActionsSection
											span={4}
											dataTestId="contract-actions"
											activeEntity={activeEntity}
											handleClose={handleClose}
										/>
									</>
								) : (
									<>
										<Section
											span={2}
											dataTestId="forth-section"
											noBorder={false}
										>
											{renderSection(summary.preHedgeGTC)}
										</Section>
										<PreHedgeActionsSection
											span={4}
											dataTestId="preHedge-actions"
											activeEntity={activeEntity}
											handleClose={handleClose}
										/>
									</>
								)}
							</>
						)}
					</Row>
					{activeView !== SummaryView.reviewAndRelease &&
						activeView !== SummaryView.preHedge && (
							<Row
								className={classNames('summary', {
									collapsed: isPanelCollapsed,
								})}
							>
								<Col span={3}>
									<Card bordered={false}>
										<div className="divider no-border">
											<h6
												className="section-title"
												data-testid="customer-section"
											>
												{summary.customerTitle}
											</h6>
											{renderSection(summary.customer)}
											{renderSection(summary.customFields)}
										</div>
									</Card>
								</Col>
								<Col span={3}>
									<Card bordered={false}>
										<div className="divider">
											<h6
												className="section-title"
												data-testid="employee-section"
											>
												{summary.employeeTitle}
											</h6>
											{renderSection(summary.employee)}
										</div>
									</Card>
								</Col>
								<Col span={2}>
									{' '}
									<Card bordered={false}>
										<div className="divider">
											<h6
												className="section-title"
												data-testid="delivery-section"
											>
												{summary.deliveryInfoTitle}
											</h6>
											{renderSection(summary.deliveryInfo)}
										</div>
									</Card>
								</Col>
								<Col span={4} className="price-section-wrapper">
									<div className="divider">
										<Row className="prices-row">
											<Col span={4}>
												<Card
													className="price-section"
													data-testid="price-section-futures"
													bordered={false}
												>
													<div className="divider no-border no-top-padding">
														<div className="value">
															{summary.prices[0].value}
														</div>
														<div className="label">
															{summary.prices[0].label}
														</div>
													</div>
												</Card>
											</Col>
											<Col span={4}>
												<Card
													className="price-section"
													data-testid="price-section-freight"
													bordered={false}
												>
													<div className="divider no-top-padding">
														<div className="value">
															{summary.prices[1].value}
														</div>
														<div className="label">
															{summary.prices[1].label}
														</div>
													</div>
												</Card>
											</Col>
											<Col span={4}>
												<Card
													className="price-section"
													data-testid="price-section-service-fees"
													bordered={false}
												>
													<div className="divider no-top-padding">
														<div className="value">
															{summary.prices[2].value1}
														</div>
														<div className="value">
															{summary.prices[2].value2}
														</div>
														<div className="label">
															{summary.prices[2].label}
														</div>
													</div>
												</Card>
											</Col>
										</Row>
										<Row className="prices-row">
											<Col span={4}>
												<Card
													className="price-section"
													data-testid="price-section-posted"
													bordered={false}
												>
													<div className="divider no-border">
														<div className="value">
															{summary.basis[0].value}
														</div>
														<div className="label">
															{summary.basis[0].label}
														</div>
													</div>
												</Card>
											</Col>
											<Col span={4}>
												<Card
													className="price-section"
													data-testid="price-section-push"
													bordered={false}
												>
													<div className="divider">
														<div className="value">
															{summary.basis[1].value}
														</div>
														<div className="label">
															{summary.basis[1].label}
														</div>
													</div>
												</Card>
											</Col>
											<Col span={4}>
												<Card
													className="price-section"
													data-testid="price-section-net"
													bordered={false}
												>
													<div className="divider">
														<div className="value">
															{summary.basis[2].value}
														</div>
														<div className="label">
															{summary.basis[2].label}
														</div>
													</div>
												</Card>
											</Col>
										</Row>
										<Row>
											<Col span={12}>
												<Card
													className="cash-section"
													data-testid="cash-section"
													bordered={false}
												>
													<div className="value">
														{summary.cashPrice.value}
														<span className="label">
															{' '}
															{summary.cashPrice.label}
														</span>
													</div>
												</Card>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						)}
					{(activeView === SummaryView.reviewAndRelease ||
						activeView === SummaryView.preHedge) && (
						<Row
							className={classNames('summary', {
								collapsed: isPanelCollapsed,
							})}
						>
							<Section span={2} dataTestId="sixth-section" noBorder={true}>
								{renderSection(summary.orderPrice)}
							</Section>
							<Section span={2} dataTestId="seventh-section" noBorder={false}>
								{renderSection(summary.orderAvgFillPrice)}
							</Section>
							<Section span={2} dataTestId="eight-section" noBorder={false}>
								{renderSection(summary.orderQuantity)}
							</Section>
							<Section span={2} dataTestId="ninth-section" noBorder={false}>
								{renderSection(summary.orderFilledQuantity)}
							</Section>
							<Section span={4} dataTestId="tenth-section" noBorder={false}>
								{renderSection(summary.orderRejectedReason)}
							</Section>
						</Row>
					)}
					{isCollapsable && (
						<UpOutlined
							className={classNames({
								'summary__collapse-icon': true,
								'summary__collapse-icon--collapsed': isPanelCollapsed,
							})}
							onClick={handleExpandablePanel}
							data-testid="collapse-button"
						/>
					)}
				</div>
			</>
		)
	);
});
