import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.tradeBook || initialState;

export const selectTradeBookListLoading = createSelector(
	[selectDomain],
	(tradeBookState) => tradeBookState.tradeBookList.loading,
);

export const selectTradeBookList = createSelector(
	[selectDomain],
	(tradeBookState) => tradeBookState.tradeBookList.data,
);

export const selectErpErrorMessage = createSelector(
	[selectDomain],
	(tradeBookState) => tradeBookState.tradeBookList.erpErrorMessage,
);

export const selectSelectedFilters = createSelector(
	[selectDomain],
	(tradeBookState) => tradeBookState.selectedFilters,
);

export const selectSelectedBuyRows = createSelector(
	[selectDomain],
	(tradeBookState) => tradeBookState.selectedBuyRows,
);

export const selectSelectedSellRows = createSelector(
	[selectDomain],
	(tradeBookState) => tradeBookState.selectedSellRows,
);

export const selectFuturesMonthsList = createSelector(
	[selectDomain],
	(tradeBookState) => tradeBookState.futureMonths.data,
);

export const selectIntegrateToErpLoading = createSelector(
	[selectDomain],
	(tradeBookState) => tradeBookState.integrateToErp.loading,
);
