import { FieldValues } from 'react-hook-form';

import { translations } from 'locales/i18n';
import { GenericOption } from 'types/GenericOption';
import { CustomField, FIELD_TYPES_VALUES, OrderEntry } from 'types/OrderEntry';
import { DATE_FORMAT } from 'utils/constants';
import Yup from 'yupCustom';

import { dateMoment } from './helpers';

const domain = translations.app.containers.Transactions.Modals.validations;

const formValues = {
	[FIELD_TYPES_VALUES.BLN]: (formValue: boolean | null) =>
		formValue?.toString(),
	[FIELD_TYPES_VALUES.DAT]: (formValue: moment.Moment | null) =>
		formValue?.format(DATE_FORMAT),
	[FIELD_TYPES_VALUES.INT]: (formValue: number | null) => formValue?.toString(),
	[FIELD_TYPES_VALUES.MUL]: (formValue: GenericOption | null) =>
		formValue?.value,
	[FIELD_TYPES_VALUES.TXT]: (formValue: string | null) => formValue,
	[FIELD_TYPES_VALUES.TXT2]: (formValue: string | null) => formValue,
	[FIELD_TYPES_VALUES.UND]: () => null,
};

const getBlnValue = (formValue: string | undefined | null) =>
	formValue === 'true';

const getDatValue = (formValue: string | undefined | null) =>
	formValue && dateMoment(formValue);

const getIntValue = (formValue: string | undefined | null) =>
	formValue && parseFloat(formValue).toFixed(4);

const getMulValue = (formValue: string | undefined | null) => ({
	label: formValue,
	value: formValue,
});

const getTxtValue = (formValue: string | undefined | null) => formValue;

const initialValues = {
	[FIELD_TYPES_VALUES.BLN]: getBlnValue,
	[FIELD_TYPES_VALUES.DAT]: getDatValue,
	[FIELD_TYPES_VALUES.INT]: getIntValue,
	[FIELD_TYPES_VALUES.MUL]: getMulValue,
	[FIELD_TYPES_VALUES.TXT]: getTxtValue,
	[FIELD_TYPES_VALUES.TXT2]: getTxtValue,
	[FIELD_TYPES_VALUES.UND]: () => undefined,
};

const getInitialValue = (
	customFields: CustomField[] | null | undefined,
	entryData: OrderEntry,
) => {
	const orderEntry = customFields?.find(({ id }) => id === entryData.id);

	let type: FIELD_TYPES_VALUES = orderEntry?.type || FIELD_TYPES_VALUES.UND;
	let value = orderEntry?.value;

	if (!customFields) {
		type = entryData.type || FIELD_TYPES_VALUES.UND;
		value = entryData.defaultValue;

		if (type === FIELD_TYPES_VALUES.MUL) {
			// check value is present under entryData.items
			const itemPresent = entryData.items?.find(
				(itemValue) => itemValue === value,
			);

			if (!itemPresent) {
				value = undefined;
			}
		}
	}

	return initialValues[type](value);
};

export const getInitialValues = (
	orderEntries: OrderEntry[] = [],
	orderData?: { customFields?: CustomField[] } | null,
) =>
	orderEntries
		.filter(({ isActive }: OrderEntry) => isActive)
		.reduce<Record<string, unknown>>(
			(initialValues, entryData: OrderEntry) => ({
				...initialValues,
				[entryData.id || '']: getInitialValue(
					orderData?.customFields,
					entryData,
				),
			}),
			{},
		);

export const getOrderEntriesFormValues = (
	orderEntries: OrderEntry[] | undefined,
	values: FieldValues | undefined,
) =>
	orderEntries?.map(({ id = '', type = FIELD_TYPES_VALUES.UND }) => ({
		fieldId: id,
		value:
			formValues[type](values?.[id]) === undefined ||
			formValues[type](values?.[id]) === ''
				? null
				: formValues[type](values?.[id]),
	}));

export const getValidationSchema = (
	orderEntries: OrderEntry[] = [],
	translate: Function,
) =>
	orderEntries
		.filter(({ isActive }: OrderEntry) => isActive)
		.reduce<Record<string, Yup.Schema<unknown>>>(
			(
				validationSchema,
				{ id = '', type = FIELD_TYPES_VALUES.UND }: OrderEntry,
			) => {
				const charactersOnlyRegEx = /^([^0-9]*)$/;
				const orderEntryValidations = {
					[FIELD_TYPES_VALUES.BLN]: Yup.boolean().nullable(),
					[FIELD_TYPES_VALUES.DAT]: Yup.mixed().nullable(),
					[FIELD_TYPES_VALUES.INT]: Yup.mixed().nullable(),
					[FIELD_TYPES_VALUES.MUL]: Yup.mixed().nullable(),
					[FIELD_TYPES_VALUES.TXT]: Yup.string()
						.nullable()
						.test(
							'textValidation',
							translate(domain.textValidation),
							(value: string) => charactersOnlyRegEx.test(value),
						),
					[FIELD_TYPES_VALUES.TXT2]: Yup.string().nullable(),
					[FIELD_TYPES_VALUES.UND]: Yup.string().nullable(),
				};

				return {
					...validationSchema,
					[id]: orderEntryValidations[type],
				};
			},
			{},
		);
