/**
 *
 * BulkActions
 *
 */
import './style.scss';

import { Button, Col, Divider, Popover, Row } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectContractListLoading,
	selectSelectedRows,
} from 'app/containers/Contracts/selectors';
import { actions } from 'app/containers/Contracts/slice';
import { ContractsModals } from 'app/containers/Contracts/types';
import { translations } from 'locales/i18n';
import { Contract } from 'types/Contract';
import { hasFollowingDataRestrictions } from 'utils/ContractOfferDataValidations/common';
import {
	hasSameCustomFieldValues,
	hasSameDeliveryDates,
	hasSameFututreMonth,
	hasSameValue,
	haveSameFees,
} from 'utils/ContractOfferDataValidations/contract';
import { useResize } from 'utils/hooks/useResize';

interface Props {}

export const BulkActions = memo((props: Props) => {
	const scopeTranslations = translations.app.containers.Contracts.ListMenu;

	const { t: translate } = useTranslation();
	const { isMobile } = useResize();
	const dispatch = useDispatch();

	const selectedRowsData = useSelector(selectSelectedRows) as Contract[];
	const singleData = selectedRowsData && selectedRowsData[0];

	const isLoading = useSelector(selectContractListLoading);

	// canBeConverted is a restriction type comming from backend as a permission that a contat can be converted and so rest are same
	const isNTCContract = hasFollowingDataRestrictions(
		selectedRowsData,
		'canBeConverted',
	);

	const hasSameCropYear = hasSameValue(selectedRowsData, 'cropYear');
	const hasSameCommmodity = hasSameValue(selectedRowsData, 'commodity');
	const hasSameEmployee = hasSameValue(selectedRowsData, 'employee');
	const hasSameBuySell = hasSameValue(selectedRowsData, 'isSell');
	const hasSameRegionId = hasSameValue(selectedRowsData, 'regionId');
	const hasSameGroupLocationName = hasSameValue(
		selectedRowsData,
		'groupLocationName',
	);
	const hasSameFuturesMonth = hasSameFututreMonth(
		selectedRowsData,
		singleData && singleData.price.futuresMonth,
	);

	const canPerformBulkAction =
		isNTCContract &&
		hasSameCropYear &&
		hasSameEmployee &&
		hasSameCommmodity &&
		hasSameBuySell &&
		hasSameFuturesMonth &&
		hasSameDeliveryDates(selectedRowsData) &&
		hasSameRegionId &&
		hasSameGroupLocationName &&
		hasSameCustomFieldValues(selectedRowsData) &&
		haveSameFees(selectedRowsData) &&
		!isLoading;

	const isSelectedMoreThanOne = selectedRowsData.length > 1;
	const isSelectedOnlyOneRow = selectedRowsData.length === 1;

	const handleConvert = () => {
		let selectedId = selectedRowsData[0].id;
		dispatch(actions.loadContractDetails(selectedId));
		dispatch(actions.setCurrentModal(ContractsModals.BulkConvert));
	};

	const handleEdit = () => {
		let selectedId = selectedRowsData[0].id;
		dispatch(actions.loadContractDetails(selectedId));
		dispatch(actions.setCurrentModal(ContractsModals.BulkEdit));
	};

	return (
		<div className="bulk-actions">
			<Row
				data-testid="topbar-menu-container"
				className="bulk-actions-container"
			>
				<Col span={12} className="horizontal-align--right">
					{
						<div className="selection-count">
							<span>
								{`${selectedRowsData.length}
          ${translate(scopeTranslations.selected)}`}
							</span>
							{isSelectedOnlyOneRow ? (
								<p className="selection-count__single">
									More than one row needs to be selected
								</p>
							) : (
								'.'
							)}
						</div>
					}
					<Popover
						trigger={isMobile ? 'click' : 'hover'}
						placement="left"
						content={
							<div className="create-new-entry__actions-container">
								<Button
									disabled={!canPerformBulkAction}
									onClick={handleConvert}
									size="small"
									type="text"
									className="create-offer__button"
								>
									{translate(scopeTranslations.bulkConvert)}
								</Button>
								<Divider
									type="horizontal"
									className="actions__divider--margin"
								/>
								<Button
									disabled={!canPerformBulkAction}
									onClick={handleEdit}
									size="small"
									type="text"
									className="create-offer__button"
								>
									{translate(scopeTranslations.bulkEdit)}
								</Button>
							</div>
						}
					>
						<Button
							className="topbar-menu-container__button"
							data-testid="create-new-entry-button"
							disabled={!canPerformBulkAction && !isSelectedMoreThanOne}
							type="primary"
						>
							{'Bulk'}
						</Button>
					</Popover>
				</Col>
			</Row>
		</div>
	);
});
