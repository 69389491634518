import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { translations } from 'locales/i18n';
import { Trade } from 'types/TradeBook';

interface AllocationChangeArgs {
	dataSource: Trade[];
	setDataSource: React.Dispatch<React.SetStateAction<Trade[]>>;
	setEditingKey: React.Dispatch<React.SetStateAction<string>>;
}

const useAllocationChange = ({
	dataSource,
	setDataSource,
	setEditingKey,
}: AllocationChangeArgs) => {
	const translationsScope = translations.app.containers.TradeBook;
	const { t: translate } = useTranslation();

	const dispatch = useDispatch();

	const handleAllocationChange = (record, value, selectedRowsData) => {
		// Negative value handling >> The user cannot enter a negative value as an allocation.
		if (Number(value) < 0) {
			setEditingKey('');
			return;
		}

		// Check if record.id is in selectedRowsData
		const isRecordSelected = selectedRowsData.some(
			(selectedRow) => selectedRow.id === record.id,
		);
		if (!isRecordSelected) {
			setEditingKey('');
			return;
		}

		if (Number(value) > Number(record['filledQuantity'])) {
			dispatch(
				globalActions.addNotification({
					type: CustomNotificationTypes.WARNING,
					showDescription: true,
					message: translate(translationsScope.editAllocationWarning),
				}),
			);
			return;
		}

		const rowIndex = dataSource.findIndex((item) => item.id === record.id);

		if (rowIndex !== -1) {
			const updatedRow = {
				...dataSource[rowIndex],
				allocation: Number(value),
			};

			const updatedDataSource = [...dataSource];
			updatedDataSource[rowIndex] = updatedRow;

			setDataSource(updatedDataSource);
			setEditingKey('');
		}
	};

	return { handleAllocationChange };
};

export default useAllocationChange;
