import { call, put, select } from 'redux-saga/effects';

import { genericRequest, httpMethod } from 'utils/request';

import { apiEndpoints } from '../../../../utils/api-endpoints';
import { apiRoutes } from '../../../../utils/api-routes';
import { selectSelectedFilters } from '../selectors';
import { actions } from '../slice';

const { futureQuote, products } = apiEndpoints;

/**
 * Reducer : loadFutureQuotes
 * @method: POST /api/futures/frontmonthfutureprices
 */
export function* getFutureQuoteData() {
	const selectedFilters: any = yield select(selectSelectedFilters);

	const filters = {
		...selectedFilters,
		products:
			selectedFilters.products
				?.filter((element) => element.value !== 'all')
				.map((element) => element.value) || [],
	};

	const productList = filters.products.map((ProductId) => ({
		ProductId,
		frontMonths: 0,
	}));

	const requestUrl = yield new URL(`${apiRoutes.base}/${futureQuote}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestUrl,
		httpMethod.Post,
		{ productList },
	);
	//success actions
	if (!!responseData) {
		yield put(actions.futureQuoteListLoaded(responseData));
		return;
	}
	// error actions
	if (!!responseError) {
		yield put(actions.futureListError(responseError));
		return;
	}
}

export function* getProductsList() {
	const requestURL = yield new URL(`${apiRoutes.base}/${products}`);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.productListLoaded(responseData.product));
	} else if (!!responseError.detail) {
		yield put(actions.productListError(responseError));
	}
}
