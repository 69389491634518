import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import { LoadFuturesMontOptionsPayload } from '../types';

const { futuresMonthOptions } = apiEndpoints;

export function* getFuturesMonthOptions(
	action: PayloadAction<LoadFuturesMontOptionsPayload>,
) {
	const { commodityId, excludeExpired } = action.payload.params;
	const source = action.payload.source;
	const requestURL = yield new URL(`${apiRoutes.base}/${futuresMonthOptions}`);
	requestURL.searchParams.append('commodityId', commodityId);

	if (excludeExpired) {
		requestURL.searchParams.append('excludeExpired', excludeExpired);
	}

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(
			actions.futuresMonthOptionsLoaded({
				source,
				data: responseData,
			}),
		);
	} else if (!!responseError.detail) {
		yield put(actions.futuresMonthError({ error: responseError, source }));
	}
}
