import { EditFilled } from '@ant-design/icons';
import { Button, Input, Switch } from 'antd';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { Region } from 'types/Regions';

import { selectAllRegions } from '../../selectors';
import { REGIONS_FORM_ITEMS } from '../../types';
import { REGIONS_DOMAIN } from '../../utils/helpers';

interface Props {
	regionSetup: Region;
	index: number;
}
export const RegionsRow = memo(({ index, regionSetup }: Props) => {
	const { t: translate } = useTranslation();
	const regions = useSelector(selectAllRegions);

	const isCreatingRegions = regions?.length === 0;
	const isActive = isCreatingRegions || !!regionSetup?.isEnabled;
	const isUsed = regionSetup?.isUsed;

	const [disabledItems, setDisabledItems] = useState(true);

	const isNewRegion = 'newRegion';

	const regionId = regionSetup?.id || isNewRegion;

	const getFormItemId = (key: string) =>
		`${key}-${regionId !== isNewRegion ? regionId : index}`;

	const regionNameLabel = getFormItemId(REGIONS_FORM_ITEMS.REGION_NAME_LABEL);
	const erpIdLabel = getFormItemId(REGIONS_FORM_ITEMS.ERP_ID_LABEL);

	return (
		<>
			<GenericForm.FormItem name={regionId}>
				<GenericForm.FormItem name={getFormItemId(REGIONS_FORM_ITEMS.STATUS)}>
					<Switch data-testid="form-item-status" checked={isActive} />
				</GenericForm.FormItem>
				<GenericForm.FormItem name={regionNameLabel}>
					<Input
						disabled={disabledItems || isUsed}
						key={regionNameLabel}
						name={regionNameLabel}
						placeholder={translate(REGIONS_DOMAIN.regionNamePlaceholder)}
						type="string"
						maxLength={15}
					/>
				</GenericForm.FormItem>
				<GenericForm.FormItem name={erpIdLabel}>
					<Input
						disabled={disabledItems}
						key={erpIdLabel}
						name={erpIdLabel}
						placeholder={translate(REGIONS_DOMAIN.erpIdPlaceholder)}
						type="string"
						maxLength={15}
					/>
				</GenericForm.FormItem>
				<GenericForm.FormItem name={getFormItemId('edit')}>
					<div onClick={() => setDisabledItems(!disabledItems)}>
						<Button type="text" data-testid="regions-setup-edit-button">
							<EditFilled />
						</Button>
					</div>
				</GenericForm.FormItem>
			</GenericForm.FormItem>
		</>
	);
});
