import './style.scss';

import { Input } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { translations } from 'locales/i18n';

interface Props {
	disabled?: boolean;
}

export const NumberOfLots = memo((props: Props) => {
	const { disabled } = props;
	const { t: translate } = useTranslation();
	const msg = translations.app.containers.Transactions.components.NumberOfLots;

	let label = translate(msg.label);

	return (
		<>
			<GenericForm.FormItem name="numberOfLots" label={label}>
				<Input
					disabled={disabled}
					className="futures__input"
					placeholder={label}
					step={1}
					key="numberOfLots"
					type="text"
				/>
			</GenericForm.FormItem>
		</>
	);
});
