import { FixedColumn } from 'types/FixedColumn';
import { GenericError } from 'types/GenericError';
import { GenericOption } from 'types/GenericOption';
import { IGenericPagination } from 'types/GenericPagination';

interface Restrictions {
	canBeApproved: boolean;
	canBeDenied: boolean;
	canBeResent: boolean;
	canBeAcknowledged: boolean;
	canBeEdited: boolean;
	canBeCanceled: boolean;
	canCreateContract: boolean;
}

interface Customer {
	id: string | null;
	name: string;
	number: number | null;
	phone: string | null;
	email: string | null;
	address: string | null;
	workPhoneNumber: string | null;
	isActive: boolean;
}

export interface ReviewAndReleaseRecord {
	id: string;
	creationDate: string;
	updateDate: string | null;
	orderNumber: string;
	type: string;
	typeName: string;
	accountNumber: number;
	accountName: string;
	isEditReject: boolean;
	isSell: boolean;
	side: string;
	symbol: {
		code: string;
		name: string | null;
		instrument: string | null;
	};
	futuresMonth: string;
	gtc: {
		isGTC: boolean;
		expiration: string | null;
	};
	status: string;
	price: number | null;
	avgFillPrice: number;
	quantity: number;
	workingQuantity: number;
	source: string;
	restrictions: Restrictions;
	marketInformation: string;
	wasAcknowledge: boolean;
	passFill: boolean;
	internalCode: string;
	customer: Customer;
}

export interface ReviewAndReleaseStatusUpdate
	extends Pick<
		ReviewAndReleaseRecord,
		| 'id'
		| 'status'
		| 'orderNumber'
		| 'avgFillPrice'
		| 'workingQuantity'
		| 'updateDate'
	> {}

/* --- STATE --- */
export interface ReviewAndReleaseState {
	fixedColumns: ReviewAndReleaseFixedColumns;

	selectedColumns: ReviewAndReleaseVisibleColumns;

	acknowledgeAndFill: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};

	acknowledgeAndFillUpdate: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};

	exportReviewAndRelease: {
		url: string | null;
		fileName: string | null;
		loading: boolean;
		error: GenericError | null;
	};

	activeOrderDetails: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};

	currentModal: Modal | null;

	currentView: ReviewAndReleaseViews;

	externalFill: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};

	list: {
		data: ReviewAndReleaseRecord[];
		loading: boolean;
		total: number;
		allLoaded: boolean;
		error: GenericError | null;
	};

	pagination: IGenericPagination;

	selectedFilters: ReviewAndReleaseFilters;

	selectedRows: any[];

	futureMonths: {
		data: GenericOption[];
		loading: boolean;
		error: GenericError | null;
	};
}

export type ReviewAndReleaseFixedColumns = ReviewAndReleaseColumns<FixedColumn>;

type ReviewAndReleaseVisibleColumns = ReviewAndReleaseColumns<boolean>;

interface ReviewAndReleaseColumns<T> {
	created: T;
	updateDate: T;
	status: T;
	account: T;
	orderNumber: T;
	sellBuy: T;
	source: T;
	symbol: T;
	futuresMonth: T;
	type: T;
	gtc: T;
	price: T;
	avgFillPrice: T;
	quantity: T;
	workingQty: T;
	customer: T;
	hrvystId: T;
	dots: T;
}

export type ContainerState = ReviewAndReleaseState;

export enum MenuActionType {
	approve = 'approve',
	deny = 'deny',
	acknowledge = 'acknowledge',
	resent = 'resent',
	view = 'view',
}

export enum Modal {
	AcknowledgeAndFill = 'AcknowledgeAndFill',
	ExternalFill = 'ExternalFill',
	EditExternalFill = 'EditExternalFill',
	Cancel = 'Cancel',
}

export enum OrderSource {
	contract = 'Contract',
	accumulation = 'Accumulation',
	offer = 'Offers',
	preHedge = 'Futures First',
}

export enum OrderStatus {
	Filled = 'Filled',
}

export interface ReviewAndReleaseAction {
	data: any[];
	type: MenuActionType;
}

/* FIXME: sprint 13 - US1170 Mau - Check if these are the correct names for filters, currently the endpoind with wrong filters */
export interface ReviewAndReleaseFilters {
	accountName?: string | null;
	endDate?: string | null;
	futuresMonth?: GenericOption[];
	number?: string | null;
	contractOrCustomer?: string;
	status?: GenericOption[];
	startDate?: string | null;
	sortColumnName?: string | null;
	sortOrder?: 0 | 1 | null | number;
	symbol?: GenericOption[];
	customerId?: GenericOption[];
}

export enum ReviewAndReleaseViews {
	table = 'table',
	details = 'details',
}

export interface ExportExcelPayload {
	successMsg: string;
	errorMsg: string;
}
