import React, { memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
interface Props {
	id: string;
	deliveryMonth: string;
	rollingFee: string;
	writingFee: string;
}

export const ServiceFeeRow = memo(
	({ id, deliveryMonth, rollingFee, writingFee }: Props) => {
		const { setValue } = useFormContext();
		const handleChange = () => {};

		useEffect(() => {
			setValue(`deliveryMonth-${id}`, deliveryMonth);
			setValue(`writingFee-${id}`, writingFee);
			setValue(`rollingFee-${id}`, rollingFee);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		return (
			<GenericForm.FormItem name={id} data-testid="fees-form-item">
				<GenericForm.FormItem name={`deliveryMonth-${id}`}>
					<Input
						name="deliveryMonth"
						key="deliveryMonth"
						type="string"
						value={deliveryMonth}
						disabled
					></Input>
				</GenericForm.FormItem>
				<GenericForm.FormItem name={`writingFee-${id}`}>
					<Input
						type="number"
						name={`writingFee-${id}`}
						handleChange={handleChange}
						step={0.0001}
					></Input>
				</GenericForm.FormItem>
				<GenericForm.FormItem name={`rollingFee-${id}`}>
					<Input
						type="number"
						name={`rollingFee-${id}`}
						handleChange={handleChange}
						step={0.0001}
					></Input>
				</GenericForm.FormItem>
			</GenericForm.FormItem>
		);
	},
);
