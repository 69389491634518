import './styles.scss';

import { PlusOutlined } from '@ant-design/icons';
import React, { memo, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { GenericForm } from 'app/components/GenericForm';
import { FormButtonsProps } from 'app/components/GenericForm/types';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { getNumberArray } from 'utils/helpers';

import { Header } from '../../components/Header';
import { NtcAdminRow } from '../../components/NtcAdminRow';
import { actions } from '../../slice';
import { getFormValues, NTC_ADMIN_DOMAIN } from '../../utils/helpers';
import { useNtcAdminFormData } from './hooks/useNtcAdminFormData';

export default memo(function NtcAdminForm() {
	const {
		dispatch,
		formInstance,
		initialValues,
		isCreatingNTCs,
		NTCs,
		translate,
		validationSchema,
	} = useNtcAdminFormData();

	const [ntcFieldCount, setNtcFieldCount] = useState(NTCs?.length || 1);

	const getTranslation = (key: string, values?: any) =>
		translate(NTC_ADMIN_DOMAIN.Views.NtcAdminForm[key], values);

	const handleCancel = () => {
		formInstance.reset({
			...initialValues,
		});
		setNtcFieldCount(NTCs?.length || 1);
		globalActions.getNtcAdminRequest();
	};
	const handleSubmit = (values: FieldValues) => {
		const mappedValues = getFormValues(isCreatingNTCs, values);
		dispatch(
			actions.setNtcAdminRequest({
				data: mappedValues,
			}),
		);
	};

	const addAdditionalField = () => {
		setNtcFieldCount(ntcFieldCount + 1);
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			children: getTranslation('cancelButton'),
			dataTestId: 'cancel-button',
			htmlType: 'button',
			key: 'cancel',
			onClick: handleCancel,
		},
		{
			children: getTranslation('submitButton'),
			dataTestId: 'submit-button',
			htmlType: 'submit',
			key: 'submit',
		},
	];
	return (
		<GenericForm.Form
			buttonsDefinition={btnsDefinition}
			className="ntc-admin-form"
			formInstance={formInstance}
			key="ntcAdminForm"
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
		>
			<>
				<Header />
				{getNumberArray(ntcFieldCount).map((index: number) => (
					<NtcAdminRow index={index} ntcAdmin={NTCs?.[index]} />
				))}
				<div className="additional-field">
					<p onClick={addAdditionalField}>
						<PlusOutlined /> {getTranslation('addAdditionalField')}
					</p>
				</div>
			</>
		</GenericForm.Form>
	);
});
