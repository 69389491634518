import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import { LoadFuturesPricePayload } from '../types';

const { futuresPrice } = apiEndpoints;

export function* getFuturePriceFromMarket(
	action: PayloadAction<LoadFuturesPricePayload>,
) {
	const source = action.payload.source;
	const params = action.payload.params;

	if (!params.commodityId || !params.futuresMonth) {
		return;
	}

	let requestURL = yield new URL(`${apiRoutes.base}/${futuresPrice}`);
	requestURL.searchParams.append('CommodityId', params.commodityId);
	requestURL.searchParams.append('FutureMonth', params.futuresMonth);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		const payload = {
			source,
			data: responseData?.price,
		};
		const cachedFuturesPricePayload = {
			futuresPrice: responseData?.price,
			priceControl: responseData?.priceControl,
		};

		yield put(actions.futuresPriceLoaded(payload));
		yield put(actions.cachedFuturesPriceLoaded(cachedFuturesPricePayload));
	} else if (responseError.detail) {
		const payload = {
			source,
			error: responseError,
		};

		yield put(actions.futuresPriceError(payload));
	}
}
