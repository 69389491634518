import './style.scss';

import React from 'react';

import { basisFormat } from 'utils/helpers';
interface Props {
	elements: any;
}
export const PopoverContent = ({ elements }: Props) => {
	const geElements = () => {
		let elementsWithValue: any = [];
		let firstElementsRow: any = [];

		elementsWithValue = elements.filter((element) => !!element.value);

		if (elementsWithValue.length > 3) {
			firstElementsRow = elementsWithValue.slice(0, 2);
			elementsWithValue = elementsWithValue.slice(2, elementsWithValue.length);
		}

		return (
			<>
				{firstElementsRow.length > 0 && (
					<div className="row">
						{firstElementsRow?.map(
							(element, index) =>
								!!element.value && (
									<div
										className="column half"
										key={`${element.label}-${index}`}
									>
										<p className="value">{basisFormat(element.value)}</p>
										<p className="label">{element.label}</p>
									</div>
								),
						)}
					</div>
				)}
				<div className="row">
					{elementsWithValue?.map(
						(element, index) =>
							!!element.value && (
								<div className="column" key={`${element.label}-${index}`}>
									<p className="value">{basisFormat(element.value)}</p>
									<p className="label">{element.label}</p>
								</div>
							),
					)}
				</div>
			</>
		);
	};
	return elements && <div className="popover-content">{geElements()}</div>;
};
