/**
 *
 * FuturesPrice
 *
 */
import React, { memo } from 'react';

import { GenericTable } from 'app/components/GenericTable';
import { CONSTANTS } from 'utils/constants';
import { currencyFormat } from 'utils/helpers';

interface Props {
	data: any;
}

const isOrderFilled = ({ futures, passFill }: any) =>
	futures !== null || !!!passFill;

export const FuturesPrice = memo(({ data }: Props) => (
	<>
		{isOrderFilled(data) && (
			<GenericTable.Column>
				<p
					className="text text--large text--centered"
					data-testid="futures-price"
				>
					{currencyFormat(
						data.futuresPrice,
						CONSTANTS.FIXED_DECIMALS,
						CONSTANTS.FIXED_DECIMALS,
					)}
				</p>
			</GenericTable.Column>
		)}
	</>
));
