import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getNumberArray } from 'utils/helpers';
import Yup from 'yupCustom';

import { selectAllRegions } from '../selectors';
import { REGIONS_FORM_ITEMS } from '../types';
import { REGION_ENTRIES_MIN, REGIONS_DOMAIN } from '../utils/helpers';

export const useRegionSchema = () => {
	const { t: translate } = useTranslation();

	const regions = useSelector(selectAllRegions);

	const schema = useMemo(
		() =>
			getNumberArray(
				regions?.length === 0 ? REGION_ENTRIES_MIN : regions?.length,
			).reduce(
				(schema, currentValue, index: number) => {
					const requiredMsg = translate(REGIONS_DOMAIN.requiredValidation);

					const isCreatingRegions = regions?.length === 0;
					const currentRegion = regions?.[index];

					const regionsId = currentRegion?.id || index;

					const regionNameLabel = `${REGIONS_FORM_ITEMS.REGION_NAME_LABEL}-${regionsId}`;
					const erpIdLabel = `${REGIONS_FORM_ITEMS.ERP_ID_LABEL}-${regionsId}`;
					const regionStatus = `${REGIONS_FORM_ITEMS.STATUS}-${regionsId}`;

					const createValidationRule = {
						message: requiredMsg,
						name: 'isActive',
						test: function (label: string) {
							return !!label || !this[regionStatus];
						},
					};

					return {
						initialValues: {
							...schema.initialValues,
							[regionStatus]: isCreatingRegions || !!currentRegion?.isEnabled,
							[regionNameLabel]: currentRegion?.name,
							[erpIdLabel]: currentRegion?.erpNumber,
						},
						validationSchema: schema.validationSchema.shape({
							[regionStatus]: Yup.boolean(),
							[regionNameLabel]: Yup.string()
								.required(requiredMsg)
								.max(15, translate(REGIONS_DOMAIN.charactersValidation))
								.test(createValidationRule),
							[erpIdLabel]: Yup.string()
								.required(requiredMsg)
								.max(15, translate(REGIONS_DOMAIN.charactersValidation))
								.test(createValidationRule),
						}),
					};
				},
				{ initialValues: {}, validationSchema: Yup.object().shape({}) },
			),
		[regions],
	);

	return schema;
};
