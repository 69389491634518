import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { translations } from 'locales/i18n';
import { mapToLabelValue } from 'utils/helpers';
import { useEnabledRegions } from 'utils/hooks/useEnabledRegions';

const domain = translations.app.containers.Settings.definitions.fields.region;

export const Region = memo(() => {
	const { t: translate } = useTranslation();
	const regionsList = useEnabledRegions();
	const regionsOptions = mapToLabelValue(regionsList);

	const getTranslation = (key: string) => translate(domain[key]);

	return (
		<GenericForm.FormItem
			data-testid="region-form-item"
			label={getTranslation('label')}
			name="region"
		>
			<Dropdown
				data-testid="region-input-item"
				options={regionsOptions}
				placeholder={getTranslation('placeholder')}
			></Dropdown>
		</GenericForm.FormItem>
	);
});
