import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { reviewAndRealeaseExternalFill } = apiEndpoints;

export function* createExternalFill(action) {
	const { data, successMessage } = action.payload;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${reviewAndRealeaseExternalFill}`,
	);
	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		data,
	);

	if (!!responseData) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
			}),
		);

		yield put(actions.createdExternalFill(responseData));
		yield put(actions.loadReviewAndReleaseList());
		yield put(actions.setCurrentModal(null));
	} else if (!!responseError.detail) {
		yield put(actions.externalFillError(responseError));
	}
}
