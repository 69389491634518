import React from 'react';
import { useSelector } from 'react-redux';

import { QuantityToPrice } from 'app/containers/Contracts/components/Business/QuantityToPrice';
import { useTranslations } from 'app/containers/Contracts/components/Modals/shared/useTranslations';
import { selectActiveContract } from 'app/containers/Contracts/selectors';
import { AssignedRegion } from 'app/containers/Transactions/components/AssignedRegion';
import { Basis } from 'app/containers/Transactions/components/Basis';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractNumber } from 'app/containers/Transactions/components/ContractNumber';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { DeliveryDates } from 'app/containers/Transactions/components/DeliveryDates';
import { DeliveryLocation } from 'app/containers/Transactions/components/DeliveryLocation';
import { Employee } from 'app/containers/Transactions/components/Employee';
import { ExpirationDate } from 'app/containers/Transactions/components/ExpirationDate';
import { ExpirationDateGTC } from 'app/containers/Transactions/components/ExpirationDateGTC';
import { Fees } from 'app/containers/Transactions/components/Fees';
import { Freight } from 'app/containers/Transactions/components/Freight';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Location } from 'app/containers/Transactions/components/Location';
import { CONSTANTS } from 'utils/constants';
import {
	customFormat,
	dateFormatYearMonthDay,
	dateMoment,
	getExpirationDate,
	getFormattedExpirationDate,
	isDeliveryDateCustom,
	isDeliveryDateWindow,
	validatePriceFormat,
} from 'utils/helpers';
import Yup from 'yupCustom';

import { validateMinMaxValue } from '../../../shared/helpers';

export const useHTASchema = () => {
	const translations = useTranslations();

	let orderData = useSelector(selectActiveContract) as any;
	if (!!!orderData) return null;

	const requiredMsg = translations.validations.required;
	return {
		initialValues: {
			contractNumber: orderData.number,
			theirContract: orderData.theirContract,
			commodity: { value: orderData.commodityId },
			location: {
				label: orderData.locationName,
				value: orderData.locationId,
			},
			deliveryLocation: {
				label: orderData.deliveryLocationName,
				value: orderData.deliveryLocationId,
			},
			assignedRegion: {
				label: orderData?.regionName,
				value: orderData?.regionId,
			},
			cropYear: orderData.cropYear,
			deliveryDate: [
				dateMoment(orderData.deliveryStartDate),
				dateMoment(orderData.deliveryEndDate),
			],
			futuresMonth: {
				label: orderData.futuresMonth,
				value: orderData.futuresMonth,
			},
			futuresPrice: validatePriceFormat(orderData.futuresPrice),
			qtyPriceBalance: `${customFormat(orderData.remainingBalance, true, CONSTANTS.FIXED_QUANTITY_DECIMALS)}`,
			qtyPriceAmount: null,
			postedBasis: validatePriceFormat(orderData.postedBasis),
			pushBasis: validatePriceFormat(orderData.pushBasis),
			netBasis: validatePriceFormat(orderData.netBasis),
			freight: validatePriceFormat(orderData.freightPrice),
			fees1: validatePriceFormat(orderData.fees1),
			fees2: validatePriceFormat(orderData.fees2),
			employee: {
				label: orderData.employeeName,
				value: orderData.employeeId,
			},
			comments: null,
			gtcMode: false,
			expirationDateGTC: null,
			expirationDate: getExpirationDate(orderData),
		},
		validationSchema: Yup.object().shape({
			location: Yup.object().requiredOption(requiredMsg),
			deliveryLocation: Yup.object().requiredOption(requiredMsg),
			assignedRegion: Yup.object().requiredOption(requiredMsg),
			qtyPriceAmount: Yup.string()
				.required(translations.validations.required)
				.test(
					'minMaxValidation',
					translations.validations.rangeValue(
						customFormat(
							orderData.remainingBalance,
							true,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						),
					),
					(value: string) =>
						validateMinMaxValue(orderData.remainingBalance, value),
				)
				.nullable(),
			employee: Yup.object().requiredOption(requiredMsg),
			freight: Yup.number().typeError(translations.validations.number),
			fees1: Yup.number().typeError(translations.validations.number),
			fees2: Yup.number().typeError(translations.validations.number),
			postedBasis: Yup.number().nullable(),
			pushBasis: Yup.number().nullable(),
			netBasis: Yup.number().nullable(),
			expirationDateGTC: Yup.string().when('gtcMode', {
				is: false,
				then: Yup.string().typeError(requiredMsg).required(requiredMsg),
				otherwise: Yup.string().nullable(),
			}),
			expirationDate: Yup.string().nullable(),
			deliveryDate: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateCustom(mode),
				then: Yup.mixed().required(requiredMsg),
			}),
			deliveryDateWindow: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateWindow(mode),
				then: Yup.object().requiredOption(requiredMsg),
			}),
			futuresPrice: Yup.string().required(requiredMsg),
			futuresMonth: Yup.object().requiredOption(requiredMsg),
		}),
		elements: [
			<Commodity hidden />,
			<ContractNumber disabled />,
			<Location />,
			<DeliveryLocation />,
			<AssignedRegion />,
			<CropYears disabled />,
			<DeliveryDates
				filterByFutureMonth={orderData.futuresMonth}
				forceToWindow
			/>,
			<QuantityToPrice disabled={{ qtyPriceBalance: true }} />,
			<Basis disabled={{ netBasis: true }} />,
			<Futures
				futuresMonth={{ market: true, disabled: true }}
				futuresPrice={{ market: false, disabled: true }}
			/>,
			<ExpirationDateGTC messageGTC />,
			<ExpirationDate disabled longLabel />,
			<Freight />,
			<Fees />,
			<Employee />,
			<Comments />,
		],
		getSubmitValues: (values) => {
			const submitValues = {
				quantity: parseFloat(
					customFormat(
						values.qtyPriceAmount,
						false,
						CONSTANTS.FIXED_QUANTITY_DECIMALS,
					),
				),
				postedBasis: parseFloat(values.postedBasis),
				pushBasis: parseFloat(values.pushBasis),
				netBasis: parseFloat(values.netBasis),
				gtc: values.gtcMode,
				futuresPrice: parseFloat(values.futuresPrice) || 0,
				expiration: !values.expirationDateGTC
					? null
					: dateFormatYearMonthDay(values.expirationDateGTC),
				freightPrice: parseFloat(values.freight),
				fees1: parseFloat(values.fees1),
				fees2: parseFloat(values.fees2),
				employeeId: values.employee?.value,
				comments: values.comments ?? '',
				locationId: values.location?.value,
				deliveryLocationId: values.deliveryLocation?.value,
				expirationDate: getFormattedExpirationDate(values),
				deliveryStartDate: dateFormatYearMonthDay(values.deliveryDate?.[0]),
				deliveryEndDate: dateFormatYearMonthDay(values.deliveryDate?.[1]),
				isDeliveryDatesCustom: isDeliveryDateCustom(values.deliveryDatesMode),
				theirContract: orderData.theirContract,
				regionId: values?.assignedRegion?.value,
			};

			return submitValues;
		},
	};
};
