import { CONTRACT_TYPES } from 'utils/constants';

import { useTranslations } from '../../../shared/useTranslations';
import { useBasisSchema } from '../CashContract/BasisSchema';
import { useFlatPriceSchema } from '../CashContract/FlatPriceSchema';
import { useHTASchema } from '../CashContract/HTASchema';

// Implement own schemas changes when needed
export const useAdjustmentsSchema = (
	translations: ReturnType<typeof useTranslations>,
) => ({
	[CONTRACT_TYPES.flatPrice]: useFlatPriceSchema(translations, true),
	[CONTRACT_TYPES.basis]: useBasisSchema(translations, true),
	[CONTRACT_TYPES.hta]: useHTASchema(translations, true),
});
