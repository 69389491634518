import React, { memo } from 'react';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { OrderEntry } from 'types/OrderEntry';
import { DIGITS_CHARS } from 'utils/constants';

import { getOrderEntryId, isTextType } from '../utils/helpers';

interface Props {
	orderEntry: OrderEntry;
}

export const Txt = memo(({ orderEntry }: Props) => {
	const orderEntryId = getOrderEntryId(orderEntry);

	const handlePaste = (event) => {
		const pasteText = event.clipboardData.getData('Text').toUpperCase();

		let hasFoundNumber = false;

		for (
			let i = 0;
			i < pasteText?.length && !hasFoundNumber && isTextType(orderEntry?.type);
			i++
		) {
			if (DIGITS_CHARS.find((digit: string) => digit === pasteText?.[i])) {
				hasFoundNumber = true;
				event.preventDefault();
			}
		}
	};

	const handlePressDown = (event) => {
		const isANumber = !isNaN(event.key);

		if (isTextType(orderEntry?.type) && isANumber) {
			event.preventDefault();
		}
	};

	return (
		<GenericForm.FormItem
			data-testid={`${orderEntryId}-form-item`}
			label={orderEntry?.label}
			name={orderEntryId}
		>
			<Input
				maxLength={25}
				name={orderEntryId}
				onKeyDown={(event) => handlePressDown(event)}
				onPaste={(event) => handlePaste(event)}
			/>
		</GenericForm.FormItem>
	);
});
