import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { transFormToLabelValue } from 'utils/helpers';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { getCountries, getStates } = apiEndpoints;

export function* getCountriesList() {
	const requestURL = yield new URL(`${apiRoutes.base}/${getCountries}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		const transformedData = transFormToLabelValue(responseData);
		yield put(actions.retrievedCountriesList(transformedData));
	} else if (!!responseError) {
		yield put(actions.errorCountriesList(responseError));
	}
}

export function* getStatesList() {
	const requestURL = yield new URL(`${apiRoutes.base}/${getStates}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		const transformedData = transFormToLabelValue(responseData);
		yield put(actions.retrievedStatesList(transformedData));
	} else if (!!responseError) {
		yield put(actions.errorStatesList(responseError));
	}
}
