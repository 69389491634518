import { GenericError } from 'types/GenericError';
import { NtcAdmin } from 'types/NtcAdmin';

export enum NTC_ADMIN_FORM_ITEMS {
	ID = 'id',
	NTC_NAME_LABEL = 'ntcNameLabel',
	ERP_ID_LABEL = 'erpIdLabel',
	STATUS = 'isActive',
	NTC_ADMIN_DEFAULT = 'ntcAdmin',
	TYPE = 'type',
}

export interface ntcAdminState {
	ntcAdminUpdate: {
		data: NtcAdmin | null;
		error: GenericError | null;
		loading: boolean;
	};
}

export type NtcAdminSectionState = ntcAdminState;
