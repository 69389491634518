import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectFeatureFlags } from 'app/containers/GlobalSaga/selectors';
import { FeatureFlag } from 'types/FeatureFlags';
import Yup from 'yupCustom';

import { domain } from '..';
import { Commodity } from '../components/Commodity';
import { CropYears } from '../components/CropYears';
import { DoNotHedge } from '../components/DoNotHedge';
import { Region } from '../components/Region';

export const useActivateDNHSchema = () => {
	const { t: translate } = useTranslation();
	const requiredMsg = translate(domain.requiredMessage);
	const regionsEnabled =
		useSelector(selectFeatureFlags)[FeatureFlag.enableRegions];

	return {
		initialValues: {
			commodity: { value: null },
			region: { value: null },
			cropYear: null,
			doNotHedge: false,
		},
		validationSchema: regionsEnabled
			? Yup.object().shape({
					commodity: Yup.object().requiredOption(requiredMsg),
					region: Yup.object().requiredOption(requiredMsg),
					cropYear: Yup.number(),
					doNotHedge: Yup.boolean(),
				})
			: Yup.object().shape({
					commodity: Yup.object().requiredOption(requiredMsg),
					cropYear: Yup.number(),
					doNotHedge: Yup.boolean(),
				}),
		elements: regionsEnabled
			? [<Commodity />, <Region />, <CropYears />, <DoNotHedge />]
			: [<Commodity />, <CropYears />, <DoNotHedge />],
	};
};
