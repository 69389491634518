import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) =>
	state.reviewAndRelease || initialState;

export const selectAcknowledgeAnFill = createSelector(
	[selectDomain],
	(reviewAndReleaseState) => reviewAndReleaseState.acknowledgeAndFill.data,
);

export const selectActiveOrderDetails = createSelector(
	[selectDomain],
	(reviewAndReleaseState) => reviewAndReleaseState.activeOrderDetails,
);

export const selectCurrentModal = createSelector(
	[selectDomain],
	(reviewAndReleaseState) => reviewAndReleaseState.currentModal,
);

export const selectCurrentView = createSelector(
	[selectDomain],
	(reviewAndReleaseState) => reviewAndReleaseState.currentView,
);

export const selectExternalFill = createSelector(
	[selectDomain],
	(reviewAndReleaseState) => reviewAndReleaseState.externalFill,
);

export const selectFilters = createSelector(
	[selectDomain],
	(reviewAndReleaseState) => reviewAndReleaseState.selectedFilters,
);

export const selectSelectedColumns = createSelector(
	[selectDomain],
	(reviewAndReleaseState) => reviewAndReleaseState.selectedColumns,
);

export const selectFixedColumns = createSelector(
	[selectDomain],
	(reviewAndReleaseState) => reviewAndReleaseState.fixedColumns,
);

export const selectListAllLoaded = createSelector(
	[selectDomain],
	(reviewAndReleaseState) => reviewAndReleaseState.list.allLoaded,
);

export const selectListData = createSelector(
	[selectDomain],
	(reviewAndReleaseState) => reviewAndReleaseState.list.data,
);

export const selectListLoading = createSelector(
	[selectDomain],
	(reviewAndReleaseState) => reviewAndReleaseState.list.loading,
);

export const selectListTotal = createSelector(
	[selectDomain],
	(reviewAndReleaseState) => reviewAndReleaseState.list.total,
);

export const selectPagination = createSelector(
	[selectDomain],
	(reviewAndReleaseState) => reviewAndReleaseState.pagination,
);

export const selectSelectedRows = createSelector(
	[selectDomain],
	(reviewAndReleaseState) => reviewAndReleaseState.selectedRows,
);

export const selectExportTemplate = createSelector(
	[selectDomain],
	(reviewAndReleaseState) => reviewAndReleaseState.exportReviewAndRelease,
);

export const selectFuturesMonthsList = createSelector(
	[selectDomain],
	(reviewAndReleaseState) => reviewAndReleaseState.futureMonths.data,
);
