/**
 *
 * IconRoundedEvent
 *
 */

import './style.scss';

import { Popover } from 'antd';
import classNames from 'classnames';
import React, { memo } from 'react';

import { Transaction } from 'app/containers/LiveLedger/types';
import { FrontMap } from 'types/FrontMap';

interface Props {
	frontMap: FrontMap;
	data: Transaction;
	externalClass: string | boolean;
}

export const IconRoundedEvent = memo((props: Props) => {
	const { frontMap, data, externalClass } = props;

	const isValid = Object.keys(frontMap).length >= 0 && !!frontMap[data.event];

	return (
		<Popover
			content={
				<div className="popover--content">
					{isValid && frontMap[data.event].label}
				</div>
			}
			trigger="hover"
			className="popover--trigger"
		>
			<div
				className={classNames(
					isValid && frontMap[data.event].class,
					externalClass,
				)}
				data-testid={`event-icon-${data.contract?.internalNumber}`}
			>
				{(isValid && frontMap[data.event].icon) || data.event}
			</div>
		</Popover>
	);
});
