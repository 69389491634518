import './style.scss';

import classNames from 'classnames';
import { Form, Input } from 'formik-antd';
import React, { memo } from 'react';

import { FormInputProps } from './types';

export const FormInput = memo((props: FormInputProps) => {
	const {
		name,
		label,
		type,
		placeholder,
		handleChange,
		disabled,
		suffix,
		suffixReference,
		onBlur,
		onKeyUp,
		borderless = false,
	} = props;

	const handleBlur = () => {
		!!onBlur && onBlur(name);
	};

	const handleKeyUp = () => {
		!!onKeyUp && onKeyUp(name);
	};

	return (
		<Form.Item
			className="basic-input-form__label"
			label={label}
			name={name}
			key={name}
		>
			<Input
				data-testid="form-input-test"
				{...props}
				className={classNames('basic-input-form__input', {
					'basic-input-form__input--borderless': borderless,
				})}
				name={name}
				type={type ? type : 'text'}
				placeholder={placeholder}
				onChange={handleChange}
				disabled={disabled}
				suffix={suffix || (suffixReference && suffixReference.render())}
				onBlur={handleBlur}
				onKeyUp={handleKeyUp}
			/>
		</Form.Item>
	);
});
