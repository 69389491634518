import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.commodities || initialState;

export const selectCurrentModal = createSelector(
	[selectDomain],
	(commoditiesState) => commoditiesState.currentModal,
);

export const selectActiveCommodity = createSelector(
	[selectDomain],
	(commoditiesState) => commoditiesState.activeCommodity.data,
);

export const selectActiveCommodityLoading = createSelector(
	[selectDomain],
	(commoditiesState) => commoditiesState.activeCommodity.loading,
);

export const selectListData = createSelector(
	[selectDomain],
	(commoditiesState) => commoditiesState.list.data,
);

export const selectListLoading = createSelector(
	[selectDomain],
	(commoditiesState) => commoditiesState.list.loading,
);

export const selectCommodityStatusError = createSelector(
	[selectDomain],
	(commoditiesState) => commoditiesState.commodityStatus.error,
);

export const selectProductsList = createSelector(
	[selectDomain],
	(commoditiesState) => commoditiesState.products.data,
);

export const selectHedgeMapTemplate = createSelector(
	[selectDomain],
	(commoditiesState) => commoditiesState.hedgeMapTemplate,
);
