import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) =>
	state.notificationsHistory || initialState;

export const selectNotificationsHistory = createSelector(
	[selectDomain],
	(history) => history.notificationsList.data,
);
export const selectNotificationsHistoryLoading = createSelector(
	[selectDomain],
	(history) => history.notificationsList.loading,
);
export const selectNotificationsHistoryTotal = createSelector(
	[selectDomain],
	(history) => history.notificationsList.total,
);
export const selectPagination = createSelector(
	[selectDomain],
	(history) => history.pagination,
);
