import './style.scss';

import { ArrowsAltOutlined, CloseOutlined } from '@ant-design/icons';
import { Card, Col, Popover, Row, Spin } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAbac } from 'react-abac';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { actions as contractAction } from 'app/containers/Contracts/slice';
import { ContractsModals } from 'app/containers/Contracts/types';
import { selectDestinationsList } from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { actions as offerActions } from 'app/containers/Offers/slice';
import { OffersModals, TransactionType } from 'app/containers/Offers/types';
import { translations } from 'locales/i18n';
import { Permission } from 'types/Authorization';
import { DeliveryDateMode } from 'types/DeliveryDateMode';
import {
	CONTRACT_TYPE_VALUES,
	CONTRACT_TYPES_GUIDS,
	TRANSACTION_TYPES_ENUM,
	TRANSACTION_TYPES_GUIDS,
} from 'utils/constants';
import { createRangeCalendarOption } from 'utils/helpers';
import { useResize } from 'utils/hooks/useResize';

import useAutoDispatch from '../../customHooks/useAutoDispatch';
import {
	selectCashbidsData,
	selectCashbidsDataLoading,
	selectSelectedFilters,
} from '../../selectors';
import { actions } from '../../slice';
import { Filters } from './components/Filters';
import { PopoverContent } from './components/PopoverContent';
import { createCashbidsMatrix, uniqueLocations } from './utils/helper';

interface Props {
	setIsCashBidsMaximize: Dispatch<SetStateAction<boolean>>;
	isCashBidsMaximize: boolean;
}

export function Cashbids(props: Props) {
	const { setIsCashBidsMaximize, isCashBidsMaximize } = props;

	const dispatch = useDispatch();
	const { userHasPermissions } = useAbac();
	const { t: translate } = useTranslation();

	const [uniqueLocationsArr, setUniqueLocationsArr]: any = useState([]);
	const CashbidsData = useSelector(selectCashbidsData);
	const CashbidsDataLoading = useSelector(selectCashbidsDataLoading);
	const selectedFilters = useSelector(selectSelectedFilters);
	const destinationList = useSelector(selectDestinationsList);
	const { isMobile } = useResize();

	const extractOnlyLocation = destinationList?.data?.map((item) => item.name);

	const getTranslation = (key: string) =>
		translate(
			translations.app.containers.CashbidsAndQuotes.Cashbids.Cashbids[key],
		);

	// //TODO:* remove the below code when websocket/signalR is avalable
	useAutoDispatch(
		CashbidsData?.length > 0,
		() => dispatch(actions.loadCashbids()),
		60000,
	);

	useEffect(() => {
		setUniqueLocationsArr(uniqueLocations(CashbidsData));
	}, [CashbidsData]);

	// Hanlde resizing of CashBids And Futures Qoutes section
	const handleResizeSection = () => {
		setIsCashBidsMaximize(!isCashBidsMaximize);
	};

	// This function creates a contract object with the given contract type and item details
	const populateOrderData = (key, item, contractType, isOffer = false) => {
		// Create delivery date window option using the start and end dates of the item
		const { value: deliveryDateWindowValue } = createRangeCalendarOption(
			item.start,
			item.end,
		);

		// Construct the order details (for contract and offer) object using the given parameters
		const orderDetails = {
			action: 'buy',
			commodity: { value: item.commodityId },
			contract: {
				label: CONTRACT_TYPES_GUIDS[contractType],
				value: contractType,
				type: contractType,
			},
			cropYear: item.cropYear,
			deliveryDate: [moment(item.start), moment(item.end)],
			deliveryDateWindow: { value: deliveryDateWindowValue },
			deliveryDatesMode: { value: DeliveryDateMode.Window },
			futuresMonth: { value: item.deliveryMonthCode },
			location: { value: null },
			deliveryLocation: { value: null, filterId: item[key][2] },
			postedBasis: item[key][0],
			transaction: {
				label: isOffer
					? TransactionType.Offer
					: TRANSACTION_TYPES_GUIDS[TRANSACTION_TYPES_ENUM['Cash Contract']],
				value: isOffer
					? TRANSACTION_TYPES_ENUM.Offer
					: TRANSACTION_TYPES_ENUM['Cash Contract'],
				type: undefined,
			},
		};

		// If the key is included in the extractOnlyLocation array, dispatch actions to set the contract state and current modal
		if (extractOnlyLocation.includes(key)) {
			dispatch(contractAction.setCashbidsState(orderDetails));
			if (isOffer) {
				dispatch(offerActions.setCurrentModal(OffersModals.Create));
			} else {
				dispatch(contractAction.setCurrentModal(ContractsModals.Create));
			}
		} else {
			dispatch(
				globalActions.addNotification({
					type: CustomNotificationTypes.WARNING,
					message: `Sorry, it looks like you don't have permission to create orders for ${key}`,
					showDescription: true,
				}),
			);
		}
	};

	//``openOrderModal`` is a higher-order function that takes the contract type as an argument and returns a new function that calls populateOrderData with the provided contract type.
	const openOrderModal =
		(contractType) =>
		(key, item, isOffer = false) => {
			const permission =
				contractType === CONTRACT_TYPE_VALUES.basis
					? Permission.CONTRACTSERVICE_CONTRACTBASIS_CREATE
					: Permission.CONTRACTSERVICE_CONTRACTFLATPRICE_CREATE;

			// Checking if user has permission to create this contract (i.e Basis or Flat Price)
			if (userHasPermissions(permission)) {
				populateOrderData(key, item, contractType, isOffer);
			} else {
				dispatch(
					globalActions.addNotification({
						type: CustomNotificationTypes.WARNING,
						message: `Sorry, it looks like you don't have permission to create ${contractType === CONTRACT_TYPE_VALUES.basis ? 'Basis' : 'Flat Price'} Order`,
						showDescription: true,
					}),
				);
			}
		};

	// This function calls openOrderModal higher-order functions which opens a basis order by calling populateOrderData with the basis contract type and pre populated data
	const openBasisOrderModal = openOrderModal(CONTRACT_TYPE_VALUES.basis);
	// This function calls openOrderModal higher-order functions which opens a flat price order by calling populateOrderData with the flat price contract type and pre populated data
	const openFlatPriceOrderModal = openOrderModal(
		CONTRACT_TYPE_VALUES.flatPrice,
	);

	const dynamicHeaders = ['Beginning', 'Ending', 'FutCont'].concat(
		uniqueLocationsArr,
	);

	const hasSelectedCommodity = () => {
		return (
			selectedFilters.cashbidsCommodities.length > 0 &&
			selectedFilters.cashbidsCommodities[0]?.value !== 'all'
		);
	};

	const isCashbidsDataEmpty = () => {
		return CashbidsData.length < 1;
	};

	const renderWarningMessage = () => {
		return (
			<div className="cashbid--warning-writeup">
				<h1>{getTranslation('WarningMessage')}</h1>
			</div>
		);
	};

	const renderNoCashbidsFoundMessage = () => {
		return (
			<div className="cashbid--warning-writeup">
				{CashbidsDataLoading ? <Spin /> : <h1>{getTranslation('NotFound')}</h1>}
			</div>
		);
	};

	return (
		<Card bordered={true} className="cashbid">
			<div className="cashbid__data">
				<Row className="grid-menu" gutter={16}>
					<Col span={10}>
						<Filters />
					</Col>
					<Col span={2}>
						{!isCashBidsMaximize ? (
							<ArrowsAltOutlined
								onClick={handleResizeSection}
								className="cashbid__icon"
							/>
						) : (
							<CloseOutlined
								onClick={handleResizeSection}
								className="cashbid__icon"
							/>
						)}
					</Col>
				</Row>
				<div className="cashbid__table-parent">
					{!hasSelectedCommodity() ? renderWarningMessage() : null}
					{hasSelectedCommodity() && isCashbidsDataEmpty()
						? renderNoCashbidsFoundMessage()
						: null}
					{hasSelectedCommodity() && !isCashbidsDataEmpty() ? (
						<>
							<div className="cashbid__table-header">
								{dynamicHeaders.map((header) => (
									<div className="cashbid__location-grid" key={header}>
										<Popover
											trigger={isMobile ? 'click' : 'hover'}
											placement="top"
											content={
												<span className="cashbid__header-tooltip">
													{header}
												</span>
											}
										>
											<span>{header}</span>
										</Popover>
									</div>
								))}
							</div>
							{createCashbidsMatrix(CashbidsData, uniqueLocationsArr).map(
								(row, index) => (
									<div className="cashbid__table-body" key={index}>
										{Object.entries(row).map(([key, value]) => {
											if (
												[
													'futurePrice',
													'commodityId',
													'deliveryMonthCode',
													'cropYear',
													'start',
													'end',
													'locationId',
													'locationName',
												].includes(key)
											) {
												return null;
											}
											return (
												<div className="cashbid__location-grid-item" key={key}>
													{key !== 'Beginning' &&
													key !== 'Ending' &&
													key !== 'FutCont' ? (
														<div
															className={classNames({
																'cashbid--isPointer':
																	extractOnlyLocation.includes(key),
															})}
														>
															<Popover
																trigger={isMobile ? 'click' : 'hover'}
																placement="top"
																content={
																	<PopoverContent
																		row={row}
																		keyValue={key}
																		createContractHandler={openBasisOrderModal}
																		createOfferHandler={openBasisOrderModal}
																	/>
																}
															>
																{value[0]?.toLocaleString('en', {
																	useGrouping: false,
																	minimumFractionDigits: 2,
																})}
															</Popover>
														</div>
													) : (
														value
													)}{' '}
													<div className="cashbid--isBlack">
														{key !== 'Beginning' &&
														key !== 'Ending' &&
														key !== 'FutCont' &&
														value !== '' ? (
															<div
																className={classNames({
																	'cashbid--isPointer':
																		extractOnlyLocation.includes(key),
																})}
															>
																<Popover
																	trigger={isMobile ? 'click' : 'hover'}
																	placement="top"
																	content={
																		<PopoverContent
																			row={row}
																			keyValue={key}
																			createContractHandler={
																				openFlatPriceOrderModal
																			}
																			createOfferHandler={
																				openFlatPriceOrderModal
																			}
																		/>
																	}
																>
																	{(value[1] + value[0]).toFixed(4)}
																</Popover>
															</div>
														) : (
															''
														)}
													</div>
												</div>
											);
										})}
									</div>
								),
							)}
						</>
					) : null}
				</div>
			</div>
		</Card>
	);
}
