import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectCustomerNotificationGroups,
	selectCustomerNotificationGroupsLoading,
} from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';

import { actions } from '../slice';
import { Modal, NotificationGroupListHookValue, View } from '../types';

export const useCustomerGroupList = (): NotificationGroupListHookValue => {
	const dispatch = useDispatch();
	const groupList = useSelector(selectCustomerNotificationGroups);
	const listLoading = useSelector(selectCustomerNotificationGroupsLoading);

	const handleCreateGroup = () => {
		dispatch(actions.setCurrentView(View.Create));
		dispatch(actions.loadCustomerGroupNonMembers());
	};
	const handleEditGroup = ({ id }) => {
		dispatch(actions.loadCustomerGroupDetails(id));
		dispatch(actions.loadCustomerGroupMembers(id));
		dispatch(actions.loadCustomerGroupNonMembers(id));
	};
	const handleDeleteGroup = (rowData) => {
		dispatch(actions.setActiveCustomerGroup(rowData));
		dispatch(actions.setCurrentModal(Modal.Delete));
	};

	useEffect(() => {
		dispatch(globalActions.loadCustomerNotificationGroupList());
	}, []);

	return {
		handleCreateGroup,
		handleEditGroup,
		handleDeleteGroup,
		groupList,
		listLoading,
	};
};
