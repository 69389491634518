import './style.scss';

import React, { memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'app/components/Checkbox';
import { GenericForm } from 'app/components/GenericForm';
import { translations } from 'locales/i18n';

export const AppliedUnappliedBushel = memo(() => {
	const { t: translate } = useTranslation();
	const msg =
		translations.app.containers.Contracts.components.AppliedUnappliedBushel;
	const unAppliedBushel = translate(msg.UnappliedBushel);

	return (
		// eslint-disable-next-line no-new-wrappers
		<GenericForm.FormItem
			name="isUnappliedLoad"
			wrapperCol={{ span: 6, offset: 3 }}
		>
			<Checkbox label={unAppliedBushel} />
		</GenericForm.FormItem>
	);
});
