import './style.scss';

import { Input as InputAntD } from 'antd';
import { InputProps } from 'antd/lib/input';
import classNames from 'classnames';
import React, { memo } from 'react';

interface Props extends InputProps {
	name: string;
	type?: string;
	label?: string;
	placeholder?: string;
	handleChange?: any;
	value?: any;
	disabled?: boolean;
	suffix?: JSX.Element;
	suffixReference?: any;
	onBlur?: any;
	onKeyUp?: any;
	borderless?: boolean;
}

export const Input = memo((props: Props) => {
	const {
		name,
		type,
		placeholder,
		onChange,
		disabled,
		suffix,
		suffixReference,
		onBlur,
		onKeyUp,
		borderless = false,
	} = props;

	const handleBlur = () => {
		!!onBlur && onBlur(name);
	};

	const handleKeyUp = () => {
		!!onKeyUp && onKeyUp(name);
	};

	return (
		<InputAntD
			{...props}
			className={classNames('basic-input-form__input', {
				'basic-input-form__input--borderless': borderless,
			})}
			data-testid="form-input-test"
			disabled={disabled}
			name={name}
			onBlur={handleBlur}
			onChange={onChange}
			onKeyUp={handleKeyUp}
			placeholder={placeholder}
			suffix={suffix || (suffixReference && suffixReference.render())}
			type={type ? type : 'text'}
		/>
	);
});
