import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectFeatureFlags,
	selectNonNtcContractTypeCatalog,
} from 'app/containers/GlobalSaga/selectors';
import { ModalWizard } from 'app/containers/Settings/sections/Commodities/components/ModalWizard';
import {
	selectActiveCommodity,
	selectHedgeMapTemplate,
} from 'app/containers/Settings/sections/Commodities/selectors';
import { translations } from 'locales/i18n';
import { FeatureFlag } from 'types/FeatureFlags';
import { DATE_FORMAT } from 'utils/constants';
import { dateMoment } from 'utils/helpers';
import { useEnabledRegions } from 'utils/hooks/useEnabledRegions';

import { getBrokerMapping } from '../../helpers';
import { useCommodityWizardHook } from '../../hooks/useCommodityWizard';
import { actions } from '../../slice';
import { CommodityCropSection } from '../../types';
import {
	cropYearsObject,
	useCropRangePicker,
} from '../schemas/useCropRangePicker';
import { useEditCommoditySchema } from '../schemas/useEditCommoditySchema';
import { useHedgeAccountSchema } from '../schemas/useHedgeAccountSchema';
import { useHedgeMapping } from '../schemas/useHedgeMappingSchema';

export const EditCommodity = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const commodityData = useSelector(selectActiveCommodity);
	const uploadedFile = useSelector(selectHedgeMapTemplate);
	const nonNtcContractTypes = useSelector(selectNonNtcContractTypeCatalog);
	const regions = useEnabledRegions();
	const [hasAdditionalCropYear, setHasAdditionalCropYear] = useState(false);
	const [updatedMonths, setUpdatedMonths] = useState<Number>(0);
	const featureFlags = useSelector(selectFeatureFlags);

	const regionsEnabled = featureFlags[FeatureFlag.enableRegions];
	const domain = translations.app.containers.Settings.commodities;
	const wizardSchema = [
		{
			name: translate(domain.editCommodity),
			formDefinitions: useEditCommoditySchema(
				hasAdditionalCropYear,
				setHasAdditionalCropYear,
			),
		},
		{
			name: translate(domain.cropYear),
			formDefinitions: useCropRangePicker(true),
		},
		{
			name: translate(domain.hedgeMapping),
			formDefinitions: useHedgeMapping({
				uploadAction: actions.uploadEditHedgeMapTemplate,
			}),
		},
		{
			name: translate(domain.brokerMapping),
			formDefinitions: useHedgeAccountSchema(),
		},
	];

	/**
	 * Generates an object containing dates increased by 'n' years from the given start date.
	 */
	const cropYearsListObject = (
		startDate: string,
		numberOfCropYears: number,
	): Record<string, string> => {
		const cropYears: Record<string, string> = {};
		const dateInstance = moment(startDate);

		for (let i = 0; i <= numberOfCropYears; i++) {
			cropYears[i] = dateInstance
				.add(i === 0 ? 0 : 1, 'years')
				.subtract(i === 0 ? 0 : 1, 'days')
				.format(DATE_FORMAT);
		}

		return cropYears;
	};

	/**
	 * Returns the number of additional crop years based on the provided values.
	 */
	const getNumberOfAdditionalCropYears = (values): number => {
		return Array.from({ length: 4 }).reduce((count: number, _, i) => {
			return values[`addCropYear${i}`] ? count + 1 : count;
		}, 0);
	};

	/**
	 * Generates a list of dates starting from a given startDate, with a certain number of years added based on the values object.
	 */
	const generateList = (startDate: string, values): string[] => {
		if (!startDate) return [];

		const numberOfCropYears = getNumberOfAdditionalCropYears(values);
		const cropYears = cropYearsListObject(startDate, numberOfCropYears);

		return Object.values(cropYears);
	};

	const getMonthsBetweenDates = (startDate: Date, endDate: Date): number => {
		const start = moment(startDate);
		const end = moment(endDate);

		const months = end.diff(start, 'months', true) + 1;

		if (start.date() === 1) {
			return Math.floor(months);
		} else {
			return Math.ceil(months);
		}
	};

	const handleGenerateExcelForEdit = (values): void => {
		if (!commodityData) return;

		const { addCropYear2 } = values;
		const { cropYearStartDate, id, numberOfCropYears, name, productId } =
			commodityData || {};

		const cropYearDates = generateList(cropYearStartDate, values);

		if (cropYearDates.length < 2) return;

		const months = getMonthsBetweenDates(
			moment(cropYearDates[0]).toDate(),
			moment(cropYearDates[1]).toDate(),
		);

		setUpdatedMonths(months);

		dispatch(
			actions.loadFilledHedgemapTemplate({
				commodityId: id,
				additionalCropYearsAdded: getNumberOfAdditionalCropYears(values),
				numberOfCropYears:
					numberOfCropYears + getNumberOfAdditionalCropYears(values),
				name: name,
				productId: productId,
				startDate: moment(cropYearDates[0]).format(DATE_FORMAT),
				endDate: moment(cropYearDates[cropYearDates.length - 1]).format(
					DATE_FORMAT,
				),
				months: months,
				hasFourthCrop: Boolean(addCropYear2),
			}),
		);
	};

	const handleGenerateBrokerMappingForEdit = (values, actions) => {
		const { addCropYear, cropsSection } = values;
		const { setFieldValue } = actions;

		const startDateYear =
			dateMoment(commodityData?.cropYearStartDate).year() +
			(commodityData?.numberOfCropYears || 0);
		const numberOfAdditionalCropYears = getNumberOfAdditionalCropYears(values);
		if (regionsEnabled) {
			const regionToYearsList: CommodityCropSection = regions.reduce(
				(acc, region) => {
					const yearsList = Array.from(
						{ length: numberOfAdditionalCropYears },
						(_, i) => ({
							year: startDateYear + i,
							HTA: '',
							Basis: '',
							FlatPrice: '',
						}),
					);
					acc[region.id] = yearsList;
					return acc;
				},
				{},
			);

			setFieldValue('cropsSection', regionToYearsList);
		} else {
			const yearsList = Array.from(
				{ length: numberOfAdditionalCropYears },
				(_, i) => ({
					year: startDateYear + i,
					HTA: '',
					Basis: '',
					FlatPrice: '',
				}),
			);
			setFieldValue('cropsSection', yearsList);
		}
		if (!addCropYear && cropsSection.length === 4 && cropsSection.length) {
			cropsSection.pop();
			setFieldValue('cropsSection', [...cropsSection]);
		}
	};

	const handleSubmit = (values) => {
		if (!commodityData) return;

		const {
			commodity: commodityName,
			cropsSection,
			priceCtrl,
			basisCtrl,
			product,
			erpNumber,
			addCropYear,
		} = values;
		const cropYearDates = generateList(
			commodityData?.cropYearStartDate,
			values,
		);
		const fileId =
			uploadedFile?.uploadResponse?.data?.data?.fileId || commodityData.fileId;
		const brokerMappingValue = getBrokerMapping(
			cropsSection,
			nonNtcContractTypes,
			regionsEnabled,
			commodityData,
		);

		const submitValues: any = {
			id: commodityData.id,
			name: commodityName,
			lotFactor: 5000, //hardcoded value requested from BE
			priceControl: parseFloat(priceCtrl),
			basisControl: parseFloat(basisCtrl),
			number: erpNumber,
			hedgeFutures: product?.label || '',
			productId: commodityData.productId,
			cropYearStartDate: moment(commodityData?.cropYearStartDate).format(),
			cropYearEndDate: moment(cropYearDates[cropYearDates.length - 1]).format(),
			hasAdditionalCrop: addCropYear ? addCropYear : false,
			additionalCropYearsAdded: getNumberOfAdditionalCropYears(values),
			fileId: fileId,
			months: updatedMonths,
			brokerMapping: brokerMappingValue,
			numberOfCropYears:
				commodityData.numberOfCropYears +
				getNumberOfAdditionalCropYears(values),
		};
		dispatch(
			actions.editCommodity({
				data: submitValues,
				successMessage: translate(domain.toastSuccessEditMessage, {
					commodityName,
				}),
			}),
		);
	};
	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
		for (let i = 0; i < 4; i++) {
			cropYearsObject[`addCropYear${i}`] = false;
		}
	};
	return (
		<ModalWizard
			wizardDefinition={wizardSchema}
			handleSubmit={handleSubmit}
			handleClose={handleClose}
			hookSchema={useCommodityWizardHook}
			confirmDialog={{
				content: translate(domain.dialogContent),
				cancelText: translate(domain.cancelClose),
				confirmText: translate(domain.confirmClose),
			}}
			isEdit={true}
			hasAdditionalCropYear={hasAdditionalCropYear}
			handleGenerateExcelForEdit={handleGenerateExcelForEdit}
			handleGenerateBrokerMappingForEdit={handleGenerateBrokerMappingForEdit}
		/>
	);
};
