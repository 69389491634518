import './styles.scss';

import { Avatar, Menu, Popover } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectUserInfo } from 'app/containers/GlobalSaga/selectors';
import { actions } from 'app/containers/GlobalSaga/slice';
import { translations } from 'locales/i18n';
import { SessionActions } from 'types/SessionActions';

interface Props {}

export const ProfileMenu = memo((props: Props) => {
	const { t: translate } = useTranslation();
	const translationsScope = translations.app.containers.ProfileMenu;

	const dispatch = useDispatch();

	const userInfo = useSelector(selectUserInfo);
	const initialFirstName = userInfo?.firstName?.charAt(0);
	const initialLastName = userInfo?.lastName?.charAt(0);

	const handleLogOut = () => {
		dispatch(
			actions.startManageSession({
				sessionAction: SessionActions.removeSession,
			}),
		);
	};

	const avatar = (
		<Avatar className="user-profile-avatar">
			{initialFirstName}
			{initialLastName}
		</Avatar>
	);

	const title = (
		<>
			{avatar} {`${userInfo?.firstName} ${userInfo?.lastName}`}
			<div className="tenant-name">{userInfo?.tenantDisplayName}</div>
		</>
	);

	const content = (
		<Menu className="profile-menu">
			<Menu.Item
				className="log-out"
				onClick={handleLogOut}
				data-testid="logout-button"
			>
				{translate(translationsScope.logout)}
			</Menu.Item>
		</Menu>
	);

	return (
		<Popover
			title={title}
			content={content}
			trigger="click"
			placement="bottomRight"
			overlayClassName="profile-menu-overlay"
		>
			{avatar}
		</Popover>
	);
});
