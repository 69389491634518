import { call, put, select } from 'typed-redux-saga';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { FileResponse } from 'types/FileResponse';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import {
	fileRequest,
	httpMethod,
	isGenericErrorWithDetail,
} from 'utils/request';

import {
	selectSelectedCommodity,
	selectSelectedDate,
	selectSelectedRegion,
} from '../selectors';
import { actions } from '../slice';

const { liveLedgerExportExcel } = apiEndpoints;

export function* exportLiveLedger(
	action: ReturnType<typeof actions.exportLiveLedger>,
) {
	const regionSelectedId: string | null = yield* select(selectSelectedRegion);
	const commoditySelectedId = yield* select(selectSelectedCommodity);
	let dateSelected = yield* select(selectSelectedDate);

	const { successMsg, errorMsg } = action.payload;
	let requestURL;

	if (!commoditySelectedId) {
		return;
	} else {
		const commoditiesUrl = `CommodityId=${commoditySelectedId}`;
		let dateUrl = '';
		if (dateSelected) dateUrl = `&Date=${dateSelected}`;
		let regionUrl =
			regionSelectedId && regionSelectedId.length
				? `&RegionId=${regionSelectedId}`
				: '';
		requestURL = new URL(
			`${apiRoutes.base}/${liveLedgerExportExcel}?${commoditiesUrl}${dateUrl}${regionUrl}`,
		);
	}

	const { responseData, responseError } = yield* call(
		fileRequest,
		requestURL,
		httpMethod.Get,
		false,
		null,
		null,
		true,
	);

	if (!!responseData) {
		yield* put(actions.liveLedgerExported(responseData as FileResponse));
		yield* put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMsg + (responseData as FileResponse).fileName,
			}),
		);
	} else if (isGenericErrorWithDetail(responseError)) {
		yield* put(actions.liveLedgerExportFailed(responseError));
		yield* put(
			globalActions.addNotification({
				type: CustomNotificationTypes.ERROR,
				message: errorMsg,
			}),
		);
	}
}
