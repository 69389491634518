import { TransactionsTabs } from 'app/containers/Transactions/types';
import { ROUTES } from 'app/routes';

import { GenericItem } from './GenericItem';
import { GroupMemberDto } from './Notifications';

export enum AggregateType {
	Unspecified = 'Unspecified',
	Offer = 'Offer',
	Contract = 'Contract',
	Bidsheet = 'Bidsheet',
	ReviewAndRelease = 'ReviewAndRelease',
}

export interface UserInternalNotification extends GenericItem {
	subject: string;
	body: string;
	dateDelivered: Date;
	aggregateType: AggregateType;
	aggregateId: string;
	dateSeen: Date | null;
	dateDismissed: Date | null;
	path?: string;
}

export class NotificationGroupMember implements GenericItem {
	id: string;
	name: string;
	email: string;

	constructor(dto: GroupMemberDto) {
		this.id = dto.id;
		this.name = `${dto.firstName} ${dto.lastName}`;
		this.email = dto.email;
	}
}

type NotificationLinkGenerator = (id: string) => string;

export const AGGREGATE_TYPE_TO_LINK_GENERATOR: Record<
	AggregateType,
	NotificationLinkGenerator
> = {
	[AggregateType.Contract]: (id) =>
		id && `${ROUTES.TRANSACTIONS}/${TransactionsTabs.contracts}/${id}`,
	[AggregateType.Offer]: (id) =>
		id && `${ROUTES.TRANSACTIONS}/${TransactionsTabs.offers}/${id}`,
	[AggregateType.ReviewAndRelease]: (id) =>
		id && `${ROUTES.TRANSACTIONS}/${TransactionsTabs.reviewAndRelease}/${id}`,
	[AggregateType.Bidsheet]: () => '',
	[AggregateType.Unspecified]: () => '',
};
