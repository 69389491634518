// These values will be sent to the client so do not add secrets here.
interface RuntimeConfig {
	identityServiceUrl: string;
	orderServiceUrl: string;
	launchDarklyClientSideId: string;
	userPilotToken: string;
	sentryEnv: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const runtimeConfig: RuntimeConfig = (window as any)['runtimeConfig'];
export default runtimeConfig;
