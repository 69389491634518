import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { Modal, ServiceFeeState, TogglePayload, View } from './types';

export const initialState: ServiceFeeState = {
	currentView: View.List,
	currentModal: null,

	activeServiceFees: {
		data: null,
		error: null,
		loading: false,
	},

	list: {
		data: [],
		loading: false,
		error: null,
	},

	serviceFeesStatus: {
		data: [],
		loading: false,
		error: null,
	},

	createServiceFee: {
		data: [],
		loading: false,
		error: null,
	},

	commoditiesList: {
		data: [],
	},
	selectedFilters: {
		commodities: null,
		type: null,
		contract: null,
		cropYear: null,
	},
	serviceFeeMonths: {
		data: [],
		loading: false,
		error: null,
		id: '',
	},
	loadServiceFeeMonths: {
		data: [],
		loading: false,
		error: null,
	},
};

const serviceFeesSlice = createSlice({
	name: 'serviceFees',
	initialState,
	reducers: {
		setCurrentModal(state, action: PayloadAction<Modal | null>) {
			state.currentModal = action.payload;
		},

		setCurrentView(state, action: PayloadAction<View>) {
			state.currentView = action.payload;
		},

		setSelectedFilters(state, action: PayloadAction<any>) {
			state.selectedFilters = {
				...state.selectedFilters,
				...action.payload,
			};
		},

		setActiveServiceFees(state, action) {
			state.activeServiceFees = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		resetActiveServiceFees(state) {
			state.activeServiceFees = {
				data: null,
				loading: false,
				error: null,
			};
		},

		loadServiceFeesList(state: ServiceFeeState) {
			state.list = {
				...state.list,
				data: [],
				loading: true,
				error: null,
			};
		},

		loadCommodities(state: ServiceFeeState, action: PayloadAction<any>) {
			state.commoditiesList = {
				...state.commoditiesList,
				data: action.payload,
			};
		},

		ServiceFeesListLoaded(state: ServiceFeeState, action: PayloadAction<any>) {
			state.list = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		ServiceFeesListError(
			state: ServiceFeeState,
			action: PayloadAction<GenericError>,
		) {
			state.list = {
				...state.list,
				loading: false,
				error: action.payload,
			};
		},

		setServiceFeeStatus(state, action: PayloadAction<TogglePayload>) {
			state.serviceFeesStatus = {
				...state.serviceFeesStatus,
				loading: true,
				error: null,
			};
		},

		setServiceFeeStatusSuccess(state, action) {
			state.serviceFeesStatus = {
				...state.serviceFeesStatus,
				loading: false,
				error: null,
				data: action.payload,
			};
		},

		setServiceFeeStatusError(state, action) {
			state.serviceFeesStatus = {
				...state.serviceFeesStatus,
				error: action.payload,
				loading: false,
			};
		},

		deleteServiceFee(state, action: PayloadAction<object>) {
			state.list = {
				...state.list,
				loading: true,
				error: null,
			};
		},

		ServiceFeeDeleted(state) {
			state.list = {
				...state.list,
				loading: false,
				error: null,
			};
		},

		serviceFeeMonths(state, action: PayloadAction<any>) {
			state.serviceFeeMonths = {
				data: action.payload.serviceFeeMonthList,
				loading: false,
				error: null,
				id: action.payload.id,
			};
		},

		emptyFeeMonths(state) {
			state.serviceFeeMonths = {
				data: [],
				loading: false,
				error: null,
				id: '',
			};
		},

		submiServiceFeeMonths(state, action: PayloadAction<any>) {
			state.serviceFeeMonths = {
				data: [],
				loading: false,
				error: null,
				id: action.payload.ServiceFeeId,
			};
		},

		loadEditServiceFee(state, action: PayloadAction<any>) {
			state.loadServiceFeeMonths = {
				data: action.payload.serviceFeeMonthList,
				loading: false,
				error: null,
			};
		},

		deleteServiceFeeError(state, action: PayloadAction<GenericError>) {
			state.list = {
				...state.list,
				loading: false,
				error: action.payload,
			};
		},

		createServiceFee(state, action: PayloadAction<any>) {
			state.createServiceFee = {
				...state.createServiceFee,
				loading: true,
				error: null,
			};
		},

		ServiceFeeCreated(state) {
			state.createServiceFee = {
				...state.createServiceFee,
				loading: false,
				error: null,
			};
		},

		ServiceFeeError(state, action: PayloadAction<GenericError>) {
			state.createServiceFee = {
				...state.createServiceFee,
				loading: false,
				error: action.payload,
			};
		},
	},
});

export const {
	actions: serviceFeesActions,
	reducer: serviceFeesReducer,
	name: serviceFeesSliceKey,
} = serviceFeesSlice;
