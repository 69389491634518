import './styles.scss';

import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';

interface Props {
	body: string;
	cancelBtn?: string;
	confirmBtn?: string;
	handleCancel: () => void;
	handleConfirmation: () => void;
	showCancelButton?: boolean;
	title: string;
}

export const ConfirmationModal = ({
	body,
	cancelBtn,
	confirmBtn,
	handleCancel,
	handleConfirmation,
	showCancelButton = true,
	title,
}: Props) => {
	const { t: translate } = useTranslation();

	if (!confirmBtn) {
		confirmBtn = translate(
			translations.app.components.ConfirmationModal.confirm,
		);
	}

	if (!cancelBtn) {
		cancelBtn = translate(translations.app.components.ConfirmationModal.cancel);
	}

	return (
		<Modal
			className="confirmation-modal"
			footer={[
				showCancelButton && (
					<Button
						className="return-button"
						data-testid="return-button"
						key="back"
						onClick={handleCancel}
					>
						{cancelBtn}
					</Button>
				),
				<Button
					className="cancel-button"
					data-testid="confirm-button"
					key="submit"
					onClick={handleConfirmation}
					type="primary"
				>
					{confirmBtn}
				</Button>,
			]}
			maskClosable={false}
			onCancel={handleCancel}
			onOk={handleConfirmation}
			title={title}
			visible
		>
			{<p>{body}</p>}
		</Modal>
	);
};
