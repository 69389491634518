import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectAcknowledgeAnFill } from 'app/containers/ReviewAndRelease/selectors';

import { useAcknowledgeAndFillSchema } from '../schemas/useAcknowledgeAndFillSchema';

export const useAcknowledgeAndFillData = () => {
	const { elements, initialValues, validationSchema } =
		useAcknowledgeAndFillSchema();
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const formInstance = useForm({
		defaultValues: {
			...initialValues,
		},
		mode: 'all',
		resolver: yupResolver(validationSchema),
	});
	const order = useSelector(selectAcknowledgeAnFill);

	return {
		dispatch,
		elements,
		formInstance,
		orderId: order?.id,
		translate,
		validationSchema,
	};
};
