import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) =>
	state.summaryDashboard || initialState;

export const selectSelectedFilters = createSelector(
	[selectDomain],
	(summaryDashboardState) =>
		summaryDashboardState.longShortSummary.selectedFilters,
);

export const selectLongShortReport = createSelector(
	[selectDomain],
	(summaryDashboardState) => summaryDashboardState.longShortReport.data,
);

export const selectSelectedFiltersRecap = createSelector(
	[selectDomain],
	(summaryDashboardState) =>
		summaryDashboardState.longShortSummaryRecap.selectedFilters,
);

export const selectLongShortReportRecap = createSelector(
	[selectDomain],
	(summaryDashboardState) => summaryDashboardState.longShortReportRecap.data,
);
