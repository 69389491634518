import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { OrderDirectionOptions } from 'app/containers/Contracts/types';
import { genericReportActions } from 'app/containers/GenericReport/slice';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { actions as liveLedgerActions } from 'app/containers/LiveLedger/slice';
import { actions as reviewAndReleaseActions } from 'app/containers/ReviewAndRelease/slice';
import { actions as BidsheetActions } from 'app/containers/Settings/sections/Bidsheet/slice';
import { translations } from 'locales/i18n';
import { OffersFilters } from 'types/Offer';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { selectPagination, selectSelectedFilters } from '../selectors';
import { actions } from '../slice';
import { OffersViews } from '../types';

const {
	offersList,
	offersRoot,
	offersDetails,
	offersCancel,
	offersComplete,
	offersBook,
	offersMultipleBook,
} = apiEndpoints;

export function* getOffersList(action: PayloadAction<boolean>) {
	const requestURL = yield new URL(`${apiRoutes.base}/${offersList}`);
	const pagination: any = yield select(selectPagination);
	const selectedFilters: OffersFilters = yield select(selectSelectedFilters);
	const orderDirection = selectedFilters?.orderDirection?.find(
		(element) => element.value !== 'all',
	)?.value;

	const filters: any = {
		...pagination,
		...selectedFilters,
		commodities:
			selectedFilters?.commodities
				?.filter((element) => element.value !== 'all')
				.map((element) => element.value) || null,
		customerId:
			selectedFilters?.customerId?.find((element) => element.value !== 'all')
				?.value || null,
		statusView: selectedFilters.statusView?.[0].value || null,
		futuresMonth:
			selectedFilters?.futuresMonth
				?.filter((element) => element.value !== 'all')
				.map((element) => element.value) || null,
		orderDirection:
			orderDirection === OrderDirectionOptions.Buy
				? 0
				: orderDirection === OrderDirectionOptions.Sell
					? 1
					: null,
		employeeId:
			selectedFilters?.employeeId?.find((element) => element.value !== 'all')
				?.value || null,
		destinations:
			selectedFilters?.destinations
				?.filter((element) => element.value !== 'all')
				.map((element) => element.value) || null,
	};

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		filters,
	);

	// Success actions
	if (!!responseData) {
		const { total, list } = responseData;

		yield put(
			actions.offersListLoaded({
				data: list,
				total,
			}),
		);
		yield put(BidsheetActions.requestTotalOrphans());
	} else if (!!responseError.detail) {
		yield put(actions.offerListError(responseError));
	}
}

export function* createOffer(action: PayloadAction<any>) {
	const {
		data,
		closeAfterSubmit,
		successMessage,
		loadReviewAndRelease,
	}: {
		data: any;
		closeAfterSubmit: boolean;
		successMessage: string;
		loadReviewAndRelease: boolean;
	} = action.payload;

	const requestURL = yield new URL(`${apiRoutes.base}/${offersRoot}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		data as any,
	);

	if (!!responseData) {
		yield put(actions.loadOffersList(true));
		yield put(actions.offerCreated());
		if (closeAfterSubmit) {
			yield put(actions.setCurrentModal(null));
		}
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
				showDescription: false,
			}),
		);

		if (loadReviewAndRelease)
			yield put(reviewAndReleaseActions.loadReviewAndReleaseList());
		yield put(liveLedgerActions.loadLiveLedgerList());
	} else if (!!responseError.detail) {
		yield put(actions.activeOfferError(responseError));
	}
}

export function* getOfferSummary(action: PayloadAction<string>) {
	const id = action.payload;
	const requestURL = yield new URL(`${apiRoutes.base}/${offersDetails}/${id}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.offerSummaryLoaded(responseData));
		yield put(actions.setCurrentView(OffersViews.Details));
	} else if (!!responseError.detail) {
		yield put(actions.offerSummaryError(responseError));
	}
}

export function* cancelOffer(action: PayloadAction<any>) {
	const id = action.payload;
	const requestURL: any = yield new URL(
		`${apiRoutes.base}/${offersCancel}/${id}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
	);

	if (!!responseData) {
		yield put(actions.offerCanceled());
		yield put(actions.loadOffersList(true));
		yield put(actions.resetSuccess());
		yield put(actions.setCurrentView(OffersViews.Table));
	} else if (!!responseError.detail) {
		yield put(actions.cancelOfferError(responseError));
	}
}

export function* bookOffer(action: PayloadAction<any>) {
	const domain = translations.app.containers.Offers.components.BookOffer;
	const id = action.payload.id;
	const requestURL: any = yield new URL(
		`${apiRoutes.base}/${offersBook}/${id}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
	);

	if (!!responseData) {
		yield put(actions.offerBooked());
		yield put(actions.loadOffersList(true));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				title: { key: domain.succesMessageTitle },
				showDescription: false,
			}),
		);
		yield put(actions.setCurrentView(OffersViews.Table));
	} else if (!!responseError.detail) {
		yield put(actions.bookOfferError(responseError));
	}
}

export function* getOfferDetails(action: PayloadAction<string>) {
	const id = action.payload;
	const requestURL = yield new URL(`${apiRoutes.base}/${offersRoot}/${id}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.offerDetailsLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.activeOfferError(responseError));
	}
}

export function* updateOffer(action: PayloadAction<any>) {
	const { data, id, successMessage } = action.payload;

	let requestURL: any = yield new URL(`${apiRoutes.base}/${offersRoot}`);
	requestURL.searchParams.append('id', id);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		data,
	);

	if (!!responseData) {
		yield put(actions.offerUpdated());
		yield put(actions.loadOffersList(true));
		yield put(actions.setCurrentModal(null));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
				showDescription: false,
			}),
		);
		yield put(actions.setCurrentView(OffersViews.Table));
	} else if (!!responseError.detail) {
		yield put(actions.activeOfferError(responseError));
	}
}

export function* completeOffer(action: PayloadAction<any>) {
	const { id } = action.payload;

	let requestURL: any = yield new URL(
		`${apiRoutes.base}/${offersComplete}${id}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
	);

	if (!!responseData) {
		yield put(actions.loadOffersList(true));
		yield put(actions.setCurrentModal(null));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				showDescription: false,
			}),
		);
		yield put(actions.setCurrentView(OffersViews.Table));
	} else if (!!responseError.detail) {
		yield put(actions.activeOfferError(responseError));
	}
}

export function* bookMultipleOffers(action: PayloadAction<any>) {
	const domain = translations.app.containers.Offers.components.BookOffer;
	const data = action.payload;
	const requestURL: any = yield new URL(
		`${apiRoutes.base}/${offersMultipleBook}`,
	);

	const selectedIDS = data.map(({ id }) => id);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		selectedIDS,
	);

	if (!!responseData) {
		yield put(actions.offerBooked());
		yield put(actions.loadOffersList(true));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				title: { key: domain.succesMessageTitle },
				showDescription: false,
			}),
		);
	} else if (!!responseError.detail) {
		yield put(actions.bookOfferError(responseError));
	}
}
