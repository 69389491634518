import './style.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { FormButtonsProps } from 'app/components/GenericForm/types';
import {
	selectDefaultPageData,
	selectDefaultPageLoading,
} from 'app/containers/GlobalSaga/selectors';
import {
	actions,
	actions as globalActions,
} from 'app/containers/GlobalSaga/slice';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractType } from 'app/containers/Transactions/components/ContractType';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { DeliveryDates } from 'app/containers/Transactions/components/DeliveryDates';
import { DeliveryLocation } from 'app/containers/Transactions/components/DeliveryLocation';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Location } from 'app/containers/Transactions/components/Location';
import { Transaction } from 'app/containers/Transactions/components/Transaction';
import { useDefaultValues } from 'app/containers/Transactions/hooks/useDefaultValues';
import { useTranslations } from 'app/containers/Transactions/hooks/useTranslations';
import { translations } from 'locales/i18n';
import { DeliveryDateMode } from 'types/DeliveryDateMode';
import {
	getDeliveryPeriod,
	isActionSell,
	isDeliveryDateCustom,
} from 'utils/helpers';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import Yup from 'yupCustom';

import { defaultPageSaga } from './sagas';
import { defaultPageActions, defaultPageReducer, sliceKey } from './slice';

export function DefaultPage() {
	useInjectReducer({ key: sliceKey, reducer: defaultPageReducer });
	useInjectSaga({ key: sliceKey, saga: defaultPageSaga });
	const { t: translate } = useTranslation();
	const domain = translations.app.containers.Settings.sections.DefaultPage;
	const globalTranslations = useTranslations();

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(globalActions.loadGroupedLocationsList());
	}, [dispatch]);

	const isLoading = useSelector(selectDefaultPageLoading);
	const defaultData = useSelector(selectDefaultPageData);

	const validationSchema = Yup.object().shape({});
	const resolver = yupResolver(validationSchema);

	const formData = useDefaultValues();

	const formInstance = useForm({
		resolver,
		mode: 'all',
		defaultValues: formData,
	});

	const handleSubmit = (values) => {
		const data = {
			isSell: isActionSell(values.action),
			contractTypeId: values.contract?.value,
			locationId: values.location?.value,
			deliveryLocationId: values.deliveryLocation?.value,
			commodityId: values.commodity?.value,
			cropYear: values.cropYear,
			deliveryPeriod: getDeliveryPeriod(values.deliveryDate),
			futuresMonth: values.futuresMonth?.value,
			transactionTypeId: values.transaction?.value,
			isDeliveryDateCustom: isDeliveryDateCustom(values.deliveryDatesMode),
		};

		dispatch(
			defaultPageActions.updateDefaultPage({
				data,
				successMessage: globalTranslations.common.success,
			}),
		);
	};

	const onReset = () => {
		formInstance.reset({
			deliveryDatesMode: { value: DeliveryDateMode.Window },
			action: null,
			contract: { value: null },
			deliveryLocation: { value: null },
			commodity: { value: null },
			cropYear: null,
			deliveryDate: null,
			futuresMonth: { value: null },
			deliveryDateWindow: { value: null },
		});
		dispatch(actions.clearDeliveryDates(CalculatedFieldsSource.Settings));
		dispatch(actions.clearFuturesMonthOptions(CalculatedFieldsSource.Settings));
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			htmlType: 'button',
			children: translate(domain.resetValues),
			onClick: onReset,
			key: 'reset',
			dataTestId: 'reset-btn',
		},
		{
			htmlType: 'submit',
			children: translate(domain.saveChanges),
			key: 'submit',
			dataTestId: 'submit-btn',
		},
	];

	useEffect(() => {
		dispatch(actions.loadDefaultPage());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		formInstance.reset(formData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultData]);

	return (
		<>
			{isLoading && <Spin data-testid="spinner" />}
			{!isLoading && (
				<div className="default-page__container">
					<GenericForm.Form
						key="roundingRuleForm"
						formInstance={formInstance}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
						buttonsDefinition={btnsDefinition}
						className="default-page__form"
					>
						<Transaction />
						<ContractType resetDependenciesOnChange={false} />
						<Location />
						<DeliveryLocation source={CalculatedFieldsSource.Settings} />
						<Commodity
							resetFields={['location', 'deliveryLocation']}
							source={CalculatedFieldsSource.Settings}
							checkDefaultValues={false}
						/>
						<CropYears
							source={CalculatedFieldsSource.Settings}
							checkDefaultValues={false}
						/>
						<DeliveryDates
							getFromBidsheet
							resetDependencies
							checkDefaultValues={false}
							source={CalculatedFieldsSource.Settings}
						/>
						<Futures
							checkDefaultValues={false}
							futuresMonth={{
								source: CalculatedFieldsSource.Settings,
								disableWithinDeliveryMode: true,
							}}
						/>
					</GenericForm.Form>
				</div>
			)}
		</>
	);
}
