import './style.scss';

import { CloseOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Tabs } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import React, { memo } from 'react';
import * as Yup from 'yup';

import { FORM_HEADER } from 'app/containers/Settings/types';
import { FieldDefinition } from 'types/FieldDefinition';
import { GenericOption } from 'types/GenericOption';
import { ACCORDION_LAYOUT } from 'utils/constants';
import {
	FormSchema,
	formSchemaGenerator,
} from 'utils/GenericFormGenerators/generator';

import { Accordion } from './components/Accordion';
const { TabPane } = Tabs;

interface Props {
	footerButtons?: any;
	formDefinition: FieldDefinition[];
	handleSubmit: (values) => void;
	handleClose: () => void;
	hookSchema?: any;
	hookParams?: any;
	sectionsList: GenericOption[];
	secondTabSectionsList?: GenericOption[];
	header?: JSX.Element;
	tabTitles?: any;
	newLocationElement?: any;
}

export const GenericViewForm = memo((props: Props) => {
	const {
		formDefinition,
		footerButtons,
		handleSubmit,
		handleClose,
		hookSchema,
		hookParams,
		sectionsList,
		secondTabSectionsList = [],
		header,
		tabTitles = '',
		newLocationElement,
	} = props;

	const { initialValues, validationSchema, elements }: FormSchema =
		formSchemaGenerator(formDefinition);

	const formatedValidationSchema = Yup.object().shape(validationSchema);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={formatedValidationSchema}
			onSubmit={handleSubmit}
		>
			{(formikProps) => {
				!!hookSchema && hookSchema(formikProps, hookParams);
				return (
					<div className="generic-view-accordion__wrapper">
						<Form
							labelCol={{ span: 3 }}
							wrapperCol={{ span: 9 }}
							className="generic-view-accordion__form"
						>
							<div className="generic-view-accordion__header">
								<Row>
									<Col flex={30}>
										{header}
										{elements.map(
											(element) =>
												element.props.section === FORM_HEADER && element,
										)}
									</Col>
									<Col flex={68} />
									<Col flex={2}>
										<CloseOutlined
											className="close__icon"
											onClick={handleClose}
										/>
									</Col>
								</Row>
								<Divider
									key="divider"
									className="generic-view-accordion__header--divider"
								/>
							</div>
							{!!secondTabSectionsList.length && (
								<Tabs type="card">
									<TabPane tab={tabTitles.tab1} key="tab1">
										<Accordion
											sectionsList={sectionsList}
											elements={elements}
											colSpan={ACCORDION_LAYOUT}
										/>
									</TabPane>
									<TabPane tab={tabTitles.tab2} key="tab2">
										<Accordion
											sectionsList={secondTabSectionsList}
											elements={elements}
											colSpan={[
												{ col1: 0, col2: 10 },
												{ col1: 95, col2: 5 },
											]}
										/>
										<div>{newLocationElement}</div>
									</TabPane>
								</Tabs>
							)}
							{!secondTabSectionsList.length && (
								<Accordion
									sectionsList={sectionsList}
									elements={elements}
									colSpan={ACCORDION_LAYOUT}
								/>
							)}
							<div className="generic-view-accordion__actions">
								{footerButtons}
							</div>
						</Form>
					</div>
				);
			}}
		</Formik>
	);
});
