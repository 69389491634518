/**
 *
 * CropYears
 *
 */
import { useField } from 'formik';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FormRadios } from 'app/components/FormRadios';
import { selectCommoditiesList } from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';
import { getCropYearOptions } from 'utils/helpers';

interface Props {
	disabled?: boolean;
	disabledMode?: string;
	required?: boolean;
}

export const CropYearsLegacy = memo((props: Props) => {
	const { disabled, disabledMode, required = false } = props;

	const [, { value: commodity }] = useField('commodity');
	const [, { value: cropYear }] = useField('cropYear');

	const commoditiesList = useSelector(selectCommoditiesList);

	useEffect(() => {
		const options = getCropYearOptions(commoditiesList, commodity);
		setCropYearOptions(options);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [commodity]);

	const [cropYearOptions, setCropYearOptions] = useState(() =>
		getCropYearOptions(commoditiesList, cropYear),
	);

	const { t: translate } = useTranslation();
	const label = translate(
		translations.app.containers.Transactions.components.CropYears.label,
	);
	const labelText = `${required ? '*' : ''} ${label} `;

	return (
		<FormRadios
			name="cropYear"
			key="cropYear"
			label={labelText}
			options={cropYearOptions}
			disabled={disabled}
			disabledMode={disabledMode}
		/>
	);
});
