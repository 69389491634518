import {
	AlertTone,
	NotificationAlertTone,
} from 'app/containers/GlobalSaga/types';

export const BREAK_POINTS = {
	MOBILE_MAX: 767,
	TABLET_MIN: 768,
	TABLET_MAX: 1023,
	DESKTOP_MIN: 1024,
};

export const CONTRACT_TYPES = {
	basis: 'Basis',
	flatPrice: 'Flat Price',
	hedge: 'Hedge',
	hta: 'HTA',
	ntc: 'NTC',
};

export enum PRE_HEDGE_ORDER_TYPES {
	limitOrder = 'Limit Order',
	marketOrder = 'Market Order',
}

export const CONTRACT_TYPE_VALUES = {
	basis: 'B7368E94-211B-4E81-9A44-71C099419C0C',
	flatPrice: '86007747-FA94-4E15-BDC1-0EE91EE2C9A2',
	hta: '7AC9AFD7-D59F-4938-A759-800D24C880FA',
	ntc: '6256999a-8368-4fb2-98f9-01143e7c0b64',
};

export const ROUNDING_VALUES = {
	one: '0.0000',
	two: '0.0025',
	three: '0.0050',
	four: '0.0075',
};

export const CONTRACT_TYPES_GUIDS = {
	'86007747-FA94-4E15-BDC1-0EE91EE2C9A2': 'Flat Price',
	'B7368E94-211B-4E81-9A44-71C099419C0C': 'Basis',
	'7AC9AFD7-D59F-4938-A759-800D24C880FA': 'HTA',
	'6256999a-8368-4fb2-98f9-01143e7c0b64': 'NTC',
};

export const ROUNDING_TYPES_GUIDS = {
	'EA3AC2F4-CDA9-4122-B674-5E237E6B40A3': 'Rounding Up',
	'FA724A72-4D1E-4485-896B-6C090692DA8E': 'Rounding Down',
};

export const TRANSACTION_TYPES_GUIDS = {
	'0CC60437-D5FF-4076-A8AA-1F059E24ADEB': 'Offer',
	'0070AE1A-5FA4-4AB2-8C45-41873B092B3B': 'Cash Contract',
	'14B28CC1-D6BA-45AF-B4CC-65B80A103F31': 'Adjustment',
	'60776A77-089C-463D-98DC-B618B153C10E': 'Sales Trading',
	'6256999A-8368-4FB2-98F9-01143E7C0B63': 'Bushels Only',
};

export const TRANSACTION_TYPES_ENUM = {
	Offer: '0CC60437-D5FF-4076-A8AA-1F059E24ADEB',
	'Cash Contract': '0070AE1A-5FA4-4AB2-8C45-41873B092B3B',
	Adjustment: '14B28CC1-D6BA-45AF-B4CC-65B80A103F31',
	'Sales Trading': '60776A77-089C-463D-98DC-B618B153C10E',
	'Bushels Only': '6256999A-8368-4FB2-98F9-01143E7C0B63',
};

export const SESSION_NAME = 'hrvst';

export const FILTERS_FOR_CONTRACTS = 'contractFilters';

export const FIXED_COLUMNS_FOR_CONTRACTS = 'contractFixedColumns';

export const SELECTED_COLUMNS_FOR_CONTRACTS = 'contractSelectedColumns';

export const FIXED_COLUMNS_FOR_OFFERS = 'offerFixedColumns';

export const SELECTED_COLUMNS_FOR_OFFERS = 'offerSelectedColumns';

export const FIXED_COLUMNS_FOR_REVIEW_AND_RELEASE =
	'reviewAndReleaseSeFixedColumns';

export const SELECTED_COLUMNS_FOR_REVIEW_AND_RELEASE =
	'reviewAndReleaseSelectedColumns';

export const FILTERS_FOR_OFFERS = 'offerFilters';

export const FILTERS_FOR_REVIEW_AND_RELEASE = 'reviewAndReleaseFilters';

export const FILTERS_FOR_FUTURESERP = 'futuresErpFilters';

export const FILTERS_FOR_ORIGINATOR_SCREEN = 'originatorScreenFilters';

export const MARKET_CLOSE_TIME = {
	hour: 13,
	minute: 30,
	second: 0,
};

export const EMPTY_COLUMN = '---';
export const PRICE_DECIMALS = 4;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_MMDDYYYY = 'MM/DD/YYYY';
export const DATE_MMDDYY = 'MM/DD/YY';
export const TIME_FORMAT = 'h:mm:ss A';
export const CONSTANTS = {
	LABEL_SPAN: 4,
	WRAPER_SPAN: 8,
	FIXED_DECIMALS: 4,
	FIXED_QUANTITY_DECIMALS: 2,
	FIXED_FUTURES_PRICE_INTEGERS: 2,
	MIN_FRACTION_DIGIT: 0,
	MAX_FRACTION_DIGIT: 2,
};

export const EVENT_KEY_CODES = {
	BACKSPACE: 8,
	TAB: 9,
	CTRL: 17,
	LEFT_ARROW: 37,
	RIGHT_ARROW: 39,
	DELETE: 46,
	NUMBER_0: 48,
	NUMBER_1: 49,
	NUMBER_2: 50,
	NUMBER_3: 51,
	NUMBER_4: 52,
	NUMBER_5: 53,
	NUMBER_6: 54,
	NUMBER_7: 55,
	NUMBER_8: 56,
	NUMBER_9: 57,
	E: 69,
	V: 86,
	ADD: 107,
	SUBSTRACT: 109,
	DECIMAL_POINT: 110,
	EQUAL_SIGN: 187,
	EQUAL_SIGN_FIREFOX: 61,
	DASH: 189,
	PERIOD: 190,
};

/**
 * for filters, adding "all" option
 */
export const allDefaultOption = {
	id: 'all',
	name: '',
};

export const RESTRICTED_KEYS = [
	EVENT_KEY_CODES.E,
	EVENT_KEY_CODES.EQUAL_SIGN,
	EVENT_KEY_CODES.EQUAL_SIGN_FIREFOX,
	EVENT_KEY_CODES.DASH,
	EVENT_KEY_CODES.PERIOD,
	EVENT_KEY_CODES.DECIMAL_POINT,
	EVENT_KEY_CODES.ADD,
	EVENT_KEY_CODES.SUBSTRACT,
];
export const RESTRICTED_KEYS_DECIMALS = [
	EVENT_KEY_CODES.E,
	EVENT_KEY_CODES.EQUAL_SIGN,
	EVENT_KEY_CODES.EQUAL_SIGN_FIREFOX,
	EVENT_KEY_CODES.DASH,
	EVENT_KEY_CODES.ADD,
	EVENT_KEY_CODES.SUBSTRACT,
];

export const PHONE_KEYS = [
	EVENT_KEY_CODES.NUMBER_0,
	EVENT_KEY_CODES.NUMBER_1,
	EVENT_KEY_CODES.NUMBER_2,
	EVENT_KEY_CODES.NUMBER_3,
	EVENT_KEY_CODES.NUMBER_4,
	EVENT_KEY_CODES.NUMBER_5,
	EVENT_KEY_CODES.NUMBER_6,
	EVENT_KEY_CODES.NUMBER_7,
	EVENT_KEY_CODES.NUMBER_8,
	EVENT_KEY_CODES.NUMBER_9,
	EVENT_KEY_CODES.BACKSPACE,
	EVENT_KEY_CODES.EQUAL_SIGN,
	EVENT_KEY_CODES.EQUAL_SIGN_FIREFOX,
	EVENT_KEY_CODES.LEFT_ARROW,
	EVENT_KEY_CODES.RIGHT_ARROW,
	EVENT_KEY_CODES.ADD,
	EVENT_KEY_CODES.TAB,
];
export const DIGITS_KEYS = [
	EVENT_KEY_CODES.NUMBER_0,
	EVENT_KEY_CODES.NUMBER_1,
	EVENT_KEY_CODES.NUMBER_2,
	EVENT_KEY_CODES.NUMBER_3,
	EVENT_KEY_CODES.NUMBER_4,
	EVENT_KEY_CODES.NUMBER_5,
	EVENT_KEY_CODES.NUMBER_6,
	EVENT_KEY_CODES.NUMBER_7,
	EVENT_KEY_CODES.NUMBER_8,
	EVENT_KEY_CODES.NUMBER_9,
	EVENT_KEY_CODES.BACKSPACE,
	EVENT_KEY_CODES.LEFT_ARROW,
	EVENT_KEY_CODES.RIGHT_ARROW,
	EVENT_KEY_CODES.TAB,
];

export const PRICE_KEYS = [
	EVENT_KEY_CODES.BACKSPACE,
	EVENT_KEY_CODES.TAB,
	EVENT_KEY_CODES.CTRL,
	EVENT_KEY_CODES.DELETE,
	EVENT_KEY_CODES.ADD,
	EVENT_KEY_CODES.DECIMAL_POINT,
	EVENT_KEY_CODES.SUBSTRACT,
	EVENT_KEY_CODES.EQUAL_SIGN,
	EVENT_KEY_CODES.EQUAL_SIGN_FIREFOX,
	EVENT_KEY_CODES.DASH,
	EVENT_KEY_CODES.PERIOD,
];

export const DIGITS_CHARS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export const PHONE_CHARS = [
	'0',
	'1',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'+',
	'-',
];

export const MIN_VALUE = 'IsMinimumEFP';
export const MAX_VALUE = 'IsMaximumEFP';

export const ERP_NUMBER_REPEATED =
	'The ERP number is already assigned, please enter other.';

export const MIN_CROP_INTERVAL = 11;
export const MAX_CROP_INTERVAL = 13;
export const CROP_LIST_ITERATOR = 4;
export const ACCORDION_LAYOUT = [
	{
		col1: 97,
		col2: 3,
	},
	{
		col1: 97,
		col2: 3,
	},
	{
		col1: 97,
		col2: 3,
	},
	{
		col1: 97,
		col2: 3,
	},
];

export const MONTHS_CODES = {
	F: 1,
	G: 2,
	H: 3,
	J: 4,
	K: 5,
	M: 6,
	N: 7,
	Q: 8,
	U: 9,
	V: 10,
	X: 11,
	Z: 12,
};
export type FuturesMonthCode = keyof typeof MONTHS_CODES;

export const MAX_USER_NOTIFICATION_RECORDS = 15;

export const NOTIFICATION_ALERT_TONES: NotificationAlertTone[] = Object.values(
	AlertTone,
).map((value) => ({ id: value, src: `/audio/${value}.mp3`, isActive: false }));

export const DEFAULT_ALERT_TONE = NOTIFICATION_ALERT_TONES[0];
