import './style.scss';

import { Select } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { translations } from 'locales/i18n';

import { selectReportsList } from '../../selectors';
import { genericReportActions } from '../../slice';

interface Props {
	value?: any;
	reportKey?: any;
}

export const ReportSelector = (props: Props) => {
	const { value, reportKey } = props;

	const dispatch = useDispatch();

	const { t: translate } = useTranslation();

	const reportsList = useSelector(selectReportsList);

	const handleChange = (selectedReport) => {
		dispatch(genericReportActions.setCurrentReport(selectedReport));
		dispatch(genericReportActions.loadTableSchema());
	};

	useEffect(() => {
		if (reportKey) {
			dispatch(genericReportActions.setCurrentReport(reportKey));
			dispatch(genericReportActions.loadTableSchema());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return !reportKey ? (
		<div className="report-selector">
			<label className="report-selector__label">
				{translate(translations.app.containers.GenericReport.labelReport)}
			</label>
			<Select
				placeholder={translate(
					translations.app.containers.GenericReport.selectReport,
				)}
				labelInValue
				value={value}
				onChange={handleChange}
				dropdownMatchSelectWidth={false}
				className="report-selector__select"
			>
				{reportsList?.map((item) => (
					<Select.Option value={item.key} key={item.key}>
						{item.name}
					</Select.Option>
				))}
			</Select>
		</div>
	) : null;
};
