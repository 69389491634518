/**
 *
 * ActionsMenu
 *
 */
import {
	CheckCircleFilled,
	DislikeFilled,
	EditFilled,
	EyeFilled,
	LikeFilled,
	RedoOutlined,
	StopOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { actions } from 'app/containers/ReviewAndRelease/slice';
import { MenuActionType, Modal } from 'app/containers/ReviewAndRelease/types';
import { translations } from 'locales/i18n';

interface Props {
	rowData: any;
}

const domain =
	translations.app.containers.ReviewAndRelease.components.Views.List
		.ActionsMenu;

export const ActionsMenu = memo(({ rowData }: Props) => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();

	const getTranslation = (key: string) => translate(domain[key]);

	const handleAcknowledgeOrder = (rowData) => {
		dispatch(actions.getAcknowledgeAndFillRequest({ id: rowData?.id }));
	};

	const handleApproveOrder = (rowData) => {
		dispatch(
			actions.reviewAndReleaseMenuAction({
				data: [rowData],
				type: MenuActionType.approve,
			}),
		);
	};

	const handleCancelOrder = (rowData) => {
		dispatch(actions.editExternalFillLoaded({ id: rowData.id }));
		dispatch(actions.setCurrentModal(Modal.Cancel));
	};

	const handleDenyOrder = (rowData) => {
		dispatch(
			actions.reviewAndReleaseMenuAction({
				data: [rowData],
				type: MenuActionType.deny,
			}),
		);
	};

	const handleEditOrder = (rowData) => {
		dispatch(actions.getExternalFill(rowData));
	};

	const handleResendOrder = (rowData) => {
		dispatch(
			actions.reviewAndReleaseMenuAction({
				data: [rowData],
				type: MenuActionType.resent,
			}),
		);
	};

	const handleViewDetail = () => {
		dispatch(
			actions.loadOrderDetail({
				id: rowData.id,
			}),
		);
	};

	return (
		<div className="actions" data-testid="table-actions">
			<Button data-testid="view-button" onClick={handleViewDetail}>
				<EyeFilled />
				{getTranslation('viewDetails')}
			</Button>
			{rowData.restrictions.canBeApproved && (
				<Button
					data-testid="approve-button"
					onClick={() => handleApproveOrder(rowData)}
				>
					<LikeFilled />
					{getTranslation('approve')}
				</Button>
			)}
			{rowData.restrictions.canBeDenied && (
				<Button
					data-testid="deny-button"
					onClick={() => handleDenyOrder(rowData)}
				>
					<DislikeFilled />
					{getTranslation('deny')}
				</Button>
			)}
			{rowData.restrictions.canBeResent && (
				<Button
					data-testid="resend-button"
					onClick={() => handleResendOrder(rowData)}
				>
					<RedoOutlined />
					{getTranslation('resend')}
				</Button>
			)}
			{rowData.restrictions.canBeAcknowledged && (
				<Button
					data-testid="acknowledge-button"
					onClick={() => handleAcknowledgeOrder(rowData)}
				>
					<CheckCircleFilled />
					{getTranslation('acknowledge')}
				</Button>
			)}
			{rowData.restrictions.canBeCanceled && (
				<Button
					data-testid="cancel-button"
					onClick={() => handleCancelOrder(rowData)}
				>
					<StopOutlined />
					{getTranslation('cancel')}
				</Button>
			)}
			{rowData.restrictions.canBeEdited && (
				<Button
					data-testid="edit-button"
					onClick={() => handleEditOrder(rowData)}
				>
					<EditFilled />
					{getTranslation('edit')}
				</Button>
			)}
		</div>
	);
});
