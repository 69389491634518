import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { erpTypes } = apiEndpoints;

export function* getERPTypesSaga() {
	const requestURL = yield new URL(`${apiRoutes.base}/${erpTypes}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
		true,
	);

	if (!!responseData) {
		yield put(actions.getERPTypesSuccess(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.getERPTypesError(responseError));
	}
}
