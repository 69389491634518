import './style.scss';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Input as InputAntD } from 'antd';
import { InputProps } from 'antd/lib/input';
import classNames from 'classnames';
import React, { memo } from 'react';

interface Props extends InputProps {
	borderless?: boolean;
	disabled?: boolean;
	handleChange?: any;
	onBlur?: any;
	onKeyUp?: any;
	label?: string;
	name: string;
	placeholder?: string;
	suffix?: JSX.Element;
	suffixReference?: any;
	type?: string;
	value?: any;
}

export const InputPassword = memo((props: Props) => {
	const {
		name,
		borderless = false,
		disabled,
		onBlur,
		onChange,
		onKeyUp,
		placeholder,
		suffix,
		suffixReference,
		type,
	} = props;

	const handleBlur = () => {
		!!onBlur && onBlur(name);
	};

	const handleKeyUp = () => {
		!!onKeyUp && onKeyUp(name);
	};

	return (
		<InputAntD.Password
			{...props}
			className={classNames('basic-input-form__input', {
				'basic-input-form__input--borderless': borderless,
			})}
			data-testid="form-input-test"
			disabled={disabled}
			iconRender={(visible: boolean) =>
				visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
			}
			name={name}
			onBlur={handleBlur}
			onChange={onChange}
			onKeyUp={handleKeyUp}
			placeholder={placeholder}
			suffix={suffix || (suffixReference && suffixReference.render())}
			type={type ? type : 'text'}
		/>
	);
});
