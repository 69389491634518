import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { roundingRulesRoot } = apiEndpoints;

export function* deleteRoundingRule(action: PayloadAction<any>) {
	const id = action.payload;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${roundingRulesRoot}?id=${id}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Delete,
	);

	if (!!responseData) {
		yield put(actions.RoundingRuleDeleted());
		yield put(actions.loadRoundingRulesList());
		yield put(globalActions.loadRoundingRulesList());
	} else if (!!responseError.detail) {
		yield put(actions.deleteRoundingRuleError(responseError));
	}
}
