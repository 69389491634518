import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { GenericItem } from 'types/GenericItem';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { userRolesActions } from '../slice';
import { CreateEditRolePayload, View } from '../types';

const { rolesRoot, roleClaims, locationsSettinByRole, contractSettingByRole } =
	apiEndpoints;

export function* createEditUserRole(
	action: PayloadAction<CreateEditRolePayload>,
) {
	const {
		currentRoleName,
		data,
		actionType,
		successMessage,
		bushelsLimit,
		roleId: roleIdValue,
	}: CreateEditRolePayload = action.payload;

	let method;
	let requestURL;

	if (actionType === View.Create) {
		method = httpMethod.Post;
		requestURL = yield new URL(`${apiRoutes.identity}/${rolesRoot}`);
	} else {
		const currentRole = encodeURIComponent(currentRoleName);
		method = httpMethod.Put;
		requestURL = yield new URL(
			`${apiRoutes.identity}/${rolesRoot}?name=${currentRole}`,
		);
	}

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		method,
		data as any,
	);

	if (!!responseData) {
		const roleId = actionType === View.Create ? responseData.id : roleIdValue;

		const roleLimiData = {
			Roles: [
				{
					RoleId: roleId,
					Limit: bushelsLimit,
				},
			],
		};

		yield put(userRolesActions.createEditRoleLimits(roleLimiData));

		yield put(userRolesActions.userRoleCreated());

		yield put(userRolesActions.setCurrentView(View.List));

		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
			}),
		);
	} else if (!!responseError.detail) {
		yield put(userRolesActions.userRoleError(responseError));
	}
}

export function* getUserRoleDetails(action: PayloadAction<GenericItem>) {
	const { name, id } = action.payload;
	const currentRole = encodeURIComponent(name);
	const userURL = yield new URL(
		`${apiRoutes.identity}/${roleClaims}/${currentRole}`,
	);
	const locationURL = yield new URL(
		`${apiRoutes.base}/${locationsSettinByRole}/${id}`,
	);
	const limitURL = yield new URL(
		`${apiRoutes.base}/${contractSettingByRole}/${id}`,
	);

	const [
		{ responseData: userResponse, responseError: userError },
		{ responseData: locationResponse, responseError: locationError },
		{ responseData: limitsResponse, responseError: limitsError },
	] = yield all([
		call(genericRequest, userURL, httpMethod.Get),
		call(genericRequest, locationURL, httpMethod.Get),
		call(genericRequest, limitURL, httpMethod.Get),
	]);

	if (!!userResponse && !!locationResponse && !!limitsResponse) {
		const userResponseData = {
			roles: userResponse,
			locations: locationResponse.list,
			limits: limitsResponse,
			...action.payload,
		};

		yield put(userRolesActions.setActiveUserRoles(userResponseData));
		yield put(userRolesActions.editUserRoleLoaded(userResponse));
		yield put(userRolesActions.createdEditedRoleLimits(limitsResponse));
		yield put(userRolesActions.setCurrentView(View.Edit));
	} else if (userError || locationError || limitsError) {
		yield put(userRolesActions.editUserRoleError(userError));
	}
}
