import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { actions as liveLedgerActions } from 'app/containers/LiveLedger/slice';
import { actions as reviewAndReleaseActions } from 'app/containers/ReviewAndRelease/slice';
import { CreateContractData } from 'types/Contract';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import {
	selectCurrentView,
	selectPagination,
	selectSelectedFilters,
} from '../selectors';
import { actions } from '../slice';
import { ContractsViews, OrderDirectionOptions } from '../types';

const {
	contractsRoot,
	contractsList,
	contractsCancel,
	contractsDetail,
	contractsChildren,
	futuresSpread,
	contractNtcConvert,
	bulkContractNtcConvert,
	bulkContractNtcUpdate,
} = apiEndpoints;

export function* getContractList() {
	const requestURL = yield new URL(`${apiRoutes.base}/${contractsList}`);
	const pagination: any = yield select(selectPagination);
	const selectedFilters: any = yield select(selectSelectedFilters);
	const orderDirection = selectedFilters?.orderDirection?.find(
		(element) => element.value !== 'all',
	)?.value;

	const filters = {
		...pagination,
		...selectedFilters,
		commodities:
			selectedFilters.commodities
				.filter((element) => element.value !== 'all')
				.map((element) => element.value) || null,
		customerId:
			selectedFilters.customerId.find((element) => element.value !== 'all')
				?.value || null,
		contractTypeId: selectedFilters.contractTypeId.find(
			(element) => element.value !== 'all',
		)?.value,
		eventId:
			selectedFilters.eventId.find((element) => element.value !== 'all')
				?.value || null,
		statusView:
			selectedFilters.statusView.find((element) => element.value !== 'all')
				?.value || null,
		orderDirection:
			orderDirection === OrderDirectionOptions.Buy
				? 0
				: orderDirection === OrderDirectionOptions.Sell
					? 1
					: null,
		employeeId:
			selectedFilters.employeeId?.find((element) => element.value !== 'all')
				?.value || null,
		futuresMonths:
			selectedFilters.futuresMonths
				?.filter((element) => element.value !== 'all')
				.map((element) => element.value) || null,
		transactionTypeId:
			selectedFilters.transactionTypeId?.find(
				(element) => element.value !== 'all',
			)?.value || null,
		destinations:
			selectedFilters.destinations
				?.filter((element) => element.value !== 'all')
				.map((element) => element.value) || null,
	};

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		filters,
	);

	// Success actions
	if (!!responseData) {
		const { start: currentPage, limit: pageSize } = pagination;
		const { total, list } = responseData;

		const allLoaded = currentPage * pageSize >= total;

		yield put(
			actions.contractListLoaded({
				data: list,
				total,
				allLoaded,
			}),
		);
	} else if (!!responseError.detail) {
		yield put(actions.contractListError(responseError));
	}
}

export function* createContract(action: PayloadAction<any>) {
	const {
		data,
		closeAfterSubmit,
		successMessage,
		loadReviewAndRelease,
	}: {
		data: CreateContractData;
		closeAfterSubmit: boolean;
		successMessage: string;
		loadReviewAndRelease: boolean;
	} = action.payload;

	const requestURL = yield new URL(`${apiRoutes.base}/${contractsRoot}`);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		data as any,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.loadContractList());
		yield put(actions.contractCreated());
		if (closeAfterSubmit) {
			yield put(actions.setCurrentModal(null));
		}
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
				showDescription: false,
			}),
		);
		yield put(actions.setSelectedRowsAsEmpty());

		// TODO Distinguish between which update
		if (loadReviewAndRelease)
			yield put(reviewAndReleaseActions.loadReviewAndReleaseList());
		yield put(liveLedgerActions.loadLiveLedgerList());
	} else if (!!responseError.detail) {
		yield put(actions.activeContractError(responseError));
	}
}

//Update Contract
export function* updateContract(action: PayloadAction<any>) {
	const { data, id, successMessage } = action.payload;

	const currentView = yield select(selectCurrentView);

	const requestURL = yield new URL(`${apiRoutes.base}/${contractsRoot}`);
	requestURL.searchParams.append('id', id);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		data as any,
	);

	if (!!responseData) {
		yield put(actions.contractUpdated());
		yield put(actions.loadContractList());
		yield put(actions.setCurrentModal(null));

		if (currentView === ContractsViews.Details) {
			yield put(actions.loadContractSummary({ id }));
		}

		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
				showDescription: false,
			}),
		);
		yield put(actions.setSelectedRowsAsEmpty());
	} else if (!!responseError.detail) {
		yield put(actions.activeContractError(responseError));
	}
}

export function* getContractDetails(action: PayloadAction<string>) {
	const id = action.payload;
	const requestURL = yield new URL(`${apiRoutes.base}/${contractsRoot}/${id}`);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.contractDetailsLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.activeContractError(responseError));
	}
}

export function* getContractSummary(
	action: PayloadAction<{
		id: string;
	}>,
) {
	const { id } = action.payload;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${contractsDetail}/${id}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.contractSummaryLoaded(responseData));
		yield put(actions.setCurrentView(ContractsViews.Details));
	} else if (!!responseError.detail) {
		yield put(actions.contractSummaryError(responseError));
	}
}

export function* cancelContract(action: PayloadAction<any>) {
	const {
		id,
		quantity,
		transferCancelQuantityToParent,
		comments,
		isAppliedLoad,
	} = action.payload;

	const currentView = yield select(selectCurrentView);

	const requestURL = yield new URL(`${apiRoutes.base}/${contractsCancel}`);

	requestURL.searchParams.append('id', id);
	requestURL.searchParams.append('quantity', quantity);
	requestURL.searchParams.append(
		'transferCancelQuantityToParent',
		transferCancelQuantityToParent,
	);
	requestURL.searchParams.append('comments', comments);
	requestURL.searchParams.append('isAppliedLoad', isAppliedLoad);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
	);

	if (!!responseData) {
		yield put(actions.contractCanceled());
		yield put(actions.loadContractList());
		yield put(actions.resetSuccess());

		if (currentView === ContractsViews.Details) {
			yield put(actions.loadContractSummary({ id }));
		}
	} else if (!!responseError.detail) {
		yield put(actions.cancelContractError(responseError));
	}
}

export function* getContractsChildren(action: PayloadAction<string>) {
	const id = action.payload;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${contractsChildren}/${id}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.contractsChildrenLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.contractsChildrenError(responseError));
	}
}

export function* getRollSpread(action: PayloadAction<any>) {
	const { futuresMonth, id } = action.payload;

	const requestURL = yield new URL(`${apiRoutes.base}/${futuresSpread}`);
	requestURL.searchParams.append('ContractId', id);
	requestURL.searchParams.append('NewFuturesMonth', futuresMonth);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.spreadValueLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.spreadValueError(responseError));
	}
}

/**
 * @description action to Convert an NTC contract to a traditional contract
 * @param action {
    payload: any;
    type: string;
  }
 */
export function* convertContract(action: PayloadAction<any>) {
	const { data, id, successMessage } = action.payload;
	const requestURL = yield new URL(`${apiRoutes.base}/${contractNtcConvert}`);
	requestURL.searchParams.append('id', id);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		data as any,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.loadContractList());
		yield put(actions.contractConverted());
		yield put(actions.setCurrentModal(null));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
				showDescription: false,
			}),
		);
		yield put(actions.setSelectedRowsAsEmpty());

		// TODO Distinguish between which update
		yield put(reviewAndReleaseActions.loadReviewAndReleaseList());
		yield put(liveLedgerActions.loadLiveLedgerList());
	} else if (!!responseError.detail) {
		yield put(actions.activeContractError(responseError));
	}
}

/**
 * @description action to Convert an array NTC contract to a traditional contract
 * @param action {
    payload: any;
    type: string;
  }
 */
export function* bulkConvertContract(action: PayloadAction<any>) {
	const { data, successMessage } = action.payload;

	const requestURL = yield new URL(
		`${apiRoutes.base}/${bulkContractNtcConvert}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		data as any,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.loadContractList());
		yield put(actions.bulkContractConverted());
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
				showDescription: false,
			}),
		);
		yield put(actions.setSelectedRowsAsEmpty());
		yield put(actions.setCurrentModal(null));

		// TODO Distinguish between which update
		yield put(reviewAndReleaseActions.loadReviewAndReleaseList());
		yield put(liveLedgerActions.loadLiveLedgerList());
	} else if (!!responseError.detail) {
		yield put(actions.activeContractError(responseError));
	}
}

/**
 * @description action to update an array NTC contract to a traditional contract
 * @param action {
    payload: any;
    type: string;
  }
 */
export function* bulkUpdateContract(action: PayloadAction<any>) {
	const { data, successMessage } = action.payload;

	const requestURL = yield new URL(
		`${apiRoutes.base}/${bulkContractNtcUpdate}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		data as any,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.loadContractList());
		yield put(actions.contractConverted());
		yield put(actions.setSelectedRowsAsEmpty());
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
				showDescription: false,
			}),
		);

		// TODO Distinguish between which update
		yield put(reviewAndReleaseActions.loadReviewAndReleaseList());
		yield put(liveLedgerActions.loadLiveLedgerList());
	} else if (!!responseError.detail) {
		yield put(actions.activeContractError(responseError));
	}
}
