import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { TogglePayload } from '../../ServiceFees/types';
import { actions } from '../slice';

const { locationsActivate, locationsDeactivate } = apiEndpoints;

export function* toggleStatus(action: PayloadAction<TogglePayload>) {
	const { id, isActive } = action.payload;
	let requestURL;
	if (isActive) {
		requestURL = yield new URL(
			`${apiRoutes.base}/${locationsDeactivate}?id=${id}`,
		);
	} else {
		requestURL = yield new URL(
			`${apiRoutes.base}/${locationsActivate}?id=${id}`,
		);
	}

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.locationStatusToggled(responseData));
		yield put(actions.loadLocationsList());
	} else if (!!responseError.detail) {
		yield put(actions.locationStatusError(responseError));
	}
}
