import { useSelector } from 'react-redux';

import { selectFeatureFlags } from 'app/containers/GlobalSaga/selectors';
import { FeatureFlag } from 'types/FeatureFlags';

export const useFeatureFlags = () => {
	const featureFlags = useSelector(selectFeatureFlags);
	const isDestinationLocationEnabled =
		featureFlags && featureFlags[FeatureFlag.enabledDestinationLocation];
	const isRegionsEnabled =
		featureFlags && featureFlags[FeatureFlag.enableRegions];
	const isEmployeeGroupedLocationPermissionEnabled =
		featureFlags && featureFlags[FeatureFlag.enableEmployeeGroupedLocation];
	return {
		isDestinationLocationEnabled,
		isRegionsEnabled,
		isEmployeeGroupedLocationPermissionEnabled,
	};
};
