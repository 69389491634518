import { OrderStatus } from 'app/containers/Contracts/types';

import { useTranslations } from '../../shared/useTranslations';
import { useEditDefaultSchema } from './Open';
import { useEditPricedSchema } from './Priced';

export const useEditSchema = () => {
	const translations = useTranslations();
	return {
		[OrderStatus.Open]: useEditDefaultSchema(translations),
		[OrderStatus.Priced]: useEditPricedSchema(translations),
		[OrderStatus.Convert]: useEditDefaultSchema(translations),
	};
};
