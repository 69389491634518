import './style.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import React, { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { FormButtonsProps } from 'app/components/GenericForm/types';
import { selectApplyNameId } from 'app/containers/Contracts/selectors';
import { actions } from 'app/containers/Contracts/slice';
import { SourceContext } from 'app/containers/Transactions';
import { ModalHeader } from 'app/containers/Transactions/components/ModalHeader';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import { CONSTANTS } from 'utils/constants';
import { customFormat } from 'utils/helpers';

import { useTranslations } from '../shared/useTranslations';
import { useApplyNameIdSchema } from './schemas/index';

export const ApplyNameIdModal = memo(() => {
	const translations = useTranslations();
	const dispatch = useDispatch();

	const applyName = useSelector(selectApplyNameId) as any;

	const orderData = applyName.data;

	const isLoading = applyName.loading;

	const schema = useApplyNameIdSchema();
	const defaultSchema = schema[0] as any;

	const resolver = yupResolver(defaultSchema?.validationSchema);

	const formInstance = useForm({
		defaultValues: {
			...defaultSchema?.initialValues,
		},
		mode: 'all',
		resolver,
	});

	useEffect(() => {
		formInstance.reset({
			...defaultSchema?.initialValues,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
		return () => {
			dispatch(actions.clearApplyNameId());
		};
	}, [orderData]);

	const sourceName = GlobalSagaSource.contractModal;

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			children: translations.applyNameId.cancel,
			className: 'order-form__new-order',
			dataTestId: 'close-button',
			disabled: isLoading,
			htmlType: 'button',
			key: 'submit',
			onClick: handleClose,
			type: 'default',
		},
		{
			children: translations.applyNameId.submit,
			dataTestId: 'submit-button',
			disabled: isLoading,
			htmlType: 'submit',
			key: 'new-order',
		},
	];

	const contractNumber = orderData?.internalNumber
		? ` #${orderData.internalNumber}`
		: '';

	const handleSubmit = (values) => {
		const data = {
			customerId: values.customer.value,
			quantity: parseFloat(
				customFormat(
					values.quantityToSplit,
					false,
					CONSTANTS.FIXED_QUANTITY_DECIMALS,
				),
			),
			freightPrice: values.freight ? parseFloat(values.freight) : 0,
			fees1: values.fees1 ? parseFloat(values.fees1) : 0,
			fees2: values.fees2 ? parseFloat(values.fees2) : 0,
			employeeId: values.employee.value,
			comments: values.comments,
			isAppliedLoad: !values?.isUnappliedLoad ?? true,
		};

		dispatch(
			actions.applyNameId({
				data,
				id: orderData.id,
				successMessage: translations.common.success,
			}),
		);
	};

	const header = (
		<ModalHeader
			title={translations.applyNameId.title(contractNumber)}
			content={translations.actions.confirmation}
			confirmText={translations.actions.confirmText}
			cancelText={translations.actions.cancelText}
			handleConfirm={handleClose}
		/>
	);

	return (
		<GenericForm.ModalContainer
			key="applyNameModal"
			closable={false}
			title={header}
		>
			<SourceContext.Provider value={sourceName}>
				<GenericForm.Form
					className="order-form"
					key="applyNameForm"
					formInstance={formInstance}
					validationSchema={defaultSchema?.validationSchema}
					onSubmit={handleSubmit}
					buttonsDefinition={btnsDefinition}
				>
					{defaultSchema?.elements}
				</GenericForm.Form>
			</SourceContext.Provider>
		</GenericForm.ModalContainer>
	);
});
