import './styles.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { NTC_ADMIN_DOMAIN } from '../../utils/helpers';

const FIELD_NAME = 'fieldNameLabel';
const FIELD_TYPE = 'fieldTypeLabel';
const STATUS = 'statusLabel';

export const Header = () => {
	const { t: translate } = useTranslation();

	const getTranslation = (key: string) =>
		translate(NTC_ADMIN_DOMAIN.components.Header[key]);

	return (
		<div className="ntc-admin-header">
			<label className="label--status" data-testid={STATUS}>
				{getTranslation(STATUS)}
			</label>
			<label className="label" data-testid={FIELD_NAME}>
				{getTranslation(FIELD_NAME)}
			</label>
			<label className="label" data-testid={FIELD_TYPE}>
				{getTranslation(FIELD_TYPE)}
			</label>
		</div>
	);
};
