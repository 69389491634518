import './styles.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { FormButtonsProps } from 'app/components/GenericForm/types';
import { translations } from 'locales/i18n';
import { ServiceFee } from 'types/ServiceFee';
import Yup from 'yupCustom';

import { selectServiceFeeMonths } from '../selectors';
import { serviceFeesActions } from '../slice';
import { View } from '../types';
import { ServiceFeeRow } from './ServiceFeeRow';

export const ServiceFeeWrapper = () => {
	const serviceFeeMonths = useSelector(selectServiceFeeMonths);
	const serviceFeeId = serviceFeeMonths.id;
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.ServiceFees;
	const dispatch = useDispatch();
	const validationSchema = Yup.object().shape({});
	const resolver = yupResolver(validationSchema);
	const formInstance = useForm({
		resolver,
		mode: 'all',
		defaultValues: {},
	});

	const handleSubmit = (values) => {
		const serviceFeeId = document
			.querySelector("[name='serviceFeeId']")
			?.getAttribute('value');
		let items = constructItems(values, serviceFeeId);
		dispatch(serviceFeesActions.submiServiceFeeMonths(items));
	};

	const constructItems = (values, serviceFeeId) => {
		return Object.keys(values).reduce<ServiceFee[]>((acc, key) => {
			// parse key into guid and propertyName
			const [propertyName, ...guid] = key.split('-');
			const id = guid.join('-');
			// check acc to see if there is an object with id === guid in key
			const ref = acc.find((obj) => obj.id === id);
			// If there is, then add to that object the relevant data
			// else push a new object with relevant data into the acc
			if (ref) ref[propertyName] = values[key];
			else
				acc.push({
					id,
					[propertyName]: values[key],
					serviceFeeId: serviceFeeId,
				} as ServiceFee);
			return acc;
		}, []);
	};

	const handleClose = () => {
		dispatch(serviceFeesActions.emptyFeeMonths());
		dispatch(serviceFeesActions.setCurrentView(View.List));
	};
	const btnsDefinition: FormButtonsProps[] = [
		{
			className: 'order-form__new-order',
			htmlType: 'button',
			children: translate(translationsScope.View.List.Table.forgetButtonText),
			onClick: handleClose,
			key: 'cancel',
		},
		{
			htmlType: 'submit',
			children: translate(translationsScope.View.List.Table.submitButtonText),
			key: 'submit',
		},
	];

	return (
		<div className="service-fee-wrapper">
			{!!serviceFeeMonths.data ? (
				<GenericForm.Form
					key="serviceFeeFOrm"
					formInstance={formInstance}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
					buttonsDefinition={btnsDefinition}
				>
					<>
						<div className="servicefee-header-container">
							<label id="deliveryMonthLabel" className="servicefee-header">
								Delivery Month
							</label>
							<label id="writingFeeLabel" className="servicefee-header">
								Writing Fee
							</label>
							<label id="rollingFeeLabel" className="servicefee-header">
								Rolling Fee
							</label>
						</div>
						<input name="serviceFeeId" type="hidden" value={serviceFeeId} />
						{serviceFeeMonths.data.map((element, index) => (
							<ServiceFeeRow key={index} {...element} />
						))}
					</>
				</GenericForm.Form>
			) : (
				''
			)}
		</div>
	);
};
