import React from 'react';

import { BellNotifications } from 'app/components/BellNotifications';
import { ProfileMenu } from 'app/components/ProfileMenu';
import { SendCustomNotification } from 'app/components/SendCustomNotification';
import { FeatureFlag } from 'types/FeatureFlags';

export enum TopBarItemType {
	item = 'item',
	divider = 'divider',
	component = 'component',
}

export interface TopBarItem {
	type: TopBarItemType;
	tooltip?: string;
	icon?: any;
	component?: any;
	featureFlag?: FeatureFlag;
	handleClick?: () => void;
}

export const topBarItems: TopBarItem[] = [
	{
		type: TopBarItemType.component,
		component: <BellNotifications />,
		featureFlag: FeatureFlag.enableNotifications,
	},
	{
		type: TopBarItemType.component,
		component: <SendCustomNotification />,
		featureFlag: FeatureFlag.enableNotifications,
	},
	{
		type: TopBarItemType.divider,
	},
	{
		type: TopBarItemType.component,
		component: <ProfileMenu />,
	},
];
