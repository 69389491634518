import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import { Modal } from '../types';

const { employeeRoot } = apiEndpoints;

export function* getEmployeeById(action: PayloadAction<any>) {
	const id = action.payload.id;
	const requestURL = yield new URL(`${apiRoutes.base}/${employeeRoot}/${id}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.requestedEmployeeById(responseData));
		yield put(actions.setCurrentModal(Modal.Edit));
	} else if (!!responseError.detail) {
		yield put(actions.employeeError(responseError));
	}
}
