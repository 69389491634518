import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import {
	generateCheckboxGroup,
	generateInputField,
} from 'utils/GenericFormInputs/generic-form-inputs';

import { selectCurrentLocationLoading } from '../selectors';
import { useLocationFieldTranslations } from './translations';
import { CurrentLocation, FieldDefinitionForLocation } from './types';

export const useLocationFieldFactory = (
	currentLocation: CurrentLocation | null = null,
): Record<string, FieldDefinitionForLocation> => {
	const translations = useLocationFieldTranslations();
	const { t: translate } = useTranslation();
	const loading = useSelector(selectCurrentLocationLoading);
	const requiredMsg = translations.validationSchema.required;

	const initialValueForLocation = {
		id: currentLocation?.id,
		name: currentLocation?.name,
		number: currentLocation?.number,
		isUsed: currentLocation?.isUsed ?? false,
		isActive: currentLocation?.isActive ?? false,
		isDestination: currentLocation?.isDestination ?? false,
		isLocation: currentLocation?.isLocation ?? false,
	};
	const isUsed = currentLocation?.isUsed ?? false;

	let locationOptions = [
		{
			label: translations.location.origin.isLocationOption,
			value: 'isLocation',
		},
		{
			label: translations.location.origin.isDestinationOption,
			value: 'isDestination',
		},
	];
	return {
		locationName: {
			name: 'locationName',
			key: 'locationName',
			label: translations.location.locationName.label,
			placeholder: translations.location.locationName.placeholder,
			loading: loading,
			validation: Yup.string().required(requiredMsg),
			render: generateInputField,
			initialValue: initialValueForLocation.name,
			disabled: isUsed,
		},
		locationNumber: {
			name: 'locationNumber',
			key: 'locationNumber',
			label: translations.location.erpNumber.label,
			placeholder: translations.location.erpNumber.placeholder,
			loading: loading,
			validation: Yup.string().required(requiredMsg),
			render: generateInputField,
			initialValue: initialValueForLocation.number,
			disabled: false,
		},
		locationOrigin: {
			name: 'locationOrigin',
			key: 'locationOrigin',
			validation: Yup.string().required(requiredMsg),
			render: generateCheckboxGroup,
			label: translations.location.origin.label,
			message: translations.location.origin.instructions,
			options: locationOptions,
			Children: translate(translations.location.origin.message),
			initialValue: [
				initialValueForLocation.isLocation &&
					locationOptions.find((option) => option.value === 'isLocation')
						?.value,
				initialValueForLocation.isDestination &&
					locationOptions.find((option) => option.value === 'isDestination')
						?.value,
			].filter((options) => options),
		},
	};
};
