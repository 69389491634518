import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectBidsheetUpload } from 'app/containers/Settings/sections/Bidsheet/selectors';

export const useUploadBidsheetErrors = ({
	setFieldValue,
	values,
	setFieldTouched,
}) => {
	const template: any = useSelector(selectBidsheetUpload);
	const useEffectRemoveFile = (effect: React.EffectCallback) => {
		useEffect(effect, [setFieldValue, values, template]);
	};

	useEffectRemoveFile(() => {
		if (values['uploadBidsheet'] && template.uploadResponse.data) {
			setFieldValue('uploadBidsheet', values.uploadBidsheet);
			setFieldTouched('uploadBidsheet', true);
		}
	});
};
