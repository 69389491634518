import './style.scss';

import { Checkbox, Form } from 'formik-antd';
import React, { memo } from 'react';

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	handleChange?: (event) => void;
	span?: number;
	offset?: number;
}

export const FormCheckbox = memo((props: Props) => {
	const { name, label, disabled, handleChange, span, offset } = props;

	return (
		<Form.Item
			key={name}
			name={name}
			className="checkbox-form__label"
			wrapperCol={{ span: span, offset: offset }}
		>
			<Checkbox
				name={name}
				data-testid={name}
				disabled={disabled}
				onChange={handleChange}
				className="checkbox-form__checkbox"
			>
				{label}
			</Checkbox>
		</Form.Item>
	);
});
