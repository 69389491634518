import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FilterMultiple } from 'app/components/FilterMultiple';
import {
	selectCropsList,
	selectDestinationsList,
} from 'app/containers/GlobalSaga/selectors';
import { selectListData } from 'app/containers/Settings/sections/Commodities/selectors';
import { translations } from 'locales/i18n';
import { mapToLabelValue } from 'utils/helpers';

import {
	selectBidsheetListType,
	selectSelectedFilters,
} from '../../../selectors';
import { actions } from '../../../slice';

export const Filters = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const translationsScope =
		translations.app.containers.Settings.sections.Bidsheet.View.List.Filters;

	const allDefaultOption = {
		id: 'all',
		name: '',
	};
	let commoditiesList: any = useSelector(selectListData);
	commoditiesList = commoditiesList.filter((data: any) => data.isActive);

	const destinationsList = useSelector(selectDestinationsList);

	let cropsList: any = useSelector(selectCropsList);
	cropsList = cropsList.map((year) => {
		return { name: year, id: year };
	});
	const selectedFilters = useSelector(selectSelectedFilters);
	const bidsheetListType = useSelector(selectBidsheetListType);

	const allCommoditiesOption = {
		...allDefaultOption,
		name: translate(translationsScope.allCommodities),
	};
	const allDestinationOption = {
		...allDefaultOption,
		name: translate(translationsScope.allDestination),
	};
	const allCropsOption = {
		...allDefaultOption,
		name: translate(translationsScope.allCropYears),
	};

	const commoditiesOptions = mapToLabelValue([
		allCommoditiesOption,
		...commoditiesList,
	]);

	const destinationsOptions = mapToLabelValue([
		allDestinationOption,
		...destinationsList.data,
	]);

	const cropsOptions = mapToLabelValue([allCropsOption, ...cropsList]);

	const handleChangeCommodities = (selectedValues) => {
		dispatch(
			actions.setSelectedFilters({
				commodities: selectedValues,
			}),
		);
		dispatch(actions.loadBidsheetList({ type: bidsheetListType }));
	};
	const handleChangeDestinations = (selectedValues) => {
		dispatch(
			actions.setSelectedFilters({
				locations: selectedValues,
			}),
		);
		dispatch(actions.loadBidsheetList({ type: bidsheetListType }));
	};
	const handleChangeCropYears = (selectedValues) => {
		dispatch(
			actions.setSelectedFilters({
				cropYears: selectedValues,
			}),
		);
		dispatch(actions.loadBidsheetList({ type: bidsheetListType }));
	};

	return (
		<>
			<div className="bidsheet-filters">
				<FilterMultiple
					options={commoditiesOptions}
					handleChange={handleChangeCommodities}
					currentOptions={selectedFilters.commodities}
					placeholder={translate(translationsScope.allCommodities)}
				/>
			</div>
			<div className="bidsheet-filters">
				<FilterMultiple
					options={destinationsOptions}
					handleChange={handleChangeDestinations}
					currentOptions={selectedFilters.locations}
					placeholder={translate(translationsScope.allDestination)}
				/>
			</div>
			<div className="bidsheet-filters">
				<FilterMultiple
					options={cropsOptions}
					handleChange={handleChangeCropYears}
					currentOptions={selectedFilters.cropYears}
					placeholder={translate(translationsScope.allCropYears)}
				/>
			</div>
		</>
	);
};
