import { Button, Col, Modal, Row as RowAntd, Spin } from 'antd';
import { RowProps as RowAntdProps } from 'antd/lib/grid';
import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { translations } from 'locales/i18n';

import {
	selectNoBidListData,
	selectNoBidListLoading,
	selectNoBidMappedData,
	selectPublishLoading,
	selectSummaryData,
} from '../../selectors';
import { actions } from '../../slice';
import { Modal as ModalEnum, PublishType } from '../../types';

interface RowProps extends RowAntdProps {
	label: string;
	value: number;
}

const Row: FC<RowProps> = ({ label, value, children, ...props }) => (
	<RowAntd gutter={16} {...props}>
		<Col span={6} className="text text--strong flex flex--align--right">
			{label}
		</Col>
		<Col className="text text--strong" span={6}>{`${value.toFixed(0)} `}</Col>
		{children}
	</RowAntd>
);

export const BidsheetPublish = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const domain =
		translations.app.containers.Settings.sections.Bidsheet.Modals
			.BidsheetPublish;

	const publishLoading = useSelector(selectPublishLoading);
	const noBidListLoading = useSelector(selectNoBidListLoading);
	const summaryData = useSelector(selectSummaryData);
	const notBidList = useSelector(selectNoBidListData);
	const notBidMapped = useSelector(selectNoBidMappedData);

	const noBidLoaded =
		notBidList?.length > 0 &&
		Object.keys(notBidMapped).length > 0 &&
		!noBidListLoading;

	const hasOrphans =
		!!summaryData.orphanedBasisOffers || !!summaryData.orphanedFlatPriceOffers;

	useEffect(() => {
		if (noBidLoaded) {
			dispatch(actions.setCurrentModal(ModalEnum.RollOrphan));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [noBidLoaded]);

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
		dispatch(actions.resetBidsheetPublished());
	};

	const handleClickOnPublishAndRoll = () => {
		dispatch(actions.publishBidsheet(PublishType.publishAndRoll));
	};

	const handleClickOnPublish = () => {
		dispatch(actions.publishBidsheet(PublishType.publishAndClose));
	};

	return (
		<Modal
			data-testid="modal-bidsheet-publish"
			maskClosable={false}
			onCancel={handleClose}
			title={translate(domain.modalTitle)}
			visible={true}
			footer={[
				<Button key="back" data-testid="button-cancel" onClick={handleClose}>
					{translate(domain.cancelButton)}
				</Button>,
				<Button
					data-testid="button-publish-and-roll"
					type="primary"
					disabled={!hasOrphans || publishLoading || noBidListLoading}
					onClick={handleClickOnPublishAndRoll}
				>
					<div className="form-button-spin">
						{(publishLoading || noBidListLoading) && (
							<Spin size="small" data-testid="spin-publish-roll" />
						)}{' '}
						{translate(domain.publishAndRollButton)}
					</div>
				</Button>,
				<Button
					data-testid="button-publish"
					type="primary"
					className={classNames({ 'form-button-submit': publishLoading })}
					disabled={publishLoading}
					onClick={handleClickOnPublish}
				>
					{publishLoading && <Spin size="small" data-testid="spin-publish" />}{' '}
					{translate(domain.publishButton)}
				</Button>,
			]}
		>
			<div className="text text--bold">{translate(domain.modalSubtitle)}</div>
			<p className="pre-line">{translate(domain.modalMessage)}</p>

			<RowAntd gutter={16} className="flex flex-content--centered">
				<Col span={12}>
					<Row
						label={translate(domain.potentiallyFilledBasisOffer)}
						value={summaryData.potentiallyFilledBasisOffers}
					/>
					<Row
						label={translate(domain.potentiallyFilledFlatPriceOffer)}
						value={summaryData.potentiallyFilledFlatPriceOffers}
					/>
					<Row
						label={translate(domain.orphanedBasisOffers)}
						value={summaryData.orphanedBasisOffers}
					/>
					<Row
						label={translate(domain.orphanedFlatPriceOffers)}
						value={summaryData.orphanedFlatPriceOffers}
					/>
				</Col>
			</RowAntd>
		</Modal>
	);
};
