/**
 *
 * Edit User Role
 *
 */
import { EditFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { userRolesActions } from '../../../../slice';

interface Props {
	rowData: any;
}

export const Edit = (props: Props) => {
	const dispatch = useDispatch();
	const { rowData } = props;

	const handleEditUserRole = (rowData) => {
		dispatch(userRolesActions.loadEditUserRole(rowData));
	};

	return (
		<div className="actions">
			<Button
				type="text"
				data-testid="edit-button"
				onClick={() => handleEditUserRole(rowData)}
			>
				<EditFilled />
			</Button>
		</div>
	);
};
