import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.futuresErp || initialState;

export const selectFuturesErpListLoading = createSelector(
	[selectDomain],
	(futuresErpState) => futuresErpState.futuresErpList.loading,
);

export const selectFuturesErpList = createSelector(
	[selectDomain],
	(futuresErpState) => futuresErpState.futuresErpList.data,
);

export const selectFuturesErpTotal = createSelector(
	[selectDomain],
	(futuresErpState) => futuresErpState.futuresErpList.total,
);

export const selectSelectedFilters = createSelector(
	[selectDomain],
	(futuresErpState) => futuresErpState.selectedFilters,
);

export const selectPagination = createSelector(
	[selectDomain],
	(futuresErpState) => futuresErpState.pagination,
);

export const selectSelectedRows = createSelector(
	[selectDomain],
	(futuresErpState) => futuresErpState.selectedRows,
);

export const selectFuturesMonthsList = createSelector(
	[selectDomain],
	(futuresErpState) => futuresErpState.futureMonths.data,
);
