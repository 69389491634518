import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectFeatureFlags,
	selectNonNtcContractTypeCatalog,
} from 'app/containers/GlobalSaga/selectors';
import { ModalWizard } from 'app/containers/Settings/sections/Commodities/components/ModalWizard';
import { selectHedgeMapTemplate } from 'app/containers/Settings/sections/Commodities/selectors';
import { translations } from 'locales/i18n';
import { FeatureFlag } from 'types/FeatureFlags';
import { calculateEndDate } from 'utils/helpers';

import { getBrokerMapping } from '../../helpers';
import { useCommodityWizardHook } from '../../hooks/useCommodityWizard';
import { actions } from '../../slice';
import { useCommoditySchema } from '../schemas/useCommoditySchema';
import { useCropRangePicker } from '../schemas/useCropRangePicker';
import { useHedgeAccountSchema } from '../schemas/useHedgeAccountSchema';
import { useHedgeMapping } from '../schemas/useHedgeMappingSchema';

export const CreateCommodity = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const uploadedFile = useSelector(selectHedgeMapTemplate);
	const nonNtcContractTypes = useSelector(selectNonNtcContractTypeCatalog);
	const regionEnabled =
		useSelector(selectFeatureFlags)[FeatureFlag.enableRegions];

	const domain = translations.app.containers.Settings.commodities;
	const wizardSchema = [
		{
			name: translate(domain.createCommodity),
			formDefinitions: useCommoditySchema(),
		},
		{
			name: translate(domain.cropYear),
			formDefinitions: useCropRangePicker(),
		},
		{
			name: translate(domain.hedgeMapping),
			formDefinitions: useHedgeMapping({
				uploadAction: actions.uploadCreateHedgeMapTemplate,
			}),
		},
		{
			name: translate(domain.brokerMapping),
			formDefinitions: useHedgeAccountSchema(),
		},
	];

	const handleSubmit = ({
		commodity: commodityName,
		cropsSection,
		priceCtrl,
		basisCtrl,
		product,
		erpNumber,
		cropYearCalendar,
		addCropYear,
	}) => {
		const {
			data: { months, productId },
			uploadResponse: {
				data: {
					data: { fileId },
				},
			},
		} = uploadedFile;
		const brokerMappingValue = getBrokerMapping(
			cropsSection,
			nonNtcContractTypes,
			regionEnabled,
		);

		const submitValues = {
			name: commodityName,
			priceControl: parseFloat(priceCtrl),
			basisControl: parseFloat(basisCtrl),
			hedgeFutures: product.label,
			number: erpNumber,
			lotFactor: 5000,
			productId,
			cropYearStartDate: moment(cropYearCalendar),
			cropYearEndDate: calculateEndDate(cropYearCalendar),
			hasAdditionalCrop: addCropYear,
			fileId,
			months,
			brokerMapping: brokerMappingValue,
		};

		dispatch(
			actions.createCommodity({
				data: submitValues,
				successMessage: translate(domain.toastSuccessMessage, {
					commodityName,
				}),
			}),
		);
	};

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
	};

	return (
		<ModalWizard
			wizardDefinition={wizardSchema}
			handleSubmit={handleSubmit}
			handleClose={handleClose}
			hookSchema={useCommodityWizardHook}
			confirmDialog={{
				content: translate(domain.dialogContent),
				cancelText: translate(domain.cancelClose),
				confirmText: translate(domain.confirmClose),
			}}
		/>
	);
};
