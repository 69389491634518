/**
 *
 * FormFooterButtons
 *
 */
import './style.scss';

import { Button, Divider, Spin } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { Form } from 'formik-antd';
import React from 'react';

import { ConfirmDialog } from '../ConfirmDialog';

interface Props {
	isEdition?: boolean;
	isLoading?: boolean;
	closeModal?: () => void;
	cancelLabel?: string;
	submitLabel?: string;
	hasConfirmDialog?: boolean;
	confirmDialogMessage?: string;
	handleDialogConfirm?: () => void;
	confirmButtonText?: string;
	cancelButtonText?: string;
	placement?: TooltipPlacement;
	submitOnConfirm?: boolean;
}

export function FormFooterButtons({
	isEdition = false,
	isLoading,
	closeModal,
	cancelLabel,
	submitLabel,
	confirmDialogMessage = '',
	handleDialogConfirm = () => {},
	confirmButtonText = '',
	cancelButtonText = '',
	hasConfirmDialog = false,
	placement = 'bottomRight',
	submitOnConfirm = false,
}: Props) {
	const { dirty, errors, submitForm } = useFormikContext();
	const hasErrors = Object.keys(errors).length > 0;

	return (
		<Form.Item
			name="submitItem"
			key="submitItem"
			className="form-buttons"
			wrapperCol={{ offset: 0 }}
		>
			<Divider key="divider" className="form-buttons__divider" />

			{isEdition === false && (
				<>
					<Button
						data-testid="form-button-cancel"
						className="form-buttons__cancel"
						key="form-buttons-cancel"
						disabled={isLoading}
						onClick={closeModal}
					>
						{cancelLabel}
					</Button>
					<ConfirmDialog
						disabled={!hasConfirmDialog || isLoading || hasErrors || !dirty}
						placement={placement}
						message={confirmDialogMessage}
						handleConfirm={() => {
							submitOnConfirm && submitForm();
							handleDialogConfirm();
						}}
						confirmText={confirmButtonText}
						cancelText={cancelButtonText}
						okButtonProps={
							hasConfirmDialog ? { htmlType: 'submit' } : { htmlType: 'button' }
						}
						trigger={
							<Button
								data-testid="form-button-submit"
								type="primary"
								className={classNames({ 'form-button-submit': isLoading })}
								key="form-buttons-submit"
								htmlType={hasConfirmDialog ? 'button' : 'submit'}
								disabled={
									isLoading || hasErrors || (!dirty && hasConfirmDialog)
								}
							>
								{isLoading && (
									<Spin className="form-button-spin" size="small" />
								)}
								{submitLabel}
							</Button>
						}
						withOverlay={false}
					/>
				</>
			)}
		</Form.Item>
	);
}
