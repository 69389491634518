import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { FormButtonsProps } from 'app/components/GenericForm/types';
import { translations } from 'locales/i18n';

import {
	selectCurrentCustomerData,
	selectCurrentCustomerLoading,
} from '../../selectors';
import { actions } from '../../slice';
import { Customer } from '../../types';
import { useEditCustomerSchema } from './schemas';

const domain =
	translations.app.containers.Settings.sections.Customers.Modals.EditCustomer;

export const EditCustomer = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const activeCustomer = useSelector(selectCurrentCustomerData);
	const isLoading = useSelector(selectCurrentCustomerLoading);

	const { initialValues, elements, validationSchema } = useEditCustomerSchema();
	const formInstance = useForm({
		defaultValues: {
			...initialValues,
		},
		mode: 'all',
		resolver: yupResolver(validationSchema),
	});

	const getTranslation = (key: string, values?) =>
		translate(domain[key], values);

	const handleSubmit = (values) => {
		let stateValue;
		const fullNameEdited = `${values.firstName} ${values.lastName}`;

		if (values.state.hasOwnProperty('value')) {
			stateValue = values.state.value;
		} else {
			stateValue = values.state[0].value;
		}

		const data: Customer = {
			id: activeCustomer?.id,
			firstName: values.firstName,
			lastName: values.lastName,
			number: values.erpNumber,
			street: values.address,
			city: values.city,
			state: stateValue,
			zipCode: values.zipCode?.toString(),
			country: values.country?.[0].value,
			phoneNumber: values.cellphone?.toString(),
			email: values.email,
			isInSync: true,
			isMobileAppEnable: values.isMobileAppEnable,
			mobileAppLimit: values.mobileAppLimit,
			restrictions: {
				canBeEdited: true,
			},
			isEmailNotificationEnabled: values.isEmailNotificationEnabled,
			isSmsNotificationEnabled: values.isSmsNotificationEnabled,
		};

		dispatch(
			actions.editCustomer({
				data: data,
				successMessage: getTranslation('toastSuccessEditMessage', {
					fullNameEdited,
				}),
			}),
		);
	};

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			className: 'customer-form__edit-customer',
			dataTestId: 'edit-customer-modal-cancel-btn',
			htmlType: 'button',
			children: getTranslation('cancelCustomerModalButton'),
			onClick: handleClose,
			disabled: isLoading,
			key: 'cancel',
		},
		{
			htmlType: 'submit',
			children: getTranslation('saveCustomerModalButton'),
			disabled: isLoading || !formInstance.formState.isValid,
			key: 'edit-customer',
			dataTestId: 'edit-customer-modal-btn',
		},
	];

	return (
		<GenericForm.ModalContainer
			className="edit-customer-modal"
			key="editCustomerModal"
			onCancel={handleClose}
			title={getTranslation('editCustomerModalTitle')}
		>
			<GenericForm.Form
				buttonsDefinition={btnsDefinition}
				className="edit-customer-form"
				formInstance={formInstance}
				key="editCustomerForm"
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
			>
				{elements}
			</GenericForm.Form>
		</GenericForm.ModalContainer>
	);
};
