import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.hubSaga || initialState;

export const selectUserNotifications = createSelector(
	[selectDomain],
	(hubState) => hubState.userNotifications,
);
export const selectActiveAlertTone = createSelector(
	[selectDomain],
	(notificationSettingsState) => notificationSettingsState.userAlertTone,
);
