import { GenericError } from 'types/GenericError';
import { GenericItem } from 'types/GenericItem';

export interface ReportOption {
	key: string;
	name: string;
	description: string;
}

// Sections
interface SectionColumn {
	children: any[];
	id: number;
	label: string;
	fixed: boolean | 'left' | 'right' | undefined;
}

interface SectionItem {
	type: string;
	columns: SectionColumn[];
}

// Filter
export interface FilterItem {
	key: string;
	label: string;
	componentType: string;
	selectionMode: string;
	dataType: string;
	defaultValue: any;
	restrictions: {
		isRequired: boolean;
		minValue: any;
		maxValue: any;
	};
	data: any;
	size: number;
}

// Selection Mode
export enum SelectionMode {
	SINGLE = 'single',
	MULTIPLE = 'multiple',
	RANGE = 'range',
}

/* --- STATE --- */
export interface GenericReportState {
	reportsList: {
		data: ReportOption[];
		loading: boolean;
		error: GenericError | null;
	};

	currentReport: ReportOption | null;

	tableSchema: {
		data: {
			bodies: any[];
			filters: FilterItem[];
			hasDynamicHeaderLabel: boolean;
			hasPagination: boolean;
			sections: SectionItem[];
		};

		loading: boolean;
		error: GenericError | null;
	};

	filtersData: object;

	dynamicFiltersData: {
		[key: string]: GenericList;
	};

	tableData: {
		data: any[];
		total: number;
		loading: boolean;
		error: GenericError | null;
		loaded: boolean;
	};

	tableDataReportTemplate: {
		url: any;
		loading: boolean;
		error: GenericError | null;
	};

	pagination: any;
	selectedFilters: object;
}

interface GenericList {
	data: GenericItem[] | null;
	loading: boolean;
	error: GenericError | null;
}

export type ContainerState = GenericReportState;
