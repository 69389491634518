/**
 *
 * Table
 *
 */
import './style.scss';

import { Card } from 'antd';
import React from 'react';

import { Menu } from './Menu';
import { Table } from './Table';

export const List = () => {
	return (
		<Card
			bordered={true}
			className="preHedge-wrapper"
			data-testid="preHedge-wrapper"
		>
			<div className="preHedge-menu" data-testid="preHedge-menu">
				<Menu />
			</div>
			<div className="preHedge-table" data-testid="preHedge-table">
				<Table />
			</div>
		</Card>
	);
};
