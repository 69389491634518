/**
 *
 * Filters in Menu
 *
 */
import { Col, Row } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Collapsible } from 'app/components/Collapsible';
import { FilterAutocomplete } from 'app/components/FilterAutocomplete';
import { FilterDate } from 'app/components/FilterDate';
import { FilterInput } from 'app/components/FilterInput';
import { FilterMultiple } from 'app/components/FilterMultiple';
import {
	selectCustomersList,
	selectReviewAndReleaseStatus,
} from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import {
	selectFilters,
	selectFuturesMonthsList,
} from 'app/containers/ReviewAndRelease/selectors';
import { actions } from 'app/containers/ReviewAndRelease/slice';
import { ReviewAndReleaseFilters } from 'app/containers/ReviewAndRelease/types';
import { productToLabelValue } from 'app/containers/ReviewAndRelease/utils/helpers';
import { selectProductsList } from 'app/containers/Settings/sections/Commodities/selectors';
import { actions as commoditiesActions } from 'app/containers/Settings/sections/Commodities/slice';
import { translations } from 'locales/i18n';
import { FeatureFlag } from 'types/FeatureFlags';
import { GenericOption } from 'types/GenericOption';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import {
	allDefaultOption,
	FILTERS_FOR_REVIEW_AND_RELEASE,
} from 'utils/constants';
import {
	dateMoment,
	GetPersistedData,
	mapToLabelValue,
	mapToLabelValueObject,
	UpdatePersistedData,
} from 'utils/helpers';

const translationsScope =
	translations.app.containers.ReviewAndRelease.components.Views.List.Menu
		.Filters;

export const Filters = () => {
	const sourceName = GlobalSagaSource.reviewAndRelease;
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const featureFlags = useFlags();
	const productsList = useSelector(selectProductsList).product;
	const futuresMonthsList = useSelector(selectFuturesMonthsList);
	const filters = useSelector(selectFilters);
	const statusOptions = useSelector(selectReviewAndReleaseStatus);
	const getTranslation = (key: string, subKey: string) =>
		translate(
			translations.app.containers.Contracts.ListMenu.filters[key][subKey],
		);
	// Customer Filter section
	const customers = useSelector(selectCustomersList);
	const customersList = customers?.[sourceName]
		? customers[sourceName].data
		: [];
	const customersLoading = customers?.[sourceName]
		? customers[sourceName].loading
		: false;
	const customerOptions = mapToLabelValue(customersList);

	useEffect(() => {
		dispatch(actions.getFuturesMonthsRequest());
		dispatch(commoditiesActions.loadProducts());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Get the persisted filter DATA from localstorage and load the review and release grid
	const persistedFilters = GetPersistedData<null | typeof filters>(
		FILTERS_FOR_REVIEW_AND_RELEASE,
		null,
	);

	// Apply persisted filters
	useEffect(() => {
		dispatch(actions.setSelectedFilters(persistedFilters));
		dispatch(
			actions.setPagination({
				start: 1,
			}),
		);
		dispatch(actions.loadReviewAndReleaseList());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const allFuturesMonthsOption = {
		...allDefaultOption,
		name: translate(translationsScope.futuresMonthAll),
	};
	const futuresMonthsOptions = [
		mapToLabelValueObject(allFuturesMonthsOption),
		...futuresMonthsList,
	];

	const allStatusOption = {
		...allDefaultOption,
		name: translate(translationsScope.States.All),
	};
	const statesOptions: GenericOption[] = mapToLabelValue([
		allStatusOption,
		...(statusOptions.data || []),
	]);

	const allProductsOption = {
		label: translate(translationsScope.symbolAll),
		value: 'all',
	};
	const productsOptions = [
		allProductsOption,
		...productToLabelValue(productsList || []),
	];

	const handleChangeField = (obj: ReviewAndReleaseFilters) => {
		UpdatePersistedData(FILTERS_FOR_REVIEW_AND_RELEASE, filters, obj);
		dispatch(actions.setSelectedFilters(obj));
		dispatch(
			actions.setPagination({
				start: 1,
			}),
		);
		dispatch(actions.loadReviewAndReleaseList());
	};

	const handleChangeStates = (selectedStatus) => {
		const status =
			!!selectedStatus && !!selectedStatus.length
				? selectedStatus
				: [statesOptions[0]];
		handleChangeField({ status: status });
	};

	const handleChangeStartDate = (selectedDate) => {
		handleChangeField({ startDate: selectedDate || null });
	};

	const handleChangeEndDate = (selectedDate) => {
		handleChangeField({ endDate: selectedDate || null });
	};

	const handleChangeAccountName = (value) => {
		handleChangeField({ accountName: value || null });
	};

	const handleChangeFuturesMonth = (value) => {
		handleChangeField({ futuresMonth: value || null });
	};

	const handleChangeSymbol = (value) => {
		handleChangeField({ symbol: value || null });
	};

	const handleChangeNumber = (value) => {
		handleChangeField({ number: value || null });
	};

	const handleChangeCustomer = (value) => {
		// Not allowing more than one customer to be selected
		if (!filters.customerId) {
			dispatch(
				globalActions.loadCustomersList({
					search: value,
					source: sourceName,
				}),
			);
		}
		handleChangeField({ customerId: value || null });
	};

	const handleSearchCustomer = (searchValue) => {
		//  not searching anything untill user types anything
		if (searchValue.length) {
			dispatch(
				globalActions.loadCustomersList({
					search: searchValue,
					source: sourceName,
				}),
			);
			return;
		}
		if (!searchValue.length) {
			dispatch(globalActions.clearCustomersList({ source: sourceName }));
			return;
		}
		if (filters.customerId) {
			dispatch(globalActions.clearCustomersList({ source: sourceName }));
			handleChangeField({
				customerId: searchValue || null,
			});
			return;
		}
	};

	const newFiltersLayout = featureFlags[FeatureFlag.enableNewFilterLayout];

	return (
		<>
			<section className="grid-main-filters">
				<div className="grid-menu-label">
					{translate(translationsScope.title)}
				</div>
				<Row gutter={newFiltersLayout ? [8, 8] : [16, { sm: 16, md: 0 }]}>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, xl: 3 }
							: { span: 1.2, md: 4, lg: 3, xl: 2 })}
					>
						<FilterMultiple
							currentOptions={
								!!filters.status &&
								filters.status?.findIndex(
									(element) => element.value === 'all',
								) < 0
									? filters.status
									: []
							}
							options={statesOptions}
							handleChange={handleChangeStates}
							placeholder={translate(translationsScope.States.All)}
						/>
					</Col>
					<Col {...(newFiltersLayout ? { xs: 12, md: 3, xl: 2 } : { span: 1 })}>
						<FilterDate
							name="startDate"
							handleChange={handleChangeStartDate}
							defaultValue={dateMoment(
								!!filters.startDate ? filters.startDate : new Date(),
							)}
							placeholder={translate(translationsScope.startDate)}
							currentDate={
								!!filters.startDate ? dateMoment(filters.startDate) : null
							}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 3, lg: 3, xl: 2 }
							: { span: 1 })}
					>
						<FilterDate
							name="endDate"
							currentDate={
								!!filters.endDate ? dateMoment(filters.endDate) : null
							}
							handleChange={handleChangeEndDate}
							placeholder={translate(translationsScope.endDate)}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3, xl: 2 }
							: { span: 1.5 })}
					>
						<FilterInput
							onSearch={handleChangeAccountName}
							placeholder={translate(translationsScope.accountName)}
							currentOption={filters.accountName || ''}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3, xl: 2 }
							: { span: 2 })}
					>
						<FilterInput
							onSearch={handleChangeNumber}
							placeholder={translate(translationsScope.number)}
							currentOption={filters.number || ''}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3, xl: 2 }
							: { span: 1 })}
					>
						<FilterMultiple
							handleChange={handleChangeFuturesMonth}
							placeholder={translate(translationsScope.futuresMonth)}
							options={futuresMonthsOptions}
							currentOptions={filters.futuresMonth || []}
						/>
					</Col>
					<Col xs={12} md={6} lg={3} xl={2}>
						<FilterMultiple
							handleChange={handleChangeSymbol}
							placeholder={translate(translationsScope.symbol)}
							options={productsOptions}
							currentOptions={filters.symbol || []}
						/>
					</Col>
				</Row>
			</section>
			<Collapsible
				content={
					<Row gutter={[8, 8]}>
						<Col
							{...(newFiltersLayout
								? { xs: 12, md: 6, lg: 3, xl: 3 }
								: { span: 1.2, md: 4, lg: 3, xl: 3 })}
						>
							<FilterAutocomplete
								currentOption={filters.customerId || []}
								handleChange={handleChangeCustomer}
								handleSearch={handleSearchCustomer}
								loading={customersLoading}
								maxTagTextLength={30}
								notFoundContent={
									!customersLoading && !customerOptions.length
										? translate(
												translations.app.containers.Contracts.ListMenu.filters
													.notfound,
											)
										: null
								}
								options={customerOptions}
								placeholder={getTranslation('customer', 'all')}
							/>
						</Col>
					</Row>
				}
				inlineTitle={true}
				collapsibleKey="contracts-filter-more"
			/>
		</>
	);
};
