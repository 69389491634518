/**
 *
 * GenericTable
 *
 */
import { Column } from './Column';
import { FormTableWrapper } from './FormTableWrapper';
import { Table } from './Table';
import { VirtualTable } from './VirtualTable';
export const GenericTable = { Table, VirtualTable, Column, FormTableWrapper };
