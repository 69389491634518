import { CONTRACT_TYPES } from 'utils/constants';

import { useTranslations } from '../../../shared/useTranslations';
import { useNTCSchema } from '../CashContract/NTCSchema';

export const useBushelsOnlySchema = (
	translations: ReturnType<typeof useTranslations>,
) => ({
	[CONTRACT_TYPES.ntc]: useNTCSchema(translations, false, true),
});
