import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FormFooterButtons } from 'app/components/FormFooterButtons';
import { GenericModalForm } from 'app/components/GenericModalForm';
import { useErrorsInLine } from 'app/containers/Settings/definitions/hooks/useErrorsInLine';
import { translations } from 'locales/i18n';

import { useLocationFieldFactory } from '../../fields';
import {
	CurrentLocation,
	FieldDefinitionForLocation,
	SubmittedLocationValues,
} from '../../fields/types';
import { selectErrorsInline } from '../../selectors';
import { actions } from '../../slice';

interface Props {
	title: string;
	submitButtonLabel: string;
	handleSubmit: (values: SubmittedLocationValues) => void;
	currentLocation?: CurrentLocation | null;
}

export const LocationForm = ({
	title,
	submitButtonLabel,
	handleSubmit,
	currentLocation = null,
}: Props) => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const fieldList = useLocationFieldFactory(currentLocation);
	const errorsInLine = useSelector(selectErrorsInline);
	const domain = translations.app.containers.Settings.sections.Locations;

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
	};

	const formDefinition: FieldDefinitionForLocation[] = [
		fieldList.locationName,
		fieldList.locationNumber,
		fieldList.locationOrigin,
	];
	return (
		<GenericModalForm
			title={title}
			formDefinition={formDefinition}
			handleSubmit={handleSubmit}
			handleClose={handleClose}
			footerButtons={
				<FormFooterButtons
					cancelLabel={translate(domain.cancelLocationModalButton)}
					submitLabel={submitButtonLabel}
					closeModal={handleClose}
				/>
			}
			hookSchema={useErrorsInLine}
			hookParams={{ errors: errorsInLine }}
		/>
	);
};
