import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectOrderEntry } from 'app/containers/GlobalSaga/selectors';
import { GenericOption } from 'types/GenericOption';
import { getNumberArray } from 'utils/helpers';
import Yup from 'yupCustom';

import {
	getFieldTypes,
	getMultiSelectOptions,
} from '../components/OrderEntryRow/utils/helpers';
import { ORDER_ENTRY_FORM_ITEMS } from '../types';
import {
	getMultiSelectInputs,
	isInputValid,
	isMultiSelectLengthValid,
	isOptionValid,
	ORDER_ENTRIES_MAX,
	ORDER_ENTRY_DOMAIN,
} from '../utils/helpers';

const multiSelectOptions = getMultiSelectOptions();

export const useOrderEntrySchema = () => {
	const { t: translate } = useTranslation();

	const orderEntries = useSelector(selectOrderEntry);

	const schema = useMemo(
		() =>
			getNumberArray(ORDER_ENTRIES_MAX).reduce(
				(schema, currentValue, index: number) => {
					const fieldTypes = getFieldTypes(translate);
					const requiredMsg = translate(ORDER_ENTRY_DOMAIN.requiredValidation);

					const isCreatingOrderEntries = orderEntries?.length === 0;
					const orderEntry = orderEntries?.[index];

					const orderEntryId =
						orderEntry?.id ||
						`${ORDER_ENTRY_FORM_ITEMS.ORDER_ENTRY_DEFAULT}-${index}`;

					const orderEntryLabel = `${ORDER_ENTRY_FORM_ITEMS.LABEL}-${orderEntryId}`;
					const orderEntryMultiSelectLength = `${ORDER_ENTRY_FORM_ITEMS.MULTI_SELECT_LENGTH}-${orderEntryId}`;
					const orderEntryStatus = `${ORDER_ENTRY_FORM_ITEMS.STATUS}-${orderEntryId}`;
					const orderEntryType = `${ORDER_ENTRY_FORM_ITEMS.TYPE}-${orderEntryId}`;

					const multiSelectInputs = getMultiSelectInputs(
						orderEntry?.items,
						orderEntryId,
						requiredMsg,
					);

					return {
						initialValues: {
							...schema.initialValues,
							[orderEntryStatus]:
								isCreatingOrderEntries || !!orderEntry?.isActive,
							[orderEntryType]: fieldTypes.find(
								(fieldType) => fieldType.value === orderEntry?.type,
							),
							[orderEntryLabel]: orderEntry?.label,
							[orderEntryMultiSelectLength]: multiSelectOptions.find(
								(option) =>
									parseInt(option.value) === orderEntry?.items?.length,
							),
							...multiSelectInputs.initialValues,
						},
						validationSchema: schema.validationSchema.shape({
							[orderEntryStatus]: Yup.boolean(),
							[orderEntryType]: Yup.object().test({
								message: requiredMsg,
								name: 'isActive',
								test: function (option: GenericOption) {
									const status = this.parent[orderEntryStatus];

									return isOptionValid(option, status);
								},
							}),
							[orderEntryLabel]: Yup.string().test({
								message: requiredMsg,
								name: 'isActive',
								test: function (label: string) {
									const status = this.parent[orderEntryStatus];

									return isInputValid(label, status);
								},
							}),
							[orderEntryMultiSelectLength]: Yup.object().test({
								message: requiredMsg,
								name: 'multiSelectLength',
								test: function (option: GenericOption) {
									const status = this.parent[orderEntryStatus];
									const type = this.parent[orderEntryType]?.value;

									return isMultiSelectLengthValid(option, status, type);
								},
							}),
							...multiSelectInputs.validationSchema,
						}),
					};
				},
				{ initialValues: {}, validationSchema: Yup.object().shape({}) },
			),
		[orderEntries, translate],
	);

	return schema;
};
