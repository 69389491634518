import './style.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { FormButtonsProps } from 'app/components/GenericForm/types';
import {
	selectActiveContract,
	selectActiveContractLoading,
} from 'app/containers/Contracts/selectors';
import { actions } from 'app/containers/Contracts/slice';
import { OrderStatus } from 'app/containers/Contracts/types';
import { selectOrderEntry } from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { SourceContext } from 'app/containers/Transactions';
import { ModalHeader } from 'app/containers/Transactions/components/ModalHeader';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import {
	CONSTANTS,
	CONTRACT_TYPES,
	CONTRACT_TYPES_GUIDS,
	DATE_FORMAT,
} from 'utils/constants';
import {
	customFormat,
	getFormattedExpirationDate,
	isDeliveryDateCustom,
} from 'utils/helpers';
import { getOrderEntriesFormValues } from 'utils/order-entry-helpers';

import { useTranslations } from '../shared/useTranslations';
import { useEditSchema } from './schemas/index';

export const EditModal = memo(() => {
	const translations = useTranslations();
	const dispatch = useDispatch();

	const orderEntries = useSelector(selectOrderEntry);
	const orderData = useSelector(selectActiveContract) as any;

	const isLoading = useSelector(selectActiveContractLoading);

	const { status } = orderData;

	const editSchema = useEditSchema()[status] || useEditSchema()['NTC'];
	const currentSchema = editSchema[orderData.contractTypeName];

	const resolver = yupResolver(currentSchema.validationSchema);

	const formInstance = useForm({
		defaultValues: {
			...currentSchema.initialValues,
		},
		mode: 'all',
		resolver,
	});

	const sourceName = GlobalSagaSource.contractModal;

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
		cleanState();
	};

	const cleanState = () => {
		dispatch(globalActions.clearFuturesPrices(sourceName));
		dispatch(globalActions.clearPostedBasisPrice(sourceName));
		dispatch(globalActions.clearFuturesMonth(sourceName));
		dispatch(globalActions.clearDeliveryDates(sourceName));
		dispatch(
			globalActions.clearFuturesMonthOptions(
				CalculatedFieldsSource.Transactions,
			),
		);
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			className: 'order-form__new-order',
			type: 'default',
			htmlType: 'button',
			children: translations.buttons.cancelChanges,
			onClick: handleClose,
			disabled: isLoading,
			key: 'submit',
		},
		{
			htmlType: 'submit',
			children: translations.buttons.submitChanges,
			disabled: isLoading,
			key: 'new-order',
		},
	];

	const contractNumber = orderData?.internalNumber
		? ` #${orderData.internalNumber}`
		: '';

	const header = (
		<ModalHeader
			title={translations.actions.editOrderTitle + contractNumber}
			content={
				translations.actions.editConfirmationMessage +
				' ' +
				translations.actions.confirmation
			}
			confirmText={translations.actions.confirmText}
			cancelText={translations.actions.cancelText}
			handleConfirm={handleClose}
		/>
	);

	const getExtendedContractTypeId = (contract) => {
		if (!CONTRACT_TYPES_GUIDS[contract?.value?.toUpperCase()]) {
			return contract?.value;
		} else {
			return undefined;
		}
	};

	const handleSubmit = (values) => {
		let data: any = {
			theirContract: values.theirContract,
			number: values.contractNumber,
			locationId: values.location.value,
			deliveryLocationId: values.deliveryLocation.value,
			cropYear: values.cropYear,
			isDeliveryDatesCustom: isDeliveryDateCustom(values.deliveryDatesMode),
			deliveryStartDate: values.deliveryDate?.[0]?.format(DATE_FORMAT),
			deliveryEndDate: values.deliveryDate?.[1]?.format(DATE_FORMAT),
			freightPrice: values.freight ? parseFloat(values.freight) : 0,
			fees1: values.fees1 ? parseFloat(values.fees1) : 0,
			fees2: values.fees2 ? parseFloat(values.fees2) : 0,
			quantity: parseFloat(
				customFormat(values.quantity, false, CONSTANTS.FIXED_QUANTITY_DECIMALS),
			),
			employeeId: values.employee.value,
			comments: values.comments,
			futuresMonth: values.futuresMonth.value,
			customFields: getOrderEntriesFormValues(orderEntries, values),
			extendedContractTypeId: getExtendedContractTypeId(values.contract),
			regionId: values?.assignedRegion?.value,
		};

		const label =
			CONTRACT_TYPES_GUIDS[values.contract?.value?.toUpperCase()] || 'NTC';

		if (label === CONTRACT_TYPES.flatPrice) {
			data = {
				...data,
				futuresPrice: values.futuresPrice ? parseFloat(values.futuresPrice) : 0,
				postedBasis: values.postedBasis ? parseFloat(values.postedBasis) : 0,
				pushBasis: values.pushBasis ? parseFloat(values.pushBasis) : 0,
				netBasis: values.netBasis ? parseFloat(values.netBasis) : 0,
				price: values.flatPrice ? parseFloat(values.flatPrice) : 0,
				passFill: values.passFill,
				doNotHedge: values.doNotHedge,
			};
		} else if (label === CONTRACT_TYPES.basis) {
			data = {
				...data,
				postedBasis: values.postedBasis ? parseFloat(values.postedBasis) : 0,
				pushBasis: values.pushBasis ? parseFloat(values.pushBasis) : 0,
				netBasis: values.netBasis ? parseFloat(values.netBasis) : 0,
				expirationDate: getFormattedExpirationDate(values),
			};
			if (status === OrderStatus.Priced) {
				data = {
					...data,
					futuresPrice: values.futuresPrice
						? parseFloat(values.futuresPrice)
						: 0,
					price: values.flatPrice ? parseFloat(values.flatPrice) : 0,
				};
			} else {
				data = {
					...data,
					price: values.netBasisPrice ? parseFloat(values.netBasisPrice) : 0,
				};
			}
		} else if (label === CONTRACT_TYPES.hta) {
			data = {
				...data,
				futuresPrice: values.futuresPrice ? parseFloat(values.futuresPrice) : 0,
				postedBasis: values.postedBasis ? parseFloat(values.postedBasis) : 0,
				pushBasis: values.pushBasis ? parseFloat(values.pushBasis) : 0,
				netBasis: values.netBasis ? parseFloat(values.netBasis) : 0,
				price: values.flatPrice ? parseFloat(values.flatPrice) : 0,
				passFill: values.passFill,
				doNotHedge: values.doNotHedge,
				expirationDate: getFormattedExpirationDate(values),
			};
			if (status === OrderStatus.Priced) {
				data = {
					...data,
					postedBasis: values.postedBasis ? parseFloat(values.postedBasis) : 0,
					pushBasis: values.pushBasis ? parseFloat(values.pushBasis) : 0,
					netBasis: values.netBasis ? parseFloat(values.netBasis) : 0,
					price: values.flatPrice ? parseFloat(values.flatPrice) : 0,
				};
			} else {
				data = {
					...data,
					price: values.netFutures ? parseFloat(values.netFutures) : 0,
				};
			}
		} else if (label === CONTRACT_TYPES.ntc) {
			data = {
				...data,
				expirationDate: getFormattedExpirationDate(values),
				postedBasis: values.postedBasis ? parseFloat(values.postedBasis) : 0,
				pushBasis: values.pushBasis ? parseFloat(values.pushBasis) : 0,
				netBasis: values.netBasis ? parseFloat(values.netBasis) : 0,
				futuresPrice: values.futuresPrice ? parseFloat(values.futuresPrice) : 0,
			};
		}
		data = {
			...data,
			// These parameters cant't be modified
			transactionTypeId: orderData.transactionTypeId,
			contractTypeId: orderData.contractTypeId,
			isSell: orderData.isSell,
			commodityId: orderData.commodityId,
			customerId: orderData.customerId,
		};

		batch(() => {
			cleanState();

			dispatch(
				actions.updateOrder({
					data,
					id: orderData.id,
					successMessage: translations.common.success,
				}),
			);
		});
	};

	return (
		<GenericForm.ModalContainer
			key="editContractModal"
			title={header}
			closable={false}
		>
			<SourceContext.Provider value={sourceName}>
				<GenericForm.Form
					className="order-form"
					key="editContractForm"
					formInstance={formInstance}
					validationSchema={currentSchema?.validationSchema}
					onSubmit={handleSubmit}
					buttonsDefinition={btnsDefinition}
				>
					{currentSchema?.elements}
				</GenericForm.Form>
			</SourceContext.Provider>
		</GenericForm.ModalContainer>
	);
});
