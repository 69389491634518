import { Button, Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { FilterInput } from 'app/components/FilterInput';
import { translations } from 'locales/i18n';

import { actions } from '../../../slice';
import { Modal } from '../../../types';

export const Filters = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const translationsRoot =
		translations.app.containers.Settings.sections.Customers.View.List.Filters;
	const translationsShared = translations.app.containers.Settings.Shared;

	const handleCreateCustomer = () => {
		dispatch(actions.setCurrentModal(Modal.Create));
	};

	const handleOnSearch = (value) => {
		dispatch(
			actions.setPagination({
				start: 1,
			}),
		);
		dispatch(
			actions.setSelectedFilters({
				query: value,
			}),
		);
		dispatch(actions.loadCustomersList());
	};

	return (
		<div className="filters-container">
			<Row className="grid-menu" gutter={16}>
				<Col span={9}>
					<Row gutter={16}>
						<Col xs={3} md={2} xxl={1} className="grid-menu-label">
							{translate(translationsShared.Filters)}
						</Col>
						<Col xs={4} md={8} lg={5} xl={4} xxl={3}>
							<FilterInput
								onSearch={handleOnSearch}
								placeholder={translate(translationsRoot.erpNumberFilter)}
							/>
						</Col>
					</Row>
				</Col>
				<Col span={3}>
					<Row justify="end">
						<Button
							type="primary"
							size="middle"
							onClick={handleCreateCustomer}
							className="orders-button"
							data-testid="button-create-customers"
						>
							{translate(translationsRoot.createCustomerButton)}
						</Button>
					</Row>
				</Col>
			</Row>
		</div>
	);
};
