import { takeEvery, takeLatest } from 'redux-saga/effects';

import {
	dismissAllNotifications,
	dismissNotificationItem,
	getActiveAlertTone,
	markNotificationsAsSeen,
	playAudioOnNotification,
} from './sagas/notifications-saga';
import { actions } from './slice';

export function* hubSaga() {
	yield takeLatest(actions.getActiveAlertTone.type, getActiveAlertTone);
	yield takeLatest(
		actions.dismissAllNotifications.type,
		dismissAllNotifications,
	);
	yield takeLatest(
		actions.dismissNotificationItem.type,
		dismissNotificationItem,
	);
	yield takeLatest(
		actions.markNotificationsAsSeen.type,
		markNotificationsAsSeen,
	);
	yield takeEvery(actions.playNotificationSound.type, playAudioOnNotification);
}
