import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { bidsheetAddBid } = apiEndpoints;

export function* addBid(action: PayloadAction<any>) {
	const data = action.payload;
	const requestURL = yield new URL(`${apiRoutes.base}/${bidsheetAddBid}`);
	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		data,
	);

	if (!!responseData) {
		yield put(actions.bidSheetListRemoveBid(data.rowKey));
		responseData.newBidAdd = true;
		yield put(actions.bidsheetListAddBid(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.addBidValidationErrors(responseError.data));
	}
}
