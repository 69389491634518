import { takeLatest } from 'redux-saga/effects';

import { exportPreHedge } from './sagas/export-saga';
import {
	cancelPreHedge,
	createPreHedge,
	getFuturesMonths,
	getPreHedgeDetails,
	getPreHedgeList,
	getPreHedgeSummary,
	updatePreHedge,
} from './sagas/preHedge-saga';
import { actions } from './slice';

export function* preHedgeSaga() {
	yield takeLatest(actions.loadPreHedgeList.type, getPreHedgeList);

	yield takeLatest(actions.loadPreHedgeDetail.type, getPreHedgeDetails);

	// Watches for createPreHedge actions and calls createPreHedge when one comes in.
	yield takeLatest(actions.createPreHedge.type, createPreHedge);

	yield takeLatest(actions.updatePreHedge.type, updatePreHedge);

	yield takeLatest(actions.loadPreHedgeSummary.type, getPreHedgeSummary);

	yield takeLatest(actions.exportPreHedge.type, exportPreHedge);

	yield takeLatest(actions.cancelPreHedge.type, cancelPreHedge);

	yield takeLatest(actions.getFuturesMonthsRequest.type, getFuturesMonths);
}
