import { useCallback, useEffect, useRef } from 'react';

const useAutoDispatch = <T>(
	condition: boolean,
	dispatchFunc: () => T,
	interval: number,
): T | null => {
	// Create a ref to store the dispatch function between renders
	const dispatchRef: React.MutableRefObject<T | null> = useRef(null);

	// Create a ref to store the ID of the current interval
	const intervalIdRef: React.MutableRefObject<any | null> = useRef(null);

	// Create a memoized callback to conditionally call the dispatch function
	const autoDispatch = useCallback(() => {
		if (condition) {
			dispatchRef.current = dispatchFunc();
		} else {
			dispatchRef.current = null;
		}
	}, [condition, dispatchFunc]);

	// Update the interval when a new one is registered
	useEffect(() => {
		if (intervalIdRef.current !== null) {
			clearInterval(intervalIdRef.current);
			intervalIdRef.current = null;
		}

		if (interval !== null) {
			intervalIdRef.current = setInterval(autoDispatch, interval);
		}

		return () => {
			clearInterval(intervalIdRef.current);
		};
	}, [autoDispatch, interval]);

	// Return the latest value of the dispatchRef, which will be updated by the autoDispatch function
	return dispatchRef.current;
};

export default useAutoDispatch;
