/**
 *
 * Locations
 *
 */
import React from 'react';
import { useSelector } from 'react-redux';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { CreateRoundingRule } from './Modals/CreateRoundingRule';
import { DeleteRoundingRule } from './Modals/DeleteRoundingRule/';
import { EditRoundingRule } from './Modals/EditRoundingRule';
import { roundingRulesSaga } from './saga';
import { selectCurrentModal, selectCurrentRoundingRule } from './selectors';
import { reducer, sliceKey } from './slice';
import { Modal } from './types';
import { List } from './View/List';

export const RoundingRules = () => {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: roundingRulesSaga });

	const currentModal = useSelector(selectCurrentModal);
	const currentRoundingRuleData = useSelector(selectCurrentRoundingRule);

	return (
		<>
			<List />
			{currentModal === Modal.Create && <CreateRoundingRule />}
			{currentModal === Modal.Edit && currentRoundingRuleData && (
				<EditRoundingRule />
			)}
			{currentModal === Modal.Delete && <DeleteRoundingRule />}
		</>
	);
};
