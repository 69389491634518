import moment from 'moment';
import { useEffect } from 'react';

export const useCropYearChange = (formikProps) => {
	const {
		values: { cropYear },
		setFieldValue,
	} = formikProps;

	useEffect(() => {
		if (cropYear) {
			const setDate = moment(moment().year(cropYear.value));
			setFieldValue('cropYearCalendar', null);
			setFieldValue('addCropYear', false);
		}
	}, [cropYear]);
};
