import './style.scss';

import classnames from 'classnames';
import { Form, Radio } from 'formik-antd';
import React, { memo } from 'react';

interface Props {
	name: string;
	label?: string;
	options?: any;
	disabled?: boolean;
	onChange?: (string) => void;
	disabledMode?: string;
	message?: JSX.Element;
	customClass?: string;
	optionType?: 'button' | 'default';
	span?: number;
	offset?: number;
}

export const FormRadios = memo((props: Props) => {
	const {
		name,
		label,
		options,
		disabled,
		disabledMode,
		message,
		customClass,
		optionType = 'button',
		span,
		offset,
	} = props;

	const handleChange = (target) => {
		if (props.onChange) {
			props.onChange(target.target.value);
		}
	};

	return (
		<Form.Item
			data-testid={name}
			label={label}
			name={name}
			className="radios-form__label"
			wrapperCol={{ span: span, offset: offset }}
		>
			<Radio.Group
				name={name}
				options={options}
				onChange={handleChange}
				disabled={disabled}
				optionType={optionType}
				buttonStyle="solid"
				className={classnames('radios-form__buttons', customClass, {
					'disabled-mode--single': !!disabledMode && disabledMode === 'single',
					'disabled-mode--multi': !!disabledMode && disabledMode === 'multi',
				})}
			/>
			{message}
		</Form.Item>
	);
});
