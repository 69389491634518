import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { translations } from 'locales/i18n';

interface Props {
	disabled?: boolean;
}

export const ContractNumber = memo((props: Props) => {
	const { disabled = true } = props;
	const { t: translate } = useTranslation();
	const msg =
		translations.app.containers.Transactions.components.ContractNumber;

	return (
		<GenericForm.FormItem
			label={translate(msg.label)}
			name="contractNumber"
			data-testid="contractNumber-form-item"
		>
			<Input
				name="contractNumber"
				key="contractNumber"
				placeholder={translate(msg.placeholder)}
				disabled={disabled}
			/>
		</GenericForm.FormItem>
	);
});
