import { LDFlagSet } from 'launchdarkly-js-client-sdk';

import { Commodity } from 'types/Commodity';
import { ContractTypeSource } from 'types/ContractTypeSource';
import { DeliveryDate } from 'types/DeliveryDate';
import { FutureMonth } from 'types/FutureMonth';
import { GenericError } from 'types/GenericError';
import { GenericItem } from 'types/GenericItem';
import { GenericOption } from 'types/GenericOption';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import { GroupedLocation } from 'types/GroupedLocation';
import { Location } from 'types/Location';
import { NtcAdminData } from 'types/NtcAdmin';
import { OrderEntryData } from 'types/OrderEntry';
import { Region } from 'types/Regions';
import { RoundingRule } from 'types/RoundingRule';
import { TransactionItem } from 'types/TransactionItem';
import { UserAccount } from 'types/UserAccount';
import { UserInfo } from 'types/UserInfo';

export enum CustomNotificationTypes {
	SUCCESS = 'success',
	ERROR = 'error',
	INFO = 'info',
	WARNING = 'warning',
}

// Notifications
export enum AlertTone {
	DynamoBreeze = 'DynamoBreeze',
	EtherWhisper = 'EtherWhisper',
	FrostbiteEcho = 'FrostbiteEcho',
	GalacticSparkle = 'GalacticSparkle',
	HyperFlare = 'HyperFlare',
	JadeHarmony = 'JadeHarmony',
	KaleidoscopeGlow = 'KaleidoscopeGlow',
	OrionTwinkle = 'OrionTwinkle',
	PhantomZephyr = 'PhantomZephyr',
	RetroOrbit = 'RetroOrbit',
	SolarTide = 'SolarTide',
	UltravioletRipple = 'UltravioletRipple',
	XenonQuiver = 'XenonQuiver',
	YellowstoneBlip = 'YellowstoneBlip',
	ZephyrDing = 'ZephyrDing',
}

enum NotificationGroupType {
	Customer = 'Customer',
	Employee = 'Employee',
}

export interface NotificationGroupDto extends GenericItem {
	type: NotificationGroupType;
	isSystem: boolean;
}

export interface NotificationAlertTone {
	id: AlertTone;
	src: string;
	isActive: boolean;
}

export interface NotificationMessagePayload {
	data: {
		messageContent: string;
		employeeNotificationGroupIds: string[];
		customerNotificationGroupIds: string[];
	};
	successMessage: string;
}

type InternationalizedString = {
	key: string;
	data?: any;
};
export interface CustomNotification {
	confirmText?: string | InternationalizedString;
	duration?: number;
	id?: string;
	message?: string | InternationalizedString;
	showConfirmButton?: boolean;
	showDescription?: boolean;
	title?: string | InternationalizedString;
	type: CustomNotificationTypes;
}

interface GenericList {
	data: GenericItem[] | null;
	loading: boolean;
	error: GenericError | null;
}

export interface GenericLoadListPayload {
	source: GlobalSagaSource;
	search?: string;
	isActiveOnly?: boolean;
}

export interface GenericLoadListSuccessPayload {
	source: GlobalSagaSource;
	data: GenericItem[];
}

export interface GenericLoadListErrorPayload {
	source: GlobalSagaSource | CalculatedFieldsSource;
	error: GenericError;
}

export interface LoadDeliveryDatesPayload {
	source: CalculatedFieldsSource;
	params: {
		commodityId: string;
		cropYear: number;
		locationId: string;
	};
}

export interface LoadDeliveryDatesSuccessPayload {
	source: CalculatedFieldsSource;
	data: DeliveryDate[];
}
export interface LoadFuturesMonthPayload {
	source: GlobalSagaSource | CalculatedFieldsSource;
	type: ContractTypeSource;
	params: {
		cropYear: number;
		deliveryStartDate: string;
		deliveryEndDate: string;
		commodity?: string;
		location?: string;
	};
}
export interface LoadFuturesMontOptionsPayload {
	params: {
		commodityId: string;
		excludeExpired?: boolean;
	};
	source: CalculatedFieldsSource;
}

export interface LoadFuturesMonthSuccessPayload {
	source: GlobalSagaSource | CalculatedFieldsSource;
	data: string;
}

export interface LoadFuturesMonthOptionsSuccessPayload {
	source: GlobalSagaSource | CalculatedFieldsSource;
	data: FutureMonth[];
}

export interface LoadPostedBasisPricePayload {
	source: CalculatedFieldsSource;
	params: {
		commodityId: string;
		deliveryLocationId: string;
		deliveryStartDate: string;
		deliveryEndDate: string;
		cropYear: number;
		locationId: string;
	};
}

export interface LoadPostedBasisPriceSuccessPayload {
	source: CalculatedFieldsSource;
	data: number;
}

export interface LoadFuturesPricePayload {
	source: GlobalSagaSource | CalculatedFieldsSource;
	params: {
		commodityId: string;
		futuresMonth?: string;
	};
}

export interface LoadFuturesPriceSuccessPayload {
	source: GlobalSagaSource | CalculatedFieldsSource;
	data: number;
}

export interface LoadCachedFuturesPriceSuccessPayload {
	futuresPrice: number;
	priceControl: number;
}

export interface TransactionUpdates {
	totalReadyTransactions: number;
	anyTransactionRejected: boolean;
}

export interface ContractType extends GenericItem {
	typeId: string;
	code: string;
	erpNumber: string | null;
	isActive: boolean;
}

export enum GlobalModals {
	CustomNotification = 'CustomNotification',
}

/* --- STATE --- */
export interface GlobalSagaState {
	user: UserAccount | null | undefined;

	userInfo: UserInfo | null | undefined;

	currentModal: GlobalModals | null;

	commodities: {
		data: Commodity[];
		loading: boolean;
		error: GenericError | null;
	};

	crops: {
		data: GenericItem[];
		loading: boolean;
		error: GenericError | null;
	};

	transactions: {
		data: TransactionItem[];
		loading: boolean;
		error: GenericError | null;
	};

	event: {
		data: GenericItem[];
		loading: boolean;
		error: GenericError | null;
	};

	/**
	 * Refers to options in dropdown when creating an Contract / Offers
	 * depends on permissions given to logged user
	 */
	contractType: {
		[key in ContractTypeSource]: {
			data: any;
			loading: boolean;
			error: GenericError | null;
		};
	};

	/**
	 * referes to options used in filters. Load all active contract types
	 * Doesn't have any depency with permissions
	 */
	contractTypeCatalog: {
		data: ContractType[];
		loading: boolean;
		error: GenericError | null;
	};

	customers: {
		[key: string]: GenericList;
	};

	locations: {
		data: Location[];
		loading: boolean;
		error: GenericError | null;
	};

	groupedLocations: {
		data: GroupedLocation[];
		loading: boolean;
		error: GenericError | null;
	};

	roundingRules: {
		data: RoundingRule[];
		loading: boolean;
		error: GenericError | null;
	};

	serviceFees: {
		data: [];
		loading: boolean;
		error: GenericError | null;
	};

	serviceFeeTypes: {
		data: [];
		loading: boolean;
		error: GenericError | null;
	};
	destinations: {
		data: Location[];
		loading: boolean;
		error: GenericError | null;
	};

	employees: {
		[key: string]: GenericList;
	};

	deliveryDates: {
		[key: string]: {
			data: DeliveryDate[] | null;
			loading: boolean;
			error: GenericError | null;
		};
	};

	futuresMonth: {
		[key: string]: {
			data: string | null;
			loading: boolean;
			error: GenericError | null;
		};
	};

	postedBasisPrice: {
		[key: string]: {
			data: number | null;
			loading: boolean;
			error: GenericError | null;
		};
	};

	futuresPrice: {
		[key: string]: {
			data: number | null;
			loading: boolean;
			error: GenericError | null;
		};
	};

	cachedFuturesPrice: {
		data: LoadCachedFuturesPriceSuccessPayload;
		error: GenericError | null;
	};

	/**
	 * Catalog for Future Months
	 */
	catalogFuturesMonths: {
		[key: string]: {
			data: GenericOption[];
			loading: boolean;
			error: GenericError | null;
		};
	};

	reviewAndReleaseStatus: GenericList;

	futuresMonthOptions: {
		[key: string]: {
			data: any[];
			error: GenericError | null;
			loading: boolean;
		};
	};
	appNotifications: CustomNotification[];

	localStorage: {
		loading: boolean;
		error: GenericError | null;
		sessionCheck: boolean;
		fromLocalStorage: boolean;
		fromService: boolean;
	};

	hedgeAccounts: {
		data: GenericItem[];
		loading: boolean;
		error: GenericError | null;
	};
	defaultPage: {
		data: any;
		error: GenericError | null;
		loading: boolean;
	};

	orderEntry: {
		data: OrderEntryData | null;
		error: GenericError | null;
		loading: boolean;
	};
	ntcAdmin: {
		data: NtcAdminData | null;
		error: GenericError | null;
		loading: boolean;
	};

	orderStatus: {
		data: TransactionUpdates;
		error: GenericError | null;
		loading: boolean;
	};

	topbarTitle: string;

	employeeNotificationGroups: {
		data: NotificationGroupDto[];
		loading: boolean;
		error: GenericError | null;
	};

	customerNotificationGroups: {
		data: NotificationGroupDto[];
		loading: boolean;
		error: GenericError | null;
	};

	regions: {
		data: Region[];
		error: GenericError | null;
		loading: boolean;
	};

	featureFlags: LDFlagSet;

	preHedgeStatus: GenericList;
}

export type ContainerState = GlobalSagaState;

export enum CalculatedFieldsSource {
	Transactions = 'Transactions',
	Settings = 'Settings',
}
