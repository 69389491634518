import './style.scss';

import { Col, Form, Row } from 'antd';
import classNames from 'classnames';
import React, { memo, useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { addComma, removeComma } from 'utils/helpers';

import { ValidationSchemaContext } from '../Form';
import { GenericFormItemProps } from '../types';

const CONSTANTS = {
	COLUMNS: 12,
	GUTTER: 10,
};

export const FormItem = memo(
	({
		name,
		hidden,
		onChange,
		children,
		label,
		customClassName,
		isInputSlow = false,
		isNumericFieldSlow = false,
		excludeControllerOnBlur = false,
		...formItemProps
	}: GenericFormItemProps) => {
		if (hidden) return null;
		const validationSchema = useContext(ValidationSchemaContext);

		const [inputValue, setInputValue]: any = useState('');

		// A wrapper function to separate the digits by comma
		const formatQuantity = (value) => {
			// EXPLAINATION for condition value !== '.' --- /if user have entered something starting with a decimal point, this is for quantities less than one bushel
			if (value !== '.' && isNaN(value)) {
				setInputValue(value.replace(/[^0-9]/g, ''));
			} else {
				let result = addComma(value)
					.replace(/(\.\d{2})\d+/g, '$1')
					.replace('NaN', '');
				setInputValue(result);
			}
		};

		let isRequired: boolean = true;

		if (Array.isArray(children)) {
			children = children.filter((child) => React.isValidElement(child));
		}

		// If all children are required, the complete item is marked as required
		if (Array.isArray(children)) {
			children.forEach((chilData) => {
				isRequired =
					validationSchema?.fields?.[chilData.props.name]?._exclusive
						?.required ||
					validationSchema?.fields?.[chilData.props.name]?._exclusive
						?.requiredOption;
			});
		} else {
			isRequired =
				validationSchema?.fields?.[name]?._exclusive?.required ||
				validationSchema?.fields?.[name]?._exclusive?.requiredOption;
		}

		const {
			control,
			formState: { errors, isDirty },
			getValues,
			watch,
		} = useFormContext();

		const { quantity, qtyPriceAmount, quantityToSplit } = watch();

		useEffect(() => {
			const shouldResetInput =
				isDirty && !quantity && !qtyPriceAmount && !quantityToSplit;
			if (shouldResetInput) {
				setInputValue('');
			}
		}, [isDirty, quantity, qtyPriceAmount, quantityToSplit]);

		const { GUTTER, COLUMNS } = CONSTANTS;
		let content: JSX.Element = <></>;
		const hasError: boolean = errors[name]?.message?.length > 0;

		if (Array.isArray(children)) {
			const defaultColSpan = Math.floor(COLUMNS / children?.length);

			content = (
				<Row gutter={GUTTER}>
					{children?.map((childData, index) => {
						return (
							<Col
								key={childData.props.name}
								offset={childData.props.wrapperCol?.offset || undefined}
								span={childData.props.wrapperCol?.span || defaultColSpan}
							>
								<FormItem
									{...childData.props}
									wrapperCol={undefined}
									key={childData.props.name}
								/>
							</Col>
						);
					})}
				</Row>
			);
		} else {
			content = (
				<Controller
					name={name}
					control={control}
					render={(controllerProps) => {
						useEffect(() => {
							if (isNumericFieldSlow || isInputSlow) {
								setInputValue(controllerProps.value);
							}
							// eslint-disable-next-line react-hooks/exhaustive-deps
						}, []);

						const Input = children?.type;

						return Input ? (
							<Input
								{...children?.props}
								name={controllerProps.name}
								onBlur={(params) => {
									if (isNumericFieldSlow || isInputSlow)
										controllerProps?.onChange?.(inputValue);
									if (!excludeControllerOnBlur) controllerProps?.onBlur?.();
									children?.props?.onBlur?.();
								}}
								ref={controllerProps.ref}
								value={
									isNumericFieldSlow || isInputSlow
										? inputValue
										: controllerProps.value
								}
								checked={controllerProps.value}
								onChange={(params) => {
									if (isNumericFieldSlow) {
										const value = removeComma(params?.target?.value);
										formatQuantity(value);
									} else if (isInputSlow) setInputValue(params?.target?.value);
									else {
										controllerProps?.onChange?.(
											params?.target?.value || params,
										);
									}
									children?.props?.onChange?.(params?.target?.value || params);
									onChange?.(params?.target?.value || params, getValues());
								}}
							></Input>
						) : (
							<></>
						);
					}}
				/>
			);
		}

		return (
			<>
				<Form.Item
					{...formItemProps}
					label={label && isRequired ? `*${label}` : label}
					name={name}
					key={name}
					className={classNames(
						'generic-form-item',
						{
							'generic-form-item-has-error': hasError,
							'generic-form-item-multiple': Array.isArray(children),
						},
						...(customClassName ?? []),
					)}
					help={
						errors?.[name]?.message && (
							<label
								className={classNames(
									{
										'error-message': errors?.[name]?.type,
									},
									{
										'warning-message': errors?.[name]?.type === 'warning',
									},
								)}
							>
								{errors[name]?.message}
							</label>
						)
					}
				>
					{content}
				</Form.Item>
			</>
		);
	},
);
