import './style.scss';

import { DatePicker, Form } from 'formik-antd';
import moment, { Moment } from 'moment';
import React, { memo } from 'react';

interface Props {
	name: string;
	label?: string;
	format?: string;
	onSelect?: (string) => void;
	isRange?: boolean;
	defaultValue?: any;
	disabledDate?: (any) => boolean;
	placeholder?: string;
	disabled?: boolean;
	defaultPickerValue?: [Moment, Moment];
	onCalendarChange?: (any) => boolean;
	customDisabledDate?: (any) => boolean;
}

export const FormCalendar = memo((props: Props) => {
	const {
		name,
		label,
		format,
		isRange,
		defaultValue,
		disabledDate,
		placeholder,
		disabled,
		defaultPickerValue,
		onCalendarChange,
		customDisabledDate,
	} = props;

	const handleChange = (selectedValue) => {
		if (props.onSelect) {
			props.onSelect(selectedValue);
		}
	};

	const disableDate = (current) => {
		return current && current < moment().endOf('day');
	};

	return (
		<Form.Item label={label} key={name} name={name} className="calendar__label">
			{isRange ? (
				<DatePicker.RangePicker
					name={name}
					format={format}
					onChange={handleChange}
					className="calendar__input"
					disabledDate={customDisabledDate ? customDisabledDate : disableDate}
					disabled={disabled}
					defaultValue={defaultValue}
					defaultPickerValue={defaultPickerValue}
					onCalendarChange={onCalendarChange}
				/>
			) : (
				<DatePicker
					disabledDate={disabledDate}
					defaultValue={defaultValue}
					name={name}
					format={format}
					onChange={handleChange}
					className="calendar__input"
					placeholder={placeholder}
					disabled={disabled}
				/>
			)}
		</Form.Item>
	);
});
