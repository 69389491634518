import './style.scss';

import { Button, Modal } from 'antd';
import React, { useState } from 'react';

import { TabsWrapper } from '../../../../components/TabsWrapper';
import {
	summaryDashboardTabs,
	UseFieldTranslations,
} from '../../definitions/tabs';

const MODAL_WIDTH: number = 800;

export const DrawerWrapper = () => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const tabTranslations = UseFieldTranslations();

	const handleShowModal = () => setShowModal(!showModal);

	const handleHideModal = () => setShowModal(false);

	return (
		<>
			<span onClick={handleShowModal}>
				<Button type="primary">{tabTranslations.managementDashboard}</Button>
			</span>
			<Modal
				title={tabTranslations.managementDashboard}
				visible={showModal}
				onCancel={handleHideModal}
				width={MODAL_WIDTH}
				footer={null}
				centered={true}
			>
				<TabsWrapper
					classname="drawer-wrapper__tabs"
					tabs={summaryDashboardTabs}
					translations={tabTranslations}
					activeKey={undefined}
				/>
			</Modal>
		</>
	);
};
