import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { selectHedgeAccountList } from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';
import { mapToLabelValue } from 'utils/helpers';

const domain = translations.app.containers.Transactions.components.HedgeAccount;

interface Props {
	disabled?: boolean;
}

export const HedgeAccount = memo(({ disabled }: Props) => {
	const { t: translate } = useTranslation();

	const hedgeAccountList = mapToLabelValue(useSelector(selectHedgeAccountList));

	return (
		<GenericForm.FormItem label={translate(domain.label)} name="hedgeAccount">
			<Dropdown disabled={disabled} options={hedgeAccountList} />
		</GenericForm.FormItem>
	);
});
