import { useEffect, useState } from 'react';

import { BREAK_POINTS } from 'utils/constants';

interface MediaQuery {
	isMobile: boolean;
	isTablet: boolean;
	isDesktop: boolean;
}

const initialState = {
	isMobile: false,
	isTablet: false,
	isDesktop: false,
};

const getViewportWidth = () => {
	if (!('innerWidth' in window)) {
		return (document.documentElement || document.body).clientWidth;
	} else {
		return window.innerWidth;
	}
};

const resize = () => {
	const viewportWidth = getViewportWidth();

	return {
		isMobile: viewportWidth <= BREAK_POINTS.MOBILE_MAX,
		isTablet:
			viewportWidth <= BREAK_POINTS.TABLET_MAX &&
			viewportWidth >= BREAK_POINTS.TABLET_MIN,
		isDesktop: viewportWidth >= BREAK_POINTS.DESKTOP_MIN,
	};
};

export const useResize = () => {
	const [screens, setScreens] = useState<MediaQuery>(initialState);

	useEffect(() => {
		const handleResize = () => {
			const newScreens = resize();
			setScreens(newScreens);
		};

		handleResize();

		window?.addEventListener('resize', handleResize);

		return () => {
			window?.removeEventListener('resize', handleResize);
		};
	}, []);

	return screens;
};
