import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { legacyLocationsRoot } = apiEndpoints;

export function* editLocation(action: PayloadAction<any>) {
	const { data, id, successMessage } = action.payload;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${legacyLocationsRoot}?id=${id}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		data,
	);

	// Success actions
	if (!!responseData) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
			}),
		);

		yield put(actions.setCurrentModal(null));
		yield put(actions.loadLocationsList());
		yield put(actions.locationEdited(responseData));
	} else if (responseError?.status === 2) {
		yield put(actions.setErrorsInLine(responseError.data));
	}
}

export function* getLocationById(action: PayloadAction<any>) {
	const { id } = action.payload;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${legacyLocationsRoot}/${id}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.requestedLocationById(responseData));
	} else if (responseError?.status === 2) {
		yield put(actions.setErrorsInLine(responseError.data));
	}
}
