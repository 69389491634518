import { call, put, select } from 'typed-redux-saga';

import { GenericItem } from 'types/GenericItem';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import {
	httpMethod,
	isGenericErrorWithDetail,
	requestWithSchema,
} from 'utils/request';

import {
	selectSelectedCommodity,
	selectSelectedDate,
	selectSelectedRegion,
} from '../selectors';
import { actions } from '../slice';
import {
	CropYearBalance,
	LiveLedgerResponse,
	liveLedgerResponseSchema,
	Transaction,
} from '../types';

const { liveLedgerRoot } = apiEndpoints;

export function* getLiveLedgerList() {
	const commoditySelectedId = yield* select(selectSelectedCommodity);
	const dateSelected = yield* select(selectSelectedDate);
	const regionId = yield* select(selectSelectedRegion);
	let requestURL;

	if (!commoditySelectedId) {
		return;
	} else {
		const params = new URLSearchParams({
			CommodityId: commoditySelectedId,
			regionId: regionId,
		});
		if (dateSelected) params.append('Date', dateSelected);
		requestURL = new URL(`${apiRoutes.base}/${liveLedgerRoot}?${params}`);
	}

	const request = requestWithSchema<LiveLedgerResponse>;
	const response = yield* call(request, requestURL, httpMethod.Get, {
		schema: liveLedgerResponseSchema,
	});

	if (response.data) {
		const dataList: Transaction[] = response.data.transactions;
		const totalOrders: number = response.data.total;
		const cropList: CropYearBalance[] = response.data.cropYearBalance;
		const liveLedgerEvents: GenericItem[] = response.data.events;
		yield* put(actions.liveLedgerListLoaded(dataList));
		yield* put(actions.setTotalLiveLedgerList(totalOrders));
		yield* put(actions.cropYearBalanceListLoaded(cropList));
		yield* put(actions.setLiveLedgerEvents(liveLedgerEvents));
	} else if (isGenericErrorWithDetail(response.error)) {
		yield* put(actions.liveLedgerListError(response.error));
	}
}
