import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.hedgeMapping || initialState;

export const selectHedgeMappingList = createSelector(
	[selectDomain],
	(hedgeMappingState) => hedgeMappingState.list.data,
);

export const selectHedgeMappingListLoading = createSelector(
	[selectDomain],
	(hedgeMappingState) => hedgeMappingState.list.loading,
);

export const selectHedgeMappingListError = createSelector(
	[selectDomain],
	(hedgeMappingState) => hedgeMappingState.list.error,
);

export const selectHedgeMapTemplateHedgeUpdate = createSelector(
	[selectDomain],
	(hedgeMappingState) => hedgeMappingState.hedgeMapTemplateUpdate,
);

export const selectCurrentModal = createSelector(
	[selectDomain],
	(hedgeMappingState) => hedgeMappingState.currentModal,
);

export const selectCommodityInfo = createSelector(
	[selectDomain],
	(hedgeMappingState) => hedgeMappingState.regionInfo.commodityId,
);

export const selectRegionInfo = createSelector(
	[selectDomain],
	(hedgeMappingState) => hedgeMappingState.regionInfo.regionId,
);

export const selectDownloadTemplateUrl = createSelector(
	[selectDomain],
	(hedgeMappingState) => hedgeMappingState.downloadFilledHedgemapExcel.url,
);
