import Icon from '@ant-design/icons';
import React from 'react';

// Topbar
const AppsSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="currentColor"
	>
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z" />
	</svg>
);

const BellSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="currentColor"
	>
		<path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z" />
	</svg>
);

const AppIconSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 116 116"
		fill="currentColor"
		width="50px"
		height="50px"
	>
		<path
			id="Path_3"
			data-name="Path 3"
			d="M18.57,89l.182-29.7a15.229,15.229,0,0,1,4.462-10.681L41.275,30.56l-.18,29.7a15.241,15.241,0,0,1-4.462,10.683Z"
			transform="translate(-18.57 -2.368)"
			fill="#005c50"
		/>
		<path
			id="Path_4"
			data-name="Path 4"
			d="M34.441,73.131l.18-29.7a15.243,15.243,0,0,1,4.464-10.683L57.146,14.69l-.18,29.7A15.24,15.24,0,0,1,52.5,55.069Z"
			transform="translate(-6.56 -13.92)"
			fill="#5f9600"
		/>
		<path
			id="Path_5"
			data-name="Path 5"
			d="M20.955,75.7l29.7-.18A15.243,15.243,0,0,0,61.336,71.06L79.4,53l-29.7.18a15.243,15.243,0,0,0-10.683,4.464Z"
			transform="translate(-16.705 15.179)"
			fill="#005c50"
		/>
		<path
			id="Path_6"
			data-name="Path 6"
			d="M36.826,59.833l29.7-.18A15.235,15.235,0,0,0,77.2,55.189L95.267,37.128l-29.7.18a15.243,15.243,0,0,0-10.683,4.464Z"
			transform="translate(-5.275 3.12)"
			fill="#5f9600"
		/>
		<path
			id="Rectangle_1313"
			data-name="Rectangle 1313"
			d="M5.137,0H34.349a0,0,0,0,1,0,0V29.212a5.137,5.137,0,0,1-5.137,5.137H0a0,0,0,0,1,0,0V5.137A5.137,5.137,0,0,1,5.137,0Z"
			transform="translate(56.177 0)"
			fill="#75797a"
		/>
		<path
			id="Path_7"
			data-name="Path 7"
			d="M18.57,58.584,62.686,14.47H27.569a9,9,0,0,0-9,9Z"
			transform="translate(-18.57 -14.092)"
			fill="#75797a"
		/>
		<path
			id="Path_8"
			data-name="Path 8"
			d="M89.481,40.721,45.365,84.837H80.482a9,9,0,0,0,9-9Z"
			transform="translate(1.402 5.578)"
			fill="#75797a"
		/>
	</svg>
);

const NoteAddSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		height="24"
		viewBox="0 0 24 24"
		width="24"
	>
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 14h-3v3h-2v-3H8v-2h3v-3h2v3h3v2zm-3-7V3.5L18.5 9H13z" />
	</svg>
);

const AnalyticsSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		height="24"
		viewBox="0 0 24 24"
		width="24"
		fill="currentColor"
	>
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-5h2v5zm4 0h-2v-3h2v3zm0-5h-2v-2h2v2zm4 5h-2V7h2v10z" />
	</svg>
);

const LiveLedgerSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		height="24"
		viewBox="0 0 24 24"
		width="24"
		fill="currentColor"
	>
		<path fill="none" d="M0 0h24v24H0V0z" />
		<path d="M16.1 10c0 .4.2.6.7.8.1 0 .1.1.2.1V9.2c-.5 0-.9.3-.9.8zM17.8 11.9h-.1v1.9c.6-.1 1-.4 1-.9s-.3-.8-.9-1z" />
		<path d="M21.9 4.3h-5.7c-1.3.1-2.5.5-3.6 1.3-.2.2-.4.2-.7.3-.2 0-.5-.1-.7-.3-1-.8-2.3-1.2-3.6-1.3H1.9c-.8 0-1.2.3-1.2 1.2V19c0 .9.4 1.2 1.2 1.2H6c1.8-.2 3.7.3 5.2 1.4.2.2.4.2.7.3.2 0 .5-.1.7-.3 1.5-1 3.3-1.5 5.2-1.4h4.1c.8 0 1.2-.3 1.2-1.2V5.5c.1-.9-.3-1.2-1.2-1.2zM9.4 14.1H4.3v-1.4h5.1v1.4zm0-3.5H4.3V9.2h5.1v1.4zm8.2 3.9v.4c0 .1-.1.1-.1.1h-.4c-.1 0-.1-.1-.1-.1v-.4c-1 .1-1.8-.6-1.9-1.6 0-.1.1-.1.1-.1h.7c.1 0 .1 0 .1.1.1.5.5.9 1 .9v-2l-.3-.1c-.8-.1-1.4-.8-1.4-1.6 0-1 .8-1.6 1.8-1.7v-.5c0-.1.1-.1.1-.1h.4c.1 0 .1.1.1.1v.5c.9 0 1.6.7 1.8 1.5 0 .1-.1.1-.1.1h-.6c-.1 0-.1 0-.1-.1-.1-.5-.5-.8-.9-.8V11l.4.1c.9.2 1.5.7 1.5 1.6-.2 1.1-1 1.7-2.1 1.8z" />
	</svg>
);

const OriginatorSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		height="24"
		width="24"
		viewBox="0 0 1024 1024"
	>
		<path d="M832 64H192c-17.7 0-32 14.3-32 32v224h704V96c0-17.7-14.3-32-32-32zM456 216c0 4.4-3.6 8-8 8H264c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h184c4.4 0 8 3.6 8 8v48zM160 928c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V704H160v224zm576-136c22.1 0 40 17.9 40 40s-17.9 40-40 40-40-17.9-40-40 17.9-40 40-40zM160 640h704V384H160v256zm96-152c0-4.4 3.6-8 8-8h184c4.4 0 8 3.6 8 8v48c0 4.4-3.6 8-8 8H264c-4.4 0-8-3.6-8-8v-48z" />
	</svg>
);
const HedgeSummarySvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		height="24"
		width="24"
		viewBox="0 0 24 24"
	>
		<path d="M19 13c.34 0 .67.04 1 .09V8l-6-6H6c-1.11 0-2 .89-2 2v16c0 1.11.89 2 2 2h7.81c-.51-.88-.81-1.9-.81-3c0-3.31 2.69-6 6-6m-6-9.5L18.5 9H13V3.5m7 16V18h-4v-2h4v-1.5l3 2.5l-3 2.5m-2 .5h4v2h-4v1.5L15 21l3-2.5V20Z" />
	</svg>
);

const SettingsSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		height="24"
		viewBox="0 0 24 24"
		width="24"
	>
		<g>
			<path d="M0,0h24v24H0V0z" fill="none" />
			<path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" />
		</g>
	</svg>
);

const EmployeeSvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 170">
		<g id="Employee">
			<rect width="170" height="170" fill="none" />
			<g
				id="Group_1891"
				data-name="Group 1891"
				transform="translate(-117.628 -82.451)"
			>
				<path
					id="Path_820"
					data-name="Path 820"
					d="M231.673,192.625a29.787,29.787,0,0,1,14.142-3.544h.017a.558.558,0,0,0,.382-.973A64.577,64.577,0,0,0,228.2,176.676c-.069-.035-.139-.052-.209-.087a43,43,0,1,0-50.348,0c-.069.035-.139.052-.208.087a65.285,65.285,0,0,0-39.8,58.757,1.389,1.389,0,0,0,1.355,1.424h10.442a1.4,1.4,0,0,0,1.39-1.355,52.011,52.011,0,0,1,79.449-42.93,1.407,1.407,0,0,0,1.407.052Zm-28.858-21.091a29.866,29.866,0,1,1,21.092-8.756A29.638,29.638,0,0,1,202.815,171.534Z"
					transform="translate(0)"
					fill="#464849"
				/>
				<g
					id="Group_384"
					data-name="Group 384"
					transform="translate(225.558 200.244)"
				>
					<path
						id="Path_3"
						data-name="Path 3"
						d="M643.739,785.04l.084-13.62a6.978,6.978,0,0,1,2.046-4.9l8.283-8.285-.083,13.619a7,7,0,0,1-2.047,4.9Z"
						transform="translate(-643.739 -745.308)"
						fill="#464849"
					/>
					<path
						id="Path_4"
						data-name="Path 4"
						d="M717.342,712.652l.076-13.62a7,7,0,0,1,2.049-4.9l8.282-8.283-.082,13.619a6.988,6.988,0,0,1-2.042,4.9Z"
						transform="translate(-704.555 -685.496)"
						fill="#464849"
					/>
					<path
						id="Path_5"
						data-name="Path 5"
						d="M654.963,874.221l13.619-.082a7,7,0,0,0,4.9-2.047l8.283-8.283-13.62.083a6.987,6.987,0,0,0-4.9,2.047Z"
						transform="translate(-653.013 -832.538)"
						fill="#464849"
					/>
					<path
						id="Path_6"
						data-name="Path 6"
						d="M727.028,800.477l13.62-.082a6.994,6.994,0,0,0,4.9-2.048l8.284-8.283-13.62.081a6.993,6.993,0,0,0-4.9,2.048Z"
						transform="translate(-712.558 -771.606)"
						fill="#464849"
					/>
					<path
						id="Rectangle_1313"
						data-name="Rectangle 1313"
						d="M798.731,683.817h9.063v9.063a6.691,6.691,0,0,1-6.692,6.692h-9.063v-9.063a6.692,6.692,0,0,1,6.692-6.692Z"
						transform="translate(-766.274 -683.817)"
						fill="#464849"
					/>
					<path
						id="Path_7"
						data-name="Path 7"
						d="M643.739,705.043l20.229-20.229h-16.1a4.128,4.128,0,0,0-4.127,4.128h0Z"
						transform="translate(-643.739 -684.641)"
						fill="#464849"
					/>
					<path
						id="Path_8"
						data-name="Path 8"
						d="M787.436,806.042,767.2,826.274h16.106a4.127,4.127,0,0,0,4.13-4.127h0Z"
						transform="translate(-745.751 -784.807)"
						fill="#464849"
					/>
				</g>
			</g>
		</g>
	</svg>
);

const CommoditySvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 170">
		<g id="Commodities">
			<rect width="170" height="170" fill="none" />
			<g
				id="Group_382"
				data-name="Group 382"
				transform="translate(50.552 16.078)"
			>
				<g id="Group_381" data-name="Group 381">
					<path
						id="Path_814"
						data-name="Path 814"
						d="M67.08,69.45H60.128c.009-.8.013-1.6.013-2.4,0-18.529-2.458-35.408-6.917-47.53C48.954,7.921,43.009,1.141,36.347.181A4.341,4.341,0,0,0,34.7.019,4.338,4.338,0,0,0,33.049.18c-6.662.96-12.606,7.74-16.878,19.343-4.461,12.122-6.919,29-6.919,47.531,0,.8,0,1.6.013,2.4H2.313A2.313,2.313,0,0,0,0,71.764v32.384a34.7,34.7,0,1,0,69.393,0V71.764A2.313,2.313,0,0,0,67.08,69.45M26.033,10.306q-.851,2.747-1.571,6.248c-.134.65-.264,1.321-.39,2.008H21.5a39.374,39.374,0,0,1,4.535-8.256M19.766,23.188h3.549q-.617,4.253-1.083,9.252H17.239a92.232,92.232,0,0,1,2.527-9.252M16.315,37.067h5.531q-.326,4.377-.549,9.252H14.977q.55-4.887,1.338-9.252M14.526,50.946h6.586q-.152,4.441-.225,9.252h-6.9Q14.151,55.388,14.526,50.946ZM13.89,64.824h6.943q-.016,2.277-.016,4.626v.525a20.8,20.8,0,0,0-4.626-.525h-2.3c-.009-.8-.009-3.888,0-4.626ZM32.384,77.205a20.947,20.947,0,0,0-6.935-5.574q0-1.08,0-2.18,0-2.371.017-4.626h6.922Zm0-17.007h-6.87q.076-4.909.229-9.252h6.64Zm0-13.879H25.931c.156-3.345.344-6.422.559-9.252h5.894Zm0-13.879h-5.5c.333-3.507.708-6.572,1.108-9.252h4.393Zm0-13.879H28.775c1.216-6.332,2.551-9.8,3.609-11.693ZM55.5,69.45H53.2a20.793,20.793,0,0,0-4.626.525V69.45q0-2.352-.017-4.626H55.5c.008.738.008,3.828,0,4.626M55.4,60.2h-6.9q-.073-4.807-.225-9.252h6.586q.371,4.441.535,9.252m-.986-13.879H48.1q-.223-4.87-.548-9.252h5.531q.784,4.364,1.338,9.252M52.155,32.441H47.162q-.465-5-1.083-9.252h3.549a92.233,92.233,0,0,1,2.527,9.252ZM47.9,18.562H45.323c-.126-.687-.256-1.359-.39-2.008q-.723-3.506-1.571-6.248A39.479,39.479,0,0,1,47.9,18.562M37.01,6.915C38,8.708,39.247,11.883,40.4,17.487q.109.528.214,1.075H37.01Zm0,16.273H41.4q.636,4.224,1.112,9.252H37.01Zm0,13.879h5.9q.33,4.368.557,9.252H37.01Zm0,13.879h6.643q.153,4.436.227,9.252H37.01Zm0,13.879h6.922q.016,2.276.017,4.626,0,1.1,0,2.179A20.944,20.944,0,0,0,37.01,77.2Z"
						transform="translate(0 0)"
						fill="#464849"
					/>
				</g>
			</g>
		</g>
	</svg>
);

const UserRolSvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 170">
		<g id="User_Roles">
			<rect width="170" height="170" fill="none" />
			<g
				id="Group_383"
				data-name="Group 383"
				transform="translate(-117.629 -88.565)"
			>
				<path
					id="Path_817"
					data-name="Path 817"
					d="M681.812,666.173l-19.567-6.668a3.182,3.182,0,0,0-1.739,0l-19.566,6.668a1.381,1.381,0,0,0-.87,1.221v27.778a2.163,2.163,0,0,0,.726,1.492l19.848,15.467a1.236,1.236,0,0,0,1.452,0l19.848-15.467a2.141,2.141,0,0,0,.726-1.492V667.394a1.358,1.358,0,0,0-.858-1.221Zm-3.276,27.893-17.159,13.371-17.16-13.371V669.443l17.16-5.85,17.159,5.85Zm-23.367-11.574a.928.928,0,0,0-.749-.38h-3.179a.462.462,0,0,0-.374.731l7.279,10.019a.929.929,0,0,0,1.5,0l12.242-16.855a.462.462,0,0,0-.374-.731h-3.179a.938.938,0,0,0-.749.38l-8.688,11.966-3.726-5.131Z"
					transform="translate(-415.881 -464.202)"
					fill="#464849"
				/>
				<path
					id="Path_818"
					data-name="Path 818"
					d="M230.884,191.837a29.538,29.538,0,0,1,14.023-3.514h.017a.553.553,0,0,0,.379-.965,64.031,64.031,0,0,0-17.865-11.336c-.069-.034-.138-.052-.207-.086a42.639,42.639,0,1,0-49.926,0c-.069.035-.138.052-.207.086a64.738,64.738,0,0,0-39.469,58.265,1.378,1.378,0,0,0,1.343,1.412h10.354a1.387,1.387,0,0,0,1.378-1.344,51.575,51.575,0,0,1,78.783-42.57,1.4,1.4,0,0,0,1.4.052Zm-28.615-20.915a29.616,29.616,0,1,1,20.915-8.683A29.391,29.391,0,0,1,202.269,170.922Z"
					transform="translate(0 0)"
					fill="#464849"
				/>
			</g>
		</g>
	</svg>
);

const TotalAccQuantitySvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
		<path
			d="M4 .1h8c2.2 0 4 1.8 4 4v8c0 2.2-1.8 4-4 4H4c-2.2 0-4-1.8-4-4v-8c0-2.3 1.8-4 4-4z"
			fill="#464849"
		/>
		<path
			d="M10.2 6.3c.1 0 .2.1.3.1.5 0 .6-.2.6-.4V4.9c0-.3-.3-.6-.6-.6H5.6c-.4 0-.6.2-.6.6 0 .1 0 .3.1.4l2.3 2.8-2.3 2.8c-.2.3-.2.7.1.9.1.1.3.1.4.1h4.9c.3 0 .6-.3.6-.6v-1.1c0-.2-.2-.4-.6-.4s-.6.2-.6.4v.4h-3l1.8-2.2c.2-.2.2-.6 0-.8L6.9 5.5h3v.4c0 .2.1.4.3.4z"
			fill="#fff"
		/>
	</svg>
);

const BookAndOfferSvg = () => (
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.4 64.2">
		<g id="Layer_1"></g>
		<g id="Layer_2">
			<g>
				<g>
					<path d="M6.3,32.2c0-0.4,0-0.8,0-1.3C6.3,31.3,6.3,31.7,6.3,32.2z" />
					<path d="M1.7,39.2c-0.1-0.3-0.2-0.7-0.2-1C1.5,38.6,1.6,38.9,1.7,39.2z" />
					<path d="M6.3,32.4c0,0.3,0,0.7,0.1,1c0-0.4-0.1-0.8-0.1-1.2C6.3,32.2,6.3,32.3,6.3,32.4z" />
					<path d="M38.3,1.5c0.8,0.2,1.5,0.4,2.3,0.6C39.8,1.8,39,1.6,38.3,1.5z" />
					<path d="M9.2,43.2c-0.2-0.3-0.3-0.6-0.4-0.9C8.9,42.6,9.1,42.9,9.2,43.2z" />
					<path d="M1,35.6c0-0.2-0.1-0.4-0.1-0.7C0.9,35.2,1,35.4,1,35.6z" />
					<path d="M6.7,36c-0.1-0.4-0.1-0.8-0.2-1.2C6.6,35.2,6.6,35.6,6.7,36z" />
					<path d="M6.4,29.5c0-0.4,0.1-0.8,0.1-1.2C6.4,28.7,6.4,29.1,6.4,29.5z" />
					<path d="M1.3,37.4c-0.1-0.3-0.1-0.5-0.2-0.8C1.2,36.9,1.2,37.1,1.3,37.4z" />
					<path d="M9.6,9.8c0.2-0.2,0.4-0.4,0.6-0.6C10,9.4,9.8,9.6,9.6,9.8z" />
					<path d="M6.5,34.7c-0.1-0.4-0.1-0.8-0.1-1.2C6.4,33.9,6.4,34.3,6.5,34.7z" />
					<path d="M40.7,2.1c0.8,0.2,1.5,0.5,2.3,0.8C42.2,2.6,41.5,2.4,40.7,2.1z" />
					<path d="M6.3,30.8c0-0.4,0-0.8,0.1-1.2C6.3,30,6.3,30.4,6.3,30.8z" />
					<path d="M12.1,15.3c-0.1,0.1-0.1,0.2-0.2,0.2c0.4-0.4,0.7-0.9,1.1-1.3C12.7,14.6,12.4,15,12.1,15.3z" />
					<path d="M3.6,44.4C3.6,44.4,3.6,44.4,3.6,44.4C3.6,44.4,3.6,44.4,3.6,44.4z" />
					<path d="M2.3,41.1c-0.1-0.4-0.3-0.8-0.4-1.2C2,40.3,2.1,40.7,2.3,41.1z" />
					<path
						d="M36.7,1.1C36.7,1.1,36.6,1.1,36.7,1.1C36.3,1.1,36,1,35.7,1c0.8,0.1,1.6,0.3,2.4,0.4
				C37.6,1.3,37.2,1.2,36.7,1.1z"
					/>
					<path d="M4.9,46.9c-0.1-0.1-0.1-0.2-0.2-0.3C4.8,46.7,4.8,46.8,4.9,46.9z" />
					<path d="M13.6,13.7c0.3-0.3,0.6-0.6,0.9-0.8C14.2,13.1,13.9,13.4,13.6,13.7z" />
					<path d="M10.5,17.4c0.3-0.5,0.7-1,1-1.4C11.2,16.4,10.9,16.9,10.5,17.4z" />
					<path d="M6.5,28.2c0.3-2.6,1.1-5.1,2.2-7.5C7.6,23.1,6.8,25.6,6.5,28.2z" />
					<path d="M9.3,19.4c0.3-0.6,0.6-1.1,1-1.7C10,18.3,9.6,18.8,9.3,19.4z" />
					<path d="M11.8,7.8c0.2-0.2,0.4-0.3,0.6-0.5C12.2,7.5,12,7.7,11.8,7.8z" />
					<path d="M2.6,42c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c-0.1-0.2-0.2-0.4-0.2-0.6C2.5,41.7,2.5,41.8,2.6,42z" />
					<path d="M8.7,20.6C8.9,20.3,9,20,9.2,19.7C9,20,8.9,20.3,8.7,20.6z" />
					<path d="M59.7,19.4c0.1,0.1,0.1,0.3,0.2,0.4C59.8,19.7,59.8,19.6,59.7,19.4z" />
					<path d="M2.2,22.1c0.2-0.7,0.5-1.4,0.8-2.1C2.6,20.7,2.4,21.4,2.2,22.1z" />
					<path d="M53.9,10.4c1.5,1.7,2.9,3.5,4,5.4C56.8,13.9,55.4,12.1,53.9,10.4z" />
					<path d="M1.5,24.4c0.1-0.6,0.3-1.3,0.5-1.9C1.8,23.2,1.7,23.8,1.5,24.4z" />
					<path d="M39.7,55.2c-0.3,0.1-0.6,0.2-0.9,0.3C39.1,55.4,39.4,55.3,39.7,55.2z" />
					<path d="M3.1,19.4c0.3-0.6,0.5-1.2,0.8-1.8C3.7,18.2,3.4,18.8,3.1,19.4z" />
					<path d="M38.6,55.6c-0.3,0.1-0.7,0.2-1,0.3C37.9,55.7,38.2,55.6,38.6,55.6z" />
					<path d="M60.3,20.9c0,0.1,0.1,0.2,0.1,0.3C60.4,21.1,60.3,21,60.3,20.9z" />
					<path d="M0.8,33.8c0-0.2,0-0.4,0-0.6C0.8,33.4,0.8,33.6,0.8,33.8z" />
					<path d="M59,17.8c0.1,0.2,0.2,0.4,0.3,0.7C59.2,18.3,59.1,18.1,59,17.8z" />
					<path d="M5.9,14.3c0.3-0.4,0.6-0.9,0.9-1.3C6.5,13.5,6.2,13.9,5.9,14.3z" />
					<path d="M15.4,12.1c0.3-0.2,0.6-0.5,0.8-0.7C15.9,11.6,15.6,11.9,15.4,12.1z" />
					<path d="M5,15.7c-0.3,0.5-0.6,1.1-0.9,1.6c0.3-0.7,0.7-1.3,1.1-1.9C5.2,15.5,5.1,15.6,5,15.7z" />
					<path d="M10.4,45.2c-0.1-0.2-0.2-0.4-0.4-0.6C10.2,44.8,10.3,45,10.4,45.2z" />
					<path d="M11.1,46.2c-0.1-0.2-0.2-0.3-0.3-0.5C10.9,45.9,11,46.1,11.1,46.2z" />
					<path d="M0.8,29.7c0-0.8,0.1-1.6,0.2-2.4C0.9,28.2,0.8,29,0.8,29.7z" />
					<path d="M43.2,3c0.8,0.3,1.5,0.7,2.2,1C44.7,3.7,43.9,3.3,43.2,3z" />
					<path d="M11.9,47.2c-0.1-0.2-0.2-0.3-0.4-0.5C11.6,46.9,11.8,47.1,11.9,47.2z" />
					<path d="M9.8,44.2c-0.1-0.2-0.3-0.5-0.4-0.7C9.5,43.7,9.6,44,9.8,44.2z" />
					<path d="M1,27.2c0.1-0.8,0.2-1.5,0.4-2.3C1.2,25.6,1.1,26.4,1,27.2z" />
					<path d="M14.6,50.1c-0.2-0.2-0.4-0.3-0.5-0.5C14.2,49.7,14.4,49.9,14.6,50.1z" />
					<path d="M13.6,49.2c-0.2-0.2-0.3-0.3-0.5-0.5C13.3,48.9,13.5,49,13.6,49.2z" />
					<path d="M8.1,11.5c0.2-0.2,0.3-0.4,0.5-0.5C8.4,11.1,8.2,11.3,8.1,11.5z" />
					<path d="M47.2,5c-0.5-0.3-1.1-0.6-1.6-0.9c0.7,0.4,1.5,0.8,2.2,1.3C47.5,5.3,47.3,5.1,47.2,5z" />
					<path d="M12.7,48.2c-0.1-0.2-0.3-0.3-0.4-0.5C12.5,47.9,12.6,48.1,12.7,48.2z" />
					<path d="M39.8,61c-1.8,0.5-3.6,0.8-5.4,1C36.2,61.8,38,61.5,39.8,61z" />
					<path d="M56.4,28.7c0,0.2,0.1,0.5,0.1,0.7C56.5,29.2,56.5,29,56.4,28.7z" />
					<path d="M23.8,1.7c0.4-0.1,0.8-0.2,1.3-0.3C24.6,1.4,24.2,1.5,23.8,1.7z" />
					<path d="M6.7,36.1c0.4,2.1,1.1,4.2,2,6.1c-0.4-0.7-0.7-1.5-1-2.3C7.3,38.7,7,37.4,6.7,36.1z" />
					<path d="M34,62c-0.4,0-0.7,0-1.1,0.1C33.3,62.1,33.7,62.1,34,62z" />
					<path d="M58,16.1c0.2,0.4,0.4,0.7,0.6,1.1C58.4,16.8,58.2,16.4,58,16.1z" />
					<path d="M20.9,2.5c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0.4-0.1,0.7-0.3,1.1-0.4C21.6,2.3,21.2,2.4,20.9,2.5z" />
					<path d="M56.3,27.5c0,0.2,0.1,0.4,0.1,0.6C56.3,27.9,56.3,27.7,56.3,27.5z" />
					<path d="M30.7,62.1c-0.3,0-0.5,0-0.8,0C30.2,62.1,30.5,62.1,30.7,62.1z" />
					<path d="M13.6,6.4C13.8,6.3,14,6.1,14.2,6C14,6.1,13.8,6.3,13.6,6.4z" />
					<path d="M32.4,62.1c-0.3,0-0.6,0-0.9,0C31.8,62.1,32.1,62.1,32.4,62.1z" />
					<path d="M56,26.2c0,0.2,0.1,0.4,0.1,0.5C56.1,26.6,56.1,26.4,56,26.2z" />
					<path d="M55.2,23.1C55.2,23.1,55.2,23.1,55.2,23.1C55.2,23.1,55.2,23.1,55.2,23.1z" />
					<path d="M35.6,1c-0.1,0-0.2,0-0.3-0.1c0,0,0,0,0,0c-1.5-0.2-3.1-0.3-4.6-0.2c0,0,0,0,0,0C32.2,0.7,33.9,0.8,35.6,1z" />
					<path
						d="M14.9,50.4c0.1,0.1,0.3,0.3,0.4,0.4l0,0c4.6,3.8,10.6,6,17,5.8c1.7-0.1,3.4-0.3,5.1-0.7
				C29.3,57.8,20.9,55.6,14.9,50.4z"
					/>
					<path d="M41.1,60.6c-0.4,0.1-0.8,0.3-1.2,0.4C40.3,60.8,40.7,60.7,41.1,60.6z" />
					<path d="M42,60.3C42,60.3,42,60.3,42,60.3c-0.1,0-0.3,0.1-0.5,0.2C41.6,60.4,41.8,60.4,42,60.3z" />
					<path d="M0.7,29.8c0,0.8-0.1,1.7,0,2.6c0,0,0,0,0,0C0.7,31.5,0.7,30.6,0.7,29.8z" />
					<path d="M22.1,2.1C22.5,2,23,1.9,23.4,1.7C23,1.9,22.5,2,22.1,2.1z" />
					<path d="M18.2,3.7c0.1,0,0.2-0.1,0.3-0.1C18.4,3.6,18.3,3.6,18.2,3.7z" />
					<path d="M53.8,10.4c-1.7-1.9-3.7-3.5-6-4.9c0.7,0.5,1.5,1,2.2,1.5C51.4,8,52.7,9.2,53.8,10.4z" />
					<path d="M25.6,1.3c0.4-0.1,0.7-0.1,1.1-0.2C26.3,1.1,25.9,1.2,25.6,1.3z" />
					<path d="M54.3,21c0.2,0.5,0.5,1,0.7,1.6C54.8,22,54.6,21.5,54.3,21z" />
					<path d="M35.4,6.6c0.1,0,0.2,0,0.3,0C35.6,6.6,35.5,6.6,35.4,6.6z" />
					<path d="M27.4,1c0.3,0,0.6-0.1,0.9-0.1C28,0.9,27.7,0.9,27.4,1z" />
					<path d="M34.3,6.4C34.3,6.4,34.3,6.4,34.3,6.4C34.3,6.4,34.3,6.4,34.3,6.4z" />
					<path d="M8.3,51.6c-0.3-0.4-0.7-0.8-1-1.2C7.6,50.7,7.9,51.2,8.3,51.6z" />
					<path d="M10.1,53.5c-0.4-0.3-0.7-0.7-1-1C9.4,52.8,9.7,53.1,10.1,53.5z" />
					<path d="M37.7,7.1c0.2,0,0.3,0.1,0.5,0.1C38,7.1,37.8,7.1,37.7,7.1z" />
					<path d="M36.5,6.8c0.1,0,0.3,0.1,0.4,0.1C36.8,6.8,36.6,6.8,36.5,6.8z" />
					<path d="M16.9,10.9c0.4-0.3,0.8-0.6,1.3-0.8C17.8,10.3,17.4,10.6,16.9,10.9z" />
					<path d="M6.6,49.5C6.3,49,6,48.6,5.7,48.1C6,48.6,6.3,49,6.6,49.5z" />
					<path d="M29.3,0.8c0.2,0,0.5,0,0.7,0C29.8,0.7,29.5,0.8,29.3,0.8z" />
					<path d="M53.4,19.2c0.2,0.3,0.3,0.6,0.5,0.9C53.8,19.8,53.6,19.5,53.4,19.2z" />
					<path d="M19,9.6c0.5-0.3,0.9-0.5,1.4-0.8C19.9,9,19.4,9.3,19,9.6z" />
					<path d="M21.1,8.5c0.5-0.2,1.1-0.5,1.6-0.7C22.2,8,21.6,8.3,21.1,8.5z" />
					<path d="M13.5,56.4c0,0-0.1-0.1-0.1-0.1C13.4,56.3,13.5,56.3,13.5,56.4z" />
					<path d="M42.5,8.8c0.1,0.1,0.2,0.1,0.3,0.2C42.7,8.9,42.6,8.9,42.5,8.8z" />
					<path d="M41.3,8.3c0.1,0.1,0.3,0.1,0.4,0.2C41.6,8.4,41.4,8.3,41.3,8.3z" />
					<path d="M38.9,7.4c0.2,0.1,0.4,0.1,0.5,0.2C39.2,7.5,39.1,7.4,38.9,7.4z" />
					<path d="M52.4,17.5c0.1,0.1,0.1,0.2,0.2,0.3C52.5,17.7,52.4,17.6,52.4,17.5z" />
					<path d="M16,4.8c0.1-0.1,0.3-0.2,0.4-0.2C16.3,4.7,16.2,4.8,16,4.8z" />
					<path d="M28.9,62c-0.2,0-0.3,0-0.5,0C28.6,62,28.8,62,28.9,62z" />
					<path d="M12,55.2c-0.3-0.3-0.7-0.6-1-0.8C11.3,54.7,11.7,54.9,12,55.2z" />
					<path d="M40.1,7.8c0.2,0.1,0.3,0.1,0.5,0.2C40.4,7.9,40.3,7.9,40.1,7.8z" />
					<path d="M18.5,3.5c0.7-0.3,1.5-0.6,2.2-0.9C20,2.9,19.3,3.2,18.5,3.5z" />
					<path d="M12.4,7.3c0.4-0.3,0.8-0.6,1.2-0.9C13.2,6.7,12.8,7,12.4,7.3z" />
					<path d="M8.6,10.9c0.3-0.4,0.7-0.8,1.1-1.1C9.3,10.1,8.9,10.5,8.6,10.9z" />
					<path d="M7,12.8c-0.1,0.1-0.1,0.2-0.2,0.2c0.4-0.6,0.8-1.1,1.3-1.6C7.7,11.9,7.3,12.4,7,12.8z" />
					<path d="M14.2,6c0.6-0.4,1.2-0.8,1.8-1.1C15.4,5.2,14.8,5.6,14.2,6z" />
					<path d="M16.4,4.6C17,4.3,17.6,4,18.2,3.7C17.6,4,17,4.3,16.4,4.6z" />
					<path d="M10.2,9.2c0.5-0.5,1-0.9,1.5-1.3C11.2,8.2,10.7,8.7,10.2,9.2z" />
					<path d="M34.3,62c-0.1,0-0.2,0-0.3,0C34.1,62,34.2,62,34.3,62z" />
					<path d="M9,52.4c-0.3-0.3-0.5-0.6-0.8-0.9C8.5,51.9,8.8,52.1,9,52.4z" />
					<path d="M11,54.4c-0.3-0.3-0.6-0.6-1-0.9C10.4,53.8,10.7,54.1,11,54.4z" />
					<path d="M7.2,50.3C7,50,6.8,49.8,6.6,49.5C6.8,49.8,7,50,7.2,50.3z" />
					<path
						d="M4.5,46.3C4.6,46.3,4.6,46.3,4.5,46.3c0.1,0.1,0.1,0.2,0.2,0.3c-0.4-0.7-0.8-1.4-1.1-2.1
				C3.9,45.1,4.2,45.7,4.5,46.3z"
					/>
					<path d="M12.8,55.9c0.2,0.1,0.4,0.3,0.5,0.4c-0.5-0.3-0.9-0.7-1.3-1C12.3,55.4,12.5,55.7,12.8,55.9z" />
					<path
						d="M5.3,47.6C5.3,47.6,5.3,47.6,5.3,47.6c0.2,0.2,0.3,0.4,0.4,0.6c-0.3-0.4-0.6-0.9-0.8-1.3
				C5,47.1,5.1,47.3,5.3,47.6z"
					/>
					<path d="M30,62.1c-0.3,0-0.7,0-1-0.1C29.3,62.1,29.6,62.1,30,62.1z" />
					<path d="M39.9,60.9c0,0-0.1,0-0.1,0C39.8,61,39.9,61,39.9,60.9z" />
					<path d="M41.4,60.5c-0.1,0-0.2,0.1-0.3,0.1C41.2,60.5,41.3,60.5,41.4,60.5z" />
					<path d="M3.6,44.4c-0.3-0.7-0.7-1.5-0.9-2.3C2.9,42.9,3.2,43.6,3.6,44.4z" />
					<path d="M33,62.1c-0.2,0-0.4,0-0.6,0C32.6,62.1,32.8,62.1,33,62.1z" />
					<path d="M31.5,62.1c-0.3,0-0.5,0-0.8,0C31,62.1,31.2,62.1,31.5,62.1z" />
					<path d="M0.7,32.5c0,0.3,0,0.5,0.1,0.8c0-0.3,0-0.6,0-0.9C0.7,32.4,0.7,32.4,0.7,32.5z" />
					<path d="M1.4,24.9c0-0.1,0.1-0.3,0.1-0.4C1.5,24.6,1.4,24.7,1.4,24.9z" />
					<path d="M0.7,29.8C0.7,29.8,0.7,29.8,0.7,29.8C0.7,29.8,0.7,29.8,0.7,29.8z" />
					<path d="M2,22.5c0-0.2,0.1-0.3,0.1-0.5C2.1,22.2,2.1,22.4,2,22.5z" />
					<path d="M1,27.4c0-0.1,0-0.1,0-0.2C1,27.2,1,27.3,1,27.4z" />
					<path d="M4,17.6c0.1-0.1,0.1-0.2,0.2-0.3C4.1,17.4,4,17.5,4,17.6z" />
					<path d="M2.9,20c0.1-0.2,0.2-0.4,0.2-0.6C3.1,19.6,3,19.8,2.9,20z" />
					<path d="M5.2,15.4c0.2-0.3,0.4-0.7,0.7-1C5.7,14.7,5.5,15,5.2,15.4z" />
					<path d="M1.9,39.9c-0.1-0.2-0.1-0.4-0.2-0.6C1.8,39.5,1.8,39.7,1.9,39.9z" />
					<path d="M2.4,41.5c-0.1-0.1-0.1-0.3-0.1-0.4C2.3,41.2,2.4,41.4,2.4,41.5z" />
					<path d="M1.5,38.3c-0.1-0.3-0.1-0.6-0.2-0.8C1.4,37.7,1.4,38,1.5,38.3z" />
					<path d="M0.9,34.9c0-0.4-0.1-0.7-0.1-1.1C0.8,34.2,0.9,34.6,0.9,34.9z" />
					<path d="M1.1,36.6c-0.1-0.3-0.1-0.7-0.2-1C1,35.9,1.1,36.3,1.1,36.6z" />
					<path d="M14,49.6c-0.1-0.1-0.3-0.3-0.4-0.4C13.8,49.3,13.9,49.4,14,49.6z" />
					<path d="M18.2,10c0.2-0.2,0.5-0.3,0.8-0.4C18.7,9.7,18.5,9.9,18.2,10z" />
					<path d="M16.2,11.4c0.2-0.2,0.5-0.4,0.7-0.5C16.7,11,16.4,11.2,16.2,11.4z" />
					<path d="M14.4,12.9c0.3-0.3,0.6-0.5,0.9-0.8C15,12.3,14.7,12.6,14.4,12.9z" />
					<path d="M20.4,8.8c0.2-0.1,0.5-0.2,0.7-0.3C20.9,8.6,20.6,8.7,20.4,8.8z" />
					<path d="M36.9,6.9c0.3,0.1,0.5,0.1,0.7,0.2C37.4,7,37.2,6.9,36.9,6.9z" />
					<path d="M34.3,6.4c0.4,0,0.7,0.1,1,0.1C35,6.5,34.7,6.5,34.3,6.4z" />
					<path d="M13,14.3c0.2-0.2,0.4-0.4,0.5-0.6C13.4,13.9,13.2,14.1,13,14.3z" />
					<path d="M35.6,6.6c0.3,0,0.6,0.1,0.9,0.2C36.2,6.7,35.9,6.7,35.6,6.6z" />
					<path d="M11.6,16c0.1-0.1,0.2-0.3,0.3-0.4C11.8,15.7,11.7,15.8,11.6,16z" />
					<path d="M6.5,28.3C6.5,28.3,6.5,28.2,6.5,28.3C6.5,28.2,6.5,28.3,6.5,28.3z" />
					<path d="M38.2,7.2c0.2,0.1,0.5,0.1,0.7,0.2C38.6,7.3,38.4,7.3,38.2,7.2z" />
					<path d="M6.3,30.9c0,0,0-0.1,0-0.1C6.3,30.8,6.3,30.9,6.3,30.9z" />
					<path d="M6.3,29.6C6.3,29.6,6.3,29.5,6.3,29.6C6.3,29.5,6.3,29.6,6.3,29.6z" />
					<path d="M8.6,20.8c0-0.1,0.1-0.1,0.1-0.2C8.7,20.6,8.7,20.7,8.6,20.8z" />
					<path d="M21.9,2.2c0.1,0,0.1,0,0.2-0.1C22,2.2,21.9,2.2,21.9,2.2z" />
					<path d="M9.3,19.5c0,0.1-0.1,0.1-0.1,0.2c0.1-0.1,0.1-0.2,0.2-0.3C9.3,19.4,9.3,19.5,9.3,19.5z" />
					<path d="M41.7,8.5c0.2,0.1,0.5,0.2,0.7,0.3C42.2,8.7,42,8.6,41.7,8.5z" />
					<path d="M52.4,17.5c-2.3-3.5-5.4-6.3-9-8.2l0,0C43.2,9.2,43,9.1,42.8,9C46.7,10.9,50,13.9,52.4,17.5z" />
					<path d="M56.5,29.4c0,0.2,0,0.4,0,0.6C56.5,29.8,56.5,29.6,56.5,29.4z" />
					<path d="M56.4,28.1c0,0.2,0.1,0.4,0.1,0.6C56.4,28.5,56.4,28.3,56.4,28.1z" />
					<path
						d="M34.3,6.4c-3.8-0.4-7.7,0-11.4,1.3c-0.1,0-0.1,0.1-0.2,0.1c2.5-0.9,5.1-1.4,7.8-1.5
				C31.8,6.2,33.1,6.3,34.3,6.4z"
					/>
					<path d="M13.5,56.4c4.5,3.3,9.7,5.1,14.9,5.6c-4.4-0.4-8.8-1.8-12.7-4.2C14.9,57.4,14.2,56.9,13.5,56.4z" />
					<path d="M40.6,8c0.2,0.1,0.5,0.2,0.7,0.3C41.1,8.2,40.8,8.1,40.6,8z" />
					<path d="M56.2,26.8c0,0.2,0.1,0.5,0.1,0.7C56.2,27.2,56.2,27,56.2,26.8z" />
					<path d="M52.6,17.8c0.3,0.5,0.6,1,0.9,1.4C53.2,18.7,52.9,18.2,52.6,17.8z" />
					<path d="M39.4,7.6c0.2,0.1,0.5,0.2,0.7,0.2C39.9,7.7,39.6,7.6,39.4,7.6z" />
					<path d="M6.3,32.2C6.3,32.2,6.3,32.2,6.3,32.2C6.3,32.2,6.3,32.2,6.3,32.2z" />
					<path d="M55.2,23.1c0.3,1,0.6,2,0.9,3.1C55.8,25.2,55.5,24.2,55.2,23.1z" />
					<path d="M55.1,22.9c0-0.1-0.1-0.2-0.1-0.3c0.1,0.2,0.1,0.3,0.2,0.5C55.1,23,55.1,22.9,55.1,22.9z" />
					<path d="M53.9,20.1c0.1,0.3,0.3,0.5,0.4,0.8C54.2,20.7,54.1,20.4,53.9,20.1z" />
					<path d="M10.3,17.7c0.1-0.1,0.1-0.2,0.2-0.3C10.5,17.5,10.4,17.6,10.3,17.7z" />
					<path d="M47.7,5.4c0,0,0.1,0.1,0.1,0.1C47.8,5.4,47.8,5.4,47.7,5.4z" />
					<path d="M45.4,4.1c0,0,0.1,0,0.1,0.1C45.5,4.1,45.4,4.1,45.4,4.1z" />
					<path d="M40.5,2.1c0.1,0,0.1,0,0.2,0.1C40.6,2.1,40.6,2.1,40.5,2.1z" />
					<path d="M43,2.9C43,3,43.1,3,43.2,3C43.1,3,43,3,43,2.9z" />
					<path d="M53.8,10.4C53.8,10.4,53.8,10.4,53.8,10.4C53.8,10.4,53.8,10.4,53.8,10.4z" />
					<path d="M59.3,18.5c0.1,0.3,0.3,0.6,0.4,0.9C59.6,19.1,59.4,18.8,59.3,18.5z" />
					<path d="M57.9,15.8c0.1,0.1,0.1,0.2,0.1,0.3C58,16,57.9,15.9,57.9,15.8z" />
					<path d="M58.6,17.1c0.1,0.2,0.2,0.5,0.4,0.7C58.9,17.6,58.8,17.4,58.6,17.1z" />
					<path d="M35.6,1c0.1,0,0.1,0,0.2,0C35.7,1,35.6,1,35.6,1z" />
					<path d="M25,1.4c0.2,0,0.3-0.1,0.5-0.1C25.4,1.3,25.2,1.3,25,1.4z" />
					<path d="M38.1,1.4c0,0,0.1,0,0.1,0C38.2,1.5,38.2,1.4,38.1,1.4z" />
					<path d="M26.7,1.1C26.9,1,27.2,1,27.4,1C27.2,1,26.9,1,26.7,1.1z" />
					<path d="M23.4,1.7c0.1,0,0.2-0.1,0.3-0.1C23.7,1.7,23.6,1.7,23.4,1.7z" />
					<path d="M59.9,19.9c0.1,0.4,0.3,0.7,0.4,1.1C60.2,20.6,60,20.2,59.9,19.9z" />
					<path d="M30.4,0.7c-0.1,0-0.3,0-0.4,0c0.2,0,0.3,0,0.5,0C30.5,0.7,30.4,0.7,30.4,0.7z" />
					<path d="M28.3,0.8c0.3,0,0.6-0.1,1-0.1C29,0.8,28.7,0.8,28.3,0.8z" />
					<path d="M14.9,50.4c-0.1-0.1-0.2-0.2-0.3-0.3C14.7,50.2,14.8,50.3,14.9,50.4z" />
					<path d="M11.5,46.8c-0.1-0.2-0.3-0.4-0.4-0.6C11.2,46.4,11.4,46.6,11.5,46.8z" />
					<path d="M9.4,43.5c-0.1-0.1-0.1-0.2-0.2-0.3C9.3,43.3,9.3,43.4,9.4,43.5z" />
					<path d="M10.8,45.7c-0.1-0.2-0.2-0.4-0.4-0.5C10.5,45.4,10.6,45.6,10.8,45.7z" />
					<path d="M10,44.7c-0.1-0.1-0.2-0.3-0.3-0.4C9.9,44.4,9.9,44.5,10,44.7z" />
					<path d="M6.5,34.8C6.5,34.8,6.5,34.7,6.5,34.8C6.5,34.7,6.5,34.8,6.5,34.8z" />
					<path d="M6.7,36.1C6.7,36.1,6.7,36.1,6.7,36.1C6.7,36.1,6.7,36.1,6.7,36.1z" />
					<path d="M8.8,42.3c0,0,0-0.1-0.1-0.1C8.7,42.3,8.8,42.3,8.8,42.3z" />
					<path d="M62.1,30.4c-0.1-3.2-0.7-6.3-1.7-9.2c1.1,3,1.6,6.1,1.7,9.3C62.1,30.5,62.2,30.4,62.1,30.4z" />
					<path d="M12.3,47.8c-0.1-0.2-0.3-0.4-0.4-0.5C12,47.4,12.2,47.6,12.3,47.8z" />
					<path d="M38.9,55.5c-0.1,0-0.2,0.1-0.3,0.1C38.7,55.5,38.8,55.5,38.9,55.5z" />
					<path d="M37.6,55.8c-0.1,0-0.1,0-0.2,0C37.4,55.9,37.5,55.8,37.6,55.8z" />
					<path d="M13.2,48.7c-0.1-0.2-0.3-0.3-0.4-0.5C12.9,48.4,13,48.5,13.2,48.7z" />
					<path d="M6.4,33.5c0,0,0-0.1,0-0.1C6.4,33.4,6.4,33.5,6.4,33.5z" />
					<path
						d="M42.3,59.5C42.3,59.5,42.3,59.5,42.3,59.5L42.3,59.5l-1.6-4.1c-0.1-0.3-0.4-0.4-0.7-0.3
				c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.1-0.7,0.2-1,0.3c-0.1,0-0.1,0-0.2,0
				c-1.6,0.4-3.3,0.6-5.1,0.7c-6.5,0.2-12.4-2-17-5.8l0,0c-0.1-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.2-0.2-0.3-0.3
				c-0.2-0.2-0.4-0.3-0.5-0.5c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1-0.2-0.3-0.3-0.4-0.5
				c-0.1-0.2-0.3-0.3-0.4-0.5c-0.1-0.2-0.3-0.4-0.4-0.5c-0.1-0.2-0.2-0.3-0.4-0.5c-0.1-0.2-0.3-0.4-0.4-0.6
				c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.2-0.4-0.4-0.5c-0.1-0.2-0.2-0.4-0.4-0.6c-0.1-0.1-0.2-0.3-0.3-0.4
				c-0.1-0.2-0.3-0.5-0.4-0.7c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.3-0.3-0.6-0.4-0.9c0,0,0-0.1-0.1-0.1c-0.9-1.9-1.6-4-2-6.1
				c0,0,0,0,0-0.1c-0.1-0.4-0.1-0.8-0.2-1.2c0,0,0-0.1,0-0.1c-0.1-0.4-0.1-0.8-0.1-1.2c0,0,0-0.1,0-0.1c0-0.3-0.1-0.7-0.1-1
				c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0-0.4,0-0.8,0-1.3c0,0,0-0.1,0-0.1c0-0.4,0-0.8,0.1-1.2c0,0,0-0.1,0-0.1c0-0.4,0.1-0.8,0.1-1.2
				c0,0,0,0,0,0c0.3-2.6,1.1-5.1,2.2-7.5c0-0.1,0.1-0.1,0.1-0.2C8.9,20.3,9,20,9.2,19.7c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0.1-0.1
				c0.3-0.6,0.6-1.1,1-1.7c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.5,0.7-1,1-1.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.2,0.2-0.2
				c0.3-0.4,0.6-0.7,0.9-1.1c0.2-0.2,0.4-0.4,0.5-0.6c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.3,0.6-0.5,0.9-0.8c0.3-0.2,0.6-0.5,0.8-0.7
				c0.2-0.2,0.5-0.4,0.7-0.5c0.4-0.3,0.8-0.6,1.3-0.8c0.2-0.2,0.5-0.3,0.8-0.4c0.5-0.3,0.9-0.5,1.4-0.8c0.2-0.1,0.5-0.2,0.7-0.3
				c0.5-0.2,1.1-0.5,1.6-0.7c0.1,0,0.1-0.1,0.2-0.1c3.7-1.3,7.6-1.8,11.4-1.3c0,0,0,0,0,0c0.3,0,0.7,0.1,1,0.1c0.1,0,0.2,0,0.3,0
				c0.3,0,0.6,0.1,0.9,0.2c0.1,0,0.3,0.1,0.4,0.1c0.2,0.1,0.5,0.1,0.7,0.2c0.2,0,0.3,0.1,0.5,0.1c0.2,0.1,0.5,0.1,0.7,0.2
				c0.2,0.1,0.4,0.1,0.5,0.2c0.2,0.1,0.5,0.2,0.7,0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.5,0.2,0.7,0.3c0.1,0.1,0.3,0.1,0.4,0.2
				c0.2,0.1,0.5,0.2,0.7,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.4,0.2,0.5,0.3l0,0c3.6,1.9,6.7,4.7,9,8.2c0.1,0.1,0.1,0.2,0.2,0.3
				c0.3,0.5,0.6,1,0.9,1.4c0.2,0.3,0.3,0.6,0.5,0.9c0.1,0.3,0.3,0.5,0.4,0.8c0.2,0.5,0.5,1,0.7,1.6c0,0.1,0.1,0.2,0.1,0.3
				c0,0.1,0,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0.4,1,0.6,2,0.9,3.1c0,0.2,0.1,0.4,0.1,0.5c0,0.2,0.1,0.5,0.1,0.7c0,0.2,0.1,0.4,0.1,0.6
				c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.5,0.1,0.7c0,0.2,0,0.4,0,0.6c0,0.2,0,0.3,0,0.5c0,0.3,0.3,0.6,0.6,0.6l4.4-0.1l0,0c0,0,0,0,0,0
				c0.3,0,0.5-0.2,0.5-0.5c-0.1-3.2-0.7-6.3-1.7-9.3c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.4-0.3-0.7-0.4-1.1c-0.1-0.1-0.1-0.3-0.2-0.4
				c-0.1-0.3-0.3-0.6-0.4-0.9c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.2-0.5-0.4-0.7c-0.2-0.4-0.4-0.7-0.6-1.1
				c-0.1-0.1-0.1-0.2-0.1-0.3c-1.1-1.9-2.5-3.7-4-5.4c0,0,0,0,0,0C52.7,9.2,51.4,8,50,7c-0.7-0.5-1.4-1-2.2-1.5c0,0-0.1-0.1-0.1-0.1
				c-0.7-0.5-1.4-0.9-2.2-1.3c0,0-0.1,0-0.1-0.1c-0.7-0.4-1.5-0.7-2.2-1C43.1,3,43,3,43,2.9c-0.7-0.3-1.5-0.6-2.3-0.8
				c-0.1,0-0.1,0-0.2-0.1c-0.7-0.2-1.5-0.4-2.3-0.6c0,0-0.1,0-0.1,0c-0.8-0.2-1.6-0.3-2.4-0.4c-0.1,0-0.1,0-0.2,0
				c-1.7-0.2-3.3-0.3-5-0.3c0,0,0,0,0,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.5,0-0.7,0c-0.3,0-0.6,0-1,0.1c-0.3,0-0.6,0.1-0.9,0.1
				c-0.2,0-0.5,0.1-0.7,0.1c-0.4,0.1-0.7,0.1-1.1,0.2c-0.2,0-0.3,0.1-0.5,0.1c-0.4,0.1-0.8,0.2-1.3,0.3c-0.1,0-0.2,0.1-0.3,0.1
				C23,1.9,22.5,2,22.1,2.1c-0.1,0-0.1,0-0.2,0.1c-0.4,0.1-0.8,0.3-1.1,0.4c-0.8,0.3-1.5,0.6-2.2,0.9c-0.1,0-0.2,0.1-0.3,0.1
				C17.6,4,17,4.3,16.4,4.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.6,0.4-1.2,0.7-1.8,1.1c-0.2,0.1-0.4,0.3-0.6,0.4c-0.4,0.3-0.8,0.6-1.2,0.9
				c-0.2,0.2-0.4,0.3-0.6,0.5c-0.5,0.4-1,0.9-1.5,1.3C10,9.4,9.8,9.6,9.6,9.8c-0.4,0.4-0.7,0.7-1.1,1.1c-0.2,0.2-0.3,0.4-0.5,0.5
				c-0.4,0.5-0.9,1.1-1.3,1.6c-0.3,0.4-0.6,0.8-0.9,1.3c-0.2,0.3-0.5,0.7-0.7,1c-0.4,0.6-0.8,1.3-1.1,1.9C4.1,17.4,4,17.5,4,17.6
				c-0.3,0.6-0.6,1.2-0.8,1.8C3.1,19.6,3,19.8,2.9,20c-0.3,0.7-0.5,1.4-0.8,2.1c0,0.1-0.1,0.3-0.1,0.5c-0.2,0.6-0.4,1.3-0.5,1.9
				c0,0.1-0.1,0.3-0.1,0.4c-0.2,0.8-0.3,1.5-0.4,2.3c0,0.1,0,0.1,0,0.2c-0.1,0.8-0.2,1.6-0.2,2.4c0,0,0,0,0,0c0,0.8-0.1,1.7,0,2.5
				c0,0,0,0,0,0c0,0.3,0,0.6,0,0.9c0,0.2,0,0.4,0,0.6c0,0.4,0.1,0.7,0.1,1.1c0,0.2,0.1,0.4,0.1,0.7c0,0.3,0.1,0.7,0.2,1
				c0,0.3,0.1,0.5,0.2,0.8c0.1,0.3,0.1,0.6,0.2,0.8c0.1,0.3,0.2,0.7,0.2,1c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.4,0.2,0.8,0.4,1.2
				c0,0.1,0.1,0.3,0.1,0.4c0.1,0.2,0.1,0.4,0.2,0.6c0.3,0.8,0.6,1.5,0.9,2.3c0,0,0,0.1,0,0.1c0.3,0.7,0.7,1.4,1.1,2.1
				c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.4,0.5,0.9,0.8,1.3C6,48.6,6.3,49,6.6,49.5C6.8,49.8,7,50,7.2,50.3c0.3,0.4,0.7,0.8,1,1.2
				c0.3,0.3,0.5,0.6,0.8,0.9c0.3,0.4,0.7,0.7,1,1c0.3,0.3,0.6,0.6,1,0.9c0.3,0.3,0.7,0.6,1,0.8c0.4,0.4,0.9,0.7,1.3,1
				c0,0,0.1,0.1,0.1,0.1c0.7,0.5,1.4,1,2.2,1.4c3.9,2.3,8.3,3.8,12.7,4.2c0.2,0,0.3,0,0.5,0c0.3,0,0.7,0.1,1,0.1c0.3,0,0.5,0,0.8,0
				c0.3,0,0.5,0,0.8,0c0.3,0,0.6,0,0.9,0c0.2,0,0.4,0,0.6,0c0.4,0,0.7,0,1.1-0.1c0.1,0,0.2,0,0.3,0c1.8-0.2,3.7-0.5,5.4-1
				c0,0,0.1,0,0.1,0c0.4-0.1,0.8-0.2,1.2-0.4c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.4-0.1,0.6-0.2C42.3,60.1,42.4,59.8,42.3,59.5z"
					/>
				</g>
				<path
					id="file-done"
					d="M56.9,34c-8.1,0-14.8,6.6-14.8,14.8s6.6,14.8,14.8,14.8s14.8-6.6,14.8-14.8c0,0,0,0,0,0
			C71.7,40.6,65.1,34,56.9,34z M64.3,56.1c-4.1,4.1-10.6,4.1-14.7,0c-4.1-4.1-4.1-10.6,0-14.7s10.6-4.1,14.7,0c2,1.9,3,4.6,3,7.3
			C67.3,51.5,66.2,54.1,64.3,56.1z M63,43.5h-3c-0.2,0-0.3,0.1-0.4,0.2l-4.3,6l-1.6-2.2c-0.1-0.1-0.3-0.2-0.4-0.2h-3
			c-0.3,0-0.5,0.2-0.5,0.5c0,0.1,0,0.2,0.1,0.3l5,7c0.2,0.2,0.5,0.3,0.8,0.1c0,0,0.1-0.1,0.1-0.1l7.8-10.8c0.2-0.2,0.1-0.6-0.1-0.8
			C63.3,43.6,63.1,43.5,63,43.5L63,43.5z"
				/>
				<g>
					<path
						d="M42.2,37.8c0-4.8-3-6.9-7.5-8v0L33,29.4v-9.2c2.6,0.4,4.2,2,4.5,4c0,0.3,0.3,0.5,0.5,0.5h3.1
				c0.3,0,0.6-0.3,0.6-0.6c0,0,0,0,0,0c-0.4-4.3-3.9-7-8.6-7.5v-2.2c0-0.3-0.2-0.5-0.5-0.5h-1.9c-0.3,0-0.5,0.2-0.5,0.5v2.3
				c-4.9,0.5-8.7,3.2-8.7,8.2c0,4.6,3.4,6.9,7,7.7l1.7,0.4v9.8c-3-0.4-4.7-2-5.1-4.2c0-0.3-0.3-0.5-0.5-0.5h-3.2
				c-0.3,0-0.6,0.2-0.6,0.5c0,0,0,0,0,0.1c0.3,3.8,3.2,7.2,9.3,7.7v2.2c0,0.3,0.2,0.5,0.5,0.5h1.9c0.3,0,0.6-0.3,0.5-0.6
				c0,0,0,0,0,0l0-2.2C38.3,45.9,42.2,43,42.2,37.8z M29,28.4c-2.3-0.8-3.4-2.2-3.4-3.9c0-2.5,1.9-3.9,4.4-4.2l0,8.5
				C29.6,28.6,29.3,28.5,29,28.4z M33,42.8L33,42.8l0-9.2c0.2,0.1,0.4,0.1,0.6,0.2c3.2,1,4.3,2.4,4.3,4.5
				C37.9,40.9,35.9,42.5,33,42.8z"
					/>
				</g>
			</g>
		</g>
	</svg>
);

const CreatedFromFilledOfferSvg = () => (
	<svg
		id="Creation_fill"
		data-name="Creation fill"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 276.162 267.775"
	>
		<path
			id="Trazado_9"
			d="M141.173,38.5H127.91a9.171,9.171,0,0,0-7.368,3.789l-44,61.26-20-27.788a9.172,9.172,0,0,0-7.368-3.789H35.916A2.336,2.336,0,0,0,33.6,74.287a2.992,2.992,0,0,0,.421,1.263l34.945,48.418a9.037,9.037,0,0,0,12.42,2.105,9.98,9.98,0,0,0,2.105-2.105l59.154-82.1a2.552,2.552,0,0,0-.421-3.158A4.282,4.282,0,0,0,141.173,38.5Z"
			transform="translate(45.132 51.811)"
			fill="#1752b7"
		/>
		<path
			id="Trazado_1531"
			data-name="Trazado 1531"
			d="M59.8,122.8"
			transform="translate(74.087 144.974)"
			fill="#5a5b5b"
		/>
		<path
			id="Trazado_1532"
			data-name="Trazado 1532"
			d="M59.8-4.4"
			transform="translate(74.087 4.4)"
			fill="#5a5b5b"
		/>
		<path
			id="Trazado_1533"
			data-name="Trazado 1533"
			d="M-3.8,59.2"
			transform="translate(3.8 74.687)"
			fill="#5a5b5b"
		/>
		<path
			id="Trazado_1534"
			data-name="Trazado 1534"
			d="M123.3,59.2"
			transform="translate(144.263 74.687)"
			fill="#5a5b5b"
		/>
		<g id="Grupo_3313" data-name="Grupo 3313" transform="translate(8 9.263)">
			<path
				id="Trazado_1535"
				data-name="Trazado 1535"
				d="M152.833,2.947A127.314,127.314,0,0,0,125.887,0,125.959,125.959,0,0,0,0,125.888a112.041,112.041,0,0,0,.842,14.525A125.81,125.81,0,0,0,125.887,251.775a128.83,128.83,0,0,0,24-2.316,3.83,3.83,0,0,0,3.158-3.789V231.987a3.888,3.888,0,0,0-4.631-3.789,111.437,111.437,0,0,1-22.314,2.316c-52.839,0-96.626-39.366-103.783-90.311a107.152,107.152,0,0,1-1.053-14.525C21.051,68,68,21.051,125.887,21.051a103.982,103.982,0,0,1,26.946,3.579,105.08,105.08,0,0,1,77.68,101.257c0,3.368-.211,6.947-.421,10.315a3.763,3.763,0,0,0,3.789,4.21h13.473a3.855,3.855,0,0,0,3.789-3.368,95.166,95.166,0,0,0,.421-10.947A126.037,126.037,0,0,0,152.833,2.947Z"
				fill="#1752b7"
			/>
		</g>
		<g
			id="Filled_4"
			data-name="Filled 4"
			transform="translate(168.331 160.421)"
		>
			<ellipse
				id="Elipse_297"
				data-name="Elipse 297"
				cx="20.556"
				cy="20.555"
				rx="20.556"
				ry="20.555"
				transform="translate(33.389 33.121)"
				fill="#1752b7"
			/>
			<path
				id="Trazado_1304"
				data-name="Trazado 1304"
				d="M12.208,54.147A41.7,41.7,0,0,1,48.89,12.753a2.5,2.5,0,0,0,2.158-2.442V2.929A2.446,2.446,0,0,0,48.379.488a53.951,53.951,0,0,0-.057,107.319,2.414,2.414,0,0,0,2.669-2.442V97.983a2.454,2.454,0,0,0-2.158-2.442A41.7,41.7,0,0,1,12.208,54.147Z"
				transform="translate(0 -0.471)"
				fill="#1752b7"
			/>
			<path
				id="Trazado_1305"
				data-name="Trazado 1305"
				d="M138.94,54.147a41.7,41.7,0,0,1-36.682,41.394,2.5,2.5,0,0,0-2.158,2.442v7.382a2.446,2.446,0,0,0,2.669,2.442,53.954,53.954,0,0,0,0-107.319A2.414,2.414,0,0,0,100.1,2.929V10.31a2.454,2.454,0,0,0,2.158,2.442A41.7,41.7,0,0,1,138.94,54.147Z"
				transform="translate(-43.26 -0.47)"
				fill="#1752b7"
			/>
		</g>
	</svg>
);

const FilledOfferSvg = () => (
	<svg
		id="Filled_4"
		data-name="Filled 4"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 258.969 257.82"
	>
		<ellipse
			id="Elipse_330"
			data-name="Elipse 330"
			cx="49.366"
			cy="49.366"
			rx="49.366"
			ry="49.366"
			transform="translate(80.186 79.544)"
			fill="#609623"
		/>
		<path
			id="Trazado_1719"
			data-name="Trazado 1719"
			d="M29.32,129.379a100.142,100.142,0,0,1,88.1-99.413A6.01,6.01,0,0,0,122.6,24.1V6.374A5.874,5.874,0,0,0,116.188.51C50.866,7.329,0,62.422,0,129.379A129.67,129.67,0,0,0,116.052,258.248a5.8,5.8,0,0,0,6.409-5.864V234.657a5.893,5.893,0,0,0-5.182-5.864A100.137,100.137,0,0,1,29.32,129.379Z"
			transform="translate(0 -0.47)"
			fill="#609623"
		/>
		<path
			id="Trazado_1720"
			data-name="Trazado 1720"
			d="M193.378,129.38a100.142,100.142,0,0,1-88.1,99.413,6.01,6.01,0,0,0-5.182,5.864v17.728a5.874,5.874,0,0,0,6.41,5.864,129.577,129.577,0,0,0,0-257.738,5.8,5.8,0,0,0-6.41,5.864V24.1a5.893,5.893,0,0,0,5.182,5.864A100.142,100.142,0,0,1,193.378,129.38Z"
			transform="translate(36.408 -0.47)"
			fill="#609623"
		/>
	</svg>
);

const PartiallyFilledOfferSvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 259.104 259.102">
		<defs>
			<clipPath id="clip-path">
				<ellipse
					id="Elipse_338"
					data-name="Elipse 338"
					cx="129.552"
					cy="129.551"
					rx="129.552"
					ry="129.551"
					transform="translate(0 0)"
					fill="#609623"
				/>
			</clipPath>
		</defs>
		<g
			id="Enmascarar_grupo_24"
			data-name="Enmascarar grupo 24"
			clip-path="url(#clip-path)"
		>
			<g
				id="Partially_Filled_4"
				data-name="Partially Filled 4"
				transform="translate(0 0.588)"
			>
				<ellipse
					id="Elipse_337"
					data-name="Elipse 337"
					cx="49.392"
					cy="49.391"
					rx="49.392"
					ry="49.391"
					transform="translate(80.228 79.585)"
					fill="#609623"
				/>
				<path
					id="Trazado_1741"
					data-name="Trazado 1741"
					d="M29.335,129a100.194,100.194,0,0,1,88.142-99.464,6.013,6.013,0,0,0,5.185-5.867V5.929A5.877,5.877,0,0,0,116.249.063C50.893,6.885,0,62.006,0,129A129.737,129.737,0,0,0,116.113,257.933a5.8,5.8,0,0,0,6.413-5.867V234.329a5.9,5.9,0,0,0-5.185-5.867A100.189,100.189,0,0,1,29.335,129Z"
					transform="translate(0 -0.022)"
					fill="#609623"
				/>
				<path
					id="Trazado_1742"
					data-name="Trazado 1742"
					d="M98.317,129a100.193,100.193,0,0,1-88.142,99.464,6.013,6.013,0,0,0-5.185,5.867v17.737a5.877,5.877,0,0,0,6.413,5.867A129.737,129.737,0,0,0,127.516,129,129.738,129.738,0,0,0,11.4.064,5.8,5.8,0,0,0,4.99,5.931V23.668a5.9,5.9,0,0,0,5.185,5.867A100.193,100.193,0,0,1,98.317,129Z"
					transform="translate(131.589 -0.023)"
					fill="#bbdba4"
				/>
			</g>
		</g>
	</svg>
);

const RegionsSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		viewBox="0 0 32 32"
	>
		<path
			fill="currentColor"
			d="M29 10h-5v2h5v6h-7v2h3v2.142a4 4 0 1 0 2 0V20h2a2.003 2.003 0 0 0 2-2v-6a2.002 2.002 0 0 0-2-2zm-1 16a2 2 0 1 1-2-2a2.003 2.003 0 0 1 2 2zM19 6h-5v2h5v6h-7v2h3v6.142a4 4 0 1 0 2 0V16h2a2.002 2.002 0 0 0 2-2V8a2.002 2.002 0 0 0-2-2zm-1 20a2 2 0 1 1-2-2a2.003 2.003 0 0 1 2 2zM9 2H3a2.002 2.002 0 0 0-2 2v6a2.002 2.002 0 0 0 2 2h2v10.142a4 4 0 1 0 2 0V12h2a2.002 2.002 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2zM8 26a2 2 0 1 1-2-2a2.002 2.002 0 0 1 2 2zM3 10V4h6l.002 6z"
		/>
	</svg>
);

// Topbar
export const BellIcon = React.forwardRef((props, ref) => (
	<Icon component={BellSvg} {...props} />
));
export const AppsIcon = (props) => <Icon component={AppsSvg} {...props} />;
export const AppIcon = (props) => <Icon component={AppIconSvg} {...props} />;
export const NoteAddIcon = React.forwardRef((props, ref) => (
	<Icon component={NoteAddSvg} {...props} />
));
export const AnalyticsIcon = React.forwardRef((props, ref) => (
	<Icon component={AnalyticsSvg} {...props} />
));
export const LiveLedgerIcon = React.forwardRef((props, ref) => (
	<Icon component={LiveLedgerSvg} {...props} />
));
export const OriginatorIcon = React.forwardRef((props, ref) => (
	<Icon component={OriginatorSvg} {...props} />
));
export const HedgeSummaryIcon = React.forwardRef((props, ref) => (
	<Icon component={HedgeSummarySvg} {...props} />
));
export const SettingsIcon = React.forwardRef((props, ref) => (
	<Icon component={SettingsSvg} {...props} />
));

// Settings
export const EmployeeIcon = React.forwardRef((props, ref) => (
	<Icon component={EmployeeSvg} {...props} />
));
export const CommodityIcon = React.forwardRef((props, ref) => (
	<Icon component={CommoditySvg} {...props} />
));
export const UserRolIcon = React.forwardRef((props, ref) => (
	<Icon component={UserRolSvg} {...props} />
));
export const RegionsIcon = React.forwardRef((props, ref) => (
	<Icon component={RegionsSvg} {...props} />
));

// ReviewAndRelease
export const TotalAccQuantityIcon = React.forwardRef((props, ref) => (
	<Icon component={TotalAccQuantitySvg} {...props} />
));

// Events
export const BookAndOfferIcon = React.forwardRef((props, ref) => (
	<Icon component={BookAndOfferSvg} {...props} />
));

export const CreatedFromFilledOfferIcon = React.forwardRef((props, ref) => (
	<Icon component={CreatedFromFilledOfferSvg} {...props} />
));

export const FilledOfferIcon = React.forwardRef((props, ref) => (
	<Icon component={FilledOfferSvg} {...props} />
));

export const PartiallyFilledOfferIcon = React.forwardRef((props, ref) => (
	<Icon component={PartiallyFilledOfferSvg} {...props} />
));
