import './style.scss';

import { Col, Divider, Drawer, Layout, Menu, Row, Space, Tooltip } from 'antd';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { selectUserInfo } from 'app/containers/GlobalSaga/selectors';
import { TopBarMenu } from 'app/containers/TopBarMenu';
import { translations } from 'locales/i18n';
import { UserInfo } from 'types/UserInfo';

import { useBuildSideBarItems } from '../AppLayout/components/SideBar/hooks/useBuildSideBarItems';
import { AppIcon, AppsIcon } from '../CustomIcon';
import {
	TopBarItem,
	topBarItems as defaultTopBarItems,
	TopBarItemType,
} from '../TopBar/definitions/items';

const { Header } = Layout;
interface Props {
	title?: string;
	component?: React.ReactNode;
	topBarItems?: TopBarItem[];
	isOriginator?: boolean;
	className?: string;
}

export function MainMenu(props: Props) {
	const { topBarItems = defaultTopBarItems } = props;
	const { t: translate } = useTranslation();
	const userInfo = useSelector(selectUserInfo) as UserInfo;
	const flags = useFlags();
	const enabledTopBarItems = topBarItems.filter(
		(item) =>
			(item.featureFlag && flags[item.featureFlag]) ||
			item.featureFlag === undefined,
	);

	const history = useHistory();
	const currentRoute = useLocation().pathname;

	const sideBarItems = useBuildSideBarItems();

	const [visible, setVisible] = useState(false);

	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	const content = (
		<>
			<Menu theme="light" mode="inline">
				{sideBarItems.map((item, index) => (
					<Menu.Item
						data-testid={item.testId}
						key={index}
						icon={item.icon}
						onClick={() => {
							setVisible(false);
							item.handleClick && item.handleClick(history);
						}}
						className={classNames(item.className, {
							'selected ant-menu-item-selected': item.route === currentRoute,
						})}
					>
						{item.label}
					</Menu.Item>
				))}
			</Menu>
			<span className="topbar__vertical">
				<TopBarMenu />
			</span>
		</>
	);

	return (
		<Header className="main-menu">
			<Row className="topbar__menu" justify="space-between">
				<Row justify="start">
					<Col className="topbar__menu--logo">
						<AppIcon data-testid="sidebar-appicon" onClick={showDrawer} />
					</Col>
					<Col className="pages-menu">
						<div className="wide-screen__pages">
							<Menu
								theme="light"
								mode="horizontal"
								className="wide-screen__pages--height"
							>
								{sideBarItems.map((item, index) => (
									<Menu.Item
										data-testid={item.testId}
										key={index}
										onClick={() => item.handleClick(history)}
										className={classNames(item.className, {
											'selected ant-menu-item-selected item':
												item.route === currentRoute,
										})}
									>
										<Space>
											<span className="sidebar__tooltip">
												{item.icon}
												{item.label}
											</span>
										</Space>
									</Menu.Item>
								))}
							</Menu>
						</div>
					</Col>
				</Row>
				<h1>{userInfo?.tenantDisplayName}</h1>
				<Row justify="end">
					<Col className="button-icon-menu">
						<Space className="wide-screen">
							<TopBarMenu />
						</Space>
						<Menu theme="light" mode="horizontal">
							{enabledTopBarItems.map((item, index) => {
								if (item.type === 'divider') {
									// antd Divider component spreads a bunch of invalid attributes into the div
									// this makes React flood our log with errors when running in dev mode
									// for now, using the div with the appropriate classes applied manually
									// we should try switching back to the component when we upgrade antd (as of writing, we're on v4.6)
									return (
										<div
											key={index}
											data-testid={`topbar-item-${index}`}
											className="ant-divider ant-divider-vertical"
											role="separator"
										/>
									);
								} else {
									return (
										<Menu.Item
											key={index}
											onClick={item.handleClick}
											className="item"
										>
											{item.type === 'item' && item.tooltip && (
												<Tooltip
													color={'white'}
													title={
														<span className="topbar-tooltip">
															{translate(
																translations.app.components.AppLayout[
																	item.tooltip
																],
															)}
														</span>
													}
												>
													{item.icon}
												</Tooltip>
											)}
											{item.type === TopBarItemType.component && item.component}
										</Menu.Item>
									);
								}
							})}
						</Menu>
						<AppsIcon onClick={showDrawer} className="narrow-screen" />
					</Col>
					<Drawer
						placement="right"
						onClose={onClose}
						visible={visible}
						className="menu-drawer"
					>
						{content}
					</Drawer>
				</Row>
			</Row>
		</Header>
	);
}
