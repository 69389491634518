import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import { NotificationGroupPayload, View } from '../types';

const { customerGroupNotificationSettings, customerGroupAllNonMembers } =
	apiEndpoints;

export function* loadCustomerGroupDetails(action: PayloadAction<string>) {
	const requestURL = yield new URL(
		`${apiRoutes.base}/${customerGroupNotificationSettings}/${action.payload}`,
	);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.customerGroupDetailsLoaded(responseData));
		yield put(actions.setCurrentView(View.Edit));
	}

	if (!!responseError) {
		yield put(actions.customerGroupDetailsError(responseError));
	}
}

export function* loadCustomerGroupMembers(action: PayloadAction<string>) {
	const requestURL = yield new URL(
		`${apiRoutes.base}/${customerGroupNotificationSettings}/${action.payload}/members`,
	);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.customerGroupMembersLoaded(responseData));
	}

	if (!!responseError) {
		yield put(actions.customerGroupMembersError(responseError));
	}
}

export function* loadCustomerGroupNonMembers(
	action: PayloadAction<string | undefined>,
) {
	let requestURL: URL;

	if (action.payload) {
		requestURL = yield new URL(
			`${apiRoutes.base}/${customerGroupNotificationSettings}/${action.payload}/non-members`,
		);
	} else {
		requestURL = yield new URL(
			`${apiRoutes.base}/${customerGroupAllNonMembers}`,
		);
	}
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.customerGroupNonMembersLoaded(responseData));
	}

	if (!!responseError) {
		yield put(actions.customerGroupNonMembersError(responseError));
	}
}

export function* createCustomerGroup(
	action: PayloadAction<NotificationGroupPayload>,
) {
	const requestURL = yield new URL(
		`${apiRoutes.base}/${customerGroupNotificationSettings}`,
	);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		action.payload,
	);

	if (!!responseData) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(actions.setCurrentView(View.List));
		yield put(globalActions.loadCustomerNotificationGroupList());
	}

	if (!!responseError) {
		yield put(globalActions.customerNotificationGroupListError(responseError));
	}
}

export function* deleteCustomerGroup(action: PayloadAction<string>) {
	const requestURL = yield new URL(
		`${apiRoutes.base}/${customerGroupNotificationSettings}/${action.payload}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Delete,
	);

	if (!!responseData) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(globalActions.loadCustomerNotificationGroupList());
	}

	if (!!responseError) {
		yield put(actions.deleteCustomerGroupError(responseError));
	}
}

export function* updateCustomerGroup(
	action: PayloadAction<NotificationGroupPayload>,
) {
	const requestURL = yield new URL(
		`${apiRoutes.base}/${customerGroupNotificationSettings}/${action.payload.id}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		action.payload,
	);

	if (!!responseData) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(actions.setCurrentView(View.List));
		yield put(actions.clearCustomerGroupState());
		yield put(globalActions.loadCustomerNotificationGroupList());
	}

	if (!!responseError) {
		yield put(actions.updateCustomerGroupError(responseError));
	}
}
