import './style.scss';

import { Card, Select } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ExcelExport } from 'app/components/ExcelExport';
import { FilterDate } from 'app/components/FilterDate';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { translations } from 'locales/i18n';
import { GenericOption } from 'types/GenericOption';
import { mapToLabelValue, SetPersistedData } from 'utils/helpers';
import { useEnabledRegions } from 'utils/hooks/useEnabledRegions';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { isGenericOption, isNonEmptyArray } from 'utils/validators';

import { contractsSaga } from '../Contracts/root-saga';
import {
	reducer as ordersReducer,
	sliceKey as ordersKey,
} from '../Contracts/slice';
import { CommoditiesFilter } from './components/CommoditiesFilter';
import { CropYearCards } from './components/CropYearCards';
import { TableWrapper } from './components/TableWrapper';
import { PERSISTED_FILTER } from './constants';
import { liveLedgerSaga } from './saga';
import {
	selectCropYearBalanceList,
	selectLiveLedgerTemplate,
	selectSelectedRegion,
} from './selectors';
import { actions, reducer, sliceKey } from './slice';
import { CropYearBalance } from './types';

export function LiveLedger() {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: liveLedgerSaga });
	useInjectReducer({ key: ordersKey, reducer: ordersReducer });
	useInjectSaga({ key: ordersKey, saga: contractsSaga });

	const [regionName, setRegionName] = useState<string>('');

	const { t: translate } = useTranslation();
	const translationsScope = translations.app.containers.LiveLedger;
	const globalTranslationDomain = translations.app.commons;

	const cropYearBalances: CropYearBalance[] = useSelector(
		selectCropYearBalanceList,
	);

	const templateData = useSelector(selectLiveLedgerTemplate);
	const url = templateData?.url;
	const fileName = templateData?.fileName;
	const dispatch = useDispatch();
	const regionsOptions = useEnabledRegions(mapToLabelValue, true);
	const selectedRegion = useSelector(selectSelectedRegion);

	const handleLoadLiveLedger = useCallback(
		(regionInfo: GenericOption) => {
			setRegionName(regionInfo.label);
			SetPersistedData(PERSISTED_FILTER.REGION, regionInfo.value);
			dispatch(
				actions.changeSelectedFilters({
					selectedRegion: regionInfo.value,
				}),
			);
			dispatch(actions.loadLiveLedgerList());
		},
		[dispatch],
	);

	useEffect(() => {
		dispatch(
			globalActions.getEnabledRegionsRequest({ isEmployeeActiveRegion: true }),
		);
	}, [dispatch]);

	useEffect(() => {
		if (isNonEmptyArray(regionsOptions) && !regionName) {
			const regionInfo =
				regionsOptions.find((r) => r.value === selectedRegion) ??
				regionsOptions[0];
			handleLoadLiveLedger(regionInfo);
		}
	}, [regionsOptions, regionName, handleLoadLiveLedger, selectedRegion]);

	useEffect(() => {
		dispatch(
			globalActions.setPageTitle(
				translate(translations.app.components.AppLayout.liveLedger),
			),
		);
		return () => {
			dispatch(actions.clearLiveLedgerTemplate());
			dispatch(actions.clearSelectedDate());
		};
	}, [dispatch, translate]);

	const handleSelected = (value: number | null) => {
		dispatch(
			actions.changeSelectedFilters({
				selectedCropYear: value,
			}),
		);
	};

	const handleChangeDate = (selectedDate: string | null) => {
		dispatch(
			actions.changeSelectedFilters({
				selectedDate: !!selectedDate ? selectedDate : null,
			}),
		);
		dispatch(actions.loadLiveLedgerList());
	};

	const handleExport = () => {
		const errorMsg = translate(translationsScope.failedExport);
		const successMsg = `${translate(translationsScope.successExport)} `;
		dispatch(actions.exportLiveLedger({ errorMsg, successMsg }));
	};

	const handleChangeRegions = (_: string, regionInfo: unknown) => {
		if (isGenericOption(regionInfo)) {
			handleLoadLiveLedger(regionInfo);
		}
	};

	return (
		<>
			<Helmet>
				<title>{translate(translationsScope.liveLedgerTitle)}</title>
				<meta
					name={translate(translationsScope.liveLedgerTitle)}
					content={translate(translationsScope.liveLedgerMetaContent)}
				/>
			</Helmet>
			<div className="live-ledger__controls">
				{isNonEmptyArray(regionsOptions) && (
					<Select
						placeholder={translate(globalTranslationDomain.regions)}
						className="select"
						value={regionName}
						options={regionsOptions}
						onChange={handleChangeRegions}
					/>
				)}
				<CommoditiesFilter />
				<FilterDate
					name="LiveLedgerDate"
					handleChange={handleChangeDate}
					defaultValue={moment(new Date())}
				/>
				<ExcelExport
					url={url}
					handleFileRequest={handleExport}
					fileName={fileName}
				/>
			</div>
			<Card
				bordered={true}
				className="live-ledger__filter"
				data-testid="live-ledger-filter"
			>
				<CropYearCards
					optionsList={cropYearBalances}
					onSelected={handleSelected}
				/>
			</Card>
			<Card
				bordered={true}
				className="live-ledger__table"
				data-testid="live-ledger-table-card"
			>
				<TableWrapper />
			</Card>
		</>
	);
}
