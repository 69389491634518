/**
 *
 * Delete User Role
 *
 */
import './style.scss';

import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { translations } from 'locales/i18n';

import { selectActiveUserRoles } from '../../selectors';
import { userRolesActions } from '../../slice';

export const DeleteUserRole = () => {
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.UserRoles;
	const dispatch = useDispatch();

	const activeUserRoles: any = useSelector(selectActiveUserRoles);

	const handleConfirmation = () => {
		dispatch(userRolesActions.deleteUserRole(activeUserRoles.name));
		dispatch(userRolesActions.setCurrentModal(null));
	};

	const handleCancel = () => {
		dispatch(userRolesActions.resetActiveUserRoles());
		dispatch(userRolesActions.setCurrentModal(null));
	};
	return (
		<Modal
			maskClosable={false}
			className="orders-cancel-modal"
			title={translate(translationsScope.Modals.Delete.Title)}
			visible={true}
			onOk={handleConfirmation}
			onCancel={handleCancel}
			footer={[
				<Button
					key="back"
					onClick={handleCancel}
					className="return-button"
					data-testid="return-button"
				>
					{translate(translationsScope.Modals.Delete.CancelButton)}
				</Button>,
				<Button
					data-testid="confirm-button"
					key="submit"
					type="primary"
					onClick={handleConfirmation}
					className="cancel-button"
				>
					{translate(translationsScope.Modals.Delete.DeleteButton)}
				</Button>,
			]}
		>
			{translate(translationsScope.Modals.Delete.BodyMessage)}
		</Modal>
	);
};
