import './style.scss';

import { DatePicker, Descriptions } from 'antd';
import moment, { Moment } from 'moment-timezone';
import React, { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'app/components/Checkbox';
import { GenericForm } from 'app/components/GenericForm';
import { translations } from 'locales/i18n';
import { DATE_FORMAT, MARKET_CLOSE_TIME } from 'utils/constants';

interface Props {
	disabled?: boolean;
	messageGTC?: boolean;
	disableWeekends?: boolean;
}

export const ExpirationDateGTC = memo((props: Props) => {
	const { disabled, messageGTC = false, disableWeekends = false } = props;
	const { t: translate } = useTranslation();
	const translationScope =
		translations.app.containers.Transactions.components.expirationDateGTC;

	const gtcMode: any = useWatch({ name: 'gtcMode' });
	const { setValue, watch } = useFormContext();

	const disabledDate = (date: Moment): boolean => {
		// Check if the date is valid and in the correct format if user types the date
		if (!date || !moment(date, 'YYYY-MM-DD', true).isValid()) {
			return true;
		}
		const { hour, minute, second } = MARKET_CLOSE_TIME;
		var marketCloseTime = moment().hour(hour).minute(minute).second(second);

		const startOfToday = moment().startOf('day');
		const endOfToday = moment().endOf('day');

		const isWeekend =
			disableWeekends && (date?.day() === 0 || date?.day() === 6); // 0 = Sunday, 6 = Saturday

		return (
			date &&
			(date < startOfToday ||
				(date > marketCloseTime && date <= endOfToday) ||
				isWeekend)
		);
	};

	const handleChange = () => {
		const gtcMode: any = watch('gtcMode');
		if (gtcMode) {
			setValue('expirationDateGTC', '', { shouldValidate: true });
		}
	};

	return (
		<>
			<GenericForm.FormItem
				name="expirationDateGroup"
				label={translate(translationScope.label)}
				data-testid="expirationDateGroup-form-item"
			>
				<GenericForm.FormItem
					name="gtcMode"
					wrapperCol={{ span: 3 }}
					onChange={handleChange}
					data-testid="gtcMode-form-item"
				>
					<Checkbox
						key="gtcMode"
						disabled={disabled}
						label={translate(translationScope.gtcLabel)}
					/>
				</GenericForm.FormItem>
				<GenericForm.FormItem
					name="expirationDateGTC"
					wrapperCol={{ span: 8, offset: 1 }}
				>
					<DatePicker
						key="expirationDateGTC"
						placeholder={translate(translationScope.calendarPlaceholder)}
						format={DATE_FORMAT}
						disabled={gtcMode || disabled}
						disabledDate={disabledDate}
						className="expiration-date-gtc__input"
						{...({ 'data-testid': 'expirationDateGTC-datepicker' } as any)} // data-testid works here, but the typedefs don't allow it
					/>
				</GenericForm.FormItem>
				{messageGTC && (
					<GenericForm.FormItem
						name="expirationDateMessage"
						wrapperCol={{ span: 12 }}
					>
						<Descriptions.Item>
							{translate(translationScope.gtcInfo)}
						</Descriptions.Item>
					</GenericForm.FormItem>
				)}
			</GenericForm.FormItem>
		</>
	);
});
