import { NotificationGroupDto } from 'app/containers/GlobalSaga/types';
import { OffersModals } from 'app/containers/Offers/types';
import { UploadResponse } from 'types/Commodity';
import { GenericError } from 'types/GenericError';
import { IGenericPagination } from 'types/GenericPagination';

export enum TableTypes {
	Review = 'review',
	Filter = 'filter',
}

interface ContractsFilters {
	commodities: { value: any; label: string }[];
	locations: { value: any; label: string }[];
	cropYears: { value: any; label: string }[];
}

interface Employee {
	firstName: string;
	lastName: string;
}

export interface Bidsheet {
	basis: number;
	change: string;
	cropYear: number;
	location: string;
	createdOn: string;
	commodity: string;
	employee: Employee;
	futureMonth: string;
	deliveryStart: string;
	deliveryEnd: string;
	difference: number;
	oldBasis: number;
	oldFutureMonth: string;
	errors: GenericError[];
	id?: string;
	newRow?: boolean;
	notificationGroupIds: string[];
}

export interface BidsheetState {
	currentView: View;
	currentModal: Modal | OffersModals | null;

	bidsheetTemplate: {
		data: any;
		uploadResponse: UploadResponse;
		loading: boolean;
		error: GenericError | null;
	};

	bidsheetExport: {
		url: string | null;
		fileName: string | null;
		loading: boolean;
		error: GenericError | null;
	};

	bidsheetPublish: {
		data: boolean | null | undefined;
		loading: boolean;
		error: GenericError | null;
	};

	list: {
		data: Bidsheet[];
		loading: boolean;
		total: number;
		hasAnythingChanged: boolean;
		error: GenericError | null;
		type: TableTypes;
	};

	selectedFilters: ContractsFilters;

	pagination: IGenericPagination;

	bidsheetSummary: {
		data: {
			potentiallyFilledFlatPriceOffers: number;
			potentiallyFilledBasisOffers: number;
			orphanedBasisOffers: number;
			orphanedFlatPriceOffers: number;
		};
		loading: boolean;
		error: GenericError | null;
	};

	noBidList: {
		data: any[];
		mappedData: Record<string, NoBid>;
		loading: boolean;
		error: GenericError | null;
		selectedNoBid: any;
	};

	orphansAndAvailables: {
		totalOrphans: number | null;
		orphansList: Orphan[];
		availablesList: any[];
		mappedAvailablesList: any;
		loading: boolean;
		error: GenericError | null;
		selectedRows: any[];
		selectedAvailableBid: any;
	};

	rollOrphanOffers: {
		orphanCount: number | null;
		loading: boolean;
		error: GenericError | null;
		loaded: boolean;
	};

	validationErrors: {
		errors: any[];
	};

	notificationGroups: {
		data: NotificationGroupDto[];
		loading: boolean;
		error: GenericError | null;
	};
}

export enum View {
	List = 'list',
}

export enum Modal {
	Upload = 'Upload',
	RollOrphan = 'RollOrphan',
	Publish = 'Publish',
}

export interface Orphan {
	id: string;
	customer: {
		firstName: string;
		lastName: string;
		number: string;
	};
	commodity: string;
	cropYear: number;
	contractType: string;
	location: string;
	quantity: number;
	basis: {
		posted: number;
		push: number;
		net: number;
	};
	price: {
		cash: number;
		freight: number;
		futures: number;
		fees: [number, number];
	};
	futureMonth: string;
}

export interface NoBid {
	label: string;
	DeliveryLocationId: string;
	CommodityId: string;
	FuturesMonth: string;
	DeliveryStartDate: string;
	DeliveryEndDate: string;
}

export enum PublishType {
	publishAndClose = 'publishAndClose',
	publishAndRoll = 'publishAndRoll',
}

export interface ExportExcelPayload {
	successMsg: string;
	errorMsg: string;
}
