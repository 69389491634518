import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

import { AppliedUnappliedBushel } from 'app/containers/Contracts/components/Business/AppliedUnappliedBushel';
import { FuturesPrice } from 'app/containers/Contracts/components/Business/FuturesPrice';
import { OriginDeliveryDate } from 'app/containers/Contracts/components/Business/OriginDeliveryDate';
import { OriginDeliveryWindow } from 'app/containers/Contracts/components/Business/OriginDeliveryWindow';
import { QuantityToRoll } from 'app/containers/Contracts/components/Business/QuantityToRoll';
import { Spread } from 'app/containers/Contracts/components/Business/Spread';
import { useTranslations } from 'app/containers/Contracts/components/Modals/shared/useTranslations';
import { selectActiveContract } from 'app/containers/Contracts/selectors';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractNumber } from 'app/containers/Transactions/components/ContractNumber';
import { ContractType } from 'app/containers/Transactions/components/ContractType';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { DeliveryDates } from 'app/containers/Transactions/components/DeliveryDates';
import { DeliveryLocation } from 'app/containers/Transactions/components/DeliveryLocation';
import { Employee } from 'app/containers/Transactions/components/Employee';
import { ExpirationDate } from 'app/containers/Transactions/components/ExpirationDate';
import { Fees } from 'app/containers/Transactions/components/Fees';
import { Freight } from 'app/containers/Transactions/components/Freight';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Location } from 'app/containers/Transactions/components/Location';
import { Transaction } from 'app/containers/Transactions/components/Transaction';
import { CONSTANTS } from 'utils/constants';
import {
	customFormat,
	dateFormatYearMonthDay,
	deliveryWindowFormat,
	getDeliveryDatesMode,
	getDeliveryDateWindow,
	getExpirationDate,
	getFormattedExpirationDate,
	isDeliveryDateCustom,
	isDeliveryDateWindow,
	validatePriceFormat,
} from 'utils/helpers';
import Yup from 'yupCustom';

export const useHTASchema = () => {
	const translations = useTranslations();

	let orderData = useSelector(selectActiveContract) as any;
	if (!!!orderData) return null;

	const requiredMsg = translations.validations.required;

	return {
		initialValues: {
			contractNumber: orderData.number,
			commodity: {
				label: orderData.commodityName,
				value: orderData.commodityId,
			},
			location: {
				label: orderData.locationName,
				value: orderData.locationId,
			},
			deliveryLocation: {
				label: orderData.deliveryLocationName,
				value: orderData.deliveryLocationId,
			},
			contract: {
				label: orderData.contractTypeName,
				value: orderData.contractTypeId,
			},
			transaction: {
				label: orderData.transactionTypeName,
				value: orderData.transactionTypeId,
			},
			rolling: null,
			deliveryWindow: deliveryWindowFormat(
				orderData.futuresMonth,
				orderData.deliveryStartDate,
				orderData.deliveryEndDate,
			),
			deliveryDate: [
				moment(orderData.deliveryStartDate),
				moment(orderData.deliveryEndDate),
			],
			deliveryDatesMode: getDeliveryDatesMode(orderData),
			deliveryDateWindow: getDeliveryDateWindow(orderData),
			deliveryDateRolling: orderData.futuresMonth,
			futuresPriceInput: validatePriceFormat(orderData.futuresPrice),
			cropYear: orderData.cropYear,
			futuresMonth: { value: null },
			futuresPrice: null,
			qtyRollBalance: `${customFormat(orderData.remainingBalance, true, CONSTANTS.FIXED_QUANTITY_DECIMALS)}`,
			qtyRollAmount: null,
			spread: null,
			freight: validatePriceFormat(orderData.freightPrice),
			fees1: validatePriceFormat(orderData.fees1),
			fees2: validatePriceFormat(orderData.fees2),
			employee: {
				label: orderData.employeeName,
				value: orderData.employeeId,
			},
			comments: orderData.comments,
			expirationDate: getExpirationDate(orderData),
			useServiceFees: false,
			isRoll: false,
			isUnappliedLoad: false,
		},
		validationSchema: Yup.object().shape({
			deliveryDatesMode: Yup.object().requiredOption(),
			deliveryDate: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateCustom(mode),
				then: Yup.mixed().required(requiredMsg),
			}),
			deliveryDateWindow: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateWindow(mode),
				then: Yup.object().requiredOption(requiredMsg),
			}),
			deliveryDateRolling: Yup.string()
				.nullable()
				.required(translations.validations.required),
			qtyRollAmount: Yup.string()
				.required(translations.validations.required)
				.test(
					'minMaxValidation',
					translations.validations.rangeValue(
						customFormat(
							orderData.remainingBalance,
							true,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						),
					),
					(val) => {
						if (val) {
							try {
								const flatNumber = parseFloat(
									customFormat(val, false, CONSTANTS.FIXED_QUANTITY_DECIMALS),
								);
								return (
									flatNumber > 0 && flatNumber <= orderData!.remainingBalance
								);
							} catch (error) {
								console.error(error);
								return false;
							}
						} else return true;
					},
				)
				.nullable(),
			employee: Yup.object().requiredOption(requiredMsg),
			spread: Yup.number()
				.typeError(translations.validations.number)
				.required(translations.validations.required),
			freight: Yup.number().typeError(translations.validations.number),
			fees1: Yup.number().typeError(translations.validations.number),
			fees2: Yup.number().typeError(translations.validations.number),
			futuresMonth: Yup.object().requiredOption(requiredMsg),
			expirationDate: Yup.string().nullable(),
		}),
		elements: [
			<Commodity hidden />, // hidden fields work as inner references for business rules
			<DeliveryLocation hidden />,
			<Location />,
			<Transaction hidden />,
			<ContractType hidden />,
			<ContractNumber disabled />,
			<OriginDeliveryWindow disabled />,
			<OriginDeliveryDate disabled />,
			<FuturesPrice disabled />,
			<CropYears />,
			<DeliveryDates
				modeSelectorDisabled
				resetDependencies
				spreadDependency
				useServiceFees
				isRoll
			/>,
			<QuantityToRoll disabled={{ qtyRollBalance: true }} />,
			<AppliedUnappliedBushel />,
			<Futures
				futuresPrice={{ market: false, disabled: true }}
				futuresMonth={{
					market: true,
					loadSpread: true,
					filterMonths: true,
					filterByMonth: orderData.futuresMonth,
				}}
			/>,
			<Spread showRetryBtn />,
			<ExpirationDate />,
			<Freight />,
			<Fees />,
			<Employee />,
			<Comments />,
		],
		getSubmitValues: (values) => {
			let submitValues: any = {
				deliveryStartDate: dateFormatYearMonthDay(values.deliveryDate[0]),
				deliveryEndDate: dateFormatYearMonthDay(values.deliveryDate[1]),
				futuresMonth: values.futuresMonth?.value,
				quantity: parseFloat(
					customFormat(
						values.qtyRollAmount,
						false,
						CONSTANTS.FIXED_QUANTITY_DECIMALS,
					),
				),
				futuresPrice: parseFloat(values.futuresPrice),
				spread: values.spread,
				IsDeliveryDatesCustom: isDeliveryDateCustom(values.deliveryDatesMode),
				employeeId: values.employee.value,
				comments: values.comments,
				cropYear: values.cropYear,
				expirationDate: getFormattedExpirationDate(values),
				isAppliedLoad: !values?.isUnappliedLoad ?? true,
			};

			if (!isNaN(values.freight) || values.freight === 0) {
				submitValues = {
					...submitValues,
					freightPrice: values.freight ?? 0,
				};
			}

			if (!isNaN(values.fees1) || values.fees1 === 0) {
				submitValues = {
					...submitValues,
					fees1: values.fees1 ?? 0,
				};
			}

			if (!isNaN(values.fees2) || values.fees2 === 0) {
				submitValues = {
					...submitValues,
					fees2: values.fees2 ?? 0,
				};
			}

			return submitValues;
		},
	};
};
