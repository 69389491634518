import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { userRolesActions } from '../../slice';
import { Headers } from './Header';
import { TableWrapper } from './Table';

export const List = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(userRolesActions.loadUserRolesList());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="list-container">
			<Headers />
			<TableWrapper />
		</div>
	);
};
