import { Tabs } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { translations } from 'locales/i18n';

import { NotificationEventTypes } from '../../components/NotificationEventTypes';
import {
	selectUserNotificationPreferences,
	selectUserNotificationPreferencesLoading,
} from '../../selectors';
import { actions } from '../../slice';
import { NotificationEvent } from '../../types';
import { AlertTones } from './AlertTones';
import { UserGroups } from './UserGroups';

enum TAB_PANE {
	eventTypes = 'events',
	alertTones = 'alerts',
	groups = 'groups',
}

export const PersonalNotificationSettings = memo(() => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const [activeTab, setActiveTab] = useState<string>(TAB_PANE.eventTypes);
	const userPreferences = useSelector(selectUserNotificationPreferences);
	const loading = useSelector(selectUserNotificationPreferencesLoading);
	const translationsScope =
		translations.app.containers.Settings.sections.NotificationSettings;
	const handleSubmit = (records: NotificationEvent[]) => {
		dispatch(
			actions.setUserPreferenceStatus({
				userSubscriptions: records,
			}),
		);
	};
	const handleTabChange = (key: string) => {
		if (key !== activeTab) {
			setActiveTab(key);
		}
	};

	useEffect(() => {
		switch (activeTab) {
			case TAB_PANE.eventTypes:
				dispatch(actions.getUserPreferences());
				break;

			case TAB_PANE.alertTones:
				dispatch(actions.getUserNotificationsAlertTones());
				break;

			case TAB_PANE.groups:
				dispatch(actions.getUserAssignedGroups());
				break;

			default:
				break;
		}
	}, [activeTab]);

	return (
		<>
			<Tabs
				defaultActiveKey={activeTab}
				tabPosition="left"
				onChange={handleTabChange}
				destroyInactiveTabPane={true}
			>
				<Tabs.TabPane
					key={TAB_PANE.eventTypes}
					tab={translate(translationsScope.EventTypes)}
				>
					<NotificationEventTypes
						preferences={userPreferences}
						handleSubmit={handleSubmit}
						loading={loading}
					/>
				</Tabs.TabPane>
				<Tabs.TabPane
					key={TAB_PANE.alertTones}
					tab={translate(translationsScope.AlertTones)}
				>
					<AlertTones />
				</Tabs.TabPane>
				<Tabs.TabPane
					key={TAB_PANE.groups}
					tab={translate(translationsScope.MyGroups)}
				>
					<UserGroups />
				</Tabs.TabPane>
			</Tabs>
		</>
	);
});
