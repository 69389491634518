import { Input } from 'antd';
import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { useFormatPrice } from 'app/containers/Transactions/components/hooks/useFormatPrice';
import { translations } from 'locales/i18n';
import { preventWheelChange, preventWheelEvent } from 'utils/util';

import { SourceContext } from '../..';
import { useHandleBusinessRules } from '../hooks/useHandleBusinessRules';
interface Props {
	disabled?: {
		fee1: boolean;
		fee2: boolean;
	} | null;
	adjustFuturesPrice?: boolean;
}

export const Fees = memo((props: Props) => {
	const { disabled, adjustFuturesPrice = true } = props;
	const { t: translate } = useTranslation();
	const translationScope =
		translations.app.containers.Transactions.components.Fees;

	const { formatPrice }: any = useFormatPrice();

	const sourceName = useContext(SourceContext);

	const { manageTotalPriceCall, manageSetFuturesPriceCall } =
		useHandleBusinessRules(sourceName);

	const handleFees1Change = () => {
		manageTotalPriceCall();

		if (adjustFuturesPrice) {
			manageSetFuturesPriceCall();
		}
	};
	const handleFees2Change = () => {
		manageTotalPriceCall();

		if (adjustFuturesPrice) {
			manageSetFuturesPriceCall();
		}
	};

	return (
		<GenericForm.FormItem
			label={translate(translationScope.feesLabel)}
			name="fees"
			data-testid="fees-form-item"
		>
			<GenericForm.FormItem name="fees1" onChange={handleFees1Change}>
				<Input
					data-testid="fees1-input-item"
					placeholder={translate(translationScope.fee1.placeholder)}
					key="fees1"
					type="number"
					onWheel={preventWheelChange}
					onFocus={preventWheelEvent}
					disabled={disabled?.fee1}
					onBlur={() => formatPrice('fees1')}
					step={0.0001}
				></Input>
			</GenericForm.FormItem>

			<GenericForm.FormItem name="fees2" onChange={handleFees2Change}>
				<Input
					data-testid="fees2-input-item"
					placeholder={translate(translationScope.fee2.placeholder)}
					key="fees2"
					type="number"
					onWheel={preventWheelChange}
					onFocus={preventWheelEvent}
					disabled={disabled?.fee2}
					onBlur={() => formatPrice('fees2')}
					step={0.0001}
				></Input>
			</GenericForm.FormItem>
		</GenericForm.FormItem>
	);
});
