import { Input } from 'antd';
import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { useFormatPrice } from 'app/containers/Transactions/components/hooks/useFormatPrice';
import { translations } from 'locales/i18n';
import { preventWheelChange, preventWheelEvent } from 'utils/util';

import { SourceContext } from '../..';
import { useHandleBusinessRules } from '../hooks/useHandleBusinessRules';
interface Props {
	disabled?: boolean;
	adjustFuturesPrice?: boolean;
}

export const Freight = memo((props: Props) => {
	const { disabled, adjustFuturesPrice = true } = props;
	const { t: translate } = useTranslation();
	const translationScope =
		translations.app.containers.Transactions.components.Freight;

	const { formatPrice }: any = useFormatPrice();
	const sourceName = useContext(SourceContext);

	const { manageTotalPriceCall, manageSetFuturesPriceCall } =
		useHandleBusinessRules(sourceName);

	const handleChange = () => {
		manageTotalPriceCall();

		if (adjustFuturesPrice) {
			manageSetFuturesPriceCall();
		}
	};

	return (
		<GenericForm.FormItem
			label={translate(translationScope.freightLabel)}
			name="freight"
			onChange={handleChange}
			data-testid="freight-form-item"
		>
			<Input
				placeholder={translate(translationScope.freightPlaceholder)}
				key="freight"
				type="number"
				onWheel={preventWheelChange}
				onFocus={preventWheelEvent}
				step={0.0001}
				disabled={disabled}
				data-testid="freight-form-input"
				onBlur={() => formatPrice('freight')}
			></Input>
		</GenericForm.FormItem>
	);
});
