import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions } from 'app/containers/GlobalSaga/slice';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

const { regions, regionsForEmployeeActivePermissions } = apiEndpoints;

export function* getEnabledRegionsSaga(action: PayloadAction<any | undefined>) {
	const { isEmployeeActiveRegion } = action.payload;
	let requestURL = yield new URL(
		`${apiRoutes.base}/${regions}?enabledOnly=${true}`,
	);

	if (isEmployeeActiveRegion) {
		requestURL = yield new URL(
			`${apiRoutes.base}/${regionsForEmployeeActivePermissions}`,
		);
	}

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
		true,
	);

	if (!!responseData) {
		yield put(actions.getEnabledRegionsSuccess(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.getEnabledRegionsError(responseError));
	}
}
