import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { addComma, removeComma } from 'utils/helpers';

export const useExternalFillParams = ({
	values,
	setFieldValue,
	setFieldTouched,
	touched,
}) => {
	const dispatch = useDispatch();
	const { commodity, quantity, cropYear } = values;

	useEffect(() => {
		if (commodity) {
			dispatch(
				globalActions.loadFuturesMonthOptions({
					params: {
						commodityId: commodity.value,
					},
					source: CalculatedFieldsSource.Transactions,
				}),
			);

			setFieldValue('cropYear', '');
			setFieldValue('futuresMonth', '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [commodity]);

	useEffect(() => {
		if (!cropYear && touched['cropYear']) {
			setFieldTouched('cropYear', true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cropYear]);

	useEffect(() => {
		let value = values.quantity;

		if (!!value) {
			value = removeComma(value);
			if (isNaN(value)) {
				setFieldValue('quantity', addComma(value).slice(0, -1));
			} else {
				let result = addComma(value).replace(/(\.\d{2})\d+/g, '$1');
				setFieldValue('quantity', result);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quantity]);
};
