import { call, put, select } from 'redux-saga/effects';

import { PaginationEnum } from 'types/GenericPagination';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import {
	selectFilters,
	selectPaginationLimit,
	selectPaginationStart,
} from '../selectors';
import { actions } from '../slice';
import { Filters } from '../types';
import { FiltersEnum } from './../types';
const { customersFilter } = apiEndpoints;

export function* getCustomersList() {
	const filters: Filters = yield select(selectFilters);
	const limit = yield select(selectPaginationLimit);
	const start = yield select(selectPaginationStart);
	const requestURL = yield new URL(`${apiRoutes.base}/${customersFilter}`);

	if (!!filters && !!filters.query) {
		requestURL.searchParams.append(FiltersEnum.Query, filters.query);
	}
	requestURL.searchParams.append(PaginationEnum.Limit, limit);
	requestURL.searchParams.append(PaginationEnum.Start, start);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.customersListLoaded(responseData));
	} else if (!!responseError) {
		yield put(actions.customersListError(responseError));
	}
}
