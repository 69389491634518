import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import { activateDNHModalSaga } from '../root-saga';
import { useActivateDNHSchema } from '../schemas/useActivateDNHSchema';
import { selectDNHListLoading, selectIsModalOpen } from '../selectors';
import { actions, reducer, sliceKey } from '../slice';

export const useActivateDNHData = () => {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: activateDNHModalSaga });

	const { initialValues, validationSchema, elements } = useActivateDNHSchema();
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const isLoading = useSelector(selectDNHListLoading);
	const isModalOpen = useSelector(selectIsModalOpen);

	const formInstance = useForm({
		defaultValues: {
			...initialValues,
		},
		mode: 'all',
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		dispatch(actions.getDNHListRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		dispatch,
		elements,
		formInstance,
		isLoading,
		isModalOpen,
		translate,
		validationSchema,
	};
};
