import './style.scss';

import { Checkbox, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GenericTable } from 'app/components/GenericTable';
import { SwitchStateFull } from 'app/components/SwitchStateFull';
import { translations } from 'locales/i18n';

import { CurrentLocation } from '../../../fields/types';
import {
	selectCurrentLocationError,
	selectCurrentLocationId,
	selectFilters,
	selectListData,
	selectListLoading,
	selectListTotal,
	selectPaginationLimit,
	selectPaginationStart,
} from '../../../selectors';
import { actions } from '../../../slice';
import { Actions } from './Actions';

const { Paragraph } = Typography;

export const Table = () => {
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.Locations.View.List.Table;
	const translationsShared = translations.app.containers.Settings.Shared;

	const dispatch = useDispatch();
	const listData = useSelector(selectListData);
	const listTotal = useSelector(selectListTotal);
	const listLoading = useSelector(selectListLoading);
	const start = useSelector(selectPaginationStart);
	const limit = useSelector(selectPaginationLimit);
	const filters = useSelector(selectFilters);
	const columns = [
		{
			title: translate(translationsScope.Headers.Status),
			dataIndex: 'isActive',
			key: 'isActive',
			fixed: true,
			width: 70,
			render: (data: boolean, record: CurrentLocation) => (
				<GenericTable.Column>
					<SwitchStateFull
						initialStatus={data}
						recordId={record.id}
						toggleAction={actions.toggleStatus}
						activeRecordSelector={selectCurrentLocationId}
						errorSelector={selectCurrentLocationError}
						toogleOnMessage={translate(
							translationsScope.activateSwitch.toogleOnMessage,
						)}
						toggleOffMessage={translate(
							translationsScope.activateSwitch.toggleOffMessage,
						)}
						confirmButtonText={translate(
							translationsScope.activateSwitch.confirmButtonText,
						)}
						cancelButtonText={translate(
							translationsScope.activateSwitch.cancelButtonText,
						)}
						stateControlled={false}
					/>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.Headers.erpNumber),
			dataIndex: 'number',
			key: 'number',
			fixed: true,
			render: (data: string | undefined) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.Headers.locationName),
			key: 'name',
			dataIndex: 'name',
			width: 320,
			render: (data: string | undefined) => {
				return (
					<GenericTable.Column>
						<Paragraph
							className="text text--large ellipsis"
							title={data}
							ellipsis={{ rows: 2 }}
						>
							<p className="text text--large">{data}</p>
						</Paragraph>
					</GenericTable.Column>
				);
			},
		},
		{
			title: (
				<div className="table__header header--centered">
					{translate(translationsScope.Headers.isLocation)}
				</div>
			),
			key: 'isLocation',
			dataIndex: 'isLocation',
			width: 250,
			render: (data: boolean | undefined) => (
				<GenericTable.Column>
					<Checkbox
						className="text text--centered"
						disabled={true}
						checked={data}
					/>
				</GenericTable.Column>
			),
		},
		{
			title: (
				<div className="table__header header--centered">
					{translate(translationsScope.Headers.isDestination)}
				</div>
			),
			key: 'isDestination',
			dataIndex: 'isDestination',
			width: 250,
			render: (data: boolean | undefined) => (
				<GenericTable.Column>
					<Checkbox
						className="text text--centered"
						disabled={true}
						checked={data}
					/>
				</GenericTable.Column>
			),
		},
		{
			fixed: 'right' as 'right',
			width: 15,
			render: (data: CurrentLocation) => (
				<GenericTable.Column>
					<Actions rowData={data} />
				</GenericTable.Column>
			),
		},
	];

	const handleOnChange = (page, pageSize) => {
		dispatch(
			actions.setPagination({
				limit: pageSize,
				start: page,
			}),
		);
		dispatch(actions.loadLocationsList());
	};

	return (
		<GenericTable.Table
			otherClassName="table-container locations"
			columns={columns}
			data={listData}
			loading={listLoading}
			selectedFilters={filters}
			pagination={{
				total: listTotal,
				pageSize: limit,
				current: start,
				onChange: handleOnChange,
			}}
			emptyText={translate(translationsShared.Table.emptyText, {
				query: filters.name,
			})}
		/>
	);
};
