export const useHandleDependencies = () => {
	const initial = {
		spread: 0,
		netBasis: 0,
		pushBasis: 0,
	};

	const getSpread = (values) => {
		const canBeUpdated =
			!isNaN(values.netBasis) && initial.netBasis !== values.netBasis;

		if (canBeUpdated) {
			initial.spread =
				parseFloat(values?.origBasis) - parseFloat(values?.netBasis);
		}
		return { spread: initial.spread, canBeUpdated };
	};

	const getPushBasis = (values) => {
		const canBeUpdated =
			!isNaN(values.spread) && initial.spread !== values.spread;

		if (canBeUpdated) {
			initial.pushBasis =
				values?.origBasis - values?.spread - values?.postedBasis;
		}
		return { pushBasis: initial.pushBasis, canBeUpdated };
	};

	return {
		getPushBasis,
		getSpread,
	};
};
