import React from 'react';
import { useAbac } from 'react-abac';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { useTranslations } from 'app/containers/Contracts/components/Modals/shared/useTranslations';
import { selectHedgeAccountList } from 'app/containers/GlobalSaga/selectors';
import { selectExternalFill } from 'app/containers/ReviewAndRelease/selectors';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractNumber } from 'app/containers/Transactions/components/ContractNumber';
import { ContractType } from 'app/containers/Transactions/components/ContractType';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Quantity } from 'app/containers/Transactions/components/Quantity';
import { translations } from 'locales/i18n';
import { Permission } from 'types/Authorization';
import { CONSTANTS } from 'utils/constants';
import {
	customFormat,
	getActionType,
	mapToLabelValue,
	validatePriceFormat,
} from 'utils/helpers';
import Yup from 'yupCustom';

import { validateMinQuantity } from '../utils/helpers';

export const useEditSchema = () => {
	const { t: translate } = useTranslation();

	const msg =
		translations.app.containers.ReviewAndRelease.components.Views.List.Modals;

	const externalFill = useSelector(selectExternalFill);

	const orderData = externalFill.data;

	const { userHasPermissions } = useAbac();

	const quantityDisabled = !userHasPermissions(
		Permission.CONTRACTSERVICE_CONTRACTBASIS_EDITQUANTITY,
	);

	const contractNumberDisabled = !userHasPermissions(
		Permission.CONTRACTSERVICE_CONTRACTBASIS_EDITQUANTITY,
	);

	const sharedTranslations = useTranslations();

	const quantityErrorMsg =
		sharedTranslations.validations.createContractMinQuantityValidation;
	const numberMsg = sharedTranslations.validations.number;

	const requiredMsg = sharedTranslations.validations.required;

	const hedgeAccountList = mapToLabelValue(useSelector(selectHedgeAccountList));

	return {
		initialValues: {
			contractNumber: orderData.orderNumber,
			action: getActionType(orderData.isSell),
			commodity: {
				label: orderData.commodityName,
				value: orderData.commodityId,
			},
			cropYear: orderData.cropYear,
			futuresMonth: {
				label: orderData.futuresMonth,
				value: orderData.futuresMonth,
			},
			hedgeAccount: {
				value: orderData.accountNumber,
				label: orderData.accountName,
			},
			futuresPrice: validatePriceFormat(orderData.futuresPrice),
			quantity: customFormat(
				orderData.quantity,
				true,
				CONSTANTS.FIXED_QUANTITY_DECIMALS,
			),
			comments: orderData.comments,
		},
		validationSchema: Yup.object().shape({
			commodity: Yup.object().requiredOption(requiredMsg),
			quantity: Yup.string()
				.typeError(numberMsg)
				.required(requiredMsg)
				.test('minValidation', quantityErrorMsg, (value: string) =>
					validateMinQuantity(value),
				),
			cropYear: Yup.string().required(requiredMsg),
			futuresMonth: Yup.object().requiredOption(requiredMsg),
			futuresPrice: Yup.number().required(requiredMsg),
			hedgeAccount: Yup.object().requiredOption(requiredMsg),
		}),
		elements: [
			<ContractNumber disabled={contractNumberDisabled} />,
			<ContractType disabled showContractType={false} />,
			<Commodity disabled />,
			<CropYears disabled />,
			<Quantity disabled={quantityDisabled} />,
			<Futures
				futuresMonth={{ market: true }}
				futuresPrice={{ market: true }}
			/>,
			<GenericForm.FormItem
				name={'hedgeAccount'}
				label={translate(msg.hedgeAccountLabel)}
			>
				<Dropdown options={hedgeAccountList} />
			</GenericForm.FormItem>,
			<Comments />,
		],
	};
};
