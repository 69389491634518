import { takeLatest } from 'typed-redux-saga';

import { exportLiveLedger } from './sagas/excel-saga';
import { getLiveLedgerList } from './sagas/list-saga';
import { actions } from './slice';

export function* liveLedgerSaga() {
	yield* takeLatest(actions.loadLiveLedgerList.type, getLiveLedgerList);
	yield* takeLatest(actions.exportLiveLedger.type, exportLiveLedger);
}
