import './style.scss';

import { Input } from 'antd';
import React, { memo, useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { selectPostedBasisPrice } from 'app/containers/GlobalSaga/selectors';
import { useFormatPrice } from 'app/containers/Transactions/components/hooks/useFormatPrice';
import { translations } from 'locales/i18n';
import { ContractTypeSource } from 'types/ContractTypeSource';
import { DeliveryDateMode } from 'types/DeliveryDateMode';
import { formatPriceHelper } from 'utils/helpers';
import { preventWheelChange, preventWheelEvent } from 'utils/util';

import { SourceContext } from '../..';
import { useHandleBusinessRules } from '../hooks/useHandleBusinessRules';

interface Props {
	contractTypeSource?: ContractTypeSource;
	adjustedBasis?: boolean;
	disabled?: {
		postedBasis?: boolean;
		pushBasis?: boolean;
		netBasis?: boolean;
	};
	fields?: {
		postedBasis?: boolean;
		pushBasis?: boolean;
		netBasis?: boolean;
	};
	datesModeDependency?: boolean;
	setNetBasisOnMount?: boolean;
	calcFuturesOnPushBasisChange?: boolean;
}

export const Basis = memo((props: Props) => {
	const {
		contractTypeSource = ContractTypeSource.CONTRACT,
		disabled,
		adjustedBasis = false,
		datesModeDependency = false,
		setNetBasisOnMount = false,
		fields: { postedBasis = true, pushBasis = true, netBasis = true } = {},
		calcFuturesOnPushBasisChange = false,
	} = props;

	const sourceName = useContext(SourceContext);

	const contractType =
		contractTypeSource === ContractTypeSource.CONTRACT
			? ContractTypeSource.CONTRACT
			: ContractTypeSource.OFFER;

	const { t: translate } = useTranslation();
	const translationScope =
		translations.app.containers.Transactions.components.Basis;

	const postedBasisFromState = useSelector(selectPostedBasisPrice);
	const calculatedPostedBasis =
		postedBasisFromState?.[sourceName]?.data ?? null;

	const { formatPrice }: any = useFormatPrice();

	const { formState, setValue, getValues } = useFormContext();
	const { isDirty } = formState;

	const deliveryDatesMode = getValues('deliveryDatesMode');
	const disableBasis =
		datesModeDependency && deliveryDatesMode?.value === DeliveryDateMode.Window;

	const { setNetBasis, manageTotalPriceCall, manageSetFuturesPriceCall } =
		useHandleBusinessRules(sourceName);

	const useEffectSetPostedBasis = (effect: React.EffectCallback) => {
		useEffect(effect, [calculatedPostedBasis]);
	};

	useEffectSetPostedBasis(() => {
		// TODO, check prop price escenario for update besides dirty,
		if (isDirty) {
			setValue('postedBasis', formatPriceHelper(calculatedPostedBasis));
		}
	});

	const handleBlur = (name) => {
		formatPrice(name);
	};

	// TODO Check if works for offers
	const handlePostedBasisChange = () => {
		setNetBasis();
		manageTotalPriceCall();
	};
	const handlePushBasisChange = () => {
		setNetBasis();
		manageTotalPriceCall();
		if (calcFuturesOnPushBasisChange) {
			manageSetFuturesPriceCall();
		}
	};

	const handleNetBasisChange = () => {
		manageTotalPriceCall();
		manageSetFuturesPriceCall();
	};

	useEffect(() => {
		if (setNetBasisOnMount) setNetBasis();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<GenericForm.FormItem
			label={translate(translationScope.basisLabel)}
			name="basis"
			data-testid="basis-form-item"
		>
			{postedBasis && (
				<GenericForm.FormItem
					name="postedBasis"
					onChange={handlePostedBasisChange}
					data-testid="posted-basis-form-item"
				>
					<Input
						data-testid="posted-basis-input-item"
						placeholder={translate(translationScope[contractType].postedBasis)}
						key="postedBasis"
						type="number"
						onWheel={preventWheelChange}
						onFocus={preventWheelEvent}
						className="postedBasis"
						disabled={disabled?.postedBasis || disableBasis}
						onBlur={() => handleBlur('postedBasis')}
					></Input>
				</GenericForm.FormItem>
			)}
			{pushBasis && (
				<GenericForm.FormItem
					name="pushBasis"
					onChange={handlePushBasisChange}
					data-testid="push-basis-form-item"
				>
					<Input
						data-testid="push-basis-input-item"
						placeholder={translate(translationScope.pushBasis.placeholder)}
						key="pushBasis"
						type="number"
						onWheel={preventWheelChange}
						onFocus={preventWheelEvent}
						className="pushBasis"
						disabled={disabled?.pushBasis}
						onBlur={() => handleBlur('pushBasis')}
					></Input>
				</GenericForm.FormItem>
			)}
			{netBasis && (
				<GenericForm.FormItem
					name="netBasis"
					onChange={handleNetBasisChange}
					data-testid="net-basis-form-item"
				>
					<Input
						data-testid="net-basis-input-item"
						placeholder={
							adjustedBasis
								? translate(translationScope.adjustedBasis.placeholder)
								: translate(translationScope.netBasis.placeholder)
						}
						key="netBasis"
						type="number"
						onWheel={preventWheelChange}
						onFocus={preventWheelEvent}
						className="netBasis"
						disabled={disabled?.netBasis}
						onBlur={() => handleBlur('netBasis')}
					></Input>
				</GenericForm.FormItem>
			)}
		</GenericForm.FormItem>
	);
});
