import React, { memo } from 'react';

import { FIELD_TYPES_VALUES, OrderEntry } from 'types/OrderEntry';

import { Bln } from './Bln';
import { Dat } from './Dat';
import { Int } from './Int';
import { Mul } from './Mul';
import { Txt } from './Txt';
import { getOrderEntryType } from './utils/helpers';

const elements = {
	[FIELD_TYPES_VALUES.BLN]: (orderEntry: OrderEntry) => (
		<Bln orderEntry={orderEntry} />
	),
	[FIELD_TYPES_VALUES.DAT]: (orderEntry: OrderEntry) => (
		<Dat orderEntry={orderEntry} />
	),
	[FIELD_TYPES_VALUES.INT]: (orderEntry: OrderEntry) => (
		<Int orderEntry={orderEntry} />
	),
	[FIELD_TYPES_VALUES.MUL]: (orderEntry: OrderEntry) => (
		<Mul orderEntry={orderEntry} />
	),
	[FIELD_TYPES_VALUES.TXT]: (orderEntry: OrderEntry) => (
		<Txt orderEntry={orderEntry} />
	),
	[FIELD_TYPES_VALUES.TXT2]: (orderEntry: OrderEntry) => (
		<Txt orderEntry={orderEntry} />
	),
	[FIELD_TYPES_VALUES.UND]: () => <></>,
};

interface Props {
	orderEntry: OrderEntry;
}

export const OrderEntryElement = memo(({ orderEntry }: Props) =>
	elements[getOrderEntryType(orderEntry)](orderEntry),
);
