import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';

export const useTranslations = () => {
	const { t: translate } = useTranslation();

	const scope = translations.app.containers.Offers.components.Modals;
	const sharedScope = translations.app.containers.Transactions.Modals;

	return useMemo(
		() => ({
			bookModal: {
				title: translate(scope.bookMultipleTitle),
				body: translate(scope.bookMultipleBody),
			},
			informCustomer: {
				title: translate(scope.informCustomerTitle),
				body: translate(scope.informCustomerBody),
			},
			actions: {
				newOfferTitle: translate(scope.newOfferTitle),
				createConfirmationMessage: translate(
					scope.offerCreateConfirmationMessage,
				),
				editConfirmationMessage: translate(scope.editConfirmationMessage),
				editOfferTitle: translate(scope.editOfferTitle),
				confirmation: translate(scope.confirmation),
				confirmText: translate(scope.confirmText),
				cancelText: translate(scope.cancelText),
			},
			buttons: {
				submitAndNewOffer: translate(scope.buttons.submitAndNewOffer),
				submitOffer: translate(scope.buttons.submitOffer),
				submitChanges: translate(scope.buttons.submitChanges),
				cancelChanges: translate(scope.buttons.cancelChanges),
				cancel: translate(translations.app.containers.Offers.button.cancel),
				yes: translate(translations.app.containers.Offers.button.yes),
			},
			validations: {
				number: translate(sharedScope.validations.number),
				maxLength10: translate(sharedScope.validations.maxlength10),
				required: translate(sharedScope.validations.required),
				futuresPriceQuarterMsg: translate(
					translations.app.containers.Transactions.components.Futures
						.futuresPriceQuarterMsg,
				),
			},
			common: {
				success: translate(translations.app.commons.success.description),
			},
			confirmation: {
				private: translate(translations.app.containers.Offers.confirm.private),
				public: translate(translations.app.containers.Offers.confirm.public),
				editDownValidation: translate(
					translations.app.containers.Offers.confirm.editDownValidation,
				),
				title: translate(translations.app.containers.Offers.confirm.title),
			},
		}),
		[translate, scope, sharedScope],
	);
};
