import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.contracts || initialState;

export const selectContractListLoading = createSelector(
	[selectDomain],
	(contractsState) => contractsState.contractList.loading,
);

export const selectContractList = createSelector(
	[selectDomain],
	(contractsState) => contractsState.contractList.data,
);

export const selectContractAllLoaded = createSelector(
	[selectDomain],
	(contractsState) => contractsState.contractList.allLoaded,
);

export const selectTotalContracts = createSelector(
	[selectDomain],
	(contractsState) => contractsState.contractList.total,
);

export const selectActiveContractLoading = createSelector(
	[selectDomain],
	(contractsState) => contractsState.activeContract.loading,
);

export const selectActiveContract = createSelector(
	[selectDomain],
	(contractsState) => contractsState.activeContract.data,
);

export const selectActiveContractError = createSelector(
	[selectDomain],
	(contractsState) => contractsState.activeContract.error,
);

export const selectActiveContractDetails = createSelector(
	[selectDomain],
	(contractsState) => contractsState.activeContractDetails,
);

export const selectCurrentModal = createSelector(
	[selectDomain],
	(contractsState) => contractsState.currentModal,
);

export const selectCurrentView = createSelector(
	[selectDomain],
	(contractsState) => contractsState.currentView,
);

export const setCashbidsState = createSelector(
	[selectDomain],
	(contractsState) => contractsState.cashbidsState,
);

export const selectSelectedFilters = createSelector(
	[selectDomain],
	(contractsState) => contractsState.selectedFilters,
);

export const selectSelectedColumns = createSelector(
	[selectDomain],
	(contractsState) => contractsState.selectedColumns,
);

export const selectFixedColumns = createSelector(
	[selectDomain],
	(contractsState) => contractsState.fixedColumns,
);

export const selectPagination = createSelector(
	[selectDomain],
	(contractsState) => contractsState.pagination,
);

export const selectContractPriceRollLoading = createSelector(
	[selectDomain],
	(contractsState) => contractsState.contractPriceRoll.loading,
);

export const selectCurrentEventType = createSelector(
	[selectDomain],
	(contractsState) => contractsState.currentEventType,
);

export const selectContractsChildrenLoading = createSelector(
	[selectDomain],
	(contractsState) => contractsState.contractsChildren.loading,
);

export const selectContractsChildren = createSelector(
	[selectDomain],
	(contractsState) => contractsState.contractsChildren.data,
);

export const selectSpreadValue = createSelector(
	[selectDomain],
	(contractsState) => contractsState.spreadValue.response,
);

export const selectApplyNameId = createSelector(
	[selectDomain],
	(contractsState) => contractsState.contractApplyNameId,
);

export const selectResendToERPLoading = createSelector(
	[selectDomain],
	(contractsState) => contractsState.resendToERP.loading,
);

export const selectSelectedRows = createSelector(
	[selectDomain],
	(contractsState) => contractsState.selectedRows,
);

export const selectContractsFuturesMonth = createSelector(
	[selectDomain],
	(globalState) => globalState.contractsFuturesMonths,
);
