import React from 'react';

import { translations } from 'locales/i18n';

import { ReduceQuantityModal } from '../shared/GenericComponents/ReduceQuantityModal';

const UndoModal = () => {
	const orderTableText =
		translations.app.containers.OrderContainer.components.OrdersTable;
	const cancelOrderText =
		translations.app.containers.OrderContainer.components.CancelOrder;
	return (
		<ReduceQuantityModal
			transferCancelQuantityToParent={true}
			warningMessage={cancelOrderText.warningMessageForUndo}
			defaultMessageWithNumber={orderTableText.undoMessage}
			defaultMessage={orderTableText.defaultUndoMessage}
			titleMessage={orderTableText.undoContract}
			maxAmountMessage={cancelOrderText.maxAmountForUndo}
		/>
	);
};

export default UndoModal;
