import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectNtcAdmin } from 'app/containers/GlobalSaga/selectors';
import { getNumberArray } from 'utils/helpers';
import Yup from 'yupCustom';

import { NTC_ADMIN_FORM_ITEMS } from '../types';
import {
	isInputValid,
	NTC_ADMIN_DOMAIN,
	NTC_ADMIN_ENTRIES_MAX,
} from '../utils/helpers';

export const useNtcAdminSchema = () => {
	const { t: translate } = useTranslation();

	const NTCs = useSelector(selectNtcAdmin);

	const schema = useMemo(
		() =>
			getNumberArray(
				NTCs?.length === 0 ? NTC_ADMIN_ENTRIES_MAX : NTCs?.length,
			).reduce(
				(schema, currentValue, index: number) => {
					const requiredMsg = translate(NTC_ADMIN_DOMAIN.requiredValidation);

					const isCreatingNTCs = NTCs?.length === 0;
					const NtcAdmin = NTCs?.[index];

					const NtcAdminId = NtcAdmin?.id || index;

					const ntcNameLabel = `${NTC_ADMIN_FORM_ITEMS.NTC_NAME_LABEL}-${NtcAdminId}`;
					const erpIdLabel = `${NTC_ADMIN_FORM_ITEMS.ERP_ID_LABEL}-${NtcAdminId}`;
					const NtcAdminStatus = `${NTC_ADMIN_FORM_ITEMS.STATUS}-${NtcAdminId}`;

					return {
						initialValues: {
							...schema.initialValues,
							[NtcAdminStatus]: isCreatingNTCs || !!NtcAdmin?.isActive,
							[ntcNameLabel]: NtcAdmin?.name,
							[erpIdLabel]: NtcAdmin?.erpNumber,
						},
						validationSchema: schema.validationSchema.shape({
							[NtcAdminStatus]: Yup.boolean(),
							[ntcNameLabel]: Yup.string()
								.required(requiredMsg)
								.max(10, `must be at most 10 characters`)
								.test({
									message: requiredMsg,
									name: 'isActive',
									test: function (label: string) {
										const status = this.parent[NtcAdminStatus];
										return isInputValid(label, status);
									},
								}),
							[erpIdLabel]: Yup.string().test({
								message: requiredMsg,
								name: 'isActive',
								test: function (label: string) {
									const status = this.parent[NtcAdminStatus];

									return isInputValid(label, status);
								},
							}),
						}),
					};
				},
				{ initialValues: {}, validationSchema: Yup.object().shape({}) },
			),
		[NTCs, translate],
	);

	return schema;
};
