import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { SearchWithFeedback } from 'app/components/SearchWithFeedback';
import { selectCustomersList } from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { translations } from 'locales/i18n';
import { mapToLabelValue } from 'utils/helpers';

import { SourceContext } from '../..';

interface Props {
	disabled?: boolean;
	isAdjustment?: boolean;
}

export const Customer = memo((props: Props) => {
	const { disabled = false } = props;

	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const sourceName = useContext(SourceContext);

	const msg = translations.app.containers.Transactions.components.Customer;

	const customers = useSelector(selectCustomersList);
	const customersList = customers?.[sourceName]?.data
		? mapToLabelValue(customers[sourceName].data)
		: [];
	const customersLoading = customers?.[sourceName]?.loading ?? false;

	const onSearchCustomer = (searchText) => {
		dispatch(
			globalActions.loadCustomersList({
				source: sourceName,
				search: searchText,
			}),
		);
	};

	const clearCustomersList = () => {
		dispatch(globalActions.clearCustomersList({ source: sourceName }));
	};

	return (
		<GenericForm.FormItem
			label={translate(msg.label)}
			name="customer"
			customClassName={['search-form__label']}
			data-testid="customer-form-item"
		>
			<SearchWithFeedback
				disabled={disabled}
				name="customer"
				key="customer"
				placeholder={translate(msg.placeholder)}
				onSearch={onSearchCustomer}
				options={customersList}
				clearOptions={clearCustomersList}
				loading={customersLoading}
			/>
		</GenericForm.FormItem>
	);
});
