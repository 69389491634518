import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import { Button, Col, Row, Tooltip } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericTable } from 'app/components/GenericTable';
import { NotificationGroupDto } from 'app/containers/GlobalSaga/types';
import { translations } from 'locales/i18n';

import { NotificationGroupListHookValue } from '../../types';

interface Props {
	useGroupsHook: () => NotificationGroupListHookValue;
}

export const ListNotificationGroups = memo(({ useGroupsHook }: Props) => {
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.NotificationSettings;
	const {
		handleCreateGroup,
		handleEditGroup,
		handleDeleteGroup,
		groupList = [],
		listLoading = false,
	} = useGroupsHook();

	const columns = [
		{
			title: translate(translationsScope.GroupName),
			dataIndex: 'name',
			key: 'name',
			render: (data, record: NotificationGroupDto) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						{record.isSystem ? (
							<Tooltip
								placement="topLeft"
								title="Reserved system group - cannot be deleted or renamed!"
							>
								<p className="text text--large text--bold text--danger">
									{data}
								</p>
							</Tooltip>
						) : (
							<p className="text text--large">{data}</p>
						)}
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			width: 20,
			render: (data, record: NotificationGroupDto) => (
				<GenericTable.Column className="edit">
					<Button
						type="text"
						data-testid="edit-button"
						onClick={() => handleEditGroup?.(data)}
					>
						<EditFilled />
					</Button>
				</GenericTable.Column>
			),
		},
		{
			width: 20,
			render: (data, record: NotificationGroupDto) =>
				!record.isSystem && (
					<GenericTable.Column className="last-column">
						<Button
							type="text"
							data-testid="delete-button"
							onClick={() => handleDeleteGroup?.(data)}
						>
							<DeleteOutlined />
						</Button>
					</GenericTable.Column>
				),
		},
	];

	return (
		<div className="list-container">
			<Row justify="end">
				<Col>
					<Button
						type="primary"
						size="middle"
						onClick={handleCreateGroup}
						className="orders-button"
					>
						{translate(translationsScope.CreateGroupButton)}
					</Button>
				</Col>
			</Row>
			<GenericTable.Table
				otherClassName="groups-table table-container"
				columns={columns}
				loading={listLoading}
				data={groupList}
				pagination={false}
			/>
		</div>
	);
});
