import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectEnabledRegions,
	selectFeatureFlags,
} from 'app/containers/GlobalSaga/selectors';
import { FeatureFlag } from 'types/FeatureFlags';
import {
	CROP_LIST_ITERATOR,
	MAX_CROP_INTERVAL,
	MIN_CROP_INTERVAL,
} from 'utils/constants';
import { calculateEndDate, dateMoment, validateDateRange } from 'utils/helpers';

import { actions } from '../slice';

export const useHedgeAccountChange = (formikProps) => {
	const dispatch = useDispatch();
	const regions = useSelector(selectEnabledRegions);
	const featureFlags = useSelector(selectFeatureFlags);
	const regionsEnabled =
		featureFlags && featureFlags[FeatureFlag.enableRegions];

	const {
		values: { cropYearCalendar, addCropYear, cropsSection, addCropYear0 },
		setFieldValue,
	} = formikProps;

	const generateYearsList = (startDateYear) => {
		return Array.from({ length: CROP_LIST_ITERATOR - 1 }, (_, index) => ({
			year: startDateYear + index,
			HTA: '',
			Basis: '',
			FlatPrice: '',
		}));
	};
	const setCropsSectionValue = (value) => {
		const cropsSectionValue = regionsEnabled
			? regions.reduce((acc, region) => {
					acc[region.id as string] = value;
					return acc;
				}, {})
			: value;
		setFieldValue('cropsSection', cropsSectionValue);
	};

	useEffect(() => {
		if (cropYearCalendar) {
			const intervalValidation = validateDateRange(
				dateMoment(cropYearCalendar),
				dateMoment(calculateEndDate(cropYearCalendar)),
				{ min: MIN_CROP_INTERVAL, max: MAX_CROP_INTERVAL },
			);

			if (!intervalValidation) {
				const startDateYear = dateMoment(cropYearCalendar).year();
				const yearsList = generateYearsList(startDateYear);

				if (addCropYear) {
					yearsList.push({
						year: startDateYear + CROP_LIST_ITERATOR - 1,
						HTA: '',
						Basis: '',
						FlatPrice: '',
					});
				}

				if (!addCropYear && cropsSection.length === 4 && cropsSection.length) {
					cropsSection.pop();
				}

				setCropsSectionValue(yearsList);
			}
		}

		setFieldValue('hedgeMapping', '', false);
		dispatch(actions.clearHedgeMapTemplate());
	}, [cropYearCalendar, addCropYear, addCropYear0]);
};
