import { call, put, select } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import {
	selectFilters,
	selectPaginationLimit,
	selectPaginationStart,
} from '../selectors';
import { actions } from '../slice';
import { Filters } from '../types';

const { legacyLocationsFilter } = apiEndpoints;

export function* getLocationsList() {
	const filters: Filters = yield select(selectFilters);
	const limit = yield select(selectPaginationLimit);
	const start = yield select(selectPaginationStart);
	const requestURL = yield new URL(
		`${apiRoutes.base}/${legacyLocationsFilter}`,
	);
	const data = {
		limit,
		start,
		sortColumnName: null,
		sortOrder: 1,
		...filters,
	};

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		data,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.locationsListLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.locationsListError(responseError));
	}
}
