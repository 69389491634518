import './style.scss';

import React, { memo } from 'react';

interface Props {
	name: string;
	label: string;
}

export const ValidationTextViewer = memo((props: Props) => {
	return <div className="validationTextViewer">{props.label}</div>;
});
