import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
const { serviceFeeRoot, serviceFeeMonths, serviceFeeTypes } = apiEndpoints;

export function* getServiceFeesList() {
	let requestURL = yield new URL(
		`${apiRoutes.base}/${serviceFeeRoot}/${serviceFeeMonths}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.ServiceFeeListLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.ServiceFeeListError(responseError));
	}
}

export function* getServiceFeeTypes() {
	let requestURL = yield new URL(
		`${apiRoutes.base}/${serviceFeeRoot}/${serviceFeeTypes}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.ServiceFeeTypesListLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.ServiceFeeListError(responseError));
	}
}
