import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { translations } from 'locales/i18n';
import { mapToLabelValue } from 'utils/helpers';

import { selectDNHList } from '../../selectors';

const domain = translations.app.containers.Transactions.components.Commodity;

export const Commodity = memo(() => {
	const { t: translate } = useTranslation();
	const commoditiesList = useSelector(selectDNHList);
	const commoditiesOptions = mapToLabelValue(
		commoditiesList.filter(
			(commodity, index, list) =>
				list.findIndex((c) => c.id === commodity.id) === index,
		),
	);

	const getTranslation = (key: string) => translate(domain[key]);

	return (
		<GenericForm.FormItem
			data-testid="commodity-form-item"
			label={getTranslation('commodityLabel')}
			name="commodity"
		>
			<Dropdown
				data-testid="commodity-input-item"
				options={commoditiesOptions}
				placeholder={getTranslation('commodityPlaceholder')}
			></Dropdown>
		</GenericForm.FormItem>
	);
});
