import './style.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { Skeleton, Spin } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import {
	FormButtonsProps,
	FormSchemaDefinition,
} from 'app/components/GenericForm/types';
import {
	selectActiveContract,
	selectActiveContractLoading,
	selectContractPriceRollLoading,
} from 'app/containers/Contracts/selectors';
import { actions } from 'app/containers/Contracts/slice';
import { TransactionTypesContracts } from 'app/containers/Contracts/types';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { TransactionType } from 'app/containers/Offers/types';
import { SourceContext } from 'app/containers/Transactions';
import { EventType } from 'types/EventType';
import { GlobalSagaSource } from 'types/GlobalSagaSource';

import { useTranslations } from '../shared/useTranslations';
import { useAdjustmentsSchema } from './schemas/Adjustments';
import { useCashContractSchema } from './schemas/CashContract';
import { useOfferSchema } from './schemas/Offers';
import { useSalesTradingSchema } from './schemas/SalesTrading';

interface Props {
	eventType: EventType;
}

export const RollModal = memo((props: Props) => {
	const { eventType } = props;

	const translations = useTranslations();
	const dispatch = useDispatch();

	const orderData = useSelector(selectActiveContract);

	const isLoading = useSelector(selectActiveContractLoading);

	const isSubmitting = useSelector(selectContractPriceRollLoading);
	const cashContractSchema = useCashContractSchema();
	const salesTradingSchema = useSalesTradingSchema();
	const adjustmentSchema = useAdjustmentsSchema();

	const offerSchema = useOfferSchema();

	const [currentSchema, setCurrentSchema] = useState<FormSchemaDefinition>();

	const resolver = yupResolver(currentSchema?.validationSchema);
	const formInstance = useForm({
		defaultValues: {
			...(currentSchema?.initialValues as any),
		},
		mode: 'all',
		resolver,
	});

	useEffect(() => {
		let newSchema;
		switch (orderData?.transactionTypeName) {
			case TransactionTypesContracts.CashContract:
				newSchema = cashContractSchema?.[orderData.contractTypeName];
				break;
			case TransactionTypesContracts.BushelsOnly:
				newSchema = cashContractSchema?.[orderData.contractTypeName];
				break;
			case TransactionTypesContracts.SalesTrading:
				newSchema = salesTradingSchema?.[orderData.contractTypeName];
				break;
			case TransactionTypesContracts.Adjustment:
				newSchema = adjustmentSchema?.[orderData.contractTypeName];
				break;
			case TransactionType.Offer:
				newSchema = offerSchema?.[orderData.contractTypeName];
				break;
			default:
				break;
		}
		setCurrentSchema(newSchema);
		formInstance.reset({
			...newSchema?.initialValues,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderData]);

	const sourceName = GlobalSagaSource.contractModal;

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
		cleanState();
	};
	const cleanState = () => {
		dispatch(globalActions.clearFuturesPrices(sourceName));
		dispatch(globalActions.clearPostedBasisPrice(sourceName));
		dispatch(globalActions.clearFuturesMonth(sourceName));
		dispatch(globalActions.clearDeliveryDates(sourceName));
		dispatch(
			globalActions.clearFuturesMonthOptions(
				CalculatedFieldsSource.Transactions,
			),
		);
		dispatch(actions.resetSpreadValue());
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			className: 'order-form__new-order',
			type: 'default',
			htmlType: 'button',
			children: translations.buttons.cancel,
			onClick: handleClose,
			disabled: isLoading || isSubmitting,
			key: 'cancel',
			dataTestId: 'cancel-btn',
		},
		{
			htmlType: 'submit',
			children: translations.rolling.submit,
			disabled: isLoading || isSubmitting,
			key: 'new-order',
			dataTestId: 'roll-btn',
		},
	];

	const contractNumber = orderData?.internalNumber
		? ` #${orderData.internalNumber}`
		: '';

	const handleSubmit = (values) => {
		dispatch(
			actions.priceRollContract({
				eventType,
				data: currentSchema?.getSubmitValues?.(values),
				id: orderData?.id,
				successTitleGenerator: translations.rolling.successTitle,
				errorTitle: translations.rolling.errorTitle,
			}),
		);
		cleanState();
	};

	useEffect(() => {
		return () => {
			dispatch(actions.resetContractPriceRoll());
		};
	}, [dispatch]);

	return (
		<GenericForm.ModalContainer
			key="rollContractModal"
			title={isLoading ? <Spin /> : translations.rolling.title(contractNumber)}
			closable={true}
			maskClosable={false}
			visible={true}
			footer={null}
			onCancel={handleClose}
			confirmLoading={true}
			destroyOnClose={true}
		>
			<Skeleton
				active
				loading={isLoading || !!!orderData || !!!currentSchema}
				paragraph={{ rows: 10 }}
				title={false}
				className="roll-contract-modal__skeleton"
			>
				{!!currentSchema && (
					<SourceContext.Provider value={sourceName}>
						<GenericForm.Form
							className="order-form"
							key="editContractForm"
							formInstance={formInstance}
							validationSchema={currentSchema.validationSchema}
							onSubmit={handleSubmit}
							buttonsDefinition={btnsDefinition}
						>
							{currentSchema.elements}
						</GenericForm.Form>
					</SourceContext.Provider>
				)}
			</Skeleton>
		</GenericForm.ModalContainer>
	);
});
