import { PayloadAction } from '@reduxjs/toolkit';

import { NotificationGroupDto } from 'app/containers/GlobalSaga/types';
import { OffersModals } from 'app/containers/Offers/types';
import { FileResponse } from 'types/FileResponse';
import { GenericError } from 'types/GenericError';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { mapLabelToIds } from 'utils/helpers';

import {
	Bidsheet,
	BidsheetState,
	ExportExcelPayload,
	Modal as BidsheetModals,
	NoBid,
	PublishType,
	TableTypes,
	View,
} from './types';

export const initialState: BidsheetState = {
	currentView: View.List,
	currentModal: null,

	bidsheetTemplate: {
		data: null,
		uploadResponse: { data: null, status: null, statusText: null },
		loading: false,
		error: null,
	},
	bidsheetExport: {
		error: null,
		fileName: '',
		loading: false,
		url: null,
	},
	list: {
		data: [],
		loading: false,
		total: 0,
		hasAnythingChanged: false,
		error: null,
		type: TableTypes.Filter,
	},

	pagination: {
		limit: 50,
		start: 1,
	},

	selectedFilters: {
		commodities: [],
		locations: [],
		cropYears: [],
	},

	bidsheetPublish: {
		data: false,
		loading: false,
		error: null,
	},

	bidsheetSummary: {
		data: {
			orphanedBasisOffers: 0,
			orphanedFlatPriceOffers: 0,
			potentiallyFilledBasisOffers: 0,
			potentiallyFilledFlatPriceOffers: 0,
		},
		error: null,
		loading: false,
	},

	noBidList: {
		data: [],
		mappedData: {},
		loading: false,
		error: null,
		selectedNoBid: null,
	},

	orphansAndAvailables: {
		totalOrphans: null,
		orphansList: [],
		availablesList: [],
		mappedAvailablesList: null,
		loading: false,
		error: null,
		selectedRows: [],
		selectedAvailableBid: null,
	},

	rollOrphanOffers: {
		orphanCount: null,
		loading: false,
		error: null,
		loaded: false,
	},
	validationErrors: {
		errors: [],
	},

	notificationGroups: {
		data: [],
		loading: false,
		error: null,
	},
};

const bidsheetSlice = createSlice({
	name: 'bidsheet',
	initialState,
	reducers: {
		resetContainer(state) {
			state = initialState;
		},

		setCurrentModal(
			state,
			action: PayloadAction<OffersModals | BidsheetModals | null>,
		) {
			state.currentModal = action.payload;
		},

		uploadBidsheet(state: BidsheetState, action: PayloadAction<any>) {
			state.bidsheetTemplate = {
				...state.bidsheetTemplate,
				uploadResponse: { data: null, status: null, statusText: null },
				loading: true,
			};
		},

		bidsheetUploaded(state, action: PayloadAction<any>) {
			state.bidsheetTemplate = {
				...state.bidsheetTemplate,
				uploadResponse: action.payload,
				loading: false,
			};
		},

		bidsheetTemplateError(state, action: PayloadAction<GenericError>) {
			state.bidsheetTemplate = {
				...state.bidsheetTemplate,
				loading: false,
				error: action.payload,
			};
		},
		exportBids(state, action: PayloadAction<ExportExcelPayload>) {
			state.bidsheetExport = {
				...state.bidsheetExport,
				loading: true,
			};
		},
		clearExportBids(state) {
			state.bidsheetExport = {
				url: null,
				fileName: null,
				loading: false,
				error: null,
			};
		},
		exportedBids(state, action: PayloadAction<FileResponse>) {
			state.bidsheetExport = {
				url: action.payload.url,
				fileName: action.payload.fileName,
				error: null,
				loading: false,
			};
		},
		exportBidsError(state, action: PayloadAction<any>) {
			state.bidsheetExport = {
				...state.bidsheetExport,
				error: action.payload,
				loading: false,
			};
		},

		clearBidsheetUpload(state: BidsheetState) {
			state.bidsheetTemplate = {
				...state.bidsheetTemplate,
				uploadResponse: { data: null, status: null, statusText: null },
				loading: false,
				error: null,
			};
		},

		requestNoBidList(state) {
			state.noBidList = {
				...state.noBidList,
				loading: true,
				error: null,
			};
		},

		setNoBidList(state, action: PayloadAction<NoBid[]>) {
			state.noBidList = {
				...state.noBidList,
				data: action.payload,
				mappedData: mapLabelToIds(action.payload),
				loading: false,
			};

			state.rollOrphanOffers = initialState.rollOrphanOffers;
		},

		setSelectedNoBid(state, action: PayloadAction<any>) {
			state.noBidList = {
				...state.noBidList,
				selectedNoBid: action.payload,
			};

			state.orphansAndAvailables = {
				...state.orphansAndAvailables,
				availablesList: [],
				selectedAvailableBid: null,
			};
		},

		setNoBidListError(state, action: PayloadAction<GenericError>) {
			state.noBidList = {
				...state.noBidList,
				error: action.payload,
				loading: false,
			};
		},

		requestOrphansAndAvailables(state) {
			state.orphansAndAvailables = {
				...state.orphansAndAvailables,
				loading: true,
				error: null,
			};
		},

		setOrphansList(state, action: PayloadAction<any>) {
			state.orphansAndAvailables = {
				...state.orphansAndAvailables,
				orphansList: action.payload,
				loading: false,
			};
		},

		setSelectedRows(state, action: PayloadAction<any[]>) {
			state.orphansAndAvailables = {
				...state.orphansAndAvailables,
				selectedRows: action.payload,
			};
		},

		resetSelectedRows(state) {
			state.orphansAndAvailables = {
				...state.orphansAndAvailables,
				selectedRows: [],
			};
		},

		setAvailableBidList(state, action: PayloadAction<any>) {
			state.orphansAndAvailables = {
				...state.orphansAndAvailables,
				availablesList: action.payload,
				selectedAvailableBid: null,
			};
		},

		setSelectedAvailableBid(state, action: PayloadAction<any>) {
			state.orphansAndAvailables = {
				...state.orphansAndAvailables,
				selectedAvailableBid: action.payload,
			};
		},

		setOrphansAndAvailablesError(state, action: PayloadAction<GenericError>) {
			state.orphansAndAvailables = {
				...state.orphansAndAvailables,
				error: action.payload,
				loading: false,
			};
		},

		requestTotalOrphans(state) {
			state.orphansAndAvailables = {
				...state.orphansAndAvailables,
				loading: true,
				error: null,
			};
		},

		setTotalOrphans(state, action: PayloadAction<number | null>) {
			state.orphansAndAvailables = {
				...state.orphansAndAvailables,
				totalOrphans: action.payload,
				loading: false,
			};
		},

		setTotalOrphansError(state, action: PayloadAction<GenericError>) {
			state.orphansAndAvailables = {
				...state.orphansAndAvailables,
				error: action.payload,
				loading: false,
			};
		},

		resetOrphansModalState(state) {
			state.noBidList = initialState.noBidList;
			state.orphansAndAvailables = initialState.orphansAndAvailables;
		},

		setSelectedFilters(state, action: PayloadAction<any>) {
			state.selectedFilters = {
				...state.selectedFilters,
				...action.payload,
			};
		},

		loadBidsheetList(
			state: BidsheetState,
			action: PayloadAction<{ type: TableTypes }>,
		) {
			state.list = {
				...state.list,
				data: [],
				loading: true,
				error: null,
				type: action.payload.type,
			};
		},

		bidsheetListLoaded(
			state: BidsheetState,
			action: PayloadAction<{
				list: Bidsheet[];
				total: number;
				hasAnythingChanged: boolean;
			}>,
		) {
			state.list = {
				...state.list,
				data: action.payload.list,
				total: action.payload.total,
				hasAnythingChanged: action.payload.hasAnythingChanged,
				loading: false,
				error: null,
			};
		},

		bidsheetListError(state, action: PayloadAction<GenericError>) {
			state.list = {
				...state.list,
				error: action.payload,
				loading: false,
			};
		},

		bidsheetListClear(state) {
			state.list = initialState.list;
		},

		bidsheetListAddBid(state, action) {
			state.list.data = [action.payload, ...state.list.data];
			state.bidsheetSummary = { ...state.bidsheetSummary };
			state.list = { ...state.list, loading: false };
		},

		bidSheetListRemoveBid(state, action) {
			state.list.data = state.list.data.filter(
				(bid) => bid.id !== action.payload,
			);
		},
		bidsheetListUpdateBid(state, action) {
			state.list.data = [action.payload, ...state.list.data];
			state.bidsheetSummary = { ...state.bidsheetSummary };
			state.list = { ...state.list, loading: false };
		},

		setPagination(state: BidsheetState, action: PayloadAction<any>) {
			state.pagination = {
				limit: action.payload.limit || state.pagination.limit,
				start: action.payload.start || state.pagination.start,
			};
		},

		loadBidsheetSummary(state) {
			state.bidsheetSummary = {
				...state.bidsheetSummary,
				loading: true,
			};
		},

		bisheetSummaryLoaded(state, action: PayloadAction<any>) {
			state.bidsheetSummary = {
				...state.bidsheetSummary,
				loading: false,
				data: action.payload,
			};
		},

		bisheetSummaryError(state, action: PayloadAction<GenericError>) {
			state.bidsheetSummary = {
				...state.bidsheetSummary,
				loading: false,
				error: action.payload,
			};
		},

		publishBidsheet(state, action: PayloadAction<PublishType>) {
			state.bidsheetPublish = {
				...state.bidsheetPublish,
				loading: true,
			};
		},

		bidsheetPublished(state, action: PayloadAction<any>) {
			state.bidsheetPublish = {
				...state.bidsheetPublish,
				loading: false,
				data: action.payload,
			};
		},

		resetBidsheetPublished(state) {
			state.bidsheetPublish = initialState.bidsheetPublish;
		},

		bidsheetPublishError(state, action: PayloadAction<GenericError>) {
			state.bidsheetPublish = {
				...state.bidsheetPublish,
				loading: false,
				error: action.payload,
			};
		},

		rollOrphanOffers(state) {
			state.rollOrphanOffers = {
				...state.rollOrphanOffers,
				orphanCount: null,
				loading: true,
				error: null,
				loaded: false,
			};
		},

		orphanOffersRolled(state, action: PayloadAction<number>) {
			state.rollOrphanOffers = {
				...state.rollOrphanOffers,
				orphanCount: action.payload,
				loading: false,
				error: null,
				loaded: true,
			};
		},

		errorOrphanOffers(state, action: PayloadAction<GenericError>) {
			state.rollOrphanOffers = {
				...state.rollOrphanOffers,
				orphanCount: null,
				loading: false,
				error: action.payload,
				loaded: false,
			};
		},

		deleteBidRow(state, action: PayloadAction<object>) {
			state.list = {
				...state.list,
				loading: true,
				error: null,
			};
		},

		deleteBidRowError(state, action: PayloadAction<GenericError>) {
			state.list = {
				...state.list,
				loading: false,
				error: action.payload,
			};
		},

		addBidRow(state, action: PayloadAction<object>) {
			state.list = {
				...state.list,
				loading: true,
				error: null,
			};
		},
		addBidValidationErrors(state, action: PayloadAction<any>) {
			state.validationErrors = {
				errors: action.payload,
			};
			state.list = {
				...state.list,
				loading: false,
			};
		},
		clearBidValidationErrors(state: BidsheetState) {
			state.validationErrors = initialState.validationErrors;
		},

		addBidRowError(state, action: PayloadAction<GenericError>) {
			state.list = {
				...state.list,
				loading: false,
				error: action.payload,
			};
		},
		updateBid(state, action: PayloadAction<object>) {
			state.list = {
				...state.list,
				loading: true,
				error: null,
			};
		},
		updateBidValidationErrors(state, action: PayloadAction<any>) {
			state.validationErrors = {
				errors: action.payload,
			};
			state.list = {
				...state.list,
				loading: false,
			};
		},

		// Notification Groups
		getAllNotificationGroups(state) {
			state.notificationGroups = {
				...state.notificationGroups,
				loading: true,
				error: null,
			};
		},
		allNotificationGroupsLoaded(
			state,
			action: PayloadAction<NotificationGroupDto[]>,
		) {
			state.notificationGroups = {
				...state.notificationGroups,
				data: action.payload,
				loading: false,
			};
		},
		allNotificationGroupsError(state, action: PayloadAction<GenericError>) {
			state.notificationGroups = {
				...state.notificationGroups,
				loading: false,
				error: action.payload,
			};
		},

		notifyBidSent(state, action: PayloadAction<string>) {},
	},
});

export const { actions, reducer, name: sliceKey } = bidsheetSlice;
